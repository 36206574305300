import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../api';

import logo from '../assets/images/logo.png';

import { NavLink } from 'react-router-dom';

import storageKey from '../services/session';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  instanceComplexId: props.route.match.params.instanceComplexId,
	  reponseComplexId: props.route.match.params.reponseComplexId
	}
  }

class HookEmail extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hook: null
		}
	}

	componentDidMount() {
		console.log("hookemail did mount");
		this.hookEmail();
	}

	hookEmail() {
		let api = new Api();
		api.hookEmail(this.props.instanceComplexId, this.props.reponseComplexId).then((json) => {
			console.log(json);
			this.setState({
				hook: json
			})
		}).catch(err => {
			console.log(err);
		});
	}

	render() {

		let erreur = "Oups, une erreur est survenue.";
		if (this.state.hook && this.state.hook.erreur) {
			if (this.state.hook.erreur.code == "lien-invalide" || this.state.hook.erreur.code == "reponse-invalide") {
				erreur = "Oups, ce lien n'est pas valide.";
			}
			if (this.state.hook.erreur.code == "deja-repondu") {
				erreur = "Oups, une réponse a déjà été enregistrée pour cette question.";
			}
		}

		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						{this.state.hook && this.state.hook.succes &&
							<h3 className="text-muted font-18 m-b-5 text-center">
								Merci, votre réponse a bien été prise en compte.<br />
								Vous pouvez fermer cette page.
							</h3>
						}
						{this.state.hook && this.state.hook.erreur &&
							<h3 className="text-muted font-18 m-b-5 text-center">{erreur}</h3>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(HookEmail);