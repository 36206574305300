import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import DatePicker from "react-datepicker";
import moment from 'moment'

import CalendarService from '../../services/calendar';
import DataService from '../../services/data';
import SecurityService from '../../services/security'

import FormQuestion from '../Forms/FormQuestion';
import FormRepetitionQuestions from '../Forms/FormRepetitionQuestions';

import Api from '../../api'
import { copieQuestions, getQuestionsDefaut } from '../../api/axios';
import { questionValide } from '../../services/validation';


const mapStateToProps = (state, props) => {
	return {
	  data: state.data,	  
	}
  }

const SortableQuestion = SortableElement(({value, items, questionsSelectionnees, personne, toggleQuestion, setEditingQuestion}) => {
    let question = value;

    let calendarService = new CalendarService();

    let joursSemaine = [];
    for (let i = 1 ; i <= 7 ; i++) {
        joursSemaine.push(
            <span key={"btn-jourSemaine-" + (i % 7)} className={"jour" + (question.repetition.type == "jour" || question.repetition.joursSemaine.includes((i % 7)) ? " actif" : "")}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
        );
    }

    let joursReglages = [];
    for (let i = 1 ; i <= 7 ; i++) {
        joursReglages.push(
            <span key={"btn-jourReglages-" + (i % 7)} className={"jour" + (question.repetition.type == "reglages" && personne.declenchementQuestionsAutomatiques.jours.includes((i % 7)) ? " actif" : "")}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
        );
    }

    let joursMois = [];
    question.repetition.mois.joursAbsolus.sort((a,b) => (a < b) ? -1 : 1).map((i) => {
        joursMois.push(
            <span key={"btn-jourMois-" + i} className={"jour" + (question.repetition.mois.joursAbsolus.includes(i) ? " actif" : "")}>{i}</span>
        );
    });
    
    let jourAn = "Envoyée tous les " + parseInt(question.repetition.jourAn.jour) + " " + calendarService.mois()[parseInt(question.repetition.jourAn.mois) - 1].nom;

    let fin = new Date(question.repetition.fin.date);
            
    return (
        <tr key={"row-question-" + question.id} className={(questionsSelectionnees.includes(question.id) ? "table-warning" : "") + " " + (question.termine ? "obsolete" : "")}>
            <td>
                <div className="checkbox-wrapper">
                    <input type="checkbox" id={"toggle-question-" + question.id} onChange={() => toggleQuestion(question)} checked={questionsSelectionnees.includes(question.id)} />
                    <label htmlFor={"toggle-question-" + question.id} className="toggle"></label>
                </div>
            </td>
            <td className="libelle-question">
                <p className="m-0 table-nom text-muted font-14">{question.libelleCalcule}</p>
                {question.reponses.filter(reponse => reponse.questionDeclenchee != null).length > 0 &&
                    <p className="ml-2">
                        {question.reponses.map((reponse, indexReponse) => {
                            if (reponse.action != 'question') return null
                            if (reponse.questionDeclenchee == null) return null
                            return (
                                <React.Fragment key={"reponse-" + indexReponse}>
                                    <small>{question.type === 'gradient' ? (indexReponse + 1) : reponse.libelle} --&gt; {reponse.questionDeclenchee.libelleCalcule}</small><br />
                                </React.Fragment>
                            )
                        })}
                    </p>
                }
            </td>
            <td>
                <p className="m-0 text-muted font-14">
                    {!question.termine && ["jour", "semaine"].includes(question.repetition.type) && <>{joursSemaine}</>}
                    {!question.termine && ["semaine-paire", "semaine-impaire"].includes(question.repetition.type) && <>Semaines {question.repetition.type == "semaine-impaire" ? "im" : ""}paires<br />{joursSemaine}</>}
                    {!question.termine && ["mois"].includes(question.repetition.type) && 
                        <>
                            {question.repetition.mois.type === 'absolu' &&
                                <span className="mr-2">{joursMois}</span>    
                            }
                            {question.repetition.mois.type === 'relatif' &&
                                <>{{
                                    first: 'Premier',
                                    second: 'Deuxième',
                                    third: 'Troisième',
                                    fourth: 'Quatrième',
                                    last: 'Dernier'
                                }[question.repetition.mois.joursRelatifs.relatif]} {moment().days(question.repetition.mois.joursRelatifs.jour).format('dddd')}</>
                            }
                            <> tous les{question.repetition.mois.frequence > 1 ? <> {question.repetition.mois.frequence}</> : <></>} mois</>
                        </>
                    }                    
                    {!question.termine && ["an"].includes(question.repetition.type) && <>{jourAn}</>}
                    {!question.termine && ["reglages"].includes(question.repetition.type) && <>{joursReglages}</>}
                    {question.termine && question.repetition.type != "none" && question.repetition.fin.type == "date" && <>N'est plus envoyée depuis le {fin.getDate()} {calendarService.mois()[fin.getMonth()].nomCourt} {fin.getFullYear()}</>}
                    {question.termine && question.repetition.type != "none" && question.repetition.fin.type == "occurrences" && <>N'est plus envoyée ({question.repetition.fin.occurrences} occurrences)</>}
                    {question.repetition.type == "none" && "Non envoyée"}
                    {question.repetition.type == "aleatoire" && "Déclenchée aléatoirement"}
                </p>
            </td>
            <td>
                {(personne.classe == "Beneficiaire" || personne.classe == "Proche") &&
                    <>
                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifquestion-" + question.id} onClick={() => setEditingQuestion(question)}><i className="dripicons-document-edit"></i></a></span>
                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Copier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-copiequestion-" + question.id}><i className="mdi mdi-content-duplicate"></i></a></span>
                    </>
                } 
                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionquestion-" + question.id}><i className="dripicons-trash"></i></a></span>
            </td>
            <td>
                {items.length > 1 &&<div className="deplacer"><i className="dripicons-move"></i></div>}
            </td>
        </tr>
    );
});

const SortableQuestions = SortableContainer(({items, questionsSelectionnees, personne, toggleQuestion, setEditingQuestion}) => {
    return (
        <tbody id="tbody-questions">
            {items.map((value, index) => (
                <SortableQuestion
                    key={`item-${value.id}`} 
                    sortIndex={value.numero} 
                    index={value.numero} 
                    value={value} 
                    questionsSelectionnees={questionsSelectionnees} 
                    toggleQuestion={toggleQuestion}
                    setEditingQuestion={setEditingQuestion}
                    items={items}
                    personne={personne}
                 />
            ))}
            {items.length == 0 &&
                <tr key="row-question-0">
                    <td></td>
                    <td><p className="m-0 text-muted font-14">Aucune question.</p></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            }
            {(personne.classe == "Beneficiaire" || personne.classe == "Proche") &&
                <tr>
                    <td colSpan="5" className="tdaction">
                        <a href="#" data-toggle="modal" data-target="#modal-nouvellequestion" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle question / information</a>
                    </td>
                </tr>                    
            }
        </tbody>
    );
});

class TabQuestionsPersonne extends Component {

    constructor(props) {
        super(props);

        let dateFinPauseQuestions = new Date();
		dateFinPauseQuestions.setMonth(dateFinPauseQuestions.getMonth() + 1);

        this.state = {
            personne: JSON.parse(JSON.stringify(this.props.personne)),
            editingQuestion: null,
            editingQuestionDeclenchee: false,
            questionsSelectionnees: [],
            etatCopieQuestions: 'init',
            autresPersonnes: [],
            editingRepetition: null,
            dateFinPauseQuestions: dateFinPauseQuestions,
            nouvelleBibliotheque: null,
            historique: this.props.personne.historique.length > 0 ? this.props.personne.historique[0] : null,
            historiqueLoading: false,
            changementBibliotheque: false, // pour la modal d'avertissement
            pauseQuestions: false, // pour la modal d'avertissement,
            questionsDefaut: [],
            questionsDefautSelectionnees: [],
            filtreQuestionsDefaut: {
                motscles: '',
                communication: ''
            }
        }        
    }

    componentDidMount() {
        console.log("TabQuestions did mount");

        this.bindModals()        

        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip("dispose");
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip({
            trigger: 'hover'
        }); 
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").click(function() {
            window.jQuery(this).tooltip("hide");
        });

        console.log(this.props.personne)

        this.getQuestionsDefaut()
    }

    componentDidUpdate(prevProps) {
        console.log("TabQuestions did update");

        if (JSON.stringify(this.props.personne) != JSON.stringify(prevProps.personne)) {
            this.setState({
                personne: JSON.parse(JSON.stringify(this.props.personne)), 
                historique: this.props.personne.historique.length > 0 ? this.props.personne.historique[0] : null,               
            });
            if (this.state.changementBibliotheque) {                
                $("#modal-avertissementchangementbibliotheque").modal("show")
            }
            if (this.state.pauseQuestions) {                
                $("#modal-avertissementpausequestions").modal("show")
            }
            this.getQuestionsDefaut()
        }

        this.bindModals();        
    }

    bindModals() {
        window.jQuery("#modal-nouvellequestion").off('hidden.bs.modal');
        window.jQuery("#modal-nouvellequestion").on('hidden.bs.modal', () => {
            this.setState({
                editingQuestion: null
            });
        });
        if (this.getBibliotheque(this.props.personne.bibliotheque)) {
            this.getBibliotheque(this.props.personne.bibliotheque).questions.map((question) => {
                window.jQuery("#modal-modifquestion-" + question.id).off('hidden.bs.modal');
                window.jQuery("#modal-modifquestion-" + question.id).on('hidden.bs.modal', () => {
                    this.setState({
                        editingQuestion: null
                    });
                });
                window.jQuery("#modal-copiequestion-" + question.id).off('hidden.bs.modal');
                window.jQuery("#modal-copiequestion-" + question.id).on('hidden.bs.modal', () => {
                    this.setState({
                        etatCopieQuestions: 'init'
                    });
                });
            })
        } 
        window.jQuery("#modal-historique").on('hidden.bs.modal', () => {
            this.setState({
                historique: this.props.personne.historique.length >= 0 ? this.props.personne.historique[0] : null
            });
        });    
        if (this.state.changementBibliotheque) {
            window.jQuery("#modal-avertissementchangementbibliotheque").off('hidden.bs.modal');
            window.jQuery("#modal-avertissementchangementbibliotheque").on('hidden.bs.modal', () => {
                this.setState({
                    changementBibliotheque: false
                });
            });
        }
        if (this.state.pauseQuestions) {
            window.jQuery("#modal-avertissementpausequestions").off('hidden.bs.modal');
            window.jQuery("#modal-avertissementpausequestions").on('hidden.bs.modal', () => {
                this.setState({
                    pauseQuestions: false
                });
            });
        }   
        window.jQuery("#modal-banquequestions").off('hidden.bs.modal');
        window.jQuery("#modal-banquequestions").on('hidden.bs.modal', () => {
            this.setState({
                questionsDefautSelectionnees: [],
                filtreQuestionsDefaut: {
                    motscles: '',
                    communication: ''
                }
            });
        });
        window.jQuery("#modal-copiequestions").off('hidden.bs.modal');
        window.jQuery("#modal-copiequestions").on('hidden.bs.modal', () => {
            this.setState({
                etatCopieQuestions: 'init'
            });
        });

    }

    getQuestionsDefaut() {
        getQuestionsDefaut({
            cibles: [this.props.personne.classe === 'Beneficiaire' ? 'beneficiaire' : 'proche-superieur'],
            domaines: this.getStructure() ? [this.getStructure().domaine] : [],
            motscles: '',
            communications: [],
            categories: [],
            limit: 200
        })
        .then(res => {
            console.log(res)
            this.setState({
                questionsDefaut: res.data.questions
            })
        })
        .catch(err => {
            console.log(err.response)
        })
    }

    filtreQuestionDefaut(question) {
        if (this.state.filtreQuestionsDefaut.motscles.trim() !== '') {
            let mots = this.state.filtreQuestionsDefaut.motscles.replace(/[\.,?!\'\-"]/g, '').split(' ').filter(m => m != '')
            for (let mot of mots) {
                if (question.libelle.toLowerCase().indexOf(mot.toLowerCase()) == -1) {
                    return false
                }
            }
        }
        if (this.state.filtreQuestionsDefaut.communication !== '') {
            if (question.communication !== this.state.filtreQuestionsDefaut.communication) {
                return false;
            }
        }
        
        return true
    }

    toggleQuestionDefautSelectionnee(question) {
        if (this.state.questionsDefautSelectionnees.includes(question.id)) {
            this.state.questionsDefautSelectionnees.splice(this.state.questionsDefautSelectionnees.indexOf(question.id), 1)
        }
        else {
            this.state.questionsDefautSelectionnees.push(question.id)
        }
        this.setState({
            questionsDefautSelectionnees: this.state.questionsDefautSelectionnees
        })
    }

    toggleQuestion(question) {
        let questionsSelectionnees = this.state.questionsSelectionnees;
        if (questionsSelectionnees.includes(question.id)) {
            questionsSelectionnees.splice(questionsSelectionnees.indexOf(question.id), 1);
        }
        else {
            questionsSelectionnees.push(question.id);
        }
        this.setState({
            questionsSelectionnees: questionsSelectionnees
        });
    }

    toggleAllQuestions() {
        if (!this.getBibliotheque(this.props.personne.bibliotheque)) return null

        let questionsSelectionnees = [];
        if (this.state.questionsSelectionnees.length == this.getBibliotheque(this.props.personne.bibliotheque).questions.length) {
            questionsSelectionnees = [];
        }
        else {
            this.getBibliotheque(this.props.personne.bibliotheque).questions.map((question) => {
                questionsSelectionnees.push(question.id)
            });
        }
        this.setState({
            questionsSelectionnees: questionsSelectionnees
        })
    }

    setEditingQuestion(question) {
        console.log(question);
        this.setState({
            editingQuestion: question
        });
    }

    setEditingRepetition(repetition) {
        this.setState({
            editingRepetition: repetition
        });
    }

    resetReglages() {
        let personne = this.state.personne;
        personne.prefixeMessage = this.props.personne.prefixeMessage;
        personne.suffixeMessage = this.props.personne.suffixeMessage;
        personne.modeMessage = this.props.personne.modeMessage;
        personne.nbNoReponseAlerte = this.props.personne.nbNoReponseAlerte;
        personne.declenchementQuestionsAutomatiques = this.props.personne.declenchementQuestionsAutomatiques;
        personne.declenchementQuestionsAleatoires = this.props.personne.declenchementQuestionsAleatoires;
        this.setState({
            personne: personne
        })
    }

    modifiePersonne(champ, val) {
        let personne = this.state.personne;
        if (champ == "prefixeMessage") {
            personne.prefixeMessage = val;
        }
        if (champ == "suffixeMessage") {
            personne.suffixeMessage = val;
        }
        if (champ == "modeMessage") {
            personne.modeMessage = val;
        }
        if (champ == "nbNoReponseAlerte") {
            personne.nbNoReponseAlerte = val;
        }
        if (champ == "declenchementQuestionsAutomatiques-heure") {
            personne.declenchementQuestionsAutomatiques.heure = val;
        }
        if (champ == "declenchementQuestionsAutomatiques-minute") {
            personne.declenchementQuestionsAutomatiques.minute = val;
        }
        if (champ == "declenchementQuestionsAutomatiques-jours") {
            personne.declenchementQuestionsAutomatiques.jours = val;
        }
        if (champ == "declenchementQuestionsAleatoires-heure") {
            personne.declenchementQuestionsAleatoires.heure = val;
        }
        if (champ == "declenchementQuestionsAleatoires-minute") {
            personne.declenchementQuestionsAleatoires.minute = val;
        }
        if (champ == "declenchementQuestionsAleatoires-jours") {
            personne.declenchementQuestionsAleatoires.jours = val;
        }
		if (champ == "bibliotheques") {
            personne.bibliotheques = val
            this.enregistrePersonne("bibliotheques");
        }
        if (champ == "bibliotheque") {
            personne.bibliotheque = val
            this.enregistrePersonne("bibliotheque");
        }
        this.setState({
            personne: personne
        });
    }

    enregistrePersonne(champ) {
        console.log(champ);
        let personne = null;
        if (champ == "bibliotheques") {
            if (this.state.personne.bibliotheques != this.props.personne.bibliotheques) {
				personne = {
                    id: this.props.personne.id,
                    classe: this.props.personne.classe,
                    bibliotheques: this.state.personne.bibliotheques
                }
            }
        }
        if (champ == "reglages") {
            personne = {
                id: this.props.personne.id,
                classe: this.props.personne.classe,
                prefixeMessage: this.state.personne.prefixeMessage,
                suffixeMessage: this.state.personne.suffixeMessage,
                modeMessage: this.state.personne.modeMessage,
                nbNoReponseAlerte: this.state.personne.nbNoReponseAlerte,
                declenchementQuestionsAutomatiques: this.state.personne.declenchementQuestionsAutomatiques,
                declenchementQuestionsAleatoires: this.state.personne.declenchementQuestionsAleatoires
            }
        }
        if (personne) {
            this.props.enregistre(personne);
		}
    }
    
    ajouteQuestion() {      
        if (this.state.editingQuestion && this.state.editingQuestion.type == 'information') {
            this.state.editingQuestion.reponses = []
        }  
        let bibliotheques = this.state.personne.bibliotheques;
        bibliotheques.map((bibliotheque, iBibliotheque) => {
            if (bibliotheque.id == this.state.personne.bibliotheque) {
                bibliotheques[iBibliotheque].questions.push(
                    this.state.editingQuestion
                )
            }
        })
        this.modifiePersonne("bibliotheques", bibliotheques);
    }

    ajouteQuestionsDefaut() {
        this.props.ajouteQuestionsDefaut(this.state.questionsDefautSelectionnees, this.props.personne)        
    }

    modifieQuestion(index) {
        if (this.state.editingQuestion && this.state.editingQuestion.type == 'information') {
            this.state.editingQuestion.reponses.map((reponse) => {
                reponse.supprime = true
            })
        }
        let bibliotheques = this.state.personne.bibliotheques;
        bibliotheques.map((bibliotheque, iBibliotheque) => {
            if (bibliotheque.id == this.state.personne.bibliotheque) {
                bibliotheques[iBibliotheque].questions[index] = this.state.editingQuestion
            }
        })
        this.modifiePersonne("bibliotheques", bibliotheques);
    }

    supprimeQuestion(index) {
        let bibliotheques = this.state.personne.bibliotheques;
        bibliotheques.map((bibliotheque, iBibliotheque) => {
            if (bibliotheque.id == this.state.personne.bibliotheque) {
                bibliotheques[iBibliotheque].questions[index].supprime = true
            }
        })
        this.modifiePersonne("bibliotheques", bibliotheques);
    }

    supprimeQuestions() {
        let bibliotheques = this.state.personne.bibliotheques;
        bibliotheques.map((bibliotheque, iBibliotheque) => {
            if (bibliotheque.id == this.state.personne.bibliotheque) {
                this.state.questionsSelectionnees.map((q) => {
                    bibliotheque.questions.map((question, iQuestion) => {
                        if (question.id == q) {
                            bibliotheques[iBibliotheque].questions[iQuestion].supprime = true;
                        }
                    })
                })
            }
        })
        this.modifiePersonne("bibliotheques", bibliotheques);

        this.setState({
            questionsSelectionnees: []
        })
    }

    reordonneQuestions(oldIndex, newIndex) {
        console.log("reordonne");
        if (oldIndex != newIndex) {
            let bibliotheques = this.state.personne.bibliotheques;
            bibliotheques.map((bibliotheque, iBibliotheque) => {
                if (bibliotheque.id == this.state.personne.bibliotheque) {
                    bibliotheque.questions.map((q, iQuestion) => {
                        if (q.numero == oldIndex) {
                            bibliotheques[iBibliotheque].questions[iQuestion].numero = (oldIndex > newIndex) ? (newIndex - 1) : (newIndex + 1);
                        }
                    });
                }
            })
            this.modifiePersonne("bibliotheques", bibliotheques);
        }    
    }

    copieQuestion(question) {
        //this.props.copieQuestions([question.id], this.state.autresPersonnes);
        this.setState({
            etatCopieQuestions: 'encours'
        })
        copieQuestions([question.id], this.state.autresPersonnes)
        .then(res => {
            console.log(res)
            this.setState({
                autresPersonnes: [],
                etatCopieQuestions: 'termine'
            })
        })
        .catch(err => {
            console.log(err.response)
            this.setState({
                etatCopieQuestions: 'init'
            })
        })        
    }

    copieQuestions() {
        //this.props.copieQuestions(this.state.questionsSelectionnees, this.state.autresPersonnes);
        this.setState({
            etatCopieQuestions: 'encours'
        })
        copieQuestions(this.state.questionsSelectionnees, this.state.autresPersonnes)
        .then(res => {
            console.log(res)
            this.setState({
                autresPersonnes: [],
                questionsSelectionnees: [],
                etatCopieQuestions: 'termine'
            })
        })
        .catch(err => {
            console.log(err.response)
            this.setState({
                etatCopieQuestions: 'init'
            })
        })        
    }

    toggleAutresPersonnes(personne) {
        let autresPersonnes = this.state.autresPersonnes;
        if (autresPersonnes.includes(personne)) {
            autresPersonnes.splice(autresPersonnes.indexOf(personne), 1);
        }
        else {
            autresPersonnes.push(personne);
        }
        this.setState({
            autresPersonnes: autresPersonnes
        })
    }

    formValide() {
        /*return (
            this.state.editingQuestion
            && this.state.editingQuestion.libelle != ""
            && (
                this.state.editingQuestion.type == "liste"
                || (
                    this.state.editingQuestion.type == "gradient"
                    && this.state.editingQuestion.parametres.gradient.min
                    && this.state.editingQuestion.parametres.gradient.max
                    && this.state.editingQuestion.parametres.gradient.min < this.state.editingQuestion.parametres.gradient.max
                )
                || this.state.editingQuestion.type == "information"
            )
            && (
                this.state.editingQuestion.type == "information"
                || (
                    this.state.editingQuestion.reponses.length > 0
                    && this.formReponsesValide()
                )
            )
            && (
                this.state.editingQuestion.repetition.fin.type == "none"
                || this.state.editingQuestion.repetition.fin.type == "date" && this.state.editingQuestion.repetition.fin.date
                || this.state.editingQuestion.repetition.fin.type == "occurrences" && this.state.editingQuestion.repetition.fin.occurrences != "" && this.state.editingQuestion.repetition.fin.occurrences > 0
            )
        );*/        
        return questionValide(this.state.editingQuestion)
    }

    formReponsesValide() {
        let valide = true;
        if (this.state.editingQuestion && this.state.editingQuestion.type == "liste") {
            this.state.editingQuestion.reponses.map((reponse) => {
                if (
                    reponse.libelle == ""
                ) {
                    valide = false;
                }
            })
        }
        return valide;
    }

    toggleJourDeclenchementQuestionsAutomatiques(jour) {
        let declenchementQuestionsAutomatiques = this.state.personne.declenchementQuestionsAutomatiques;
        if (declenchementQuestionsAutomatiques.jours.includes(jour)) {
            declenchementQuestionsAutomatiques.jours.splice(declenchementQuestionsAutomatiques.jours.indexOf(jour), 1);
        }
        else {
            declenchementQuestionsAutomatiques.jours.push(jour);
        }
        this.modifiePersonne("declenchementQuestionsAutomatiques-jour", declenchementQuestionsAutomatiques);
    }

    toggleJourDeclenchementQuestionsAleatoires(jour) {
        let declenchementQuestionsAleatoires = this.state.personne.declenchementQuestionsAleatoires;
        if (declenchementQuestionsAleatoires.jours.includes(jour)) {
            declenchementQuestionsAleatoires.jours.splice(declenchementQuestionsAleatoires.jours.indexOf(jour), 1);
        }
        else {
            declenchementQuestionsAleatoires.jours.push(jour);
        }
        this.modifiePersonne("declenchementQuestionsAleatoires-jour", declenchementQuestionsAleatoires);
    }

    desactiveQuestions() {
		this.props.enregistre({
			id: this.props.personne.id,
			classe: this.props.personne.classe,
			dateFinPauseQuestions: this.state.dateFinPauseQuestions
		});
        let questionsEmployeurs = false;
        if (this.props.personne.classe == "Beneficiaire") {
            this.props.personne.emplois.map(emploi => {
                emploi.proches.filter(proche => proche.description == "superieur").map(proche => {
                    if (proche.bibliotheque) {
                        questionsEmployeurs = true
                    }
                })
            })
            if (questionsEmployeurs === false) {
                this.props.personne.proches.filter(proche => proche.description == "superieur").map(proche => {
                    if (proche.bibliotheque) {
                        questionsEmployeurs = true
                    }
                })
            }
        }        
        this.setState({
            pauseQuestions: questionsEmployeurs
        })                
	}
	
	activeQuestions() {
		if (this.props.personne.initQuestions) {
			this.props.enregistre({
				id: this.props.personne.id,
				classe: this.props.personne.classe,
				dateFinPauseQuestions: null
			});
		}
		else {
			this.props.enregistre({
				id: this.props.personne.id,
				classe: this.props.personne.classe,
				initQuestions: true
			});
		}
	}

    annuleActivationQuestions() {
        this.props.enregistre({
			id: this.props.personne.id,
			classe: this.props.personne.classe,
			initQuestions: false
		});
    }

    getBibliotheque(id) {
        for (let b of this.state.personne.bibliotheques) {
            if (b.id == id) {
                return b
            }
        }
        return null
    }

    changeBibliotheque() {
        if (!this.state.nouvelleBibliotheque) return
        let questionsEmployeurs = false;
        if (this.props.personne.classe == "Beneficiaire") {
            this.props.personne.emplois.map(emploi => {
                emploi.proches.filter(proche => proche.description == "superieur").map(proche => {
                    if (proche.bibliotheque) {
                        questionsEmployeurs = true
                    }
                })
            })
            if (questionsEmployeurs === false) {
                this.props.personne.proches.filter(proche => proche.description == "superieur").map(proche => {
                    if (proche.bibliotheque) {
                        questionsEmployeurs = true
                    }
                })
            }
        }
        this.props.dispatch({type: 'DATA_CHANGE_BIBLIOTHEQUE', payload: {personne: this.props.personne, bibliotheque: this.state.nouvelleBibliotheque}})   
        this.setState({
            nouvelleBibliotheque: null,
            changementBibliotheque: questionsEmployeurs
        })
    }    

    getHistorique(date) {
        let api = new Api()

        this.setState({
            historiqueLoading: true,
            historique: {
                date: date,
                instances: []
            }
        })

        api.getPersonneHistoriqueQuestions(this.props.personne, date)
        .then(res => {
            this.setState({
                historique: res,
                historiqueLoading: false

            })
        })
        .catch(err => {
            console.log(err)
            this.setState({
                historiqueLoading: true
            })
        })
    }

    envoieEmailConsentement() {
        let api = new Api();
		api.sendPersonneConsentement(this.props.personne).then((json) => {
			console.log(json);
            if (json.succes) {
                this.props.enregistre({
                    id: this.props.personne.id,
                    classe: this.props.personne.classe,
                    etatConsentement: true // fausse valeur
                });
            }
		}).catch(err => {
			console.log(err);
		});
    }

    getStructure() {
        let dataService = new DataService();

        if (this.props.personne.classe == "Beneficiaire") {
            return dataService.getStructureById(this.props.personne.structure);
        }
        if (this.props.personne.classe == "Proche") {
            let b = dataService.getBeneficiaireById(this.props.personne.beneficiaire);
            if (b) {
                return dataService.getStructureById(b.structure);
            }
        }
        return null
    }

    render() {

        let calendarService = new CalendarService();
        let dataService = new DataService();

        let modals = [];

        let questionsDeclenchees = [];
        if (this.getBibliotheque(this.props.personne.bibliotheque)) {
            this.getBibliotheque(this.props.personne.bibliotheque).questions.map((question, index) => {			
                if (question.repetition.type == "none") {
                    questionsDeclenchees.push(question);
                }
            });
        }        

        let securityService = new SecurityService()        
        let autresPersonnes = [];
        let structure = this.getStructure();
        //let allBeneficiaires = this.props.conseiller ? this.props.conseiller.beneficiaires : (structure ? structure.beneficiaires : []);
        let allBeneficiaires = dataService.getStructureBeneficiaires(structure).filter(beneficiaire => securityService.canViewBeneficiaire(beneficiaire)).map(beneficiaire => beneficiaire.id).concat(
			this.props.conseiller ? this.props.conseiller.beneficiaires : []
		)
		allBeneficiaires = allBeneficiaires.filter((b, iB) => allBeneficiaires.indexOf(b) == iB)
        
        allBeneficiaires.map((b) => {
            let beneficiaire = dataService.getBeneficiaireById(b);
            if (beneficiaire != null) {
                if (this.props.personne.classe == "Beneficiaire" && b != this.props.personne.id && !beneficiaire.supprime) {
                    autresPersonnes.push(
                        <React.Fragment key={"checkbox-copie-question-" + beneficiaire.id}>
                            <div className="checkbox-wrapper mr-2">
                                <input type="checkbox" id={"checkbox-copie-question-" + beneficiaire.id} checked={this.state.autresPersonnes.includes("beneficiaire-" + beneficiaire.id)} onChange={() => this.toggleAutresPersonnes("beneficiaire-" + beneficiaire.id)}/>
                                <label htmlFor={"checkbox-copie-question-" + beneficiaire.id} className="checkbox-remember-me"></label>
                            </div>
                            <label htmlFor={"checkbox-copie-question-" + beneficiaire.id}>{beneficiaire.prenom} {beneficiaire.nom}</label>
                            <br />
                        </React.Fragment>
                    );
                }
            }            
            if (this.props.personne.classe == "Proche") {
                beneficiaire.proches.map(proche => {
                    if (proche.id != this.props.personne.id && !proche.supprime) {
                        autresPersonnes.push(
                            <React.Fragment key={"checkbox-copie-question-" + proche.id}>
                                <div className="checkbox-wrapper mr-2">
                                    <input type="checkbox" id={"checkbox-copie-question-" + proche.id} checked={this.state.autresPersonnes.includes("beneficiaire-" + beneficiaire.id + "-proche-" + proche.id)} onChange={() => this.toggleAutresPersonnes("beneficiaire-" + beneficiaire.id + "-proche-" + proche.id)}/>
                                    <label htmlFor={"checkbox-copie-question-" + proche.id} className="checkbox-remember-me"></label>
                                </div>
                                <label htmlFor={"checkbox-copie-question-" + proche.id}>{proche.prenom} {proche.nom}, proche de {beneficiaire.prenom} {beneficiaire.nom} ({proche.description})</label>
                                <br />
                            </React.Fragment>
                        );
                    }
                });
            }
        });
		
        if (this.getBibliotheque(this.props.personne.bibliotheque)) {
            this.getBibliotheque(this.props.personne.bibliotheque).questions.map((question, index) => {
                modals.push(
                    <div key={"modal-modifquestion-" + question.id} id={"modal-modifquestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Question</h5>
                                    <div className="header-buttons">
                                        <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                        {this.state.editingQuestionDeclenchee === false && <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.modifieQuestion(index)} disabled={!this.formValide()}>Enregistrer</button>}
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <FormQuestion 
                                        question={this.state.editingQuestion}
                                        personne={this.props.personne}
                                        questionsDeclenchees={questionsDeclenchees}
                                        modifie={(question) => this.setEditingQuestion(question)}
                                        numero={(this.getBibliotheque(this.props.personne.bibliotheque).questions.length + 1) * 2}
                                        setEditingQuestionDeclenchee={(editingQuestionDeclenchee) => this.setState({editingQuestionDeclenchee})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                modals.push(
                    <div key={"modal-suppressionquestion-" + question.id} id={"modal-suppressionquestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Supprimer une question</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>La question <b>{question.libelleCalcule}</b> sera désactivée et supprimée. Les précédentes réponses restent <b>enregistrées et prises en compte</b>. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestion(index)} data-dismiss="modal">Supprimer la question</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                modals.push(
                    <div key={"modal-copiequestion-" + question.id} id={"modal-copiequestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Copier une question</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    {this.state.etatCopieQuestions !== 'termine' ?
                                        <>
                                            <p>Copier la question <b>{question.libelle}</b> vers :</p>
                                            {autresPersonnes}   
                                        </>
                                    :
                                        <>
                                            <b>La copie a bien été enregistrée</b>. Les effets seront visibiles dans quelques instants... Il sera nécessaire de rafraîchir la page de votre navigateur. 
                                        </>
                                    }                                    
                                </div>
                                <div className="modal-footer">
                                    {this.state.etatCopieQuestions !== 'termine' && <button type="button" className="btn btn-primary" disabled={this.state.autresPersonnes.length == 0 || this.state.etatCopieQuestions === 'encours'} onClick={() => this.copieQuestion(question)}>Copier la question</button>}
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">{this.state.etatCopieQuestions === 'termine' ? 'Fermer' : 'Annuler'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }        

        let optionsNbNoReponseAlerte = [];
        for (let i = 1 ; i <= 20 ; i++) {
            optionsNbNoReponseAlerte.push(
                <option key={"option-nbNoReponseAlerte-" + i} value={i}>{i}</option>
            );
        }


        let joursDeclenchementQuestionsAutomatiques = [];
        for (let i = 1 ; i <= 7 ; i++) {
            joursDeclenchementQuestionsAutomatiques.push(
                <span key={"btn-jourDeclenchementQuestionsAutomatiques-" + (i % 7)} className={"jour" + (this.state.personne.declenchementQuestionsAutomatiques.jours.includes((i % 7)) ? " actif" : "")} onClick={() => this.toggleJourDeclenchementQuestionsAutomatiques((i % 7))}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
            );
        }

        let joursDeclenchementQuestionsAleatoires = [];
        for (let i = 1 ; i <= 7 ; i++) {
            joursDeclenchementQuestionsAleatoires.push(
                <span key={"btn-jourDeclenchementQuestionsAleatoires-" + (i % 7)} className={"jour" + (this.state.personne.declenchementQuestionsAleatoires.jours.includes((i % 7)) ? " actif" : "")} onClick={() => this.toggleJourDeclenchementQuestionsAleatoires((i % 7))}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
            );
        }

        let optionsHeuresDeclenchement = [];
        for (let i = 7 ; i <= 21 ; i++) {
            let heure = ("0" + i).substr(-2);
            optionsHeuresDeclenchement.push(
                <option key={"option-heuresDeclenchement-" + i} value={heure}>{heure}</option>
            )
        }

        let optionsMinutesDeclenchement = [];
        for (let i = 0 ; i < 60 ; i+=15) {
            let minute = ("0" + i).substr(-2);
            optionsMinutesDeclenchement.push(
                <option key={"option-heuresDeclenchement-" + i} value={minute}>{minute}</option>
            )
        }

        let boutonQuestionsEnPause = <a href="#" className="btn btn-annexe bg-secondary float-right" data-toggle="modal" data-target="#modal-init-questions"><i className="mdi mdi-play-circle-outline"></i> Activer les questions auto.</a>
        if (this.props.personne.initQuestions) {
            if (this.props.personne.pauseQuestions) {
                boutonQuestionsEnPause = <a href="#" className="btn btn-annexe bg-secondary float-right" data-toggle="modal" data-target="#modal-fin-pause"><i className="mdi mdi-play-circle-outline"></i> Réactiver les questions auto.</a>
            }
            else {
                boutonQuestionsEnPause = <a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-pause"><i className="mdi mdi-pause-circle-outline"></i> Mettre en pause les questions auto.</a>
            }
            if (this.props.personne.classe == "Proche") {
                if (this.props.personne.dateConsentement == null && this.props.personne.dateDemandeConsentement != null) {
                    boutonQuestionsEnPause = <a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-annule-questions"><i className="mdi mdi-play-circle-outline"></i> Annuler l'activation des questions auto.</a>
                }
            }            
        }

        let questionsEmployeurs = {
            actives: false,
            employeur: null
        }
        if ((this.state.changementBibliotheque || this.state.pauseQuestions) && this.props.personne.classe == "Beneficiaire") {
            this.props.personne.emplois.map(emploi => {
                emploi.proches.filter(proche => proche.description == "superieur").map(proche => {
                    let employeur = proche
                    let actives = false
                    if (proche.bibliotheque && proche.initQuestions && proche.pauseQuestions == null) {
                        actives = true                            
                    }
                    questionsEmployeurs = {
                        actives: actives,
                        employeur: employeur
                    }
                })
            })
            if (questionsEmployeurs.actives === false && questionsEmployeurs.employeur === null) {
                this.props.personne.proches.filter(proche => proche.description == "superieur").map(proche => {
                    let employeur = proche
                    let actives = false
                    if (proche.bibliotheque && proche.initQuestions && proche.pauseQuestions == null) {
                        actives = true                            
                    }
                    questionsEmployeurs = {
                        actives: actives,
                        employeur: employeur
                    }
                })
            }            
        }

        console.log(this.props.personne)

        let needsConsentement = this.props.personne.classe == "Proche" || (structure && structure.domaine !== "prevention")

        return (
            <>
                <br clear="both" />
                <h3>
                    {this.props.personne.prenom} {this.props.personne.nom}                    
                </h3>
                {needsConsentement &&
                    <>
                        <h5>Consentement : {this.props.personne.etatConsentement ? {accepte: 'Accepté', refuse: 'Refusé', 'etre-rappele': 'Souhaite être rappelé'}[this.props.personne.etatConsentement] + (this.props.personne.dateConsentement ? (" (" + moment(this.props.personne.dateConsentement, "YYYY/MM/DD HH:mm:ss").format("DD/MM/YYYY") + ")") : "") : this.props.personne.dateDemandeConsentement ? ("En attente (depuis le " + moment(this.props.personne.dateDemandeConsentement, "YYYY/MM/DD HH:mm:ss").format('DD/MM/YYYY') + ")") : "Non demandé"}</h5>
                        {this.props.personne.etatConsentement != "accepte" &&
                            <>
                                <div style={{marginBottom: 10}}>
                                    &gt; Les questions ne peuvent pas être envoyées
                                    {this.props.personne.etatConsentement == null &&
                                        <>
                                            {!this.props.personne.dateDemandeConsentement &&
                                                <> (le consentement sera demandé lors de l'activation des questions auto.)</>
                                            }    
                                        </>                                        
                                    }
                                    {(['refuse', 'etre-rappele'].includes(this.props.personne.etatConsentement) || (this.props.personne.dateDemandeConsentement)) && // && moment(this.props.personne.dateDemandeConsentement, "YYYY/MM/DD HH:mm:ss").add(7, 'days').format('YYYYMMDD') <= moment().format('YYYYMMDD')
                                        <> (<a href="#" data-toggle="modal" data-target="#modal-personneconsentement">cliquez ici pour envoyer un nouvelle demande de consentement</a>)</>                                    
                                    }                                                                    
                                </div>                                
                            </>
                        }                        
                    </>
                }                
                {/*<>{this.props.personne.classe == "Proche" && this.props.personne.questions.length > 0 && <a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-repetitionquestions"><i className="mdi mdi-calendar-clock"></i> Récurrence</a>}</>*/}                
                <a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-reglages"><i className="mdi mdi-settings"></i> Réglages</a>
                {this.props.personne.classe == "Proche" &&
                    <a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-historique"><i className="mdi mdi-history"></i> Voir les précédentes réponses</a>
                }
                {boutonQuestionsEnPause}                
                {/*{this.props.personne.classe == "Beneficiaire" &&
                    <a href="#" data-toggle="modal" data-target="#modal-nouvellequestion" className="m-b-20 btn btn-primary float-right"><i className="mdi mdi-plus"></i> Nouvelle question</a>
                }*/}
                <br clear="both" />
                <br clear="both" />
                {/*<a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-changebibliotheque"><i className={"mdi " + (this.getBibliotheque(this.state.personne.bibliotheque) ? "mdi-autorenew" : "mdi-plus")}></i> {this.getBibliotheque(this.state.personne.bibliotheque) ? "Changer de bibliothèque" : "Ajouter une bibliothèque"}</a>*/}
                <a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-banquequestions"><i className="mdi mdi-playlist-plus"></i> Banque de questions</a>
                {/*<h3 style={{fontSize: "20px"}}>Bibliothèque en cours : {this.getBibliotheque(this.state.personne.bibliotheque) ? this.getBibliotheque(this.state.personne.bibliotheque).nom : "Aucune"}</h3>*/}
                <br clear="both" />
                {this.getBibliotheque(this.state.personne.bibliotheque) &&
                    <>
                        <div className="table-responsive">
                            <table className="table table-vertical" id="ordre">
                                <thead>
                                    <tr>
                                        <th>
                                            {this.getBibliotheque(this.state.personne.bibliotheque) && this.getBibliotheque(this.state.personne.bibliotheque).questions.length > 0 &&
                                                <div className={"checkbox-wrapper" + (this.state.questionsSelectionnees.length != this.getBibliotheque(this.state.personne.bibliotheque).questions.length ? " selection" : "")}>
                                                    <input type="checkbox" id="toggle-questions-all" checked={this.state.questionsSelectionnees.length > 0} onChange={() => this.toggleAllQuestions()} />
                                                    <label htmlFor="toggle-questions-all" className="toggle"></label>
                                                </div>
                                            }
                                        </th>
                                        {this.state.questionsSelectionnees.length == 0 &&
                                            <>
                                                <th>Libellé</th>
                                                <th>Déclenchement</th>
                                                <th>Actions</th>
                                                <th></th>
                                            </>
                                        }
                                        {this.state.questionsSelectionnees.length > 0 &&
                                            <th colSpan="4">
                                                <a href="#" data-toggle="modal" data-target="#modal-copiequestions" className="btn btn-annexe contour m-b-20"><i className="mdi mdi-content-duplicate"></i> Copier vers une autre personne</a>
                                                <a href="#" data-toggle="modal" data-target="#modal-suppressionquestions" className="btn btn-annexe contour m-b-20"><i className="dripicons-trash"></i> Supprimer</a>
                                            </th>
                                        }
                                    </tr>
                                </thead>
                                <SortableQuestions
                                    shouldCancelStart={(e) => {
                                        return !e.target.classList.contains("dripicons-move");
                                    }}
                                    onSortEnd={({oldIndex, newIndex, collection, isKeySorting}, e) => {
                                        this.reordonneQuestions(oldIndex, newIndex);
                                    }}
                                    helperContainer={() => {
                                        return document.getElementById("tbody-questions");                                
                                    }}
                                    updateBeforeSortStart={() => {
                                        console.log("updatebeforesart");
                                    }}
                                    items={this.getBibliotheque(this.state.personne.bibliotheque).questions.filter(q => q.autreType == null).sort((a , b) => (a.numero < b.numero) ? -1 : 1)}
                                    questionsSelectionnees={this.state.questionsSelectionnees}
                                    toggleQuestion={(question) => this.toggleQuestion(question)}
                                    setEditingQuestion={(question) => this.setEditingQuestion(question)}
                                    personne={this.props.personne}                                    
                                />
                            </table>
                            <div className="alert alert-warning" role="alert">
                                <i className="mdi mdi-alert-outline"></i> Les modifications et questions ajoutées à la bibliothèque ne s'appliquent qu'à cet utilisateur
                            </div>
                        </div>
                    </>
                }                 
                <div id="modal-nouvellequestion" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Nouvelle {this.state.editingQuestion && this.state.editingQuestion.type == 'information' ? 'information' : 'question'}</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    {this.state.editingQuestionDeclenchee === false && <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.ajouteQuestion()} disabled={!this.formValide()}>Enregistrer</button>}
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormQuestion 
                                    question={this.state.editingQuestion}
                                    personne={this.props.personne}
                                    questionsDeclenchees={questionsDeclenchees}
                                    modifie={(question) => this.setEditingQuestion(question)}
                                    numero={this.getBibliotheque(this.state.personne.bibliotheque) ? (this.getBibliotheque(this.state.personne.bibliotheque).questions.length + 1) * 2 : 2}
                                    setEditingQuestionDeclenchee={(editingQuestionDeclenchee) => this.setState({editingQuestionDeclenchee})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-copiequestions" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Copier des questions</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {this.state.etatCopieQuestions !== 'termine' ?
                                    <>
                                        <p>Copier les questions sélectionnées vers :</p>
                                        {autresPersonnes}
                                    </>
                                :
                                    <>
                                        <b>La copie a bien été enregistrée</b>. Les effets seront visibiles dans quelques instants... Il sera nécessaire de rafraîchir la page de votre navigateur. 
                                    </>
                                }                                  
                            </div>
                            <div className="modal-footer">
                                {this.state.etatCopieQuestions !== 'termine' && <button type="button" className="btn btn-primary" disabled={this.state.autresPersonnes.length == 0 || this.state.etatCopieQuestions === 'encours'} onClick={() => this.copieQuestions()}>Copier les questions</button>}
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">{this.state.etatCopieQuestions === 'termine' ? 'Fermer' : 'Annuler'}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-suppressionquestions" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des questions</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Les questions sélectionnées seront désactivées et supprimées. Les précédentes réponses restent <b>enregistrées et prises en compte</b>. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestions()} data-dismiss="modal">Supprimer les questions</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                {/*<div id="modal-repetitionquestions" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Modifier la récurrence</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal">Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormRepetitionQuestions
                                    repetition={this.state.editingRepetition}
                                    modifie={(repetition) => this.setEditingRepetition(repetition)}
                                />
                            </div>
                        </div>
                    </div>
                            </div>*/}
                <div id="modal-reglages" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Réglages pour les messages</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal" onClick={() => this.resetReglages()}>Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.enregistrePersonne("reglages")}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-8">
                                        <form>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">Texte avant la question</div>
                                                    </div>
                                                    <input type="text" className="form-control" value={this.state.personne.prefixeMessage} onChange={(e) => this.modifiePersonne("prefixeMessage", e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">Texte après la question</div>
                                                    </div>
                                                    <input type="text" className="form-control" value={this.state.personne.suffixeMessage} onChange={(e) => this.modifiePersonne("suffixeMessage", e.target.value)} />
                                                </div>
                                            </div>
                                            {this.props.personne.classe == "Beneficiaire" &&
                                                <>
                                                    <hr />
                                                    <h6>Instructions pour les messages automatiques :</h6>
                                                    <div className="form-group">
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" value="vouvoiement" checked={this.state.personne.modeMessage == "vouvoiement"} onChange={() => this.modifiePersonne("modeMessage", "vouvoiement")} /> Vouvoiement
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="radio">
                                                            <label>
                                                            <input type="radio" value="tutoiement" checked={this.state.personne.modeMessage == "tutoiement"} onChange={() => this.modifiePersonne("modeMessage", "tutoiement")} /> Tutoiement
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            }                                            
                                        </form>
                                    </div>
                                    {this.props.personne.classe == "Beneficiaire" &&
                                        <div className="col-md-4">
                                            <div className="modal-sidebar">
                                                <h5 className="modal-title mt-0">Prévisualisation</h5>
                                                <div className="previsualisation">
                                                    {this.state.personne.prefixeMessage && <>{this.state.personne.prefixeMessage}<br /></>}
                                                    Est-ce que vous allez bien ?<br />
                                                    "Je vais bien" Tapez 1<br />
                                                    "Je ne vais pas bien" Tapez 2<br />
                                                    {this.state.personne.suffixeMessage && <><br />{this.state.personne.suffixeMessage}</>}
                                                </div>
                                                <br clear="both" />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <form>  
                                            {/* 
                                            {this.props.personne.classe == "Proche" &&
                                                <>
                                                    <div className="form-group">                                                   
                                                        <h6>
                                                            Déclenchement des questions automatiques :                                                     
                                                        </h6>
                                                        <div className="input-group">
                                                            {joursDeclenchementQuestionsAutomatiques}
                                                            <span className="heure">à</span>
                                                            <select className="form-control heure" value={this.state.personne.declenchementQuestionsAutomatiques.heure} onChange={(e) => this.modifiePersonne("declenchementQuestionsAutomatiques-heure", e.target.value)}>
                                                                {optionsHeuresDeclenchement}
                                                            </select>
                                                            <span className="heure">h</span>
                                                            <select className="form-control heure" value={this.state.personne.declenchementQuestionsAutomatiques.minute} onChange={(e) => this.modifiePersonne("declenchementQuestionsAutomatiques-minute", e.target.value)}>
                                                                {optionsMinutesDeclenchement}
                                                            </select>
                                                        </div>
                                                    </div>     
                                                    <hr />
                                                </>
                                            }*/}
                                            {this.props.personne.classe == "Beneficiaire" &&
                                                <>
                                                    <div className="form-group">                                                   
                                                        <h6>
                                                            Déclenchement des questions aléatoires :                                                     
                                                        </h6>
                                                        <div className="input-group">
                                                            {joursDeclenchementQuestionsAleatoires}
                                                            <span className="heure">à</span> 
                                                            <select className="form-control heure" value={this.state.personne.declenchementQuestionsAleatoires.heure} onChange={(e) => this.modifiePersonne("declenchementQuestionsAleatoires-heure", e.target.value)}>
                                                                {optionsHeuresDeclenchement}
                                                            </select>
                                                            <span className="heure">h</span>
                                                            <select className="form-control heure" value={this.state.personne.declenchementQuestionsAleatoires.minute} onChange={(e) => this.modifiePersonne("declenchementQuestionsAleatoires-minute", e.target.value)}>
                                                                {optionsMinutesDeclenchement}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <hr />    
                                                </>
                                            }                                     
                                            {this.props.personne.classe == "Beneficiaire" &&
                                                <>
                                                    <div className="form-group">                                                   
                                                        <h6>
                                                            Nombre de questions sans réponse avant alerte : 
                                                            <select className="form-control simple" value={this.state.personne.nbNoReponseAlerte} onChange={(e) => this.modifiePersonne("nbNoReponseAlerte", e.target.value)}>
                                                                {optionsNbNoReponseAlerte}
                                                            </select>
                                                        </h6>
                                                    </div>
                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div id="modal-pause" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Mettre en pause les questions automatiques</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <form>									   									   
                                    <div className="form-group">
                                        <div className="input-group date">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Les questions seront mises en pause jusqu'au :</div>
                                            </div>
                                            {/*<input type="text" className="form-control" placeholder="mm/dd/yyyy" id="datepicker-autoclose" />*/}
                                            <DatePicker
                                                ref={(input) => { this.ref_dateFinPauseQuestions = input; }} 
                                                className="form-control" 
                                                locale="fr"
                                                minDate={new Date()}
                                                selected={this.state.dateFinPauseQuestions}
                                                onChange={(date) => this.setState({ dateFinPauseQuestions: date})}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <div className="input-group-append" onClick={() => this.ref_dateFinPauseQuestions.setFocus(true)}>
                                                <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.desactiveQuestions()} data-dismiss="modal">Mettre en pause les questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-fin-pause" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Réactiver les questions automatiques</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>Les questions automatiques seront réactivées dès maintenant. Voulez-vous continuer ?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.activeQuestions()} data-dismiss="modal">Réactiver les questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-init-questions" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Activer les questions automatiques</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {needsConsentement && this.props.personne.etatConsentement != "accepte" &&
                                    <>
                                        <p>En validant cette fenêtre, une demande va être envoyée à {this.props.personne.prenom} {this.props.personne.nom} par {this.props.personne.classe == 'Proche' ? 'email' : this.props.personne.modalite} pour recueillir son consentement. S'il accepte, les questions seront activées immédiatement et automatiquement. Vous pourrez les mettre en pause ultérieurement. Voulez-vous continuer ?</p>
                                    </>
                                }
                                {!(needsConsentement && this.props.personne.etatConsentement != "accepte") &&
                                    <>
                                        <p>Les questions automatiques seront activées dès maintenant. Vous pourrez les mettre en pause ultérieurement. Voulez-vous continuer ?</p>
                                    </>
                                }
                                                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.activeQuestions()} data-dismiss="modal">Activer les questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-annule-questions" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Annuler l'activation des questions automatiques</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>L'activation des questions automatiques sera annulée dès maintenant. Vous pourrez les réactiver ultérieurement. Voulez-vous continuer ?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.annuleActivationQuestions()} data-dismiss="modal">Annuler l'activation des questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-changebibliotheque" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Changer la bibliothèque active</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">                                
                                {this.props.personne.bibliotheques.length > 1 &&
                                    <>
                                        <p>Choisir parmi les bibliothèques déjà utilisées :</p>
                                        <div className="row">
                                            {this.props.personne.bibliotheques.filter(b => b.id != this.props.personne.bibliotheque).map((bibliotheque, iBibliotheque) => {
                                                return (
                                                    <React.Fragment key={"bibliotheque-" + iBibliotheque}>
                                                        <div className="col-6">
                                                            <button type="button" className={"btn btn-lg btn-block " + (this.state.nouvelleBibliotheque == bibliotheque ? "btn-warning" : "btn-outline-warning")} onClick={() => this.setState({nouvelleBibliotheque: bibliotheque})}>{bibliotheque.nom}</button>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}                                                
                                        </div>                                            
                                    </>
                                }
                                <p>Charger une nouvelle bibliothèque WESIREPORT :</p>
                                <div className="row">
                                    {this.props.bibliotheques.filter(b => b.cible == (this.props.personne.classe == "Beneficiaire" ? "beneficiaire" : "proche-superieur")).map((bibliotheque, iBibliotheque) => {
                                        let existe = false
                                        for (let b of this.props.personne.bibliotheques) {
                                            if (b.bibliotheque == bibliotheque.id) {
                                                existe = true
                                            }
                                        }                                        
                                        return (
                                            <React.Fragment key={"bibliotheque-" + iBibliotheque}>
                                                <div className="col-6 mb-2">
                                                    <button type="button" className={"btn btn-lg btn-block " + (this.state.nouvelleBibliotheque == bibliotheque ? "btn-warning" : "btn-outline-warning")} onClick={() => this.setState({nouvelleBibliotheque: bibliotheque})}>{existe ? "Remplacer la bibliothèque" : "Ajouter la bibliothèque"} {bibliotheque.nom}</button>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}                                                
                                </div>                             
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.changeBibliotheque()} data-dismiss="modal" disabled={this.state.nouvelleBibliotheque == null}>{this.getBibliotheque(this.props.personne.bibliotheque) ? "Changer la bibliothèque" : "Ajouter la bibliothèque"}</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-banquequestions" className="modal fade bs-example-modal-center bs-example-modal-xl" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content" style={{minHeight: '80vh'}}>
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Banque de questions</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-8'>
                                        <form>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <input type="text" className="form-control" placeholder="Filtrer les questions..." value={this.state.filtreQuestionsDefaut.motscles} onChange={(e) => this.setState({filtreQuestionsDefaut: {...this.state.filtreQuestionsDefaut, motscles: e.target.value}})} />
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <select id="filtre-questionsdefaut-communication" className="form-control ml-0" style={{padding: "0px imporant!", width: '100%'}} value={this.state.filtreQuestionsDefaut.communication} onChange={(e) => {this.state.filtreQuestionsDefaut.communication = e.target.value; this.setState({filtreQuestionDefaut: this.state.filtreQuestionDefaut})}}>
                                                            <option value=''>Tutoiement ou vouvoiement</option>
                                                            <option value='tutoiement'>Tutoiement</option>
                                                            <option value='vouvoiement'>Vouvoiement</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div id="accordion">
                                            {/*{this.props.data.categoriesQuestions.filter(categorie => this.state.questionsDefaut.filter(question => question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question)).length > 0).length == 0 &&
                                                <>
                                                    Aucune question.
                                                </>
                                            } */}  
                                            {this.state.questionsDefaut.length === 0 &&
                                                <>
                                                    Aucune question.
                                                </>
                                            }
                                            {this.props.data.categoriesQuestions
                                            .filter(categorie => this.state.questionsDefaut.filter(question => question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question)).length > 0)
                                            .concat(this.state.questionsDefaut.filter(question => question.categories.length === 0 && this.filtreQuestionDefaut(question)).length > 0 ? {
                                                id: -1,
                                                nom: 'Autre'
                                            } : null)
                                            .filter(categorie => categorie != null)
                                            .map((categorie, iCategorie) => {
                                                return (
                                                    <React.Fragment key={"categorie-" + iCategorie}>
                                                        <div className="card shadow-none border mb-1">
                                                            <div className="card-header p-3" id={"heading-categorie" + categorie.id}>
                                                                <h5 className="m-0 font-14" style={{marginBottom: 0}}>
                                                                    <a href={"#collapse-categorie" + categorie.id} className="text-dark" data-toggle="collapse" aria-expanded="false" aria-controls={"collapse-categorie" + categorie.id}>
                                                                        {categorie.nom} <small>({this.state.questionsDefaut.filter(question => (categorie.id === -1 && question.categories.length === 0) || (question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question))).length} question{this.state.questionsDefaut.filter(question => (categorie.id === -1 && question.categories.length === 0) || (question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question))).length > 1 ? 's' : ''})</small>
                                                                    </a>
                                                                </h5>                                                        
                                                            </div>                                                    
                                                            <div id={"collapse-categorie" + categorie.id} className="collapse" aria-labelledby={"heading-categorie" + categorie.id} data-parent="#accordion">
                                                                <div className="card-body">                                            
                                                                    <div className="table-responsive">
                                                                        <table className="table table-vertical" id="ordre">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>                                                                            
                                                                                    <th>Libellé</th>
                                                                                    {/*<th>Type</th>*/}
                                                                                    <th>Réponses</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.questionsDefaut
                                                                                .filter(question => (categorie.id === -1 && question.categories.length === 0) || (question.categories.includes(categorie.id)))
                                                                                .filter(question => this.filtreQuestionDefaut(question))
                                                                                .map((question, iQuestion) => {
                                                                                    return (
                                                                                        <React.Fragment key={"question-" + iQuestion}>
                                                                                            <tr className={(this.state.questionsDefautSelectionnees.includes(question.id) ? "table-warning" : "")}>
                                                                                                <td>
                                                                                                    <div className="checkbox-wrapper">
                                                                                                        <input type="checkbox" id={"toggle-questiondefaut-" + question.id} onChange={() => this.toggleQuestionDefautSelectionnee(question)} checked={this.state.questionsDefautSelectionnees.includes(question.id)} />
                                                                                                        <label htmlFor={"toggle-questiondefaut-" + question.id} className="toggle"></label>
                                                                                                    </div>
                                                                                                </td>                                                                                    
                                                                                                <td>{question.libelleCalcule}</td>
                                                                                                {/*<td>{{liste: 'Liste', information: 'Information', gradient: 'Gradient'}[question.type]} {question.type === 'gradient' &&
                                                                                                    <>de {question.parametres.gradient.min} à {question.parametres.gradient.max}</>
                                                                                                }</td>*/}
                                                                                                <td>
                                                                                                    {question.type != 'information' &&
                                                                                                        <>
                                                                                                            {question.reponses.sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, iReponse) => {
                                                                                                                console.log(reponse)
                                                                                                                return (
                                                                                                                    <React.Fragment key={"reponse-" + iReponse}>
                                                                                                                        {question.type === 'liste' ? <>{reponse.libelle}</> : <>{reponse.cle.code}</>}: {reponse.score === 0 ? <>
                                                                                                                            Ne fait pas avancer le compteur
                                                                                                                        </> : <>
                                                                                                                            Fait {reponse.score > 0 ? 'avancer' : 'reculer'} de {reponse.score} {Math.abs(reponse.score) > 1 ? 'signaux faibles' : 'signal faible'}
                                                                                                                        </>}
                                                                                                                        {reponse.action === 'question' && reponse.questionDeclenchee &&
                                                                                                                            <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">{reponse.questionDeclenchee.libelleCalcule}</span></>
                                                                                                                        }
                                                                                                                        {reponse.action === 'question-etre-rappele' &&
                                                                                                                            <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">Souhaitez-vous être rappelé(e) ?</span></>
                                                                                                                        }
                                                                                                                        <br />
                                                                                                                    </React.Fragment>
                                                                                                                )
                                                                                                            })}
                                                                                                        </>
                                                                                                    }
                                                                                                    
                                                                                                </td>
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}                                        
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="modal-sidebar">
                                            <h5 className="modal-title mt-0">Sélection</h5>
                                            <div id="accordion-questionsdefaut">
                                                {this.state.questionsDefautSelectionnees.map((q, iQ) => {
                                                    let question = this.state.questionsDefaut.filter(question => question.id === q)
                                                    if (question.length === 0) return null
                                                    question = question[0]
                                                    return (
                                                        <React.Fragment key={"question-" + q}>
                                                            <div className="card shadow-none border mb-1">
                                                                <div className="card-header p-3" id={"heading-questiondefaut" + question.id}>
                                                                    <div className="checkbox-wrapper">
                                                                        <input type="checkbox" id={"toggle-questiondefaut-" + question.id} onChange={() => this.toggleQuestionDefautSelectionnee(question)} checked={this.state.questionsDefautSelectionnees.includes(question.id)} />
                                                                        <label htmlFor={"toggle-questiondefaut-" + question.id} className="toggle"> </label>
                                                                    </div> <a href={"#collapse-questiondefaut" + question.id} data-toggle="collapse" aria-expanded="false" aria-controls={"collapse-questiondefaut" + question.id}>{question.libelle}</a>
                                                                </div>                                                    
                                                                <div id={"collapse-questiondefaut" + question.id} className="collapse" aria-labelledby={"heading-questiondefaut" + question.id} data-parent="#accordion-questionsdefaut">
                                                                    <div className="card-body">
                                                                        {/*<p className='p-0'>
                                                                            {{liste: 'Liste', information: 'Information', gradient: 'Gradient'}[question.type]} {question.type === 'gradient' &&
                                                                                <>de {question.parametres.gradient.min} à {question.parametres.gradient.max}</>
                                                                            }
                                                                        </p>*/}
                                                                        <p className='p-0'>
                                                                            {question.type != 'information' &&
                                                                                <>
                                                                                    {question.reponses.sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, iReponse) => {
                                                                                        console.log(reponse)
                                                                                        return (
                                                                                            <React.Fragment key={"reponse-" + iReponse}>
                                                                                                {question.type === 'liste' ? <>{reponse.libelle}</> : <>{reponse.cle.code}</>}: {reponse.score === 0 ? <>
                                                                                                    Ne fait pas avancer le compteur
                                                                                                </> : <>
                                                                                                    Fait {reponse.score > 0 ? 'avancer' : 'reculer'} de {reponse.score} {Math.abs(reponse.score) > 1 ? 'signaux faibles' : 'signal faible'}
                                                                                                </>}
                                                                                                {reponse.action === 'question' && reponse.questionDeclenchee &&
                                                                                                    <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">{reponse.questionDeclenchee.libelleCalcule}</span></>
                                                                                                }
                                                                                                {reponse.action === 'question-etre-rappele' &&
                                                                                                    <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">Souhaitez-vous être rappelé(e) ?</span></>
                                                                                                }
                                                                                                <br />
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>                                                           
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.ajouteQuestionsDefaut()} data-dismiss="modal" disabled={this.state.questionsDefautSelectionnees.length === 0}>Ajouter les questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-historique" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Les précédentes réponses</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body"> 
                                {this.props.personne.classe == "Proche" &&
                                    <>
                                        {this.props.personne.historique.length > 0 &&
                                            <>
                                                <select className="form-control" value={this.state.historique ? this.state.historique.date : ""} onChange={(e) => this.getHistorique(e.target.value)}>
                                                    {!this.state.historique || !this.state.historique.date &&
                                                        <option value="">Choisir une date...</option>    
                                                    }
                                                    {this.props.personne.historique.map((historique, iHistorique) => {
                                                        return <option key={iHistorique} value={historique.date}>{moment(historique.date).format("DD-MM-YYYY")}</option>
                                                    })}
                                                </select>                   
                                                <div>
                                                    {this.state.historiqueLoading &&
                                                        <p>Veuillez patienter...</p>
                                                    }
                                                    {!this.state.historiqueLoading && this.state.historique &&
                                                        <>
                                                            {this.state.historique.instances.map((instance, iInstance) => {
                                                                return (
                                                                    <React.Fragment key={"instance-" + iInstance}>
                                                                        <p>
                                                                            {instance.libelle}<br /><i className="mdi mdi-arrow-right-bold"></i> <b>{instance.reponses.join(", ")}</b>
                                                                        </p>
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                            {this.state.historique.instances.length == 0 &&
                                                                <p>Les questions sont en attente de réponse.</p>
                                                            }
                                                        </>
                                                    }                                            
                                                </div>
                                            </>
                                        }
                                        {this.props.personne.historique.length == 0 &&
                                            <p>Aucune précédente réponse.</p>
                                        }
                                    </>
                                }                                                               
                            </div>
                            <div className="modal-footer">                                
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.changementBibliotheque &&                    
                    <div id={"modal-avertissementchangementbibliotheque"} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Changement de bibliothèque</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>Vous venez de changer de bibliothèque de questions pour <b>{this.props.personne.prenom} {this.props.personne.nom}</b>, les questions à l'employeur sont actuellement {questionsEmployeurs.actives ? "actives" : "inactives"}, pensez à vérifier si vous devez faire évoluer ce paramétrage également.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.props.changePersonneCourante(questionsEmployeurs.employeur.id)} data-dismiss="modal">Paramétrer les questions employeurs</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                } 
                {this.state.pauseQuestions &&                    
                    <div id={"modal-avertissementpausequestions"} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Mise en pause des questions</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>Vous venez de mettre en pause les questions automatiques pour <b>{this.props.personne.prenom} {this.props.personne.nom}</b>, les questions à l'employeur sont actuellement {questionsEmployeurs.actives ? "actives" : "inactives"}, pensez à vérifier si vous devez faire évoluer ce paramétrage également.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.props.changePersonneCourante(questionsEmployeurs.employeur.id)} data-dismiss="modal">Paramétrer les questions employeurs</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                } 
                <div id="modal-personneconsentement" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Demander le consentement</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>Un nouvelle demande de consentement va être envoyée à <b>{this.props.personne.prenom} {this.props.personne.nom}</b>. Voulez-vous continuer ?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.envoieEmailConsentement()} data-dismiss="modal">Envoyer</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>               
                {modals}                                                  					   
            </>
        )
	}
}
  

export default connect(mapStateToProps, null)(TabQuestionsPersonne);