import React, { Component } from 'react';

import Api from '../../api';

class FormBibliotheque extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.erreurs = []; 
    }

    initFromProps() {
        console.log("init", this.props.bibliotheque);
        let bibliotheque = this.props.bibliotheque ? JSON.parse(JSON.stringify(this.props.bibliotheque)) : {
            id: null,
            classe: "BibliothequeDefaut",
            nom: "",            
            cible: "beneficiaire"
        };

        return {
            bibliotheque: bibliotheque
        };
    }

    componentDidMount() {
        console.log("formbibliotheque did mount");
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.bibliotheque != this.props.bibliotheque) {
            this.setState(this.initFromProps());
            if (!this.props.bibliotheque) {
                this.setState({erreurs: []});
            }
        }
    }

    modifieBibliotheque(champ, val) {
        let bibliotheque = this.state.bibliotheque;
        if (champ == "nom") {
            bibliotheque.nom = val;
        }
        if (champ == "cible") {
            bibliotheque.cible = val
        }
        
        this.setState({
            bibliotheque: bibliotheque
        });
        if (this.props.modifie) {
            this.props.modifie(bibliotheque);
        }
    }

    valideChamp(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
        if (champ == "nom") {
            if (this.state.bibliotheque.nom == "") {
                erreurs.push(champ);
            }
        }
        this.setState({
            erreurs: erreurs
        });
    }    

    render() {

        
        console.log(this.state.erreurs);

        return (
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('nom') ? " input-error" : "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Nom de la bibliothèque *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.bibliotheque.nom} onChange={(e) => this.modifieBibliotheque("nom", e.target.value)} onBlur={() => this.valideChamp("nom")} />
                                </div>
                            </div>
                        </div>                        
                        <div className="form-group row">
                            <label className="col-form-label ml-3">La bibliothèque d'adresse à :</label>
                            <div className="">
                                <select className="form-control" value={this.state.bibliotheque.cible} onChange={(e) => this.modifieBibliotheque("cible", e.target.value)}>
                                    <option value="beneficiaire">Utilisateur</option>
                                    <option value="proche-superieur">Supérieur hiérarchique</option>                                                                                    
                                </select>
                            </div>
                        </div>                         
                        <br clear="both" />
                    </form>
                </div>
            </div>
        );
	}
}

export default FormBibliotheque;