const initialState = { 
    urlCible: "/",
    history: [],
    accueilVueBeneficiaires: "grille",
    accueilTriBeneficiaires: "nom_a-z",
    beneficiaireTab: "synthese",
    structureTab: "beneficiaires"
  }

function ihmReducer(state = initialState, action) {
  /*let nextState;
  nextState = {
    ...state
  };*/
  let nextState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'IHM_URLCIBLE':
      nextState.urlCible = action.payload;
      return JSON.parse(JSON.stringify(nextState));
    case 'IHM_ACCUEIL_VUE':
      nextState.accueilVueBeneficiaires = action.payload;
      return JSON.parse(JSON.stringify(nextState));
    case 'IHM_ACCUEIL_TRI':
      nextState.accueilTriBeneficiaires = action.payload;
      return JSON.parse(JSON.stringify(nextState));
    case 'IHM_BENEFICIAIRE_TAB':
        nextState.beneficiaireTab = action.payload;
        return JSON.parse(JSON.stringify(nextState));
    case 'IHM_STRUCTURE_TAB':
        nextState.structureTab = action.payload;
        return JSON.parse(JSON.stringify(nextState));
    case 'IHM_HISTORY_PUSH':
        if (action.payload.key) {
          let iLocation = null;
          let iPathname = null;
          nextState.history.map((location, index) => {
            if (location.key == action.payload.key) {
              iLocation = index;
            }
            if (location.pathname == action.payload.pathname) {
              iPathname = index;
            }
          })
          if (iLocation !== null) {
            nextState.history.splice(iLocation, nextState.history.length);
          }
          else {
            if (iPathname !== null) {
              nextState.history.splice(iPathname, nextState.history.length);
            }
            nextState.history.push(action.payload);
          }
        }
        return JSON.parse(JSON.stringify(nextState));
    default:
      return nextState
  }
}

export default ihmReducer