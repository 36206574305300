import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class CGU extends Component {


	componentDidMount() {
		console.log("cgu did mount");

		document.getElementById('page-login').classList.add('cgu');
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						<h3 className="text-muted font-18 m-b-5 text-center">Condition générales d'utilisation</h3>
						<p className="p-3 text-center">
							En vigueur au 12/01/2024
						</p>
						<a href="#1"><h5 className="px-3 py-1">Article 1 : Accès au site</h5></a>
						<a href="#2"><h5 className="px-3 py-1">Article 2 : Propriété intellectuelle</h5></a>
						<a href="#3"><h5 className="px-3 py-1">Article 3 : Responsabilités</h5></a>
						<a href="#4"><h5 className="px-3 py-1">Article 4 : Liens hypertextes</h5></a>
						<a href="#5"><h5 className="px-3 py-1">Article 5 : Politique de confidentialité</h5></a>
						<a href="#6"><h5 className="px-3 py-1">Article 6 : Cookies</h5></a>
						<a href="#7"><h5 className="px-3 py-1">Article 7 : Droit applicable et juridiction compétente</h5></a>

						<hr />
						
						<p className="px-3 text-justify">
							Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site app.wesireport.com et de définir les conditions d’accès et d’utilisation.<br /><br />
							Toute validation de profil implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’Accompagnant ou le Référent et/ou Responsable. Lors de l'inscription sur la plateforme via la validation du mail de création de mot de passe, chaque Accompagnant ou Référent et/ou Responsable accepte expressément les présentes CGU en cochant la case précédant le texte suivant : <b>« J'accepte les Conditions Générales d'Utilisation de la plateforme WESIREPORT»</b>.<br /><br />
							En cas de non-acceptation des CGU stipulées dans le présent contrat, l’Accompagnant ou le Référent et/ou Responsable se doit de renoncer à l'accès aux services proposés par l’application.<br /><br />
							Wesireport, éditeur de app.wesireport.com se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
						</p>
						{/*<h5 className="p-3">Article 1 : Les mentions légales</h5>
						<p className="mt-3 p-3 text-justify">
							L'édition du site https://app.wesireport.com/ est assurée par : <br /><br />
							la Société par Action Simplifiée Wesireport au capital de 10000 euros, dont le siège social est situé au 82 Route de Jean Roux 33700 Salles.<br /><br />
							Immatriculation au RCS 841 368 822 RCS Bordeaux.<br /><br />
							Adresse e-mail : contact@wesireport.com.<br /><br />
							Les Directeurs de la publication sont  : Christophe Guérin et Christophe Lécuyer<br /><br />
							L'hébergeur du site https://app.wesireport.com/ est la société SAS OVH, dont le siège social est situé au 2 rue Kellermann BP 80157 59053 Roubaix Cedex 1.
						</p>*/}
						<h5 id="1" className="p-3">Article 1 : Accès au site</h5>
						<p className="px-3 text-justify">
							Le site app.wesireport.com permet à l’Accompagnant ou au Référent et/ou Responsable un accès aux services suivants :<br /><br />							
							- Le paramétrage et l’envoi de questions de détection de signaux faibles à destination d'Utilisateurs du service.<br />
							- La réception d’une alerte lorsque le cumul de signaux faibles pourrait représenter un risque de rupture pour l’Utilisateur du service.<br />
							L’application est accessible en tout lieu à tout Accompagnant ou Référent et/ou Responsable ayant un accès à Internet. Tous les frais supportés par l’Accompagnant ou Référent et/ou Responsable pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à la charge de sa structure. L’application s’utilise sur une taille  d’écran d’ordinateur de bureau ou tablette.<br /><br />
							Tous les frais supportés par l'Utilisateur du service (la personne suivie) pour accéder au service (matériel, sms, etc.) sont à sa charge.
							L’Accompagnant ou Référent non membre n'a pas accès aux services. Pour cela, le Responsable de structure doit créer son profil et envoyer un mail de création de mot de passe et d’activation de compte. Le compte du Responsable est créé par le super-admin de Wesireport.<br /><br />
							Pour accéder aux services, l’Accompagnant ou Référent et/ou Responsable doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qu’il définit à son inscription.<br /><br />
							Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de app.wesireport.com. Dans ces cas, l’Accompagnant ou Référent et/ou Responsable  accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.<br /><br />
							L'Accompagnant ou Référent et/ou Responsable a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué dans les mentions légales.
						</p>
						{/*<h5 className="p-3">Article 3 : Collecte des données</h5>
						<p className="mt-3 p-3 text-justify">
							Le site assure à l'Accompagnateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. <br /><br />
							En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Accompagnateur exerce ce droit :<br /><br />
							- par mail à l'adresse mail contact@wesireport.com

						</p>*/}
						<h5 id="2" className="p-3">Article 2 : Propriété intellectuelle</h5>
						<p className="px-3 text-justify">
							Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.<br /><br />
							L'Accompagnant ou Référent et/ou Responsable doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.<br /><br />
							Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.<br /><br />
							Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Accompagnant ou Référent et/ou Responsable qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
						</p>
						<h5 id="3" className="p-3">Article 3 : Responsabilités</h5>
						<p className="px-3 text-justify">
							<b>Wesireport :</b><br /><br />
							Les sources des informations diffusées sur le site app.wesireport.com sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.<br /><br />
							Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site app.wesireport.com ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.<br /><br />
							L’Accompagnant ou Référent et/ou Responsable s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité.<br /><br />
							Le site app.wesireport.com ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.<br /><br />
							La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.<br /><br />
							<b>L’Accompagnant et/ou Responsable  :</b><br /><br />
							En acceptant les CGU l’Accompagnant et/ou Responsable  s’engage à avoir une utilisation fiable et sincère de l’application :<br /><br />
							- L’accompagnant et/ou Responsable s’engage à tout mettre en œuvre pour transmettre des informations fiables et vérifiées lors de sa communication avec Wesireport.<br />
							- Toutes les questions ou consignation d’informations relatives à une donnée de santé sont rigoureusement interdites.
							- Le contenu des écrits rentrent dans le cadre de la déontologie sur les écrits.							
						</p>
						<h5 id="4" className="p-3">Article 4 : Liens hypertextes</h5>
						<p className="px-3 text-justify">
							Des liens hypertextes peuvent être présents sur le site. L’Accompagnant ou Référent et/ou Responsable est informé qu’en cliquant sur ces liens, il sortira du site. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
						</p>
						<h5 id="5" className="p-3">Article 5 : Politique de confidentialité</h5>
						<p className="px-3 text-justify">
							Pour tout sujet se rapportant à la protection des données à caractère personnel, veuillez prendre connaissance de la rubrique <b><Link to="/politique-de-confidentialite">« politique de confidentialité »</Link></b>.
						</p>
						<h5 id="6" className="p-3">Article 6 : Cookies</h5>
						<p className="px-3 text-justify">
							Pour tout sujet se rapportant aux cookies, veuillez prendre connaissance de la rubrique <b><Link to="/cookies">« cookies »</Link></b>.
						</p>
						{/*<h5 className="p-3">Article 7 : Cookies</h5>
						<p className="mt-3 p-3 text-justify">
							L’ Accompagnant est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.<br /><br />
							Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site https://app.wesireport.com/. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.<br /><br />
							L’information contenue dans les cookies est utilisée pour améliorer le site https://app.wesireport.com/.<br /><br />
							En naviguant sur le site, L’ Accompagnant les accepte.<br /><br />
							L’Accompagnant pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
						</p>*/}
						<h5 id="7" className="p-3">Article 7 : Droit applicable et juridiction compétente</h5>
						<p className="px-3 text-justify">
							La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître. Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites dans les mentions légales.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default CGU;