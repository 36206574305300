import React, { Component } from 'react';
import { connect } from 'react-redux';

import logoD from '../assets/images/logo-d.png';
import logoS from '../assets/images/logo-s.png';

import WrapperMobile from '../components/WrapperMobile';

import CalendarService from '../services/calendar';
import SecurityService from '../services/security';
import DataService from '../services/data';


import {
	BrowserRouter as Router,
	Route,
    NavLink,
    Link
  } from 'react-router-dom';



const mapStateToProps = (state, props) => {
    return {
	  data: state.data,
      ihm: state.ihm,
      history: props.route.history
	}
  }

class LayoutConseiller extends Component {


    componentDidMount() {
        document.body.classList.remove("bg-login");

        window.jQuery(window).on('popstate', function() { 
            window.jQuery(".modal-backdrop.show").hide();
        });

        window.jQuery("#dropdown-notifications").on('hidden.bs.dropdown', () => {
            let notifications = [];
            this.props.data.notifications.filter((notification) => notification.dateLecture == null).map((notification) => {
                notification.lu = true;
                notifications.push({
                    id: notification.id,
                    classe: notification.classe,
                    lu: true
                });
            });
            if (notifications.length > 0) {
                this.props.dispatch({ type: "DATA_POST_NOTIFICATIONS", payload: {notifications: notifications} });
            }
        });

        let securityService = new SecurityService()
        let items = [];
        this.props.data.beneficiaires.filter(beneficiaire => !beneficiaire.supprime && securityService.canViewBeneficiaire(beneficiaire)).map((beneficiaire) => {
            items.push(
                {id:beneficiaire.id, name:(beneficiaire.prenom + " " + beneficiaire.nom)}
            )
        });
        window.jQuery("#input-recherche").typeahead({
            source: items,
            items: 5,
            afterSelect: (s) => {
                this.props.history.push('/utilisateurs/' + s.id)
            }
        });

        window.jQuery("[data-toggle='tooltip']").tooltip("dispose");
        window.jQuery("[data-toggle='tooltip']").tooltip({
            trigger: 'hover'
        }); 
        window.jQuery("[data-toggle='tooltip']").click(function() {
            window.jQuery(this).tooltip("hide");
        });

        window.jQuery(".select2").select2({
            language: "fr"            
        });
    }

    componentDidUpdate() {
        console.log("LayoutConseiller did update");
        window.jQuery("[data-toggle='tooltip']").tooltip("dispose");
        window.jQuery("[data-toggle='tooltip']").tooltip({
            trigger: 'hover'
        });
        window.jQuery("[data-toggle='tooltip']").click(function() {
            window.jQuery(this).tooltip("hide");
        });

        window.jQuery(".select2").select2({
            language: "fr"
        });
    }
   
    render() {

        window.jQuery(this).tooltip("hide");

        let securityService = new SecurityService();
        let calendarService = new CalendarService();
        let dataService = new DataService();

        const Component = this.props.component;
        const route = this.props.route;

        let notificationsNonLues = [];
        this.props.data.notifications.filter((notification) => notification.dateLecture == null).sort((a, b) => a.date > b.date ? -1 : 1).map((notification) => {
            let beneficiaire = dataService.getBeneficiaireById(notification.beneficiaire);
            let lien = (notification.type == "conseillerTache" ? "/activite" : ((beneficiaire && !beneficiaire.supprime) ? ("/utilisateurs/" + beneficiaire.id) : "#"));
            notificationsNonLues.push(
                <NavLink key={"row-notificationnonlue-" + notification.id} to={lien} className={"dropdown-item notify-item active" + (lien == "#" ? " disabled" : "")}>
                    <p className="notify-details">{notification.titre}<span className="text-muted">{notification.libelle}</span></p>
                    <span className="notif-date">{calendarService.ilya(calendarService.intervalle(new Date(notification.date), new Date()))}</span>
                </NavLink>
            );
        });

        let notifications = [];
        this.props.data.notifications.filter((notification) => notification.dateLecture != null).sort((a, b) => a.dateLecture > b.dateLecture ? -1 : 1).map((notification) => {
            let beneficiaire = dataService.getBeneficiaireById(notification.beneficiaire);
            let lien = (notification.type == "conseillerTache" ? "/activite" : ((beneficiaire && !beneficiaire.supprime) ? ("/utilisateurs/" + beneficiaire.id) : "#"));
            notifications.push(
                <NavLink key={"row-notification-" + notification.id} to={lien} className={"dropdown-item notify-item active" + (lien == "#" ? " disabled" : "")}>
                    <p className="notify-details">{notification.titre}<span className="text-muted">{notification.libelle}</span></p>
                    <span className="notif-date">{calendarService.ilya(calendarService.intervalle(new Date(notification.date), new Date()))}</span>
                </NavLink>
            );
        });

        return (
            <>
                {!this.props.mobile && 
                    <div id="wrapper">
                        <div className="topbar">
                            <nav className="navbar-custom">
                                <div className={"row"}>
                                    <div className="col-sm-6 col-lg-5">
                                        {/* LOGO */}
                                        <div className="topbar-left">
                                            <NavLink className="logo" to="/">
                                                <span className="logo-d">
                                                    <img src={logoD} alt="" />
                                                </span>
                                                <span className="logo-s">
                                                    <img src={logoS} alt="" />
                                                </span>
                                            </NavLink>
                                        </div>

                                        <ul className="navbar-left d-flex list-inline float-left mb-0">
                                            <li className="dropdown d-none d-sm-block">
                                                <form role="search" className="app-search" onSubmit={(e) => e.preventDefault()}>
                                                    <div className="form-group mb-0">
                                                        <button><i className="fa fa-search"></i></button>
                                                        <input className="form-control" type="search" placeholder="Rechercher une personne" id="input-recherche" autoComplete="off" />                   
                                                    </div>
                                                </form>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-sm-6 col-lg-7">
                                        <ul className="list-inline float-right mb-0">
                                            <li className="d-none d-sm-block">
                                                <div className="dropdown pt-3 d-inline-block">
                                                    <a className="btn btn-light dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <div className="acronyme">{this.props.data.me.prenom.charAt(0)}{this.props.data.me.nom.charAt(0)}</div><span className="acronyme-label">{this.props.data.me.prenom} {this.props.data.me.nom}</span>
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        {securityService.isAdmin() &&
                                                            <>
                                                                <NavLink to="/" exact className="dropdown-item" activeClassName="actif">Administration</NavLink>
                                                                <div className="dropdown-divider"></div>
                                                            </>
                                                        }
                                                        {!securityService.isAdmin() && securityService.isResponsable() &&
                                                            <>
                                                                <NavLink to="/admin" exact className="dropdown-item" activeClassName="actif">Administration</NavLink>
                                                                <div className="dropdown-divider"></div>
                                                            </>
                                                        }
                                                        <NavLink to="/mon-compte" exact className="dropdown-item" activeClassName="actif">Mon compte</NavLink>
                                                        <div className="dropdown-divider"></div>
                                                        <NavLink className="dropdown-item" to="/logout">Déconnexion</NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                        <ul className="navbar-right d-flex list-inline float-right mb-0">
                                            <li className="dropdown notification-list" id="dropdown-notifications">
                                                <a className="nav-link dropdown-toggle arrow-none menu-notifs" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                                    <i className="mdi mdi-bell-outline noti-icon"></i>
                                                    {notificationsNonLues.length > 0 && <span className="badge badge-pill badge-danger noti-icon-badge">{notificationsNonLues.length}</span>}
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                                    <h6 className="dropdown-item-text">
                                                        Notifications ({notificationsNonLues.length})
                                                    </h6>
                                                    <div className="slimscroll notification-item-list">
                                                        {notificationsNonLues}
                                                        {notifications.length > 0 &&
                                                            <>
                                                                <h6 className="dropdown-header">Précédemment</h6>
                                                                {notifications}
                                                            </>
                                                        } 
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="menu-onglets float-right">
                                            {securityService.isConseiller() && 
                                                <>
                                                    <NavLink to="/" exact className="btn-beneficiaires" activeClassName="actif"><i className="mdi mdi-account-multiple-outline"></i><span className="labelmenu">Personnes suivies</span></NavLink>
                                                    {dataService.getMyStructure() && dataService.getMyStructure().accesSuiviActivite && <NavLink to="/activite" exact className="btn-activite" activeClassName="actif"><i className="mdi mdi-chart-bar"></i><span className="labelmenu">Mon activité</span></NavLink>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>


                        <Component route={route}/>
                    </div>
                }
                {this.props.mobile && 
                    <WrapperMobile />
                }
            </>
        );
    }
}

export default connect(mapStateToProps, null)(LayoutConseiller);