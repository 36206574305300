import React, { Component } from 'react';

import CalendarService from '../../services/calendar';
import FormPeriodeConges from '../Forms/FormPeriodeConges';

class TabPeriodesConges extends Component {

    constructor(props) {
        super(props);

        this.state = {
            beneficiaire: JSON.parse(JSON.stringify(this.props.beneficiaire)),
            editingPeriode: null,
            periodesSelectionnees: []
        }
    }

    componentDidMount() {
        console.log("TabPeriodes did mount");

        this.bindModals();
    }

    componentDidUpdate(prevProps) {
        console.log("TabPeriodes did update");

        if (this.props != prevProps) {
            this.setState({
                beneficiaire: JSON.parse(JSON.stringify(this.props.beneficiaire))
            });
        }

        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-nouvelleperiodeconges").off('hidden.bs.modal');
        window.jQuery("#modal-nouvelleperiodeconges").on('hidden.bs.modal', () => {
            console.log("dismiss");
            this.setState({
                editingPeriode: null
            });
        });
        this.props.beneficiaire.periodesConges.map((periode) => {
            window.jQuery("#modal-modifperiode-" + periode.id).off('hidden.bs.modal');
            window.jQuery("#modal-modifperiode-" + periode.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingPeriode: null
                });
            });
        });
    }

    setEditingPeriode(periode) {
        this.setState({
            editingPeriode: periode
        });
    }

    togglePeriode(periode) {
        let periodesSelectionnees = this.state.periodesSelectionnees;
        if (periodesSelectionnees.includes(periode.id)) {
            periodesSelectionnees.splice(periodesSelectionnees.indexOf(periode.id), 1);
        }
        else {
            periodesSelectionnees.push(periode.id);
        }
        this.setState({
            periodesSelectionnees: periodesSelectionnees
        });
    }

    toggleAllPeriodes() {
        let periodesSelectionnees = [];
        if (this.state.periodesSelectionnees.length == this.props.beneficiaire.periodesConges.length) {
            periodesSelectionnees = [];
        }
        else {
            this.props.beneficiaire.periodesConges.map((periode) => {
                periodesSelectionnees.push(periode.id)
            });
        }
        this.setState({
            periodesSelectionnees: periodesSelectionnees
        })
    }

    modifieBeneficiaire(champ, val) {
        let beneficiaire = this.state.beneficiaire;
        if (champ == "periodesConges") {
            beneficiaire.periodesConges = val;
            this.enregistreBeneficiaire("periodesConges");
        }
        this.setState({
            beneficiaire: beneficiaire
        })
    }

    enregistreBeneficiaire(champ) {
        console.log(champ);
        let beneficiaire = null;
        if (champ == "periodesConges") {
            if (this.state.beneficiaire.periodesConges != this.props.beneficiaire.periodesConges) {
				beneficiaire = {
                    id: this.props.beneficiaire.id,
                    classe: this.props.beneficiaire.classe,
                    periodesConges: this.state.beneficiaire.periodesConges
                }
            }
        }
        if (beneficiaire) {
            this.props.enregistre(beneficiaire);
		}
    }

    ajoutePeriode() {
        let periodes = this.state.beneficiaire.periodesConges;
        let periode = JSON.parse(JSON.stringify(this.state.editingPeriode));
        periodes.push(
            periode
        );
        this.modifieBeneficiaire("periodesConges", periodes);
    }

    modifiePeriode() {
        let iPeriode = null;
        let periodes = this.state.beneficiaire.periodesConges;

        console.log("periodes", periodes);
        
        periodes.map((periode, index) => {
            if (periode.id == this.state.editingPeriode.id) iPeriode = index;
        })

        periodes[iPeriode] = this.state.editingPeriode;

        this.modifieBeneficiaire("periodesConges", periodes);
    }

    supprimePeriode(periode) {
        let periodes = this.state.beneficiaire.periodesConges;
        periodes[periodes.map(p => p.id).indexOf(periode.id)].supprime = true;
        this.modifieBeneficiaire("periodesConges", periodes);
    }

    formValide() {
        console.log(this.state.editingPeriode);
        return (
            this.state.editingPeriode
            && this.state.editingPeriode.debut
            && this.state.editingPeriode.fin
            && this.state.editingPeriode.debut < this.state.editingPeriode.fin            
        );
    }
    
	render() {

        let modals = [];

        let calendarService = new CalendarService();

        let periodes = [];
        this.props.beneficiaire.periodesConges.sort((a, b) => {
            if (a.enCours != b.enCours) return a.enCours ? -1 : 1;
            return (new Date(a.debut) > new Date(b.debut)) ? -1 : 1;
        }).map((periode, index) => {
            let debut = new Date(periode.debut);
            let fin = new Date(periode.fin);        
            let now = new Date()
            now.setHours(0,0,0,0)            
            periodes.push(
				<tr key={"row-periode-" + periode.id} className={(this.state.periodesSelectionnees.includes(periode.id) ? "table-warning" : "") + " " + (fin < now ? "obsolete" : "")}>
                    {/*<td>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id={"toggle-periode-" + periode.id} onChange={() => this.togglePeriode(periode)} checked={this.state.periodesSelectionnees.includes(periode.id)} />
                            <label htmlFor={"toggle-periode-" + periode.id} className="toggle"></label>
                        </div>
                    </td>*/}
                    <td>
                        <p className="m-0 text-muted font-14">Du {debut.getDate()} {calendarService.mois()[debut.getMonth()].nomCourt} {debut.getFullYear()} au {fin.getDate()} {calendarService.mois()[fin.getMonth()].nomCourt} {fin.getFullYear()}</p>
                    </td>   
                    <td>
                        {fin >= now &&
                            <>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifperiode-" + periode.id} onClick={() => this.setEditingPeriode(periode)}><i className="dripicons-document-edit"></i></a></span>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionperiode-" + periode.id}><i className="dripicons-trash"></i></a></span>
                            </>
                        }
                    </td>
                </tr>
            );
            
            modals.push(
                <div key={"modal-modifperiode-" + periode.id} id={"modal-modifperiode-" + periode.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Période</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formValide()} onClick={() => this.modifiePeriode()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormPeriodeConges
                                    periode={this.state.editingPeriode}
                                    modifie={(periode) => this.setEditingPeriode(periode)}                                    
                                />
                            </div>
                        </div>
                    </div>
                </div> 
            );
            modals.push(
                <div key={"modal-suppressionperiode-" + periode.id} id={"modal-suppressionperiode-" + periode.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer la période de congés</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>La période de congés du {debut.getDate()} {calendarService.mois()[debut.getMonth()].nomCourt} {debut.getFullYear()} au {fin.getDate()} {calendarService.mois()[fin.getMonth()].nomCourt} {fin.getFullYear()} pour <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> sera supprimée. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
                                <button type="button" className="btn btn-primary suppression" onClick={() => this.supprimePeriode(periode)} data-dismiss="modal">Suppr. la période de congés</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
            );
        });

        let aucunePeriode = null;
        if (periodes.length == 0) {
			aucunePeriode =
				<tr key="row-periode-0">
					{/*<td></td>*/}
					<td>
						<p className="m-0 text-muted font-14">Aucune période de congés.</p>
					</td>
					<td></td>                    
                    <td></td>
				</tr>
			;
		}

        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="periodesConges" role="tabpanel">
                <h3>Planification de la mise en pause des questions</h3>
                <a href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouvelleperiodeconges"><i className="mdi mdi-plus"></i> Nouvelle période de congés</a>
                <br clear="both" />
                <div className="table-responsive">
                    <table className="table table-vertical">
                        <thead>
                            <tr>
                                {/*<th>
                                    <div className={"checkbox-wrapper" + (this.state.periodesSelectionnees.length != this.props.beneficiaire.periodesConges.length ? " selection" : "")}>
                                        <input type="checkbox" id="toggle-periodes-all" checked={this.state.periodesSelectionnees.length > 0} onChange={() => this.toggleAllPeriodes()} />
                                        <label htmlFor="toggle-periodes-all" className="toggle"></label>
                                    </div>
                                </th>*/}
                                {this.state.periodesSelectionnees.length == 0 &&
                                    <>
                                        <th>Dates</th>
                                        <th>Actions</th>
                                    </>
                                }
                                {this.state.periodesSelectionnees.length > 0 &&
                                    <th colSpan="4">
                                        {/*<a href="#" data-toggle="modal" data-target="#modal-suppressionperiodes" className="btn btn-annexe contour m-b-20"><i className="dripicons-trash"></i> Supprimer</a>*/}
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {periodes}
                            {aucunePeriode}
                            <tr>
                                <td colSpan="5" className="tdaction">
                                    <a href="#" data-toggle="modal" data-target="#modal-nouvelleperiodeconges" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle période de congés</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>     
                <div id="modal-nouvelleperiodeconges" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Période</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" disabled={!this.formValide()} data-dismiss="modal" onClick={() => this.ajoutePeriode()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormPeriodeConges
                                    periode={this.state.editingPeriode}
                                    modifie={(periode) => this.setEditingPeriode(periode)}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
                {modals}                                            					   
            </div>
        )
	}
}
  

export default TabPeriodesConges;