import React, { Component } from 'react';
import { getTerme } from '../../services/contextualisation';
import DataService from '../../services/data';

class FormProche extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.erreurs = [];
    }

    initFromProps() {
        console.log("init", this.props.proche);
        let proche = this.props.proche ? JSON.parse(JSON.stringify(this.props.proche)) : {
            id: null,
            classe: "Proche",
            nom: "",
            prenom: "",
            genre: "h",
            email: "",
            telephone: "",
            description: "superieur",
            actif: true,
            //emploi: this.props.beneficiaire.emplois.length > 0 ? this.props.beneficiaire.emplois[0].id : null,
            emploi: null,
            bibliotheque: null
        };

        return {
            proche: proche
        };
    }

    componentDidMount() {
        console.log("formproche did mount");
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.proche != this.props.proche) {
            this.setState(this.initFromProps());
        }
    }

    modifieProche(champ, val) {
        let proche = this.state.proche;
        if (champ == "nom") {
            proche.nom = val;
        }
        if (champ == "prenom") {
            proche.prenom = val;
        }
        /*
        if (champ == "genre") {
            proche.genre = val;
        }
        */
        if (champ == "telephone") {
            proche.telephone = val;
        }
        if (champ == "email") {
            proche.email = val;
        }
        if (champ == "description") {
            proche.description = val;
            /*if (["collegue", "superieur", "rh", "emploi"].includes(proche.description) && !proche.emploi && this.props.beneficiaire.emplois.filter((e) => e.enCours).length > 0) {
                proche.emploi = this.props.beneficiaire.emplois.filter((e) => e.enCours)[0].id;
            }*/           
        }
        if (champ == "emploi") {
            proche.emploi = val;
        }
        if (champ == "actif") {
            proche.actif = val;
        }
        this.setState({
            proche: proche,
            erreurs: this.valideChamp(champ)
        });
        if (this.props.modifie) {
            this.props.modifie(proche);
        }
    }

    valideChamp(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
        if (champ == "nom" && this.state.proche.nom == "") {
            erreurs.push(champ);
        }
        if (champ == "prenom" && this.state.proche.prenom == "") {
            erreurs.push(champ);
        }
        if (champ == "email") {
            if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.proche.email)) {
                erreurs.push(champ);
            }            
        }
        if (champ == "telephone") {
            if (this.state.proche.telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.proche.telephone)) {
                erreurs.push(champ);
            }            
        } 
        return erreurs;
    }

    render() {

        let dataService = new DataService()
        let structure = dataService.getStructureById(this.props.beneficiaire.structure)
        
        let emplois = [];
        this.props.beneficiaire.emplois.filter((e) => e.enCours).map((emploi) => {
            emplois.push(
                <option key={"option-proche-emploi-" + emploi.id} value={emploi.id}>[{emploi.entreprise}] {emploi.poste}</option>
            );
        });



        return (
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="form-row">
                            {/*
                            <div className="form-group col-md-2">
                                <select className="form-control select-genre" value={this.state.proche.genre} onChange={(e) => this.modifieProche("genre", e.target.value)}>
                                    <option value="h">M.</option>
                                    <option value="f">Mme</option>
                                </select>
                            </div>
                            */}
                            <div className="form-group col-md-6">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("nom") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Nom *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.proche.nom} onChange={(e) => this.modifieProche("nom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChamp("nom")})} />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("prenom") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Prénom *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.proche.prenom} onChange={(e) => this.modifieProche("prenom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChamp("prenom")})} />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <div className="input-group">
                                    <label className="col-form-label">Type de relation :</label>
                                    <select className="form-control" value={this.state.proche.description} onChange={(e) => this.modifieProche("description", e.target.value)}>
                                        {/*<option value="parent">Parent</option>
                                        <option value="conjoint">Conjoint</option>
                                        <option value="ami">Ami</option>
                                        <option value="personne-confiance">Personne de confiance</option>
                                        <option value="famille">Autre membre de la famille</option>*/}
                                        {!this.props.famille &&
                                            <>
                                                <option value="superieur">{getTerme("Supérieur hiérarchique", structure.domaine)}</option>
                                                {/*<option value="collegue">Collègue</option>                                                
                                                <option value="rh">RH</option>
                                                <option value="emploi">Autre membre lié à l'emploi</option>*/}
                                            </>
                                        }
                                    </select>
                                </div>
                            </div> 
                            {/*{structure.domaine != "entreprise" && ["collegue", "superieur", "rh", "emploi"].includes(this.state.proche.description) &&
                                <div className="form-group col-md-6">
                                    <div className="input-group">
                                        <label className="col-form-label ml-2">Sélectionner l'emploi concerné :</label>
                                        <select className="form-control">
                                            {emplois}
                                        </select>
                                    </div>
                                </div> 
                            }*/}
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("email") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Mail *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername"  value={this.state.proche.email} onChange={(e) => this.modifieProche("email", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChamp("email")})} />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                            <div className={"input-group" + (this.state.erreurs.includes("telephone") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Téléphone</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername"  value={this.state.proche.telephone} onChange={(e) => this.modifieProche("telephone", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChamp("telephone")})} />
                                </div>
                            </div>
                        </div>
                        <br clear="both" />  
                    </form>
                </div>
            </div>
        );
	}
}

export default FormProche;