export default class CalendarService {

    jours() {
        return {
            0: "dimanche",
            1: "lundi",
            2: "mardi",
            3: "mercredi",
            4: "jeudi",
            5: "vendredi",
            6: "samedi",
        }
    }

    mois() {
        return {
            0: {
                nom: "janvier",
                nomCourt: "jan.",
                chiffres: "01"
            },
            1: {
                nom: "février",
                nomCourt: "fév.",
                chiffres: "02"
            },
            2: {
                nom: "mars",
                nomCourt: "mars",
                chiffres: "03"
            },
            3: {
                nom: "avril",
                nomCourt: "avr.",
                chiffres: "04"
            },
            4: {
                nom: "mai",
                nomCourt: "mai",
                chiffres: "05"
            },
            5: {
                nom: "juin",
                nomCourt: "juin",
                chiffres: "06"
            },
            6: {
                nom: "juillet",
                nomCourt: "juil.",
                chiffres: "07"
            },
            7: {
                nom: "août",
                nomCourt: "août",
                chiffres: "08"
            },
            8: {
                nom: "septembre",
                nomCourt: "sept.",
                chiffres: "09"
            },
            9: {
                nom: "octobre",
                nomCourt: "oct.",
                chiffres: "10"
            },
            10: {
                nom: "novembre",
                nomCourt: "nov.",
                chiffres: "11"
            },
            11: {
                nom: "décembre",
                nomCourt: "déc.",
                chiffres: "12"
            }
        }
    }

    intervalle(date1, date2) {
        let d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0, 0);
        let d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0, 0);

        //console.log(date1, date2)
        //console.log(d1, d2)

        let interval = Math.abs(d1.getTime() - d2.getTime());

        return interval;
    }

    ilya(interval) {
        if (interval < 0) {
            return "plus tard";
        }
        if (interval < 1000 * 60 * 60 * 24) {
            return "auj.";
        }
        if (interval < 1000 * 60 * 60 * 24 * 2) {
            return "hier";
        }
        if (interval < 1000 * 60 * 60 * 24 * 31) {
            return "il y a " + Math.round((interval / (1000 * 60 * 60 * 24))) + " jours";
        }
        if (interval < 1000 * 60 * 60 * 24 * 365) {
            return "il y a " + Math.round((interval / (1000 * 60 * 60 * 24  * 31))) + " mois";
        }
        return "il y a " + Math.round((interval / (1000 * 60 * 60 * 24 * 365))) + " an" + (Math.round((interval / (1000 * 60 * 60 * 24 * 365))) > 1 ? "s" : "");
    }

    depuis(interval) {
        if (interval < 0) {
            return "plus tard";
        }
        if (interval < 1000 * 60 * 60 * 24) {
            return "aujourd'hui";
        }
        if (interval < 1000 * 60 * 60 * 24 * 2) {
            return "hier";
        }
        if (interval < 1000 * 60 * 60 * 24 * 31) {
            return Math.round((interval / (1000 * 60 * 60 * 24))) + " jours";
        }
        if (interval < 1000 * 60 * 60 * 24 * 365) {
            return Math.round((interval / (1000 * 60 * 60 * 24 * 31))) + " mois";
        }
        return Math.round((interval / (1000 * 60 * 60 * 24 * 365))) + " an" + (Math.round((interval / (1000 * 60 * 60 * 24 * 365))) > 1 ? "s" : "");
    }

    formatteDate(date, mode="bdd") {
        if (date == null) return null;

        if (mode == "bdd") {
            return (
                [
                    date.getFullYear(),
                    ('0' + (date.getMonth() + 1)).slice(-2),
                    ('0' + date.getDate()).slice(-2)
                ].join('/')
                + " " +
                [
                    ('0' + date.getHours()).slice(-2),
                    ('0' + date.getMinutes()).slice(-2),
                    ('0' + date.getSeconds()).slice(-2)
                ].join(':')
            );
        }
        if (mode == "vue") {
            return [
                ('0' + date.getDate()).slice(-2),
                ('0' + (date.getMonth() + 1)).slice(-2),
                date.getFullYear(),
            ].join('/');
        }

        
    }

}