import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Api from '../api';

import logo from '../assets/images/logo.png';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  history: props.route.history,
	  token: props.route.match.params.token
	}
  }

class PasswordOublie extends Component {	

	constructor(props) {
		super(props);

		let params = new URLSearchParams(this.props.route.location.search)
		this.expire = params.has('expire');

		this.state = {
			etat: null,
			password1: "",
			password2: "",			
			email: null,
			password1Visible: false,
			password2Visible: false,
			erreurs: [],
			newPasswordHistoriqueOk: true,
		};
	}

	componentDidMount() {
		console.log("passwordreset did mount");

		this.verifieToken();
	}

	changePasswords(champ, val) {
		if (champ == "password1") {
			this.setState({
				password1: val
			});
		}
		if (champ == "password2") {
			this.setState({
				password2: val
			});
		}
	}

	verifieToken() {
		if (this.props.token != "") {
			let api = new Api();
			api.checkToken(this.props.token).then((json) => {
				console.log(json);
				if (json.succes && json.email) {
					this.setState({
						etat: "token-ok",
						email: json.email
					})
					if (json.structure && json.structure.id == 12) {
						console.log(document.getElementById("lien-mentions-informations"))
						if (document.getElementById("lien-mentions-informations")) {
							document.getElementById("lien-mentions-informations").setAttribute("href", document.getElementById("lien-mentions-informations").getAttribute("href").replace("?la-poste", "") + "?la-poste")
						}
					}
				}
				else {
					this.setState({
						etat: "token-nok"
					})
				}
			}).catch(err => {
				console.log(err);
				this.setState({
					etat: "token-erreur"
				})
			});
		}
		else {
			this.setState({
				etat: "token-nok"
			})
		}
	}

	formPasswordValide() {
		let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

		return (
			this.state.password1 == this.state.password2
			&& strongRegex.test(this.state.password1)
		);
	}

	enregistrePassword(e) {
		e.preventDefault();

		this.setState({
			etat: "reset-encours"
		})

		let api = new Api();
		api.postPassword(this.props.token, this.state.password1).then((json) => {
			console.log(json);
			if (json.succes) {
				this.setState({
					etat: "reset-ok"
				})
				setTimeout(() => {
					this.props.dispatch({ type: "DATA_LOGIN", payload: {
						email: this.state.email,
						password: this.state.password1,
						callbackOk: () => {
							this.props.history.replace('/');
						},
						callbackNok: () => {
							this.props.history.replace('/');
						},
						callbackNokVPN: () => {
							this.props.history.replace('/');
						},
						callbackNokPasswordExpire: () => {
							this.props.history.replace('/');
						},
						callbackNokCompteInactif: () => {
							this.props.history.replace('/');
						},
						callbackErreur: () => {
							this.props.history.replace('/');
						}
					}});
				}, 3000);
			}
			else {
				this.setState({
					etat: "reset-nok",
					newPasswordHistoriqueOk: json.erreur.code == 'password-historique' ? false : true,
					password1: "",
					password2: ""
				})
			}
		}).catch(err => {
			console.log(err);
			this.setState({
				etat: "reset-erreur",
				password1: "",
				password2: ""
			})
		});
	}

	render() {
		
		if (this.state.etat == null) return <></>;

		if (this.state.etat == "token-nok") {

		}

		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						{this.expire && 
							<div className="alert alert-info bg-info text-white" role="alert">
								Votre mot de passe est expiré. Par mesure de sécurité, celui-ci doit être renouvelé.
							</div>
						}															
                        <h4 className="text-muted font-18 mb-3 text-center">Réinitialiser le mot de passe</h4>
                        {this.state.etat == "token-nok" &&
							<div className="alert alert-info bg-info text-white" role="alert">
								Ce lien n'est pas valide. Vous pouvez recommencer la <NavLink to="/compte/mot-de-passe-oublie">procédure de réinitialisation de votre mot de passe</NavLink> ou contacter votre administrateur.
							</div>
						}
						{this.state.etat == "token-erreur" &&
							<div className="alert alert-warning bg-warning text-white" role="alert">
								Une erreur s'est produite, veuillez recharger la page ou contacter votre administrateur.
							</div>
						}
						{(this.state.etat == "reset-nok" || this.state.etat == "reset-erreur") &&
							<>
								{!this.state.newPasswordHistoriqueOk && 
									<div className="alert alert-warning bg-warning text-white" role="alert">
										Votre nouveau mot de passe doit être différent des précédents mots de passe utilisés.<br />
										Veuillez réessayer avec un nouveau mot de passe.
									</div>
								}
								{this.state.newPasswordHistoriqueOk && 
									<div className="alert alert-warning bg-warning text-white" role="alert">
										Une erreur s'est produite, veuillez recommencer ou contacter votre administrateur.
									</div>
								}
							</>							
						}
						{!["token-nok", "token-erreur"].includes(this.state.etat) &&
							<>	
								<div className="alert alert-info bg-info text-white" role="alert">
									Votre nouveau mot de passe doit faire au minimum <b>8 caractères</b> et contenir au minimum <b>une minuscule</b>, une <b>majuscule</b>, un <b>chiffre</b> et un <b>caractère spécial</b> (! @ # $ % ^ & *).<br />
									Il doit être <b>différent des 3 derniers mots de passe</b> utilisés.
								</div>
								<form className="form-horizontal m-t-30" action="index.html">
									<div className="form-group">
										<label htmlFor="password-1">Mot de passe</label>
										<div className={"input-group" + (this.state.erreurs.includes('password1') ? " input-error" : "")}>
											<input type={this.state.password1Visible ? "text" : "password"} className="form-control" id="password-1" disabled={["reset-encours", "reset-ok"].includes(this.state.etat)} value={this.state.password1} onChange={(e) => this.changePasswords("password1", e.target.value)} />
											<div className="input-group-append" onClick={() => this.setState({password1Visible: !this.state.password1Visible})}>
												<div className="input-group-text"><i className={"fas " + (this.state.password1Visible ? "fa-eye-slash" : "fa-eye")}></i></div>
											</div>
										</div>										
									</div>
									<div className="form-group">
										<label htmlFor="password-2">Confirmation</label>
										<div className={"input-group" + (this.state.erreurs.includes('password2') ? " input-error" : "")}>
											<input type={this.state.password2Visible ? "text" : "password"} className="form-control" id="password-2" disabled={["reset-encours", "reset-ok"].includes(this.state.etat)} value={this.state.password2} onChange={(e) => this.changePasswords("password2", e.target.value)} />
											<div className="input-group-append" onClick={() => this.setState({password2Visible: !this.state.password2Visible})}>
												<div className="input-group-text"><i className={"fas " + (this.state.password2Visible ? "fa-eye-slash" : "fa-eye")}></i></div>
											</div>
										</div>											
									</div>
									<div className="form-group row m-t-20">
										<div className="col-12 text-right">
											<button type="submit" className="btn btn-primary w-md waves-effect waves-light" disabled={!this.formPasswordValide() || ["reset-encours", "reset-ok"].includes(this.state.etat)} onClick={(e) => this.enregistrePassword(e)}>{(this.state.etat == "reset-encours") ? "En cours..." : (this.state.etat == "reset-ok" ? "C'est fait !" : "Enregistrer le nouveau mot de passe")}</button>
										</div>
									</div>
								</form>
							</>
						}
						{this.state.etat == "reset-ok" &&
							<div className="alert alert-success bg-success text-white" role="alert">
								Votre mot de passe a bien été mis à jour ! Vous allez être redirigé vers votre page d'accueil...
							</div>
						}
                    </div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(PasswordOublie);