import Superadmin from '../../pages/Superadmin';
import Structure from '../../pages/Structure';

export default {
    AdminAccueil: {
        component: Superadmin,
        path: '/'
    },
    AdminStructure: {
        component: Structure,
        path: '/structures/:id'
    },
    
}