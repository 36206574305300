import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm
	}
  }

class Erreur404 extends Component {

	componentDidMount() {
		console.log("structure did mount");
	}

	render() {

		return <h3>Erreur 404</h3>;
	}
}

export default connect(mapStateToProps, null)(Erreur404);