import React from 'react';
import { render } from 'react-dom'

import './index.css';
import './plugins/morris/morris.css';
import './plugins/select2/css/select2.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/metismenu.min.css';
import './assets/css/icons.css';
import './assets/css/style.css';
import './assets/css/override.css';

require('jquery');

import './assets/js/jquery.min.js';
import './assets/js/bootstrap.bundle.min.js';
import './assets/js/metisMenu.min.js';
import './assets/js/jquery.slimscroll.js';
import './assets/js/waves.min.js';
import './plugins/jquery-sparkline/jquery.sparkline.min.js';
import './plugins/select2/js/select2.min.js';
import './plugins/select2/js/i18n/fr';
import './assets/js/bootstrap3-typeahead.min.js';
import './assets/js/app.js';

import App from './App';
import * as serviceWorker from './serviceWorker';

import store from './store';

render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
