import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import Api from '../api';

import logo from '../assets/images/logo.png';

import { NavLink } from 'react-router-dom';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  token: props.route.match.params.questionnaireToken,	  
	}
  }

class Questionnaire extends Component {

	constructor(props) {
		super(props);
		this.state = {
			init: null,
			termine: false,
			instances: [],
			beneficiaire: null,
			destinataire: null,	
			erreur: null		
		}
	}

	componentDidMount() {
		console.log("questionnaire did mount");

		document.getElementById('page-login').classList.add('cgu');

		this.getQuestionnaire();
	}

	getQuestionnaire() {
		let api = new Api();

		api.getQuestionnaire(this.props.token).then((json) => {
			console.log(json);
			if (json.erreur) {
				this.setState({
					init: false,
					instances: [],
					beneficiaire: null,
					destinataire: null,
					erreur: json.erreur
				})
			}
			else {
				let instances = [];
				json.instances.map(instance => {
					instances.push(this.initInstancesGradient(instance));
				});
				this.setState({
					init: true,
					instances: instances,
					beneficiaire: json.beneficiaire,
					destinataire: json.destinataire,
					erreur: null
				});
				if (json.beneficiaire.structure == 12) {
					if (document.getElementById("lien-mentions-informations-superieur")) {
						document.getElementById("").setAttribute("href", "/mentions-informations-superieur-hierarchique-la-poste")
					}	
					if (document.getElementById("lien-politique-confidentialite")) {
						document.getElementById("lien-politique-confidentialite").setAttribute("href", "/politique-de-confidentialite-la-poste")
					}
					if (document.getElementById("lien-politique-confidentialite-manager")) {
						document.getElementById("lien-politique-confidentialite-manager").setAttribute("href", "/politique-de-confidentialite-manager-la-poste")
					}					
				}				
			}
			
		}).catch(err => {
			console.log(err);
		});
	}		

	postQuestionnaire() {
		let api = new Api();		

		let allInstances = [];
		this.state.instances.map(instance => {
			allInstances = allInstances.concat(this.prepareInstances(instance));
		});

		let instances = [];
		allInstances.map((instance) => {
			let i = {
				id: instance.id,
				classe: instance.classe,
				reponses: instance.reponses
			}
			if (instance.id == null) {
				i.question = instance.question;
				i.date = instance.date;
				i.dateEnvoi = instance.dateEnvoi;
				i.etat = instance.etat;
			}
			instances.push(i);
		});

		api.postQuestionnaire(this.props.token, instances).then((json) => {
			if (json.erreur) {
				console.log(json.erreur);
			}
			else {
				this.setState({
					termine: true,
					instances: [],
					destinataire: null,
					beneficiaire: null,
					erreur: null
				})
			}
		}).catch(err => {
			console.log(err);
		});
	}	

	modifieReponsesInstance(item, val) {
		console.log(item, val);
		item.instance.reponses = [val];
		this.setState({
			instances: this.state.instances
		});
	}
	
	getGradientReponseFromCode(instance, code) {
		let reponse = null;
		instance.question.reponses.map(r => {
			if (r.cle.code == code) {
				reponse = r;
			}
		});
		return reponse;
	}

	getReponseById(instance, idReponse) {
		let reponse = null;
		instance.question.reponses.map(r => {
			if (r.id == idReponse) {
				reponse = r;
			}
		});
		return reponse;
	}

	initInstancesGradient(item) {		
		if (item.instance.question.type == "gradient") {
			item.instance.reponses = [this.getGradientReponseFromCode(item.instance, parseInt(item.instance.question.parametres.gradient.min)).id];
		}	
		let sousInstances = [];
		item.sousInstances.map(sousInstance => {
			sousInstances.push(this.initInstancesGradient(sousInstance));
		});		
		item.sousInstances = sousInstances;
		return item;
	}

	afficheInstance(item) {	
				
		let instance = 
			<React.Fragment key={"bloc-instance-" + item.fakeId}>
				<h4>{item.instance.question.libelleCalcule}</h4>
				{item.instance.question.type == "liste" &&
					<>
						{item.instance.question.reponses.map(reponse => {
							return ( 
								<React.Fragment key={"bloc-reponse-" + reponse.id}>
									<div className="radio">
										<label>
											<input type="radio" checked={item.instance.reponses.includes(reponse.id)} onChange={(e) => this.modifieReponsesInstance(item, reponse.id)} /> {reponse.cle.icone && <>{reponse.cle.icone}</>} {reponse.libelle}
											<span className="checkmark"></span>
										</label>
									</div>
								</React.Fragment>
							);
						})}
					</>
				}
				{item.instance.question.type == "gradient" &&
					<>
						<InputRange
							minValue={parseInt(item.instance.question.parametres.gradient.min)}
							maxValue={parseInt(item.instance.question.parametres.gradient.max)}
							value={item.instance.reponses.length > 0 ? this.getReponseById(item.instance, item.instance.reponses[0]).cle.code : parseInt(item.instance.question.parametres.gradient.min)}
							onChange={value => this.modifieReponsesInstance(item, this.getGradientReponseFromCode(item.instance, value).id)}
						/>						
					</>
				}
			</React.Fragment>
		;

		let sousInstances = [];
		item.sousInstances.filter(sousInstance => item.instance.reponses.includes(sousInstance.reponse)).map(sousInstance => {
			sousInstances.push(this.afficheInstance(sousInstance));
		});

		return [instance, sousInstances]
	}

	valideInstance(item) {		
		if (item.instance.reponses.length == 0) return false;
		let valide = true;
		item.sousInstances.filter(sousInstance => item.instance.reponses.includes(sousInstance.reponse)).map(sousInstance => {
			if (!this.valideInstance(sousInstance)) valide = false;
		});
		return valide;
	}

	valideQuestionnaire() {
		let valide = true;

		this.state.instances.map(instance => {
			if (!this.valideInstance(instance)) valide = false;
		});

		return valide;
	}

	prepareInstances(item) {
		let instances = [];
		instances.push(item.instance);
		item.sousInstances.filter(sousInstance => item.instance.reponses.includes(sousInstance.reponse)).map(sousInstance => {
			instances = instances.concat(this.prepareInstances(sousInstance))
		});
		return instances;
	}

	render() {

		let instances = [];
		this.state.instances.map(instance => {
			instances.push(this.afficheInstance(instance));			
		});		

		return(
			<div className="card" id="card-questionnaire">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						{this.state.termine &&
							<>
								<h3 className="text-muted font-18 m-b-5 text-center">
									Merci, vos réponses ont bien été prises en compte.<br />
									Vous pouvez fermer cette page.
								</h3>
							</>
						}
						{!this.state.termine &&
							<>
								{this.state.init === null &&
									<h3 className="text-muted font-18 m-b-5 text-center">Chargement du questionnaire en cours...</h3>
								}
								{this.state.init === true && this.state.instances.length > 0 &&
									<>
										<h3>Quelques questions {this.state.beneficiaire && this.state.destinataire && this.state.beneficiaire.id == this.state.destinataire.id ? "pour vous" : ("à propos de " + this.state.beneficiaire.prenom + " " + this.state.beneficiaire.nom)}</h3>
										{instances}
										<button className="btn btn-primary" disabled={!this.valideQuestionnaire()} onClick={() => this.postQuestionnaire()}>Envoyer les réponses</button>
									</>
								}
								{this.state.init === true && this.state.instances.length == 0 &&
									<>
										<h3 className="text-muted font-18 m-b-5 text-center">Aucune question en ce moment.</h3>
									</>
								}
								{this.state.init === false &&
									<h3 className="text-muted font-18 m-b-5 text-center">Oups ! {this.state.erreur.message}</h3>
								}
							</>
						}											
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(Questionnaire);