import storageKey from '../services/session';
import DataService from '../services/data';
import cookie from 'react-cookies';
import moment from 'moment'

export default class Api {

    constructor() {
        //this.endpoint = "https://dev.wesireport.com/api/web/";
        //this.endpoint = "https://app.wesireport.com/api/web/";
        this.endpoint = process.env.APP_API_URL
    }
  
    headers() {

        let dataService = new DataService();        

        return new Headers({
            Authorization: 'Bearer ' + cookie.load(storageKey),
            "Content-Type": "application/json",
            "X-Timestamp": dataService.getTimestamp()
        });
    }

    login(email, password) {

        let json = {
            username: email,
            password: password
        };

        let url = "auth/login_check";

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json'
                  }),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {            
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    loginTmp(mode = "conseiller") {

        let json = null;
        if (mode == "responsable") {
            json = {
                username: "cedric.test@wesireport.com",
                password: "cedric"
            };    
        }
        else if (mode == "admin") {
            json = {
                username: "thibaut.sagi@iuserlab.com",
                password: "thibaut"
            };
        }
        else { // conseiller
            json = {
                username: "charles.test@wesireport.com",
                password: "charles"
            };
        }
        
        let url = "auth/login_check";

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json'
                  }),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    sendResetPasswordToken(email) {

        let json = {
            email: email
        };

        let url = "compte/mot-de-passe/reset";

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json'
                  }),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    checkToken(token) {

        let url = "compte/mot-de-passe/token/" + token;

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json'
                  })
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postPassword(token, password) {

        let url = "compte/mot-de-passe/token/" + token;

        let json = {
            password: password
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json'
                  }),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    checkPassword(password) {
        let url = "mon-compte/mot-de-passe/verification";

        let json = {
            password: password
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    changePassword(oldPassword, newPassword) {
        let url = "mon-compte/mot-de-passe/modification";

        let json = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    checkUniqueEmail(email, classe, id) {
        let url = "compte/email/unique";

        let json = {
            email: email,
            classe: classe,
            id: id
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getInitData() {        
        let url = "data/init";
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers()
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getUpdatesData() {
        let dataService = new DataService();

        let url = "data/updates";
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers()
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    putStructure(structure) {
        
        let url = "structures";
        
        let json = {
            structure: structure
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    putStructureBeneficiaire(structure, beneficiaire) {
        let url = "structures/" + structure.id + "/beneficiaires";
        
        let json = {
            beneficiaire: beneficiaire
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    putStructureConseiller(structure, conseiller) {
        let url = "structures/" + structure.id + "/conseillers";
        
        let json = {
            conseiller: conseiller
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postStructure(structure) {
        let url = "structures/" + structure.id;
        
        let json = {
            structure: structure
        };

        console.log('api', structure);

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getBeneficiaire(beneficiaire) {
        let url = "beneficiaires/" + beneficiaire.id;

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers(),                
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getBeneficiaireCourbes(beneficiaire) {
        let url = "beneficiaires/" + beneficiaire.id + "/courbes";

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers(),                
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getStructureSuivi(structure) {
        let url = "structures/" + structure.id + "/suivi";
        
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers(),                
            })
            .then(json => {
                resolve(json.body);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getStatistiques(structureId, debut, fin) { // structureId peut être égal à "admin" ou "conseiller"
        let url = "statistiques/" + structureId + "/" + moment(debut).format("YYYY-MM-DD") + "/" + moment(fin).format("YYYY-MM-DD");
        
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers(),                
            })
            .then(json => {
                resolve(json.body);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postBeneficiaire(beneficiaire) {
        let url = "beneficiaires/" + beneficiaire.id;
        
        let json = {
            beneficiaire: beneficiaire
        };

        console.log('api', beneficiaire);

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postConseiller(conseiller) {
        let url = "conseillers/" + conseiller.id;
        
        let json = {
            conseiller: conseiller
        };

        console.log('api', conseiller);

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postProche(proche) {
        let url = "proches/" + proche.id;
        
        let json = {
            proche: proche
        };

        console.log('api', proche);

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    copieQuestions(questions, destinataires) {
        let url = "questions/copie";
        
        let json = {
            questions: questions,
            destinataires: destinataires
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    ajouteQuestionsDefaut(questions, destinataire) {
        let url = "personnes/" + destinataire.id + "/questions"
        
        let json = {
            questions: questions            
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postProfil(profil) {
        let url = "profils/" + profil.id;
        
        let json = {
            profil: profil
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    ajouteProfilQuestionsDefaut(questions, profil) {
        let url = "/profils/" + profil.id + "/questions"

        let json = {
            questions: questions
        }

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    copieProfilQuestions(questions, profils) {
        let url = "profils/questions/copie";
        
        let json = {
            questions: questions,
            profils: profils
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    synchroniseProfil(profil, synchronisation) {
        let url = "profils/" + profil.id + "/synchronisation"

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(synchronisation)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postUtilisateur(utilisateur) {
        let url = "utilisateurs/" + utilisateur.id;
        
        let json = {
            utilisateur: utilisateur
        };

        console.log('api', utilisateur);

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postNotifications(notifications) {
        let url = "notifications";
        
        let json = {
            notifications: notifications
        };

        console.log('api', notifications);

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    putQuestion(question) {
        
        let url = "admin/questions";
        
        let json = {
            question: question,
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postQuestion(question) {
        
        let url = "admin/questions/" + question.id;
        
        let json = {
            question: question
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    putQuestionCategorie(categorie) {

        console.log(categorie)
        
        let url = "admin/questions-categories";
        
        let json = {
            categorie: categorie
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postQuestionCategorie(categorie) {
        
        let url = "admin/questions-categories/" + categorie.id;
        
        let json = {
            categorie: categorie
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    putBibliotheque(bibliotheque) {
        
        let url = "admin/bibliotheques";
        
        let json = {
            bibliotheque: bibliotheque
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postBibliotheque(bibliotheque) {
        
        let url = "admin/bibliotheques/" + bibliotheque.id;
        
        let json = {
            bibliotheque: bibliotheque
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    changeBibliotheque(personne, bibliotheque) {
        let url = "personnes/" + personne.id + "/bibliotheques/change";

        let json = {
            bibliotheque: bibliotheque.id
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                headers: this.headers(),
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    sendPersonneQuestionTest(personne) {
        let url = "personnes/" + personne.id + "/questions/test";
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers()
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    sendPersonneConsentement(personne) {
        let url = "personnes/" + personne.id + "/consentement";
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers()
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getPersonneHistoriqueQuestions(personne, date) {
        let url = "personnes/" + personne.id + "/questions/historique/" + date;
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers()
            })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    getQuestionnaire(token) {
        let url = "hooks/questionnaire/" + token;

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postQuestionnaire(token, instances) {
        let url = "hooks/questionnaire/" + token;

        let json = {
            instances: instances
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    hookEmail(instanceComplexId, reponseComplexId) {
        let url = "hooks/email";
        
        let json = {
            instanceComplexId: instanceComplexId,
            reponseComplexId: reponseComplexId
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    hookQualificationAlerte(alerteComplexId, qualification) {
        let url = "hooks/qualification-alerte";
        
        let json = {
            alerteComplexId: alerteComplexId,
            qualification: qualification
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    hookConsentement(token) {
        let url = "hooks/consentement/" + token;
        
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET'            
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    postConsentement(token, consentement) {
        let url = "hooks/consentement/" + token ;

        let json = {
            consentement: consentement
        };

        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'POST',
                body: JSON.stringify(json)
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                resolve(json);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }

    putFichier(fichier, callbackUploading, callbackFinished) {
        let url = "fichiers";

        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function(e){
            if (xhttp.readyState === 4 && xhttp.status === 200){
                callbackFinished(JSON.parse(this.response));
            }
        }
        xhttp.onprogress = function(e){
            if (e.total != 0) {
                callbackUploading();
            }            
        }

        var formData = new FormData();
        formData.append("fichier", fichier);
        xhttp.open("POST", this.endpoint + url, true);
        xhttp.setRequestHeader("Authorization", 'Bearer ' + cookie.load(storageKey));
        xhttp.send(formData);
    }

    getFichier(fichierId) {
        let url = "fichiers/" + fichierId ;
                
        return new Promise((resolve, reject) => {
            fetch(this.endpoint + url, {
                method: 'GET',
                headers: this.headers(),                
            })
            .then(json => {
                resolve(json.body);
            })
            .catch(err => {
                reject(err);
            })
            ; 
        });
    }
}
  
