import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom'

import Footer from '../components/Footer';

import DataService from '../services/data';
import SecurityService from '../services/security';
import RouteService from '../services/route';

import Api from '../api';

import TabInformations from '../components/BeneficiaireProche/TabInformations';
import TabQuestions from '../components/BeneficiaireProche/TabQuestions';
import TabInteractions from '../components/BeneficiaireProche/TabInteractions';
import SidebarProche from '../components/BeneficiaireProche/SidebarProche';



const mapStateToProps = (state, props) => {
	console.log(props);
	let dataService = new DataService();
	return {
	  data: state.data,
	  ihm: state.ihm,
	  beneficiaire: dataService.getBeneficiaireById(props.route.match.params.beneficiaireId),
	  proche: dataService.getProcheByBeneficiaireAndId(dataService.getBeneficiaireById(props.route.match.params.beneficiaireId), props.route.match.params.procheId),
	  conseiller: dataService.getConseillerById((state.data.me != null) ? state.data.me.id : 0),
	  history: props.route.history
	}
  }

class Proche extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: "informations",
			question: null,
		}
	}
	
	componentDidMount() {
		console.log("Proche did mount");
		if (!this.props.beneficiaire || !this.props.proche) {
			console.log("oups");
			this.props.history.replace('/');
			return;
		}
		let securityService = new SecurityService();
		if (!securityService.canViewBeneficiaire(this.props.beneficiaire)) {
			console.log("not allowed");
			this.props.history.replace('/');
			return;
		}

		console.log(this.props.location);
		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});

		let api = new Api();
		api.getPersonneHistoriqueQuestions(this.props.proche, '2021-02-19')
		.then(res => {
			console.log(res)
		})
		.catch(err => {
			console.log(err)
		})
	}

	changeTab(tab) {
		this.setState({
			tab: tab
		})
	}

	ouvreFormQuestion(question = null) {
		if (question == null) {
			question = {
				id: null,
			}
		}
		this.setState({
			question: question
		});
	}

	fermeForm() {
		this.setState({
			question: null,
			proche: null,
			periode: null
		});
	}

	envoieQuestionTest() {
		let api = new Api();
		api.sendBeneficiaireQuestionTest(this.props.proche).then((json) => {
			console.log(json);
		}).catch(err => {
			console.log(err);
		});
	}

	formatteDate(date) {
        if (date == null) return null;

        return [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join('-');
	}

	enregistreProche(proche) {
		console.log(proche);
		this.props.dispatch({type: 'DATA_POST_PROCHE', payload: {proche: proche}});
		if (proche.supprime) this.props.history.goBack();
	}

	copieQuestions(questions, destinataires) {
		this.props.dispatch({type: 'DATA_COPIE_QUESTIONS', payload: {questions: questions, destinataires: destinataires}})
	}

	render() {
		if (!this.props.proche) return <></>;

		let routeService = new RouteService();
		let historyLastLocation = routeService.getPreviousLocation();
		let lastLocation = 
			<NavLink to={historyLastLocation ? historyLastLocation : "/"}><i className="mdi mdi-arrow-left"></i>
				{routeService.getLocationLibelle(historyLastLocation)}
			</NavLink>
		;
		
		return(
			<div className="content-page" id="page-beneficiaire">
                <div className="content">
                    <div className="container-fluid">
						{lastLocation &&
							<div className="row">
								<div className="col-lg-12 lienretour">
									{lastLocation}
								</div>
							</div>
						}
						<div className="row">
							<div className="col-lg-4">
								<SidebarProche 
									proche={this.props.proche}
									beneficiaire={this.props.beneficiaire}
									enregistre={(proche) => this.enregistreProche(proche)}
									goToQuestions={() => this.changeTab('questions')}
								/>
							</div>
							<div className="col-lg-8">
								<div className="row">
									<div className="col-md-12">			
										<div className="card contenu m-b-20">
											<div className="card-body">
												<ul className="nav nav-tabs" role="tablist">
													<li className="nav-item">
														<a className={"nav-link" + (this.state.tab == "informations" ? " active" : "")} data-toggle="tab" href="#informations" role="tab"  onClick={() => this.changeTab("informations")}>
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">Informations</span>
														</a>
													</li>
													{/*{this.props.beneficiaire.suiviAvance && this.props.proche.description == "superieur" && 
														<li className="nav-item">
															<a className={"nav-link" + (this.state.tab == "questions" ? " active" : "")} data-toggle="tab" href="#questions" role="tab"  onClick={() => this.changeTab("questions")}>
																<span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
																<span className="d-none d-sm-block">Questions</span>
															</a>
														</li>
													}*/}
												</ul>
												<div className="tab-content">
													<TabInformations personne={this.props.proche} enregistre={(proche) => this.enregistreProche(proche)}  actif={this.state.tab == "informations"} conseiller={this.props.conseiller} />
													{/*{this.props.beneficiaire.suiviAvance && this.props.proche.description == "superieur" && <TabQuestions personne={this.props.proche} actif={this.state.tab == "questions"} enregistre={(proche) => this.enregistreProche(proche)} copieQuestions={(questions, destinataires) => this.copieQuestions(questions, destinataires)} />}*/}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>	
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(Proche));