import React, { Component } from 'react';
import { Provider } from 'react-redux';
import {BrowserRouter as Router, NavLink, Route, Switch} from "react-router-dom";
import Template from './template';
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)

const App = ({ store }) => (
  <Provider store={store}>
    <Router>
        <Switch>
          <Route path="/" component={Template} />
        </Switch>
    </Router>
  </Provider>
)

/*const App = ({ store }) => (
  <Provider store={store}>
    <Template />
  </Provider>
)*/

export default App;
