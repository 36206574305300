import React, { Component } from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarService from '../../services/calendar';
import InfobulleRGPD from '../InfobulleRGPD';

class FormEmploi extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.erreurs = [];
    }

    initFromProps() {
        let emploi = this.props.emploi ? JSON.parse(JSON.stringify(this.props.emploi)) : {     
            id: null,
            classe: "Emploi",
            entreprise: "",
            commentaire: "",
            secteur: null,
            sites: [{
                id: null,
                classe: "Adresse",
                adresse1: "",
                codePostal: "",
                ville: ""
            }],
            poste: "",
            debut: null,
            fin: null,
            /*proches: [{
                id: null,
                classe: "Proche",
                nom: "",
                prenom: "",
                genre: "h",
                description: "superieur",
                email: "",
                telephone: ""
            }]*/
            proches: []
        };

        emploi.debutWidget = (emploi.debut) ? new Date(emploi.debut) : new Date();
        emploi.finWidget = (emploi.fin) ? new Date(emploi.fin) : null;

        return {
            emploi: emploi
        };
    }

    componentDidMount() {
        console.log("formemploi did mount");
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.emploi != this.props.emploi) {
            this.setState(this.initFromProps());
        }
    }

    modifieEmploi(champ, val) {
        let emploi = this.state.emploi;
        if (champ == "entreprise") {
            emploi.entreprise = val;
        }
        if (champ == "secteur") {
            emploi.secteur = val;
        }
        if (champ == "poste") {
            emploi.poste = val;
        }
        if (champ == "commentaire") {
            emploi.commentaire = val;
        }
        if (champ == "debut") {
            let calendarService = new CalendarService();
            emploi.debutWidget = val;
            if (emploi.debutWidget) emploi.debut = calendarService.formatteDate(emploi.debutWidget);
        }
        if (champ == "fin") {
            let calendarService = new CalendarService();
            emploi.finWidget = val;
            if (emploi.finWidget) emploi.fin = calendarService.formatteDate(emploi.finWidget);
            else emploi.fin = null;
        }
        if (champ == "sites") {
            emploi.sites = val;
        }
        if (champ == "proches") {
            console.log(val);
            emploi.proches = val;
        }
        this.setState({
            emploi: emploi,
            erreurs: this.valideChampEmploi(champ)
        });
        if (this.props.modifie) {
            this.props.modifie(emploi);
        }
    }

    valideChampEmploi(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
		if (champ == "poste" && this.state.emploi.poste == "") {
			erreurs.push(champ);
        }
        if (champ == "entreprise" && this.state.emploi.entreprise == "") {
			erreurs.push(champ);
        }
        return erreurs;
    }

    modifieProche(index, champ, val) {
        console.log(index, champ, val);
        let proches = this.state.emploi.proches;
        if (champ == "nom") {
            proches[index].nom = val;
        }
        if (champ == "prenom") {
            proches[index].prenom = val;
        }
        /*
        if (champ == "genre") {
            proches[index].genre = val;
        }
        */
        if (champ == "email") {
            proches[index].email = val;
        }
        if (champ == "telephone") {
            proches[index].telephone = val;
        }
        if (champ == "description") {
            proches[index].description = val;
        }
        if (champ == "actif") {
            proches[index].actif = val;
        }
        this.valideChampProche(index, champ);
        this.modifieEmploi('proches', proches);
    }

    valideChampProche(index, champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes("proche-" + index + "-" + champ)) {
            erreurs.splice(erreurs.indexOf("proche-" + index + "-" + champ), 1);
        }
        if (this.state.emploi.proches[index].id != null || this.state.emploi.proches[index].nom != "" || this.state.emploi.proches[index].prenom != "" || this.state.emploi.proches[index].email != "" || this.state.emploi.proches[index].telephone != "") {
            if (champ == "nom" && this.state.emploi.proches[index].nom == "") {
                erreurs.push("proche-" + index + "-nom");
            }
            if (champ == "prenom" && this.state.emploi.proches[index].prenom == "") {
                erreurs.push("proche-" + index + "-prenom");
            }
            if (champ == "email") {
                if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.emploi.proches[index].email)) {
                    erreurs.push("proche-" + index + "-email");
                }                
            }
            if (champ == "telephone") {
                if (this.state.emploi.proches[index].telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.emploi.proches[index].telephone)) {
                    erreurs.push("proche-" + index + "-telephone");
                }                
            }
        } 
        else {
            if (erreurs.includes("proche-" + index + "-nom")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-nom"), 1);
            }
            if (erreurs.includes("proche-" + index + "-prenom")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-prenom"), 1);
            }
            if (erreurs.includes("proche-" + index + "-email")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-email"), 1);
            }
            if (erreurs.includes("proche-" + index + "-telephone")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-telephone"), 1);
            }
        }
        return erreurs;
    }

    modifieSite(index, champ, val) {
        let sites = this.state.emploi.sites;    
        if (champ == "adresse1") {
            sites[index].adresse1 = val;
        }
        if (champ == "codePostal") {
            sites[index].codePostal = val;
        }
        if (champ == "ville") {
            sites[index].ville = val;
        }
        this.valideChampSite(index, champ);
        this.modifieEmploi('sites', sites);
    }

    valideChampSite(index, champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes("site-" + index + "-" + champ)) {
            erreurs.splice(erreurs.indexOf("site-" + index + "-" + champ), 1);
        }
        if (champ == "codePostal") {
            if (this.state.emploi.sites[index].codePostal != "" && !/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(this.state.emploi.sites[index].codePostal)) {
                erreurs.push("site-" + index + "-codePostal");
            }
        }
        return erreurs;
    }

    ajouteProche(e) {
        e.preventDefault();
        let proches = this.state.emploi.proches;
        proches.push({
            id: null,
            classe: "Proche",
            nom: "",
            prenom: "",
            genre: "h",
            description: "superieur",
            email: "",
            telephone: ""
        });
        this.modifieEmploi("proches", proches);
    }

    ajouteSite(e) {
        e.preventDefault();
        let sites = this.state.emploi.sites;
        sites.push({
            id: null,
            classe: "Adresse",
            adresse1: "",
            codePostal: "",
            ville: ""
        });
        this.modifieEmploi("sites", sites);
    }

    render() {

        let proches = [];
        this.state.emploi.proches.map((proche, index) => {
            let obligatoire = (proche.id != null || proche.nom != "" || proche.prenom != "" || proche.email != "" || proche.telephone != "");
            proches.push(
                <React.Fragment key={"row-proche" + index}>
                    <div className="form-row">
                        {/*
                        <div className="form-group col-md-2">
                            <select className="form-control select-genre" value={proche.genre} onChange={(e) => this.modifieProche(index, "genre", e.target.value)}>
                                <option value="h">M.</option>
                                <option value="f">Mme</option>
                            </select>
                        </div>
                        */}
                        <div className="form-group col-md-6">
                            <div className={"input-group" + (obligatoire ? " input-obligatoire":"") + (this.state.erreurs.includes("proche-" + index + "-nom") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Nom{obligatoire ? " *":""}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.nom} onChange={(e) => this.modifieProche(index, "nom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "nom")})} />
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className={"input-group" + (obligatoire ? " input-obligatoire":"") + (this.state.erreurs.includes("proche-" + index + "-prenom") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Prénom{obligatoire ? " *":""}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.prenom} onChange={(e) => this.modifieProche(index, "prenom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "prenom")})} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label ml-3">Type de relation :</label>
                        <div className="">
                            <select className="form-control" value={proche.description} onChange={(e) => this.modifieProche(index, "description", e.target.value)}>
                                <option value="superieur">Supérieur hiérarchique</option>
                                {/*<option value="collegue">Collègue</option>                                                
                                <option value="rh">RH</option>
                                <option value="emploi">Autre membre lié à l'emploi</option> */}                               
                            </select>
                        </div>
                    </div> 
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <div className={"input-group" + (obligatoire ? " input-obligatoire":"") + (this.state.erreurs.includes("proche-" + index + "-email") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Mail{obligatoire ? " *":""}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.email} onChange={(e) => this.modifieProche(index, "email", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "email")})} />
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className={"input-group" + (this.state.erreurs.includes("proche-" + index + "-telephone") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Téléphone</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.telephone} onChange={(e) => this.modifieProche(index, "telephone", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "telephone")})} />
                            </div>
                        </div>
                    </div>
                    {proche.id != null && 
                        <div className="form-group row">
                            <input type="checkbox" id={"toggle-proche-" + proche.id} switch="warning" checked={proche.actif} onChange={() => this.modifieProche(index, "actif", !proche.actif)} />
                            <label htmlFor={"toggle-proche-" + proche.id}></label>
                            Actif
                        </div>
                    } 
                    {index != this.state.emploi.proches.length - 1 && <hr />}
                </React.Fragment>
            );
        });

        let sites = [];
        this.state.emploi.sites.map((site, index) => {
            sites.push(
                <React.Fragment key={"row-site-" + index}>
                    <h6>Adresse {this.state.emploi.sites.length > 1 ? ("site n°" + (index + 1)) : ""}:</h6>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">N° et nom de rue</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={site.adresse1} onChange={(e) => this.modifieSite(index, "adresse1", e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <div className={"input-group" + (this.state.erreurs.includes("site-" + index + "-codePostal") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Code postal</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={site.codePostal} onChange={(e) => this.modifieSite(index, "codePostal", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampSite(index, "codePostal")})} />
                            </div>
                        </div>
                        <div className="form-group col-md-8">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Commune</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={site.ville} onChange={(e) => this.modifieSite(index, "ville", e.target.value)} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        });

        return (     
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="form-row">
                        <div className="form-group col-md-12">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("poste") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Poste occupé *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.emploi.poste} onChange={(e) => this.modifieEmploi("poste", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampEmploi("poste")})} />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("entreprise") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Nom de la société *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.emploi.entreprise} onChange={(e) => this.modifieEmploi("entreprise", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampEmploi("entreprise")})} />
                                </div>
                            </div>
                            <div className="form-group row ml-3">
                                <label className="col-form-label ml-3">Secteur d'activité :</label>
                                <div className="">
                                    <select className="form-control" value={this.state.emploi.secteur || ""} onChange={(e) => this.modifieEmploi("secteur", e.target.value)} >
                                        {this.state.emploi.secteur == null && <option value={null}>Choisir un secteur...</option>}
                                        <option value="agriculture">Agriculture</option>
                                        <option value="industrie">Industrie</option>
                                        <option value="construction">Construction / Bâtiment</option>
                                        <option value="commerce">Commerce / Grande distribution</option>
                                        <option value="transport">Transport et entreposage</option>
                                        <option value="administration">Administration publique</option>
                                        <option value="arts">Arts, spectacles, activités récréatives</option>
                                        <option value="autre">Autre activité de services</option>
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className="form-group col-md-12">
                                <div className="input-group">
                                    {sites}
                                    <button className="m-0 mt-3 btn btn-annexe contour action" onClick={(e) => this.ajouteSite(e)}><i className="mdi mdi-plus"></i> Ajouter un site</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <div className="input-group date">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Date de début</div>
                                    </div>
                                    <DatePicker
                                        ref={(input) => { this.ref_debutWidget = input; }} 
                                        className="form-control" 
                                        locale="fr"
                                        selected={this.state.emploi.debutWidget}
                                        onChange={(date) => this.modifieEmploi("debut", date)}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={this.state.emploi.finWidget}
                                    />
                                    <div className="input-group-append" onClick={() => this.ref_debutWidget.setFocus(true)}>
                                        <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <div className="input-group date">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Date de fin</div>
                                    </div>
                                    <DatePicker
                                        ref={(input) => { this.ref_finWidget = input; }} 
                                        className="form-control" 
                                        locale="fr"
                                        selected={this.state.emploi.finWidget}
                                        onChange={(date) => this.modifieEmploi("fin", date)}
                                        dateFormat="dd/MM/yyyy"
                                        isClearable={true}
                                        minDate={this.state.emploi.debutWidget}
                                    />
                                    <div className="input-group-append" onClick={() => this.ref_finWidget.setFocus(true)}>
                                        <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h6>Commentaire <InfobulleRGPD /></h6>
                        <textarea className="form-control" value={this.state.emploi.commentaire} onChange={(e) => this.modifieEmploi("commentaire", e.target.value)}></textarea>
                        {/*<hr />
                        <h6>Personne(s) associée(s) à l'emploi :</h6>
                        {proches}
                        <button className="m-0 btn btn-annexe contour action" onClick={(e) => this.ajouteProche(e)}><i className="mdi mdi-plus"></i> Ajouter une personne</button>
                        <br clear="both" />  */}
                    </form>
                </div>
            </div>
        );
	}
}

export default FormEmploi;