import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../api';

import logo from '../assets/images/logo.png';

import { NavLink } from 'react-router-dom';

import storageKey from '../services/session';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  token: props.route.match.params.token
	}
  }

class HookConsentement extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hook: null,
			consentement: {
				proche: null,
				beneficiaire: null,
				consentement: null,
				enCours: false,
				succes: false
			}
		}
	}

	componentDidMount() {
		console.log("hookconsentement did mount");
		this.hookConsentement();
	}

	hookConsentement() {
		let api = new Api();
		api.hookConsentement(this.props.token).then((json) => {
			console.log(json);
			if (json.erreur) {
				this.setState({
					hook: json
				})
				return
			}
			if (json.proche) {
				this.state.consentement.proche = json.proche
				this.setState({
					consentement: this.state.consentement
				})		
				if (json.proche.beneficiaire.structure && json.proche.beneficiaire.structure.id == 12) {
					if (document.getElementById("lien-mentions-informations-superieur")) {
						document.getElementById("lien-mentions-informations-superieur").setAttribute("href", "/mentions-informations-superieur-hierarchique-la-poste")
					}
					if (document.getElementById("lien-politique-confidentialite")) {
						document.getElementById("lien-politique-confidentialite").setAttribute("href", "/politique-de-confidentialite-la-poste")
					}
					if (document.getElementById("lien-politique-confidentialite-manager")) {
						document.getElementById("lien-politique-confidentialite-manager").setAttribute("href", "/politique-de-confidentialite-manager-la-poste")
					}					
				}
			}
			if (json.beneficiaire) {
				this.state.consentement.beneficiaire = json.beneficiaire
				this.setState({
					consentement: this.state.consentement
				})	
			}
			
		}).catch(err => {
			console.log(err);
		});
	}

	postConsentement() {

		if (this.state.consentement.proche == null && this.state.consentement.beneficiaire == null) return
		if (this.state.consentement.consentement === null) return

		this.state.consentement.succes = false
		this.state.consentement.enCours = true
		this.setState({
			consentement: this.state.consentement
		})

		let api = new Api();
		api.postConsentement(this.props.token, this.state.consentement.consentement).then((json) => {
			console.log(json);
			if (json.erreur) {
				this.setState({
					hook: json
				})
				return
			}
			this.state.consentement.succes = true
			this.state.consentement.enCours = false
			this.setState({
				consentement: this.state.consentement
			})			
		}).catch(err => {
			console.log(err);
		});
	}

	render() {

		let erreur = "Oups, une erreur est survenue.";
		if (this.state.hook && this.state.hook.erreur) {
			if (this.state.hook.erreur.code == "lien-invalide") {
				erreur = "Oups, ce lien n'est pas valide.";
			}
			if (this.state.hook.erreur.code == "consentement-ok") {
				erreur = "Oups, votre consentement a déjà été enregistré.";
			}
		}

		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						{/*{this.state.hook && this.state.hook.succes &&
							<h3 className="text-muted font-18 m-b-5 text-center">
								Merci, votre consentement a bien été pris en compte.<br />
								Vous pouvez fermer cette page.
							</h3>
						}*/}
						{this.state.consentement.succes &&
							<>
								<h3 className="text-muted font-18 m-b-5 text-center">
									Merci, votre {this.state.consentement.consentement ? "" : "non-"}consentement a bien été pris en compte.<br />
									Vous pouvez fermer cette page.
								</h3>
							</>
						}
						{!this.state.consentement.succes &&
							<>
								{this.state.consentement.beneficiaire &&
									<>
										<h5>Souhaitez-vous participer au suivi Wesireport ?</h5>
										<br />
										<div className="radio">
											<label>
												<input type="radio" checked={this.state.consentement.consentement === 'accepte'} onChange={(e) => {this.state.consentement.consentement = 'accepte'; this.setState({consentement: this.state.consentement})}} /> Oui, j'ai lu et j'accepte les <a href={"/conditions-generales-utilisation"} target="_blank">conditions générales d'utilisation</a> de Wesireport
												<span className="checkmark"></span>
											</label>
										</div>		
										<div className="radio">
											<label>
												<input type="radio" checked={this.state.consentement.consentement === 'refuse'} onChange={(e) => {this.state.consentement.consentement = 'refuse'; this.setState({consentement: this.state.consentement})}} /> Non, j'ai lu les conditions et je refuse de participer au suivi Wesireport
												<span className="checkmark"></span>
											</label>
										</div>								
										{this.state.consentement.beneficiaire &&
											<div className="radio">
												<label>
													<input type="radio" checked={this.state.consentement.consentement === 'etre-rappele'} onChange={(e) => {this.state.consentement.consentement = 'etre-rappele'; this.setState({consentement: this.state.consentement})}} /> Je souhaite être rappelé
													<span className="checkmark"></span>
												</label>
											</div>								
										}
										<div style={{width: "100%", textAlign: "center", marginTop:20}}>
											<button className="btn btn-primary" disabled={this.state.consentement.consentement === null || this.state.consentement.enCours} onClick={() => this.postConsentement()}>Valider ma réponse</button>								
										</div>
									</>
								}
								{this.state.consentement.proche &&
									<>
										<h5>Souhaitez-vous participer au suivi Wesireport de {this.state.consentement.proche.beneficiaire.prenom} {this.state.consentement.proche.beneficiaire.nom} ?</h5>
										<br />
										<div className="radio">
											<label>
												<input type="radio" checked={this.state.consentement.consentement === 'accepte'} onChange={(e) => {this.state.consentement.consentement = 'accepte'; this.setState({consentement: this.state.consentement})}} /> Oui, j'ai lu et j'accepte les <a href={"/conditions-generales-utilisation-manager" + (this.state.consentement.proche.beneficiaire.structure.id == 12 ? "-la-poste" : "")} target="_blank">conditions de participation</a> au suivi Wesireport
												<span className="checkmark"></span>
											</label>
										</div>		
										<div className="radio">
											<label>
												<input type="radio" checked={this.state.consentement.consentement === 'refuse'} onChange={(e) => {this.state.consentement.consentement = 'refuse'; this.setState({consentement: this.state.consentement})}} /> Non, j'ai lu les conditions et je refuse de participer au suivi Wesireport
												<span className="checkmark"></span>
											</label>
										</div>								
										<div style={{width: "100%", textAlign: "center", marginTop:20}}>
											<button className="btn btn-primary" disabled={this.state.consentement.consentement === null || this.state.consentement.enCours} onClick={() => this.postConsentement()}>Valider ma réponse</button>								
										</div>
									</>
								}
							</>
						}						
						{this.state.hook && this.state.hook.erreur &&
							<h3 className="text-muted font-18 m-b-5 text-center">{erreur}</h3>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(HookConsentement);