const initialState = { 
  init: false,
  token: false,
  timestamp: 0,
  me: null,
  structures: [],
  beneficiaires: [],
  conseillers: [],
  notifications: [],
  questions: [],
  bibliotheques: [],
  modalites: [],
  categoriesInteractions: [],
  categoriesQuestions: []
}

function dataReducer(state = initialState, action) {
  /*let nextState;
  nextState = {
    ...state
  };*/
  let nextState = JSON.parse(JSON.stringify(state));
  switch (action.type) {

    case 'DATA_LOGIN': 
      nextState.token = action.payload.token;
      return JSON.parse(JSON.stringify(nextState));
    
    case 'DATA_INIT':
      console.log(action);
      if (nextState !== true) {
        nextState.structures = action.payload.structures;
        nextState.conseillers = action.payload.conseillers;
        nextState.beneficiaires = action.payload.beneficiaires;
        nextState.modalites = action.payload.modalites;
        nextState.notifications = action.payload.notifications;
        nextState.questions = action.payload.questions;
        nextState.bibliotheques = action.payload.bibliotheques;
        nextState.categoriesInteractions = action.payload.categoriesInteractions;
        nextState.categoriesQuestions = action.payload.categoriesQuestions;
        nextState.me = action.payload.me;
        nextState.timestamp = action.payload.timestamp;
        nextState.init = true;
      }      
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_UPDATES':
      // structures
      action.payload.structures.suppressions.map((structure) => {
        let iStructure = -1;
        for (let s in nextState.structures) {
          if (nextState.structures[s].id == structure.id) {
            iStructure = s;
          }
        }
        if (iStructure != -1) {
          nextState.structures.splice(iStructure, 1);
        }
      });
      action.payload.structures.modifications.map((structure) => {
        for (let s in nextState.structures) {
          if (nextState.structures[s].id == structure.id) {
            nextState.structures[s] = structure;
          }
        }
      });
      action.payload.structures.ajouts.map((structure) => {
        nextState.structures.push(structure);
      });

      // conseillers
      action.payload.conseillers.suppressions.map((conseiller) => {
        let iConseiller = -1;
        for (let c in nextState.conseillers) {
          if (nextState.conseillers[c].id == conseiller.id) {
            iConseiller = c;
          }
        }
        if (iConseiller != -1) {
          nextState.conseillers.splice(iConseiller, 1);
        }
      });
      action.payload.conseillers.modifications.map((conseiller) => {
        for (let c in nextState.conseillers) {
          if (nextState.conseillers[c].id == conseiller.id) {
            nextState.conseillers[c] = conseiller;
          }
        }
      });
      action.payload.conseillers.ajouts.map((conseiller) => {
        nextState.conseillers.push(conseiller);
      });

      // beneficiaires
      action.payload.beneficiaires.suppressions.map((beneficiaire) => {
        let iBeneficiaire = -1;
        for (let b in nextState.beneficiaires) {
          if (nextState.beneficiaires[b].id == beneficiaire.id) {
            iBeneficiaire = b;
          }
        }
        if (iBeneficiaire != -1) {
          nextState.beneficiaires.splice(iBeneficiaire, 1);
        }
      });
      action.payload.beneficiaires.modifications.map((beneficiaire) => {
        for (let b in nextState.beneficiaires) {
          if (nextState.beneficiaires[b].id == beneficiaire.id) {
            nextState.beneficiaires[b] = beneficiaire;
          }
        }
      });
      action.payload.beneficiaires.ajouts.map((beneficiaire) => {
        nextState.beneficiaires.push(beneficiaire);
      });

      // questions
      Object.getOwnPropertyNames(action.payload.questions).map((property) => {
        action.payload.questions[property].suppressions.map((question) => {
          let iQuestion = -1;
          for (let q in nextState.questions[property]) {
            if (nextState.questions[property][q].id == question.id) {
              iQuestion = q;
            }
          }
          if (iQuestion != -1) {
            nextState.questions[property].splice(iQuestion, 1);
          }
        });
        action.payload.questions[property].modifications.map((question) => {
          for (let q in nextState.questions[property]) {
            if (nextState.questions[property][q].id == question.id) {
              nextState.questions[property][q] = question;
            }
          }
        });
        action.payload.questions[property].ajouts.map((question) => {
          nextState.questions[property].push(question);
        });
      });

      // bibliotheques      
      nextState.bibliotheques = [].concat(action.payload.bibliotheques.modifications, action.payload.bibliotheques.ajouts)
      
      // notifications
      action.payload.notifications.suppressions.map((notification) => {
        let iNotification = -1;
        for (let n in nextState.notifications) {
          if (nextState.notifications[n].id == notification.id) {
            iNotification = n;
          }
        }
        if (iNotification != -1) {
          nextState.notifications.splice(iNotification, 1);
        }
      });
      action.payload.notifications.modifications.map((notification) => {
        for (let n in nextState.notifications) {
          if (nextState.notifications[n].id == notification.id) {
            nextState.notifications[n] = notification;
          }
        }
      });
      action.payload.notifications.ajouts.map((notification) => {
        nextState.notifications.push(notification);
      });

      // autres
      nextState.categoriesQuestions = action.payload.categoriesQuestions;
      nextState.timestamp = action.payload.timestamp;
      nextState.me = action.payload.me;
      nextState.init = true;
      

      return JSON.parse(JSON.stringify(nextState));
    
    case 'DATA_PUT_STRUCTURE':
      console.log(action);
      nextState.structures.push(action.payload);
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_SET_STRUCTURE':
      console.log('DATA SET STRUCTURE');
      for (let s in nextState.structures) {
        if (nextState.structures[s].id == action.payload.id) {
          console.log("if structure !");
          nextState.structures[s] = action.payload;
        }
      }
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_DELETE_STRUCTURE':
      console.log('DATA DELETE STRUCTURE');
      let iStructure = -1;
      for (let s in nextState.structures) {
        if (nextState.structures[s].id == action.payload.id) {
          iStructure = s;
        }
      }
      if (iStructure != -1) {
        nextState.structures.splice(iStructure, 1);
      }
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));
    
    case 'DATA_PUT_BENEFICIAIRE':
      console.log(action);
      nextState.beneficiaires.push(action.payload);
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_SET_BENEFICIAIRE':
      console.log('DATA SET BENEFICIAIRE');
      for (let b in nextState.beneficiaires) {
        if (nextState.beneficiaires[b].id == action.payload.id) {
          nextState.beneficiaires[b] = action.payload;
        }
      }
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_DELETE_BENEFICIAIRE':
      console.log('DATA DELETE BENEFICIAIRE');
      let iBeneficiaire = -1;
      for (let b in nextState.beneficiaires) {
        if (nextState.beneficiaires[b].id == action.payload.id) {
          iBeneficiaire = b;
        }
      }
      if (iBeneficiaire != -1) {
        nextState.beneficiaires.splice(iBeneficiaire, 1);
      }
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_PUT_CONSEILLER':
      console.log(action);
      nextState.conseillers.push(action.payload);
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_SET_CONSEILLER':
      console.log('DATA SET CONSEILLER');
      for (let c in nextState.conseillers) {
        if (nextState.conseillers[c].id == action.payload.id) {
          nextState.conseillers[c] = action.payload;
        }
      }
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_DELETE_CONSEILLER':
      console.log('DATA DELETE CONSEILLER');
      let iConseiller = -1;
      for (let c in nextState.conseillers) {
        if (nextState.conseillers[c].id == action.payload.id) {
          iConseiller = c;
        }
      }
      if (iConseiller != -1) {
        nextState.conseillers.splice(iConseiller, 1);
      }
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));

    case 'DATA_SET_UTILISATEUR':
      console.log('DATA SET UTILISATEUR');
      nextState.me = action.payload;
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));
    
    case 'DATA_CLEAR':
      console.log("DATA CLEAR");
      nextState = initialState;
      console.log("nextState", nextState);
      return JSON.parse(JSON.stringify(nextState));
      
    default:
      return nextState
  }
}

export default dataReducer