import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import CalendarService from '../../services/calendar';
import DataService from '../../services/data';
import SecurityService from '../../services/security';

class RowBeneficiaire extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log("CardBeneficiaire did mount");
        console.log(this.props.beneficiaire);

        window.jQuery("#dropdown-notifications-row-beneficiaire-" + this.props.beneficiaire.id).on('hidden.bs.dropdown', () => {
            let notifications = [];
            this.props.notifications.filter((notification) => notification.dateLecture == null).map((notification) => {
                notification.lu = true;
                notifications.push({
                    id: notification.id,
                    classe: notification.classe,
                    lu: true
                });
            });
            if (notifications.length > 0) {
                this.props.enregistreNotifications(notifications);
            }
        });
    }

	render() {

        let calendarService = new CalendarService();
        let dataService = new DataService();
        let securityService = new SecurityService();

        let structure = dataService.getStructureById(this.props.beneficiaire.structure)

        let questionsEnPause = null
        if (this.props.beneficiaire.suiviAvance && !this.props.beneficiaire.pauseQuestions && !this.props.beneficiaire.enConges && this.props.beneficiaire.etatConsentement == 'accepte') {        
            questionsEnPause =
                <p>
                    <i className="mdi mdi-play-circle-outline"></i> Questions automatiques activées
                </p>
            ;
        }
        else {
            questionsEnPause = 
                <p className="pause">
                    <i className="mdi mdi-pause-circle-outline"></i> {(this.props.beneficiaire.etatConsentement == null && this.props.beneficiaire.dateDemandeConsentement) ? "Consentement en attente" : (this.props.beneficiaire.etatConsentement === 'refuse' ? 'Consentement refusé' : (this.props.beneficiaire.etatConsentement === 'etre-rappele' ? 'Demande à être rappelé' : (this.props.beneficiaire.enConges ? "En congés" : "En pause")))}                    
                </p>
            ;
        }        
        
        let notifications = [];
        this.props.notifications.filter((notification) => notification.dateLecture == null).map((notification) => {
            notifications.push(
                <a href="#" key={"row-notificationnonlue-" + notification.id} className="dropdown-item notify-item active">
                    <p className="notify-details">{notification.titre}<span className="text-muted">{notification.libelle}</span></p>
                    <span className="notif-date">{calendarService.ilya(calendarService.intervalle(new Date(notification.date), new Date()))}</span>
                </a>
            );
        });

        let filtresBeneficiaire = []
        structure.filtres.map(filtre => {
            let actif = this.props.beneficiaire.filtres.includes(filtre.id)
            let sousFiltres = []
            filtre.filtres.map(sousFiltre => {
                if (this.props.beneficiaire.filtres.includes(sousFiltre.id)) {
                    actif = true
                    sousFiltres.push(sousFiltre)
                }
            })								
            if (actif) {
                if (sousFiltres.length == 0) {
                    filtresBeneficiaire.push(filtre.nom)
                }
                else {
                    sousFiltres.map(sousFiltre => {
                        filtresBeneficiaire.push(filtre.nom + " - " + sousFiltre.nom)
                    })
                }
            }
        })

        return (
            <tr key={"row-beneficiaire-" + this.props.beneficiaire.id} className={(this.props.beneficiairesSelectionnes.includes(this.props.beneficiaire) ? "table-warning" : "") + " " + (!this.props.beneficiaire.actif ? "obsolete" : "")}>
                {/*<td>
                    <div className="checkbox-wrapper">
                        <input type="checkbox" id={"toggle-row-" + this.props.beneficiaire.id} onChange={(e) => this.props.toggle()} />
                        <label htmlFor={"toggle-row-" + this.props.beneficiaire.id} id={"label-row-toggle-" + this.props.beneficiaire.id} className="toggle"></label>
                    </div>
                </td>*/}
                <td>
                    <NavLink to={"/utilisateurs/" + this.props.beneficiaire.id}><p className="m-0 table-nom font-14">{this.props.beneficiaire.suiviAvance && <i className="mdi mdi-wifi"></i>} {this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</p></NavLink>
                    {filtresBeneficiaire.length > 0 &&
                        <p>
                            {filtresBeneficiaire.map((filtre, iFiltre) => {
                                return (
                                    <React.Fragment key={"filtre-" + iFiltre}>
                                        {iFiltre != 0 && <br />}
                                        {filtre}
                                    </React.Fragment>
                                )
                            })}						
                        </p>
                    }	
                </td>
                <td>
                    <p className="m-0 text-muted font-14">Signaux faibles : <span className="table-signaux">{this.props.beneficiaire.score}<span className="table-signaux total">/{this.props.beneficiaire.seuilCalcule}</span></span>
                    </p>
                </td>
                <td>
                    {questionsEnPause}
                </td>
                <td className="table-notifs">
                    {notifications.length > 0 && 
                        <>
                            <div className="dropdown" id={"dropdown-notifications-row-beneficiaire-" + this.props.beneficiaire.id}>
                                <a className="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="mdi mdi-bell-outline"></i>
                                    <span className="badge badge-pill badge-danger noti-icon-badge">{notifications.length}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                    <div className="slimscroll notification-item-list">
                                        {notifications}
                                    </div>
                                </div>
                            </div>
                        </>  
                    }        
                </td>
                <td>
                    {dataService.getStructureById(this.props.beneficiaire.structure).domaine !== 'entreprise' &&
                        <>
                            <span className="span-tooltip" data-toggle={(this.props.conseiller && this.props.beneficiaire.conseillerPrincipal != this.props.conseiller.id) ? "tooltip" : ""} data-placement="top" title={(this.props.conseiller && this.props.beneficiaire.conseillerPrincipal != this.props.conseiller.id) ? "Ne plus suivre" : ""}><button className="m-0 btn btn-annexe contour" disabled={!(this.props.conseiller && this.props.beneficiaire.conseillerPrincipal != this.props.conseiller.id)} onClick={() => this.props.ouvreModalStoppeSuivi()}><i className="mdi mdi-bell-off"></i></button></span>
                            <span className="span-tooltip" data-toggle={this.props.peutPartager ? "tooltip" : ""} data-placement="top" title={this.props.peutPartager ? "Partager le suivi" : ""}><button className="m-0 btn btn-annexe contour" disabled={!this.props.peutPartager} onClick={() => this.props.ouvreModalPartage()}><i className="mdi mdi-share-variant"></i></button></span>                    
                        </>
                    }
                    <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={this.props.beneficiaire.actif ? "Désactiver" : "Réactiver" }><button className="m-0 btn btn-annexe contour" onClick={() => this.props.ouvreModalToggleActivation()} disabled={!this.props.beneficiaire.actif ? dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviAvance && dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviNormal : false}><i className={"mdi " + (this.props.beneficiaire.actif ? "mdi-block-helper" : "mdi-checkbox-marked-circle-outline")}></i></button></span>
                    {/*{this.props.beneficiaire.actif && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={this.props.beneficiaire.suiviAvance ? "Passer en suivi sans signaux faibles" : "Passer en suivi avec signaux faibles" }><button className="m-0 btn btn-annexe contour" onClick={() => this.props.ouvreModalToggleSuivi()} disabled={this.props.beneficiaire.suiviAvance ? dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviNormal : dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviAvance}><i className={"mdi " + (this.props.beneficiaire.suiviAvance ? "mdi-wifi-off" : "mdi-wifi")}></i></button></span>}*/}
                    {(securityService.isAdmin() || securityService.isResponsableStructure(dataService.getStructureById(this.props.beneficiaire.structure))) && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><button className="m-0 btn btn-annexe contour" onClick={() => this.props.ouvreModalSuppression()}><i className="dripicons-trash"></i></button></span>}
                </td>
            </tr>
        );
	}
}
  

export default RowBeneficiaire;