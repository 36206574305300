import React, { Component } from 'react';

import logoD from '../assets/images/logo-d.png';

class WrapperMobile extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="wrapper-mobile">
                <div className="card-block">
                    <div className="ex-page-content text-center">
                        <h1 className="text-dark"><i className="dripicons-rocket"></i></h1>
                        <h4 className="">L' application WESIReport n'est pas conçue pour un visionnage mobile. Passez sur tablette ou sur ordinateur 
                            <i className="dripicons-device-tablet"></i> <i className="dripicons-device-desktop"></i>.
                        </h4>
                        <br />
                    </div>
                </div>
                <div className="m-t-40 text-center">
                    <img src={logoD} alt="" />
                </div>
            </div>
        )
    }
}

export default WrapperMobile;
