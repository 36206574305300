import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import Api from '../api/index';

import Footer from '../components/Footer';

import DataService from '../services/data';
import { getTerme } from '../services/contextualisation';

const mapStateToProps = (state, props) => {
	let dataService = new DataService();
	return {
	  data: state.data,
	  ihm: state.ihm,
	  conseiller: dataService.getConseillerById((state.data.me != null) ? state.data.me.id : 0),
	  history: props.route.history
	}
  }

class Compte extends Component {

	constructor(props) {
		super(props);

		let utilisateur = JSON.parse(JSON.stringify(props.data.me)); 
		utilisateur.oldPassword = "";
		utilisateur.newPassword1 = "";
		utilisateur.newPassword2 = "";

		if (!('beneficiaireDebutPeriodeSensible' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireDebutPeriodeSensible = false;
		}
		if (!('beneficiaireFinPeriodeSensible' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireFinPeriodeSensible = false;
		}
		if (!('beneficiaireScoreApprocheSeuil' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireScoreApprocheSeuil = false;
		}
		if (!('beneficiaireScoreAtteintSeuil' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireScoreAtteintSeuil = false;
		}
		if (!('beneficiaireSilenceRadio' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireSilenceRadio = false;
		}
		if (!('beneficiaireEtreRappele' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireEtreRappele = false;
		}
		if (!('conseillerTache' in utilisateur.preferences)) {
			utilisateur.preferences.conseillerTache = false;
		}
		if (!('beneficiaireConsentementAccepte' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireConsentementAccepte = false;
		}
		if (!('beneficiaireConsentementRefus' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireConsentementRefus = false;
		}
		if (!('beneficiaireConsentementEtreRappele' in utilisateur.preferences)) {
			utilisateur.preferences.beneficiaireConsentementEtreRappele = false;
		}
		if (!('procheConsentementRefus' in utilisateur.preferences)) {
			utilisateur.preferences.procheConsentementRefus = false;
		}
		if (!('procheConsentementAttente' in utilisateur.preferences)) {
			utilisateur.preferences.procheConsentementAttente = false;
		}
		
		this.state = {
			utilisateur: utilisateur,
			oldPasswordOk: false,
			newPasswordHistoriqueOk: true,
			erreurs: [],
			checkUniqueEmail: null,
			oldPasswordVisible: false,
			newPassword1Visible: false,
			newPassword2Visible: false,
		}
	}
	
	componentDidMount() {
		console.log("compte did mount");
		if (!this.props.data.me) {
			console.log("oups");
			this.props.history.replace('/');
		}
	}

	componentDidUpdate() {
		console.log("compte did update");
	}

	changeUtilisateur(champ, val) {
		let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
		let utilisateur = this.state.utilisateur;
        if (champ == "nom") {
            utilisateur.nom = val;
        }
        if (champ == "prenom") {
            utilisateur.prenom = val;
        }
        if (champ == "telephone") {
            utilisateur.telephone = val;
        }
        if (champ == "email") {
			utilisateur.email = val;
			if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(val)) {
                this.checkUniqueEmail();
            }	
		}
		if (champ == "oldPassword") {
            utilisateur.oldPassword = val;
		}
		if (champ == "newPassword1") {
			utilisateur.newPassword1 = val;
		}
		if (champ == "newPassword2") {
			utilisateur.newPassword2 = val;
		}
		if (champ == "preferences-notifications") {
			utilisateur.preferences.notificationsAll = false;
			utilisateur.preferences.notificationsJour = false;
			utilisateur.preferences.notificationsSemaine = false;
			if (val == "all") {
				utilisateur.preferences.notificationsAll = true;
			}
			if (val == "jour") {
				utilisateur.preferences.notificationsJour = true;
			}
			if (val == "semaine") {
				utilisateur.preferences.notificationsSemaine = true;
			}
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireDebutPeriodeSensible") {
			utilisateur.preferences.beneficiaireDebutPeriodeSensible = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireFinPeriodeSensible") {
			utilisateur.preferences.beneficiaireFinPeriodeSensible = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireScoreApprocheSeuil") {
			utilisateur.preferences.beneficiaireScoreApprocheSeuil = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireScoreAtteintSeuil") {
			utilisateur.preferences.beneficiaireScoreAtteintSeuil = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireSilenceRadio") {
			utilisateur.preferences.beneficiaireSilenceRadio = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireEtreRappele") {
			utilisateur.preferences.beneficiaireEtreRappele = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-conseillerTache") {
			utilisateur.preferences.conseillerTache = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireConsentementAccepte") {
			utilisateur.preferences.beneficiaireConsentementAccepte = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireConsentementRefus") {
			utilisateur.preferences.beneficiaireConsentementRefus = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-beneficiaireConsentementEtreRappele") {
			utilisateur.preferences.beneficiaireConsentementEtreRappele = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-procheConsentementRefus") {
			utilisateur.preferences.procheConsentementRefus = val;
			this.enregistreUtilisateur("preferences");
		}
		if (champ == "preferences-procheConsentementAttente") {
			utilisateur.preferences.procheConsentementAttente = val;
			this.enregistreUtilisateur("preferences");
		}	
        this.setState({
			utilisateur: utilisateur,
			erreurs: erreurs
		});
	}
	
	enregistreUtilisateur(champ) {
		let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
		let utilisateur = null;
		if (champ == "nom" && this.state.utilisateur.nom != this.props.data.me.nom) {
			if (this.state.utilisateur.nom != "") {
				utilisateur = {
					id: this.props.data.me.id,
					classe: this.props.data.me.classe,
					nom: this.state.utilisateur.nom
				}
			}
		}
		if (champ == "prenom" && this.state.utilisateur.prenom != this.props.data.me.prenom) {
			if (this.state.utilisateur.prenom != "") {
				utilisateur = {
					id: this.props.data.me.id,
					classe: this.props.data.me.classe,
					prenom: this.state.utilisateur.prenom
				}
			}
		}
		if (champ == "email" && this.state.utilisateur.email != this.props.data.me.email) {
			if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.utilisateur.email) && this.state.checkUniqueEmail) {
				utilisateur = {
					id: this.props.data.me.id,
					classe: this.props.data.me.classe,
					email: this.state.utilisateur.email
				}
			}
			else {
				erreurs.push(champ);
			}
		}
		if (champ == "telephone" && this.state.utilisateur.telephone != this.props.data.me.telephone) {
			if (/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.utilisateur.telephone)) {
				utilisateur = {
					id: this.props.data.me.id,
					classe: this.props.data.me.classe,
					telephone: this.state.utilisateur.telephone
				}
			}
			else {
				erreurs.push(champ);
			}
		}
		if (champ == "preferences" && this.state.utilisateur.preferences != this.props.data.me.preferences) {
			utilisateur = {
				id: this.props.data.me.id,
				classe: this.props.data.me.classe,
				preferences: this.state.utilisateur.preferences
			}
		}
		console.log(utilisateur);
		if (utilisateur) {
			this.props.dispatch({type: 'DATA_POST_UTILISATEUR', payload: {utilisateur: utilisateur}});
		}
		this.setState({
            erreurs: erreurs
        })
	}

	blurNewPassword(champ) {
		let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
		let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		if (champ == "newPassword1") {
			if (this.state.utilisateur.newPassword1 != "" && !strongRegex.test(this.state.utilisateur.newPassword1)) {
				erreurs.push(champ);
			}
		}
		if (champ == "newPassword2") {
			if (this.state.utilisateur.newPassword2 != "" && (!strongRegex.test(this.state.utilisateur.newPassword2) || this.state.utilisateur.newPassword1 != this.state.utilisateur.newPassword2)) {
				erreurs.push(champ);
			}
		}
		this.setState({
            erreurs: erreurs
        });
	}

	formPasswordValide() {
		let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

		return (
			this.state.oldPasswordOk
			&& this.state.utilisateur.newPassword1 == this.state.utilisateur.newPassword2
			&& strongRegex.test(this.state.utilisateur.newPassword1)
		);
	}

	checkOldPassword() {
		let api = new Api();

		let erreurs = this.state.erreurs;
		if (erreurs.includes('oldPassword')) {
			erreurs.splice(erreurs.indexOf('oldPassword'));
		}

		this.setState({
			oldPasswordOk: false,
			erreurs: erreurs
		});

		if (this.state.utilisateur.oldPassword) {
			api.checkPassword(this.state.utilisateur.oldPassword).then((json) => {
				console.log(json);
				if (json.succes) {
					this.setState({
						oldPasswordOk: true
					});
				}
				else {
					let erreurs = this.state.erreurs;
					erreurs.push('oldPassword');
					this.setState({
						oldPasswordOk: false,
						erreurs: erreurs
					});
				}
			}).catch(err => {
				console.log(err);
				let erreurs = this.state.erreurs;
				erreurs.push('oldPassword');
				this.setState({
					oldPasswordOk: false,
					erreurs: erreurs
				});
			});
		}
	}

	enregistrePassword() {

		let api = new Api();

		api.changePassword(this.state.utilisateur.oldPassword, this.state.utilisateur.newPassword1).then((json) => {
			console.log(json);
			let utilisateur = this.state.utilisateur;
			utilisateur.newPassword1 = "";
			utilisateur.newPassword2 = "";
			if (json.succes) {
				utilisateur.oldPassword = "";	
				this.setState({
					oldPasswordOk: false,
					utilisateur: utilisateur
				});
			}
			else {				
				this.setState({
					utilisateur: utilisateur,
					newPasswordHistoriqueOk: json.erreur.code == 'password-historique' ? false : true
				});
			}
		}).catch(err => {
			console.log(err);
			let utilisateur = this.state.utilisateur;
			utilisateur.newPassword1 = "";
			utilisateur.newPassword2 = "";
            this.setState({
				utilisateur: utilisateur
			}); 
		});
	}

	checkUniqueEmail() {
        let api = new Api();

		let utilisateur = this.state.utilisateur;
		
		api.checkUniqueEmail(utilisateur.email, utilisateur.classe, utilisateur.id).then(json => {
            let erreurs = this.state.erreurs;
            if (json.unique && erreurs.includes("email")) {
                erreurs.splice(erreurs.indexOf("email"), 1);
            }
            if (!json.unique && !erreurs.includes("email")) {
                erreurs.push("email");
            }                        
            this.setState({
				checkUniqueEmail: json.unique,
				erreurs: erreurs,				
            });            
        }).catch(err => {
			console.log(err);
        })
    }

	render() {

		if (!this.props.data.me) return <></>;

		console.log(this.props.data.me);

		console.log(this.state.utilisateur)

		let dataService = new DataService()
		let structure = null
		if (this.props.data.me.structure) {
			structure = dataService.getStructureById(this.props.data.me.structure.id)
		}

		return (
			<div className="content-page h-100" id="page-moncompte">
                <div className="content h-100">
                    <div className="container-fluid h-100">
						<div className="row h-100">
							<div className="col-lg-5 h-100">
								<div className="card card-profil sidebar m-b-20">
									<div className="card-body text-center">
										<h4 className="mt-0 header-title mb-3 text-center">{this.props.data.me.prenom} {this.props.data.me.nom}</h4>
										<div className="text-center signaux">
											<br clear="both" />
											<div className="sidebar-center"></div>
										</div>
										{/*<a href="#" className="mt-0 mb-3 btn btn-annexe contour action"><i className="mdi mdi-alert-circle-outline"></i> Mon compte a été piraté</a>
										<hr />*/}
										<NavLink to="/logout" className="btn btn-annexe contour text-center w-100"><i className="mdi mdi-logout"></i> Déconnexion</NavLink>
									</div>
								</div>
							</div>
							<div className="col-lg-7 h-100">
								<div className="row h-100">
									<div className="col-md-12 h-100">
										<div className="card contenu m-b-20" id="informations">
											<div className="card-body">
												<h3>Informations personnelles</h3>
												<form>
													<div className="form-row">
														<div className="form-group col-md-12">
															<div className="input-group input-obligatoire">
																<div className="input-group-prepend">
																	<div className="input-group-text">Nom *</div>
																</div>
																<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.utilisateur.nom} onChange={(e) => this.changeUtilisateur("nom", e.target.value)} onBlur={() => this.enregistreUtilisateur("nom")} />
															</div>
														</div>
														<div className="form-group col-md-12">
															<div className="input-group input-obligatoire">
																<div className="input-group-prepend">
																	<div className="input-group-text">Prénom *</div>
																</div>
																<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.utilisateur.prenom} onChange={(e) => this.changeUtilisateur("prenom", e.target.value)} onBlur={() => this.enregistreUtilisateur("prenom")} />
															</div>
														</div>
													</div>
													<div className="form-row">
														<div className="form-group col-md-12">
															<div className={"input-group input-obligatoire" + (this.state.erreurs.includes('email') ? " input-error" : "")}>
																<div className="input-group-prepend">
																	<div className="input-group-text">Mail *</div>
																</div>
																<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.utilisateur.email} onChange={(e) => this.changeUtilisateur("email", e.target.value)} onBlur={() => this.enregistreUtilisateur("email")} />
															</div>
															{this.state.checkUniqueEmail === false && <ul className="parsley-errors-list filled" id="parsley-id-15"><li className="parsley-required">Cette adresse email est déjà utilisée.</li></ul>}
														</div>
														{this.props.conseiller != null && 
															<div className="form-group col-md-12">
																<div className={"input-group" + (this.state.erreurs.includes('telephone') ? " input-error" : "")}>
																	<div className="input-group-prepend">
																		<div className="input-group-text">Tel. portable (pour les SMS)</div>
																	</div>
																	<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.utilisateur.telephone} onChange={(e) => this.changeUtilisateur("telephone", e.target.value)} onBlur={() => this.enregistreUtilisateur("telephone")} />
																</div>
															</div>
														}
													</div>
													<br clear="both" />
													<h3>Modifier mon mot de passe</h3>						   
													<div className="form-row">
														<div className="form-group col-md-12">
															<div className={"input-group" + (this.state.erreurs.includes('oldPassword') ? " input-error" : "")}>
																<div className="input-group-prepend">
																	<div className="input-group-text">Mot de passe actuel</div>
																</div>
																<input type={this.state.oldPasswordVisible ? "text" : "password"} className="form-control" id="inlineFormInputGroupUsername" value={this.state.utilisateur.oldPassword} onChange={(e) => this.changeUtilisateur("oldPassword", e.target.value)} onBlur={() => this.checkOldPassword()} />
																<div className="input-group-append" onClick={() => this.setState({oldPasswordVisible: !this.state.oldPasswordVisible})}>
																	<div className="input-group-text"><i className={"fas " + (this.state.oldPasswordVisible ? "fa-eye-slash" : "fa-eye")}></i></div>
																</div>
															</div>
														</div>
													</div>
													<div className="alert alert-info bg-info text-white" role="alert">
														Votre nouveau mot de passe doit faire au minimum <b>8 caractères</b> et contenir au minimum <b>une minuscule</b>, une <b>majuscule</b>, un <b>chiffre</b> et un <b>caractère spécial</b> (! @ # $ % ^ & *).<br />
														Il doit être <b>différent des 3 derniers mots de passe</b> utilisés.
													</div>
													<div className="form-row">
														<div className="form-group col-md-12">
															<div className={"input-group" + (this.state.erreurs.includes('newPassword1') ? " input-error" : "")}>
																<div className="input-group-prepend">
																	<div className="input-group-text">Nouveau mot de passe</div>
																</div>
																<input type={this.state.newPassword1Visible ? "text" : "password"} className="form-control" id="inlineFormInputGroupUsername" value={this.state.utilisateur.newPassword1} onChange={(e) => this.changeUtilisateur("newPassword1", e.target.value)} onBlur={() => this.blurNewPassword('newPassword1')} />
																<div className="input-group-append" onClick={() => this.setState({newPassword1Visible: !this.state.newPassword1Visible})}>
																	<div className="input-group-text"><i className={"fas " + (this.state.newPassword1Visible ? "fa-eye-slash" : "fa-eye")}></i></div>
																</div>
															</div>
														</div>
													</div>
													<div className="form-row">
														<div className="form-group col-md-12">
															<div className={"input-group" + (this.state.erreurs.includes('newPassword2') ? " input-error" : "")}>
																<div className="input-group-prepend">
																	<div className="input-group-text">Confirmation</div>
																</div>
																<input type={this.state.newPassword2Visible ? "text" : "password"} className="form-control" id="inlineFormInputGroupUsername" value={this.state.utilisateur.newPassword2} onChange={(e) => this.changeUtilisateur("newPassword2", e.target.value)} onBlur={() => this.blurNewPassword('newPassword2')} />
																<div className="input-group-append" onClick={() => this.setState({newPassword2Visible: !this.state.newPassword2Visible})}>
																	<div className="input-group-text"><i className={"fas " + (this.state.newPassword2Visible ? "fa-eye-slash" : "fa-eye")}></i></div>
																</div>
															</div>
														</div>
													</div>
													{!this.state.newPasswordHistoriqueOk && 
														<div className="alert alert-warning bg-warning text-white" role="alert">
															Votre nouveau mot de passe doit être différent des précédents mots de passe utilisés.<br />
															Veuillez réessayer avec un nouveau mot de passe.
														</div>
													}	
													<button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formPasswordValide()} onClick={() => this.enregistrePassword()}>Enregistrer le nouveau mot de passe</button>													
						 							<br />
													<br clear="both" />																										
													<h3>Notifications</h3>   						  
													<div className="row">
														<div className="col-md-6">				
															<h6>Recevoir les notifications par mail lorsque :</h6>                                                        
															<div className="checkbox-wrapper">
																<input type="checkbox" id="compte-preferences-conseillerTache" checked={this.state.utilisateur.preferences.conseillerTache} onChange={() => this.changeUtilisateur("preferences-conseillerTache", !this.state.utilisateur.preferences.conseillerTache)} />
																<label htmlFor="compte-preferences-conseillerTache" className="toggle"></label>
															</div>
															J'ai une tâche à réaliser
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireScoreApprocheSeuil" checked={this.state.utilisateur.preferences.beneficiaireScoreApprocheSeuil} onChange={() => this.changeUtilisateur("preferences-beneficiaireScoreApprocheSeuil", !this.state.utilisateur.preferences.beneficiaireScoreApprocheSeuil)} />
																<label htmlFor="compte-preferences-beneficiaireScoreApprocheSeuil" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} approche de son seuil d'alerte
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireScoreAtteintSeuil" checked={this.state.utilisateur.preferences.beneficiaireScoreAtteintSeuil} onChange={() => this.changeUtilisateur("preferences-beneficiaireScoreAtteintSeuil", !this.state.utilisateur.preferences.beneficiaireScoreAtteintSeuil)} />
																<label htmlFor="compte-preferences-beneficiaireScoreAtteintSeuil" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} atteint son seuil d'alerte
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireDebutPeriodeSensible" checked={this.state.utilisateur.preferences.beneficiaireDebutPeriodeSensible} onChange={() => this.changeUtilisateur("preferences-beneficiaireDebutPeriodeSensible", !this.state.utilisateur.preferences.beneficiaireDebutPeriodeSensible)} />
																<label htmlFor="compte-preferences-beneficiaireDebutPeriodeSensible" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} termine une période sensible
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireFinPeriodeSensible" checked={this.state.utilisateur.preferences.beneficiaireFinPeriodeSensible} onChange={() => this.changeUtilisateur("preferences-beneficiaireFinPeriodeSensible", !this.state.utilisateur.preferences.beneficiaireFinPeriodeSensible)} />
																<label htmlFor="compte-preferences-beneficiaireFinPeriodeSensible" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} termine une période sensible
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireSilenceRadio" checked={this.state.utilisateur.preferences.beneficiaireSilenceRadio} onChange={() => this.changeUtilisateur("preferences-beneficiaireSilenceRadio", !this.state.utilisateur.preferences.beneficiaireSilenceRadio)} />
																<label htmlFor="compte-preferences-beneficiaireSilenceRadio" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} ne répond plus aux questions
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireEtreRappele" checked={this.state.utilisateur.preferences.beneficiaireEtreRappele} onChange={() => this.changeUtilisateur("preferences-beneficiaireEtreRappele", !this.state.utilisateur.preferences.beneficiaireEtreRappele)} />
																<label htmlFor="compte-preferences-beneficiaireEtreRappele" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} a demandé à être rappelé
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireConsentementAccepte" checked={this.state.utilisateur.preferences.beneficiaireConsentementAccepte} onChange={() => this.changeUtilisateur("preferences-beneficiaireConsentementAccepte", !this.state.utilisateur.preferences.beneficiaireConsentementAccepte)} />
																<label htmlFor="compte-preferences-beneficiaireConsentementAccepte" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} a donné son consentement et les questions auto. ne sont pas activées
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireConsentementRefus" checked={this.state.utilisateur.preferences.beneficiaireConsentementRefus} onChange={() => this.changeUtilisateur("preferences-beneficiaireConsentementRefus", !this.state.utilisateur.preferences.beneficiaireConsentementRefus)} />
																<label htmlFor="compte-preferences-beneficiaireConsentementRefus" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} a refusé de donner son consentement
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-beneficiaireConsentementEtreRappele" checked={this.state.utilisateur.preferences.beneficiaireConsentementEtreRappele} onChange={() => this.changeUtilisateur("preferences-beneficiaireConsentementEtreRappele", !this.state.utilisateur.preferences.beneficiaireConsentementEtreRappele)} />
																<label htmlFor="compte-preferences-beneficiaireConsentementEtreRappele" className="toggle"></label>
															</div>
															Un {getTerme('utilisateur', structure ? structure.domaine : 'accompagnement')} a demandé à être rappelé à propos de son consentement
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-procheConsentementRefus" checked={this.state.utilisateur.preferences.procheConsentementRefus} onChange={() => this.changeUtilisateur("preferences-procheConsentementRefus", !this.state.utilisateur.preferences.procheConsentementRefus)} />
																<label htmlFor="compte-preferences-procheConsentementRefus" className="toggle"></label>
															</div>
															Un proche a refusé de participer au suivi Wesireport
															<br clear="both" />
															<div className="checkbox-wrapper mt-3">
																<input type="checkbox" id="compte-preferences-procheConsentementAttente" checked={this.state.utilisateur.preferences.procheConsentementAttente} onChange={() => this.changeUtilisateur("preferences-procheConsentementAttente", !this.state.utilisateur.preferences.procheConsentementAttente)} />
																<label htmlFor="compte-preferences-procheConsentementAttente" className="toggle"></label>
															</div>
															Un proche n'a pas répondu à la demande de consentement après 7 jours
							  							</div>  
									   					<div className="col-md-6">            
									  						<h6>Fréquence :</h6>               
															<div className="form-group">
																<div className="radio">
																	<label>
																		<input type="radio" name="radio-input" checked={this.state.utilisateur.preferences.notificationsAll} onChange={() => this.changeUtilisateur("preferences-notifications", "all")} /> A chaque événement
																		<span className="checkmark"></span>
																	</label>
																</div>
															</div>
															<div className="form-group">
																<div className="radio">
																	<label>
																		<input type="radio" name="radio-input" checked={this.state.utilisateur.preferences.notificationsJour} onChange={() => this.changeUtilisateur("preferences-notifications", "jour")} /> 1 fois par jour
																		<span className="checkmark"></span>
																	</label>
																</div>
															</div>													
															<div className="form-group">
																<div className="radio">
																	<label>
																		<input type="radio" name="radio-input" checked={this.state.utilisateur.preferences.notificationsSemaine} onChange={() => this.changeUtilisateur("preferences-notifications", "semaine")} /> 1 fois par semaine
																		<span className="checkmark"></span>
																	</label>
																</div>
															</div>
							  							</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>							
						</div>
					</div>
					<Footer />
				</div>
			</div>
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(Compte));