import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import DataService from '../services/data';
import SecurityService from '../services/security';
import RouteService from '../services/route';

import Api from '../api';

import Footer from '../components/Footer';

import TabSynthese from '../components/BeneficiaireProche/TabSynthese';
import TabInformations from '../components/BeneficiaireProche/TabInformations';
import TabAnnuaire from '../components/BeneficiaireProche/TabAnnuaire';
import TabQuestions from '../components/BeneficiaireProche/TabQuestions';
import TabInteractions from '../components/BeneficiaireProche/TabInteractions';
import TabPeriodes from '../components/BeneficiaireProche/TabPeriodes';
import TabPeriodesConges from '../components/BeneficiaireProche/TabPeriodesConges';
import SidebarBeneficiaire from '../components/BeneficiaireProche/SidebarBeneficiaire';

import FormInteraction from '../components/Forms/FormInteraction';
import { getBeneficiaire, getBeneficiaireBibliotheque } from '../api/axios';

const mapStateToProps = (state, props) => {
	console.log(props);
	let dataService = new DataService();

	return {
	  data: state.data,
	  ihm: state.ihm,
	  beneficiaire: dataService.getBeneficiaireById(props.route.match.params.id),
	  conseiller: dataService.getConseillerById((state.data.me != null) ? state.data.me.id : 0),	  
	}
  }

class Beneficiaire extends Component {

	constructor(props) {
		super(props);		

		this.state = {
			tab: "synthese",
			question: null,
			editingInteraction: null,
			autresConseillers: JSON.parse(JSON.stringify(this.props.beneficiaire ? this.props.beneficiaire.conseillers : []))			
		}
	}
	
	componentDidMount() {
		console.log("Beneficiaire did mount");
		if (!this.props.beneficiaire) {
			console.log("oups");
			this.props.history.replace('/');
			return;
		}
		let securityService = new SecurityService();
		if (!securityService.canViewBeneficiaire(this.props.beneficiaire)) {
			console.log("not allowed");
			this.props.history.replace('/');
			return;
		}
		
		window.jQuery("#modal-partagerbeneficiaire").on('hidden.bs.modal', () => {
			this.setState({				
                autresConseillers: JSON.parse(JSON.stringify(this.props.beneficiaire.conseillers))				
            });
		});

		console.log(this.props.location);
		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});		

		//this.getCourbes()

		getBeneficiaireBibliotheque(this.props.beneficiaire.id)
		.then(res => {
			console.log(res)
			if (res.data !== null) {
				this.props.dispatch({type: 'DATA_SET_BENEFICIAIRE', payload: {...this.props.beneficiaire, bibliotheques: [res.data], bibliotheque: res.data.id}})
			}
		})
		.catch(err => {
			console.log(err.response)
		})
	}

	componentDidUpdate() {
		
	}

	setEditingInteraction(interaction) {
        this.setState({
            editingInteraction: interaction
        });
    }

	changeTab(tab) {
		this.setState({
			tab: tab
		})
		this.props.dispatch({type: 'IHM_BENEFICIAIRE_TAB', payload: tab});
	}

	ouvreConseiller(conseiller) {
		let security = new SecurityService();
		if (security.canViewConseiller(conseiller)) {
			this.props.history.push('/accompagnants/' + conseiller.id);
		}		
	}

	ouvreFormProche(proche = null) {
		if (proche == null) {
			proche = {
				id: null,
			}
		}
		this.setState({
			proche: proche
		});
	}

	enregistreProche(proche) {
		this.props.dispatch({type: 'DATA_POST_PROCHE', payload: {proche: proche}})
	}

	fermeForm() {
		this.setState({
			question: null,
			proche: null,
			periode: null
		});
	}

	formatteDate(date) {
        if (date == null) return null;

        return [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join('-');
	}

	toggleSuivi() {
		if (this.props.conseiller) {
			let beneficiaire = {
				id: this.props.beneficiaire.id,
				classe: this.props.beneficiaire.classe,
				conseillers: this.props.beneficiaire.conseillers
			};
			if (beneficiaire.conseillers.includes(this.props.conseiller.id)) {
				beneficiaire.conseillers.splice(beneficiaire.conseillers.indexOf(this.props.conseiller.id), 1);
			}
			else {
				beneficiaire.conseillers.push(this.props.conseiller.id);
			}
			this.enregistreBeneficiaire(beneficiaire);
		}
	}

	ajouteInteraction() {
		let interactions = this.props.beneficiaire.interactions;
		interactions.push(this.state.editingInteraction);
		let beneficiaire = {
			id: this.props.beneficiaire.id,
			classe: this.props.beneficiaire.classe,
			interactions: interactions
		}
		this.enregistreBeneficiaire(beneficiaire);
	}

	enregistreBeneficiaire(beneficiaire) {
		console.log(beneficiaire);
		this.props.dispatch({type: 'DATA_POST_BENEFICIAIRE', payload: {beneficiaire: beneficiaire}})
		if (beneficiaire.supprime) this.props.history.goBack();
	}

	toggleAutresConseillers(conseillerId) {
		console.log('toggle autre conseiller')
		let autresConseillers = this.state.autresConseillers;
		if (autresConseillers.includes(conseillerId)) {
			autresConseillers.splice(autresConseillers.indexOf(conseillerId), 1);
		}
		else {
			autresConseillers.push(conseillerId);
		}
		this.setState({
			autresConseillers: autresConseillers
		});
	}

	partagerSuivi() {
		let beneficiaire = {
			id: this.props.beneficiaire.id,
			classe: this.props.beneficiaire.classe,
			//conseillers: this.props.beneficiaire.conseillers.concat(this.state.autresConseillers)
			conseillers: this.state.autresConseillers
		}
		this.enregistreBeneficiaire(beneficiaire);		
		setTimeout(() => {
			this.setState({
				autresConseillers: JSON.parse(JSON.stringify(beneficiaire.conseillers))
			});
		}, 600)		
	}

	formInteractionValide() {
		return (
            this.state.editingInteraction
			&& this.state.editingInteraction.description
            && this.state.editingInteraction.categorie
            && new Date(this.state.editingInteraction.debut) <= new Date(this.state.editingInteraction.fin)
        )
	}

	ajouteQuestionsDefaut(questions, destinataire) {
		this.props.dispatch({type: 'DATA_AJOUTE_QUESTIONSDEFAUT', payload: {questions: questions, destinataire: destinataire}})
	}
	
	copieQuestions(questions, destinataires) {
		this.props.dispatch({type: 'DATA_COPIE_QUESTIONS', payload: {questions: questions, destinataires: destinataires}})
	}

	getCourbes() {
		this.props.dispatch({type: 'DATA_GET_BENEFICIAIRE', payload: {beneficiaire: this.props.beneficiaire}})
	}

	render() {
		if (!this.props.beneficiaire) return <></>;	

		let dataService = new DataService();
		let securityService = new SecurityService();
		
		let structure = dataService.getStructureById(this.props.beneficiaire.structure);

		console.log("tab", this.state.tab);

		let boutonSuivre = null;
		if (
			this.props.conseiller
			&& !this.props.conseiller.seulementAdmin
			&& this.props.beneficiaire.conseillerPrincipal != this.props.conseiller.id
		) {
			if (this.props.beneficiaire.conseillers.includes(this.props.conseiller.id)) {
				boutonSuivre = 
					<a href="#" onClick={() => this.toggleSuivi()} className="btn btn-annexe"><i className="mdi mdi-bell-off"></i> Ne plus suivre</a>
				;
			}
			else {	
				if (securityService.canFollowBeneficiaire(this.props.conseiller, this.props.beneficiaire)) {
					boutonSuivre = <a href="#" onClick={() => this.toggleSuivi()} className="btn btn-annexe bg-secondary"><i className="mdi mdi-bell"></i> Suivre</a>								
				}				
			}
		}

		console.log(this.state.autresConseillers)
		let autresConseillers = [];
        if (structure) {
            structure.conseillers.map((c) => {
                //if (!this.props.beneficiaire.conseillers.includes(c)) {
                    let conseiller = dataService.getConseillerById(c);
                    if (conseiller && conseiller.actif && !conseiller.seulementAdmin) {
                        autresConseillers.push(
							<React.Fragment key={"checkbox-partage-suivi-" + conseiller.id}>
								<div className="checkbox-wrapper mr-2">
									<input type="checkbox" id={"checkbox-partage-suivi-" + conseiller.id} checked={this.state.autresConseillers.includes(conseiller.id) || securityService.canFollowBeneficiaire(conseiller, this.props.beneficiaire)} onChange={() => this.toggleAutresConseillers(conseiller.id)} disabled={securityService.canFollowBeneficiaire(conseiller, this.props.beneficiaire)} />
									<label htmlFor={"checkbox-partage-suivi-" + conseiller.id} className="checkbox-remember-me"></label>
								</div>
								<label htmlFor={"checkbox-partage-suivi-" + conseiller.id}>{conseiller.prenom} {conseiller.nom} {securityService.canFollowBeneficiaire(conseiller, this.props.beneficiaire) ? <>(automatique)</> : <></>}</label>
								<br />
							</React.Fragment>
                        );
                    }
                //}
            });
		}
		
		let routeService = new RouteService();
		let historyLastLocation = routeService.getPreviousLocation();
		let lastLocation = 
			<NavLink to={historyLastLocation ? historyLastLocation : "/"}><i className="mdi mdi-arrow-left"></i>
				{routeService.getLocationLibelle(historyLastLocation)}
			</NavLink>
		;

		let partagePossible = autresConseillers.length > 0 && (structure.domaine !== 'entreprise' || securityService.isResponsable() || securityService.isAdmin())
		
		return(
			<div className="content-page" id="page-beneficiaire">
                <div className="content">
                    <div className="container-fluid">
						<div className="row">
							<div className="col-lg-12 lienretour">
								{lastLocation}
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4">
								<SidebarBeneficiaire
									beneficiaire={this.props.beneficiaire}
									enregistre={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)}
									ouvreConseiller={(conseiller) => this.ouvreConseiller(conseiller)}
									partagePossible={partagePossible}
									conseiller={this.props.conseiller}
									categoriesInteractions={this.props.data.categoriesInteractions}
									goToQuestions={() => this.changeTab('questions')}
								/>
							</div>
							<div className="col-lg-8">
								<div className="row">
									<div className="col-sm-12">
										<div className="page-title-box">
											{partagePossible && <a href="#" className="btn btn-annexe" data-toggle="modal" data-target="#modal-partagerbeneficiaire"><i className="mdi mdi-share-variant"></i> Partager</a>}
											{boutonSuivre}
											<div id="modal-partagerbeneficiaire" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
												<div className="modal-dialog modal-dialog-centered modal-lg">
													<div className="modal-content">
														<div className="modal-header">
															<h5 className="modal-title mt-0">Partager le suivi de {this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</h5>
															<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
														</div>
														<div className="modal-body">
															<p>Partager le suivi de <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> avec :</p>
															{autresConseillers}
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.partagerSuivi()}>Partager</button>
															<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
														</div>
													</div>
												</div>
											</div>															
											<a href="#" data-toggle="modal" data-target="#modal-beneficiairenouvelleinteraction" onClick={() => this.setEditingInteraction(null)} className="mb-3 btn btn-primary float-right"><i className="mdi mdi-plus"></i> Créer une nouvelle interaction</a>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">			
										<div className="card contenu m-b-20">
											<div className="card-body">
												<ul className="nav nav-tabs" role="tablist">
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.beneficiaireTab == "synthese" ? "active" : "")} data-toggle="tab" href="#synthese" role="tab" onClick={() => this.changeTab("synthese")}>
															<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
															<span className="d-none d-sm-block">Synthèse</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.beneficiaireTab == "informations" ? "active" : "")} data-toggle="tab" href="#informations" role="tab" onClick={() => this.changeTab("informations")}>
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">Informations</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.beneficiaireTab == "annuaire" ? "active" : "")} data-toggle="tab" href="#annuaire" role="tab" onClick={() => this.changeTab("annuaire")}>
															<span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
															<span className="d-none d-sm-block">Annuaire</span>
														</a>
													</li>
													{this.props.beneficiaire.suiviAvance &&
														<li className="nav-item">
															<a className={"nav-link " + (this.props.ihm.beneficiaireTab == "questions" ? "active" : "")} data-toggle="tab" href="#questions" role="tab" onClick={() => this.changeTab("questions")}>
																<span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
																<span className="d-none d-sm-block">Questions</span>
															</a>
														</li>
													}
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.beneficiaireTab == "interactions" ? "active" : "")} data-toggle="tab" href="#interactions" role="tab" onClick={() => this.changeTab("interactions")}>
															<span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
															<span className="d-none d-sm-block">Interactions</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.beneficiaireTab == "periodesConges" ? "active" : "")} data-toggle="tab" href="#periodesConges" role="tab" onClick={() => this.changeTab("periodesConges")}>
															<span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
															<span className="d-none d-sm-block">Congés</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.beneficiaireTab == "periodes" ? "active" : "")} data-toggle="tab" href="#periodes" role="tab" onClick={() => this.changeTab("periodes")}>
															<span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
															<span className="d-none d-sm-block">Périodes sensibles</span>
														</a>
													</li>
												</ul>
												<div className="tab-content">
													<TabSynthese beneficiaire={this.props.beneficiaire} actif={this.props.ihm.beneficiaireTab == "synthese"} categoriesInteractions={this.props.data.categoriesInteractions} />			
													<TabInformations personne={this.props.beneficiaire} enregistre={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)} actif={this.props.ihm.beneficiaireTab == "informations"} changeTab={(tab) => this.changeTab(tab)} enregistreProche={(proche) => this.enregistreProche(proche)} conseiller={this.props.conseiller} />
													<TabAnnuaire beneficiaire={this.props.beneficiaire} actif={this.props.ihm.beneficiaireTab == "annuaire"} enregistre={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)} enregistreProche={(proche) => this.enregistreProche(proche)} />
													{this.props.beneficiaire.suiviAvance &&
														<TabQuestions 
															personne={this.props.beneficiaire} 
															actif={this.props.ihm.beneficiaireTab == "questions"}
															enregistreBeneficiaire={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)}
															enregistreProche={(proche) => this.enregistreProche(proche)}
															conseiller={this.props.conseiller}
															copieQuestions={(questions, destinataires) => this.copieQuestions(questions, destinataires)}
															ajouteQuestionsDefaut={(questions, personne) => this.ajouteQuestionsDefaut(questions, personne)}
														 />
													}													
													<TabInteractions beneficiaire={this.props.beneficiaire} actif={this.props.ihm.beneficiaireTab == "interactions"} enregistre={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)} categories={this.props.data.categoriesInteractions} />
													<TabPeriodesConges beneficiaire={this.props.beneficiaire} actif={this.props.ihm.beneficiaireTab == "periodesConges"} enregistre={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)} />
													<TabPeriodes beneficiaire={this.props.beneficiaire} actif={this.props.ihm.beneficiaireTab == "periodes"} enregistre={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
				<div key="modal-beneficiairenouvelleinteraction" id="modal-beneficiairenouvelleinteraction" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Nouvelle interaction</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formInteractionValide()} onClick={() => this.ajouteInteraction()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormInteraction
                                    interaction={this.state.editingInteraction}
                                    beneficiaire={this.props.beneficiaire}
									modifie={(interaction) => this.setEditingInteraction(interaction)}
									categories={this.props.data.categoriesInteractions}
									origine="beneficiaire"
                                />
                            </div>
                        </div>
                    </div>
                </div>
			</div>	
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(Beneficiaire));