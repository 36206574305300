import React, { Component } from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CalendarService from '../../services/calendar';
import DataService from '../../services/data';

import FormEmploi from './FormEmploi';
import { getTerme } from '../../services/contextualisation';
import SecurityService from '../../services/security';

class FormBeneficiaire extends Component {

    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState() {

        let dataService = new DataService()

        return {
            beneficiaire: {
                id: null,
                classe: "Beneficiaire",
                nom: "",
                prenom: "",
                genre: "h",
                //suiviAvance: dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) < this.props.structure.nbMaxBeneficiairesSuiviAvance,
                suiviAvance: true,
                //bibliotheque: dataService.getBibliotheques().length > 0 ? dataService.getBibliotheques()[0].id : null,
                bibliotheque: null,
                adresse: {
                    id: null,
                    classe: "Adresse",
                    adresse1: "",
                    codePostal: "",
                    ville: ""
                },
                commentaire: "",
                situationFamiliale: "",
                email: "",
                telephone: "",
                modalite: "sms",
                emplois: [{
                    id: null,
                    classe: "Emploi",
                    entreprise: "",
                    secteur: null,
                    sites: [{
                        id: null,
                        classe: "Adresse",
                        adresse1: "",
                        codePostal: "",
                        ville: ""
                    }],
                    poste: "",
                    commentaire: "",
                    debut: null,
                    debutWidget: new Date(),
                    fin: null,
                    finWidget: null,
                    /*proches: [{
                        id: null,
                        classe: "Proche",
                        nom: "",
                        prenom: "",
                        genre: "h",
                        description: "superieur",
                        email: "",
                        telephone: ""
                    }]*/
                    proches: []
                }],
                proches: [{
                    id: null,
                    classe: "Proche",
                    nom: "",
                    prenom: "",
                    genre: "h",
                    description: "parent",
                    email: "",
                    telephone: ""
                }],
                filtres: [],
                conseillers: this.props.conseiller && !this.props.conseiller.seulementAdmin ? [this.props.conseiller.id] : [],
                structure: this.props.structure.id
            },
            volet: "informations",
            indexEmploi: 0,
            erreurs: []
        };
    }

    componentDidMount() {
        console.log("formbeneficiaire did mount");
        
        window.jQuery("#modal-accueilnouvellepersonne").on('hidden.bs.modal', () => {
            this.setState(this.initState());
        });

        window.jQuery("#modal-nouvellepersonne").on('hidden.bs.modal', () => {
            this.setState(this.initState());
        });
    }

    componentDidUpdate(prevProps) {
        let dataService = new DataService()

        if (this.state.beneficiaire.suiviAvance && dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviAvance) {
            this.state.beneficiaire.suiviAvance = false
            this.setState({
                beneficiaire: this.state.beneficiaire
            })
        }
        if (this.state.beneficiaire.suiviNormal && dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviNormal) {
            this.state.beneficiaire.suiviAvance = true
            this.setState({
                beneficiaire: this.state.beneficiaire
            })
        }
    }


    
    modifieBeneficiaire(champ, val, autre={}) {
        let beneficiaire = this.state.beneficiaire;
        if (champ == "nom") {
            beneficiaire.nom = val;
        }
        if (champ == "prenom") {
            beneficiaire.prenom = val;
        }
        /*
        if (champ == "genre") {
            beneficiaire.genre = val;
        }
        */
        if (champ == "adresse-adresse1") {
            beneficiaire.adresse.adresse1 = val;
        }
        if (champ == "adresse-codePostal") {
            if (/^[0-9]*$/.test(val)) {
                beneficiaire.adresse.codePostal = val;
            }
        }
        if (champ == "adresse-ville") {
            beneficiaire.adresse.ville = val;
        }
        if (champ == "suiviAvance") {
            beneficiaire.suiviAvance = val;
        }
        if (champ == "bibliotheque") {
            beneficiaire.bibliotheque = val;
        }
        if (champ == "email") {
            beneficiaire.email = val;
        }
        if (champ == "telephone") {
            beneficiaire.telephone = val;
        }
        if (champ == "modalite") {
            beneficiaire.modalite = val;
        }        
        if (champ == "commentaire") {
            beneficiaire.commentaire = val;
        }
        if (champ == "situationFamiliale") {
            beneficiaire.situationFamiliale = val;
        }
        if (champ == "emplois") {
            beneficiaire.emplois = val;
        }
        if (champ == "proches") {
            beneficiaire.proches = val;
        }
        console.log(beneficiaire);
        this.setState({
            beneficiaire: beneficiaire,
            volet: (autre && autre.volet) ? autre.volet : this.state.volet,
            indexEmploi: (autre && autre.indexEmploi) ? autre.indexEmploi : this.state.indexEmploi,
            erreurs: this.valideChamp(champ)
        });
        
    }

    valideChamp(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
		if (champ == "nom" && this.state.beneficiaire.nom == "") {
			erreurs.push(champ);
        }
        if (champ == "prenom" && this.state.beneficiaire.prenom == "") {
			erreurs.push(champ);
		}
		if (champ == "email") {
            if (this.state.beneficiaire.email == "") {
                if (this.state.beneficiaire.telephone == "" || !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.beneficiaire.telephone)) {
                    erreurs.push(champ);
                }
            }
			else if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.beneficiaire.email)) {
                erreurs.push(champ);
            }
            else {
                if (this.state.beneficiaire.telephone == "") {
                    if (erreurs.includes("telephone")) {
                        erreurs.splice(erreurs.indexOf("telephone"), 1);
                    }
                }
            }
		}
		if (champ == "telephone") {
			if (this.state.beneficiaire.telephone == "") {
                if (this.state.beneficiaire.email == "" || !/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.beneficiaire.email)) {
                    erreurs.push(champ);
                }
            }
			else if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.beneficiaire.telephone)) {
                erreurs.push(champ);
            }
            else {
                if (this.state.beneficiaire.email == "") {
                    if (erreurs.includes("email")) {
                        erreurs.splice(erreurs.indexOf("email"), 1);
                    }
                }
            }
        }
        if (champ == "adresse-codePostal") {
			if (this.state.beneficiaire.adresse.codePostal != "" && !/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(this.state.beneficiaire.adresse.codePostal)) {
                erreurs.push(champ);
            }
        }
        return erreurs;
	}

    ajusteModalite() {
		let modalites = [];
		if (this.state.beneficiaire.telephone) {
			modalites.push("sms");
		}
		if (this.state.beneficiaire.email) {
			modalites.push("email");
        }
        if (this.state.beneficiaire.facebookMessengerId) {
			modalites.push("facebook");
		}
		if (modalites.length > 0 && !modalites.includes(this.state.beneficiaire.modalite)) {
			this.modifieBeneficiaire("modalite", modalites[0]);
		}
    }
    
    ajouteEmploi() {
        let emplois = this.state.beneficiaire.emplois;
        emplois.push({
            id: null,
            classe: "Emploi",
            entreprise: "",
            commentaire: "",
            secteur: null,
            sites: [{
                id: null,
                classe: "Adresse",
                adresse1: "",
                codePostal: "",
                ville: ""
            }],
            poste: "",
            debut: null,
            debutWidget: new Date(),
            fin: null,
            finWidget: null,
            /*proches: [{
                id: null,
                classe: "Proche",
                nom: "",
                prenom: "",
                genre: "h",
                description: "superieur",
                email: "",
                telephone: ""
            }]*/
            proches: []
        });
        this.modifieBeneficiaire('emplois', emplois, {indexEmploi: emplois.length - 1});
    }

    modifieEmploi(index, emploi) {
        let emplois = this.state.beneficiaire.emplois;
        emplois[index] = emploi;
        this.modifieBeneficiaire('emplois', emplois);
    }

    ajouteProche(e) {
        e.preventDefault();
        let proches = this.state.beneficiaire.proches;
        proches.push({
            id: null,
            classe: "Proche",
            nom: "",
            prenom: "",
            genre: "h",
            description: "parent",
            email: "",
            telephone: ""
        });
        this.modifieBeneficiaire("proches", proches);
    }

    modifieProche(index, champ, val) {
        let proches = this.state.beneficiaire.proches;
        if (proches[index]) {
            if (champ == "nom") {
                proches[index].nom = val;
            }
            if (champ == "prenom") {
                proches[index].prenom = val;
            }
            /*
            if (champ == "genre") {
                proches[index].genre = val;
            }
            */
            if (champ == "email") {
                proches[index].email = val;
            }
            if (champ == "telephone") {
                proches[index].telephone = val;
            }
            if (champ == "description") {
                proches[index].description = val;
            }
            this.valideChampProche(index, champ);
            this.modifieBeneficiaire('proches', proches);
        }
    }

    valideChampProche(index, champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes("proche-" + index + "-" + champ)) {
            erreurs.splice(erreurs.indexOf("proche-" + index + "-" + champ), 1);
        }
        if (this.state.beneficiaire.proches[index].id != null || this.state.beneficiaire.proches[index].nom != "" || this.state.beneficiaire.proches[index].prenom != "" || this.state.beneficiaire.proches[index].email != "" || this.state.beneficiaire.proches[index].telephone != "") {
            if (champ == "nom" && this.state.beneficiaire.proches[index].nom == "") {
                erreurs.push("proche-" + index + "-nom");
            }
            if (champ == "prenom" && this.state.beneficiaire.proches[index].prenom == "") {
                erreurs.push("proche-" + index + "-prenom");
            }
            if (champ == "email") {
                if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.beneficiaire.proches[index].email)) {
                    erreurs.push("proche-" + index + "-email");
                }               
            }
            if (champ == "telephone") {
                if (this.state.beneficiaire.proches[index].telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.beneficiaire.proches[index].telephone)) {
                    erreurs.push("proche-" + index + "-telephone");
                }                
            }
        } 
        else {
            if (erreurs.includes("proche-" + index + "-nom")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-nom"), 1);
            }
            if (erreurs.includes("proche-" + index + "-prenom")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-prenom"), 1);
            }
            if (erreurs.includes("proche-" + index + "-email")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-email"), 1);
            }
            if (erreurs.includes("proche-" + index + "-telephone")) {
                erreurs.splice(erreurs.indexOf("proche-" + index + "-telephone"), 1);
            }
        }
        return erreurs;
    }

    voletSuivant() {
        let indexEmploi = this.state.indexEmploi;
        let volet = this.state.volet;
        if (this.state.volet == "informations") {
            if (this.state.beneficiaire.emplois.length != 0) {
                volet = "emplois";
                indexEmploi = 0;
            }
            else {
                volet = "proches";
                indexEmploi = 0;
            }
        }
        if (this.state.volet == "emplois") {
            if (this.state.indexEmploi == this.state.beneficiaire.emplois.length - 1) {
                volet = "proches";
            }
            else {
                indexEmploi++;
            }
        }
        this.setState({
            volet: volet,
            indexEmploi: indexEmploi
        })
    }

    voletPrecedent() {
        let indexEmploi = this.state.indexEmploi;
        let volet = this.state.volet;
        if (this.state.volet == "emplois") {
            if (this.state.indexEmploi == 0) {
                volet = "informations";
            }
            else {
                indexEmploi--;
            }
        }
        if (this.state.volet == "proches") {
            if (this.state.beneficiaire.emplois.length != 0) {
                volet = "emplois";
                indexEmploi = this.state.beneficiaire.emplois.length - 1;
            }
            else {
                volet = "informations";
                indexEmploi = 0;
            }
        }
        this.setState({
            volet: volet,
            indexEmploi: indexEmploi
        })
    }

    passeVoletEmploi(index) {
        let emplois = this.state.beneficiaire.emplois;
        emplois.splice(index, 1);
        this.modifieBeneficiaire("emplois", emplois, {volet: "proches"});
    }
    
    voletInformationsValide() {
        return (
            this.state.beneficiaire.nom != ""
            && this.state.beneficiaire.prenom != ""
            && (this.state.beneficiaire.adresse.codePostal == "" || /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(this.state.beneficiaire.adresse.codePostal))
            && (this.state.beneficiaire.email != "" || this.state.beneficiaire.telephone != "")
            && (this.state.beneficiaire.email == "" || /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.beneficiaire.email))
            && (this.state.beneficiaire.telephone == "" || /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.beneficiaire.telephone))
            && (
                (!this.props.conseiller || (this.props.conseiller.filtres.length === 0 || this.state.beneficiaire.filtres.length > 0))
            )
        );
    }

    voletEmploisValide(index) {
        if (index >= this.state.beneficiaire.emplois) return true;
        let emploi = this.state.beneficiaire.emplois[index];
        return (
            emploi.entreprise != ""
            && emploi.poste != ""
            && this.voletEmploisProchesValide(emploi)
            && this.voletEmploisSitesValide(emploi)
        );
    }

    voletEmploisProchesValide(emploi) {
        let valide = true;
        emploi.proches.map((proche) => {
            if (
                (proche.nom != "" || proche.prenom != "" || proche.telephone != "" || proche.email != "")
                && (proche.nom == "" || proche.prenom == "" || (proche.telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(proche.telephone)) || !/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(proche.email))
            ) {
                valide = false;
            }
        });
        return valide;
    }

    voletEmploisSitesValide(emploi) {
        let valide = true;
        emploi.sites.map((site) => {
            if (site.codePostal != "" && !/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(site.codePostal)) {
                valide = false;
            }
        });
        return valide;
    }

    voletProchesValide() {
        let valide = true;
        this.state.beneficiaire.proches.map((proche) => {
            if (
                (proche.nom != "" || proche.prenom != "" || proche.telephone != "" || proche.email != "")
                && (proche.nom == "" || proche.prenom == "" || (proche.telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(proche.telephone)) || !/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(proche.email))
            ) {
                valide = false;
            }
        });
        return valide;
    }

    prepareBeneficiaire(beneficiaire) {
        beneficiaire.emplois = this.prepareEmplois(beneficiaire.emplois);
        beneficiaire.proches = this.prepareProches(beneficiaire.proches);
        return beneficiaire;
    }

    prepareEmplois(emplois) {
        let calendarService = new CalendarService();

        let newEmplois = emplois.filter((emploi) => {
            return emploi.entreprise != "";
        });
        newEmplois.map((emploi, index) => {
            newEmplois[index].proches = this.prepareProches(emploi.proches);
            newEmplois[index].sites = this.prepareSites(emploi.sites);
            newEmplois[index].debut = calendarService.formatteDate(emploi.debutWidget);
            newEmplois[index].fin = calendarService.formatteDate(emploi.finWidget);
        });
        return newEmplois;
    }

    prepareProches(proches) {
        let newProches = proches.filter((proche) => {
            return (proche.nom != "")
        });
        return newProches;
    }

    prepareSites(sites) {
        let newSites = sites.filter((site) => {
            return (site.adresse1 != "" || site.codePostal != "" || site.ville != "")
        });
        return newSites;
    }

    enregistreBeneficiaire() {
        let beneficiaire = this.prepareBeneficiaire(this.state.beneficiaire);
        console.log(beneficiaire);
        this.props.enregistre(beneficiaire);
        this.setState({

        })
    }

    // remplacé par fonction en-dessous pour n'avoir qu'un seul filtre par niveau
    toggleFiltre(id) {
        if (this.state.beneficiaire.filtres.includes(id)) {
            this.state.beneficiaire.filtres.splice(this.state.beneficiaire.filtres.indexOf(id), 1)            
            this.props.structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.beneficiaire.filtres.includes(sousFiltre.id)) {
                            this.state.beneficiaire.filtres.splice(this.state.beneficiaire.filtres.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.beneficiaire.filtres.push(id)            
            this.props.structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.beneficiaire.filtres.includes(filtre.id)) {
                            this.state.beneficiaire.filtres.push(filtre.id)
                        }
                    }
                })
            })
        }

        let securityService = new SecurityService()
        let dataService = new DataService()
        for (let c in this.state.beneficiaire.conseillers) {
            if (securityService.canFollowBeneficiaire(dataService.getConseillerById(this.state.beneficiaire.conseillers[c]), this.state.beneficiaire) == false) {
                this.state.beneficiaire.conseillers.splice(c, 1)
            }
        }

        console.log(this.state.beneficiaire)

        this.setState({
            beneficiaire: this.state.beneficiaire
        })
    }

    // un seul filtre par niveau
    toggleFiltre(id) {
        if (this.state.beneficiaire.filtres.includes(id)) {
            this.state.beneficiaire.filtres.splice(this.state.beneficiaire.filtres.indexOf(id), 1)            
            this.props.structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.beneficiaire.filtres.includes(sousFiltre.id)) {
                            this.state.beneficiaire.filtres.splice(this.state.beneficiaire.filtres.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.beneficiaire.filtres = [id]
            this.props.structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.beneficiaire.filtres.includes(filtre.id)) {
                            this.state.beneficiaire.filtres.push(filtre.id)                            
                        }
                    }
                })
            })
        }
        let securityService = new SecurityService()
        let dataService = new DataService()
        for (let c in this.state.beneficiaire.conseillers) {
            if (securityService.canFollowBeneficiaire(dataService.getConseillerById(this.state.beneficiaire.conseillers[c]), this.state.beneficiaire) == false) {
                this.state.beneficiaire.conseillers.splice(c, 1)
            }
        }

        console.log(this.state.beneficiaire)

        this.setState({
            beneficiaire: this.state.beneficiaire
        })  
    }

    toggleConseiller(id) {
        if (this.state.beneficiaire.conseillers.includes(id)) {
            this.state.beneficiaire.conseillers.splice(this.state.beneficiaire.conseillers.indexOf(id), 1)            
        }
        else {
            this.state.beneficiaire.conseillers.push(id)            
        }
        this.setState({
            beneficiaire: this.state.beneficiaire
        })
    }

	render() {

        let dataService = new DataService()
        let securityService = new SecurityService()

        let modalites = [];
		if (this.state.beneficiaire.telephone) {
			modalites.push(
				<option key="option-modalite-sms" value="sms">SMS : {this.state.beneficiaire.telephone}</option>
			);
		}
		if (this.state.beneficiaire.email) {
			modalites.push(
				<option key="option-modalite-email" value="email">Email : {this.state.beneficiaire.email}</option>
			);
        }
        if (this.state.beneficiaire.facebookMessengerId) {
			modalites.push(
				<option key="option-modalite-facebook" value="facebook">Facebook : {this.state.beneficiaire.facebookMessengerId}</option>
			);
        }

        let nbVolets = 2 + (this.state.beneficiaire.emplois.length - 1);
        //let nbVolets = 3 + (this.state.beneficiaire.emplois.length - 1); // Demande de Silvia le 21/03/2022 : on ne demande plus les proches
        let indexVolet = 1;
        if (this.state.volet == "emplois") {
            indexVolet += (this.state.indexEmploi + 1);
        }
        if (this.state.volet == "proches") {
            indexVolet += (this.state.beneficiaire.emplois.length + 1);
        }

        let voletInformations = 
            <div className="modal-content">
                <div className="modal-header">
                    <div className="header-buttons ml-auto">                    
                        {/*<button type="button" className="btn btn-primary" disabled={!this.voletInformationsValide()} onClick={() => this.voletSuivant()}>Suivant <i className="mdi mdi-arrow-right"></i></button>*/}
                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.voletInformationsValide()} onClick={() => this.enregistreBeneficiaire()}>Enregistrer</button>
                        {this.props.structure.domaine != "entreprise" && <button type="button" className="btn btn-annexe contour" disabled={!this.voletInformationsValide()} onClick={() => this.voletSuivant()}>Ajouter un emploi</button>}
                        <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                    </div>
                </div>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">{/*{indexVolet}/{nbVolets} - */}{getTerme("Nouvel utilisateur", this.props.structure.domaine)} - Identité et contact</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="form-row">
                                    {/*
                                    <div className="form-group col-md-2">
                                        <select className="form-control select-genre" value={this.state.beneficiaire.genre} onChange={(e) => this.modifieBeneficiaire("genre", e.target.value)}>
                                            <option value="h">M.</option>
                                            <option value="f">Mme</option>
                                        </select>
                                    </div>
                                    */}
                                    <div className="form-group col-md-6">
                                        <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("nom") ? " input-error": "")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Nom *</div>
                                            </div>
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.beneficiaire.nom} onChange={(e) => this.modifieBeneficiaire("nom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChamp("nom")})} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("prenom") ? " input-error": "")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Prénom *</div>
                                            </div>
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.beneficiaire.prenom} onChange={(e) => this.modifieBeneficiaire("prenom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChamp("prenom")})} />
                                        </div>
                                    </div>
                                </div>
                                {/*<h6>Adresse :</h6>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">N° et nom de rue</div>
                                            </div>
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.beneficiaire.adresse.adresse1} onChange={(e) => this.modifieBeneficiaire("adresse-adresse1", e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <div className={"input-group" + (this.state.erreurs.includes("adresse-codePostal") ? " input-error": "")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Code postal</div>
                                            </div>
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.beneficiaire.adresse.codePostal} onChange={(e) => this.modifieBeneficiaire("adresse-codePostal", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChamp("adresse-codePostal")})} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-8">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Commune</div>
                                            </div>
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.beneficiaire.adresse.ville} onChange={(e) => this.modifieBeneficiaire("adresse-ville", e.target.value)} />
                                        </div>
                                    </div>
                                </div>*/}
                                {/*<div className="form-group row">
                                    <label className="col-form-label ml-3"><h6>Type de suivi :</h6></label>
                                    <div className="">
                                        <select className="form-control" value={this.state.beneficiaire.suiviAvance ? "avance" : "normal"} onChange={(e) => this.modifieBeneficiaire("suiviAvance", e.target.value == "avance")} >
                                            {dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) < this.props.structure.nbMaxBeneficiairesSuiviAvance &&
                                                <option value="avance">Suivi avec signaux faibles</option>
                                            }
                                            {dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) < this.props.structure.nbMaxBeneficiairesSuiviNormal &&
                                                <option value="normal">Suivi sans signaux faibles</option>
                                            }       
                                        </select>
                                    </div>
                                        </div>    */}
                                {/*{this.state.beneficiaire.suiviAvance && dataService.getBibliotheques().length > 0 &&
                                    <div className="form-group row">
                                        <label className="col-form-label ml-3"><h6>Bibliothèque de questions :</h6></label>
                                        <div className="">
                                            <select className="form-control" value={this.state.beneficiaire.bibliotheque} onChange={(e) => this.modifieBeneficiaire("bibliotheque", e.target.value)}>
                                                {dataService.getBibliotheques().filter(b => b.cible == "beneficiaire" && b.domaine == this.props.structure.domaine).map((bibliotheque, iBibliotheque) => {
                                                    return <option key={iBibliotheque} value={bibliotheque.id}>{bibliotheque.nom}</option>
                                                })}                                                       
                                            </select>
                                        </div>
                                    </div>                                
                                } */}                                                                                                                       
                                <h6>Pour l'envoi des questions :</h6>
                                <div className="form-group col-md-12">Saisir au minimum un moyen de contact</div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("email") ? " input-error": "")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Mail **</div>
                                            </div>
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.beneficiaire.email} onChange={(e) => this.modifieBeneficiaire("email", e.target.value)} onBlur={() => {this.ajusteModalite();  this.setState({erreurs: this.valideChamp("email")})}} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("telephone") ? " input-error": "")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Téléphone **</div>
                                            </div>
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.beneficiaire.telephone} onChange={(e) => this.modifieBeneficiaire("telephone", e.target.value)} onBlur={() => {this.ajusteModalite(); this.setState({erreurs: this.valideChamp("telephone")})}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label ml-3">Envoyer les questions automatiques sur :</label>
                                    <div className="">
                                        <select className="form-control" value={this.state.beneficiaire.modalite} onChange={(e) => this.modifieBeneficiaire("modalite", e.target.value)} >
                                            {modalites}
                                        </select>
                                    </div>
                                </div>                                
                                <br clear="both" />
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {(!this.props.conseiller || this.props.conseiller.filtres.length > 0) &&
                                            <>
                                                <h6>Filtres *</h6> 
                                                {this.props.structure.filtres
                                                .filter(filtre => {
                                                    if (!this.props.conseiller) return true
                                                    if (this.props.conseiller.responsabilites.includes(this.props.structure.id)) return true
                                                    return this.props.conseiller.filtres.includes(filtre.id)
                                                })
                                                .map((filtre, iFiltre) => {
                                                    return (
                                                        <React.Fragment key={"filtre-" + iFiltre}>
                                                            <div className="checkbox-wrapper mr-2">
                                                                <input type="checkbox" id={"formbeneficiaire-toggle-filtre-" + filtre.id} checked={this.state.beneficiaire.filtres.includes(filtre.id)} onChange={(e) => this.toggleFiltre(filtre.id)} />
                                                                <label htmlFor={"formbeneficiaire-toggle-filtre-" + filtre.id} className="toggle"></label>
                                                            </div>
                                                            <label htmlFor={"formbeneficiaire-toggle-filtre-" + filtre.id}> {filtre.nom}</label>
                                                            <br />
                                                            {filtre.filtres
                                                            .filter(filtre => {
                                                                if (!this.props.conseiller) return true
                                                                if (this.props.conseiller.responsabilites.includes(this.props.structure.id)) return true
                                                                if (this.props.conseiller.filtres.includes(filtre.id)) return true
                                                                if (filtre.filtres.filter(f => this.props.conseiller.includes(f.id)).length == 0) return true
                                                                return false                                                        
                                                            })
                                                            .map((sousFiltre, iSousFiltre) => {
                                                                return (
                                                                    <React.Fragment key={"sousfiltre-" + iSousFiltre}>
                                                                        <div className="ml-3">
                                                                            <div className="checkbox-wrapper mr-2">
                                                                                <input type="checkbox" id={"formbeneficiaire-toggle-filtre-" + sousFiltre.id} checked={this.state.beneficiaire.filtres.includes(sousFiltre.id)} onChange={(e) => this.toggleFiltre(sousFiltre.id)} />
                                                                                <label htmlFor={"formbeneficiaire-toggle-filtre-" + sousFiltre.id} className="toggle"></label>
                                                                            </div>
                                                                            <label htmlFor={"formbeneficiaire-toggle-filtre-" + sousFiltre.id}> {sousFiltre.nom}</label>
                                                                        </div>                                                
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )
                                                })}
                                                <br />
                                            </>
                                        } 
                                    </div>
                                    {(!this.props.conseiller || this.props.structure.responsables.includes(this.props.conseiller.id)) &&
                                        <div className='col-md-6'>
                                            <h6>{getTerme('Accompagnants', this.props.structure.domaine)}</h6> 
                                            {this.props.structure.conseillers                                            
                                            .map(c => dataService.getConseillerById(c))
                                            .filter(c => c.actif)
                                            .filter(c => !c.seulementAdmin)
                                            .sort((a, b) => {
                                                if (securityService.canFollowBeneficiaire(a, this.state.beneficiaire) != securityService.canFollowBeneficiaire(b, this.state.beneficiaire)) {
                                                    return securityService.canFollowBeneficiaire(a, this.state.beneficiaire) === true ? -1 : 1
                                                }
                                                return a.nom + a.prenom < b.nom + b.prenom ? -1 : 1
                                            })
                                            .map((conseiller, indexConseiller) => {                                                
                                                return (
                                                   <React.Fragment key={indexConseiller}>
                                                        <div className="checkbox-wrapper mr-2">
                                                            <input type="checkbox" id={"formbeneficiaire-toggle-conseiller-" + conseiller.id} checked={this.state.beneficiaire.conseillers.includes(conseiller.id) || securityService.canFollowBeneficiaire(conseiller, this.state.beneficiaire)} onChange={(e) => this.toggleConseiller(conseiller.id)} disabled={securityService.canFollowBeneficiaire(conseiller, this.state.beneficiaire)} />
                                                            <label htmlFor={"formbeneficiaire-toggle-conseiller-" + conseiller.id} className="toggle"></label>
                                                        </div>
                                                        <label htmlFor={"formbeneficiaire-toggle-conseiller-" + conseiller.id}> {conseiller.prenom} {conseiller.nom} {securityService.canFollowBeneficiaire(conseiller, this.state.beneficiaire) ? <>(automatique)</> : <></>}</label>
                                                        <br />
                                                    </React.Fragment>
                                                )
                                            })}                                            
                                        </div>
                                    }                                    
                                </div>                                  
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        ;
    
        let voletEmplois = (this.state.indexEmploi >= this.state.beneficiaire.emplois.length) ? null : 
            <div className="modal-content">
                <div className="modal-header">
                    <div className="header-buttons ml-auto">        
                        {/*<button type="button" className="btn btn-primary" disabled={!this.voletEmploisValide(this.state.indexEmploi)} onClick={() => this.voletSuivant()}>Suivant <i className="mdi mdi-arrow-right"></i></button>*/}                        
                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.voletEmploisValide(this.state.indexEmploi)} onClick={() => this.enregistreBeneficiaire()}>Enregistrer</button>
                        {this.props.structure.domaine != "entreprise" && <button type="button" className="btn btn-annexe contour" disabled={!this.voletEmploisValide(this.state.indexEmploi)} onClick={() => this.ajouteEmploi()}>Ajouter un emploi</button>}
                        {/*<button type="button" className="btn btn-annexe contour" onClick={() => this.passeVoletEmploi(this.state.indexEmploi)}>Passer cette étape</button>*/}
                        <button type="button" className="btn btn-annexe contour" onClick={() => this.voletPrecedent()}><i className="mdi mdi-arrow-left"></i> Précédent</button>
                        <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                    </div>
                </div> 
                <div className="modal-header">
                    <h5 className="modal-title mt-0">{/*{indexVolet}/{nbVolets} - */}{getTerme("Nouvel utilisateur", this.props.structure.domaine)} - Emploi</h5>
                </div>               
                <div className="modal-body">
                    <FormEmploi
                        emploi={this.state.beneficiaire.emplois[this.state.indexEmploi]}
                        modifie={(emploi) => this.modifieEmploi(this.state.indexEmploi, emploi)}
                    />
                </div>
            </div>
        ;

        let proches = [];
        this.state.beneficiaire.proches.map((proche, index) => {
            let obligatoire = (proche.nom != "" || proche.prenom != "" || proche.email != "" || proche.telephone != "");
            proches.push(
                <React.Fragment key={"row-proche" + index}>
                    <div className="form-row">
                        {/*
                        <div className="form-group col-md-2">
                            <select className="form-control select-genre" value={proche.genre} onChange={(e) => this.modifieProche(index, "genre", e.target.value)}>
                                <option value="h">M.</option>
                                <option value="f">Mme</option>
                            </select>
                        </div>
                        */}
                        <div className="form-group col-md-5">
                            <div className={"input-group" + (obligatoire ? " input-obligatoire":"") + (this.state.erreurs.includes("proche-" + index + "-nom") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Nom{obligatoire ? " *":""}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.nom} onChange={(e) => this.modifieProche(index, "nom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "nom")})} />
                            </div>
                        </div>
                        <div className="form-group col-md-5">
                            <div className={"input-group" + (obligatoire ? " input-obligatoire":"") + (this.state.erreurs.includes("proche-" + index + "-prenom") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Prénom{obligatoire ? " *":""}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.prenom} onChange={(e) => this.modifieProche(index, "prenom", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "prenom")})} />
                            </div>
                        </div>
                    </div>                 
                    <div className="form-group row">
                        <label className="col-form-label ml-3">Type de relation :</label>
                        <div className="">
                        <select className="form-control" value={proche.description} onChange={(e) => this.modifieProche(index, "description", e.target.value)}>
                                <option value="parent">Parent</option>
                                <option value="conjoint">Conjoint</option>
                                <option value="ami">Ami</option>
                                <option value="personne-confiance">Personne de confiance</option>
                                <option value="famille">Autre membre de la famille</option>
                            </select>
                        </div>
                    </div> 
                    <div className="form-row">  
                        <div className="form-group col-md-6">
                            <div className={"input-group" + (obligatoire ? " input-obligatoire":"") + (this.state.erreurs.includes("proche-" + index + "-email") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Mail{obligatoire ? " *":""}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.email} onChange={(e) => this.modifieProche(index, "email", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "email")})} />
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className={"input-group" + (this.state.erreurs.includes("proche-" + index + "-telephone") ? " input-error": "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Téléphone</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={proche.telephone} onChange={(e) => this.modifieProche(index, "telephone", e.target.value)} onBlur={() => this.setState({erreurs: this.valideChampProche(index, "telephone")})} />
                            </div>
                        </div>
                    </div>
                    {index != this.state.beneficiaire.proches.length - 1 && <hr />}
                </React.Fragment>
            );
        });
        let voletProches = 
            <div className="modal-content">
                <div className="modal-header">
                    <div className="header-buttons ml-auto">                                    
                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.voletProchesValide()} onClick={() => this.enregistreBeneficiaire()}>Enregistrer</button>
                        <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-annexe contour" onClick={() => this.voletPrecedent()}><i className="mdi mdi-arrow-left"></i> Précédent</button>
                    </div>
                </div>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">{/*{indexVolet}/{nbVolets} - */}{getTerme("Nouvel utilisateur", this.props.structure.domaine)} - Famille et proches</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <form> 
                                <h6>Personnes de l'entourage proche :</h6>
                                {proches}                             
                                <button className="m-0 btn btn-annexe contour action" onClick={(e) => this.ajouteProche(e)}><i className="mdi mdi-plus"></i> Ajouter une nouvelle personne</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        ;

        return (
            <>
                {this.state.volet == "informations" && voletInformations}
                {this.state.volet == "emplois" && voletEmplois}
                {this.state.volet == "proches" && voletProches}
            </>
        )
	}
}

export default FormBeneficiaire;