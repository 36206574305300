import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../assets/images/logo.png';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  history: props.route.history
	}
  }

class Login extends Component {

	constructor(props) {
		super(props);

		this.state = {
			email: "",
			password: "",
			remember: false,
			etat: null,
			test: false
		};
	}

	componentDidMount() {
		console.log("login did mount");
	}

	changeIdentifiants(champ, val) {
		if (champ == "email") {
			this.setState({
				email: val
			});
		}
		if (champ == "password") {
			this.setState({
				password: val
			});
		}
		if (champ == "remember") {
			this.setState({
				remember: !this.state.remember
			});
		}
	}

	formLoginValide() {
		return (
			/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.email)
			&& this.state.password.length > 0
		);
	}

	login(e) {
		e.preventDefault();
		this.setState({
			etat: "login-encours"
		});
		
		this.props.dispatch({ type: "DATA_LOGIN", payload: {
			email: this.state.email,
			password: this.state.password,
			remember: this.state.remember,
			callbackOk: () => {
				this.setState({
					etat: "login-ok"
				});
				let urlCible = this.props.ihm.urlCible;
				if (urlCible) {
					this.props.history.replace(this.props.ihm.urlCible);
					this.props.dispatch({ type: "IHM_URLCIBLE", payload: "/" })
				}
			},
			callbackNok: () => {
				this.setState({
					etat: "login-nok",
					password: ""
				});
			},
			callbackNokVPN: () => {
				this.setState({
					etat: "login-nok-vpn",
					password: ""
				});
			},
			callbackNokPasswordExpire: (data) => {
				this.setState({
					etat: "login-nok-passwordexpire",
					password: ""
				});
				this.props.history.replace('/compte/mot-de-passe/' + data.token + "?expire")
			},
			callbackNokCompteInactif: () => {
				this.setState({
					etat: "login-nok-compteinactif",
					password: ""
				});				
			},
			callbackErreur: () => {
				this.setState({
					etat: "login-erreur",
					password: ""
				});
			}
		}});
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/login" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						<h4 className="text-muted font-18 m-b-5 text-center">Bienvenue sur l'App WESIReport !</h4>
						<p className="text-muted text-center">Merci de vous identifier.</p>
						{this.state.etat == "login-nok" && 
							<div className="alert alert-warning bg-warning text-white" role="alert">
								Les identifiants saisis sont incorrects, veuillez recommencer ou contacter votre administrateur.
							</div>
						}
						{this.state.etat == "login-nok-vpn" && 
							<div className="alert alert-warning bg-warning text-white" role="alert">
								Votre adresse IP n'est pas autorisée à se connecter avec ce compte utilisateur.
							</div>
						}
						{this.state.etat == "login-nok-compteinactif" && 
							<div className="alert alert-warning bg-warning text-white" role="alert">
								Votre compte utilisateur n'est pas actif, veuillez contacter votre administrateur.
							</div>
						}
						{this.state.etat == "login-erreur" && 
							<div className="alert alert-warning bg-warning text-white" role="alert">
								Une erreur s'est produite, veuillez recommencer ou contacter votre administrateur.
							</div>
						}
						<form className="form-horizontal m-t-30">
							<div className="form-group">
								<label htmlFor="username">Adresse email</label>
								<input type="text" className="form-control" id="username" disabled={["login-encours", "login-ok"].includes(this.state.etat)} value={this.state.email} onChange={(e) => this.changeIdentifiants("email", e.target.value)} />
							</div>
							<div className="form-group">
								<label htmlFor="userpassword">Mot de passe</label>
								<input type="password" className="form-control" id="userpassword" disabled={["login-encours", "login-ok"].includes(this.state.etat)} value={this.state.password} onChange={(e) => this.changeIdentifiants("password", e.target.value)} />
							</div>
							<div className="form-group row m-t-20">
								<div className="col-6">    
									<div className="checkbox-wrapper mr-2">
										<input type="checkbox" id="checkbox-remember-me" disabled={["login-encours", "login-ok"].includes(this.state.etat)} checked={this.state.remember} onChange={() => this.changeIdentifiants("remember", true)}/>
										<label htmlFor="checkbox-remember-me" className="checkbox-remember-me"></label>
									</div><label htmlFor="checkbox-remember-me">Se souvenir de moi</label>
								</div>
								<div className="col-6 text-right">
									<button type="submit" className="btn btn-primary" disabled={!this.formLoginValide() || ["login-encours", "login-ok"].includes(this.state.etat)} onClick={(e) => this.login(e)}>{(this.state.etat == "login-encours") ? "En cours..." : (this.state.etat == "login-ok" ? "Connecté !" : "Connexion")}</button>
								</div>
							</div>
							<div className="form-group m-t-10 mb-0 row">
								<div className="col-12 m-t-20">
									<NavLink to="/compte/mot-de-passe-oublie" className="text-muted"><i className="mdi mdi-lock"></i> J'ai oublié mon mot de passe</NavLink>
								</div>
							</div>
						</form>
						{this.state.etat == "login-ok" && 
							<div className="alert alert-success bg-success text-white" role="alert">
								Vous êtes maintenant connecté ! Vous allez être redirigé vers votre page d'accueil...
							</div>
						}
						{this.state.test && 
							<>
								<div className="alert alert-warning bg-warning text-white" role="alert">
									Une erreur s'est produite, veuillez recommencer ou contacter votre administrateur.
								</div>
								<div className="alert alert-warning bg-warning text-white" role="alert">
									Les identifiants saisis sont incorrects, veuillez recommencer ou contacter votre administrateur.
								</div>
								<div className="alert alert-success bg-success text-white" role="alert">
									Vous êtes maintenant connecté ! Vous allez être redirigé vers votre page d'accueil...
								</div>
							</>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(Login);
