import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom';

import Footer from '../components/Footer';

import FormTache from '../components/Forms/FormTache';
import FormInteraction from '../components/Forms/FormInteraction';

import DataService from '../services/data';
import CalendarService from '../services/calendar';

const mapStateToProps = (state, props) => {
	console.log(props);
	let dataService = new DataService();
	return {
	  data: state.data,
	  ihm: state.ihm,
	  conseiller: dataService.getConseillerById((state.data.me != null) ? state.data.me.id : 0),
	  history: props.route.history
	}
  }

class Activite extends Component {

	constructor(props) {
        super(props);
        
        this.state = {
            alerteContexte: null,
            editingTache: null,
            editingInteraction: null,
            tachesSelectionnees: [],
            contexteTache: null
        }
	}

	componentDidMount() {
		console.log("activite did mount");
		if (!this.props.conseiller) {
			console.log("oups");
			this.props.history.replace('/');
			return;
        }

        let dataService = new DataService();
        if (!dataService.getMyStructure() || !dataService.getMyStructure().accesSuiviActivite) {
            console.log("oups");
			this.props.history.replace('/');
			return;
        }
        
        this.bindModals();

        console.log(this.props.location);
		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});
    }

    componentDidUpdate(prevProps) {
        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-nouvelletache").off('hidden.bs.modal');
        window.jQuery("#modal-nouvelletache").on('hidden.bs.modal', () => {
            this.setState({
                editingTache: null
            });
        });
        this.props.data.me.taches.map((tache) => {
            window.jQuery("#modal-modiftache-" + tache.id).off('hidden.bs.modal');
            window.jQuery("#modal-modiftache-" + tache.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingTache: null
                });
            });
        });

        this.props.data.me.alertes.map((alerte) => {
            window.jQuery("#modal-modifalerte-" + alerte.id).off('hidden.bs.modal');
            window.jQuery("#modal-modifalerte-" + alerte.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingAlerte: null
                });
            });
        });

        window.jQuery("#modal-activite-nouvelleinteraction").off('hidden.bs.modal');
        window.jQuery("#modal-activite-nouvelleinteraction").on('hidden.bs.modal', () => {
            this.setState({
                editingInteraction: null
            });
        });
    }
    
    setEditingTache(tache) {
        this.setState({
            editingTache: tache
        });
    }

    ajouteTache() {
        let taches = this.props.data.me.taches;
        taches.push(this.state.editingTache);
        this.enregistreUtilisateur("taches", taches);
    }

    enregistreTache() {
        let taches = this.props.data.me.taches;
        taches.map((t, index) => {
            if (taches[index].id == this.state.editingTache.id) {
                taches[index] = this.state.editingTache;
            }
        });
        this.enregistreUtilisateur("taches", taches);
    }

    supprimeTache(tache) {
        let taches = this.props.data.me.taches;
        if (taches.includes(tache)) {
            taches[taches.indexOf(tache)].supprime = true;
        }
        this.setState({
            tachesSelectionnees: []
        });
        this.enregistreUtilisateur("taches", taches);
    }

    supprimeTaches() {
        let taches = this.props.data.me.taches;
        this.getTachesInSelection(this.state.contexteTache).map(tache => {
            if (taches.includes(tache)) {
                taches[taches.indexOf(tache)].supprime = true;
            }
        });
        this.setState({
            tachesSelectionnees: []
        });
        this.enregistreUtilisateur("taches", taches);
    }

    archiveTache(tache) {
        let taches = this.props.data.me.taches;
        if (taches.includes(tache)) {
            taches[taches.indexOf(tache)].execution = true;
        }
        this.setState({
            tachesSelectionnees: []
        });
        this.enregistreUtilisateur("taches", taches);
    }

    archiveTaches() {
        let taches = this.props.data.me.taches;
        this.getTachesInSelection(this.state.contexteTache).map(tache => {
            if (taches.includes(tache)) {
                taches[taches.indexOf(tache)].execution = true;
            }
        });
        this.setState({
            tachesSelectionnees: []
        });
        this.enregistreUtilisateur("taches", taches);
    }

    formTacheValide() {
		return (
            this.state.editingTache
            && this.state.editingTache.description
        )
    }

    setEditingInteraction(interaction) {
        this.setState({
            editingInteraction: interaction
        });
    }

    formInteractionValide() {
		return (
            this.state.editingInteraction
            && this.state.editingInteraction.description
            && this.state.editingInteraction.categorie
            && new Date(this.state.editingInteraction.debut) <= new Date(this.state.editingInteraction.fin)
        )
    }

    archiveAlerte(alerte) {
        let alertes = this.props.data.me.alertes;
        alertes.map((a, index) => {
            if (alertes[index].id == alerte.id) {
                alertes[index].archive = true;
            }
        });
        this.enregistreUtilisateur("alertes", alertes);
    }

    enregistreUtilisateur(champ, val) {
        let utilisateur = null;
        if (champ == "taches") {
			utilisateur = {
                id: this.props.data.me.id,
                classe: this.props.data.me.classe,
                taches: val,
            };
        }
        if (champ == "alertes") {
			utilisateur = {
                id: this.props.data.me.id,
                classe: this.props.data.me.classe,
                alertes: val
            };
        }
        if (utilisateur) {
            this.props.dispatch({type: 'DATA_POST_UTILISATEUR', payload: {utilisateur: utilisateur}});
        }
    }

    toggleTache(tache) {
        let tachesSelectionnees = this.state.tachesSelectionnees;
        if (tachesSelectionnees.includes(tache.id)) {
            tachesSelectionnees.splice(tachesSelectionnees.indexOf(tache.id), 1);
        }
        else {
            tachesSelectionnees.push(tache.id);
        }
        this.setState({
            tachesSelectionnees: tachesSelectionnees
        });
    }

    toggleAllTaches(etat) {
        let tachesSelectionnees = this.state.tachesSelectionnees;
        if (this.nbTachesInSelection(etat) == -1) {
            this.getTachesInSelection(etat).map(t => {
                if (tachesSelectionnees.includes(t.id)) {
                    tachesSelectionnees.splice(tachesSelectionnees.indexOf(t.id));
                }
            });
        }
        else {
            let today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);

            if (etat == "encours") {
                this.props.data.me.taches.filter((t) => t.dateExecution == null).filter((t) => {
                    let date = new Date(t.date);
                    return date >= today
                }).map(t => {
                    if (!tachesSelectionnees.includes(t.id)) {
                        tachesSelectionnees.push(t.id);
                    }
                });
            }
            if (etat == "enretard") {
                this.props.data.me.taches.filter((t) => t.dateExecution == null).filter((t) => {
                    let date = new Date(t.date);
                    return date < today
                }).map(t => {
                    if (!tachesSelectionnees.includes(t.id)) {
                        tachesSelectionnees.push(t.id);
                    }
                });
            }
            if (etat == "archivees") {
                this.props.data.me.taches.filter((t) => t.dateExecution != null).map(t => {
                    if (!tachesSelectionnees.includes(t.id)) {
                        tachesSelectionnees.push(t.id);
                    }
                });
            }
        }
        this.setState({
            tachesSelectionnees: tachesSelectionnees
        })
    }

    getTachesInSelection(etat) {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let taches = [];

        if (etat == "encours") {
            this.props.data.me.taches.filter((t) => t.dateExecution == null).filter((t) => {
                let date = new Date(t.date);
                return date >= today
            }).map(t => {
                if (this.state.tachesSelectionnees.includes(t.id)) taches.push(t);
            });
        }
        if (etat == "enretard") {
            this.props.data.me.taches.filter((t) => t.dateExecution == null).filter((t) => {
                let date = new Date(t.date);
                return date < today
            }).map(t => {
                if (this.state.tachesSelectionnees.includes(t.id)) taches.push(t);
            });
        }
        if (etat == "archivees") {
            this.props.data.me.taches.filter((t) => t.dateExecution != null).map(t => {
                if (this.state.tachesSelectionnees.includes(t.id)) taches.push(t);
            });
        }

        return taches;
    }

    nbTachesInSelection(etat) {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let nb = 0;
        let total = 0;

        if (etat == "encours") {
            this.props.data.me.taches.filter((t) => t.dateExecution == null).filter((t) => {
                let date = new Date(t.date);
                return date >= today
            }).map(t => {
                if (this.state.tachesSelectionnees.includes(t.id)) nb++;
                total++;
            });
        }
        if (etat == "enretard") {
            this.props.data.me.taches.filter((t) => t.dateExecution == null).filter((t) => {
                let date = new Date(t.date);
                return date < today
            }).map(t => {
                if (this.state.tachesSelectionnees.includes(t.id)) nb++;
                total++;
            });
        }
        if (etat == "archivees") {
            this.props.data.me.taches.filter((t) => t.dateExecution != null).map(t => {
                if (this.state.tachesSelectionnees.includes(t.id)) nb++;
                total++;
            });
        }

        if (nb == total && total != 0) return -1;

        return nb;
    }
	
	render() {

		if (!this.props.conseiller) return <></>;

        let dataService = new DataService();
        let calendarService = new CalendarService();

        let modals = [];
        
        let alertes = [];
        let alertesArchivees = [];
        this.props.data.me.alertes.filter((a) => a.dateArchivage == null).sort((a, b) => a.date > b.date ? -1 : 1).map((alerte, index) => {
            let date = new Date(alerte.date);
            let beneficiaire = dataService.getBeneficiaireById(alerte.beneficiaire);
            if (beneficiaire) {
                alertes.push(
                    <li key={"row-alerte-" + alerte.id} className="feed-item">
                        <div className="feed-item-list">
                            <div className="row">
                                <div className="col-md-7">
                                    <span className="date">{date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt} à {("0" + date.getHours()).substr(-2)}h{("0" + date.getMinutes()).substr(-2)}</span> 
                                    <span className="activity-titre"><NavLink to={"/utilisateurs/" + beneficiaire.id}>{alerte.titre}</NavLink></span>
                                </div>
                                <div className="col-md-5">
                                    <div className="btn-alertes">
                                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Créer une interaction"><a href="#" data-toggle="modal" data-target="#modal-activite-nouvelleinteraction" onClick={() => this.setState({alerteContexte: alerte})} className="m-0 btn btn-annexe contour"><i className="mdi mdi-comment-plus-outline"></i></a></span>
                                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Créer une tâche"><a href="#" data-toggle="modal" data-target="#modal-nouvelletache" onClick={() => this.setState({alerteContexte: alerte})} className="m-0 btn btn-annexe contour"><i className="mdi mdi-playlist-plus"></i></a></span>
                                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Archiver"><a href="#" data-toggle="modal" data-target={"#modal-archivealerte-" + alerte.id} className="m-0 btn btn-annexe contour"><i className="mdi mdi-folder-multiple-outline"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>    
                );
                modals.push(
                    <div key={"modal-archivealerte-" + alerte.id} id={"modal-archivealerte-" + alerte.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Archiver une alerte</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>L'alerte "<b>{alerte.titre}</b>" sera archivée. Voulez-vous continuer ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.archiveAlerte(alerte)} data-dismiss="modal">Archiver l'alerte</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }                
        });

        this.props.data.me.alertes.filter(((a) => a.dateArchivage != null)).sort((a, b) => a.dateArchivage > b.dateArchivage ? -1 : 1).map((alerte, index) => {
            let dateArchivage = new Date(alerte.dateArchivage);
            let date = new Date(alerte.date);
            let beneficiaire = dataService.getBeneficiaireById(alerte.beneficiaire);
            if (beneficiaire) {
                alertesArchivees.push(
                    <li key={"row-alerte-" + alerte.id} className="feed-item">
                        <div className="feed-item-list">
                            <span className="date">{date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt} à {("0" + date.getHours()).substr(-2)}h{("0" + date.getMinutes()).substr(-2)}</span> 
                            <span className="activity-titre"><a href={"/utilisateurs/" + beneficiaire.id}>{beneficiaire.prenom} {beneficiaire.nom}</a> {alerte.titre}</span>
                            <span className="date">Archivé le : {dateArchivage.getDate()} {calendarService.mois()[dateArchivage.getMonth()].nomCourt}</span> 
                        </div>
                    </li>
                );
            }
        });

        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let taches = [];
        let tachesRetard = [];
        let tachesArchivees = [];
		if (this.props.conseiller) {
			this.props.data.me.taches.filter((t) => t.dateExecution == null).sort((a, b) => a.date < b.date ? -1 : 1).map((t) => {
                let date = new Date(t.date);
                let beneficiaire = dataService.getBeneficiaireById(t.beneficiaire);
                
                if (date >= today) {
                    taches.push(
                        <tr key={"row-tache-" + t.id} className={(this.state.tachesSelectionnees.includes(t.id) ? "table-warning" : "")}>
                            <td>
                                <div className="checkbox-wrapper">
                                    <input type="checkbox" id={"toggle-tache-" + t.id} checked={this.state.tachesSelectionnees.includes(t.id)} onChange={() => this.toggleTache(t)} />
                                    <label htmlFor={"toggle-tache-" + t.id} className="toggle"></label>
                                </div>
                            </td>
                            <td className="maxdescription">
                                <p className="m-0 table-nom text-muted font-14">{t.description}</p>
                            </td>

                            <td>
                                <p className="m-0 text-muted font-14">{date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt} {date.getFullYear()}</p>
                            </td>
                            <td>
                            {beneficiaire && <NavLink to={"/utilisateurs/" + beneficiaire.id}><p className="m-0 text-muted font-14">{beneficiaire.prenom} {beneficiaire.nom}</p></NavLink>}
                            </td>
                            <td>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Archiver"><a href="#" data-toggle="modal" data-target={"#modal-archivetache-" + t.id} className="m-0 btn btn-annexe contour"><i className="mdi mdi-checkbox-marked-outline"></i></a></span>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" data-toggle="modal" data-target={"#modal-modiftache-" + t.id} onClick={() => this.setEditingTache(t)} className="m-0 btn btn-annexe contour"><i className="dripicons-document-edit"></i></a></span>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" data-toggle="modal" data-target={"#modal-suppressiontache-" + t.id} className="m-0 btn btn-annexe contour"><i className="dripicons-trash"></i></a></span>
                            </td>
                        </tr>
                    );
                }
                else if (date < today) {
                    tachesRetard.push(
                        <tr key={"row-tache-" + t.id} className={(this.state.tachesSelectionnees.includes(t.id) ? "table-warning" : "")}>
                            <td>
                                <div className="checkbox-wrapper">
                                    <input type="checkbox" id={"toggle-tache-" + t.id} checked={this.state.tachesSelectionnees.includes(t.id)} onChange={() => this.toggleTache(t)} />
                                    <label htmlFor={"toggle-tache-" + t.id} className="toggle"></label>
                                </div>
                            </td>
                            <td className="maxdescription">
                                <p className="m-0 table-nom text-muted font-14">{t.description}</p>
                            </td>

                            <td>
                                <p className="m-0 text-muted font-14">{date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt} {date.getFullYear()}</p>
                            </td>
                            <td>
                                {beneficiaire && <NavLink to={"/utilisateurs/" + beneficiaire.id}><p className="m-0 text-muted font-14">{beneficiaire.prenom} {beneficiaire.nom}</p></NavLink>}
                            </td>
                            <td>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Archiver"><a href="#" data-toggle="modal" data-target={"#modal-archivetache-" + t.id} className="m-0 btn btn-annexe contour"><i className="mdi mdi-checkbox-marked-outline"></i></a></span>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" data-toggle="modal" data-target={"#modal-modiftache-" + t.id} onClick={() => this.setEditingTache(t)} className="m-0 btn btn-annexe contour"><i className="dripicons-document-edit"></i></a></span>
                                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" data-toggle="modal" data-target={"#modal-suppressiontache-" + t.id} className="m-0 btn btn-annexe contour"><i className="dripicons-trash"></i></a></span>
                            </td>
                        </tr>
                    );
                } 

                modals.push(
                    <div key={"modal-modiftache-" + t.id} id={"modal-modiftache-" + t.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Tâche</h5>
                                    <div className="header-buttons">
                                        <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formTacheValide()} onClick={() => this.enregistreTache()}>Enregistrer</button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <FormTache 
                                        tache={this.state.editingTache}
                                        alerte={this.state.alerteContexte}
                                        beneficiaires={dataService.getConseillerBeneficiaires(this.props.conseiller)}
                                        modifie={(tache) => this.setEditingTache(tache)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                modals.push(
                    <div key={"modal-suppressiontache-" + t.id} id={"modal-suppressiontache-" + t.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Supprimer une tâche</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>La tâche <b>{t.description}</b> {beneficiaire && <>(pour <b>{beneficiaire.prenom} {beneficiaire.nom}</b>)</>} sera supprimée. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeTache(t)} data-dismiss="modal">Supprimer la tâche</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>	
                );
                modals.push(
                    <div key={"modal-archivetache-" + t.id} id={"modal-archivetache-" + t.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Archiver une tâche</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>La tâche <b>{t.description}</b> {beneficiaire && <>(pour <b>{beneficiaire.prenom} {beneficiaire.nom}</b>)</>} sera archivée. Voulez-vous continuer ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.archiveTache(t)} data-dismiss="modal">Archiver la tâche</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>	
                );
            });

            this.props.data.me.taches.filter((t) => t.dateExecution != null).sort((a, b) => a.date.Execution > b.dateExecution ? -1 : 1).map((t) => {
                let date = new Date(t.dateExecution);
                let beneficiaire = dataService.getBeneficiaireById(t.beneficiaire);
                
                tachesArchivees.push(
                    <tr key={"row-tache-" + t.id} className={(this.state.tachesSelectionnees.includes(t.id) ? "table-warning" : "")}>
                        <td>
                            <div className="checkbox-wrapper">
                                <input type="checkbox" id={"toggle-tache-" + t.id} checked={this.state.tachesSelectionnees.includes(t.id)} onChange={() => this.toggleTache(t)} />
                                <label htmlFor={"toggle-tache-" + t.id} className="toggle"></label>
                            </div>
                        </td>
                        <td className="maxdescription">
                            <p className="m-0 table-nom text-muted font-14">{t.description}</p>
                        </td>

                        <td>
                            <p className="m-0 text-muted font-14">{date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt} {date.getFullYear()}</p>
                        </td>
                        <td>
                            {beneficiaire && <NavLink to={"/utilisateurs/" + beneficiaire.id}><p className="m-0 text-muted font-14">{beneficiaire.prenom} {beneficiaire.nom}</p></NavLink>}
                        </td>
                    </tr>
                );
            });   
		}

		return (
			<div className="content-page h-100" id="page-moncompte">
                <div className="content h-100">
                    <div className="container-fluid h-100">
						{/*<div className="row">
							<div className="col-lg-12 lienretour">
								<NavLink onClick={() => this.props.history.goBack()}><i className="mdi mdi-arrow-left"></i>Retour</NavLink>
							</div>
						</div>*/}
						<div className="row h-100">
							<div className="col-lg-4 h-100">
                                <div className="card card-profil sidebar m-b-20">
                                    <div className="card-body text-center">
                                        <h3 className="taches">Mes alertes</h3>
                                        <div className="text-left">
                                            <br clear="both" />
                                            <h4 className="taches">Alertes en cours</h4>
                                            <ol className="activity-feed encours mb-0">
                                                {alertes}
                                                {alertes.length == 0 && 
                                                    <p>Aucune alerte en cours.</p>
                                                }
                                            </ol>
                                            <hr />                
                                            <h4 className="taches">Alertes archivées</h4>                
                                            <ol className="activity-feed archives mb-0">
                                                {alertesArchivees}
                                                {alertesArchivees.length == 0 && 
                                                    <p>Aucune alerte archivée.</p>
                                                }                 
                                            </ol>
                                            {/*<div className="text-center">
                                                <a href="#" className="btn btn-sm btn-primary">Voir plus</a>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 h-100"> 
                                <div className="row h-100">
                                    <div className="col-md-12 h-100">
                                        <div className="card contenu m-b-20" id="informations">
                                            <div className="card-body">
                                            <button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouvelletache" onClick={() => this.setState({alerteContexte: null})}><i className="mdi mdi-plus"></i> Nouvelle tâche</button>
                                                <h3 className="taches">Mes tâches</h3>                                                
                                                <br clear="both" />                        
                                                <div className="taches-atraiter">
                                                    <h4 className="taches">A traiter</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-vertical">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <div className={"checkbox-wrapper" + (this.nbTachesInSelection("encours") != -1 ? " selection" : "")}>
																			<input type="checkbox" id="toggle-taches-all-encours" checked={this.nbTachesInSelection("encours") != 0} onChange={() => this.toggleAllTaches("encours")} />
                                        									<label htmlFor="toggle-taches-all-encours" className="toggle"></label>
																		</div>
                                                                    </th>
                                                                    {this.nbTachesInSelection("encours") == 0 &&
                                                                        <>
                                                                            <th>Objet</th>
                                                                            <th>Date</th>
                                                                            <th>Utilisateur</th>
                                                                            <th>Actions</th>
                                                                        </>
                                                                    }
                                                                    {this.nbTachesInSelection("encours") != 0 &&
                                                                        <th colSpan="4">
                                                                            <a href="#" data-toggle="modal" data-target="#modal-archivetaches" className="btn btn-annexe contour" onClick={() => this.setState({contexteTache: "encours"})}><i className="mdi mdi-folder-multiple-outline"></i> Archiver</a>
                                                                            <a href="#" data-toggle="modal" data-target="#modal-suppressiontaches" className="btn btn-annexe contour" onClick={() => this.setState({contexteTache: "encours"})}><i className="dripicons-trash"></i> Supprimer</a>
                                                                        </th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {taches}
                                                                {taches.length == 0 && 
                                                                    <tr>
                                                                        <td colSpan="5"><p>Aucune tâche à traiter</p></td>
                                                                    </tr>
                                                                }
                                                                <tr>
                                                                    <td colSpan="5" className="tdaction">
                                                                        <a href="#" data-toggle="modal" data-target="#modal-nouvelletache" onClick={() => this.setState({alerteContexte: null})} className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle tâche</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>            
                                                <hr />
                                                <div className="taches-enretard">
                                                    <h4 className="taches">En retard</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-vertical">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <div className={"checkbox-wrapper" + (this.nbTachesInSelection("enretard") != -1 ? " selection" : "")}>
																			<input type="checkbox" id="toggle-taches-all-enretard" checked={this.nbTachesInSelection("enretard") != 0} onChange={() => this.toggleAllTaches("enretard")} />
                                        									<label htmlFor="toggle-taches-all-enretard" className="toggle"></label>
																		</div>
                                                                    </th>
                                                                    {this.nbTachesInSelection("enretard") == 0 &&
                                                                        <>
                                                                            <th>Objet</th>
                                                                            <th>Date</th>
                                                                            <th>Utilisateur</th>
                                                                            <th>Actions</th>
                                                                        </>
                                                                    }
                                                                    {this.nbTachesInSelection("enretard") != 0 &&
                                                                        <th colSpan="4">
                                                                            <a href="#" data-toggle="modal" data-target="#modal-archivetaches" className="btn btn-annexe contour" onClick={() => this.setState({contexteTache: "enretard"})}><i className="mdi mdi-folder-multiple-outline"></i> Archiver</a>
                                                                            <a href="#" data-toggle="modal" data-target="#modal-suppressiontaches" className="btn btn-annexe contour" onClick={() => this.setState({contexteTache: "enretard"})}><i className="dripicons-trash"></i> Supprimer</a>
                                                                        </th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tachesRetard}
                                                                {tachesRetard.length == 0 && 
                                                                    <tr>
                                                                        <td colSpan="5"><p>Aucune tâche en retard</p></td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>                
                                                <hr />  
                                                <div className="taches-archivees">
                                                    <h4 className="taches">Archivées</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-vertical">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <div className={"checkbox-wrapper" + (this.nbTachesInSelection("archivees") != -1 ? " selection" : "")}>
																			<input type="checkbox" id="toggle-taches-all-archivees" checked={this.nbTachesInSelection("archivees") != 0} onChange={() => this.toggleAllTaches("archivees")} />
                                        									<label htmlFor="toggle-taches-all-archivees" className="toggle"></label>
																		</div>
                                                                    </th>
                                                                    {this.nbTachesInSelection("archivees") == 0 &&
                                                                        <>
                                                                            <th>Objet</th>
                                                                            <th>Date</th>
                                                                            <th>Utilisateur</th>
                                                                        </>
                                                                    }
                                                                    {this.nbTachesInSelection("archivees") != 0 &&
                                                                        <th colSpan="3">
                                                                            <a href="#" data-toggle="modal" data-target="#modal-suppressiontaches" className="btn btn-annexe contour" onClick={() => this.setState({contexteTache: "archivees"})}><i className="dripicons-trash"></i> Supprimer</a>
                                                                        </th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tachesArchivees}
                                                                {tachesArchivees.length == 0 && 
                                                                    <tr>
                                                                        <td colSpan="4"><p>Aucune tâche archivée</p></td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
                    <Footer />
				</div>
                {modals}
                <div id="modal-nouvelletache" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Nouvelle tâche</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formTacheValide()} onClick={() => this.ajouteTache()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormTache 
                                    tache={this.state.editingTache}
                                    alerte={this.state.alerteContexte}
                                    beneficiaires={dataService.getConseillerBeneficiaires(this.props.conseiller)}
                                    modifie={(tache) => this.setEditingTache(tache)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div key="modal-activite-nouvelleinteraction" id="modal-activite-nouvelleinteraction" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Interaction</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formInteractionValide()} onClick={() => this.ajouteInteraction()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormInteraction
                                    interaction={this.state.editingInteraction}
                                    beneficiaire={this.props.beneficiaire}
                                    modifie={(interaction) => this.setEditingInteraction(interaction)}
                                    categories={this.props.data.categoriesInteractions}
                                    origine="activite"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div key="modal-archivetaches" id="modal-archivetaches" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Archiver des tâches</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Les tâches sélectionnées seront archivées. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.archiveTaches()} data-dismiss="modal">Archiver les tâches</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
                <div key="modal-suppressiontaches" id="modal-suppressiontaches" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des tâches</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
                            <p>Les tâches sélectionnées seront supprimées. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeTaches()} data-dismiss="modal">Supprimer les tâches</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
                {modals}
			</div>
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(Activite));