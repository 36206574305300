import Accueil from '../../pages/Accueil';
import Proche from '../../pages/Proche';
import Beneficiaire from '../../pages/Beneficiaire';
import Activite from '../../pages/Activite';
import Filtre from '../../pages/Filtre';

export default {
    ConseillerAccueil: {
        component: Accueil,
        path: '/'
    },
    ConseillerActivite: {
        component: Activite,
        path: '/activite'
    },
    ConseillerBeneficiaire: {
        component: Beneficiaire,
        path: '/utilisateurs/:id'
    },
    ConseillerProche: {
        component: Proche,
        path: '/utilisateurs/:beneficiaireId/proches/:procheId'
    },
    ConseillerFiltre: {
        component: Filtre,
        path: '/structures/:structureId/profils/:id'
    }
}