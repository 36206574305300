import moment from 'moment';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Footer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer className="footer">
                © 2019-{moment().format('YYYY')} WESIReport - <a href="/mentions-legales" target="_blank">Mentions légales</a> - <a href="/conditions-generales-utilisation" target="_blank">Conditions générales d'utilisation</a> - {/*<a href="/mentions-informations-accompagnant" target="_blank">Mentions d'informations</a> - */}<a href="/politique-de-confidentialite" target="_blank">Politique de confidentialité</a> - <a href="/cookies" target="_blank">Cookies</a>
            </footer>
        )
    }
}

export default Footer;
