import Structure from '../../pages/Structure';
import Conseiller from '../../pages/Conseiller';

export default {
    /*ResponsableAccueil: {
        component: Structure,
        path: '/'
    },*/
    ResponsableAdmin: {
        component: Structure,
        path: '/admin'
    },
    ResponsableConseiller: {
        component: Conseiller,
        path: '/accompagnants/:id'
    }    
}