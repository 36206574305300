import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	BrowserRouter as Router,
	Route,
	NavLink
  } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import 'morris.js/morris.css';
import 'morris.js/morris.min.js';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Footer from '../components/Footer';

import CardBeneficiaire from '../components/Accueil/CardBeneficiaire';
import RowBeneficiaire from '../components/Accueil/RowBeneficiaire';

import FormBeneficiaire from '../components/Forms/FormBeneficiaire';
import FormInteraction from '../components/Forms/FormInteraction';

import DataService from '../services/data';
import CalendarService from '../services/calendar';

import Api from '../api/index';
import { getTerme } from '../services/contextualisation';
import SecurityService from '../services/security';


const mapStateToProps = (state, props) => {
	let dataService = new DataService();
	return {
	  data: state.data,
	  ihm: state.ihm,
	  history: props.route.history,
	  conseiller: dataService.getConseillerById((state.data.me != null) ? state.data.me.id : 0),
	}
  }

class Accueil extends Component {

	constructor(props) {
        super(props);

		this.state = {
			beneficiairesSelectionnes: [],
			tachesSelectionnees: [],
			autresConseillers: [],
			editingInteraction: null,
			statistiquesDebut: moment().startOf('year').toDate(),
			statistiquesFin: moment().endOf('year').toDate(),
			filtresBeneficiaires: [],
			responsableSeulementSuivis: false,
			motifSuppression: 'depart'
		}
	}

	componentDidMount() {

		let dataService = new DataService();
		dataService.getConseillerBeneficiaires(this.props.conseiller).map((b) => {
			window.jQuery("#modal-accueil-partagerbeneficiaire" + b.id).off('hidden.bs.modal');
			window.jQuery("#modal-accueil-partagerbeneficiaire" + b.id).on('hidden.bs.modal', () => {
				this.setState({
					autresConseillers: []
				});
			});
		});

		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});

		this.bindSelect2();

		if (this.props.conseiller.seulementAdmin === false) {
			this.afficheBarres();		
		}
		this.bindModals()

		console.log(dataService.getMyStructure())
	}

	componentDidUpdate(prevProps) {
		let dataService = new DataService();
		dataService.getConseillerBeneficiaires(this.props.conseiller).map((b) => {
			window.jQuery("#modal-accueil-partagerbeneficiaire" + b.id).off('hidden.bs.modal');
			window.jQuery("#modal-accueil-partagerbeneficiaire" + b.id).on('hidden.bs.modal', () => {
				this.setState({
					autresConseillers: []
				});
			});
		});

		if (prevProps.data.me.taches != this.props.data.me.taches) {
			let tachesSelectionnees = this.state.tachesSelectionnees;
			tachesSelectionnees.map((tache, index) => {
				if (!this.props.data.me.taches.includes(tache)) {
					tachesSelectionnees.splice(tachesSelectionnees.indexOf(tache), 1);
				}
			})
		}

		if (this.props.conseiller.seulementAdmin === false) {
			this.afficheBarres();
		}
			
		this.bindModals()		
	}

	bindModals() {
        window.jQuery(".modal-suppression").off('hidden.bs.modal');
        window.jQuery(".modal-suppression").on('hidden.bs.modal', () => {
            this.setState({
                motifSuppression: 'depart'
            });
        });
    }	

	bindSelect2() {
        window.jQuery("#filtre-accueil-filtres").off('change');
        window.jQuery("#filtre-accueil-filtres").on('change', (e) => {
            console.log(window.jQuery("#filtre-accueil-filtres").val());
            this.setState({
                filtresBeneficiaires: window.jQuery("#filtre-accueil-filtres").val(),				
            });            
        });
    }

	afficheBarres() {

		let calendarService = new CalendarService();
		let dataService = new DataService();

		let data = [];
		let max = 0;
		
		dataService.getConseillerBeneficiaires(this.props.conseiller).sort((a, b) => {
			if (this.props.ihm.accueilTriBeneficiaires == "nom_a-z") {
				return (a.nom + a.prenom).localeCompare(b.nom + b.prenom);
			}
		}).map(beneficiaire => {
			let duree = 0;
			beneficiaire.interactions.map(interaction => {
				if (interaction.conseiller == this.props.data.me.id) {
					let debut = new Date(interaction.debut);
					let fin = new Date(interaction.fin);
					duree += (Math.abs(debut.getTime() - fin.getTime()) / 1000 / 60 / 60);
				}
			});
			if (duree > max) max = duree;
			data.push({
				y: beneficiaire.prenom + " " + beneficiaire.nom, activite: duree
			});
		});

        
        window.jQuery("#graphique-suivi-accueil").empty();		
        window.Morris.Bar({
            element: 'graphique-suivi-accueil',
            data: data,
            xkey: 'y',
            ykeys: ['activite'],
            horizontal: true,
            ymax: max,
            labels: ['Activité'],
            hideHover: 'auto',
            resize: true, //defaulted to true
            gridLineColor: '#eeeeee',
            barColors: ['#7a6fbe'],
            yLabelFormat: (duree) => {
                let heures = Math.floor(duree);
                let minutes = (duree - heures) * 60;
                return heures + "h" + (minutes > 0 ? minutes : "");
			},
			barSizeRatio:2,
			barSize:60
		});
    }

	changeTriBeneficiaires(tri) {
		this.props.dispatch({ type: "IHM_ACCUEIL_TRI", payload: tri });
	}

	changeVueBeneficiaires(vue) {
		this.props.dispatch({ type: "IHM_ACCUEIL_VUE", payload: vue });
	}

	toggleSelectionBeneficiaire(beneficiaire) {
		let beneficiairesSelectionnes = this.state.beneficiairesSelectionnes;
		if (!beneficiairesSelectionnes.includes(beneficiaire)) {
			beneficiairesSelectionnes.push(beneficiaire);
		}
		else {
			beneficiairesSelectionnes.splice(beneficiairesSelectionnes.indexOf(beneficiaire), 1);
		}
		console.log(beneficiairesSelectionnes);
		this.setState({
			beneficiairesSelectionnes: beneficiairesSelectionnes
		});
	}

	ouvreBeneficiaire(beneficiaire) {
		this.props.history.push('/utilisateurs/' + beneficiaire.id);
	}

	enregistreBeneficiaire(beneficiaire) {
		console.log(beneficiaire);
		this.props.dispatch({type: 'DATA_POST_BENEFICIAIRE', payload: {beneficiaire: beneficiaire}});
	}

	enregistreNotifications(notifications) {
		console.log(notifications);
		this.props.dispatch({type: 'DATA_POST_NOTIFICATIONS', payload: {notifications: notifications}})
	}

	ajouteBeneficiaire(beneficiaire) {
		let dataService = new DataService();
		let structure = dataService.getMyStructure();
		if (structure) {
			//beneficiaire.conseillers = [this.props.conseiller.id];
			this.props.dispatch({type: 'DATA_PUT_STRUCTURE_BENEFICIAIRE', payload: {structure: structure, beneficiaire: beneficiaire}});
		}		
	}

	changeBeneficiaire(b, champ, val) {
		let beneficiaire = null;
		if (champ == "conseillers") {
			console.log("change conseillers", JSON.parse(JSON.stringify(val)))
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				conseillers: val
			}
		}
		if (champ == "interactions") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				interactions: val
			}
		}
		if (champ == "actif") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				actif: val
			}
		}
		if (champ == "suiviAvance") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				suiviAvance: val,
				actif: true
			}
		}
		if (champ == "supprime") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				supprime: val
			}
		}
		if (beneficiaire) {
			this.enregistreBeneficiaire(beneficiaire);
		}
	}

	toggleAutresConseillers(conseillerId) {
		let autresConseillers = this.state.autresConseillers;
		if (autresConseillers.includes(conseillerId)) {
			autresConseillers.splice(autresConseillers.indexOf(conseillerId), 1);
		}
		else {
			autresConseillers.push(conseillerId);
		}
		this.setState({
			autresConseillers: autresConseillers
		});
	}

	setEditingInteraction(interaction) {
        this.setState({
            editingInteraction: interaction
        });
    }

	formInteractionValide() {
		return (
            this.state.editingInteraction
			&& this.state.editingInteraction.description
			&& this.state.editingInteraction.categorie
			&& this.state.editingInteraction.beneficiaire
            && new Date(this.state.editingInteraction.debut) <= new Date(this.state.editingInteraction.fin)
        )
	}
	
	ajouteInteraction() {
		let dataService = new DataService();
		let beneficiaire = dataService.getBeneficiaireById(this.state.editingInteraction.beneficiaire);
		if (beneficiaire) {
			let interactions = beneficiaire.interactions;
			interactions.push(this.state.editingInteraction);
			this.changeBeneficiaire(beneficiaire, "interactions", interactions);
		}
	}

	toggleTache(tache) {
		let taches = this.state.tachesSelectionnees;
		if (taches.includes(tache)) {
			taches.splice(taches.indexOf(tache), 1);
		}
		else {
			taches.push(tache);
		}
		this.setState({
			tachesSelectionnees: taches
		})
	}

	archiveTaches() {
		let taches = this.props.data.me.taches;
		this.state.tachesSelectionnees.map((t) => {
			taches.map((tache, index) => {
				if (tache.id == t.id) {
					taches[index].execution = true;
				}
			})
		});
		let utilisateur = {
			id: this.props.data.me.id,
			classe: this.props.data.me.classe,
			taches: taches
		}
		this.enregistreUtilisateur(utilisateur);
	}

	enregistreUtilisateur(utilisateur) {
		this.props.dispatch({type: 'DATA_POST_UTILISATEUR', payload: {utilisateur: utilisateur}});
	}

	getStatistiques() {
		let api = new Api();

		api.getStatistiques("conseiller", this.state.statistiquesDebut, this.state.statistiquesFin).then((stream) => {
            let reader = stream.getReader();
            let result = [];   
            let self = this;
            reader.read().then(function processFile({done, value}) {
                console.log(done);
                if (done) {
                    let blob = new Blob(result, {type: "application/pdf"});
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.setAttribute("target", "_blank");
					// commenter cette ligne pour ouvrir dans nouveau tab, mais impossible d'avoir le bon nom					
                    a.setAttribute("download", ("Statistiques - " + moment().format("DD-MM-YYYY") + ".xlsx"));  
                    a.click();
                    return;
                }
                result = result.concat(value);
                reader.read().then(processFile);
            });
           
        }).catch(err => {
            console.log(err);
        });
	}

  	render() {

		let dataService = new DataService();
		let calendarService = new CalendarService();
		let securityService = new SecurityService()

		let structure = dataService.getMyStructure();

		let modals = [];

		let beneficiaires = [];

		let baseBeneficiaires = []

		if (structure.responsables.includes(this.props.conseiller.id) && !this.state.responsableSeulementSuivis) {
			baseBeneficiaires = [
				...dataService.getStructureBeneficiaires(structure)
			]
		}
		else {
			baseBeneficiaires = dataService.getStructureBeneficiaires(structure).filter(beneficiaire => !structure.responsables.includes(this.props.conseiller.id) && securityService.canFollowBeneficiaire(this.props.conseiller, beneficiaire)).map(beneficiaire => beneficiaire.id).concat(
				this.props.conseiller.beneficiaires
			)
			baseBeneficiaires = baseBeneficiaires.filter((b, iB) => baseBeneficiaires.indexOf(b) == iB)
			baseBeneficiaires = baseBeneficiaires.map(b => dataService.getBeneficiaireById(b))
		}		
		
		baseBeneficiaires
		.filter(b => {
			if (this.state.filtresBeneficiaires.length == 0) return true
			return this.state.filtresBeneficiaires.length == 0 || this.state.filtresBeneficiaires.filter(f => b.filtres.includes(parseInt(f))).length > 0
		})
		.sort((a, b) => {
			if (a.actif != b.actif) {
				return a.actif ? -1 : 1
			}
			if (a.suiviAvance != b.suiviAvance) {
				return a.suiviAvance ? -1 : 1
			}
			if (this.props.ihm.accueilTriBeneficiaires == "nom_a-z") {
				return (a.nom + a.prenom).localeCompare(b.nom + b.prenom);
			}
			if (this.props.ihm.accueilTriBeneficiaires == "nom_z-a") {
				return (b.nom + b.prenom).localeCompare(a.nom + a.prenom);
			}
			if (this.props.ihm.accueilTriBeneficiaires == "score_0-9") {
				return (a.score <= b.score) ? -1 : 1
			}
			if (this.props.ihm.accueilTriBeneficiaires == "score_9-0") {
				return (a.score >= b.score) ? -1 : 1
			}
		}).map((b, index) => {
			modals.push(
				<div key={"modal-accueil-stoppesuivi-beneficiaire-" + b.id} id={"modal-accueil-stoppesuivi-beneficiaire-" + b.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Ne plus suivre {b.prenom} {b.nom}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p><b>{b.prenom} {b.nom}</b> n'apparaitra plus dans votre tableau de bord. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => {b.conseillers.splice(b.conseillers.indexOf(this.props.conseiller.id), 1); this.changeBeneficiaire(b, "conseillers", b.conseillers)}} data-dismiss="modal">Ne plus suivre</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
			);
			modals.push(
				<div key={"modal-accueil-toggleactivationbeneficiaire-" + b.id} id={"modal-accueil-toggleactivationbeneficiaire-" + b.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{b.actif ? "Désactiver" : "Réactiver" } la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{b.actif && <p>L'ensemble des services de suivi concernant <b>{b.prenom} {b.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!b.actif && <p>Le suivi concernant <b>{b.prenom} {b.nom}</b> sera réactivé. Vous pouvez choisir ci-dessous le type de suivi (avec signaux faibles, sans signaux faibles) souhaité.</p>}
							</div>
							<div className="modal-footer">
								{b.actif &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(b, "actif", false)} data-dismiss="modal">Désactiver le suivi de la personne</button>
									</>
								}
								{!b.actif &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(b, "suiviAvance", true)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getMyStructure()) >= dataService.getMyStructure().nbMaxBeneficiairesSuiviAvance}>Réactiver la personne avec signaux faibles</button>
										<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(b, "suiviAvance", false)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getMyStructure()) >= dataService.getMyStructure().nbMaxBeneficiairesSuiviNormal}>Réactiver la personne sans signaux faibles</button>
									</>
								}								
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>							
						</div>
					</div>
				</div>	
			);
			modals.push(
				<div key={"modal-accueil-togglesuivibeneficiaire-" + b.id} id={"modal-accueil-togglesuivibeneficiaire-" + b.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Changer le type de suivi la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{b.suiviAvance && <p>Le suivi avec signaux faibles de <b>{b.prenom} {b.nom}</b> sera désactivé. Il pourra être rétabli ultérieurement. Voulez-vous continuer ?</p>}
								{!b.suiviAvance && <p>Le suivi avec signaux faibles de <b>{b.prenom} {b.nom}</b> sera activé. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(b, "suiviAvance", !b.suiviAvance)} data-dismiss="modal">Passer en suivi {b.suiviAvance ? "sans" : "avec"} signaux faibles</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
			modals.push(
				<div key={"modal-accueil-suppressionbeneficiaire-" + b.id} id={"modal-accueil-suppressionbeneficiaire-" + b.id} className="modal fade bs-example-modal-center bs-example-modal-lg modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer le compte de la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Merci d'indiquer le motif de la suppression du compte :</p>
								<form>
									<div className="form-group">													
										<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
											<option value="depart">Ne fait plus partie des effectifs</option>
											<option value="rgpd">Demande explicite (RGPD)</option>
											<option value="autre">Autre</option>
										</select>
									</div>
								</form>
								<p>L'ensemble des services de suivi et informations liés à <b>{b.prenom} {b.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.changeBeneficiaire(b, "supprime", true)} data-dismiss="modal">Supprimer le compte de la personne</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
			let autresConseillers = [];
			let structure = dataService.getMyStructure();
			if (structure) {
				structure.conseillers.map((c) => {
					if (!b.conseillers.includes(c)) {
						let conseiller = dataService.getConseillerById(c);
						if (conseiller && conseiller.actif && !conseiller.seulementAdmin && securityService.canFollowBeneficiaire(conseiller, b)) {
							autresConseillers.push(
								<React.Fragment key={"checkbox-partage-suivi-" + conseiller.id}>
									<div className="checkbox-wrapper mr-2">
										<input type="checkbox" id={"checkbox-partage-suivi-" + conseiller.id} checked={this.state.autresConseillers.includes(conseiller.id)} onChange={() => this.toggleAutresConseillers(conseiller.id)}/>
										<label htmlFor={"checkbox-partage-suivi-" + conseiller.id} className="checkbox-remember-me"></label>
									</div>
									<label htmlFor={"checkbox-partage-suivi-" + conseiller.id}>{conseiller.prenom} {conseiller.nom}</label>
									<br />
								</React.Fragment>
							);
						}
					}
				});
			}
			modals.push(
				<div key={"modal-accueil-partagerbeneficiaire-" + b.id} id={"modal-accueil-partagerbeneficiaire-" + b.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Partager le suivi de {b.prenom} {b.nom}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Partager le suivi de <b>{b.prenom} {b.nom}</b> avec :</p>
								{autresConseillers}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" disabled={this.state.autresConseillers.length == 0} data-dismiss="modal" onClick={() => this.changeBeneficiaire(b, "conseillers", b.conseillers.concat(this.state.autresConseillers))}>Partager</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
			);

			if (this.props.ihm.accueilVueBeneficiaires == "grille" && b.actif) {
				beneficiaires.push(
					<CardBeneficiaire 
						key={"CardBeneficiaire-" + b.id}
						beneficiaire={b}
						conseiller={this.props.conseiller}
						notifications={this.props.data.notifications.filter((notification) => notification.beneficiaire == b.id)}
						enregistreNotifications={(notifications) => this.enregistreNotifications(notifications)}
						toggle={() => this.toggleSelectionBeneficiaire(b)}
						enregistre={(b) => this.enregistreBeneficiaire(b)}
						ouvreBeneficiaire={() => this.ouvreBeneficiaire(b)}
						ouvreModalStoppeSuivi={() => window.jQuery("#modal-accueil-stoppesuivi-beneficiaire-" + b.id).modal("show")}
						ouvreModalToggleActivation={() => window.jQuery("#modal-accueil-toggleactivationbeneficiaire-" + b.id).modal("show")}
						ouvreModalToggleSuivi={() => window.jQuery("#modal-accueil-togglesuivibeneficiaire-" + b.id).modal("show")}
						ouvreModalSuppression={() => window.jQuery("#modal-accueil-suppressionbeneficiaire-" + b.id).modal("show")}
						ouvreModalPartage={() => window.jQuery("#modal-accueil-partagerbeneficiaire-" + b.id).modal("show")}
						peutPartager={autresConseillers.length > 0}
						beneficiairesSelectionnes={this.state.beneficiairesSelectionnes}
						location={this.props.location}
					/>
				);
			}

			if (this.props.ihm.accueilVueBeneficiaires == "liste") {
				beneficiaires.push(
					<RowBeneficiaire 
						key={"RowBeneficiaire-" + b.id}
						beneficiaire={b}
						conseiller={this.props.conseiller}
						notifications={this.props.data.notifications.filter((notification) => notification.beneficiaire == b.id)}
						enregistreNotifications={(notifications) => this.enregistreNotifications(notifications)}
						toggle={() => this.toggleSelectionBeneficiaire(b)}
						enregistre={(b) => this.enregistreBeneficiaire(b)}
						ouvreModalStoppeSuivi={() => window.jQuery("#modal-accueil-stoppesuivi-beneficiaire-" + b.id).modal("show")}
						ouvreModalToggleActivation={() => window.jQuery("#modal-accueil-toggleactivationbeneficiaire-" + b.id).modal("show")}
						ouvreModalToggleSuivi={() => window.jQuery("#modal-accueil-togglesuivibeneficiaire-" + b.id).modal("show")}
						ouvreModalSuppression={() => window.jQuery("#modal-accueil-suppressionbeneficiaire-" + b.id).modal("show")}
						ouvreModalPartage={() => window.jQuery("#modal-accueil-partagerbeneficiaire-" + b.id).modal("show")}
						peutPartager={autresConseillers.length > 0}
						beneficiairesSelectionnes={this.state.beneficiairesSelectionnes}
						location={this.props.location}
					/>
				);
			}	
		});

		let taches = [];
		let tachesRetard = [];
		this.props.data.me.taches.filter(t => t.dateExecution == null).sort((a, b) => a.date < b.date ? -1 : 1).map((t) => {
			let date = new Date(t.date);
			let today = new Date();
			today.setHours(0);
			today.setMinutes(0);
			today.setSeconds(0);
			today.setMilliseconds(0);

			let beneficiaire = dataService.getBeneficiaireById(t.beneficiaire);

			if (date >= today) {
				taches.push(
					<li key={"row-tache-" + t.id}>
						<div className="checkbox-wrapper">
							<input type="checkbox" id={"toggle-tache-" + t.id} checked={this.state.tachesSelectionnees.includes(t)} onChange={() => this.toggleTache(t)} />
							<label htmlFor={"toggle-tache-" + t.id} className="toggle"></label>
						</div>
						<span className="mestaches-date">{date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt}</span> | 
						{beneficiaire && <span className="mestaches-beneficiaire">{beneficiaire.prenom} {beneficiaire.nom} - </span>}
						{t.description}
					</li>
				);
			}
			else {
				tachesRetard.push(
					<li key={"row-tache-" + t.id}>
						<div className="checkbox-wrapper">
							<input type="checkbox" id={"toggle-tache-" + t.id} checked={this.state.tachesSelectionnees.includes(t)} onChange={() => this.toggleTache(t)} />
							<label htmlFor={"toggle-tache-" + t.id} className="toggle"></label>
						</div>
						<span className="mestaches-date">{date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt}</span> |
						{beneficiaire && <span className="mestaches-beneficiaire">{beneficiaire.prenom} {beneficiaire.nom} - </span>}
						{t.description}
					</li>
				);
			}	
		});

		let actionsGroupe = null;
		/*if (this.state.beneficiairesSelectionnes.length > 0) {
			actionsGroupe = 
				<div className="actions-groupe">
					<a href="#" className="btn btn-annexe"><i className="dripicons-trash"></i> Supprimer</a>
					<a href="#" className="btn btn-annexe"><i className="mdi mdi-refresh"></i> Réinitialiser le score</a>
					<a href="#" className="btn btn-annexe"><i className="dripicons-document-edit"></i> Modifier le seuil d'alerte</a>
					<a href="#" className="btn btn-annexe"><i className="mdi mdi-share-variant"></i> Partager avec un collègue</a>
					<a href="#" className="btn btn-annexe"><i className="mdi mdi-bell-off"></i> Ne plus suivre</a>
				</div>
			;
		}*/

		let suiviActivite = true;
		if (!dataService.getMyStructure() || !dataService.getMyStructure().accesSuiviActivite) {
            suiviActivite = false;
        }

		return(

			<div className="content-page" id="page-home">
                <div className="content">
                    <div className="container-fluid">
						<div className="row h-100">
							<div className={"col-md-" + (this.props.conseiller.seulementAdmin === false ? "8" : "12")}>								
								<div className="row">
									<div className="col-sm-12">
										<div className="page-title-box">
											{this.props.ihm.accueilVueBeneficiaires == "grille" &&
												<a href="#" onClick={() => this.changeVueBeneficiaires("liste")} className="btn btn-annexe m-t-30">
													<i className="mdi mdi-view-headline"></i> Vue liste
												</a>
											}
											{this.props.ihm.accueilVueBeneficiaires == "liste" &&
												<a href="#" onClick={() => this.changeVueBeneficiaires("grille")} className="btn btn-annexe m-t-30">
													<i className="mdi mdi-view-grid"></i> Vue grille
												</a>
											}
											<a className="btn btn-annexe m-t-30 dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<i className="mdi mdi-sort-descending"></i>Trier par
											</a>
											<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
												<a className="dropdown-item" onClick={() => this.changeTriBeneficiaires('nom_a-z')}>Ordre alphabétique A &gt; Z</a>
												<a className="dropdown-item" onClick={() => this.changeTriBeneficiaires('score_9-0')}>Compteur du + au - élevé</a>
												<a className="dropdown-item" onClick={() => this.changeTriBeneficiaires('score_0-9')}>Compteur du - au + élevé</a>
											</div>
											<input type="checkbox" id="toggle-accueil-responsable-seulementSuivis" switch="warning" checked={this.state.responsableSeulementSuivis} onChange={() => this.setState({responsableSeulementSuivis: !this.state.responsableSeulementSuivis})} />
											{structure.responsables.includes(this.props.conseiller.id) && this.props.conseiller.seulementAdmin === false &&
												<>
													<label htmlFor="toggle-accueil-responsable-seulementSuivis"></label>
													Voir seulement mes suivis
												</>
											}
											{/* commenté à la demande de silvia la 29012024 */}
											{/*{(structure.domaine !== 'entreprise' || securityService.isResponsable() || securityService.isAdmin()) && */}											
												<div className="state-information d-none d-sm-block">
													<button type="button" data-toggle="modal" data-target="#modal-accueilnouvellepersonne" className="btn btn-secondary m-t-30" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getMyStructure()) >= dataService.getMyStructure().nbMaxBeneficiairesSuiviAvance && dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getMyStructure()) >= dataService.getMyStructure().nbMaxBeneficiairesSuiviNormal}><i className="mdi mdi-account-plus"></i> Ajouter un {getTerme("utilisateur", dataService.getMyStructure().domaine)}</button>
												</div>
											{/*}*/}											
											{actionsGroupe}
											{(this.props.conseiller.filtres.length > 0 || securityService.isResponsable()) &&
												<div className="mb-2 mt-2 select2-filtre">
													<select id="filtre-accueil-filtres" className="form-control select2 select2-multiple" multiple data-placeholder="Filtrer...">
														{structure.filtres
														.filter(f => this.props.conseiller.filtres.includes(f.id) || structure.responsables.includes(this.props.conseiller.id))
														.map((filtre, iFiltre) => {
															return (
																<React.Fragment key={"filtre-" + iFiltre}>
																	<option value={filtre.id}>{filtre.nom}</option>
																	{filtre.filtres
																	.filter(f => {
																		return (
																			this.props.conseiller.filtres.includes(f.id)
																			|| structure.responsables.includes(this.props.conseiller.id)
																			|| filtre.filtres.filter(ff => this.props.conseiller.filtres.includes(ff.id)).length == 0
																		)
																	})
																	.map((sousFiltre, iSousFiltre) => {
																		return (
																			<React.Fragment key={"sousfiltre-" + iSousFiltre}>
																				<option value={sousFiltre.id}>{filtre.nom} - {sousFiltre.nom}</option>
																			</React.Fragment>
																		)
																	})}
																</React.Fragment>
															)
														})}																		
													</select>
												</div>
											}
										</div>
									</div>
								</div>	
								
								<div className="row">
									{this.props.ihm.accueilVueBeneficiaires == "grille" &&
										<>
											{beneficiaires}
											{/* commenté à la demande de silvia la 29012024 */}
											{/*{(structure.domaine !== 'entreprise' || securityService.isResponsable() || securityService.isAdmin()) && */}
												<div className="col-lg-6 col-xl-4 widget-home">
													<div className="card card-ajout widget-user m-b-20">
														<a href="#" data-toggle="modal" data-target="#modal-accueilnouvellepersonne" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getMyStructure()) >= dataService.getMyStructure().nbMaxBeneficiairesSuiviAvance && dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getMyStructure()) >= dataService.getMyStructure().nbMaxBeneficiairesSuiviNormal}>
															<div className="card-body">
																<div className="text-center ajout">
																	<i className="mdi mdi-plus"></i>
																	<p>Ajouter un {getTerme("nouvel utilisateur", dataService.getMyStructure().domaine)}</p>
																</div>
															</div>
														</a>
													</div>
												</div>
											{/*} */}
										</>

									}
									{this.props.ihm.accueilVueBeneficiaires == "liste" &&
										<div className="card w-100 m-3">
											<div className="card-body">
												<div className="table-responsive">
													<table className="table table-vertical">
														<thead>
															<tr>
																{/*<th>
																	<div className="checkbox-wrapper selection">
																		<input type="checkbox" id="chk19" />
																		<label htmlFor="chk19" className="toggle"></label>
																	</div>
																</th>*/}
																<th>Nom</th>
																<th>Signaux</th>
																<th>Questions</th>
																<th>Notifications</th>
																<th>Actions</th>
															</tr>
														</thead>
														<tbody>
															{beneficiaires}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									}
								</div>
							</div>

							{this.props.conseiller.seulementAdmin === false && 
								<div className="col-md-4 h-100 sidebar-home">
									<div className="row h-100">
										<div className="col-xl-12 sidebar-right h-100">
											<div className="card m-b-20 h-100">
												<div className="card-body">
													{this.props.conseiller.beneficiaires.length > 0 &&
														<div className="text-center pt-4 pb-4">
															<a href="#" data-toggle="modal" data-target="#modal-accueilnouvelleinteraction" onClick={() => this.setEditingInteraction(null)} className="btn btn-sm btn-primary w-100">Créer une nouvelle interaction</a>
														</div>
													}
													{suiviActivite &&
														<div className="card card-todo m-b-30 text-white bg-secondary">
															<div className="card-body">
																{taches.length > 0 && 
																	<>
																		<h4 className="mt-0 header-title mb-3 text-center">Mes tâches à traiter</h4>
																		<ul>
																			{taches}
																		</ul>
																	</>
																}
																{tachesRetard.length > 0 && 
																	<>
																		<h4 className="mt-0 header-title mb-3 text-center">Mes tâches en retard</h4>
																		<ul>
																			{tachesRetard}
																		</ul>
																	</>
																}
																{taches.length + tachesRetard.length == 0 && 
																	<h4 className="mt-0 header-title mb-3 text-center">Aucune tâche à traiter</h4>
																}
																{this.state.tachesSelectionnees.length > 0 && 
																	<div className="text-center p-4">
																		<button className="btn btn-sm btn-more" onClick={() => this.archiveTaches()}><i className="mdi mdi-checkbox-marked-outline"></i> Archiver !</button>
																	</div>
																}
																<div className="text-center p-4">
																	<NavLink to="/activite" className="btn btn-sm btn-more"><i className="mdi mdi-eye"></i> Voir toutes mes tâches</NavLink>
																</div>
															</div>
														</div>
													}									
													<h4 className="mt-0 header-title mb-3 text-center">Mon activité</h4> 
													<div id="graphique-suivi-accueil"></div>
													{/*
													<h4 className="mt-0 header-title mb-3 text-center">Mes statistiques</h4> 
													<form className="form-statistiques">	
														<div className="row">
															<div className="input-group date">
																<div className="input-group-prepend">
																	<div className="input-group-text">Date de début</div>
																</div>
																<DatePicker
																	ref={(input) => { this.ref_statistiquesDebutWidget = input; }} 
																	className="form-control" 
																	locale="fr"
																	selected={this.state.statistiquesDebut}
																	onChange={(date) => this.setState({statistiquesDebut: date})}
																	dateFormat="dd/MM/yyyy"
																	maxDate={this.state.statistiquesFin}
																/>
																<div className="input-group-append" onClick={() => this.ref_statistiquesDebutWidget.setFocus(true)}>
																	<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																</div>
															</div>
														</div>
														<br clear="both" />
														<div className="row">
															<div className="input-group date">
																<div className="input-group-prepend">
																	<div className="input-group-text">Date de fin (incluse)</div>
																</div>
																<DatePicker
																	ref={(input) => { this.ref_statistiquesFinWidget = input; }} 
																	className="form-control" 
																	locale="fr"
																	selected={this.state.statistiquesFin}
																	onChange={(date) => this.setState({statistiquesFin: date})}
																	dateFormat="dd/MM/yyyy"
																	minDate={this.state.statistiquesDebut}
																/>
																<div className="input-group-append" onClick={() => this.ref_statistiquesFinWidget.setFocus(true)}>
																	<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																</div>
															</div>																	
														</div>	
														<br clear="both" />														
														<div className="row ml-1">
															<button type="button" className="mb-3 btn btn-primary" onClick={() => this.getStatistiques()}>Télécharger le fichier de statistiques</button>
														</div>															
													</form>*/}
												</div>
											</div>
										</div>
									</div>
								</div>
							}
							
						</div>
					</div>
					<Footer />
				</div>
				{modals}
				<div id="modal-accueilnouvellepersonne" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
						<FormBeneficiaire
							enregistre={(beneficiaire) => this.ajouteBeneficiaire(beneficiaire)}
							conseiller={this.props.conseiller}
							structure={dataService.getMyStructure()}
						/>
					</div>
				</div>
				<div key="modal-accueilnouvelleinteraction" id="modal-accueilnouvelleinteraction" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Interaction</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formInteractionValide()} onClick={() => this.ajouteInteraction()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormInteraction
                                    interaction={this.state.editingInteraction}
									beneficiaire={null}
									beneficiaires={dataService.getStructureBeneficiaires(structure).filter(beneficiaire => securityService.canViewBeneficiaire(beneficiaire))}
									modifie={(interaction) => this.setEditingInteraction(interaction)}
									categories={this.props.data.categoriesInteractions}
									origine="accueil"
                                />
                            </div>
                        </div>
                    </div>
                </div>
			</div>		
		)
	}
}

export default withRouter(connect(mapStateToProps, null)(Accueil));