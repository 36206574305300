import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataService from '../../services/data';
import { getTerme } from '../../services/contextualisation';
import TabQuestionsProfil from './TabQuestionsProfil';

const mapStateToProps = (state, props) => {
	return {
	  data: state.data,	  
	}
}

class TabQuestionsFiltre extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            filtre: JSON.parse(JSON.stringify(this.props.filtre)),            
            idProfilCourant: this.props.filtre.profils.length > 0 ? this.props.filtre.profils[0].id : null
        }        
    }

    componentDidMount() {
        console.log("TabQuestionsFiltre did mount");
        
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip("dispose");
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip({
            trigger: 'hover'
        }); 
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").click(function() {
            window.jQuery(this).tooltip("hide");
        });

        console.log(this.props.personne)
    }

    componentDidUpdate(prevProps) {
        console.log("TabQuestions did update");

        if (JSON.stringify(this.props.filtre) != JSON.stringify(prevProps.filtre)) {
            this.setState({
                filtre: JSON.parse(JSON.stringify(this.props.filtre)),
                //idPersonneCourante: this.props.personne.id
            });
        }
    }    

    getProfilCourant() {
        for (let profil of this.props.filtre.profils) {
            if (profil.id == this.state.idProfilCourant) {
                return profil
            }
        }
        return null
    }
     
	render() {

        let dataService = new DataService()
        
        let profilCourant = this.getProfilCourant()
        console.log(profilCourant, this.state.idProfilCourant)

        console.log(profilCourant)

        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="questions" role="tabpanel">
                <br clear="both" />
                <div className="row">
                    <div className="form-group col-md-12">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">Profil concerné</div>
                            </div>
                            <select className="form-control" value={this.state.idProfilCourant} onChange={(e) => this.setState({idProfilCourant: e.target.value})}>
                                {this.props.filtre.profils.map((profil, iProfil) => {
                                    return (
                                        <option key={"profil-" + iProfil} value={profil.id}>{getTerme({
                                            beneficiaire: 'Utilisateur',
                                            'proche-superieur': 'Supérieur'
                                        }[profil.cible], this.props.structure.domaine)}</option>
                                    )
                                })}                                
                            </select>
                        </div>
                    </div>                            
                </div>
                {profilCourant && 
                    <>
                        <TabQuestionsProfil
                            profil={profilCourant}
                            filtre={this.props.filtre}
                            conseiller={this.props.conseiller}
                            ajouteQuestionsDefaut={(questions, personne) => this.props.ajouteQuestionsDefaut(questions, personne)}
                            copieQuestions={(questions, profils) => this.props.copieQuestions(questions, profils)}
                            enregistre={(profil) => this.props.enregistreProfil(profil)}
                            structure={this.props.structure}
                        />
                    </>
                }                                                                                               					   
            </div>
        )
	}
}
  

export default connect(mapStateToProps, null)(TabQuestionsFiltre);