import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import DatePicker from "react-datepicker";
import moment from 'moment'

import CalendarService from '../../services/calendar';
import DataService from '../../services/data';

import FormQuestion from '../Forms/FormQuestion';
import FormRepetitionQuestions from '../Forms/FormRepetitionQuestions';

import Api from '../../api'
import TabQuestionsPersonne from './TabQuestionsPersonne';


const mapStateToProps = (state, props) => {
	return {
	  data: state.data,	  
	}
}

class TabQuestions extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            personne: JSON.parse(JSON.stringify(this.props.personne)),
            idPersonneCourante: this.props.personne.id
        }        
    }

    componentDidMount() {
        console.log("TabQuestions did mount");
        
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip("dispose");
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip({
            trigger: 'hover'
        }); 
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").click(function() {
            window.jQuery(this).tooltip("hide");
        });

        console.log(this.props.personne)
    }

    componentDidUpdate(prevProps) {
        console.log("TabQuestions did update");

        if (JSON.stringify(this.props.personne) != JSON.stringify(prevProps.personne)) {
            this.setState({
                personne: JSON.parse(JSON.stringify(this.props.personne)),                

            });
        }
        if (this.props.personne.id != prevProps.personne.id) {
            this.setState({
                idPersonneCourante: this.props.personne.id
            })
        }
    }    

    getPersonneCourante() {
        if (this.state.personne.id == this.state.idPersonneCourante) return this.state.personne        
        for (let proche of this.state.personne.proches) {
            if (proche.id == this.state.idPersonneCourante) {
                return proche
            }
        }
        for (let emploi of this.state.personne.emplois) {
            for (let proche of emploi.proches) {
                if (proche.id == this.state.idPersonneCourante) {
                    return proche
                }
            }
        }
        return null
    }
     
	render() {

        let dataService = new DataService()
        
        let personneCourante = this.getPersonneCourante()

        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="questions" role="tabpanel">
                <br clear="both" />
                {this.props.personne.classe == "Beneficiaire" &&
                    <>
                        {this.props.personne.enConges &&
                            <div className="alert alert-warning" role="alert">
                                <i className="mdi mdi-alert-outline"></i> {this.props.personne.prenom} {this.props.personne.nom} est en congés, <b>aucune question n'est envoyée en ce moment</b>.
                            </div>
                        }                        
                        <div className="row">
                            <div className="form-group col-md-12">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Personne concernée</div>
                                    </div>
                                    <select className="form-control" value={this.state.idPersonneCourante} onChange={(e) => this.setState({idPersonneCourante: e.target.value})}>
                                        <option value={this.props.personne.id}>{this.props.personne.prenom} {this.props.personne.nom}</option>
                                        {this.props.personne.emplois.map((emploi, iEmploi) => {
                                            return emploi.proches.filter(p => p.description == "superieur" && p.actif && !p.supprime).map((proche, iProche) => {
                                                return (
                                                    <option key={"proche-" + iProche} value={proche.id}>{proche.prenom} {proche.nom}</option>
                                                )
                                            })
                                        })}
                                        {this.props.personne.proches.filter(p => p.description == "superieur" && p.actif && !p.supprime).map((proche, iProche) => {
                                            return (
                                                <option key={"proche-" + iProche} value={proche.id}>{proche.prenom} {proche.nom}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>                            
                        </div>
                    </>
                }  
                {this.getPersonneCourante() && 
                    <>
                        <TabQuestionsPersonne 
                            personne={this.getPersonneCourante()}
                            enregistre={(personne) => personne.classe == "Beneficiaire" ? this.props.enregistreBeneficiaire(personne) : this.props.enregistreProche(personne)}
                            conseiller={this.props.conseiller}
                            copieQuestions={(questions, personnes) => this.props.copieQuestions(questions, personnes)}
                            bibliotheques={this.props.data.bibliotheques.filter(b => this.props.personne.classe == "Beneficiaire" && b.domaine == dataService.getStructureById(this.props.personne.structure).domaine)}
                            changePersonneCourante={(idPersonne) => this.setState({idPersonneCourante: idPersonne})}
                            ajouteQuestionsDefaut={(questions, personne) => this.props.ajouteQuestionsDefaut(questions, personne)}
                        />
                    </>
                }                                                                                               					   
            </div>
        )
	}
}
  

export default connect(mapStateToProps, null)(TabQuestions);