import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';

import CalendarService from '../../services/calendar';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfobulleRGPD from '../InfobulleRGPD';
import DataService from '../../services/data';

class FormInteraction extends Component {

    constructor(props) {
        super(props);        

        this.state = this.initFromProps();
        this.state.repetitionTypeSelect = "jour";        
    }

    initFromProps() {

        let calendarService = new CalendarService();
        let dataService = new DataService();

        let debut = new Date();
        debut.setMinutes(0);
        debut.setSeconds(0);
        let fin = new Date();
        fin.setHours(fin.getHours() + 2);
        fin.setMinutes(0);
        fin.setSeconds(0);

        if (debut.getHours() < 7) {
            debut.setHours(7);
            fin.setHours(9);
        }
        if (debut.getHours() > 21) {
            debut.setHours(19);
            fin.setHours(21);
        }

        let structure = dataService.getStructureById(this.props.beneficiaire ? this.props.beneficiaire.structure : (this.props.beneficiaires && this.props.beneficiaires.length > 0 ? this.props.beneficiaires[0].structure : null))

        let interaction = this.props.interaction ? JSON.parse(JSON.stringify(this.props.interaction)) : {     
            id: null,
            classe: "Interaction",
            score: null,
            description: null,
            commentaire: null,
            beneficiaire: null,
            categorie: structure && structure.domaine === 'entreprise' ? 'autre_autre' : null,
            enPresentiel: true,
            enEntreprise: structure && structure.domaine === 'entreprise' ? true : false,
            debut: calendarService.formatteDate(debut),
            fin: calendarService.formatteDate(fin)
        };

        debut = new Date(interaction.debut);
        fin = new Date(interaction.fin);

        interaction.dateWidget = new Date(interaction.debut);
        interaction.debutWidget = {
            heure: ("0" + debut.getHours()).slice(-2),
            minute: ("0" + debut.getMinutes()).slice(-2)
        }
        interaction.finWidget = {
            heure: ("0" + fin.getHours()).slice(-2),
            minute: ("0" + fin.getMinutes()).slice(-2)
        }

        interaction.categorieNiveau1 = this.props.categories[0].key;
        if (interaction.categorie == null) {
            interaction.categorie = this.props.categories[0].categories[0].key;
        }
        else {
            this.props.categories.map((categorieNiveau1) => {
                categorieNiveau1.categories.map((categorie) => {
                    if (categorie.key == interaction.categorie) {
                        interaction.categorieNiveau1 = categorieNiveau1.key;
                    }
                });
            });
        }

        if (!this.props.beneficiaire && this.props.beneficiaires && this.props.beneficiaires.length > 0) {
            interaction.beneficiaire = this.props.beneficiaires.sort((a, b) => (a.nom + " " + a.prenom) < (b.nom + " " + b.prenom) ? -1 : 1)[0].id;
        }

        return {
            interaction: interaction
        };
    }

    componentDidMount() {
        console.log("forminteraction did mount");

        this.bindSelect2();

        if (this.props.modifie) {
            this.props.modifie(this.state.interaction);
        }
    }

    componentDidUpdate(prevProps) {
        console.log("forminteraction did update");

        if (prevProps.interaction != this.props.interaction) {
            this.setState(this.initFromProps());
            if (!this.props.interaction && this.props.modifie) {
                this.props.modifie(this.initFromProps().interaction);
            }
        }
        this.bindSelect2();
    }

    bindSelect2() {
        let select2 = findDOMNode(this.refs.interactionCategorie);
        
        window.jQuery(select2).off("change");
        window.jQuery(select2).select2({
            language: "fr"
        });
        window.jQuery(select2).on("change", (e) => {
            this.modifieInteraction("categorie", e.target.value);
        });
    }
    
    modifieInteraction(champ, val) {
        console.log(champ, val);
        let interaction = this.state.interaction;
        if (champ == "scorePlusMoins") {
            if (val == "avancer") interaction.score = 1;
            if (val == "reculer") interaction.score = -1;
            if (val == "stagner") interaction.score = 0;
            if (val == "reinitialiser") interaction.score = null;
        }
        if (champ == "score") {
            interaction.score = interaction.score >= 0 ? val : -val;
        }
        if (champ == "dateWidget") {
            let calendarService = new CalendarService();
            interaction.dateWidget = val;
            let debut = new Date(interaction.dateWidget.getTime());
            let fin = new Date(interaction.dateWidget.getTime());
            debut.setHours(parseInt(this.state.interaction.debutWidget.heure));
            debut.setMinutes(parseInt(this.state.interaction.debutWidget.minute));
            fin.setHours(parseInt(this.state.interaction.finWidget.heure));
            fin.setMinutes(parseInt(this.state.interaction.finWidget.minute));
            interaction.debut = calendarService.formatteDate(debut);
            interaction.fin = calendarService.formatteDate(fin);
        }
        if (champ == "debutWidget-heure") {
            let calendarService = new CalendarService();
            interaction.debutWidget.heure = val;
            let debut = new Date(interaction.dateWidget.getTime());
            debut.setHours(parseInt(interaction.debutWidget.heure));
            debut.setMinutes(parseInt(interaction.debutWidget.minute));
            interaction.debut = calendarService.formatteDate(debut);
        }
        if (champ == "debutWidget-minute") {
            let calendarService = new CalendarService();
            interaction.debutWidget.minute = val;
            let debut = new Date(interaction.dateWidget.getTime());
            debut.setHours(parseInt(interaction.debutWidget.heure));
            debut.setMinutes(parseInt(interaction.debutWidget.minute));
            interaction.debut = calendarService.formatteDate(debut);
        }
        if (champ == "finWidget-heure") {
            let calendarService = new CalendarService();
            interaction.finWidget.heure = val;
            let fin = new Date(interaction.dateWidget.getTime());
            fin.setHours(parseInt(interaction.finWidget.heure));
            fin.setMinutes(parseInt(interaction.finWidget.minute));
            interaction.fin = calendarService.formatteDate(fin);
        }
        if (champ == "finWidget-minute") {
            let calendarService = new CalendarService();
            interaction.finWidget.minute = val;
            let fin = new Date(interaction.dateWidget.getTime());
            fin.setHours(parseInt(interaction.finWidget.heure));
            fin.setMinutes(parseInt(interaction.finWidget.minute));
            interaction.fin = calendarService.formatteDate(fin);
        }
        if (champ == "description") {
            interaction.description = val || null;
        }
        if (champ == "commentaire") {
            interaction.commentaire = val || null;
        }
        if (champ == "categorieNiveau1") {
            interaction.categorieNiveau1 = val;
            this.props.categories.filter(categorieNiveau1 => categorieNiveau1.key == interaction.categorieNiveau1).map(categorieNiveau1 => {
                interaction.categorie = categorieNiveau1.categories[0].key
            });
        }
        if (champ == "categorie") {
            console.log(val);
            interaction.categorie = val;
        }
        if (champ == "beneficiaire") {
            interaction.beneficiaire = val || null;
        }
        if (champ == "enPresentiel") {
            interaction.enPresentiel = val;
        }
        if (champ == "enEntreprise") {
            interaction.enEntreprise = val;
        }
        console.log(interaction);
        this.setState({
            interaction: interaction
        });
        if (this.props.modifie) {
            this.props.modifie(this.state.interaction);
        }
    }

	render() {

        let calendarService = new CalendarService();
        let dataService = new DataService();

        let structure = dataService.getStructureById(this.props.beneficiaire ? this.props.beneficiaire.structure : (this.props.beneficiaires && this.props.beneficiaires.length > 0 ? this.props.beneficiaires[0].structure : null))

        let scores = [];
        for (let i = 1 ; i <= 10 ; i++) {
            scores.push(
                <option key={"option-score-" + i} value={i}>{i}</option>
            );
        }

        let heures = [];
        for (let i = 7 ; i < 22 ; i++) {
            heures.push(
                <option key={"option-heure-" + i} value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>
            );
        }

        let minutes = [];
        for (let i = 0 ; i < 60 ; i+=15) {
            minutes.push(
                <option key={"option-minute-" + i} value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>
            );
        }

        let beneficiaires = [];
        if (this.props.beneficiaires && !this.props.beneficiaire) {
            this.props.beneficiaires.sort((a, b) => (a.nom + " " + a.prenom) < (b.nom + " " + b.prenom) ? -1 : 1).map((beneficiaire) => {
                beneficiaires.push(
                    <option key={"option-beneficiaire-" + beneficiaire.id} value={beneficiaire.id}>{beneficiaire.prenom} {beneficiaire.nom}</option>
                )
            });
        }
        
        let categoriesNiveau1 = [];
        let categories = [];
        this.props.categories.map(categorieNiveau1 => {
            categoriesNiveau1.push(
                <option key={"option-categorieniveau1-" + categorieNiveau1.key} value={categorieNiveau1.key}>{categorieNiveau1.value}</option>
            );
            if (categorieNiveau1.key == this.state.interaction.categorieNiveau1) {
                categorieNiveau1.categories.map(categorie => {
                    categories.push(
                        <option key={"option-categorie-" + categorie.key} value={categorie.key}>{categorie.value}</option>
                    );
                });
            }
        });

        return ( 
            <div className="row">
                <div className="col-md-12">
                    <form>
                        {beneficiaires.length > 0 &&
                            <div className="form-group">
                                <div className="input-group input-obligatoire">
                                    <select className="form-control" value={this.state.interaction.beneficiaire || ""} onChange={(e) => this.modifieInteraction("beneficiaire", e.target.value)}>
                                        {beneficiaires}
                                    </select>
                                </div>
                            </div>
                        }
                        {(!structure || !['accompagnement', 'entreprise'].includes(structure.domaine)) &&
                            <>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label className="col-form-label">Type d'interaction :</label>
                                        </div>
                                        
                                            <div className="form-group col-md-6">
                                                <div className="input-group">
                                                    <select className="form-control ml-0" value={this.state.interaction.categorieNiveau1} onChange={(e) => this.modifieInteraction("categorieNiveau1", e.target.value)}>
                                                        {categoriesNiveau1}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <div className="input-group">
                                                    <select ref="interactionCategorie" className="form-control select2" id={"select2-" + this.props.origine + "-interaction-categories-" + this.state.interaction.id} value={this.state.interaction.categorie} onChange={() => {}}>
                                                        {categories}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    <div className="form-group mb-0">
                                        <input type="checkbox" id={"toggle-" + this.props.origine + "-interaction-enPresentiel-" + this.state.interaction.id} switch="warning" checked={(this.state.interaction.enPresentiel)} onChange={() => this.modifieInteraction("enPresentiel", !this.state.interaction.enPresentiel)} />
                                        <label htmlFor={"toggle-" + this.props.origine + "-interaction-enPresentiel-" + this.state.interaction.id}></label>En présence de la personne
                                    </div>
                                    <div className="form-group">
                                        <input type="checkbox" id={"toggle-" + this.props.origine + "-interaction-enEntreprise-" + this.state.interaction.id} switch="warning" checked={(this.state.interaction.enEntreprise)} onChange={() => this.modifieInteraction("enEntreprise", !this.state.interaction.enEntreprise)} />
                                        <label htmlFor={"toggle-" + this.props.origine + "-interaction-enEntreprise-" + this.state.interaction.id}></label>Sur le lieu de travail
                                    </div>
                            </>
                        }
                        <div className="form-group">
                            <div className="input-group input-obligatoire">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Titre *</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.interaction.description || ""} onChange={(e) => this.modifieInteraction("description", e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row row-forminteraction">                            
                            <div className="input-group date input-obligatoire">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Date *</div>
                                </div>
                                <DatePicker
                                    ref={(input) => { this.ref_dateWidget = input; }} 
                                    className="form-control"
                                    locale="fr" 
                                    selected={this.state.interaction.dateWidget}
                                    onChange={(date) => this.modifieInteraction("dateWidget", date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                                <div className="input-group-append" onClick={() => this.ref_dateWidget.setFocus(true)}>
                                    <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                </div>
                            </div>
                            <span> à </span>
                            <select className="form-control" value={this.state.interaction.debutWidget.heure} onChange={(e) => this.modifieInteraction("debutWidget-heure", e.target.value)}>
                                {heures}
                            </select>
                            <span> h </span>
                            <select className="form-control" value={this.state.interaction.debutWidget.minute} onChange={(e) => this.modifieInteraction("debutWidget-minute", e.target.value)}>
                                {minutes}
                            </select>
                            {/*<span> à </span>
                            <select className="form-control" value={this.state.interaction.finWidget.heure} onChange={(e) => this.modifieInteraction("finWidget-heure", e.target.value)}>
                                {heures}
                            </select>
                            <span> h </span>
                            <select className="form-control" value={this.state.interaction.finWidget.minute} onChange={(e) => this.modifieInteraction("finWidget-minute", e.target.value)}>
                                {minutes}
                    </select>                        */}
                        </div>
                        {structure && ['accompagnement', 'entreprise'].includes(structure.domaine) &&
                            <br />
                        }
                        <div className="form-group row">
                            <select className="form-control" value={(this.state.interaction.score === null ? "reinitialiser" : (this.state.interaction.score == 0 ? "stagner" : (this.state.interaction.score > 0 ? "avancer" : "reculer")))} onChange={(e) => this.modifieInteraction("scorePlusMoins", e.target.value)} disabled={this.props.scoreDisabled}>
                                <option key="reponse-compteur-reinitialiser" value="reinitialiser">Remet le compteur à zéro</option>
                                <option key="reponse-compteur-stagner" value="stagner">Ne fait pas avancer le compteur</option>
                                <option key="reponse-compteur-avancer" value="avancer">Fait avancer le compteur</option>
                                <option key="reponse-compteur-reculer" value="reculer">Fait reculer le compteur</option>
                            </select>
                            {this.state.interaction.score != null && this.state.interaction.score != 0 &&
                                <>
                                    <span>de </span>
                                    <select className="form-control" value={Math.abs(this.state.interaction.score)} onChange={(e) => this.modifieInteraction("score", e.target.value)}>
                                        {scores}
                                    </select>
                                    <span>{Math.abs(this.state.interaction.score) > 1 ? "signaux faibles" : "signal faible"}</span>
                                </>
                            }
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Commentaire <InfobulleRGPD /> :</label>
                            <div className="input-group">
                                <textarea id="textarea" className="form-control" rows="3" value={this.state.interaction.commentaire || ""} onChange={(e) => this.modifieInteraction("commentaire", e.target.value)}></textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
	}
}

export default FormInteraction;