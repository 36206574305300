import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../assets/images/logo.png';

class MentionsLegales extends Component {


	componentDidMount() {
		console.log("mentions legages did mount");

		document.getElementById('page-login').classList.add('cgu');
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						<h3 className="text-muted font-18 m-b-5 text-center">Mentions légales</h3>
						<p className="p-3 text-center">
							En vigueur au 22/01/2024
						</p>
						<h5 className="p-3">Editeur</h5>
						<p className="px-3 text-justify">
							Les sites www.wesireport.com et app.wesireport.com sont édités par la société Wesireport, société par actions simplifiée au capital de 12 856 euros, inscrite au RCS de Bordeaux sous le numéro 841 368 822, dont le siège social est situé 82 Route de Jean Roux 33700 Salles.<br />
							Numéro de TVA intra-communautaire : FR26841368822<br /><br />
							Le Directeur de la publication est Monsieur Christophe Guérin.<br />
							Numéro de téléphone:  06 71 58 91 07 <br />
							Adresse email: contact@wesireport.com
						</p>
						<p className="px-3 text-justify">
							Pour toute question relative à l'exercice des droits : rgpd@wesireport.com
						</p>
						<h5 className="p-3">Hébergeur</h5>
						<p className="px-3 text-justify">
							Ce site est hébergé par la société OVHCloud Health Care.<br />
							Adresse : 2 rue Kellermann BP 80157 59053 Roubaix Cedex 1.<br />
							Numéro de téléphone : 1007							
						</p>						
					</div>
				</div>
			</div>
		);
	}
}

export default MentionsLegales;