import React, { Component } from 'react';

import CalendarService from '../../services/calendar';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfobulleRGPD from '../InfobulleRGPD';

class FormPeriode extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.erreurs = [];
    }

    initFromProps() {

        let calendarService = new CalendarService();

        let fin = new Date();
        fin.setMonth(fin.getMonth() + 1);

        let finRepetition = new Date();
        finRepetition.setMonth(finRepetition.getMonth() + 13);

        let periode = this.props.periode ? JSON.parse(JSON.stringify(this.props.periode)) : {     
            id: null,
            classe: "PeriodeSensible",
            description: "",
            commentaire: "",
            repetition: {
                type: "none",
                pas: 1,
                fin: {
                    type: "none",
                    date: calendarService.formatteDate(finRepetition),
                    dateWidget: finRepetition,
                    occurrences: 10
                }
            },
            seuil: 5,
            debutWidget: new Date(),
            debut: calendarService.formatteDate(new Date()),
            finWidget: fin,
            fin: calendarService.formatteDate(fin),
        };

        periode.debutWidget = new Date(periode.debut);
        periode.finWidget = new Date(periode.fin);
        periode.repetition.fin.dateWidget = (periode.repetition.fin.date) ? new Date(periode.repetition.fin.date) : new Date();

        return {
            periode: periode
        };
    }

    componentDidMount() {
		console.log("formperiode did mount");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.periode != this.props.periode) {
            this.setState(this.initFromProps());
        }
    }
    
    modifiePeriode(champ, val) {
        let periode = this.state.periode;
        console.log(periode);
        console.log(champ);
        console.log(val);
        if (champ == "description") {
            periode.description = val;
        }
        if (champ == "commentaire") {
            periode.commentaire = val;
        }
        if (champ == "seuil") {
            if (val == "" || /^[0-9]*$/.test(val)) {
                periode.seuil = val;
            }            
        }
        if (champ == "repetition-type") {
            periode.repetition.type = val;
        }
        if (champ == "repetition-pas") {
            periode.repetition.pas = val;
        }
        if (champ == "repetition-fin-type") {
            periode.repetition.fin.type = val;
        }
        if (champ == "repetition-fin-date") {
            let calendarService = new CalendarService();
            periode.repetition.fin.dateWidget = val;
            periode.repetition.fin.date = calendarService.formatteDate(periode.repetition.fin.dateWidget);
            periode.repetition.fin.type = "date";
        }
        if (champ == "repetition-fin-occurrences") {
            periode.repetition.fin.occurrences = val;
            periode.repetition.fin.type = "occurrences";
        }
        if (champ == "debut") {
            let calendarService = new CalendarService();
            periode.debutWidget = val;
            periode.debut = calendarService.formatteDate(periode.debutWidget);
            if (periode.debut >= periode.fin) {                                
                periode.finWidget = new Date(periode.debutWidget.getTime());
                periode.finWidget.setDate(periode.finWidget.getDate() + 1);                        
                periode.fin = calendarService.formatteDate(periode.finWidget)                
            }
        }
        if (champ == "fin") {
            let calendarService = new CalendarService();
            periode.finWidget = val;
            periode.fin = calendarService.formatteDate(periode.finWidget);
        }
        console.log(periode);
        this.setState({
            periode: periode,
            erreurs: this.valideChamp(champ)
        });
        console.log(this.state.periode);
        if (this.props.modifie) {
            this.props.modifie(this.state.periode);
        }
    }

    valideChamp(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
        if (champ == champ == "repetition-fin-type") {
            erreurs.splice(erreurs.indexOf("repetition-fin-occurrences"), 1);
        }
        if (champ == "description" && this.state.periode.description == "") {
            erreurs.push(champ);
        }
        if (champ == "seuil" && (this.state.periode.seuil == "" || !/^[0-9]*$/.test(this.state.periode.seuil))) {
            erreurs.push(champ);
        }
        if ((champ == "repetition-fin-occurrences" || champ == "repetition-fin-type") && this.state.periode.repetition.fin.type == "occurrences" && (this.state.periode.repetition.fin.occurrences == "" || !/^[0-9]*$/.test(this.state.periode.repetition.fin.occurrences))) {
            erreurs.push("repetition-fin-occurrences");
        }
        return erreurs;
    }


	render() {

        return ( 
            <div className="row">
                <div className="col-md-8">
                    <form>
                        <div className="form-group">
                            <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('description') ? ' input-error' : '')}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Nom de la période *</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.periode.description} onChange={(e) => this.modifiePeriode("description", e.target.value)} />
                            </div>
                        </div>  
                        
                        <div className="input-group date">
                            <label>Du</label>
                            <DatePicker
                                ref={(input) => { this.ref_dateDebutWidget = input; }} 
                                className="form-control" 
                                locale="fr"
                                selected={this.state.periode.debutWidget}
                                onChange={(date) => this.modifiePeriode("debut", date)}
                                dateFormat="dd/MM/yyyy"
                                //maxDate={this.state.periode.finWidget}
                            />    
                            <div className="input-group-append" onClick={() => this.ref_dateDebutWidget.setFocus(true)}>
                                <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                            </div>
                        </div>
                        <div className="input-group date">
                            <label>Au</label>
                            <DatePicker
                                ref={(input) => { this.ref_dateFinWidget = input; }} 
                                className="form-control" 
                                locale="fr"
                                selected={this.state.periode.finWidget}
                                onChange={(date) => this.modifiePeriode("fin", date)}
                                dateFormat="dd/MM/yyyy"
                                minDate={this.state.periode.debutWidget}
                            />   
                            <div className="input-group-append" onClick={() => this.ref_dateFinWidget.setFocus(true)}>
                                <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                            </div>
                        </div>                                                                              
                        <br clear="both" />
                        <hr />        
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="label-compteur">
                                        Définir le seuil d'alerte temporaire à :
                                        <input type="text" className={"form-control simple" + (this.state.erreurs.includes('seuil') ? ' input-error' : '')} id="inlineFormInputGroupUsername" value={this.state.periode.seuil} onChange={(e) => this.modifiePeriode("seuil", e.target.value)} />
                                        {/*<span className="info" data-toggle="tooltip" data-placement="top" title="Contenu de l'info">?</span>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="form-group">
                            <label className="col-form-label">Commentaire <InfobulleRGPD /> :</label>
                            <div className="input-group">
                                <textarea id="textarea" className="form-control" maxLength="225" rows="3" value={this.state.periode.commentaire} onChange={(e) => this.modifiePeriode("commentaire", e.target.value)}></textarea>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4">
                    <div className="modal-sidebar">
                        <input type="checkbox" id={"toggle-periode-repetition-" + (this.state.periode.id ? this.state.periode.id : (this.props.sidebar ? "sidebar" : "null"))} switch="warning" checked={(this.state.periode.repetition.type != "none")} onChange={() => this.modifiePeriode("repetition-type", ((this.state.periode.repetition.type != "none") ? "none" : "mois"))} />
                        <label htmlFor={"toggle-periode-repetition-" + (this.state.periode.id ? this.state.periode.id : (this.props.sidebar ? "sidebar" : "null"))}></label>Répéter 
                        {this.state.periode.repetition.type != "none"  && 
                            <>    
                                <h6 className="">
                                    Répéter tous les
                                    <select className="form-control" value={this.state.periode.repetition.pas} onChange={(e) => this.modifiePeriode("repetition-pas", e.target.value)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                    <select className="form-control" value={this.state.periode.repetition.type} onChange={(e) => this.modifiePeriode("repetition-type", e.target.value)}>
                                        <option value="mois">mois</option>
                                        <option value="an">ans</option>
                                    </select>
                                </h6>    
                                <h6 className="">Se termine</h6>
                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.periode.repetition.fin.type == "none"} onChange={() => this.modifiePeriode("repetition-fin-type", "none")} /> Jamais
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.periode.repetition.fin.type == "date"} onChange={() => this.modifiePeriode("repetition-fin-type", "date")} /> Le :
                                            <span className="checkmark"></span>
                                            <div className="input-group date">
                                                <DatePicker
                                                    ref={(input) => { this.ref_dateRepetitionFinWidget = input; }} 
                                                    className="form-control inline" 
                                                    locale="fr"
                                                    selected={this.state.periode.repetition.fin.dateWidget}
                                                    onChange={(date) => this.modifiePeriode("repetition-fin-date", date)}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                                <div className="input-group-append" onClick={() => this.ref_dateRepetitionFinWidget.setFocus(true)}>
                                                    <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.periode.repetition.fin.type == "occurrences"} onChange={() => this.modifiePeriode("repetition-fin-type", "occurrences")} /> Après
                                            <input type="text" className={"form-control simple" + (this.state.erreurs.includes('repetition-fin-occurrences') ? ' input-error' : '')} id="inlineFormInputGroupUsername" value={this.state.periode.repetition.fin.occurrences} onChange={(e) => this.modifiePeriode("repetition-fin-occurrences", e.target.value)}/> occurrences
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <br clear="both" />
                            </>
                        }
                    </div>
                </div>
            </div>
        );
	}
}

export default FormPeriode;