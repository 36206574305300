import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import cookie from 'react-cookies';
import { LastLocationProvider } from 'react-router-last-location';

import conseillerAndResponsableRoutes from './routes/conseillerAndResponsableRoutes';
import conseillerRoutes from './routes/conseillerRoutes';
import publicRoutes from './routes/publicRoutes';
import adminRoutes from './routes/adminRoutes';
import responsableRoutes from './routes/responsableRoutes';
import utilisateurRoutes from './routes/utilisateurRoutes';
import errorRoutes from './routes/errorRoutes';

import ScrollToTop from './ScrollToTop'

import LayoutConseiller from './LayoutConseiller';
import LayoutPublic from './LayoutPublic';

import Login from '../pages/Login';
import Loading from '../pages/Loading';

import Erreur404 from '../pages/Erreur404';

import storageKey from '../services/session';
import SecurityService from '../services/security';
import DataService from '../services/data';

const mapStateToProps = (state, props) => {

    console.log("mapStateToProps");

    let login = false;
    //console.log("cookie", cookie.load(storageKey));
    if (cookie.load(storageKey) != undefined && cookie.load(storageKey) != 0) {
        //console.log('cookie ok');
        login = true;
    }
    else {
        if (localStorage.getItem(storageKey) != null) {
            //console.log('localstorage ok');
            cookie.save(storageKey, localStorage.getItem(storageKey), {path: '/'});
            login = true;
        }
    }
    
	return {
	  data: state.data,
      ihm: state.ihm,
      login: login,
      location: props.history.location
	}
}

class Template extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewportWidth: window.innerWidth,
            initCalled: false
        }

        this.interval = null;

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        console.log('templateindex didmount');
        if (!this.props.data.init && this.props.login) {
            this.setState({
                initCalled: true
            })
            this.props.dispatch({ type: "DATA_INIT", payload: null });
            /*this.interval = setInterval(() => {
                this.props.dispatch({ type: "DATA_UPDATES", payload: null });
            }, 300000);*/
        }  
        
        // si on vise une page précise avant d'être redirigé vers login
        if (this.props.location.pathname != "/login" && this.props.location.pathname != "/logout" && this.props.location.pathname.indexOf("/compte/") == -1) {
            this.props.dispatch({ type: "IHM_URLCIBLE", payload: this.props.location.pathname })
        }

        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentDidUpdate(prevProps) {
        console.log('templateindex didupdate');
        console.log(this.props.data.init, this.props.login);
        if (!this.props.data.init && this.props.login && !this.state.initCalled) {
            this.props.dispatch({ type: "DATA_INIT", payload: null });
            //if (this.interval) clearInterval(this.interval);
        }
        else {
            /*if (this.interval) clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.props.dispatch({ type: "DATA_UPDATES", payload: null });
            }, 300000);*/
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            viewportWidth: window.innerWidth 
        });
    }
    
    render() {

        if (!this.props.data.init && this.props.login) return (<Loading />);

        let securityService = new SecurityService();
        let dataService = new DataService();

        let routes = {};
        console.log(this.props.data.me);
        if (this.props.data.me) {
            if (this.props.data.me.roles.includes('ROLE_ADMIN')) {
                routes = Object.assign(routes, adminRoutes);
            }
            if (securityService.isResponsable() && dataService.getConseillerById(this.props.data.me.id) && !dataService.getConseillerById(this.props.data.me.id).seulementAdmin) {
                routes = Object.assign(routes, conseillerAndResponsableRoutes);
            }
            if (this.props.data.me.roles.includes('ROLE_ADMIN') || securityService.isResponsable()) {
                routes = Object.assign(routes, responsableRoutes);
            }
            if (this.props.data.me.roles.includes('ROLE_ADMIN') || securityService.isResponsable() || (dataService.getConseillerById(this.props.data.me.id) && !dataService.getConseillerById(this.props.data.me.id).seulementAdmin)) {
                routes = Object.assign(routes, conseillerRoutes);
            }
            routes = Object.assign(routes, utilisateurRoutes);
        }

        //routes = Object.assign(routes, publicRoutes);

        console.log(routes);
        console.log(this.props.data.me);
        console.log(this.props.location.pathname);
        console.log(window.location.pathname);

        return (
            <>                
                <BrowserRouter>
                    <ScrollToTop />
                    <LastLocationProvider watchOnlyPathname={true}>
                        <Switch>
                            { _.map(routes, (route, key) => {
                                const { component, path } = route;
                                return (
                                    <Route
                                        exact
                                        path={path}
                                        key={key}
                                        render={ (route) => <LayoutConseiller component={component} route={route} mobile={this.state.viewportWidth < 992} />}
                                    />
                                )
                            }) }
                            { _.map(publicRoutes, (route, key) => {
                                const { component, path, acceptsMobile } = route;
                                return (
                                    <Route
                                        exact
                                        path={path}
                                        key={key}
                                        render={ (route) => <LayoutPublic component={component} route={route} mobile={this.state.viewportWidth < 992 && !acceptsMobile} />}
                                    />
                                )
                            }) }
                            {this.props.data.me && this.props.location.pathname != "/" &&
                                <Redirect to="/" />
                            }
                            {!this.props.data.me && 
                                <>
                                    <Route
                                        exact
                                        path="/login"
                                        key="Login"
                                        render={ (route) => <LayoutPublic component={Login} route={route} mobile={this.state.viewportWidth < 992} />}
                                    />
                                    {(this.props.location.pathname != "/login" || window.location.pathname != "/login") &&
                                        <Redirect to="/login" />
                                    }
                                </>
                            }
                        </Switch>
                    </LastLocationProvider>
                </BrowserRouter>
            </>
        );
    }
}

export default  connect(mapStateToProps, null)(Template);