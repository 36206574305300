import React, { Component } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import CalendarService from '../../services/calendar';
import { questionValide } from '../../services/validation';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const SortableReponse = SortableElement(({value, i, items, question, questionsDeclenchees, scores, modifieReponse, supprimeReponse, editeQuestionDeclenchee}) => {
    let r = value;
    let index = i;

    return (
        <div className="ordrereponseschild">
            <div className="liste-question">
                {question.type == "liste" &&
                    <>
                        <div className="form-row question-reponse">
                            <div className="form-group col-md-5">
                                <div className="input-group"> 
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Réponse" value={r.libelle || ""} onChange={(e) => modifieReponse(index, "libelle", e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <div className="input-group">	
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername simple" placeholder="Code" value={r.cle.code || ""} onChange={(e) => modifieReponse(index, "cle-code", e.target.value)} />
                                </div>
                            </div>
                            {/*<div className="form-group col-md-3">
                                <div className="input-group">	
                                    <select className="form-control" value={r.cle.icone || ""} onChange={(e) => modifieReponse(index, "cle-icone", e.target.value)}>
                                        <option value="">Pas d'icône</option>
                                        <option value={"\u{1F600}"}>{"\u{1F600}"}</option>
                                        <option value={"\u{1F61E}"}>{"\u{1F61E}"}</option>
                                    </select>
                                </div>
                            </div>*/}
                            <div className="form-group col-md-1">
                                <a href="#" onClick={(e) => supprimeReponse(e, index)} className="m-0 btn btn-annexe contour"><i className="dripicons-trash"></i></a>
                            </div>    
                            <div className="form-group col-md-1">
                                {items.length > 1 && <div className="deplacer"><i className="dripicons-move"></i></div>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="label-compteur">
                                    <div className="form-group">
                                        <select className="form-control" value={(r.score == 0 ? "stagner" : r.score > 0 ? "avancer" : "reculer")} onChange={(e) => modifieReponse(index, "scorePlusMoins", e.target.value)}>
                                            <option key="reponse-compteur-stagner" value="stagner">Ne fait pas avancer le compteur</option>
                                            <option key="reponse-compteur-avancer" value="avancer">Fait avancer le compteur de</option>
                                            <option key="reponse-compteur-reculer" value="reculer">Fait reculer le compteur de</option>
                                        </select>
                                        {r.score != 0 &&
                                            <>
                                                <select className="form-control" value={Math.abs(r.score)} onChange={(e) => modifieReponse(index, "score", e.target.value)}>
                                                    {scores}
                                                </select>
                                                <label className="col-form-label ml-20">{Math.abs(r.score) <= 1 ? "signal faible" : "signaux faibles"} si cette réponse est choisie</label>
                                            </>
                                        }
                                        <br clear="both" />
                                    </div>
                                </div>
                                <div className="label-declencher">
                                    <div className="form-group">
                                        <label className="col-form-label ml-20">Déclencher une autre question suite à cette réponse :</label>
                                        <select className="form-control" value={r.action ? r.action : ''} onChange={(e) => modifieReponse(index, "action", e.target.value)}>
                                            <option value="">Aucune question déclenchée</option>
                                            <option value="question-etre-rappele">Souhaitez-vous être rappelé(e) ?</option>
                                            <option value="question">Question personnalisée</option>
                                        </select>
                                        {r.action === 'question' &&
                                            <>
                                                {r.questionDeclenchee !== null &&
                                                    <>
                                                        <label className="col-form-label ml-20">Question déclenchée : {r.questionDeclenchee.libelleCalcule} <a className="text-warning" href="#" onClick={(e) => {e.preventDefault(); editeQuestionDeclenchee(r, index)}}>Editer la question</a></label>
                                                    </>
                                                }    
                                            </>                                        
                                        }                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }  
                {question.type == "gradient" &&
                    <>
                        <div className="form-row question-reponse">
                            <div className="form-group col-md-1">
                                <span>{r.cle.code}</span>
                            </div>
                            <div className="col-md-11">
                                <div className="label-compteur">
                                    <div className="form-group">
                                        <select className="form-control" value={(r.score == 0 ? "stagner" : r.score > 0 ? "avancer" : "reculer")} onChange={(e) => modifieReponse(index, "scorePlusMoins", e.target.value)}>
                                            <option key="reponse-compteur-stagner" value="stagner">Ne fait pas avancer le compteur</option>
                                            <option key="reponse-compteur-avancer" value="avancer">Fait avancer le compteur de</option>
                                            <option key="reponse-compteur-reculer" value="reculer">Fait reculer le compteur de</option>
                                        </select>
                                        {r.score != 0 &&
                                            <>
                                                <select className="form-control" value={Math.abs(r.score)} onChange={(e) => modifieReponse(index, "score", e.target.value)}>
                                                    {scores}
                                                </select>
                                                <label className="col-form-label ml-20">{Math.abs(r.score) <= 1 ? "signal faible" : "signaux faibles"} si cette réponse est choisie</label>
                                            </>
                                        }
                                        {r.score == 0 &&
                                            <label className="col-form-label ml-20">si cette réponse est choisie</label>
                                        }
                                        <br clear="both" />
                                    </div>
                                </div>
                                <div className="label-declencher">
                                    <div className="form-group">
                                        <label className="col-form-label ml-20">Déclencher une autre question suite à cette réponse :</label>
                                        <select className="form-control" value={r.action ? r.action : ''} onChange={(e) => modifieReponse(index, "action", e.target.value)}>
                                            <option value="">Aucune question déclenchée</option>
                                            <option value="question-etre-rappele">Souhaitez-vous être rappelé(e) ?</option>
                                            <option value="question">Question personnalisée</option>
                                        </select>
                                        {r.action === 'question' &&
                                            <>
                                                {r.questionDeclenchee !== null &&
                                                    <>
                                                        <label className="col-form-label ml-20">Question déclenchée : {r.questionDeclenchee.libelleCalcule} <a className="text-warning" href="#" onClick={(e) => {e.preventDefault(); editeQuestionDeclenchee(r, index)}}>Editer la question</a></label>
                                                    </>
                                                }    
                                            </>                                        
                                        }                                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
});

const SortableReponses = SortableContainer(({items, question, questionsDeclenchees, scores, modifieReponse, supprimeReponse, editeQuestionDeclenchee}) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableReponse key={`item-${index}`} sortIndex={value.numero} index={value.numero} i={index} items={items} value={value} question={question} questionsDeclenchees={questionsDeclenchees} editeQuestionDeclenchee={editeQuestionDeclenchee} scores={scores} modifieReponse={modifieReponse} supprimeReponse={supprimeReponse} />
            ))}
        </div>
    );
}); 


class FormQuestionDefaut extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();        
    }

    initFromProps() {
        console.log('initfromprops', JSON.parse(JSON.stringify(this.props.question)))
        let question = this.props.question ? JSON.parse(JSON.stringify(this.props.question)) : this.nouvelleQuestionDefaut();        

        return {
            question: question,
            questionsDeclenchees: []
        };
    }

    /*componentDidMount() {
        console.log('formquestiondefaut did update')
        if (this.props.question.id !== prevProps.question.id) {
            console.log('formquestiondefaut did update init')
            this.initFromProps()
        }
    }*/

    nouvelleQuestionDefaut() {
        return {     
            id: null,
            classe: "QuestionDefaut",
            libelle: "",
            cle: {
                code: null,
                icone: null
            },
            type: "liste",
            parametres: {
                "liste": {},
                "gradient": {
                    min: 1,
                    max: 5
                },
                "information": {}
            },
            gradientMin: 1,
            gradientMax: 5,
            reponses: [],
            reponseDefaut: {
                id: null,
                classe: "Reponse",
                libelle: "Pas de réponse",
                cle: {
                    code: null,
                    icone: null
                },
                numero: 0,
                score: 0,
                action: '',
                questionDeclenchee: null
            },
            categories: [],
            cibles: [],
            domaines: [],
            communication: 'vouvoiement'
        }
    }

    nouvelleQuestion() {
        return {     
            id: null,
            classe: "Question",
            libelle: "",
            numero: this.props.numero,
            cle: {
                code: null,
                iconde: null
            },
            type: "liste",
            parametres: {
                "liste": {},
                "gradient": {
                    min: 1,
                    max: 5
                },
                "information": {}
            },
            gradientMin: 1,
            gradientMax: 5,
            repetition: {
                type: "none",
                joursSemaine: [1],
                mois: {
                    type: 'absolu',
                    joursAbsolus: [1],
                    joursRelatifs: {
                        relatif: 'first',
                        jour: 0
                    },
                    frequence: 1,
                    debut: {
                        mois: (new Date()).getMonth() + 1,
                        annee: (new Date()).getFullYear()
                    }
                },
                jourAn: {
                    jour: "01",
                    mois: "01"
                },
                heure: "12",
                minute: "00",
                fin: {
                    type: "none",
                    date: null,
                    dateWidget: new Date(),
                    occurrences: 10
                },
            },
            recurrence: 1,
            reponses: [],
            reponseDefaut: {
                id: null,
                classe: "Reponse",
                libelle: "Pas de réponse",
                cle: {
                    code: null,
                    icone: null
                },
                numero: 0,
                score: 0,
                questionDeclenchee: null
            }
        }
    }

    componentDidMount() {
		console.log("formquestion did mount");
        this.bindSelect2()
    }

    componentDidUpdate(prevProps) {
        console.log("formquestion did update");
        console.log(prevProps.question, this.props.question)
        if (
            (prevProps.question === null && this.props.question !== null)
            || (prevProps.question !== null && this.props.question === null)
            || (
                prevProps.question !== null
                && this.props.question !== null
                && (
                    prevProps.question.id != this.props.question.id
                    || (this.props.question.id == null && this.props.question.libelle != '' && prevProps.question.libelle != this.props.question.libelle)
                )
            )
        ) {
            console.log('initFromProps')
            this.setState(this.initFromProps())
            if (this.props.question) {
                setTimeout(() => {
                    window.jQuery("#form-question-categories").val(this.props.question.categories).trigger('change')    
                    window.jQuery("#form-question-domaines").val(this.props.question.domaines).trigger('change')    
                    window.jQuery("#form-question-cibles").val(this.props.question.cibles).trigger('change')    
                    window.jQuery("#form-question-communication").val(this.props.question.communication).trigger('change')    
                }, 0)
            }         
        }          
    }

    bindSelect2() {
        window.jQuery("#form-question-categories").off('change');
        window.jQuery("#form-question-categories").on('change', (e) => {
            this.modifieQuestion('categories', window.jQuery("#form-question-categories").val())	
        });
        window.jQuery("#form-question-categories").val(this.state.question.categories).trigger('change')

        window.jQuery("#form-question-domaines").off('change');
        window.jQuery("#form-question-domaines").on('change', (e) => {
            this.modifieQuestion('domaines', window.jQuery("#form-question-domaines").val())	
        });
        window.jQuery("#form-question-domaines").val(this.state.question.domaines).trigger('change')

        window.jQuery("#form-question-cibles").off('change');
        window.jQuery("#form-question-cibles").on('change', (e) => {
            this.modifieQuestion('cibles', window.jQuery("#form-question-cibles").val())	
        });
        window.jQuery("#form-question-cibles").val(this.state.question.cibles).trigger('change')

        window.jQuery("#form-question-communication").off('change');
        window.jQuery("#form-question-communication").on('change', (e) => {
            this.modifieQuestion('communication', window.jQuery("#form-question-communication").val())	
        });
        window.jQuery("#form-question-communication").val(this.state.question.communication).trigger('change')
    }
    
    modifieQuestion(champ, val) {
        let question = this.state.question;        
        if (champ == "libelle") {
            question.libelle = val;
        }
        if (champ == "type") {
            question.type = val;
            if (question.type == "gradient") {
                question = this.ajusteReponsesGradient(question);
            }
        }
        if (champ == "parametres-gradient-min") {
            if (/^[0-9]*$/.test(val)) {
                question.parametres.gradient.min = val;
                question = this.ajusteReponsesGradient(question);    
            }            
        }
        if (champ == "parametres-gradient-max") {
            if (/^[0-9]*$/.test(val)) {
                question.parametres.gradient.max = val;
                question = this.ajusteReponsesGradient(question);
            }
        }
        if (champ == "reponses") {
            question.reponses = val;
        }
        if (champ == "reponseDefaut") {
            question.reponseDefaut = val;
        }
        if (champ == "categories") {
            question.categories = val
        }
        if (champ == "domaines") {
            question.domaines = val
        }
        if (champ == "cibles") {
            question.cibles = val
        }
        if (champ == "communication") {
            question.communication = val
        }
        console.log(JSON.parse(JSON.stringify(question)))
        this.setState({
            question: question    
        });
        if (this.state.questionsDeclenchees.length === 0 && this.props.modifie) {
            this.props.modifie(this.state.question);
        }
    }

    ajusteReponsesGradient(question) {
        console.log(parseInt(question.parametres.gradient.min) < parseInt(question.parametres.gradient.max));
        if (question.parametres.gradient.min !== null && question.parametres.gradient.min !== "" && question.parametres.gradient.max !== null && question.parametres.gradient.max !== "" && parseInt(question.parametres.gradient.min) < parseInt(question.parametres.gradient.max)) {
            question.reponses.map((r, index) => {
                if (r.cle.code) {
                    if (r.cle.code < question.parametres.gradient.min || r.cle.code > question.parametres.gradient.max) {
                        question.reponses[index].supprime = true;
                    }
                }
                else {
                    if ((index + 1) >= question.parametres.gradient.min && (index + 1) <= question.parametres.gradient.max) {
                        question.reponses[index].supprime = true;
                    }
                }
            });
            question.reponses = question.reponses.filter(reponse => reponse.id || !reponse.supprime);
            for (let i = parseInt(question.parametres.gradient.min) ; i <= parseInt(question.parametres.gradient.max) ; i++) {
                let trouve = false;
                question.reponses.filter((r) => !r.supprime).map((r) => {
                    if (r.cle.code) {
                        if (r.cle.code == i) {
                            trouve = true;
                        }
                    }
                    else {
                        if ((index + 1) == i) {
                            trouve = true;
                        }
                    }
                    
                });
                if (!trouve) {
                    question.reponses.push({
                        id: null,
                        classe: "Reponse",
                        libelle: null,
                        cle: {
                            code: i,
                            icone: null
                        },
                        numero: (i * 2),
                        score: 0,
                        questionDeclenchee: null,
                        supprime: false
                    });
                }
            }
        }
        return question;
    }

    modifieReponse(index, champ, val) {
        //let reponses = this.state.question.reponses;
        let reponses = this.state.question.reponses.filter((r) => !r.supprime).sort((a, b) => this.state.question.type == "liste" ? (a.numero < b.numero ? -1 : 1) : (a.cle.code < b.cle.code ? -1 : 1));

        if (reponses[index]) {
            if (champ == "cle-code") {
                reponses[index].cle.code = val;
            }
            if (champ == "cle-icone") {
                reponses[index].cle.icone = val;
            }
            if (champ == "libelle") {
                reponses[index].libelle = val;
            }
            if (champ == "score") {
                reponses[index].score = reponses[index].score >= 0 ? val : -val;
            }
            if (champ == "scorePlusMoins") {
                let score = Math.abs(reponses[index].score);
                if (val == "avancer") {
                    if (score == 0) {
                        score = 1
                    }
                }
                if (val == "reculer") {
                    if (score == 0) {
                        score = -1
                    }
                    else {
                        score = -score;
                    }
                }
                if (val == "stagner") score = 0;
                reponses[index].score = score;
            }
            if (champ == "action") {
                reponses[index].action = val;
                if (val === 'question' && reponses[index].questionDeclenchee === null) {
                    reponses[index].questionDeclenchee = this.nouvelleQuestion()
                }
            }            
            if (champ == "questionDeclenchee") {
                reponses[index].questionDeclenchee = val;
            }
            this.modifieQuestion('reponses', reponses);
        }
    }

    modifieReponseDefaut(champ, val) {
        let reponseDefaut = this.state.question.reponseDefaut;
        if (champ == "score") {
            reponseDefaut.score = reponseDefaut.score >= 0 ? val : -val;
        }
        if (champ == "scorePlusMoins") {
            let score = Math.abs(reponseDefaut.score);
            if (val == "avancer") {
                if (score == 0) {
                    score = 1
                }
            }
            if (val == "reculer") {
                if (score == 0) {
                    score = -1
                }
                else {
                    score = -score;
                }
            }
            if (val == "stagner") score = 0;
            reponseDefaut.score = score;
        }
        this.modifieQuestion("reponseDefaut", reponseDefaut);
    }

    editeQuestionDeclenchee(reponse, indexReponse) {
        this.state.questionsDeclenchees.push({
            indexReponse: indexReponse,
            question: JSON.parse(JSON.stringify(this.state.question))
        })

        this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0)

        this.setState({
            question: JSON.parse(JSON.stringify(reponse.questionDeclenchee)),
            questionsDeclenchees: this.state.questionsDeclenchees
        })
    }

    enregistreQuestionDeclenchee() {
        if (this.state.questionsDeclenchees.length === 0) return

        let question = this.state.questionsDeclenchees[this.state.questionsDeclenchees.length - 1].question
        question.reponses[this.state.questionsDeclenchees[this.state.questionsDeclenchees.length - 1].indexReponse].questionDeclenchee = JSON.parse(JSON.stringify(this.state.question))
        
        this.state.questionsDeclenchees.splice(-1)
        this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0)
        if (this.state.questionsDeclenchees.length === 0) {
            window.dispatchEvent(new Event('resize')); // pour remettre bien les dropdowns
            question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
        }
    
        this.setState({
            question: JSON.parse(JSON.stringify(question)),
            questionsDeclenchees: this.state.questionsDeclenchees
        }, () => {
            if (this.state.questionsDeclenchees.length === 0) {
                this.props.modifie(this.state.question)
            }            
        })               
    }

    annuleQuestionDeclenchee() {
        if (this.state.questionsDeclenchees.length === 0) return

        let question = this.state.questionsDeclenchees[this.state.questionsDeclenchees.length - 1].question
        
        this.state.questionsDeclenchees.splice(-1)        
        this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0)
        if (this.state.questionsDeclenchees.length === 0) {
            window.dispatchEvent(new Event('resize')); // pour remettre bien les dropdowns
            question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
        }
        
        this.setState({
            question: JSON.parse(JSON.stringify(question)),
            questionsDeclenchees: this.state.questionsDeclenchees
        }, () => {
            if (this.state.questionsDeclenchees.length === 0) {
                this.props.modifie(this.state.question)
            }            
        })  
    }

    blurScore(index) {
        let reponses = this.state.question.reponses;
        if (reponses[index]) {
            reponses[index].score = /^[0-9]*$/.test(reponses[index].score) ? reponses[index].score : 1;
        }
    }

    ajouteReponse(e) {
        e.preventDefault();

        let reponses = this.state.question.reponses;

        reponses.push({
            id: null,
            classe: "Reponse",
            libelle: null,
            cle: {
                code: null,
                icone: null
            },
            numero: ((reponses.length + 1) * 2),
            score: 0,
            action: '',
            questionDeclenchee: null,
            supprime: false
        });

        this.modifieQuestion('reponses', reponses);
    }

    supprimeReponse(e, index) {
        e.preventDefault();        

        let reponses = this.state.question.reponses;        

        if (index != null) {
            if (reponses.filter((r) => !r.supprime)[index].id) {
                reponses.filter((r) => !r.supprime)[index].supprime = true;
            }
            else {
                let cpt = 0
                for (let r in reponses) {
                    if (!reponses[r].supprime) {
                        if (cpt == index) {
                            reponses.splice(r, 1)
                        }
                        cpt++
                    }
                }
                //reponses.splice(index, 1);   
            }
            
        }

        this.modifieQuestion('reponses', reponses);
    }

    reordonneReponses(oldIndex, newIndex) {
        if (oldIndex != newIndex) {
            let reponses = this.state.question.reponses;
            reponses.map((r, index) => {
                if (r.numero == oldIndex) {
                    reponses[index].numero = (oldIndex > newIndex) ? (newIndex - 1) : (newIndex + 1);
                }
            });
            reponses = reponses.sort((a, b) => (a.numero < b.numero) ? -1 : 1);
            let numero = 2;
            reponses.map((r, index) => {
                reponses[index].numero = numero;
                numero += 2;
            });
            this.modifieQuestion('reponses', reponses);
        }    
    }

    getChaineSms() {
        let chaine = "";
        
        chaine += this.state.question.libelle + " ";

        if (this.state.question.type == "liste") {
            this.state.question.reponses.filter((reponse) => !reponse.supprime).sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, index) => {
                chaine += "\"" + reponse.libelle + "\"" + " Tapez " + (reponse.cle.code ? reponse.cle.code : (index + 1)) + (reponse.cle.icone ? ("ou " + reponse.cle.icone + " ") : "") + (index != this.state.question.reponses.length - 1 ? " " : "");
            });    
        }

        if (this.state.question.type == "gradient") {
            chaine += "Tapez une valeur entre " + this.state.question.parametres.gradient.min + " et " + this.state.question.parametres.gradient.max + "." + " ";
        }

        return chaine;
    }

    getNbCreditsSms() {
        let paliersUnicode = [70, 134, 201, 268, 335, 402, 469];
        let paliersGsm = [160, 306, 459, 612, 765, 918, 1071];

        let chaine = this.getChaineSms();

        let mode = "gsm";
        
        for (let i = 0 ; i < chaine.length ; i++) {
            if ("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMN OPQRSTUVWXYZÄäàÅåÆæßÇèéÉìÖöòØøÑñÜüù#¤%&()*+,-./:;<>=§$!?£¿¡@¥Δ_ΦΓΛΩΠΨΣΘΞ\"'".indexOf(chaine.charAt(i)) == -1) {
                mode = "unicode";
            }
        }

        let nbCredits = 1;

        if (mode == "gsm") {
            for (let i = 0 ; i < paliersGsm.length ; i++) {
                if (chaine.length > paliersGsm[i]) {
                    nbCredits++;
                }
            }
        } 

        if (mode == "unicode") {
            for (let i = 0 ; i < paliersUnicode.length ; i++) {
                if (chaine.length > paliersUnicode[i]) {
                    nbCredits++;
                }
            }
        } 


        return nbCredits;
    }

	render() {

        console.log(this.state.question)

        let calendarService = new CalendarService();

        let scores = [];
        for (let i = 1 ; i <= 10 ; i++) {
            scores.push(
                <option key={"option-score-" + i} value={i}>{i}</option>
            );
        }

        return ( 
            <div className="row">
                {this.state.questionsDeclenchees.length > 0 &&
                    <div className='col-md-12'>
                        <div className="alert alert-warning" role="alert">
                            <span className='text-secondary bold'>Question déclenchée par : </span>
                            {this.state.questionsDeclenchees.map((historique, indexHistorique) => {
                                console.log(historique)
                                return (
                                    <React.Fragment key={"historique-" + indexHistorique}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            let question = this.state.questionsDeclenchees[indexHistorique].question
                                            this.state.questionsDeclenchees.splice(-(this.state.questionsDeclenchees.length - indexHistorique))
                                            this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0)     

                                            if (this.state.questionsDeclenchees.length === 0) {
                                                window.dispatchEvent(new Event('resize')); // pour remettre bien les dropdowns
                                                question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
                                            }

                                            this.setState({
                                                question: JSON.parse(JSON.stringify(question)),
                                                questionsDeclenchees: this.state.questionsDeclenchees
                                            }, () => {
                                                if (this.state.questionsDeclenchees.length === 0) {
                                                    this.props.modifie(this.state.question)
                                                }            
                                            })
                                        }}><span className='text-warning'>{historique.question.libelle} &gt; {historique.question.reponses[historique.indexReponse].libelle}</span></a>{indexHistorique != this.state.questionsDeclenchees.length - 1 &&<> &gt; </>}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                }
                <div className="col-md-8">                    
                    <form>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Intitulé{this.state.question.type != 'information' ? ' de la question' : ''}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.question.libelle} onChange={(e) => this.modifieQuestion("libelle", e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label ml-3">Type :</label>
                            <div className="">
                                <select className="form-control" value={this.state.question.type} onChange={(e) => this.modifieQuestion("type", e.target.value)}>
                                    <option key="option-type-liste" value="liste">Liste de réponses</option>
                                    <option key="option-type-gradient" value="gradient">Gradient</option>
                                    <option key="option-type-information" value="information">Information</option>
                                </select>
                            </div>
                            {this.state.question.type == "gradient" &&
                                <>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">Valeur Min.</div>
                                                </div>
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.question.parametres.gradient.min} onChange={(e) => this.modifieQuestion("parametres-gradient-min", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">Valeur Max.</div>
                                                </div>
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.question.parametres.gradient.max} onChange={(e) => this.modifieQuestion("parametres-gradient-max", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {(this.state.question.type == "liste" || this.state.question.type == "gradient")  &&
                            <div id="ordrereponses">
                                <SortableReponses
                                    items={this.state.question.reponses.filter((r) => !r.supprime).sort((a, b) => this.state.question.type == "liste" ? (a.numero < b.numero ? -1 : 1) : (a.cle.code < b.cle.code ? -1 : 1))}
                                    shouldCancelStart={(e) => {
                                        return !e.target.classList.contains("dripicons-move");
                                    }}
                                    onSortEnd={({oldIndex, newIndex, collection, isKeySorting}, e) => {
                                        this.reordonneReponses(oldIndex, newIndex);
                                    }}
                                    helperContainer={() => {
                                        return document.querySelector("#modal-questiondefaut #ordrereponses"); 
                                    }}
                                    question={this.state.question}
                                    scores={scores}
                                    modifieReponse={(index, champ, val) => this.modifieReponse(index, champ, val)}
                                    supprimeReponse={(e, index) => this.supprimeReponse(e, index) }
                                    editeQuestionDeclenchee={(reponse, indexReponse) => this.editeQuestionDeclenchee(reponse, indexReponse)}                                    
                                />                                
                                <div className="liste-question">
                                    <div className="form-row question-reponse">
                                        <div className="col-md-12">
                                            <div className="label-compteur">
                                                <div className="form-group">
                                                    <select className="form-control" value={(this.state.question.reponseDefaut.score == 0 ? "stagner" : this.state.question.reponseDefaut.score > 0 ? "avancer" : "reculer")} onChange={(e) => this.modifieReponseDefaut("scorePlusMoins", e.target.value)}>
                                                        <option key="reponse-compteur-stagner" value="stagner">Ne fait pas avancer le compteur</option>
                                                        <option key="reponse-compteur-avancer" value="avancer">Fait avancer le compteur de</option>
                                                        <option key="reponse-compteur-reculer" value="reculer">Fait reculer le compteur de</option>
                                                    </select>                                                
                                                    {this.state.question.reponseDefaut.score != 0 &&
                                                        <>
                                                            <select className="form-control" value={Math.abs(this.state.question.reponseDefaut.score)} onChange={(e) => this.modifieReponseDefaut("score", e.target.value)}>
                                                                {scores}
                                                            </select>
                                                            <label className="col-form-label ml-20">{Math.abs(this.state.question.reponseDefaut.score) <= 1 ? "signal faible" : "signaux faibles"} si l'utilisateur ne répond pas</label>
                                                        </>
                                                    }
                                                    {this.state.question.reponseDefaut.score == 0 &&
                                                        <label className="col-form-label ml-20">si l'utilisateur ne répond pas</label>
                                                    }
                                                    <br clear="both" />
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                                {this.state.question.type == "liste" &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <a href="#" onClick={(e) => this.ajouteReponse(e)} className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle réponse</a>
                                        </div>
                                    </div>
                                }
                                <div className="ordrereponseschild">
                                    <div className="liste-question"></div>
                                </div>
                            </div>
                        }
                        {this.state.questionsDeclenchees.length !== 0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <button onClick={(e) => {e.preventDefault(); this.enregistreQuestionDeclenchee()}} disabled={questionValide(this.state.question) == false} type="button" className="btn btn-primary contour"> Enregistrer la question déclenchée</button>
                                    <button onClick={(e) => {e.preventDefault(); this.annuleQuestionDeclenchee()}} type="button" className="btn btn-annexe contour">Annuler</button>                                            
                                </div>
                            </div>
                        }
                    </form>
                </div>
                <div className="col-md-4">
                    <div className="modal-sidebar">
                        {this.state.questionsDeclenchees.length === 0 &&
                            <>
                                <div className="form-group">																				
                                    <div className="form-group">
                                        <label className="col-form-label ml-3">Secteur(s) d'activité :</label>
                                        <div className="">
                                            <select id="form-question-domaines" className="form-control select2 select2-multiple" multiple data-placeholder="Choisir..." value={this.state.question.domaines}>
                                                {Object.entries(this.props.domaines).map(([kDomaine, domaine]) => {
                                                    return (
                                                        <React.Fragment key={"domaine-" + kDomaine}>
                                                            <option value={kDomaine}>{domaine}</option>																								
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label ml-3">Cible(s) :</label>
                                    <div className="">
                                        <select id="form-question-cibles" className="form-control select2 select2-multiple" multiple data-placeholder="Choisir..." style={{padding: "0px imporant!"}}>
                                            {Object.entries(this.props.cibles).map(([kCible, cible]) => {
                                                console.log(kCible, cible)
                                                return (
                                                    <React.Fragment key={"cible-" + kCible}>
                                                        <option value={kCible}>{cible}</option>																							
                                                    </React.Fragment>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>      
                                <div className="form-group">																				
                                    <label className="col-form-label ml-3">Catégorie(s) :</label>
                                    <div className="">
                                        <select id="form-question-categories" className="form-control select2 select2-multiple" multiple data-placeholder="Choisir..." style={{padding: "0px imporant!"}}>
                                            {this.props.categories
                                            .map((categorie, iCategorie) => {
                                                console.log(categorie, iCategorie)
                                                return (
                                                    <React.Fragment key={"categorie-" + iCategorie}>
                                                        <option value={categorie.id}>{categorie.nom}</option>																								
                                                    </React.Fragment>
                                                )
                                            })}																		
                                        </select>																				
                                    </div>
                                </div>   
                                <div className="form-group">
                                    <label className="col-form-label ml-3">Tutoiement / Vouvoiement :</label>
                                    <div className="">
                                        <select id="form-question-communication" className="form-control select2" data-placeholder="Choisir..." style={{padding: "0px imporant!"}}>
                                            <option value='tutoiement'>Tutoiement</option>																							                                        
                                            <option value='vouvoiement'>Vouvoiement</option>																							                                        
                                        </select>
                                    </div>
                                </div>        
                                <hr />
                            </>   
                        }                     
                        <h5 className="modal-title mt-0">Prévisualisation (~ {this.getNbCreditsSms()} SMS)</h5>
                        <div className="previsualisation">
                            {this.state.question.libelle && <>{this.state.question.libelle}<br /></>}
                            {this.state.question.type == "liste" &&
                                <>
                                    {this.state.question.reponses.filter((reponse) => !reponse.supprime).sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, index) =>
                                        <React.Fragment key={"question-previsualisation-reponse-" + index}>
                                            "{reponse.libelle}" Tapez {reponse.cle.code ? reponse.cle.code : (index + 1)} {reponse.cle.icone ? ("ou " + reponse.cle.icone) : ""}
                                            {index != this.state.question.reponses.length - 1 && <br />}
                                        </React.Fragment>
                                    )}
                                </>
                            }
                            {this.state.question.type == "gradient" &&
                                <>
                                    Tapez une valeur entre {this.state.question.parametres.gradient.min} et {this.state.question.parametres.gradient.max}.
                                </>
                            }                            
                        </div>
                        <br clear="both" />
                    </div>
                </div>
            </div>
        );
	}
}

export default FormQuestionDefaut;