import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import DataService from '../services/data';
import SecurityService from '../services/security';
import RouteService from '../services/route';

import Api from '../api';

import Footer from '../components/Footer';

import TabSynthese from '../components/BeneficiaireProche/TabSynthese';
import TabInformations from '../components/BeneficiaireProche/TabInformations';
import TabAnnuaire from '../components/BeneficiaireProche/TabAnnuaire';
import TabQuestions from '../components/BeneficiaireProche/TabQuestions';
import TabInteractions from '../components/BeneficiaireProche/TabInteractions';
import TabPeriodes from '../components/BeneficiaireProche/TabPeriodes';
import TabPeriodesConges from '../components/BeneficiaireProche/TabPeriodesConges';
import SidebarBeneficiaire from '../components/BeneficiaireProche/SidebarBeneficiaire';

import FormInteraction from '../components/Forms/FormInteraction';
import { getTerme } from '../services/contextualisation';
import TabQuestionsFiltre from '../components/Filtre/TabQuestionsFiltre';

const mapStateToProps = (state, props) => {
	console.log(props);
	let dataService = new DataService();

	let structure = dataService.getStructureById(props.route.match.params.structureId)

	return {
	  data: state.data,
	  ihm: state.ihm,
	  structure,
	  filtre: props.route.match.params.id === 'defaut' ? {
		profils: structure.profilsDefaut,
		nom: 'Défaut'
	  } : dataService.getFiltreById(props.route.match.params.id),
	  beneficiaire: dataService.getBeneficiaireById(props.route.match.params.id),
	  conseiller: dataService.getConseillerById((state.data.me != null) ? state.data.me.id : 0),
	}
  }

class Filtre extends Component {

	constructor(props) {
		super(props);		

		console.log(props)

		this.state = {
			question: null			
		}
	}
	
	componentDidMount() {
		console.log("Filtre did mount");
		if (!this.props.filtre) {
			console.log("oups");
			this.props.history.replace('/');
			return;
		}
		let securityService = new SecurityService();
		if (!securityService.canViewFiltre(this.props.structure, this.props.filtre)) {
			console.log("not allowed");
			this.props.history.replace('/');
			return;
		}
		
		console.log(this.props.location);
		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});		

		//this.getCourbes()
	}

	componentDidUpdate() {
		
	}

	setEditingInteraction(interaction) {
        this.setState({
            editingInteraction: interaction
        });
    }

	changeTab(tab) {
		this.setState({
			tab: tab
		})
		this.props.dispatch({type: 'IHM_BENEFICIAIRE_TAB', payload: tab});
	}

	ouvreConseiller(conseiller) {
		let security = new SecurityService();
		if (security.canViewConseiller(conseiller)) {
			this.props.history.push('/accompagnants/' + conseiller.id);
		}		
	}

	ouvreFormProche(proche = null) {
		if (proche == null) {
			proche = {
				id: null,
			}
		}
		this.setState({
			proche: proche
		});
	}

	fermeForm() {
		this.setState({
			question: null,
			proche: null,
			periode: null
		});
	}

	formatteDate(date) {
        if (date == null) return null;

        return [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join('-');
	}

	toggleSuivi() {
		if (this.props.conseiller) {
			let beneficiaire = {
				id: this.props.beneficiaire.id,
				classe: this.props.beneficiaire.classe,
				conseillers: this.props.beneficiaire.conseillers
			};
			if (beneficiaire.conseillers.includes(this.props.conseiller.id)) {
				beneficiaire.conseillers.splice(beneficiaire.conseillers.indexOf(this.props.conseiller.id), 1);
			}
			else {
				beneficiaire.conseillers.push(this.props.conseiller.id);
			}
			this.enregistreBeneficiaire(beneficiaire);
		}
	}

	enregistreProfil(profil) {
		console.log(profil)
		this.props.dispatch({type: 'DATA_POST_PROFIL', payload: {profil: profil}})
	}

	ajouteQuestionsDefaut(questions, profil) {
		this.props.dispatch({type: 'DATA_AJOUTE_PROFILQUESTIONSDEFAUT', payload: {questions: questions, profil: profil}})
	}
	
	copieQuestions(questions, profils) {
		this.props.dispatch({type: 'DATA_COPIE_PROFILQUESTIONS', payload: {questions: questions, profils: profils}})
	}	

	render() {
		if (!this.props.filtre) return <></>;	

		let dataService = new DataService();
		let securityService = new SecurityService();
		
		console.log("tab", this.state.tab);
		
		let routeService = new RouteService();
		let historyLastLocation = routeService.getPreviousLocation();
		let lastLocation = 
			<NavLink to={historyLastLocation ? historyLastLocation : "/"}><i className="mdi mdi-arrow-left"></i>
				{routeService.getLocationLibelle(historyLastLocation)}
			</NavLink>
		;
		
		return(
			<div className="content-page" id="page-beneficiaire">
                <div className="content">
                    <div className="container-fluid">
						<div className="row">
							<div className="col-lg-12 lienretour">
								{lastLocation}
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4">
								<div className="card card-profil sidebar m-b-20">
									<div className="card-body text-center">
										<h4 className="mt-0 header-title mb-3 text-center">{dataService.getFiltreParent(this.props.filtre) !== null && <>{dataService.getFiltreParent(this.props.filtre).nom} - </>}{this.props.filtre.nom}</h4>
										<br clear="both" />
										<hr />										
									</div>
								</div>
							</div>
							<div className="col-lg-8">
								<div className="row">
									<div className="col-md-12">			
										<div className="card contenu m-b-20">
											<div className="card-body">
												<ul className="nav nav-tabs" role="tablist">
													<li className="nav-item">
														<a className="nav-link active" data-toggle="tab" href="#questions" role="tab" onClick={() => this.changeTab("questions")}>
															<span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
															<span className="d-none d-sm-block">Questions</span>
														</a>
													</li>													
												</ul>
												<div className="tab-content">
													<TabQuestionsFiltre
														actif={true}
														filtre={this.props.filtre}
														conseiller={this.props.conseiller}
														ajouteQuestionsDefaut={(questions, profil) => this.ajouteQuestionsDefaut(questions, profil)}
														copieQuestions={(questions, profils) => this.copieQuestions(questions, profils)}
														enregistreProfil={(profil) => this.enregistreProfil(profil)}														
														structure={this.props.structure}
													/>													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>				
			</div>	
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(Filtre));