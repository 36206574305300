export const questionValide = (question) => {

    if (!question) {
        return false
    }

    if (!question.libelle || question.libelle == "") {
        return false
    }

    if (['liste', 'gradient', 'information'].includes(question.type) == false) {        
        return false
    }

    if (question.type == "gradient") {
        if (!(
            question.parametres.gradient.min
            && question.parametres.gradient.max
            && question.parametres.gradient.min < question.parametres.gradient.max
        )) {
            return false
        }
    }

    if (question.type != 'information') {
        if (question.reponses.filter(r => r.supprime == false).length == 0) {
            return false
        }
        let reponses = question.reponses.filter(r => r.supprime == false)
        for (let reponse of reponses) {
            if (reponseValide(reponse, question) == false) {
                return false
            }
        }
    }

    if (question.classe == 'Question') {
        if (!(
            question.repetition.fin.type == "none"
            || question.repetition.fin.type == "date" && question.repetition.fin.date
            || question.repetition.fin.type == "occurrences" && question.repetition.fin.occurrences != "" && question.repetition.fin.occurrences > 0
        )) {
            return false
        }
    }

    return true
}

export const reponseValide = (reponse, question) => {
    if (question.type == "liste") {                
        if (
            !reponse.libelle || reponse.libelle == ""
        ) {
            return false
        }        
    }
    if (reponse.action == 'question') {
        if (questionValide(reponse.questionDeclenchee) == false) {
            return false
        }
    }

    return true
}