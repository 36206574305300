import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CalendarService from '../../services/calendar';
import DataService from '../../services/data';
import { getTerme } from '../../services/contextualisation';

class FormTache extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
    }

    initFromProps() {

        let calendarService = new CalendarService();

        let date = new Date();
        date.setMonth(date.getMonth() + 1);

        let tache = this.props.tache ? JSON.parse(JSON.stringify(this.props.tache)) : {
            id: null,
            classe: "Tache",
            description: null,
            date: calendarService.formatteDate(date),
            dateWidget: date,
            beneficiaire: this.props.alerte ? this.props.alerte.beneficiaire : null
        };

        tache.dateWidget = new Date(tache.date);

        return {
            tache: tache
        };
    }

    componentDidMount() {
        console.log("formtache did mount");

        this.bindSelect2();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.tache != this.props.tache || prevProps.alerte != this.props.alerte ) {
            this.setState(this.initFromProps());
        }

        this.bindSelect2();
    }

    bindSelect2() {
        let select2 = findDOMNode(this.refs.tacheBeneficiaire);
        
        window.jQuery(select2).off("change");
        window.jQuery(select2).select2({
            language: "fr"
        });
        window.jQuery(select2).on("change", (e) => {
            this.modifieTache("beneficiaire", e.target.value);
        });
    }

    modifieTache(champ, val) {
        let tache = this.state.tache;
        if (champ == "description") {
            tache.description = val;
        }
        if (champ == "date") {
            let calendarService = new CalendarService();
            tache.dateWidget = val;
            tache.date = calendarService.formatteDate(tache.dateWidget);
        }
        if (champ == "beneficiaire") {
            tache.beneficiaire = val;
        }
        this.setState({
            tache: tache
        });
        if (this.props.modifie) {
            this.props.modifie(tache);
        }
    }

    render() {

        let dataService = new DataService();

        let beneficiaires = [];
        this.props.beneficiaires.map((b) => {
            beneficiaires.push(
                <option key={"option-beneficiaire-" + b.id} value={b.id}>{b.prenom} {b.nom}</option>
            );
        })
        if (this.props.alerte && !this.props.beneficiaires.map((b) => b.id).includes(this.props.alerte.beneficiaire)) {
            let beneficiaire = dataService.getBeneficiaireById(this.props.alerte.beneficiaire);
            if (beneficiaire) {
                beneficiaires.push(
                    <option key={"option-beneficiaire-" + beneficiaire.id} value={beneficiaire.id}>{beneficiaire.prenom} {beneficiaire.nom}</option>
                );
            }
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <div className="input-group date">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Date</div>
                                    </div>
                                    <DatePicker
                                        className="form-control" 
                                        locale="fr"
                                        selected={this.state.tache.dateWidget}
                                        onChange={(date) => this.modifieTache("date", date)}
                                        dateFormat="dd/MM/yyyy"
                                    />    
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <div className="input-group">
                                    <label className="col-form-label">{getTerme("Utilisateur", dataService.getMyStructure().domaine)} :</label>
                                    {/*<select className="form-control" value={this.state.tache.beneficiaire || ""} disabled={this.props.alerte} onChange={(e) => this.modifieTache("beneficiaire", e.target.value)}>
                                        {beneficiaires}
                                    </select>*/}    
                                    <select ref="tacheBeneficiaire" className="form-control select2" id={"select2-tache-beneficiaire-" + this.state.tache.id} value={this.state.tache.beneficiaire} onChange={() => {}}>
                                        {beneficiaires}
                                    </select>                                    
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Description</div>
                                    </div>
                                    <textarea className="form-control" id="inlineFormInputGroupUsername"  value={this.state.tache.description || ""} onChange={(e) => this.modifieTache("description", e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                        <br clear="both" />  
                    </form>
                </div>
            </div>
        );
	}
}

export default FormTache;