import Logout from '../../pages/Logout';
import Compte from '../../pages/Compte';

export default {
    UtilisateurAccueil: {
        component: Compte,
        path: '/'
    },
    UtilisateurLogout: {
        component: Logout,
        path: '/logout'
    },
    UtilisateurCompte: {
        component: Compte,
        path: '/mon-compte'
    }
}