import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	BrowserRouter as Router,
	Route,
	NavLink
  } from 'react-router-dom';
  
import WrapperMobile from '../components/WrapperMobile';

const mapStateToProps = (state) => {
	return {
	  data: state.data,
	  ihm: state.ihm
	}
  }

class LayoutPublic extends Component {

    componentDidMount() {

        document.body.classList.add("bg-login");

        if (this.props.route.location.pathname != "/login") {
          this.props.dispatch({ type: "IHM_URLCIBLE", payload: '/' })
        }        
    }

    pageNonConseiller() {
      return (
        this.props.route.location.pathname.indexOf("/questionnaire/") != -1
        || this.props.route.location.pathname.indexOf("/alerte/") != -1
        || this.props.route.location.pathname.indexOf("/consentement/") != -1
        || this.props.route.location.pathname.indexOf("/email/") != -1
        || this.props.route.location.pathname.indexOf("/mentions-informations-superieur-hierarchique") != -1
        || this.props.route.location.pathname.indexOf("/politique-de-confidentialite-manager") != -1
        || this.props.route.location.pathname.indexOf("/conditions-generales-utilisation-manager") != -1
      )
    }

    pageEmployeur() {
      return (
        this.props.route.location.pathname.indexOf("/questionnaire/") != -1
        || this.props.route.location.pathname.indexOf("/alerte/") != -1
        || this.props.route.location.pathname.indexOf("/consentement/") != -1
        || this.props.route.location.pathname.indexOf("/email/") != -1
        || this.props.route.location.pathname.indexOf("/mentions-informations-superieur-hierarchique") != -1
        || this.props.route.location.pathname.indexOf("/politique-de-confidentialite-manager") != -1
        || this.props.route.location.pathname.indexOf("/conditions-generales-utilisation-manager") != -1
      )
    }

    render() {

        console.log("render public", this.props.mobile);        

        const Component = this.props.component;
        const route = this.props.route;
        return (
            <>
              {!this.props.mobile && 
                <div className="wrapper-page" id="page-login">
                    <Component route={route}/>
                    <div className="meta m-t-40 text-center">
                        <p>© 2019-{moment().format('YYYY')} WESIReport<br />
                          <a href="/mentions-legales" className="lienPublic" target="_blank">Mentions légales</a>
                          {/*{!this.pageNonConseiller() &&
                            <> - <a href="/mentions-informations-accompagnant" id="lien-mentions-informations" className="lienPublic" target="_blank">Mentions d'informations</a></>
                          }
                          {this.pageNonConseiller() &&
                            <> - <a href={this.props.route.location.pathname.indexOf("/mentions-informations-superieur-hierarchique") != -1 ? this.props.route.location.pathname : "/mentions-informations-superieur-hierarchique"} id="lien-mentions-informations-superieur" className="lienPublic" target="_blank">Mentions d'informations</a></>
                          }*/}
                          {this.pageEmployeur() &&
                            <>
                              - <a className="lienPublic" href="/conditions-generales-utilisation-manager" target="_blank">Conditions générales d'utilisation</a>
                              - <a id="lien-politique-confidentialite-manager" className="lienPublic" href="/politique-de-confidentialite-manager" target="_blank">Politique de confidentialité</a>
                            </>
                          }
                          {!this.pageEmployeur() &&
                            <>
                              - <a className="lienPublic" href="/conditions-generales-utilisation" target="_blank">Conditions générales d'utilisation</a>
                              - <a id="lien-politique-confidentialite" className="lienPublic" href="/politique-de-confidentialite" target="_blank">Politique de confidentialité</a>
                            </>
                          }
                          <> - <a className="lienPublic" href="/cookies" target="_blank">Cookies</a></>
                        </p>
                    </div>
                </div>
              }
              {this.props.mobile && 
                <WrapperMobile />
              }
            </>
        );
    }
}

export default connect(mapStateToProps, null)(LayoutPublic);