import React, { Component } from 'react';
import 'morris.js/morris.css';
import 'morris.js/morris.min.js';
import Api from '../../api'

import CalendarService from '../../services/calendar';
import { getTime } from 'date-fns';
import { getTerme } from '../../services/contextualisation';
import DataService from '../../services/data';

class TabSynthese extends Component {

    constructor(props) {
        super(props);

        this.state = {
			courbes: null
        }
    }

    componentDidMount() {
        console.log("TabSynthese did mount");

        console.log(window.Raphael);
        console.log(Raphael);

        let api = new Api();
		api.getBeneficiaireCourbes(this.props.beneficiaire)
		.then(res => {
			console.log(res)
			this.setState({
                courbes: res
            })
		})
		.catch(err => {
			console.log(err)
		})
                
        this.afficheBarres();
    }

    componentDidUpdate(prevProps) {
        console.log("TabSynthese did update");

        console.log(JSON.parse(JSON.stringify(prevProps.beneficiaire.interactions)))
        console.log(JSON.parse(JSON.stringify(this.props.beneficiaire.interactions)))
        if (JSON.stringify(prevProps.beneficiaire.interactions) !== JSON.stringify(this.props.beneficiaire.interactions)) {
            let api = new Api();
            api.getBeneficiaireCourbes(this.props.beneficiaire)
            .then(res => {
                console.log(res)
                this.setState({
                    courbes: res
                })
                this.afficheCourbes();
            })
            .catch(err => {
                console.log(err)
            })
        }
        
        if (this.state.courbes) {
            this.afficheCourbes();
        }        
        this.afficheBarres();
    }    

    afficheCourbes() {
        
        console.log(this.state.courbes)

        if (this.state.courbes == null) {
            return null
        }
        
        let calendarService = new CalendarService();

        let courbes = {
            beneficiaire: false,
            tous: false,
            emploi: false,
            famille: false
        }

        for (let [key, value] of Object.entries(this.state.courbes)) {
            if (value.tous !== undefined) {
                console.log(value.tous)
                courbes.tous = true
            }
            if (value.beneficiaire !== undefined) {
                courbes.beneficiaire = true
            }            
            if (value.emploi !== undefined) {
                courbes.emploi = true
            }
            if (value.famille !== undefined) {
                courbes.famille = true
            }
        }

        console.log(this.state.courbes)

        let lastTous = 0
        let lastBeneficiaire = 0
        let lastEmploi = 0
        let lastFamille = 0
        
        var data = [];
        for (let [key, value] of Object.entries(this.state.courbes)) {
            console.log(key, value)
            let d = {y: (key * 1000)}
            if (courbes.tous) {
                d.tous = value.tous
            }
            if (courbes.beneficiaire) {
                d.beneficiaire = value.beneficiaire
            }            
            if (courbes.emploi) {
                d.emploi = value.emploi
            }
            if (courbes.famille) {
                d.famille = value.famille
            }
            data.push(
                //{y: (key * 1000), tous: value.tous, beneficiaire: value.beneficiaire, famille: value.famille, emploi: value.emploi}
                //{y: (key * 1000), tous: value.tous, beneficiaire: value.beneficiaire, emploi: value.emploi}
                d                
            );
        }
        
        let ykeys = []
        let labels = []
        let couleurs = []
        if (courbes.tous) {
            ykeys.push('tous')
            labels.push('Total')
            couleurs.push('#3c6382')
        }
        if (courbes.beneficiaire) {
            ykeys.push('beneficiaire')
            labels.push(this.props.beneficiaire.prenom + " " + this.props.beneficiaire.nom)
            couleurs.push('#e55039')
        }  
        if (courbes.emploi) {
            ykeys.push('emploi')
            labels.push("Emploi")
            couleurs.push('#F9B233')
        }  
        if (courbes.famille) {
            ykeys.push('famille')
            labels.push("Famille")
            couleurs.push('#503E9D')
        } 

        console.log(ykeys, labels)

        window.jQuery("#graphique-score").empty();
		window.Morris.Line({
			element: 'graphique-score',
			data: data,
            xkey: 'y',
            //ykeys: ['tous', 'beneficiaire', 'famille', 'emploi'],
            //ykeys: ['tous', 'beneficiaire', 'emploi'],
            ykeys: ykeys,
            //labels: ['Total', (this.props.beneficiaire.prenom + " " + this.props.beneficiaire.nom), 'Famille', 'Emploi'],
            //labels: ['Total', (this.props.beneficiaire.prenom + " " + this.props.beneficiaire.nom), 'Emploi'],
            labels: labels,
            ymin: 0,
            // ymax: Math.max(this.props.beneficiaire.seuilCalcule, this.props.beneficiaire.score),
            ymax: Math.max(this.props.beneficiaire.seuilCalcule, Object.entries(this.state.courbes).map(([key, value]) => value).reduce((list, value) => {
                return [...list, value[Object.keys(value)[0]]]
            }, []).reduce((max, value) => value > max ? value : max, 0)),
			hideHover: 'auto',
			gridLineColor: '#eef0f2',
			resize: true, //defaulted to true
            //lineColors: ['#3c6382', '#e55039', '#503E9D', '#F9B233'],
            //lineColors: ['#3c6382', '#e55039', '#F9B233'],
            lineColors: couleurs,
            lineWidth: 2,
            dateFormat: (timestamp) => {
                return calendarService.formatteDate(new Date(timestamp), "vue")
            },
            xLabelFormat: (date) => {
                return calendarService.formatteDate(date, "vue")
            }
		});
    }

    afficheBarres() {

        let calendarService = new CalendarService();

        let heuresEntrepriseOui = 0;
        let heuresEntrepriseNon = 0;
        let heuresPresentielOui = 0;
        let heuresPresentielNon = 0;
        let total = 0;
        this.props.beneficiaire.interactions.map(interaction => {
            let debut = new Date(interaction.debut);
            let fin = new Date(interaction.fin);
            let duree = Math.abs(debut.getTime() - fin.getTime()) / 1000 / 60 / 60;

            if (interaction.enPresentiel) {
                heuresPresentielOui += duree;
            }
            else {
                heuresPresentielNon += duree;
            }
            if (interaction.enEntreprise) {
                heuresEntrepriseOui += duree;
            }
            else {
                heuresEntrepriseNon += duree;
            }
            total += duree;
        });

        var data = [];
        data = [
            { y: 'Sur le lieu de travail', oui: heuresEntrepriseOui, non: heuresEntrepriseNon },
            { y: 'En présence de ' + this.props.beneficiaire.prenom, oui: heuresPresentielOui,  non: heuresPresentielNon },
        ]
        /*window.jQuery("#graphique-suivi").empty();
        window.Morris.Bar({
            element: 'graphique-suivi',
            data: data,
            xkey: 'y',
            ykeys: ['oui', 'non'],
            stacked: true,
            horizontal: true,
            ymax: Math.ceil(total),
            labels: ['Oui', 'Non'],
            hideHover: 'auto',
            resize: true, //defaulted to true
            gridLineColor: '#eeeeee',
            barColors: ['#7a6fbe', '#f0f1f4'],
            yLabelFormat: (duree) => {
                let heures = Math.floor(duree);
                let minutes = (duree - heures) * 60;
                return heures + "h" + (minutes > 0 ? minutes : "");
            }
        });*/
    }

    getCategorie(c) {
        let categorie = null;
        this.props.categoriesInteractions.map(categorieNiveau1 => {
            categorieNiveau1.categories.map(cat => {
                if (cat.key == c) categorie = cat;
            });
        })
        return categorie;
    }
    
	render() {

        let interactions = [];
        this.props.beneficiaire.interactions.sort((a, b) => a.debut > b.debut ? -1 : 1).slice(0, 10).map((i) => {
			let calendarService = new CalendarService();

			let date = calendarService.ilya(calendarService.intervalle(new Date(i.debut), new Date()));
			interactions.push(
				<a key={"row-interaction-" + i.id} href="#" className="text-dark">
					<div className="inbox-item">
						{/*<h6 className="inbox-item-author mt-0 mb-1 titreinteraction">{this.getCategorie(i.categorie) ? this.getCategorie(i.categorie).value : ""}</h6>
						{i.description && <p className="inbox-item-text text-muted mb-0">{i.description}</p>}
                        <p className="inbox-item-date text-muted">{date}</p>*/}
                        <h6 className="inbox-item-author mt-0 mb-1 titreinteraction">{i.description ? i.description : "Autre"}</h6>
                        <p className="inbox-item-date text-muted">{date}</p>
					</div>
				</a>
			);
		});
		if (interactions.length == 0) {
			interactions.push(
				<p key="row-interaction-none" className="inbox-item-text text-muted mb-0">Aucune interaction.</p>
			)
		}

        let courbes = {
            beneficiaire: false,
            tous: false,
            emploi: false,
            famille: false
        }
        if (this.state.courbes) {
            for (let [key, value] of Object.entries(this.state.courbes)) {
                console.log(value)
                if (value.beneficiaire !== undefined) {
                    courbes.beneficiaire = true
                }
                if (value.tous !== undefined) {
                    console.log(value.tous)
                    courbes.tous = true
                }
                if (value.emploi !== undefined) {
                    courbes.emploi = true
                }
                if (value.famille !== undefined) {
                    courbes.famille = true
                }
            }
        }    
        
        let dataService = new DataService()
        let structure = dataService.getStructureById(this.props.beneficiaire.structure)

        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="synthese" role="tabpanel">
                <div className="row">
                    <div className="col-md-7">
                        <h3>Suivi de l'état</h3> 
                        <div id="graphique-score" className="morris-charts morris-chart-height"></div>
                        {/*<ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
                            {courbes.tous &&
                                <li className="list-inline-item">
                                    <h5 className="mb-0 courbe-tous">-</h5>
                                    <p className="text-muted"><b>Tous</b></p>
                                </li>
                            }
                            {courbes.beneficiaire &&
                                <li className="list-inline-item">
                                    <h5 className="mb-0 courbe-beneficiaire">-</h5>
                                    <p className="text-muted">{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</p>
                                </li>
                            }                            
                            {courbes.emploi &&
                                <li className="list-inline-item">
                                    <h5 className="mb-0 courbe-emploi">-</h5>
                                    <p className="text-muted">Emploi</p>
                                </li>
                            }
                            {courbes.famille &&
                                <li className="list-inline-item">
                                <h5 className="mb-0 courbe-famille">-</h5>
                                <p className="text-muted">Famille</p>
                                </li>
                            }                                                        
                        </ul>*/}
                        {/*<br clear="both" />										
                        <h3>Progression du suivi</h3> 
                        <div id="graphique-suivi"></div>*/}
                    </div>
                    <div className="col-md-5">
                        {this.props.beneficiaire.score >= this.props.beneficiaire.seuilCalcule && this.props.beneficiaire.alertes.length > 0 && this.props.beneficiaire.alertes[0].contexte && this.props.beneficiaire.alertes[0].contexte.type === 'discordance-beneficiaire-superieur' &&
                            <div className="alert alert-danger mb-3" role="alert">
                                La courbe monte ici parce que les réponses de {this.props.beneficiaire.prenom} {this.props.beneficiaire.nom} et celles de son {getTerme('supérieur hiérarchique', structure.domaine)} ne concordent pas.<br />
                                {this.props.beneficiaire.prenom} {this.props.beneficiaire.nom} cumule des signaux faibles alors que son {getTerme('supérieur hiérarchique', structure.domaine)} ne les exprime pas, cela est un contexte particulier qui provoque une importante montée.<br />                            
                            </div>
                        }                        
                        <h3>Dernières interactions</h3> 
                        <div className="inbox-wid">
                            {interactions}
                        </div>  
                    </div>							
                </div>
            </div>
        )
	}
}
  

export default TabSynthese;