import React, { Component } from 'react';

import Api from '../../api';

class FormStructure extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.erreurs = []; 
    }

    initFromProps() {
        console.log("init", this.props.structure);
        let structure = this.props.structure ? JSON.parse(JSON.stringify(this.props.structure)) : {
            id: null,
            classe: "Structure",
            nom: "",     
            type: {
                accompagnement: "deac",
                entreprise: "ea",
                rps: "autre"
            }[this.props.domaine],       
            precisionType: "",
            actif: true,
            adresse: {
                id: null,
                classe: "Adresse",
                adresse1: "",
                codePosta: "",
                ville: ""
            },
            newResponsable: {
                id: null,
                classe: "Conseiller",
                nom: "",
                prenom: "",
                email: "",
                telephone: "",
                actif: true,
                seulementAdmin: false,
                checkUniqueEmail: null
            }
        };

        return {
            structure: structure
        };
    }

    componentDidMount() {
        console.log("formstructure did mount");
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.structure != this.props.structure) {
            this.setState(this.initFromProps());
            if (!this.props.structure) {
                this.setState({erreurs: []});
            }
        }
        if (prevProps.domaine != this.props.domaine) {
            this.setState(this.initFromProps())
        }
    }

    modifieStructure(champ, val) {
        let structure = this.state.structure;
        if (champ == "nom") {
            structure.nom = val;
        }
        if (champ == "type") {
            structure.type = val;
            if (structure.type == "autre") {
                structure.precisionType = ""
            }
        }
        if (champ == "precisionType") {
            structure.precisionType = val
        }
        if (champ == "adresse-adresse1") {
            structure.adresse.adresse1 = val;
        }
        if (champ == "adresse-codePostal") {
            structure.adresse.codePostal = val;
        }
        if (champ == "adresse-ville") {
            structure.adresse.ville = val;
        }
        if (champ == "newResponsable-nom") {
            structure.newResponsable.nom = val;
        }
        if (champ == "newResponsable-prenom") {
            structure.newResponsable.prenom = val;
        }
        if (champ == "newResponsable-email") {
            structure.newResponsable.email = val;
            structure.newResponsable.checkUniqueEmail = null;
            if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(val)) {
                this.checkUniqueEmail();
            }  
        }
        if (champ == "newResponsable-telephone") {
            structure.newResponsable.telephone = val;
        }
        if (champ == "newResponsable-seulementAdmin") {
            structure.newResponsable.seulementAdmin = !structure.newResponsable.seulementAdmin;
        }
        
        this.setState({
            structure: structure
        });
        if (this.props.modifie) {
            this.props.modifie(structure);
        }
    }

    valideChamp(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
        if (champ == "nom") {
            if (this.state.structure.nom == "") {
                erreurs.push(champ);
            }
        }
        if (champ == "type") {
            if (this.state.structure.type == "") {
                erreurs.push(champ);
            }
        }
        if (champ == "newResponsable-nom") {
            if (this.state.structure.newResponsable.nom == "") {
                erreurs.push(champ);
            }
        }
        if (champ == "newResponsable-prenom") {
            if (this.state.structure.newResponsable.prenom == "") {
                erreurs.push(champ);
            }
        }
        if (champ == "newResponsable-email") {
            if (this.state.structure.newResponsable.email == "" || !/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.structure.newResponsable.email)) {
                erreurs.push(champ);
            }
            else {
                this.checkUniqueEmail();
            } 
        }
        if (champ == "newResponsable-telephone") {
            if (this.state.structure.newResponsable.telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.structure.newResponsable.telephone)) {
                erreurs.push(champ);
            }
        }
        this.setState({
            erreurs: erreurs
        });
    }

    checkUniqueEmail() {
        let api = new Api();

        let structure = this.state.structure;

        api.checkUniqueEmail(structure.newResponsable.email, structure.newResponsable.classe, structure.newResponsable.id).then(json => {
            let erreurs = this.state.erreurs;
            if (json.unique && erreurs.includes("newResponsable-email")) {
                erreurs.splice(erreurs.indexOf("newResponsable-email"), 1);
            }
            if (!json.unique && !erreurs.includes("newResponsable-email")) {
                erreurs.push("newResponsable-email");
            }            
            structure.newResponsable.checkUniqueEmail = json.unique;
            this.setState({
                structure: structure,
                erreurs: erreurs
            });
            this.props.modifie(structure);
        }).catch(err => {
            console.log(err);
        })
    }

    render() {

        
        console.log(this.state.erreurs);

        let types = []
        if (this.props.domaine == 'accompagnement') {
            types.push(
                <option value="deac">DEAC</option>,
                <option value="capemploi">Cap Emploi</option>,
                <option value="esat">ESAT</option>,
                <option value="formation">Organisme de formation</option>,                
            )
        }
        if (this.props.domaine == 'entreprise') {
            types.push(
                <option value="ea">EA</option>,
                <option value="horsea">Hors EA</option>,
                <option value="public">Secteur public</option>,                
            )
        }
        if (this.props.domaine == 'rps') {
            
        }
        types.push(<option value="autre">Autre</option>)

        return (
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-8">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('nom') ? " input-error" : "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Nom de la structure *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.nom} onChange={(e) => this.modifieStructure("nom", e.target.value)} onBlur={() => this.valideChamp("nom")} />
                                </div>
                            </div>                            
                        </div>
                        <h4>Type :</h4>
                        <div className="form-row">                            
                            <div className="form-group col-md-4">                                
                                <select className="form-control" value={this.state.structure.type} onChange={(e) => this.modifieStructure("type", e.target.value)}>                                    
                                    {types}
                                </select>                                
                            </div>
                            {this.state.structure.type == "autre" && 
                                <div className="form-group col-md-4">
                                    <div className={"input-group"}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Précision</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.precisionType} onChange={(e) => this.modifieStructure("precisionType", e.target.value)} onBlur={() => this.valideChamp("precisionType")} />
                                    </div>
                                </div>
                            }
                        </div>
                        <h4>Adresse :</h4>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">N° et nom de rue</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.adresse.adresse1} onChange={(e) => this.modifieStructure("adresse-adresse1", e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Code Postal</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.adresse.codePostal} onChange={(e) => this.modifieStructure("adresse-codePostal", e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-8">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Ville</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.adresse.ville} onChange={(e) => this.modifieStructure("adresse-ville", e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <h4>Responsable :</h4>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('newResponsable-nom') ? " input-error" : "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Nom *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.newResponsable.nom} onChange={(e) => this.modifieStructure("newResponsable-nom", e.target.value)} onBlur={() => this.valideChamp("newResponsable-nom")} />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('newResponsable-prenom') ? " input-error" : "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Prénom *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.newResponsable.prenom} onChange={(e) => this.modifieStructure("newResponsable-prenom", e.target.value)} onBlur={() => this.valideChamp("newResponsable-prenom")} />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('newResponsable-email') ? " input-error" : "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Adresse email *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.newResponsable.email} onChange={(e) => this.modifieStructure("newResponsable-email", e.target.value)} onBlur={() => this.valideChamp("newResponsable-email")} />
                                </div>
                                {this.state.structure.newResponsable.checkUniqueEmail === false && <ul className="parsley-errors-list filled" id="parsley-id-15"><li className="parsley-required">Cette adresse email est déjà utilisée.</li></ul>}
                            </div>
                            <div className="form-group col-md-6">
                                <div className={"input-group" + (this.state.erreurs.includes('newResponsable-telephone') ? " input-error" : "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Téléphone</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.newResponsable.telephone} onChange={(e) => this.modifieStructure("newResponsable-telephone", e.target.value)} onBlur={() => this.valideChamp("newResponsable-telephone")} />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <input type="checkbox" id={"toggle-responsable-seulementAdmin-new"} switch="warning" checked={!this.state.structure.newResponsable.seulementAdmin} onChange={(e) => this.modifieStructure("newResponsable-seulementAdmin", !e.target.value)} />
                                <label htmlFor={"toggle-responsable-seulementAdmin-new"}></label>
                                Est aussi accompagnant
                            </div>
                        </div>
                        <br clear="both" />
                    </form>
                </div>
            </div>
        );
	}
}

export default FormStructure;