import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import Api from '../api';

import storageKey from '../services/session';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  history: props.route.history
	}
  }

class Logout extends Component {

	componentDidMount() {
		console.log("logout did mount");

		//cookie.remove(storageKey);
		cookie.save(storageKey, 0, {path: '/'});
		localStorage.removeItem(storageKey);
		this.props.dispatch({ type: "IHM_URLCIBLE", payload: "/" })
		this.props.dispatch({ type: "DATA_CLEAR", payload: null });
	}

	componentDidUpdate() {
		console.log("logout did update");
	}

	render() {
		return(
			<></>
		);
	}
}

export default connect(mapStateToProps, null)(Logout);