import React, { Component } from 'react';

import CalendarService from '../../services/calendar';

import FormInteraction from '../Forms/FormInteraction';

class TabInteractions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            beneficiaire: JSON.parse(JSON.stringify(this.props.beneficiaire)),
            editingInteraction: null,
            interactionsSelectionnees: [],
            categories: []
        }
    }

    componentDidMount() {
        console.log("TabInteractions did mount");
        
        this.bindModals();
        this.bindSelect2();
    }

    componentDidUpdate(prevProps) {
        console.log("TabInteractions did update");

        if (this.props != prevProps) {
            this.setState({
                beneficiaire: JSON.parse(JSON.stringify(this.props.beneficiaire))
            });
        }

        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-nouvelleinteraction").off('hidden.bs.modal');
        window.jQuery("#modal-nouvelleinteraction").on('hidden.bs.modal', () => {
            console.log("hidden");
            this.setState({
                editingInteraction: null
            });
        });
        this.props.beneficiaire.interactions.map((interaction) => {
            window.jQuery("#modal-modifinteraction-" + interaction.id).off('hidden.bs.modal');
            window.jQuery("#modal-modifinteraction-" + interaction.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingInteraction: null
                });
            });
        });
    }

    bindSelect2() {
        window.jQuery("#filtre-interactions-categories").off('change');
        window.jQuery("#filtre-interactions-categories").on('change', (e) => {
            console.log(window.jQuery("#filtre-interactions-categories").val());
            this.setState({
                categories: window.jQuery("#filtre-interactions-categories").val()
            });
            this.updateInteractionsSelectionnees()
        });
    }

    updateInteractionsSelectionnees() {
        let interactionsSelectionnees = this.state.interactionsSelectionnees;
        interactionsSelectionnees.map((i, index) => {
            let trouve = false;
            this.props.beneficiaire.interactions.filter(interaction => this.state.categories.length == 0 || this.state.categories.includes(interaction.categorie)).sort((a, b) => a.debut > b.debut ? -1 : 1).map(interaction => {
                if (interaction.id == i) trouve = true;
            });
            if (!trouve) {
                interactionsSelectionnees.splice(index, 1);
            }
        });
        this.setState({
            interactionsSelectionnees: interactionsSelectionnees
        })
    }

    toggleInteraction(interaction) {
        let interactionsSelectionnees = this.state.interactionsSelectionnees;
        if (interactionsSelectionnees.includes(interaction.id)) {
            interactionsSelectionnees.splice(interactionsSelectionnees.indexOf(interaction.id), 1);
        }
        else {
            interactionsSelectionnees.push(interaction.id);
        }
        this.setState({
            interactionsSelectionnees: interactionsSelectionnees
        });
    }

    toggleAllInteractions() {
        let interactionsSelectionnees = [];
        if (this.state.interactionsSelectionnees.length == this.props.beneficiaire.interactions.length) {
            interactionsSelectionnees = [];
        }
        else {
            this.props.beneficiaire.interactions.map((interaction) => {
                interactionsSelectionnees.push(interaction.id)
            });
        }
        this.setState({
            interactionsSelectionnees: interactionsSelectionnees
        })
    }

    setEditingInteraction(interaction) {
        console.log("seteditinginteraction", interaction);
        this.setState({
            editingInteraction: interaction
        });
        console.log(interaction);
    }

    enregistreBeneficiaire(champ, val) {
        console.log(champ);
        let beneficiaire = null;
        if (champ == "interactions") {
            if (this.props.beneficiaire.interactions != val) {
				beneficiaire = {
                    id: this.props.beneficiaire.id,
                    classe: this.props.beneficiaire.classe,
                    interactions: val
                }
            }
        }
        if (beneficiaire) {
            this.props.enregistre(beneficiaire);
		}
    }

    ajouteInteraction() {
        let interactions = this.state.beneficiaire.interactions;
        interactions.push(
            this.state.editingInteraction
        );
        this.enregistreBeneficiaire("interactions", interactions);
    }

    modifieInteraction(index) {
        let interactions = this.state.beneficiaire.interactions;
        interactions[index] = this.state.editingInteraction;
        this.enregistreBeneficiaire("interactions", interactions);
    }

    supprimeInteraction(index) {
        let interactions = this.state.beneficiaire.interactions;
        interactions[index].supprime = true;
        this.enregistreBeneficiaire("interactions", interactions);
    }

    supprimeInteractions() {
        let interactions = this.state.beneficiaire.interactions;
        this.state.interactionsSelectionnees.map((i) => {
            interactions.map((interaction, index) => {
                if (interaction.id == i) {
                    interactions[index].supprime = true;
                }
            });
        });
        this.enregistreBeneficiaire("interactions", interactions);
        this.setState({
            interactionsSelectionnees: []
        })
    }

    formInteractionValide() {
        return (
            this.state.editingInteraction
            && this.state.editingInteraction.description
            && this.state.editingInteraction.categorie
            && this.state.editingInteraction.debut <= this.state.editingInteraction.fin
        )
    }

    getCategorie(c) {
        let categorie = null;
        this.props.categories.map(categorieNiveau1 => {
            categorieNiveau1.categories.map(cat => {
                if (cat.key == c) categorie = cat;
            });
        })
        return categorie;
    }
    
	render() {

        let calendarService = new CalendarService();

        let modals = [];

        let interactions = [];
        this.props.beneficiaire.interactions.filter(interaction => this.state.categories.length == 0 || this.state.categories.includes(interaction.categorie)).sort((a, b) => a.debut > b.debut ? -1 : 1).map((interaction, index) => {
            interactions.push(
				<tr key={"row-interaction-" + interaction.id} className={this.state.interactionsSelectionnees.includes(interaction.id) ? "table-warning" : ""}>
					<td>
						<div className="checkbox-wrapper">
							<input type="checkbox" id={"toggle-interaction-" + interaction.id} onChange={() => this.toggleInteraction(interaction)} checked={this.state.interactionsSelectionnees.includes(interaction.id)} />
							<label htmlFor={"toggle-interaction-" + interaction.id} className="toggle"></label>
						</div>
					</td>
					<td>
						<p className="m-0 table-date text-muted font-14">{calendarService.formatteDate(new Date(interaction.debut), "vue")}</p>
					</td>
					<td>
						{/*<p className="m-0 text-muted font-14"><b>{this.getCategorie(interaction.categorie) ? this.getCategorie(interaction.categorie).value : ""}</b></p>
                        <p>En présence de {this.props.beneficiaire.prenom} {this.props.beneficiaire.nom} : <b>{interaction.enPresentiel ? "Oui" : "Non"}</b>
                        <br />Sur le lieu de travail : <b>{interaction.enEntreprise ? "Oui" : "Non"}</b>
                        {interaction.description && <><br />{interaction.description}</>}</p>*/}
                        <p className="m-0 text-muted font-14"><b>{interaction.description}</b></p>
					</td>
                    <td>
						<p className="m-0 text-muted font-14">{interaction.score !== null ? ((interaction.score < 0 ? interaction.score : "+" + interaction.score) + (Math.abs(interaction.score) <= 1 ? " signal" : " signaux")) : "Remise à zéro"}</p>
					</td>
					<td>
                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifinteraction-" + interaction.id} onClick={() => this.setEditingInteraction(interaction)}><i className="dripicons-document-edit"></i></a></span>
						<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressioninteraction-" + interaction.id}><i className="dripicons-trash"></i></a></span>
					</td>
				</tr>
            );
            
            modals.push(
                <div key={"modal-modifinteraction-" + interaction.id} id={"modal-modifinteraction-" + interaction.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Interaction</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formInteractionValide()} onClick={() => this.modifieInteraction(index)}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormInteraction
                                    interaction={this.state.editingInteraction}
                                    beneficiaire={this.props.beneficiaire}
                                    modifie={(interaction) => this.setEditingInteraction(interaction)}
                                    categories={this.props.categories}
                                    origine="tabinteractions"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );

            modals.push(
                <div key={"modal-suppressioninteraction-" + interaction.id} id={"modal-suppressioninteraction-" + interaction.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer une interaction</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>L'interaction et ses effets seront supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeInteraction(index)} data-dismiss="modal">Supprimer l'interaction</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
            );

        });
        
        let aucuneInteraction = null;
		if (interactions.length == 0) {
			aucuneInteraction = 
				<tr key="row-interaction-0">
					<td></td>
					<td></td>
					<td>
						<p className="m-0 text-muted font-14">Aucune interaction.</p>
					</td>
					<td></td>
                    <td></td>
				</tr>
			;
        }
        
        let categories = [];
        this.props.categories.map((categorieNiveau1) => {
            let sousCategories = [];
            categorieNiveau1.categories.map((categorie) => {
                sousCategories.push(
                    <option key={"option-categorie-" + categorie.key} value={categorie.key}>{categorie.value}</option>
                );
            })
            categories.push(
                <optgroup key={"optiongroup-categorie-" + categorieNiveau1.key} label={categorieNiveau1.value}>
                    {sousCategories}
                </optgroup>
            );
        });
        
        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="interactions" role="tabpanel">
                {/*<a href="#" className="btn btn-annexe contour m-b-20"><i className="mdi mdi-sort-descending"></i> Trier par</a>*/}
                {/*<a href="#" className="btn btn-annexe contour m-b-20"><i className="mdi mdi-filter-outline"></i> Filtrer</a>*/}
                <a href="#" data-toggle="modal" data-target="#modal-nouvelleinteraction" className="mb-3 btn btn-primary float-right"><i className="mdi mdi-plus"></i> Nouvelle interaction</a>
                <br clear="both" />
                <div className="mb-3">
                    <select id="filtre-interactions-categories" className="form-control select2 select2-multiple" multiple data-placeholder="Filtrer par catégorie...">
                        {categories}
                    </select>
                </div>
                <div className="table-responsive">
                    <table className="table table-vertical">
                        <thead>
                            <tr>
                                <th>
                                    {this.props.beneficiaire.interactions.length > 0 &&
                                        <div className={"checkbox-wrapper" + (this.state.interactionsSelectionnees.length != this.props.beneficiaire.interactions.length ? " selection" : "")}>
                                            <input type="checkbox" id="toggle-interactions-all" checked={this.state.interactionsSelectionnees.length > 0} onChange={() => this.toggleAllInteractions()} />
                                            <label htmlFor="toggle-interactions-all" className="toggle"></label>
                                        </div>
                                    }
                                </th>
                                {this.state.interactionsSelectionnees.length == 0 &&
                                    <>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Compteur</th>
                                        <th>Actions</th>
                                    </>
                                }
                                {this.state.interactionsSelectionnees.length > 0 &&
                                    <th colSpan="4">
                                        <a href="#" data-toggle="modal" data-target="#modal-suppressioninteractions" className="btn btn-annexe contour m-b-20"><i className="dripicons-trash"></i> Supprimer</a>
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {interactions}
                            {aucuneInteraction}
                            <tr>
                                <td colSpan="5" className="tdaction">
                                    <a href="#" data-toggle="modal" data-target="#modal-nouvelleinteraction" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle interaction</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div key="modal-nouvelleinteraction" id="modal-nouvelleinteraction" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Nouvelle interaction</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formInteractionValide()} onClick={() => this.ajouteInteraction()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormInteraction
                                    interaction={this.state.editingInteraction}
                                    beneficiaire={this.props.beneficiaire}
                                    modifie={(interaction) => this.setEditingInteraction(interaction)}
                                    categories={this.props.categories}
                                    origine="tabinteractions"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-suppressioninteractions" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des interactions</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Les interactions sélectionnées et leurs effets seront supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeInteractions()} data-dismiss="modal">Supprimer les interactions</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                {modals}
            </div>
        )
	}
}
  

export default TabInteractions;