import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Api from '../api';

import logo from '../assets/images/logo.png';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  history: props.route.history
	}
  }

class PasswordOublie extends Component {

	constructor(props) {
		super(props);

		this.state = {
			etat: null,
			email: "",
			test: false
		};
	}

	componentDidMount() {
		console.log("passwordoublie did mount");

	}

	changeIdentifiants(champ, val) {
		if (champ == "email") {
			this.setState({
				email: val
			});
		}
	}

	formTokenValide() {
		return (
			/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.email)
		);
	}

	demandeToken(e) {
		e.preventDefault();
		let api = new Api();
		this.setState({
			etat: "token-encours",
		});
		api.sendResetPasswordToken(this.state.email).then((json) => {
			if (json.succes) {
				this.setState({
					etat: "token-ok",
					email: ""
				});
			}
			else {
				this.setState({
					etat: "token-nok"
				});
			}
		}).catch(err => {
			console.log(err);
			this.setState({
				etat: "token-erreur"
			});
		});
	}

	render() {
		
		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
                        <h4 className="text-muted font-18 mb-3 text-center">Réinitialiser le mot de passe</h4>
						{this.state.etat == "token-nok" &&
							<div className="alert alert-warning bg-warning text-white" role="alert">
								Nous ne reconnaissons pas votre adresse email, veuillez recommencer ou contacter votre administrateur.
							</div>
						}
						{this.state.etat == "token-erreur" && 
							<div className="alert alert-warning bg-warning text-white" role="alert">
								Une erreur s'est produite, veuillez recommencer ou contacter votre administrateur.
							</div>
						}
						<div className="alert alert-info bg-info text-white" role="alert">
							Entrez votre mail et les instructions vous seront transmises pour réinitialiser votre mot de passe.
						</div>
						<form className="form-horizontal m-t-30" action="index.html">
							<div className="form-group">
								<label htmlFor="useremail">Email</label>
								<input type="email" className="form-control" id="useremail" disabled={["token-encours", "token-ok"].includes(this.state.etat)} value={this.state.email} onChange={(e) => this.changeIdentifiants("email", e.target.value)} />
							</div>
							<div className="form-group row m-t-20">
								<div className="col-12 text-right">
									<button type="submit" className="btn btn-primary w-md waves-effect waves-light" disabled={!this.formTokenValide() || ["token-encours", "token-ok"].includes(this.state.etat)} onClick={(e) => this.demandeToken(e)}>{(this.state.etat == "token-encours") ? "En cours..." : (this.state.etat == "token-ok" ? "C'est fait !" : "Générer un nouveau mot de passe")}</button>
								</div>
							</div>
						</form>
						{this.state.etat == "token-ok" &&
							<div className="alert alert-success bg-success text-white" role="alert">
								Nous venons de vous envoyer un email contenant un lien personnalisé pour réinitialiser votre mot de passe.
							</div>
						}
						{this.state.test && 
							<>
								<div className="alert alert-warning bg-warning text-white" role="alert">
									Nous ne reconnaissons pas votre adresse email, veuillez recommencer ou contacter votre administrateur.
								</div>
								<div className="alert alert-info bg-info text-white" role="alert">
									Entrez votre mail et les instructions vous seront transmises pour réinitialiser votre mot de passe.
								</div>
								<div className="alert alert-success bg-success text-white" role="alert">
									Nous venons de vous envoyer un email contenant un lien personnalisé pour réinitialiser votre mot de passe.
								</div>
							</>
						}
                    </div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(PasswordOublie);