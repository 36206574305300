import React, { Component } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import CalendarService from '../../services/calendar';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { questionValide } from '../../services/validation';
import moment from 'moment';
moment.locale('fr');


const SortableReponse = SortableElement(({value, i, items, question, questionsDeclenchees, scores, modifieReponse, supprimeReponse, editeQuestionDeclenchee}) => {
    let r = value;
    let index = i;

    return (
        <div className="ordrereponseschild">
            <div className="liste-question">
                {question.type == "liste" &&
                    <>
                        <div className="form-row question-reponse">
                            <div className="form-group col-md-5">
                                <div className="input-group"> 
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Réponse" value={r.libelle || ""} onChange={(e) => modifieReponse(index, "libelle", e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <div className="input-group">	
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername simple" placeholder="Code" value={r.cle.code || ""} onChange={(e) => modifieReponse(index, "cle-code", e.target.value)} />
                                </div>
                            </div>
                            {/*<div className="form-group col-md-3">
                                <div className="input-group">	
                                    <select className="form-control" value={r.cle.icone || ""} onChange={(e) => modifieReponse(index, "cle-icone", e.target.value)}>
                                        <option value="">Pas d'icône</option>
                                        <option value={"\u{1F600}"}>{"\u{1F600}"}</option>
                                        <option value={"\u{1F61E}"}>{"\u{1F61E}"}</option>
                                    </select>
                                </div>
                            </div>*/}
                            <div className="form-group col-md-1">
                                <a href="#" onClick={(e) => supprimeReponse(e, index)} className="m-0 btn btn-annexe contour"><i className="dripicons-trash"></i></a>
                            </div>    
                            <div className="form-group col-md-1">
                                {items.length > 1 && <div className="deplacer"><i className="dripicons-move"></i></div>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="label-compteur">
                                    <div className="form-group">
                                        <select className="form-control" value={(r.score == 0 ? "stagner" : r.score > 0 ? "avancer" : "reculer")} onChange={(e) => modifieReponse(index, "scorePlusMoins", e.target.value)}>
                                            <option key="reponse-compteur-stagner" value="stagner">Ne fait pas avancer le compteur</option>
                                            <option key="reponse-compteur-avancer" value="avancer">Fait avancer le compteur de</option>
                                            <option key="reponse-compteur-reculer" value="reculer">Fait reculer le compteur de</option>
                                        </select>
                                        {r.score != 0 &&
                                            <>
                                                <select className="form-control" value={Math.abs(r.score)} onChange={(e) => modifieReponse(index, "score", e.target.value)}>
                                                    {scores}
                                                </select>
                                                <label className="col-form-label ml-20">{Math.abs(r.score) <= 1 ? "signal faible" : "signaux faibles"} si cette réponse est choisie</label>
                                            </>
                                        }
                                        <br clear="both" />
                                    </div>
                                </div>
                                <div className="label-declencher">
                                    <div className="form-group">
                                        <label className="col-form-label ml-20">Déclencher une autre question suite à cette réponse :</label>
                                        <select className="form-control" value={r.action ? r.action : ''} onChange={(e) => modifieReponse(index, "action", e.target.value)}>
                                            <option value="">Aucune question déclenchée</option>
                                            <option value="question-etre-rappele">Souhaitez-vous être rappelé(e) ?</option>
                                            <option value="question">Question personnalisée</option>
                                        </select>
                                        {r.action === 'question' &&
                                            <>
                                                {r.questionDeclenchee !== null &&
                                                    <>
                                                        <label className="col-form-label ml-20">Question déclenchée : {r.questionDeclenchee.libelleCalcule} <a className="text-warning" href="#" onClick={(e) => {e.preventDefault(); editeQuestionDeclenchee(r, index)}}>Editer la question</a></label>
                                                    </>
                                                }    
                                            </>                                        
                                        } 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }  
                {question.type == "gradient" &&
                    <>
                        <div className="form-row question-reponse">
                            <div className="form-group col-md-1">
                                <span>{r.cle.code}</span>
                            </div>
                            <div className="col-md-11">
                                <div className="label-compteur">
                                    <div className="form-group">
                                        <select className="form-control" value={(r.score == 0 ? "stagner" : r.score > 0 ? "avancer" : "reculer")} onChange={(e) => modifieReponse(index, "scorePlusMoins", e.target.value)}>
                                            <option key="reponse-compteur-stagner" value="stagner">Ne fait pas avancer le compteur</option>
                                            <option key="reponse-compteur-avancer" value="avancer">Fait avancer le compteur de</option>
                                            <option key="reponse-compteur-reculer" value="reculer">Fait reculer le compteur de</option>
                                        </select>
                                        {r.score != 0 &&
                                            <>
                                                <select className="form-control" value={Math.abs(r.score)} onChange={(e) => modifieReponse(index, "score", e.target.value)}>
                                                    {scores}
                                                </select>
                                                <label className="col-form-label ml-20">{Math.abs(r.score) <= 1 ? "signal faible" : "signaux faibles"} si cette réponse est choisie</label>
                                            </>
                                        }
                                        {r.score == 0 &&
                                            <label className="col-form-label ml-20">si cette réponse est choisie</label>
                                        }
                                        <br clear="both" />
                                    </div>
                                </div>
                                <div className="label-declencher">
                                    <div className="form-group">
                                        <label className="col-form-label ml-20">Déclencher une autre question suite à cette réponse :</label>
                                        <select className="form-control" value={r.action ? r.action : ''} onChange={(e) => modifieReponse(index, "action", e.target.value)}>
                                            <option value="">Aucune question déclenchée</option>
                                            <option value="question-etre-rappele">Souhaitez-vous être rappelé(e) ?</option>
                                            <option value="question">Question personnalisée</option>
                                        </select>
                                        {r.action === 'question' &&
                                            <>
                                                {r.questionDeclenchee !== null &&
                                                    <>
                                                        <label className="col-form-label ml-20">Question déclenchée : {r.questionDeclenchee.libelleCalcule} <a className="text-warning" href="#" onClick={(e) => {e.preventDefault(); editeQuestionDeclenchee(r, index)}}>Editer la question</a></label>
                                                    </>
                                                }    
                                            </>                                        
                                        } 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
});

const SortableReponses = SortableContainer(({items, question, questionsDeclenchees, scores, modifieReponse, supprimeReponse, editeQuestionDeclenchee}) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableReponse key={`item-${index}`} sortIndex={value.numero} index={value.numero} i={index} items={items} value={value} question={question} questionsDeclenchees={questionsDeclenchees} editeQuestionDeclenchee={editeQuestionDeclenchee} scores={scores} modifieReponse={modifieReponse} supprimeReponse={supprimeReponse} />
            ))}
        </div>
    );
}); 


class FormQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.repetitionTypeSelect = "jour";
    }

    initFromProps() {
        let question = this.props.question ? JSON.parse(JSON.stringify(this.props.question)) : this.nouvelleQuestion();

        question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;

        let repetitionTypeSelect = "jour";
        if (["jour", "semaine", "semaine-paire", "semaine-impaire", "mois", "an"].includes(question.repetition.type)) {
            repetitionTypeSelect = question.repetition.type
        }

        return {
            question: question,
            questionsDeclenchees: [],
            repetitionTypeSelect: repetitionTypeSelect
        };
    }

    nouvelleQuestion() {
        return {     
            id: null,
            classe: "Question",
            libelle: "",
            numero: this.props.numero,
            cle: {
                code: null,
                iconde: null
            },
            type: "liste",
            parametres: {
                "liste": {},
                "gradient": {
                    min: 1,
                    max: 5
                },
                "information": {}
            },
            gradientMin: 1,
            gradientMax: 5,
            repetition: {
                type: "none",
                joursSemaine: [1],
                mois: {
                    type: 'absolu',
                    joursAbsolus: [1],
                    joursRelatifs: {
                        relatif: 'first',
                        jour: 0
                    },
                    frequence: 1,
                    debut: {
                        mois: (new Date()).getMonth() + 1,
                        annee: (new Date()).getFullYear()
                    }
                },
                jourAn: {
                    jour: "01",
                    mois: "01"
                },
                heure: "12",
                minute: "00",
                fin: {
                    type: "none",
                    date: null,
                    dateWidget: new Date(),
                    occurrences: 10
                },
            },
            recurrence: 1,
            reponses: [],
            reponseDefaut: {
                id: null,
                classe: "Reponse",
                libelle: "Pas de réponse",
                cle: {
                    code: null,
                    icone: null
                },
                numero: 0,
                score: 0,
                questionDeclenchee: null
            }
        }
    }

    componentDidMount() {
		console.log("formquestion did mount");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.question != this.props.question) {
            this.setState(this.initFromProps());
        }        
    }
    
    modifieQuestion(champ, val) {
        let question = this.state.question;
        let repetitionTypeSelect = this.state.repetitionTypeSelect;
        if (champ == "libelle") {
            question.libelle = val;
        }
        if (champ == "type") {
            question.type = val;
            if (question.type == "gradient") {
                question = this.ajusteReponsesGradient(question);
            }
        }
        if (champ == "parametres-gradient-min") {
            if (/^[0-9]*$/.test(val)) {
                question.parametres.gradient.min = val;
                question = this.ajusteReponsesGradient(question);    
            }            
        }
        if (champ == "parametres-gradient-max") {
            if (/^[0-9]*$/.test(val)) {
                question.parametres.gradient.max = val;
                question = this.ajusteReponsesGradient(question);
            }
        }
        if (champ == "repetition-type") {
            question.repetition.type = val;
            if (["jour", "semaine", "semaine-paire", "semaine-impaire", "mois"].includes(val)) {
                repetitionTypeSelect = val;
            }
        }
        if (champ == "repetition-joursSemaine") {
            question.repetition.joursSemaine = val;
        }
        if (champ == "repetition-mois-type") {
            question.repetition.mois.type = val;
        }
        if (champ == "repetition-mois-joursAbsolus") {
            question.repetition.mois.joursAbsolus = val;
        }
        if (champ == "repetition-mois-joursRelatifs-relatif") {
            question.repetition.mois.joursRelatifs.relatif = val;
        }
        if (champ == "repetition-mois-joursRelatifs-jour") {
            question.repetition.mois.joursRelatifs.jour = val;
        }
        if (champ == "repetition-mois-frequence") {
            question.repetition.mois.frequence = val;            
        }
        if (champ == "repetition-mois-debut-mois") {
            question.repetition.mois.debut.mois = val;
        }
        if (champ == "repetition-mois-debut-annee") {
            question.repetition.mois.debut.annee = val;
        }
        if (champ == "repetition-jourAn-jour") {
            question.repetition.jourAn.jour = val;
        }
        if (champ == "repetition-jourAn-mois") {
            question.repetition.jourAn.mois = val;
        }
        if (champ == "repetition-heure") {
            question.repetition.heure = val;
        }
        if (champ == "repetition-minute") {
            question.repetition.minute = val;
        }
        if (champ == "repetition-fin-type") {
            question.repetition.fin.type = val;
        }
        if (champ == "repetition-fin-date") {
            question.repetition.fin.type = "date";
            question.repetition.fin.dateWidget = val;
            let calendarService = new CalendarService();
            question.repetition.fin.date = calendarService.formatteDate(question.repetition.fin.dateWidget);

        }
        if (champ == "repetition-fin-occurrences") {
            question.repetition.fin.type = "occurrences";
            question.repetition.fin.occurrences = val;
        }
        if (champ == "reponses") {
            question.reponses = val;
        }
        if (champ == "reponseDefaut") {
            question.reponseDefaut = val;
        }
        this.setState({
            question: question,
            repetitionTypeSelect: repetitionTypeSelect
        });
        if (this.state.questionsDeclenchees.length === 0 && this.props.modifie) {
            this.props.modifie(this.state.question);
        }
    }

    ajusteReponsesGradient(question) {
        console.log(parseInt(question.parametres.gradient.min) < parseInt(question.parametres.gradient.max));
        if (question.parametres.gradient.min !== null && question.parametres.gradient.min !== "" && question.parametres.gradient.max !== null && question.parametres.gradient.max !== "" && parseInt(question.parametres.gradient.min) < parseInt(question.parametres.gradient.max)) {
            question.reponses.map((r, index) => {
                if (r.cle.code) {
                    if (r.cle.code < question.parametres.gradient.min || r.cle.code > question.parametres.gradient.max) {
                        question.reponses[index].supprime = true;
                    }
                }
                else {
                    if ((index + 1) >= question.parametres.gradient.min && (index + 1) <= question.parametres.gradient.max) {
                        question.reponses[index].supprime = true;
                    }
                }
            });
            question.reponses = question.reponses.filter(reponse => reponse.id || !reponse.supprime);
            for (let i = parseInt(question.parametres.gradient.min) ; i <= parseInt(question.parametres.gradient.max) ; i++) {
                let trouve = false;
                question.reponses.filter((r) => !r.supprime).map((r) => {
                    if (r.cle.code) {
                        if (r.cle.code == i) {
                            trouve = true;
                        }
                    }
                    else {
                        if ((index + 1) == i) {
                            trouve = true;
                        }
                    }
                    
                });
                if (!trouve) {
                    question.reponses.push({
                        id: null,
                        classe: "Reponse",
                        libelle: null,
                        cle: {
                            code: i,
                            icone: null
                        },
                        numero: (i * 2),
                        score: 0,
                        questionDeclenchee: null,
                        supprime: false
                    });
                }
            }
        }
        return question;
    }

    toggleJourSemaine(jour) {
        if (["semaine", "semaine-paire", "semaine-impaire"].includes(this.state.question.repetition.type)) {
            let joursSemaine = this.state.question.repetition.joursSemaine;
            if (joursSemaine.includes(jour)) {
                joursSemaine.splice(joursSemaine.indexOf(jour), 1);
            }
            else {
                joursSemaine.push(jour);
            }
            this.modifieQuestion("repetition-joursSemaine", joursSemaine);
        }    
    }

    toggleJourMois(jour) {
        let joursMois = this.state.question.repetition.mois.joursAbsolus;
        if (joursMois.includes(jour)) {
            joursMois.splice(joursMois.indexOf(jour), 1);
        }
        else {
            joursMois.push(jour);
        }
        this.modifieQuestion("repetition-mois-joursAbsolus", joursMois);
    }

    modifieReponse(index, champ, val) {
        //let reponses = this.state.question.reponses;
        let reponses = this.state.question.reponses.filter((r) => !r.supprime).sort((a, b) => this.state.question.type == "liste" ? (a.numero < b.numero ? -1 : 1) : (a.cle.code < b.cle.code ? -1 : 1));

        if (reponses[index]) {
            if (champ == "cle-code") {
                reponses[index].cle.code = val;
            }
            if (champ == "cle-icone") {
                reponses[index].cle.icone = val;
            }
            if (champ == "libelle") {
                reponses[index].libelle = val;
            }
            if (champ == "score") {
                reponses[index].score = reponses[index].score >= 0 ? val : -val;
            }
            if (champ == "scorePlusMoins") {
                let score = Math.abs(reponses[index].score);
                if (val == "avancer") {
                    if (score == 0) {
                        score = 1
                    }
                }
                if (val == "reculer") {
                    if (score == 0) {
                        score = -1
                    }
                    else {
                        score = -score;
                    }
                }
                if (val == "stagner") score = 0;
                reponses[index].score = score;
            }
            if (champ == "action") {
                reponses[index].action = val;
                if (val === 'question' && reponses[index].questionDeclenchee === null) {
                    reponses[index].questionDeclenchee = this.nouvelleQuestion()
                }
            }   
            if (champ == "questionDeclenchee") {
                reponses[index].questionDeclenchee = val;
            }
            this.modifieQuestion('reponses', reponses);
        }
    }

    modifieReponseDefaut(champ, val) {
        let reponseDefaut = this.state.question.reponseDefaut;
        if (champ == "score") {
            reponseDefaut.score = reponseDefaut.score >= 0 ? val : -val;
        }
        if (champ == "scorePlusMoins") {
            let score = Math.abs(reponseDefaut.score);
            if (val == "avancer") {
                if (score == 0) {
                    score = 1
                }
            }
            if (val == "reculer") {
                if (score == 0) {
                    score = -1
                }
                else {
                    score = -score;
                }
            }
            if (val == "stagner") score = 0;
            reponseDefaut.score = score;
        }
        this.modifieQuestion("reponseDefaut", reponseDefaut);
    }

    editeQuestionDeclenchee(reponse, indexReponse) {
        this.state.questionsDeclenchees.push({
            indexReponse: indexReponse,
            question: JSON.parse(JSON.stringify(this.state.question))
        })

        this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0)

        this.setState({
            question: JSON.parse(JSON.stringify(reponse.questionDeclenchee)),
            questionsDeclenchees: this.state.questionsDeclenchees
        })        
    }

    enregistreQuestionDeclenchee() {
        if (this.state.questionsDeclenchees.length === 0) return

        let question = this.state.questionsDeclenchees[this.state.questionsDeclenchees.length - 1].question
        question.reponses[this.state.questionsDeclenchees[this.state.questionsDeclenchees.length - 1].indexReponse].questionDeclenchee = JSON.parse(JSON.stringify(this.state.question))
        
        this.state.questionsDeclenchees.splice(-1)   
        this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0) 
        if (this.state.questionsDeclenchees.length === 0) {
            window.dispatchEvent(new Event('resize')); // pour remettre bien les dropdowns
            question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
        }    

        if (this.state.questionsDeclenchees.length === 0) {
            question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
        }
        
        this.setState({
            question: JSON.parse(JSON.stringify(question)),
            questionsDeclenchees: this.state.questionsDeclenchees
        }, () => {
            if (this.state.questionsDeclenchees.length === 0) {
                this.props.modifie(this.state.question)
            }            
        })  
    }

    annuleQuestionDeclenchee() {
        if (this.state.questionsDeclenchees.length === 0) return

        let question = this.state.questionsDeclenchees[this.state.questionsDeclenchees.length - 1].question
        
        this.state.questionsDeclenchees.splice(-1)        
        this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0) 
        if (this.state.questionsDeclenchees.length === 0) {
            window.dispatchEvent(new Event('resize')); // pour remettre bien les dropdowns
            question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
        }    

        if (this.state.questionsDeclenchees.length === 0) {
            question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
            console.log(question.repetition)
        }
        
        this.setState({
            question: JSON.parse(JSON.stringify(question)),
            questionsDeclenchees: this.state.questionsDeclenchees
        }, () => {
            if (this.state.questionsDeclenchees.length === 0) {
                this.props.modifie(this.state.question)
            }            
        })  
    }

    blurScore(index) {
        let reponses = this.state.question.reponses;
        if (reponses[index]) {
            reponses[index].score = /^[0-9]*$/.test(reponses[index].score) ? reponses[index].score : 1;
        }
    }

    ajouteReponse(e) {
        e.preventDefault();

        let reponses = this.state.question.reponses;

        reponses.push({
            id: null,
            classe: "Reponse",
            libelle: null,
            cle: {
                code: null,
                icone: null
            },
            numero: ((reponses.length + 1) * 2),
            score: 0,
            action: '',
            questionDeclenchee: null,
            supprime: false
        });

        this.modifieQuestion('reponses', reponses);
    }

    supprimeReponse(e, index) {
        e.preventDefault();        

        let reponses = this.state.question.reponses;        

        if (index != null) {
            if (reponses.filter((r) => !r.supprime)[index].id) {
                reponses.filter((r) => !r.supprime)[index].supprime = true;
            }
            else {
                let cpt = 0
                for (let r in reponses) {
                    if (!reponses[r].supprime) {
                        if (cpt == index) {
                            reponses.splice(r, 1)
                        }
                        cpt++
                    }
                }
                //reponses.splice(index, 1);   
            }
            
        }

        this.modifieQuestion('reponses', reponses);
    }

    reordonneReponses(oldIndex, newIndex) {
        if (oldIndex != newIndex) {
            let reponses = this.state.question.reponses;
            reponses.map((r, index) => {
                if (r.numero == oldIndex) {
                    reponses[index].numero = (oldIndex > newIndex) ? (newIndex - 1) : (newIndex + 1);
                }
            });
            reponses = reponses.sort((a, b) => (a.numero < b.numero) ? -1 : 1);
            let numero = 2;
            reponses.map((r, index) => {
                reponses[index].numero = numero;
                numero += 2;
            });
            this.modifieQuestion('reponses', reponses);
        }    
    }

    getChaineSms() {
        let chaine = "";
        
        if (this.props.personne.prefixeMessage) {
            chaine += (this.props.personne.prefixeMessage + " ");
        }
        
        chaine += this.state.question.libelle + " ";

        if (this.state.question.type == "liste") {
            this.state.question.reponses.filter((reponse) => !reponse.supprime).sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, index) => {
                chaine += "\"" + reponse.libelle + "\"" + " Tapez " + (reponse.cle.code ? reponse.cle.code : (index + 1)) + (reponse.cle.icone ? ("ou " + reponse.cle.icone + " ") : "") + (index != this.state.question.reponses.length - 1 ? " " : "");
            });    
        }

        if (this.state.question.type == "gradient") {
            chaine += "Tapez une valeur entre " + this.state.question.parametres.gradient.min + " et " + this.state.question.parametres.gradient.max + "." + " ";
        }

        if (this.props.personne.suffixeMessage) {
            chaine += (this.props.personne.suffixeMessage + " ");
        }

        return chaine;
    }

    getNbCreditsSms() {
        let paliersUnicode = [70, 134, 201, 268, 335, 402, 469];
        let paliersGsm = [160, 306, 459, 612, 765, 918, 1071];

        let chaine = this.getChaineSms();

        let mode = "gsm";
        
        for (let i = 0 ; i < chaine.length ; i++) {
            if ("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMN OPQRSTUVWXYZÄäàÅåÆæßÇèéÉìÖöòØøÑñÜüù#¤%&()*+,-./:;<>=§$!?£¿¡@¥Δ_ΦΓΛΩΠΨΣΘΞ\"'".indexOf(chaine.charAt(i)) == -1) {
                mode = "unicode";
            }
        }

        let nbCredits = 1;

        if (mode == "gsm") {
            for (let i = 0 ; i < paliersGsm.length ; i++) {
                if (chaine.length > paliersGsm[i]) {
                    nbCredits++;
                }
            }
        } 

        if (mode == "unicode") {
            for (let i = 0 ; i < paliersUnicode.length ; i++) {
                if (chaine.length > paliersUnicode[i]) {
                    nbCredits++;
                }
            }
        } 


        return nbCredits;
    }

	render() {

        let calendarService = new CalendarService();

        let joursSemaine = [];
        for (let i = 1 ; i <= 7 ; i++) {
            joursSemaine.push(
                <span key={"btn-jourSemaine-" + (i % 7)} className={"jour" + (this.state.question.repetition.type == "jour" || this.state.question.repetition.joursSemaine.includes((i % 7)) ? " actif" : "")} onClick={() => this.toggleJourSemaine((i % 7))}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
            );
        }

        let joursMois = [];
        for (let i = 1 ; i <= 31 ; i++) {
            joursMois.push(
                <span key={"btn-jourMois-" + i} className={"jour" + (this.state.question.repetition.mois.joursAbsolus.includes(i) ? " actif" : "")} onClick={() => this.toggleJourMois(i)}>{i}</span>
            );
        }

        let joursAn = [];
        for (let i = 1 ; i <= 31 ; i++) {
            let val = ("0" + i).substr(-2);
            joursAn.push(
                <option key={"option-jourAn-" + i} value={val}>{i}</option>
            );
        }

        let moisAn = [];
        for (let i = 0 ; i < 12 ; i++) {
            let mois = calendarService.mois()[i];
            moisAn.push(
                <option key={"option-moisAn-" + i} value={mois.chiffres}>{mois.nom}</option>
            );
        }

        let heures = [];
        for (let i = 7 ; i < 22 ; i++) {
            heures.push(
                <option key={"option-heure-" + i} value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>
            );
        }

        let minutes = [];
        for (let i = 0 ; i < 60 ; i+=5) {
            minutes.push(
                <option key={"option-minute-" + i} value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>
            );
        }

        let questionsDeclenchees = [];
        this.props.questionsDeclenchees.map((question) => {
            questionsDeclenchees.push(
                <option key={"option-questiondeclenchee-" + question.id} value={question.id}>{question.libelle.substr(0, 30) + (question.libelle.length > 30 ? "..." : "")}</option>
            )
        });

        let scores = [];
        for (let i = 1 ; i <= 10 ; i++) {
            scores.push(
                <option key={"option-score-" + i} value={i}>{i}</option>
            );
        }

        return ( 
            <div className="row">
                {this.state.questionsDeclenchees.length > 0 &&
                    <div className='col-md-12'>
                        <div className="alert alert-warning" role="alert">
                            <span className='text-secondary bold'>Question déclenchée par : </span>
                            {this.state.questionsDeclenchees.map((historique, indexHistorique) => {
                                return (
                                    <React.Fragment key={"historique-" + indexHistorique}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            let question = this.state.questionsDeclenchees[indexHistorique].question
                                            this.state.questionsDeclenchees.splice(-(this.state.questionsDeclenchees.length - indexHistorique))
                                            this.props.setEditingQuestionDeclenchee(this.state.questionsDeclenchees.length !== 0)     

                                            if (this.state.questionsDeclenchees.length === 0) {
                                                window.dispatchEvent(new Event('resize')); // pour remettre bien les dropdowns
                                                question.repetition.fin.dateWidget = (question.repetition.fin.date) ? new Date(question.repetition.fin.date) : null;
                                            }

                                            this.setState({
                                                question: JSON.parse(JSON.stringify(question)),
                                                questionsDeclenchees: this.state.questionsDeclenchees
                                            }, () => {
                                                if (this.state.questionsDeclenchees.length === 0) {
                                                    this.props.modifie(this.state.question)
                                                }            
                                            })
                                        }}><span className='text-warning'>{historique.question.libelleCalcule} &gt; {historique.question.type === 'liste' ? historique.question.reponses[historique.indexReponse].libelle : historique.indexReponse + 1}</span></a>{indexHistorique != this.state.questionsDeclenchees.length - 1 &&<> &gt; </>}
                                    </React.Fragment>
                                )
                            })}
                        </div>                        
                    </div>
                }
                <div className="col-md-8">
                    <form>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Intitulé{this.state.question.type != 'information' ? ' de la question' : ''}</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.question.libelle} onChange={(e) => this.modifieQuestion("libelle", e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label ml-3">Type :</label>
                            <div className="">
                                <select className="form-control" value={this.state.question.type} onChange={(e) => this.modifieQuestion("type", e.target.value)}>
                                    <option key="option-type-liste" value="liste">Liste de réponses</option>
                                    <option key="option-type-gradient" value="gradient">Gradient</option>
                                    <option key="option-type-information" value="information">Information</option>
                                </select>
                            </div>
                            {this.state.question.type == "gradient" &&
                                <>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">Valeur Min.</div>
                                                </div>
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.question.parametres.gradient.min} onChange={(e) => this.modifieQuestion("parametres-gradient-min", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">Valeur Max.</div>
                                                </div>
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.question.parametres.gradient.max} onChange={(e) => this.modifieQuestion("parametres-gradient-max", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {(this.state.question.type == "liste" || this.state.question.type == "gradient")  &&
                            <div id="ordrereponses">
                                <SortableReponses
                                    items={this.state.question.reponses.filter((r) => !r.supprime).sort((a, b) => this.state.question.type == "liste" ? (a.numero < b.numero ? -1 : 1) : (a.cle.code < b.cle.code ? -1 : 1))}
                                    updateBeforeStart={() => this.setState({})}
                                    shouldCancelStart={(e) => {
                                        return !e.target.classList.contains("dripicons-move");
                                    }}
                                    onSortEnd={({oldIndex, newIndex, collection, isKeySorting}, e) => {
                                        this.reordonneReponses(oldIndex, newIndex);
                                    }}
                                    helperContainer={() => {
                                        if (!this.state.question.id) {
                                            return document.querySelector("#modal-nouvellequestion #ordrereponses-new");   
                                        }
                                        return document.querySelector("#modal-modifquestion-" + this.state.question.id + " #ordrereponses");   
                                    }}
                                    question={this.state.question}
                                    questionsDeclenchees={questionsDeclenchees}
                                    scores={scores}
                                    modifieReponse={(index, champ, val) => this.modifieReponse(index, champ, val)}
                                    supprimeReponse={(e, index) => this.supprimeReponse(e, index) }
                                    editeQuestionDeclenchee={(reponse, indexReponse) => this.editeQuestionDeclenchee(reponse, indexReponse)}                                    
                                /> 
                                {this.props.personne.classe == "Beneficiaire" &&
                                    <div className="liste-question">
                                        <div className="form-row question-reponse">
                                            <div className="col-md-12">
                                                <div className="label-compteur">
                                                    <div className="form-group">
                                                        <select className="form-control" value={(this.state.question.reponseDefaut.score == 0 ? "stagner" : this.state.question.reponseDefaut.score > 0 ? "avancer" : "reculer")} onChange={(e) => this.modifieReponseDefaut("scorePlusMoins", e.target.value)}>
                                                            <option key="reponse-compteur-stagner" value="stagner">Ne fait pas avancer le compteur</option>
                                                            <option key="reponse-compteur-avancer" value="avancer">Fait avancer le compteur de</option>
                                                            <option key="reponse-compteur-reculer" value="reculer">Fait reculer le compteur de</option>
                                                        </select>                                                
                                                        {this.state.question.reponseDefaut.score != 0 &&
                                                            <>
                                                                <select className="form-control" value={Math.abs(this.state.question.reponseDefaut.score)} onChange={(e) => this.modifieReponseDefaut("score", e.target.value)}>
                                                                    {scores}
                                                                </select>
                                                                <label className="col-form-label ml-20">{Math.abs(this.state.question.reponseDefaut.score) <= 1 ? "signal faible" : "signaux faibles"} si l'utilisateur ne répond pas</label>
                                                            </>
                                                        }
                                                        {this.state.question.reponseDefaut.score == 0 &&
                                                            <label className="col-form-label ml-20">si l'utilisateur ne répond pas</label>
                                                        }
                                                        <br clear="both" />
                                                    </div>
                                                </div>                                        
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.question.type == "liste" &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <a href="#" onClick={(e) => this.ajouteReponse(e)} className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle réponse</a>
                                        </div>
                                    </div>
                                }
                                <div className="ordrereponseschild">
                                    <div className="liste-question"></div>
                                </div>                                
                            </div>
                        }
                        {this.state.questionsDeclenchees.length !== 0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <button onClick={(e) => {e.preventDefault(); this.enregistreQuestionDeclenchee()}} disabled={questionValide(this.state.question) == false} type="button" className="btn btn-primary contour"> Enregistrer la question déclenchée</button>
                                    <button onClick={(e) => {e.preventDefault(); this.annuleQuestionDeclenchee()}} type="button" className="btn btn-annexe contour">Annuler</button>
                                </div>
                            </div>
                        }
                    </form>
                </div>
                <div className="col-md-4">
                    <div className="modal-sidebar">
                        {this.state.questionsDeclenchees.length === 0 &&
                            <>
                                <h5 className="modal-title mt-0">Récurrence</h5>
                                <h6 className="">Répéter</h6>
                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.question.repetition.type == "none"} onChange={(e) => this.modifieQuestion("repetition-type", "none")} /> Jamais
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.question.repetition.type == "aleatoire"} onChange={(e) => this.modifieQuestion("repetition-type", "aleatoire")} /> Envoyée aléatoirement
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                {(this.props.personne.classe == "Beneficiaire" || this.props.personne.classe == "Proche") &&
                                    <>
                                        <div className="form-group">
                                            <div className="radio">
                                                <label>
                                                    <input type="radio" checked={["jour", "semaine", "semaine-paire", "semaine-impaire", "mois", "an"].includes(this.state.question.repetition.type)} onChange={() => this.modifieQuestion("repetition-type", this.state.repetitionTypeSelect)} /> Tou(te)s les :
                                                    <span className="checkmark"></span>
                                                </label>
                                                <select className="form-control" value={this.state.repetitionTypeSelect} onChange={(e) => this.modifieQuestion("repetition-type", e.target.value)}>
                                                <option key="option-repetition-jour" value="jour">jours</option>
                                                    <option key="option-repetition-semaine" value="semaine">semaines</option>
                                                    <option key="option-repetition-semaine-paire" value="semaine-paire">semaines paires</option>
                                                    <option key="option-repetition-semaine-impaire" value="semaine-impaire">semaines impaires</option>
                                                    <option key="option-repetition-mois" value="mois">[x] mois</option>
                                                    <option key="option-repetition-an" value="an">an</option>
                                                </select>
                                            </div>
                                        </div>   
                                        {["jour", "semaine", "semaine-paire", "semaine-impaire", "mois", "an"].includes(this.state.question.repetition.type) && 
                                            <>
                                                <h6 className="jours">Répéter le</h6>
                                                {["jour", "semaine", "semaine-paire", "semaine-impaire"].includes(this.state.question.repetition.type) &&
                                                    <>
                                                        <p className="modal-jours m-0 text-muted font-14">
                                                            {joursSemaine}
                                                        </p>
                                                        <br clear="both" />
                                                    </>
                                                }
                                                {this.state.question.repetition.type == "mois" &&
                                                    <>
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <div className="radio">
                                                                    <label>
                                                                        <input type="radio" checked={this.state.question.repetition.mois.type === 'absolu'} onChange={() => this.modifieQuestion("repetition-mois-type", 'absolu')} />
                                                                        <span className="checkmark"></span>
                                                                    </label>                                                                      
                                                                </div>               
                                                            </div>
                                                            <div className='col-10'>
                                                                <p className="modal-jours m-0 text-muted font-14" style={{paddingBottom: '12px !important'}}>
                                                                    {joursMois}
                                                                    <br clear="both" />                                                                                                                                        
                                                                </p>                                                                                   
                                                            </div>
                                                        </div>                                                                                                 
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <div className="radio">
                                                                    <label>
                                                                        <input type="radio" checked={this.state.question.repetition.mois.type === 'relatif'} onChange={() => this.modifieQuestion("repetition-mois-type", 'relatif')} />
                                                                        <span className="checkmark"></span>
                                                                    </label>                                                                                                                      
                                                                </div>  
                                                            </div>
                                                            <div className="col-10">
                                                                <p className="modal-jours m-0 text-muted font-14 p-0">
                                                                    <select className="form-control" value={this.state.question.repetition.mois.joursRelatifs.relatif} onChange={(e) => this.modifieQuestion("repetition-mois-joursRelatifs-relatif", e.target.value)}>
                                                                        <option key="option-repetition-mois-relatif-premier" value="first">1er</option>
                                                                        <option key="option-repetition-mois-relatif-deuxieme" value="second">2e</option>
                                                                        <option key="option-repetition-mois-relatif-troisieme" value="third">3e</option>
                                                                        <option key="option-repetition-mois-relatif-quatrieme" value="fourth">4e</option>
                                                                        <option key="option-repetition-mois-relatif-dernier" value="last">dern.</option>                                                                    
                                                                    </select> 
                                                                    <select className="form-control" value={this.state.question.repetition.mois.joursRelatifs.jour} onChange={(e) => this.modifieQuestion("repetition-mois-joursRelatifs-jour", e.target.value)}>
                                                                        {Array.from({ length: 7 }, (_, i) => i + 1).map(i => {
                                                                            return (
                                                                                <React.Fragment key={"mois-" + i}>
                                                                                    <option value={i % 7}>{moment().days(i % 7).format('ddd')}</option>
                                                                                </React.Fragment>
                                                                            )
                                                                        })}                                                                                                                                         
                                                                    </select>                                                                
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='pl-0 pb-3 pt-0'>
                                                            <p className="m-0 mb-4 text-muted font-14 p-0">
                                                                {/*Tous les <input type="text" className="form-control simple" id="inlineFormInputGroupUsername" value={this.state.question.repetition.mois.frequence} onChange={(e) => this.modifieQuestion("repetition-mois-frequence", e.target.value)} /> mois*/}
                                                                Tous les <select className="form-control" value={this.state.question.repetition.mois.frequence} onChange={(e) => this.modifieQuestion("repetition-mois-frequence", e.target.value)} >
                                                                    {Array.from({ length: 12 }, (_, i) => i + 1).map(i => {
                                                                        return (
                                                                            <React.Fragment key={"mois-" + i}>
                                                                                <option value={i}>{i}</option>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}                                                                 
                                                                </select> mois
                                                            </p>                                                            
                                                            <p className="m-0 text-muted font-14 p-0">
                                                                <span className='pt-2'>à partir de</span>
                                                                <select className="form-control" value={this.state.question.repetition.mois.debut.mois} onChange={(e) => this.modifieQuestion("repetition-mois-debut-mois", e.target.value)}>
                                                                    {Array.from({ length: 12 }, (_, i) => i).map(i => {
                                                                        return (
                                                                            <React.Fragment key={"mois-" + i}>
                                                                                <option value={i + 1}>{moment().month(i).format('MMM')}</option>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}                                                                 
                                                                </select> 
                                                                <select className="form-control" value={this.state.question.repetition.mois.debut.annee} onChange={(e) => this.modifieQuestion("repetition-mois-debut-annee", e.target.value)}>
                                                                    {Array.from({ length: (new Date()).getFullYear() - 2020 + 1 }, (_, i) => i + 2020).map(i => {
                                                                        return (
                                                                            <React.Fragment key={"annee-" + i}>
                                                                                <option value={i}>{i}</option>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}                                                                 
                                                                </select>                                                                
                                                            </p>                                                              
                                                        </div>                                                                                                                                                                      
                                                    </>
                                                }
                                                {this.state.question.repetition.type == "an" &&
                                                    <>
                                                        <p className="modal-jours m-0 text-muted font-14">
                                                            <select className="form-control" value={this.state.question.repetition.jourAn.jour} onChange={(e) => this.modifieQuestion("repetition-jourAn-jour", e.target.value)}>
                                                                {joursAn}
                                                            </select>
                                                            <select className="form-control" value={this.state.question.repetition.jourAn.mois} onChange={(e) => this.modifieQuestion("repetition-jourAn-mois", e.target.value)}>
                                                                {moisAn}
                                                            </select>
                                                        </p>
                                                        <br clear="both" />
                                                    </>
                                                }
                                                <h6 className="">Répéter à : 
                                                    <select className="form-control" value={this.state.question.repetition.heure} onChange={(e) => this.modifieQuestion("repetition-heure", e.target.value)}>
                                                        {heures}
                                                    </select>
                                                    h
                                                    <select className="form-control" value={this.state.question.repetition.minute} onChange={(e) => this.modifieQuestion("repetition-minute", e.target.value)}>
                                                        {minutes}
                                                    </select>
                                                </h6>
                                                <h6 className="">Se termine</h6>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        <label>
                                                            <input type="radio" checked={this.state.question.repetition.fin.type == "none"} onChange={() => this.modifieQuestion("repetition-fin-type", "none")} /> Jamais
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        <label>
                                                            <input type="radio" checked={this.state.question.repetition.fin.type == "date"} onChange={() => this.modifieQuestion("repetition-fin-type", "date")} /> <span className="le">Le :</span>
                                                            <span className="checkmark"></span>
                                                            <div className="input-group date">
                                                                <DatePicker
                                                                    ref={(input) => { this.ref_findateWidget = input; }} 
                                                                    className="form-control" 
                                                                    locale="fr"
                                                                    selected={this.state.question.repetition.fin.dateWidget instanceof Date ? this.state.question.repetition.fin.dateWidget : null}
                                                                    onChange={(date) => this.modifieQuestion("repetition-fin-date", date)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                />    
                                                                <div className="input-group-append" onClick={() => this.ref_findateWidget.setFocus(true)}>
                                                                    <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        <label>
                                                            <input type="radio" checked={this.state.question.repetition.fin.type == "occurrences"} onChange={() => this.modifieQuestion("repetition-fin-type", "occurrences")} /> Après
                                                            <input type="text" className="form-control simple" id="inlineFormInputGroupUsername" value={this.state.question.repetition.fin.occurrences} onChange={(e) => this.modifieQuestion("repetition-fin-occurrences", e.target.value)} /> occurrences
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <br clear="both" /> 
                                            </>
                                        }
                                    </>
                                }
                                {/*
                                {this.props.personne.classe == "Proche" &&
                                    <div className="form-group">
                                        <div className="radio">
                                            <label>
                                                <input type="radio" checked={this.state.question.repetition.type == "reglages"} onChange={(e) => this.modifieQuestion("repetition-type", "reglages")} /> Selon les réglages
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                }*/}
                            </>
                        }                                                                            
                        <h5 className="modal-title mt-0">Prévisualisation {this.props.personne.modalite == "sms" && <>({this.getNbCreditsSms()} SMS)</>}</h5>
                        <div className="previsualisation">
                            {this.props.personne.prefixeMessage && this.state.question.repetition.type != "none" && <>{this.props.personne.prefixeMessage}<br /></>}
                            {this.state.question.libelle && <>{this.state.question.libelle}<br /></>}
                            {this.state.question.type == "liste" &&
                                <>
                                    {this.state.question.reponses.filter((reponse) => !reponse.supprime).sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, index) =>
                                        <React.Fragment key={"question-previsualisation-reponse-" + index}>
                                            "{reponse.libelle}" Tapez {reponse.cle.code ? reponse.cle.code : (index + 1)} {reponse.cle.icone ? ("ou " + reponse.cle.icone) : ""}
                                            {index != this.state.question.reponses.length - 1 && <br />}
                                        </React.Fragment>
                                    )}
                                </>
                            }
                            {this.state.question.type == "gradient" &&
                                <>
                                    Tapez une valeur entre {this.state.question.parametres.gradient.min} et {this.state.question.parametres.gradient.max}.
                                </>
                            }
                            {this.props.personne.suffixeMessage && this.state.question.repetition.type != "none" && <><br />{this.props.personne.suffixeMessage}</>}
                        </div>
                        <br clear="both" />
                    </div>
                </div>
            </div>
        );
	}
}

export default FormQuestion;