import React, { Component } from 'react';

import Api from '../../api';

import DataService from '../../services/data';

import JaugeBeneficiaire from '../JaugeBeneficiaire';
import CalendarService from '../../services/calendar';

import FormPeriode from '../Forms/FormPeriode';
import FormInteraction from '../Forms/FormInteraction';
import SecurityService from '../../services/security';
import { getTerme } from '../../services/contextualisation';

import moment from 'moment';

class SidebarBeneficiaire extends Component {

    constructor(props) {
        super(props);
        this.state = {
            motifFinAnticipeePeriodeEnCours: "",
            editingPeriode: null,
            editingInteraction: null,
            seuil: props.beneficiaire.seuil,
            motifSuppression: 'depart'
        }
    }

    componentDidMount() {
        console.log("SidebarBeneficiaire did mount");

        this.bindModals();
    }

    componentDidUpdate() {
        console.log("SidebarBeneficiaire did mount");
        
        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-sidebar-nouvelleperiode").off('hidden.bs.modal');
        window.jQuery("#modal-sidebar-nouvelleperiode").on('hidden.bs.modal', () => {
            console.log("dismiss");
            this.setState({
                editingPeriode: null
            });
        });

        window.jQuery("#modal-sidebar-nouvelleinteraction").off('hidden.bs.modal');
        window.jQuery("#modal-sidebar-nouvelleinteraction").on('hidden.bs.modal', () => {
            console.log("dismiss");
            this.setState({
                editingInteraction: null
            });
        });
    }

    setEditingPeriode(periode) {
        this.setState({
            editingPeriode: periode
        });
    }

    setEditingInteraction(interaction) {
        this.setState({
            editingInteraction: interaction
        });
    }

    resetScore() {
		this.props.enregistre({
			id: this.props.beneficiaire.id,
			classe: this.props.beneficiaire.classe,
			score: 0
		});
    }

    modifieMotifFinAnticipeePeriodeEnCours(valeur) {
        this.setState({
            motifFinAnticipeePeriodeEnCours: valeur
        });
    }

    ajouteModele() {
        let periodes = this.props.beneficiaire.periodes;
        let modele = JSON.parse(JSON.stringify(this.state.editingPeriode));
        let periode = JSON.parse(JSON.stringify(this.state.editingPeriode));
        modele.occurrences = [periode];
        periodes.push(
            modele
        );
        this.enregistreBeneficiaire("periodes", periodes);
    }

    ajouteInteraction() {
        let interactions = this.props.beneficiaire.interactions;
        interactions.push(
            this.state.editingInteraction
        );
        this.enregistreBeneficiaire("interactions", interactions);
    }

    stoppePeriode(periode) {
        let iModele = null;
        let iPeriode = null;
        let periodes = this.props.beneficiaire.periodes;
        
        periodes.map((m, index) => {
            if (m.id == periode.modele) iModele = index;
        });

        periodes[iModele].occurrences.map((p, index) => {
            if (p.id == periode.id) iPeriode = index;
        })

        periode.motifFinAnticipee = this.state.motifFinAnticipeePeriodeEnCours;
        periode.termine = true;

        periodes[iModele].occurrences[iPeriode] = periode;

        this.enregistreBeneficiaire("periodes", periodes);
    }

    modifieSeuil(val) {
        if (val == "" || /^[0-9]*$/.test(val)) {
            this.setState({
                seuil: val
            })
        }
    }

    enregistreBeneficiaire(champ, val) {
        console.log(champ);
        let beneficiaire = null; 
        if (champ == "periodes") {
            beneficiaire = {
                id: this.props.beneficiaire.id,
                classe: this.props.beneficiaire.classe,
                periodes: val
            }
        }
        if (champ == "interactions") {
            beneficiaire = {
                id: this.props.beneficiaire.id,
                classe: this.props.beneficiaire.classe,
                interactions: val
            }
        }
        if (champ == "seuil") {
            beneficiaire = {
                id: this.props.beneficiaire.id,
                classe: this.props.beneficiaire.classe,
                seuil: val
            }
        }
        if (champ == "conseillerPrincipal") {
            beneficiaire = {
                id: this.props.beneficiaire.id,
                classe: this.props.beneficiaire.classe,
                conseillerPrincipal: val
            }
        }
        if (champ == "actif") {
            beneficiaire = {
                id: this.props.beneficiaire.id,
                classe: this.props.beneficiaire.classe,
                actif: val
            }
        }
        if (champ == "suiviAvance") {
            beneficiaire = {
                id: this.props.beneficiaire.id,
                classe: this.props.beneficiaire.classe,
                suiviAvance: val,
                actif: true
            }
        }
        if (champ == "supprime") {            
            beneficiaire = {
                id: this.props.beneficiaire.id,
                classe: this.props.beneficiaire.classe,
                supprime: val,
                motifSuppression: this.state.motifSuppression
            }
            console.log(JSON.parse(JSON.stringify(beneficiaire)))
        }
        if (beneficiaire) {
            this.props.enregistre(beneficiaire);
		}
    }
    
    envoieQuestionTest() {
		let api = new Api();
		api.sendPersonneQuestionTest(this.props.beneficiaire).then((json) => {
			console.log(json);
		}).catch(err => {
			console.log(err);
		});
    }
    
    formPeriodeValide() {
        if (this.state.editingPeriode) console.log(this.state.editingPeriode.repetition.date);
        return (
            this.state.editingPeriode
            && this.state.editingPeriode.debutWidget
            && this.state.editingPeriode.finWidget
            && this.state.editingPeriode.debutWidget < this.state.editingPeriode.finWidget
            && this.state.editingPeriode.description != ""
            && this.state.editingPeriode.seuil != ""
            && this.state.editingPeriode.seuil > 0
            && (
                this.state.editingPeriode.repetition.type == "none"
                || (
                    this.state.editingPeriode.repetition.pas
                    && (
                        this.state.editingPeriode.repetition.fin.type == "none"
                        || this.state.editingPeriode.repetition.fin.type == "date" && this.state.editingPeriode.repetition.fin.date
                        || this.state.editingPeriode.repetition.fin.type == "occurrences" && this.state.editingPeriode.repetition.fin.occurrences != "" && this.state.editingPeriode.repetition.fin.occurrences > 0
                    )
                )
            )
        );
    }

    formInteractionValide() {
        return (
            this.state.editingInteraction
            && this.state.editingInteraction.description
            && this.state.editingInteraction.categorie
            && this.state.editingInteraction.debut <= this.state.editingInteraction.fin
        )
    }

    envoieDemandeConsentement() {
        let api = new Api();
		api.sendPersonneConsentement(this.props.beneficiaire).then((json) => {
			console.log(json);
            if (json.succes) {
                this.props.enregistre({
                    id: this.props.beneficiaire.id,
                    classe: this.props.beneficiaire.classe,
                    etatConsentement: true // fausse valeur
                })
            }
		}).catch(err => {
			console.log(err);
		});
    }
    
	render() {

        let dataService = new DataService();
        let calendarService = new CalendarService();
        let securityService = new SecurityService();

        let modals = [];

        let structure = dataService.getStructureById(this.props.beneficiaire.structure)

        

        modals.push(
            <div key="modal-sidebar-nouvelleperiode" id="modal-sidebar-nouvelleperiode" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Nouvelle période sensible</h5>
                            <div className="header-buttons">
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formPeriodeValide()} onClick={() => this.ajouteModele()}>Enregistrer</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <FormPeriode
                                periode={this.state.editingPeriode}
                                modifie={(periode) => this.setEditingPeriode(periode)}
                                sidebar={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

        modals.push(
            <div key="modal-beneficiairechangeseuilnormal" id="modal-beneficiairechangeseuilnormal" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Modifier le seuil d'alerte</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Le seuil d'alerte que vous êtes sur le point de définir pour <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> est le seuil utilisé en dehors des périodes sensibles.<br /><br />
                                Nouveau seuil : <input className={"form-control simple" + (!/^[0-9]*$/.test(this.state.seuil) || this.state.seuil == "" ? " input-error" : "")} type="text" value={this.state.seuil} onChange={(e) => this.modifieSeuil(e.target.value)} /> 
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => this.enregistreBeneficiaire("seuil", this.state.seuil)} disabled={!/^[0-9]*$/.test(this.state.seuil) || this.state.seuil == ""} data-dismiss="modal">Modifier le seuil d'alerte</button>
                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        );

        let periodesEnCours = [];
		this.props.beneficiaire.periodesEnCours.map((periodeEnCours) => {
            console.log(periodeEnCours.fin);
            let dateFinPeriode = new Date(periodeEnCours.fin);
            console.log(dateFinPeriode);
            periodesEnCours.push(
                <div key={"bloc-periodeencours-" + periodeEnCours.id} className="card card-period m-b-30 text-white">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xs-12 col-md-4">
                                <i className="mdi mdi-clock-alert huge"></i>
                            </div>
                            <div className="col-xs-12 col-md-8 pl-4 text-left">
                                <h4 className="mt-0 header-title mb-3">Période sensible en cours</h4>
                                <h5>{periodeEnCours.description}</h5>
                                <p className="text-muted">Fin de la période : {dateFinPeriode.getDate()} {calendarService.mois()[dateFinPeriode.getMonth()].nomCourt} {dateFinPeriode.getFullYear()}</p>
                            </div>
                        </div>
                        <a href="#" data-toggle="modal" data-target={"#modal-stoppeperiode-" + periodeEnCours.id} className="btn btn-annexe contour float-right">Arrêter la période</a>
                    </div>
                </div>
            );
            modals.push(
                <div key={"modal-stoppeperiode-" + periodeEnCours.id} id={"modal-stoppeperiode-" + periodeEnCours.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom} - Mettre fin à une période sensible</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Vous êtes en train d'arrêter la période sensible ({periodeEnCours.description.toLowerCase()}) pour <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b>. Le seuil d'alerte sera remis à son niveau d'origine.<br /><br />
                                    Quel est le motif de la fin de la période sensible ?<br />
                                    <textarea className="form-control" value={this.state.motifFinAnticipeePeriodeEnCours} onChange={(e) => this.modifieMotifFinAnticipeePeriodeEnCours(e.target.value)}></textarea>
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.stoppePeriode(periodeEnCours)} data-dismiss="modal">Mettre fin à la période sensible</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        
        let conseillers = [];
        // DEPRECATED 05/12/2023
        /*if (dataService.getConseillerById(this.props.beneficiaire.conseillerPrincipal)) {
            let conseiller = dataService.getConseillerById(this.props.beneficiaire.conseillerPrincipal);
            conseillers.push(
                <button key={"btn-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme principal" data-toggle="tooltip" data-placement="top" title={(this.props.beneficiaire.conseillers.length > 1 ? "Principal: " : "") + conseiller.prenom + " " + conseiller.nom}>
                    {conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
                </button>
            )
        }
        this.props.beneficiaire.conseillers.filter((c) => (c != this.props.beneficiaire.conseillerPrincipal)).map((c) => {
            let conseiller = dataService.getConseillerById(c);
            if (conseiller != null) {
                conseillers.push(
                    <button key={"btn-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom}>
                        {conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
                    </button>
                )
            }
        });*/
        let conseillersFiltres = []
        dataService.getStructureConseillers(structure).map(conseiller => {
            if (securityService.canFollowBeneficiaire(conseiller, this.props.beneficiaire) === false) {
                return
            }
            conseillers.push(
                <button key={"btn-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme principal" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom}>
                    {conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
                </button>
            )
            conseillersFiltres.push(conseiller.id)
        })        
        this.props.beneficiaire.conseillers
        .filter(c => conseillersFiltres.includes(c) === false)
        .map((c) => {
            let conseiller = dataService.getConseillerById(c);
            if (conseiller != null) {
                conseillers.push(
                    <button key={"btn-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom + (structure.filtres.length > 0 ? ' (ajout manuel)' : '')}>
                        {conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
                    </button>
                )
            }
        });        
        
        let questionsEnPause = null;
		if (this.props.beneficiaire.pauseQuestions || this.props.beneficiaire.enConges) {
			questionsEnPause = <p className="pause" onClick={() => this.props.goToQuestions()}><i className="mdi mdi-pause-circle-outline"></i> Questions auto. en pause {this.props.beneficiaire.enConges && <>(congés)</>}</p>;
        }

        let filtresBeneficiaire = []
        structure.filtres.map(filtre => {
            let actif = this.props.beneficiaire.filtres.includes(filtre.id)
            let sousFiltres = []
            filtre.filtres.map(sousFiltre => {
                if (this.props.beneficiaire.filtres.includes(sousFiltre.id)) {
                    actif = true
                    sousFiltres.push(sousFiltre)
                }
            })								
            if (actif) {
                if (sousFiltres.length == 0) {
                    filtresBeneficiaire.push(filtre.nom)
                }
                else {
                    sousFiltres.map(sousFiltre => {
                        filtresBeneficiaire.push(filtre.nom + " - " + sousFiltre.nom)
                    })
                }
            }
        })

        return (
            <div className="card card-profil m-b-20">
                <div className="card-body text-center">
                    <h3>{this.props.beneficiaire.suiviAvance && <i className="mdi mdi-wifi"></i>} {this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</h3>
                    {this.props.beneficiaire.supprime &&
                        <span className="compte-supprime"><b>Compte supprimé</b></span>
                    }
                    {filtresBeneficiaire.length > 0 &&
                        <p className='text-center'>
                            {filtresBeneficiaire.map((filtre, iFiltre) => {
                                return (
                                    <React.Fragment key={"filtre-" + iFiltre}>
                                        {iFiltre != 0 && <br />}
                                        {filtre}
                                    </React.Fragment>
                                )
                            })}						
                        </p>
                    }	
                    <div className="widget-user-desc p-3 text-center">
                        <div id="sparkline">
                            <JaugeBeneficiaire
                                beneficiaire={this.props.beneficiaire}
                                min="0"
                                max={this.props.beneficiaire.seuilCalcule}
                                value={this.props.beneficiaire.score}
                            />
                        </div>
                    </div>
                    <div className="text-center signaux">
                        <h6 className="mb-1">{this.props.beneficiaire.score}<span>/{this.props.beneficiaire.seuilCalcule}</span></h6>
                        <p className="text-muted mb-0">Signaux faibles comptabilisés</p>
                        <p className="alert"><i className="mdi mdi-alert-outline"></i> Alerte au bout de {this.props.beneficiaire.seuilCalcule} signaux faibles</p>                        
                        {questionsEnPause}
                        <br clear="both" />
                    </div>
                    {this.props.beneficiaire.score != 0 &&
                        <>
                            <a href="#" data-toggle="modal" data-target="#modal-sidebar-beneficiairenouvelleinteraction" onClick={() => this.setEditingInteraction(null)} className="btn btn-annexe contour text-center w-100"><i className="mdi mdi-refresh"></i> Réinitialiser le compteur</a>
                            <br clear="both" />
                        </>
                    }
                    <a href="#" data-toggle="modal" data-target="#modal-beneficiairechangeseuilnormal" className="btn btn-annexe contour text-center w-100"><i className="dripicons-document-edit"></i> Modifier le seuil d'alerte</a>
                    <br clear="both" />
                    <a href="#" className="btn btn-annexe contour text-center w-100" data-toggle="modal" data-target="#modal-sidebar-nouvelleperiode"><i className="dripicons-warning"></i> Indiquer une période sensible</a>
                    {periodesEnCours}
                    <hr />
                    <h4 className="mt-0 header-title mb-3 text-center">Suivi par :</h4>
                    <div className="acronymes">
                        {conseillers}                        
                        {this.props.partagePossible && 
                            <a href="" data-toggle="modal" data-target="#modal-partagerbeneficiaire">
                                <div data-toggle="tooltip" data-placement="top" title="Partager le suivi" className="acronyme plus"><i className="mdi mdi-account-settings-variant"></i></div>
                            </a>	
                        }
                        {/*
                        {!this.props.partagePossible && 
                            <p>Aucun {getTerme("accompagnant", structure.domaine)} n'est disponible.</p>
                        }*/}
                        <br clear="both" />                   
                    </div>
                    {/*
                    {structure.domaine !== 'entreprise' &&
                        <>
                            {conseillers.length > 1 &&
                                <>
                                    <h5>{getTerme("Accompagnant", structure.domaine)} principal :</h5>
                                    <select className="form-control" value={this.props.beneficiaire.conseillerPrincipal} onChange={(e) => this.enregistreBeneficiaire("conseillerPrincipal", e.target.value)}>
                                        {this.props.beneficiaire.conseillers.map(c => {
                                            let dataService = new DataService();
                                            let conseiller = dataService.getConseillerById(c);
                                            if (conseiller) {
                                                return <option value={conseiller.id} key={"option-conseillerprincipal-" + conseiller.id}>{conseiller.prenom} {conseiller.nom}</option>
                                            }
                                        })}
                                    </select>
                                </>
                            }    
                        </>
                    }*/}
                    {this.props.beneficiaire.suiviAvance &&
                        <>
                            <hr />
                            <a href="#" className="btn btn-annexe contour text-center w-100" data-toggle="modal" data-target="#modal-questiontest"><i className="mdi mdi-comment-question-outline"></i> Envoyer une question test</a>
                            <div id="modal-questiontest" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0">Envoyer une question test</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Etes-vous sûr de vouloir envoyer une question à <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> qui ne sera pas comptabilisée dans les résultats. Cela peut vous permettre de lui faire une démonstration. Voulez-vous continuer ?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" onClick={() => this.envoieQuestionTest()} data-dismiss="modal">Envoyer la question test</button>
                                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                        </div>
                                    </div>
                                </div>
                            </div>	
                        </>
                    }
                    {!this.props.beneficiaire.supprime && 
                        <>
                            {(securityService.isAdmin() || securityService.isResponsableStructure(dataService.getStructureById(this.props.beneficiaire.structure)) || (this.props.conseiller && this.props.beneficiaire.conseillers.includes(this.props.conseiller.id))) &&
                                <>
                                    {this.props.beneficiaire.actif &&
                                        <>
                                            {/*<hr />*/}
                                            {/*<button type="button" data-toggle="modal" data-target="#modal-togglesuivibeneficiaire" className="btn btn-annexe contour text-center w-100 action" disabled={this.props.beneficiaire.suiviAvance ? dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviNormal : dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviAvance}><i className={"mdi " + (this.props.beneficiaire.suiviAvance ? "mdi-bell-off" : "mdi-bell")}></i> Passer en suivi {this.props.beneficiaire.suiviAvance ? "sans" : "avec" } signaux faibles</button>*/}
                                            <div key="modal-togglesuivibeneficiaire" id="modal-togglesuivibeneficiaire" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title mt-0">Changer le type de suivi la personne</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            {this.props.beneficiaire.suiviAvance && <p>Le suivi avec signaux faibles de <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> sera désactivé. Il pourra être rétabli ultérieurement. Voulez-vous continuer ?</p>}
                                                            {!this.props.beneficiaire.suiviAvance && <p>Le suivi avec signaux faibles de <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> sera activé. Voulez-vous continuer ?</p>}
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-primary" onClick={() => this.enregistreBeneficiaire("suiviAvance", !this.props.beneficiaire.suiviAvance)} data-dismiss="modal">Passer en suivi {this.props.beneficiaire.suiviAvance ? "sans" : "avec"} signaux faibles</button>
                                                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>	
                                        </>
                                    }
                                    <hr />                                    
                                    <div className="prochede">Consentement : {this.props.beneficiaire.etatConsentement ? {accepte: 'Accepté', refuse: 'Refusé', 'etre-rappele': 'Souhaite être rappelé'}[this.props.beneficiaire.etatConsentement] : this.props.beneficiaire.dateDemandeConsentement ? ("En attente (depuis le " + moment(this.props.beneficiaire.dateDemandeConsentement, "YYYY/MM/DD HH:mm:ss").format('DD/MM/YYYY') + ")") : "Non demandé"}</div>
                                    {(['refuse', 'etre-rappele'].includes(this.props.beneficiaire.etatConsentement) || (this.props.beneficiaire.dateDemandeConsentement)) &&
                                        <>
                                            <a href="#" data-toggle="modal" data-target="#modal-beneficiaireconsentement" className="btn btn-annexe contour contour text-center w-100">
                                                <i className="mdi mdi-send"></i>
                                                <>Envoyer une nouvelle demande de consentement</>
                                            </a>
                                            <div id="modal-beneficiaireconsentement" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title mt-0">Renvoyer une demande de consentement</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>Un nouvelle demande de consentement va être envoyée à <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> par {this.props.beneficiaire.modalite}. Voulez-vous continuer ?</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-primary" onClick={() => this.envoieDemandeConsentement()} data-dismiss="modal">Envoyer la demande de consentement</button>
                                                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }       
                                    <hr />   
                                    <a href="#" data-toggle="modal" data-target="#modal-toggleactivationbeneficiaire" className={"btn btn-annexe contour text-center w-100" + (this.props.beneficiaire.actif ? " attention" : " action")}><i className={"mdi " + (this.props.beneficiaire.actif ? "mdi-block-helper" : "mdi-checkbox-marked-circle-outline")}></i> {this.props.beneficiaire.actif ? "Désactiver" : "Réactiver" } l'utilisateur</a>
                                    <div key="modal-toggleactivationbeneficiaire" id="modal-toggleactivationbeneficiaire" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0">{this.props.beneficiaire.actif ? "Désactiver" : "Réactiver" } l'utilisateur</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                </div>
                                                <div className="modal-body">
                                                    {this.props.beneficiaire.actif && <p>L'ensemble des services de suivi concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
                                                    {!this.props.beneficiaire.actif && <p>Le suivi concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> sera réactivé. Vous pouvez choisir ci-dessous le type de suivi (avec signaux faibles, sans signaux faibles) souhaité.</p>}
                                                </div>
                                                <div className="modal-footer">
                                                    {this.props.beneficiaire.actif &&
                                                        <>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.enregistreBeneficiaire("actif", false)} data-dismiss="modal">Désactiver le suivi de la personne</button>
                                                        </>
                                                    }
                                                    {!this.props.beneficiaire.actif &&
                                                        <>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.enregistreBeneficiaire("suiviAvance", true)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviAvance}>Réactiver la personne avec signaux faibles</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.enregistreBeneficiaire("suiviAvance", false)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviNormal}>Réactiver la personne sans signaux faibles</button>
                                                        </>
                                                    }
                                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>	
                                    {(securityService.isAdmin() || securityService.isResponsableStructure(dataService.getStructureById(this.props.beneficiaire.structure))) &&
                                        <>
                                            <a href="#" data-toggle="modal" data-target="#modal-suppressionbeneficiaire" className={"btn btn-annexe contour text-center w-100 attention suppression"}><i className="dripicons-trash"></i> Supprimer le compte</a>
                                            <div key="modal-suppressionbeneficiaire" id="modal-suppressionbeneficiaire" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title mt-0">Supprimer le compte de l'utilisateur</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>Merci d'indiquer le motif de la suppression du compte :</p>
                                                            <form>
                                                                <div className="form-group">													
                                                                    <select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
                                                                        <option value="depart">Ne fait plus partie des effectifs</option>
                                                                        <option value="rgpd">Demande explicite (RGPD)</option>
                                                                        <option value="autre">Autre</option>
                                                                    </select>
                                                                </div>
                                                            </form>
                                                            <p>L'ensemble des services de suivi et informations liés à <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-primary suppression" onClick={() => this.enregistreBeneficiaire("supprime", true)} data-dismiss="modal">Supprimer le compte de l'utilisateur</button>
                                                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }		
                </div>
                {modals}
                <div key="modal-sidebar-beneficiairenouvelleinteraction" id="modal-sidebar-beneficiairenouvelleinteraction" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Réinitialiser le compteur</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formInteractionValide()} onClick={() => this.ajouteInteraction()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormInteraction
                                    interaction={this.state.editingInteraction}
                                    beneficiaire={this.props.beneficiaire}
                                    modifie={(interaction) => this.setEditingInteraction(interaction)}
                                    scoreDisabled={true}
                                    categories={this.props.categoriesInteractions}
                                    origine="sidebarbeneficiaire"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
	}
}
  

export default SidebarBeneficiaire;