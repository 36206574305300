import HookEmail from '../../pages/HookEmail';
import Questionnaire from '../../pages/Questionnaire';
import HookQualificationAlerte from '../../pages/HookQualificationAlerte';
import PasswordOublie from '../../pages/PasswordOublie';
import PasswordReset from '../../pages/PasswordReset';
import PasswordCreation from '../../pages/PasswordCreation';
import CGU from '../../pages/CGU';
import CGUEmployeur from '../../pages/CGUEmployeur';
import PolitiqueConfidentialite from '../../pages/PolitiqueConfidentialite';
import PolitiqueConfidentialiteEmployeur from '../../pages/PolitiqueConfidentialiteEmployeur';
import PolitiqueConfidentialiteLaposte from '../../pages/PolitiqueConfidentialiteLaposte';
import PolitiqueConfidentialiteEmployeurLaposte from '../../pages/PolitiqueConfidentialiteEmployeurLaposte';
import MentionsInformationsConseiller from '../../pages/MentionsInformationsConseiller';
import PublicMentionsInformationsEmployeur from '../../pages/MentionsInformationsEmployeur';
import PublicMentionsInformationsEmployeurLaposte from '../../pages/MentionsInformationsEmployeurLaposte';
import HookConsentement from '../../pages/HookConsentement';
import Cookies from '../../pages/Cookies';
import MentionsLegales from '../../pages/MentionsLegales';

export default {
    PublicPasswordCreation: {
        component: PasswordCreation,
        path: '/compte/inscription/:token'
    },
    PublicPasswordOublie: {
        component: PasswordOublie,
        path: '/compte/mot-de-passe-oublie'
    },
    PublicPasswordReset: {
        component: PasswordReset,
        path: '/compte/mot-de-passe/:token'
    },
    PublicInscription: {
        component: HookEmail,
        path: '/compte/inscription'
    },
    PublicCGU: {
        component: CGU,
        path: '/conditions-generales-utilisation'
    },
    PublicCGUEmployeur: {
        component: CGUEmployeur,
        path: '/conditions-generales-utilisation-manager'
    },
    PublicMentionsInformationsConseiller: {
        component: MentionsInformationsConseiller,
        path: '/mentions-informations-accompagnant'
    },
    PublicMentionsInformationsEmployeur: {
        component: PublicMentionsInformationsEmployeur,
        path: '/mentions-informations-superieur-hierarchique'
    },
    PublicMentionsInformationsEmployeurLaposte: {
        component: PublicMentionsInformationsEmployeurLaposte,
        path: '/mentions-informations-superieur-hierarchique-la-poste'
    },
    PublicPolitiqueConfidentialite: {
        component: PolitiqueConfidentialite,
        path: '/politique-de-confidentialite'
    },
    PublicPolitiqueConfidentialiteEmployeur: {
        component: PolitiqueConfidentialiteEmployeur,
        path: '/politique-de-confidentialite-manager'
    },
    PublicPolitiqueConfidentialiteLaposte: {
        component: PolitiqueConfidentialiteLaposte,
        path: '/politique-de-confidentialite-la-poste'
    },
    PublicPolitiqueConfidentialiteEmployeurLaposte: {
        component: PolitiqueConfidentialiteEmployeurLaposte,
        path: '/politique-de-confidentialite-manager-la-poste'
    },
    PublicCookies: {
        component: Cookies,
        path: '/cookies'
    },
    PublicMentionsLegales: {
        component: MentionsLegales,
        path: '/mentions-legales'
    },
    PublicQuestionnaire: {
        component: Questionnaire,
        path: '/questionnaire/:questionnaireToken',
        acceptsMobile: true
    },
    PublicHookQualificationAlerte: {
        component: HookQualificationAlerte,
        path: '/alerte/:alerteComplexId/:qualification',
        acceptsMobile: true
    },
    PublicHookConsentement: {
        component: HookConsentement,
        path: '/consentement/:token',
        acceptsMobile: true
    },
    PublicHookEmail: {
        component: HookEmail,
        path: '/email/:instanceComplexId/:reponseComplexId',
        acceptsMobile: true
    }
}