import Api from '../api';
import storageKey from '../services/session';
import cookie from 'react-cookies';
import DataService from '../services/data';

const middleware = store => next => action => {
    console.log('middleware');
    console.log(action);
    let api = new Api();
    let helper = new Helper();

    if (action.type == "DATA_LOGIN") {
        api.login(action.payload.email, action.payload.password).then((json) => {
            console.log(json);
			if (json.code && json.code == 401) {
                if (json.erreur && json.erreur == "ip") {
                    if (action.payload.callbackNokVPN) {
                        action.payload.callbackNokVPN();
                    }
                }
                else if (json.erreur && json.erreur == "password-expire") {
                    if (action.payload.callbackNokPasswordExpire) {
                        action.payload.callbackNokPasswordExpire(json.data);
                    }
                }
                else if (json.erreur && json.erreur == "compte-inactif") {
                    if (action.payload.callbackNokCompteInactif) {
                        action.payload.callbackNokCompteInactif();
                    }
                }
                else {
                    if (action.payload.callbackNok) {
                        action.payload.callbackNok();
                    }
                }                
                cookie.save(storageKey, 0, {path: '/'});
                localStorage.removeItem(storageKey);
			}
			else {
                if (action.payload.callbackOk) {
                    action.payload.callbackOk();
                }
                cookie.save(storageKey, json.token, {path: '/'});
				if (action.payload.remember) {
					localStorage.setItem(storageKey, json.token);
                }
                action.payload.token = json.token;
				next(action);
			}
		}).catch(err => {
            console.log(err);
            if (action.payload.callbackErreur) {
                action.payload.callbackErreur();
            }
			this.setState({
				etat: "login-erreur",
				password: ""
            });
            
		});
    }
    
    if (action.type == "DATA_INIT") {
        
        api.getInitData().then((json) => {

            console.log(json);

            if (json.code && json.code == 401) {
                cookie.save(storageKey, 0, {path: '/'});
                localStorage.removeItem(storageKey);
                store.dispatch({type: 'DATA_CLEAR', payload: null});
            }
            else {
                let data = {
                    structures: [],
                    conseillers: [],
                    beneficiaires: [],
                    modalites: [],
                    questions: [],
                    bibliotheques: [],
                    notifications: [],
                    categoriesInteractions: [],
                    categoriesQuestions: [],
                    me : {}
                };
    
                if (Array.isArray(json.structures)) {
                    data.structures = json.structures;
                }
    
                if (Array.isArray(json.conseillers)) {
                    data.conseillers = json.conseillers;
                }
    
                if (Array.isArray(json.beneficiaires)) {
                    data.beneficiaires = json.beneficiaires;
                }
    
                data.questions = json.questions;
                data.bibliotheques = json.bibliotheques;
    
                if (Array.isArray(json.notifications)) {
                    data.notifications = json.notifications;
                }
    
                if (Array.isArray(json.categoriesInteractions)) {
                    data.categoriesInteractions = json.categoriesInteractions;
                }

                if (Array.isArray(json.categoriesQuestions)) {
                    data.categoriesQuestions = json.categoriesQuestions;
                }

                if (Array.isArray(json.modalites)) {
                    data.modalites = json.modalites;
                }
    
                data.me = json.utilisateur;
    
                data.timestamp = json.timestamp;
    
                action.payload = data;
                next(action);
            }
            
        }).catch((err) => {
            console.log(err);
        });
    }

    if (action.type == "DATA_UPDATES") {
        if (action.payload) {
            next(action);
        }
        else {
            api.getUpdatesData().then((json) => {
                if (json.code && json.code == 401) {
                    cookie.save(storageKey, 0, {path: '/'});
                    localStorage.removeItem(storageKey);
                    store.dispatch({type: 'DATA_CLEAR', payload: null});
                }
                else {
                    helper.traiteReponseApi(store, next, action, json);
                }                
            }).catch((err) => {
                console.log(err);
            });
        }   
    }

    if (action.type == 'DATA_PUT_STRUCTURE') {
        api.putStructure(action.payload.structure).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        });   
    }

    if (action.type == "DATA_POST_STRUCTURE") {
        api.postStructure(action.payload.structure).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_PUT_STRUCTURE_BENEFICIAIRE") {
        api.putStructureBeneficiaire(action.payload.structure, action.payload.beneficiaire).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        });
    }

    if (action.type == "DATA_PUT_STRUCTURE_CONSEILLER") {
        api.putStructureConseiller(action.payload.structure, action.payload.conseiller).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        });
    }

    if (action.type == "DATA_POST_BENEFICIAIRE") {
        api.postBeneficiaire(action.payload.beneficiaire).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_GET_BENEFICIAIRE") {
        api.getBeneficiaire(action.payload.beneficiaire).then((json) => {
            console.log(json)
            //helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_POST_CONSEILLER") {
        api.postConseiller(action.payload.conseiller).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_POST_UTILISATEUR") {
        api.postUtilisateur(action.payload.utilisateur).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_POST_PROCHE") {
        api.postProche(action.payload.proche).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_COPIE_QUESTIONS") {
        api.copieQuestions(action.payload.questions, action.payload.destinataires).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_AJOUTE_QUESTIONSDEFAUT") {
        api.ajouteQuestionsDefaut(action.payload.questions, action.payload.destinataire).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_POST_PROFIL") {
        api.postProfil(action.payload.profil).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_COPIE_PROFILQUESTIONS") {
        api.copieProfilQuestions(action.payload.questions, action.payload.profils).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }
    
    if (action.type == "DATA_AJOUTE_PROFILQUESTIONSDEFAUT") {
        api.ajouteProfilQuestionsDefaut(action.payload.questions, action.payload.profil).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_POST_NOTIFICATIONS") {
        api.postNotifications(action.payload.notifications).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == 'DATA_PUT_QUESTION') {
        api.putQuestion(action.payload.question).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        });
    }

    if (action.type == "DATA_POST_QUESTION") {
        api.postQuestion(action.payload.question).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == 'DATA_PUT_QUESTIONCATEGORIE') {
        api.putQuestionCategorie(action.payload.categorie).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        });
    }

    if (action.type == "DATA_POST_QUESTIONCATEGORIE") {
        api.postQuestionCategorie(action.payload.categorie).then((json) => {
            console.log(json)
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == 'DATA_PUT_BIBLIOTHEQUE') {
        api.putBibliotheque(action.payload.bibliotheque).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        });
    }

    if (action.type == "DATA_POST_BIBLIOTHEQUE") {
        console.log(action.payload.bibliotheque)
        api.postBibliotheque(action.payload.bibliotheque).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }

    if (action.type == "DATA_CHANGE_BIBLIOTHEQUE") {
        api.changeBibliotheque(action.payload.personne, action.payload.bibliotheque).then((json) => {
            helper.traiteReponseApi(store, next, action, json);
        }).catch((e) => {
            console.log(e);
        });
    }


    if (action.type == 'DATA_CLEAR') {
        next(action);
    }


    if (action.type == 'IHM_URLCIBLE') {
        next(action);
    }

    if (action.type == 'IHM_ACCUEIL_VUE') {
        next(action);
    }

    if (action.type == 'IHM_ACCUEIL_TRI') {
        next(action);
    }

    if (action.type == 'IHM_BENEFICIAIRE_TAB') {
        next(action);
    }

    if (action.type == 'IHM_STRUCTURE_TAB') {
        next(action);
    }

    if (action.type == 'IHM_HISTORY_PUSH') {
        next(action);
    }

    if (action.type == 'DATA_SET_BENEFICIAIRE') {
        next(action);
    }
};

class Helper {
    traiteReponseApi(store, next, action, json) {

        if (json.erreur) {
            if (action.payload.callbackErreur) {
                //action.payload.callbackErreur();
                alert("Une erreur est survenue. Vérifiez par exemple que l'adresse email saisie n'est pas déjà utilisée.");
            }
            return;
        }

        let dataService = new DataService();

        let structures = {
            ajouts: [],
            modifications: [],
            suppressions: []
        };

        let beneficiaires = {
            ajouts: [],
            modifications: [],
            suppressions: []
        };

        let conseillers = {
            ajouts: [],
            modifications: [],
            suppressions: []
        };

        let questions = {};

        let bibliotheques = {
            ajouts: [],
            modifications: [],
            suppressions: []
        };

        let notifications = {
            ajouts: [],
            modifications: [],
            suppressions: []
        };

        let categoriesQuestions = []

        // structures
        if (json.structures) {
            json.structures.map((structure) => {
                if (structure.supprime) {
                    structures.suppressions.push(structure);
                }
                else if (dataService.getStructureById(structure.id)) {
                    structures.modifications.push(structure);
                }
                else {
                    structures.ajouts.push(structure);
                }
            });
        }

        // conseillers
        if (json.conseillers) {
            json.conseillers.map((conseiller) => {
                if (conseiller.supprime) {
                    conseillers.suppressions.push(conseiller);
                }
                else if (dataService.getConseillerById(conseiller.id)) {
                    conseillers.modifications.push(conseiller);
                }
                else {
                    conseillers.ajouts.push(conseiller);
                }
            });
        }

        // beneficiaires
        if (json.beneficiaires) {
            json.beneficiaires.map((beneficiaire) => {
                if (beneficiaire.supprime) {
                    beneficiaires.suppressions.push(beneficiaire);
                }
                else if (dataService.getBeneficiaireById(beneficiaire.id)) {
                    beneficiaires.modifications.push(beneficiaire);
                }
                else {
                    beneficiaires.ajouts.push(beneficiaire);
                }
            });
        }

        // questions
        if (json.questions) {
            Object.getOwnPropertyNames(json.questions).map((property) => {
                questions[property] = {
                    ajouts: [],
                    modifications: [],
                    suppressions: []
                }
                console.log(json.questions);
                json.questions[property].map((question) => {
                    if (question.supprime) {
                        questions[property].suppressions.push(question);
                    }
                    else if (dataService.getQuestionById(question.id)) {
                        questions[property].modifications.push(question);
                    }
                    else {
                        questions[property].ajouts.push(question);
                    }
                });
            })
        }

        // bibliothèques
        console.log(json.bibliotheques)
        if (json.bibliotheques) {
            json.bibliotheques.map((bibliotheque) => {
                if (bibliotheque.supprime) {
                    bibliotheques.suppressions.push(bibliotheque);
                }
                else if (dataService.getBibliothequeById(bibliotheque.id)) {
                    bibliotheques.modifications.push(bibliotheque);
                }
                else {
                    bibliotheques.ajouts.push(bibliotheque);
                }
            });
        }

        // notifications
        if (json.notifications) {
            json.notifications.map((notification) => {
                if (notification.supprime) {
                    notifications.suppressions.push(notification);
                }
                else if (dataService.getNotificationById(notification.id)) {
                    notifications.modifications.push(notification);
                }
                else {
                    notifications.ajouts.push(notification);
                }
            });
        }

        if (json.categoriesQuestions) {
            categoriesQuestions = json.categoriesQuestions
        }

        let payload = {
            structures: structures,
            conseillers: conseillers,
            beneficiaires: beneficiaires,
            questions: questions,
            bibliotheques: bibliotheques,
            notifications: notifications,
            timestamp: (json.timestamp ? json.timestamp : 0),
            categoriesQuestions: categoriesQuestions,

            me: json.utilisateur,
        }

        if (action.type == "DATA_UPDATES") {
            action.payload = payload;
            next(action);
        }
        else {
            store.dispatch({ type: 'DATA_UPDATES', payload: payload });
        }
        
    }
}

export default middleware;
