import storageKey from '../services/session';
import DataService from '../services/data';
import cookie from 'react-cookies';
import moment from 'moment'
import axios from "axios";

const endpoint = process.env.APP_API_URL

const headers = () => {

    let dataService = new DataService();        

    return new Headers({
        Authorization: 'Bearer ' + cookie.load(storageKey),
        "Content-Type": "application/json",
        "X-Timestamp": dataService.getTimestamp()
    });
}

const config = () => {

    let dataService = new DataService()

    return {
        headers: {
            Authorization: 'Bearer ' + cookie.load(storageKey),
            "Content-Type": "application/json",
            "X-Timestamp": dataService.getTimestamp()
        },
        //withCredentials: true
    }
}

export const getSecuriteLogs = (q) => {
    let url = "securite/logs" + (q.trim() != "" ? "?q=" + q.trim() : "")

    return axios.get(endpoint + url, config())
}

export const getRgpdLogs = () => {
    let url = "rgpd/logs"

    return axios.get(endpoint + url, config())
}

export const postRgpdLog = (log) => {
    let url = "rgpd/logs" + (log.id ? '/' + log.id : '')

    return axios.post(endpoint + url, log, config())
}

export const getQuestionsDefaut = (criteres, admin = false) => {

    let params = new URLSearchParams()
    params.append('motscles', criteres.motscles)
    params.append('domaines', criteres.domaines)
    params.append('categories', criteres.categories)
    params.append('cibles', criteres.cibles)
    params.append('communications', criteres.communications)
    console.log(criteres)

    let url = (admin ? "admin/" : "") + "questions?" +  params.toString()

    return axios.get(endpoint + url, config())
}

export const postQuestionDefaut = (question) => {
    let url = "/admin/questions" + (question.id !== null ? ("/" + question.id) : "")   
    
    return axios.post(endpoint + url, question, config())
}

export const getPreparationSynchronisationProfil = (profil) => {
    let url = "/profils/" + profil.id + "/synchronisation/preparation"

    return axios.get(endpoint + url, config())
}

export const postSynchronisationProfil = (profil, synchronisation) => {
    let url = "/profils/" + profil.id + "/synchronisation"

    return axios.post(endpoint + url, synchronisation, config())
}

export const copieQuestions = (questions, destinataires) => {
    let url = "questions/copie"

    return axios.post(endpoint + url, {questions, destinataires}, config())
}

export const getQuestionsSurveillance = () => {
    let url = "questions/surveillance"

    return axios.get(endpoint + url, config())
}

export const getBeneficiaire = (beneficiaireId) => {
    let url = "beneficiaires/" + beneficiaireId

    return axios.get(endpoint + url, config())
}

export const getBeneficiaireBibliotheque = (beneficiaireId) => {
    let url = "beneficiaires/" + beneficiaireId + "/bibliotheque"

    return axios.get(endpoint + url, config())
}