import React, { Component } from 'react';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  history: props.route.history
	}
  }

class Loading extends Component {

	componentDidMount() {
		console.log("loading did mount");
		document.body.classList.remove("bg-login");
	}

	componentDidMount() {
		console.log("loading did update");
		document.body.classList.remove("bg-login");
	}

	render() {
		return(
			<div style={{position:"fixed", top:"calc(50vh - 10px)", left:"calc(50vw - 10px)"}}>
				<div id="page-loading">
					<div className="three-balls">
						<div className="ball ball1"></div>
						<div className="ball ball2"></div>
						<div className="ball ball3"></div>
					</div>
				</div>
			</div>
		);
	}
}

export default Loading;