import store from '../store';

import DataService from './data';

export default class RouteService {

    getPreviousLocation() {

        console.log(store.getState().ihm.history);

        if (store.getState().ihm.history.length > 1) {
            return (store.getState().ihm.history[store.getState().ihm.history.length - 2]);
        }
        return null;
    }

    getLocationLibelle(location) {

        if (location == null || location.pathname == "/") {
            return 'Retourner à l\'accueil';
        }

        if (location.pathname == "/activite") {
            return 'Retourner à mon suivi d\'activité';
        }

        let pathname = location.pathname.substr(1);
        let tabPathname = pathname.split('/');

        let dataService = new DataService();

        console.log(store.getState().ihm.history);
        console.log(location);
        console.log(tabPathname);

        if (tabPathname[0] == "beneficiaires") {
            let beneficiaire = dataService.getBeneficiaireById(tabPathname[1]);
            if (beneficiaire) {
                if (tabPathname.length > 2) {
                    if (tabPathname[2] == "proches") {
                        let proche = dataService.getProcheByBeneficiaireAndId(beneficiaire, tabPathname[3]);
                        if (proche) {
                            return 'Retourner à ' + proche.prenom + ' ' + proche.nom;
                        }
                        else {
                            return this.getLocationLibelle(null);
                        }
                    }
                }
                return 'Retourner à ' + beneficiaire.prenom + ' ' + beneficiaire.nom;
            }
            else {
                return this.getLocationLibelle(null);
            }
        }

        if (tabPathname[0] == "conseillers") {
            let conseiller = dataService.getConseillerById(tabPathname[1]);
            if (conseiller) {
                return 'Retourner à ' + conseiller.prenom + ' ' + conseiller.nom;
            }
            else {
                return this.getLocationLibelle(null);
            }
        }

        if (tabPathname[0] == "structures") {
            let structure = dataService.getStructureById(tabPathname[1]);
            if (structure) {
                return 'Retourner à ' + structure.nom;
            }
            else {
                return this.getLocationLibelle(null);
            }
        }

        return this.getLocationLibelle(null);
    }
}