import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import Footer from '../components/Footer';

import DataService from '../services/data';
import CalendarService from '../services/calendar';

import Api from '../api';
import { getQuestionsDefaut, getQuestionsSurveillance, getRgpdLogs, getSecuriteLogs, postQuestionDefaut, postRgpdLog } from '../api/axios';

import FormStructure from '../components/Forms/FormStructure';
import FormBibliotheque from '../components/Forms/FormBibliotheque';
import TabQuestions from '../components/Superadmin/TabQuestions';
import Bibliotheque from '../components/Superadmin/Bibliotheque';
import { getTerme } from '../services/contextualisation';
import InfobulleRGPD from '../components/InfobulleRGPD';
import FormQuestionDefaut from '../components/Forms/FormQuestionDefaut';
import { questionValide } from '../services/validation';


const mapStateToProps = (state, props) => {
	return {
	  data: state.data,
	  ihm: state.ihm,
	}
  }

class Superadmin extends Component {

	constructor(props) {
		super(props);
		this.state = {
			domaine: 'accompagnement',
			editingStructure: null,
			editingBibliotheque: null,
			editingCategorie: null,
			editingQuestion: null,
			editingQuestionDeclenchee: false,
			structuresSelectionnees: [],
			questionsSelectionnees: [],
			bibliothequeCourante: this.props.data.bibliotheques.filter(b => b.domaine == 'accompagnement').length > 0 ? this.props.data.bibliotheques.filter(b => b.domaine == 'accompagnement')[0] : null,
			categoriesQuestions: JSON.parse(JSON.stringify(this.props.data.categoriesQuestions)),
			questions: [],
			statistiquesDebut: moment().startOf('year').toDate(),
			statistiquesFin: moment().endOf('year').toDate(),
			securiteLogs: null,			
			rechercheSecuriteLogs: '',
			rechercheQuestions: {
				motscles: '',
				categories: [],
				cibles: [],
				domaines: [],
				communications: []
			},
			rgpdLogs: null,
			editingRgpd: {
				id: null,
				commentaire: '',
				date: new Date(),
				personne: '',
				utilisateur: '',
				autre: '',
				type: 'acces',
				statut: 'non-traite',
				dateTraitement: null,
				action: ''
			},
			removingRgpd: null,
			surveillance: []
		}

		this.domaines = {
			accompagnement: 'Accompagnement et handicap',
			entreprise: 'Entreprise et handicap', 
			prevention: 'Prévention et RPS'
		}	

		this.cibles = {
			beneficiaire: 'Utilisateur',
			'proche-superieur': 'Supérieur hiérarchique'
		}
	}

	componentDidMount() {
		console.log("Superadmin did mount");

		this.bindModals();
		this.bindSelect2();

		console.log(this.props.location);
		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});		

		this.chercheSecuriteLogs()
		this.chercheRgpdLogs()	
		this.chercheQuestions()		
		
		setTimeout(() => {
			this.setState({})
		}, 100)

		getQuestionsSurveillance()
		.then(res => {
			console.log(res)
			this.setState({
				surveillance: res.data
			})
		})
		.catch(err => {
			console.log(err.response)
		})

		const params = new URLSearchParams(this.props.location.search)
		if (params.get('tab1')) {
			const link = document.querySelector('[href="#' + params.get('tab1') + '"')
			if (link) {
				link.click()
			}
		}
		if (params.get('tab2')) {
			const link = document.querySelector('[href="#' + params.get('tab2') + '"')
			if (link) {
				link.click()
			}
		}
	}

	componentDidUpdate(prevProps) {
		console.log("Superadmin did update");

		this.bindModals();
		//this.bindSelect2();

		if (this.props.data.bibliotheques != prevProps.data.bibliotheques && prevProps.data.bibliotheques.length > 0) {			
			let bibliothequeCourante = null
			if (this.props.data.bibliotheques.length > prevProps.data.bibliotheques.length) {
				for (let b of this.props.data.bibliotheques) {
					if (bibliothequeCourante == null || b.id > bibliothequeCourante.id) {
						bibliothequeCourante = b						
					}
				}				
			}
			else if (this.props.data.bibliotheques.length < prevProps.data.bibliotheques.length) {
				if (this.props.data.bibliotheques.length > 0) {
					bibliothequeCourante = this.props.data.bibliotheques[0]
				}				
			}
			else if (this.state.bibliothequeCourante) {				
				for (let b of this.props.data.bibliotheques) {
					if (b.id == this.state.bibliothequeCourante.id) {
						bibliothequeCourante = b						
					}
				}				
			}	
			this.setState({
				bibliothequeCourante: bibliothequeCourante
			})		
		}

		if (this.props.data.categoriesQuestions != prevProps.data.categoriesQuestions) {
			this.setState({
				categories: JSON.parse(JSON.stringify(this.props.data.categoriesQuestions))
			})
		}

		// recherche individu rgpd
		let dataService = new DataService()
		let items = [];
		this.props.data.beneficiaires.filter(beneficiaire => !beneficiaire.supprime).map((beneficiaire) => {
			let structure = dataService.getStructureById(beneficiaire.structure)
			items.push(
				{id: 'personne-' + beneficiaire.id, name:(beneficiaire.prenom + " " + beneficiaire.nom + " (utilisateur/salarié, " + structure.nom + ")")}
			)
			beneficiaire.proches.map((proche) => {
				items.push(
					{id: 'personne-' + proche.id, name:(proche.prenom + " " + proche.nom + " (proche de " + beneficiaire.prenom + " " + beneficiaire.nom + ", " + structure.nom + ")")}
				)	
			})
		});
		this.props.data.conseillers.filter(conseiller => !conseiller.supprime).map((conseiller) => {
			let structure = dataService.getStructureById(conseiller.structure)
			items.push(
				{id: 'utilisateur-' + conseiller.id, name:(conseiller.prenom + " " + conseiller.nom + " (accompagnant/référent, " + structure.nom + ")")}
			)
		})
		this.props.data.beneficiaires.filter(beneficiaire => !beneficiaire.supprime).map((beneficiaire) => {
			
			
		});
		window.jQuery("#input-recherche-rgpd").typeahead({
			source: items,
			items: 5,
			afterSelect: (s) => {
				if (s.id.indexOf('personne-') === 0) {
					this.state.editingRgpd.personne = s.id.replace('personne-', '')
					this.state.editingRgpd.utilisateur = ''
					this.state.editingRgpd.autre = ''
					this.setState({
						editingRgpd: this.state.editingRgpd
					})
				}
				if (s.id.indexOf('utilisateur-') === 0) {
					this.state.editingRgpd.utilisateur = s.id.replace('utilisateur-', '')
					this.state.editingRgpd.personne = ''
					this.state.editingRgpd.autre = ''
					this.setState({
						editingRgpd: this.state.editingRgpd
					})
				}				
			}
		});		
	}

	bindModals() {
        window.jQuery("#modal-nouvellestructure").off('hidden.bs.modal');
		window.jQuery("#modal-nouvellestructure").on('hidden.bs.modal', () => {
			this.setState({
				editingStructure: null
			});
		});
		window.jQuery("#modal-nouvellebibliotheque").off('hidden.bs.modal');
		window.jQuery("#modal-nouvellebibliotheque").on('hidden.bs.modal', () => {
			this.setState({
				editingBibliotheque: null
			});
		});
		window.jQuery("#modal-modifbibliotheque").off('hidden.bs.modal');
		window.jQuery("#modal-modifbibliotheque").on('hidden.bs.modal', () => {
			this.setState({
				editingBibliotheque: null
			});
		});

		window.jQuery("#modal-rgpd").off('hidden.bs.modal');
		window.jQuery("#modal-rgpd").on('hidden.bs.modal', () => {
			document.getElementById('input-recherche-rgpd').value = ''
		})

		{this.props.data.categoriesQuestions.map((categorie) => {
			window.jQuery("#modal-modifcategorie-" + categorie.id).off('hidden.bs.modal');
			window.jQuery("#modal-modifcategorie-" + categorie.id).on('hidden.bs.modal', () => {
				this.setState({
					editingCategorie: null
				});
			});
		})}
		window.jQuery("#modal-nouvellecategorie").off('hidden.bs.modal');
		window.jQuery("#modal-nouvellecategorie").on('hidden.bs.modal', () => {
			this.setState({
				editingCategorie: null
			});
		});
		window.jQuery("#modal-questiondefaut").off('hidden.bs.modal');
		window.jQuery("#modal-questiondefaut").on('hidden.bs.modal', () => {
			this.setState({
				editingQuestion: null
			});
		});
	}
	
	bindSelect2() {
        window.jQuery("#filtre-questions-categories").off('change');
        window.jQuery("#filtre-questions-categories").on('change', (e) => {
            console.log(window.jQuery("#filtre-questions-categories").val());
			this.state.rechercheQuestions.categories = window.jQuery("#filtre-questions-categories").val()
            this.setState({
                rechercheQuestions: this.state.rechercheQuestions				
            }); 
			// this.chercheQuestions()           
        });		

		window.jQuery("#filtre-questions-domaines").off('change');
        window.jQuery("#filtre-questions-domaines").on('change', (e) => {
            console.log(window.jQuery("#filtre-questions-domaines").val());
			this.state.rechercheQuestions.domaines = window.jQuery("#filtre-questions-domaines").val()
            this.setState({
                rechercheQuestions: this.state.rechercheQuestions				
            }); 
			// this.chercheQuestions()           
        });

		window.jQuery("#filtre-questions-cibles").off('change');
        window.jQuery("#filtre-questions-cibles").on('change', (e) => {
            console.log(window.jQuery("#filtre-questions-cibles").val());
			this.state.rechercheQuestions.cibles = window.jQuery("#filtre-questions-cibles").val()
            this.setState({
                rechercheQuestions: this.state.rechercheQuestions				
            }); 
			// this.chercheQuestions()           
        });

		window.jQuery("#filtre-questions-communications").off('change');
        window.jQuery("#filtre-questions-communications").on('change', (e) => {
            console.log(window.jQuery("#filtre-questions-communications").val());
			this.state.rechercheQuestions.communications = window.jQuery("#filtre-questions-communications").val()
            this.setState({
                rechercheQuestions: this.state.rechercheQuestions				
            }); 
			// this.chercheQuestions()           
        });

		console.log(window.jQuery("#filtre-questions-cibles").parent().find('.select2-container'))
		setTimeout(() => {
			console.log(window.jQuery("#filtre-questions-cibles").parent().find('.select2-selection__rendered').width())
		}, 100)
    }

	toggleStructure(structure) {
        let structuresSelectionnees = this.state.structuresSelectionnees;
        if (structuresSelectionnees.includes(structure.id)) {
            structuresSelectionnees.splice(structuresSelectionnees.indexOf(structure.id), 1);
        }
        else {
            structuresSelectionnees.push(structure.id);
        }
        this.setState({
            structuresSelectionnees: structuresSelectionnees
        });
    }

    toggleAllStructures() {
        let structuresSelectionnees = [];
        if (this.state.structuresSelectionnees.length == this.props.data.structures.length) {
            structuresSelectionnees = [];
        }
        else {
            this.props.data.structures.map((structure) => {
                structuresSelectionnees.push(structure.id)
            });
        }
        this.setState({
            structuresSelectionnees: structuresSelectionnees
        })
	}

	setEditingStructure(structure) {
		console.log(structure);
        this.setState({
            editingStructure: structure
        });
	}
	
	changeEditingStructure(champ, val) {
		let structure = this.state.newStructure;
		if (champ == "nom") {	
			structure.nom = val;
		}
		if (champ == "adresse-adresse1") {	
			structure.adresse.adresse1 = val;
		}
		if (champ == "adresse-codePostal") {	
			structure.adresse.codePostal = val;
		}
		if (champ == "adresse-ville") {	
			structure.adresse.ville = val;
		}
		this.setState({
			editingStructure: structure
		})
	}

	formEditingStructureValide() {
        return (
			this.state.editingStructure
			&& this.state.editingStructure.nom != ""
			&& this.formConseillerValide(this.state.editingStructure.newResponsable)
        );
	}

	formConseillerValide(conseiller) {
        return (
			conseiller
			&& conseiller.nom != ""
            && conseiller.prenom != ""
			&& /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(conseiller.email)
			&& conseiller.checkUniqueEmail
			&& (conseiller.telephone == "" || /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(conseiller.telephone))
        );
	}
	
	changeStructure(s, champ, val) {
		let structure = null;
		if (champ == "actif") {
			structure = {
				id: s.id,
				classe: s.classe,
				actif: val
			};
		}
		if (champ == "supprime") {
			structure = {
				id: s.id,
				classe: s.classe,
				supprime: val
			};
		}
		if (structure) {
			this.enregistreStructure(structure);
		}
	}

	enregistreStructure(structure) {
		if (structure.id) {
			this.props.dispatch({type: 'DATA_POST_STRUCTURE', payload: {structure: structure}});
		}
		else {
			structure.domaine = this.state.domaine
			this.props.dispatch({type: 'DATA_PUT_STRUCTURE', payload: {structure: structure}});
		}
	}

	structuresSelectionneesActives() {
        let dataService = new DataService();
        if (this.state.structuresSelectionnees.length == 0) return null;
        
        let premiereStructure = dataService.getStructureById(this.state.structuresSelectionnees[0]);
        if (!premiereStructure) return null;
        
        for (let i = 1 ; i < this.state.structuresSelectionnees.length ; i++) {
            let structure = dataService.getStructureById(this.state.structuresSelectionnees[i]);
            if (premiereStructure.actif != structure.actif) {
                return null;
            }
        }
        return premiereStructure.actif;
	}
	
	supprimeStructures() {
        let dataService = new DataService();
        this.state.structuresSelectionnees.map(s => {
            let structure = dataService.getStructureById(s);
            if (structure) {
                this.changeStructure(structure, "supprime", true);
            }
        });
        this.setState({
            structuresSelectionnees: []
        })
    }

    toggleActivationStructures() {
        let dataService = new DataService();
        this.state.structuresSelectionnees.map(s => {
            let structure = dataService.getStructureById(s);
            if (structure) {
                this.changeStructure(structure, "actif", !structure.actif);
            }
        });
        this.setState({
            structuresSelectionnees: []
        })
    }

	chercheQuestions() {
		this.setState({
			questionsDefaut: []
		})

		console.log(this.state.rechercheQuestions)

		getQuestionsDefaut(this.state.rechercheQuestions, true)
		.then(res => {
			console.log(res)	
			this.setState({
				questions: res.data.questions
			})		
		})
		.catch(err => {
			console.log(err.response)
		})
	}

	formQuestionDefautValide() {
        /*return (
            this.state.editingQuestion
            && this.state.editingQuestion.libelle != ""
            && (
                this.state.editingQuestion.type == "liste"
                || (
                    this.state.editingQuestion.type == "gradient"
                    && this.state.editingQuestion.parametres.gradient.min
                    && this.state.editingQuestion.parametres.gradient.max
                    && this.state.editingQuestion.parametres.gradient.min < this.state.editingQuestion.parametres.gradient.max
                )
                || this.state.editingQuestion.type == "information"
            )
            && (
                this.state.editingQuestion.type == "information"
                || (
                    this.state.editingQuestion.reponses.length > 0
                    && this.formReponsesValide()
                )
            )            
        );*/

		console.log(this.state.editingQuestion)
		console.log('question valide', questionValide(this.state.editingQuestion))

		return questionValide(this.state.editingQuestion)
    }

    formReponsesValide() {
        let valide = true;
        if (this.state.editingQuestion && this.state.editingQuestion.type == "liste") {
            this.state.editingQuestion.reponses.map((reponse) => {
                if (
                    reponse.libelle == ""
                ) {
                    valide = false;
                }
            })
        }
        return valide;
    }

	setEditingQuestion(question) {
		console.log("setediting question", question)
		this.setState({
            editingQuestion: question
        });
	}

	enregistreQuestion() {
		console.log(this.state.editingQuestion)
		if (this.state.editingQuestion.id !== null && this.state.editingQuestion.id < 0) {
			this.state.editingQuestion.id = null
		}
		postQuestionDefaut(this.state.editingQuestion)
		.then(res => {
			console.log(res)
			//this.chercheQuestions()
		})
		.catch(err => {
			console.log(err.message)
		})
	}

	supprimeQuestion() {
		if (this.state.editingQuestion == null) return

		this.state.editingQuestion.supprime = true
		this.enregistreQuestion()
	}

	setEditingBibliotheque(bibliotheque) {
		console.log(bibliotheque);
        this.setState({
            editingBibliotheque: bibliotheque
        });
	}

	formEditingBibliothequeValide() {
        return (
			this.state.editingBibliotheque
			&& this.state.editingBibliotheque.nom != ""
			&& this.state.editingBibliotheque.cible != ""			
        );
	}

	enregistreBibliotheque(bibliotheque) {
		if (bibliotheque.id) {
			this.props.dispatch({type: 'DATA_POST_BIBLIOTHEQUE', payload: {bibliotheque: bibliotheque}});			
		}
		else {
			bibliotheque.domaine = this.state.domaine
			this.props.dispatch({type: 'DATA_PUT_BIBLIOTHEQUE', payload: {bibliotheque: bibliotheque}});
		}		
	}

	getStructureSuivi(structure) {
		let api = new Api();

		api.getStructureSuivi(structure).then((stream) => {
            let reader = stream.getReader();
            let result = [];   
            let self = this;
            reader.read().then(function processFile({done, value}) {
                console.log(done);
                if (done) {
                    let blob = new Blob(result, {type: "application/pdf"});
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.setAttribute("target", "_blank");
					// commenter cette ligne pour ouvrir dans nouveau tab, mais impossible d'avoir le bon nom					
                    a.setAttribute("download", (structure.nom + " - " + moment().format("DD-MM-YYYY") + ".xlsx"));  
                    a.click();
                    return;
                }
                result = result.concat(value);
                reader.read().then(processFile);
            });
           
        }).catch(err => {
            console.log(err);
        });
	}

	getStatistiques() {
		let api = new Api();

		api.getStatistiques("admin-" + this.state.domaine, this.state.statistiquesDebut, this.state.statistiquesFin).then((stream) => {
            let reader = stream.getReader();
            let result = [];   
            let self = this;
            reader.read().then(function processFile({done, value}) {
                console.log(done);
                if (done) {
                    let blob = new Blob(result, {type: "application/pdf"});
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.setAttribute("target", "_blank");
					// commenter cette ligne pour ouvrir dans nouveau tab, mais impossible d'avoir le bon nom					
                    a.setAttribute("download", ("Statistiques " + self.domaines[self.state.domaine] + " - " + moment().format("DD-MM-YYYY") + ".xlsx"));  
                    a.click();
                    return;
                }
                result = result.concat(value);
                reader.read().then(processFile);
            });
           
        }).catch(err => {
            console.log(err);
        });
	}

	getBibliotheque(bibliothequeId) {
		for (let b of this.props.data.bibliotheques) {
			if (b.id == bibliothequeId) {
				return b
			}
		}
		return null
	}

	supprimeBibliotheque() {
		if (!this.state.bibliothequeCourante) return

		this.state.bibliothequeCourante.supprime = true

		this.enregistreBibliotheque(this.state.bibliothequeCourante)
	}

	chercheSecuriteLogs() {
		this.setState({
			securiteLogs: null			
		})

		getSecuriteLogs(this.state.rechercheSecuriteLogs)
		.then(res => {
			console.log(res)
			this.setState({
				securiteLogs: res.data
			})
		})
		.catch(err => {
			console.log(err.response)
		})
	}
	chercheRgpdLogs() {
		this.setState({
			rgpdLogs: null			
		})

		getRgpdLogs()
		.then(res => {
			console.log(res)
			this.setState({
				rgpdLogs: res.data
			})
		})
		.catch(err => {
			console.log(err.response)
		})
	}

	postRgpd() {

		this.setState({
			rgpdLogs: null
		})

		postRgpdLog(this.state.editingRgpd)
		.then(res => {
			console.log(res)
			this.chercheRgpdLogs()
		})
		.catch(err => {
			console.log(err.response)
			this.chercheRgpdLogs()
		})
	}	

	removeRgpd() {

		this.setState({
			rgpdLogs: null
		})

		this.state.removingRgpd.supprime = true

		postRgpdLog(this.state.removingRgpd)
		.then(res => {
			console.log(res)
			this.chercheRgpdLogs()
		})
		.catch(err => {
			console.log(err.response)
			this.chercheRgpdLogs()
		})
	}	

	enregistreCategorie(categorie) {		
		console.log(categorie)
		if (categorie.id) {
			this.props.dispatch({type: 'DATA_POST_QUESTIONCATEGORIE', payload: {categorie: categorie}});			
		}
		else {
			this.props.dispatch({type: 'DATA_PUT_QUESTIONCATEGORIE', payload: {categorie: categorie}});
		}		
	}

	prepareQuestionDefaut(question, declenchee = false) {
		question.id = -question.id
		if (declenchee === false) {
			question.classe = "QuestionDefaut"
		}		
		question.reponses.map(reponse => {
			reponse.id = null
			if (reponse.questionDeclenchee) {
				this.prepareQuestionDefaut(reponse.questionDeclenchee, true)
			}			
		})
		question.reponseDefaut.id = null
		question.cibles = []
		question.categories = []
		question.tutoiement = ''
		if (question.destinataire) {
			if (question.destinataire.classe === 'Profil') {
				question.cibles = [question.destinataire.cible]
			}
			else {				
				question.cibles = [question.destinataire.classe === 'Beneficiaire' ? 'beneficiaire' : 'proche-superieur']
			}
			question.domaines = [question.destinataire.structure.domaine]
			
			delete question.destinataire
		}		
		delete question.termine
	}

	afficheContenuQuestionSurveillance(question) {
		return (<>
			{question.libelle}<br />
			{question.type == "liste" &&
				<>
					{question.reponses.filter((reponse) => !reponse.supprime).sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, index) =>
						<React.Fragment key={"question-previsualisation-reponse-" + reponse.id}>
							"{reponse.libelle}" Tapez {reponse.cle.code ? reponse.cle.code : (index + 1)} {reponse.cle.icone ? ("ou " + reponse.cle.icone) : ""}
							{reponse.action === 'question' && reponse.questionDeclenchee &&
								<>
									<div className='pl-3'>
										--&gt; Question déclenchée :<br />
										{this.afficheContenuQuestionSurveillance(reponse.questionDeclenchee)}
									</div>
								</>
							}
							{index != question.reponses.length - 1 && !(reponse.action === 'question' && reponse.questionDeclenchee) && <br />}
						</React.Fragment>
					)}
				</>
			}
			{question.type == "gradient" &&
				<>
					Tapez une valeur entre {question.parametres.gradient.min} et {question.parametres.gradient.max}.
					{question.reponses.map((reponse, indexReponse) => {
						if (reponse.action != 'question') return <></>
						if (reponse.questionDeclenchee == null) return <></>
						return (
							<React.Fragment key={'reponse-' + indexReponse}>
								<div className='pl-3'>
										indexReponse + 1 --&gt; Question déclenchée :<br />
										{this.afficheContenuQuestionSurveillance(reponse.questionDeclenchee)}
									</div>
							</React.Fragment>
						)
					})}
				</>
			}	
			</>
		)
	}

	toggleQuestion(question) {
        let questionsSelectionnees = this.state.questionsSelectionnees;
        if (questionsSelectionnees.includes(question.id)) {
            questionsSelectionnees.splice(questionsSelectionnees.indexOf(question.id), 1);
        }
        else {
            questionsSelectionnees.push(question.id);
        }
        this.setState({
            questionsSelectionnees: questionsSelectionnees
        });
    }

	removeId(object) {	
		for(let prop in object) {
			if (prop === 'id')
			object[prop] = null;
			else if (typeof object[prop] === 'object')
			this.removeId(object[prop]);
		}
	}

	supprimeQuestions() {
        this.state.questionsSelectionnees.map((q) => {
			this.state.questions.map(question => {
				if (question.id == q) {
					question.supprime = true
					postQuestionDefaut(question)
					.then(res => {
						console.log(res)
						// this.chercheQuestions()
					})
					.catch(err => {
						console.log(err.message)
					})
				}
			})
		})		
        this.setState({
            questionsSelectionnees: []
        })
    }

	dupliqueQuestions() {
        this.state.questionsSelectionnees.map((q) => {
			this.state.questions.map(question => {
				if (question.id == q) {
					let newQuestion = JSON.parse(JSON.stringify(question))
					this.removeId(newQuestion)
					newQuestion.libelle += " - Copie"
					postQuestionDefaut(newQuestion)
					.then(res => {
						console.log(res)
						// this.chercheQuestions()
					})
					.catch(err => {
						console.log(err.message)
					})
				}
			})
		})		
        this.setState({
            questionsSelectionnees: []
        })
    }

	reordonneQuestions(oldIndex, newIndex) {
        console.log("reordonne");
        if (oldIndex != newIndex) {                                    
			this.state.questions.map((q, iQuestion) => {
				if (q.numero == oldIndex) {
					this.state.questions[iQuestion].numero = (oldIndex > newIndex) ? (newIndex - 1) : (newIndex + 1);
					postQuestionDefaut(this.state.questions[iQuestion])
					.then(res => {
						console.log(res)						
					})
					.catch(err => {
						console.log(err.message)
					})
				}
			});
            this.setState({questions: this.state.questions})
        }    
    }



	render() {

		if (!this.props.data.init) return <></>;

		let dataService = new DataService();
		let calendarService = new CalendarService();

		console.log(this.props.data)

		console.log('editingQuestion ', this.state.editingQuestion)


		let modals = [];

		let types = {
			deac: "DEAC",
			capemploi: "Cap Emploi",
			esat: "ESAT",
			formation: "Organisme de formation",
			ea: "EA",
			horsea: "Entreprise hors EA",
			public: "Secteur public",
			autre: "Autre"
		}		

		let structures = [];
		this.props.data.structures
		.filter((s) => {
			return s.domaine == this.state.domaine
		})
		.sort((a, b) => {
			if (a.actif != b.actif) return a.actif ? -1 : 1;
			return a.nom.localeCompare(b.nom)
		}).map((structure, index) => {

			let responsables = [];
			structure.responsables.map(r => {
				let responsable = dataService.getConseillerById(r);
				if (responsable) {
					responsables.push(responsable);
				}
			});

			structures.push(
				<tr key={"row-structure-" + structure.id} className={(this.state.structuresSelectionnees.includes(structure.id) ? "table-warning" : "") + " " + (!structure.actif ? "obsolete" : "")}>
					{/*<td>
						<div className="checkbox-wrapper">
							<input type="checkbox" id={"toggle-structure-" + structure.id} onChange={() => this.toggleStructure(structure)} checked={this.state.structuresSelectionnees.includes(structure.id)} />
                            <label htmlFor={"toggle-structure-" + structure.id} className="toggle"></label>
						</div>
					</td>*/}
					<td>
						<NavLink to={"/structures/" + structure.id}><p className="m-0 table-nom font-14">{structure.nom}</p></NavLink>
						<p className="m-0 text-muted font-14">
							{types[structure.type]}{structure.type == "autre" && structure.precisionType && <> ({structure.precisionType})</>}
						</p>
					</td>
					
					<td>
						{responsables.length <= 1 && 
							<>
								<p className="m-0 table-nom font-14">{responsables[0].prenom} {responsables[0].nom}</p>
								<p className="m-0 text-muted font-14">
									{responsables[0].email}
									{responsables[0].telephone != null &&
										<>
											<br />
											{responsables[0].telephone}
										</>
									}
								</p>	
							</>
						}
						{responsables.length > 1 &&
							<>	
								{responsables.map(responsable => 
									<React.Fragment key={"row-responsable-" + responsable.id}><p className="m-0 table-nom font-14">{responsable.prenom} {responsable.nom}</p><br /></React.Fragment>
								)}
							</>
						}
					</td>

					<td className="nbrebeneficiaires">
						<p className="m-0 text-muted font-14"><i className="mdi mdi-account-multiple"></i> {dataService.getStructureNbConseillersActifs(structure)}</p>
					</td>
					<td className="nbrebeneficiaires">
						<p className="m-0 text-muted font-14"><i className="mdi mdi-account-multiple-outline"></i> {dataService.getStructureNbBeneficiairesSuiviAvance(structure)}</p>
					</td>
					<td className="nbrebeneficiaires">
						<p className="m-0 text-muted font-14"><i className="mdi mdi-account-multiple-outline"></i> {dataService.getStructureNbBeneficiairesSuiviNormal(structure)}</p>
					</td>
					<td>                                                                    																																								
						<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Générer fichier de suivi"><a href="#" className="m-0 btn btn-annexe contour" onClick={(e) => {e.preventDefault(); this.getStructureSuivi(structure)}}><i className="mdi mdi-currency-eur"></i></a></span>
						<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={structure.actif ? "Désactiver" : "Réactiver" }><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-toggleactivationstructure-" + structure.id}><i className={"mdi " + (structure.actif ? "mdi-block-helper" : "mdi-checkbox-marked-circle-outline")}></i></a></span>
						<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionstructure-" + structure.id}><i className="dripicons-trash"></i></a></span>
					</td>
				</tr>
			);
			modals.push(
				<div key={"modal-toggleactivationstructure-" + structure.id} id={"modal-toggleactivationstructure-" + structure.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{structure.actif ? "Désactiver" : "Réactiver" } la structure</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{structure.actif && <p>L'ensemble des services de suivi et comptes utilisateurs liés à <b>{structure.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!structure.actif && <p>L'ensemble des services de suivi et comptes utilisateurs liés à <b>{structure.nom}</b> seront réactivés. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.changeStructure(structure, "actif", !structure.actif)} data-dismiss="modal">{structure.actif ? "Désactiver" : "Réactiver" } la structure</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
			modals.push(
				<div key={"modal-suppressionstructure-" + structure.id} id={"modal-suppressionstructure-" + structure.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer la structure</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>L'ensemble des services de suivi et comptes utilisateurs liés à <b>{structure.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.changeStructure(structure, "supprime", true)} data-dismiss="modal">Supprimer la structure</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
		});

		this.props.data.categoriesQuestions.map((categorie, iCategorie) => {
			modals.push(
				<div id={"modal-modifcategorie-" + categorie.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
						{this.state.editingCategorie &&
							<>
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title mt-0">Catégorie</h5>
										<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
									</div>
									<div className="modal-body">
										<input type="text" className="form-control" value={this.state.editingCategorie.nom} onChange={(e) => {this.state.editingCategorie.nom = e.target.value; this.setState({editingCategorie: this.state.editingCategorie})}} />
									</div>									
									<div className="modal-footer">
										<button type="button" className="btn btn-primary" onClick={() => this.enregistreCategorie(this.state.editingCategorie)} disabled={this.state.editingCategorie.nom.trim() == ''} data-dismiss="modal">Enregistrer</button>
										<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
									</div>	
								</div>								
							</>
						}						
					</div>
				</div>				
			)
			modals.push(
				<div id={"modal-suppressioncategorie-" + categorie.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">													
						<div className="modal-content">
						<	div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer la catégorie</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">x</button>
							</div>
							<div className="modal-body">
								<p>La catégorie sera retirée aux questions qui l'utilisaient. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.enregistreCategorie({id: categorie.id, supprime: true})} data-dismiss="modal">Supprimer la catégorie</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>																				
					</div>
				</div>
			)
		})
		modals.push(
			<div id={"modal-nouvellecategorie"}  className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
				<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
					<div className="modal-content">
						{this.state.editingCategorie &&
							<>
								<div className="modal-header">
									<h5 className="modal-title mt-0">Enregistrer</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>											
								<div className="modal-body">
									<input type="text" className="form-control" value={this.state.editingCategorie.nom} onChange={(e) => {this.state.editingCategorie.nom = e.target.value; this.setState({editingCategorie: this.state.editingCategorie})}} />
								</div>	
								<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.enregistreCategorie(this.state.editingCategorie)} disabled={this.state.editingCategorie.nom.trim() == ''} data-dismiss="modal">Enregistrer</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>									
							</>
						}						
					</div>
				</div>
			</div>
		)		

		let optionsNbMaxBeneficiaires = [];
		for (let i = 0 ; i <= 100 ; i+=5) {
			optionsNbMaxBeneficiaires.push(
				<option key={"option-maxbeneficiaires-" + i} value={i}>{i}</option>
			);
		}

		let optionsNbMaxConseillers = [];
		for (let i = 0 ; i <= 50 ; i+=5) {
			optionsNbMaxConseillers.push(
				<option key={"option-maxconseillers-" + i} value={i}>{i}</option>
			);
		}

		let optionsNbMessagesAvantAvertissement = [];
		for (let i = 0 ; i <= 50 ; i+=5) {
			optionsNbMessagesAvantAvertissement.push(
				<option key={"option-maxmessages-" + i} value={i}>{i}</option>
			);
		}

		let troismois = new Date();
		troismois.setMonth(troismois.getMonth() - 3);
		let allQuestions = [];
		this.props.data.structures.map(structure => {
			dataService.getStructureBeneficiaires(structure).map(beneficiaire => {
				/*beneficiaire.questions.filter(question => new Date(question.dateModification) > troismois).map(question => {
					allQuestions.push({
						structure: structure,
						beneficiaire: beneficiaire,
						destinataire: beneficiaire,
						question: question
					});
				});*/
				/*beneficiaire.emplois.map(emploi => {
					emploi.proches.map(proche => {
						proche.questions.filter(question => new Date(question.dateModification) > troismois).map(question => {
							allQuestions.push({
								structure: structure,
								beneficiaire: beneficiaire,
								destinataire: proche,
								question: question
							});
						});						
					});
				});*/
				beneficiaire.proches.map(proche => {
					proche.questions.filter(question => new Date(question.dateModification) > troismois).map(question => {
						allQuestions.push({
							structure: structure,
							beneficiaire: beneficiaire,
							destinataire: proche,
							question: question
						});
					});						
				});
			});
		});

		console.log(this.state.surveillance)
		let surveillance = [];
		this.state.surveillance.map((question, indexQuestion) => {
			console.log(question.dateModificationMajeure)
			let date = new Date(question.dateModificationMajeure);
			console.log(date)
			surveillance.push(
				<li key={"row-question-" + question.id} className="feed-item">
					<div className="feed-item-list">
						<div className='row'>
							<div className='col-9'>
								{question.destinataire &&
									<span className="date">
										{question.destinataire.classe === 'Profil' ?
											<>
												Profil "{question.destinataire.filtre} &gt; {{beneficiaire: getTerme('utilisateur', question.destinataire.structure ? question.destinataire.structure.domaine : ''), 'proche-superieur': getTerme('supérieur hiérarchique', question.destinataire.structure ? question.destinataire.structure.domaine : '')}[question.destinataire.cible]}"
											</>
										:
											<>
												{question.destinataire.prenom} {question.destinataire.nom}
												{question.destinataire.classe === 'Proche' &&
													<>
													(proche de {question.destinataire.beneficiaire.prenom} {question.destinataire.beneficiaire.nom})
													</>
												}
											</>
										}
										<> - {question.destinataire.structure.nom}</>
									</span> 
								}
								<div className="activity-titre pb-0">
									{this.afficheContenuQuestionSurveillance(question)}												
								</div>
								<span className="date">Modifiée le : {date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt} à {("0" + date.getHours()).substr(-2)}h{("0" + date.getMinutes()).substr(-2)}</span>
							</div>
							<div className='col-3'>
								<button className='btn btn-primary' data-toggle="modal" data-target="#modal-questiondefaut" onClick={() => {
									document.querySelector('a.nav-link[role="tab"][href="#questions-questions"').click()
									let questionDefaut = JSON.parse(JSON.stringify(question))
									this.prepareQuestionDefaut(questionDefaut)
									this.setEditingQuestion(questionDefaut)
								}}>Ajouter à la banque des questions</button>
							</div>
						</div>						
					</div>
				</li>
			);
		})
		/*allQuestions.sort((a, b) => a.question.dateModification > b.question.dateModification ? -1 : 1).map(question => {
			let date = new Date(question.question.dateModification);
			surveillance.push(
				<li key={"row-question-" + question.question.id} className="feed-item">
					<div className="feed-item-list">
						<span className="date">
							{question.destinataire.prenom} {question.destinataire.nom}
							{question.destinataire != question.beneficiaire &&
								<>
								(proche de {question.beneficiaire.prenom} {question.beneficiaire.nom})
								</>
							}
							<> - {question.structure.nom}</>
						</span> 
						<span className="activity-titre">
							{question.destinataire.prefixeMessage && <>{question.destinataire.prefixeMessage}<br /></>}
							{question.question.libelle}<br />
							{question.question.type == "liste" &&
								<>
									{question.question.reponses.filter((reponse) => !reponse.supprime).sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, index) =>
										<React.Fragment key={"question-previsualisation-reponse-" + reponse.id}>
											"{reponse.libelle}" Tapez {reponse.cle.code ? reponse.cle.code : (index + 1)} {reponse.cle.icone ? ("ou " + reponse.cle.icone) : ""}
											{index != question.question.reponses.length - 1 && <br />}
										</React.Fragment>
									)}
								</>
							}
							{question.question.type == "gradient" &&
								<>
                                    Tapez une valeur entre {question.question.parametres.gradient.min} et {question.question.parametres.gradient.max}.
                                </>
							}
							{question.destinataire.suffixeMessage && <><br />{question.destinataire.suffixeMessage}</>}
						</span>
						<span className="date">Modifiée le : {date.getDate()} {calendarService.mois()[date.getMonth()].nomCourt} à {("0" + date.getHours()).substr(-2)}h{("0" + date.getMinutes()).substr(-2)}</span>
					</div>
				</li>
			);
		});*/

		return (
			<div className="content-page h-100" id="page-admin-structures">
                <div className="content h-100">
                    <div className="container-fluid h-100">
						<div className="row h-100">
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-12">
										<div className="card contenu m-b-20">
											<div className="card-body">
												<ul className="nav nav-tabs nav-tabs-1" role="tablist">
													{Object.keys(this.domaines).map((domaine, iDomaine) => {
														return (
															<React.Fragment key={"domaine-" + iDomaine}>
																<li className="nav-item">
																	<a className={"nav-link" + (this.state.domaine == domaine ? " active" : "")} data-toggle="tab" href={"#" + domaine} role="tab" onClick={() => this.setState({domaine, bibliothequeCourante: this.props.data.bibliotheques.filter(b => b.domaine == domaine).length > 0 ? this.props.data.bibliotheques.filter(b => b.domaine == domaine)[0] : null})}>
																		<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
																		<span className="d-none d-sm-block">{this.domaines[domaine]}</span>
																	</a>
																</li>
															</React.Fragment>
														)
													})}
													<li className="nav-item">
														<a className={"nav-link"} data-toggle="tab" href="#questions" role="tab">
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">Questions</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link"} data-toggle="tab" href="#securite" role="tab">
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">Sécurité</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link"} data-toggle="tab" href="#rgpd" role="tab">
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">RGPD</span>
														</a>
													</li>
												</ul>
												<div className="tab-content">
													{Object.keys(this.domaines).map((domaine, iDomaine) => {
														return (
															<React.Fragment key={"domaine-" + iDomaine}>															
																<div className={"tab-pane p-3" + (this.state.domaine == domaine ? " active" : "")} id={domaine} role="tabpanel">
																	<ul className="nav nav-tabs nav-tabs-custom nav-tabs-2" role="tablist">
																		<li className="nav-item">
																			<a className={"nav-link active"} data-toggle="tab" href={"#" + domaine + "-structures"} role="tab">
																				<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
																				<span className="d-none d-sm-block">Les structures</span>
																			</a>
																		</li>
																		{/*<li className="nav-item">
																			<a className={"nav-link"} data-toggle="tab" href={"#" + domaine + "-bibliotheques"} role="tab">
																				<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
																				<span className="d-none d-sm-block">Les bibliothèques de questions</span>
																			</a>
																		</li>*/}
																		{/*{Object.getOwnPropertyNames(this.props.data.questions).map(property => {
																			return (
																				<li key={"li-questiondefaut-" + property} className="nav-item">
																					<a className={"nav-link"} data-toggle="tab" href={"#questions-" + property} role="tab">
																						<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
																						<span className="d-none d-sm-block">Les questions par défaut - {nomsCibles[property]}</span>
																					</a>
																				</li>
																			);
																		})}	*/}																															
																		<li className="nav-item">
																			<a className={"nav-link"} data-toggle="tab" href={"#" + domaine + "-statistiques"} role="tab">
																				<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
																				<span className="d-none d-sm-block">Statistiques</span>
																			</a>
																		</li>																		
																	</ul>																
																	<div className="tab-content">
																		<div className={"tab-pane p-3 active"} id={domaine + "-structures"} role="tabpanel">
																			<h3 className="ml-3 mt-3">Les structures</h3>	
																			<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouvellestructure"><i className="mdi mdi-plus"></i> Nouvelle structure</button>				                                                    
																			<div className="table-responsive">
																				<table className="table table-vertical">
																					<thead>
																						<tr>
																							{/*<th>
																								<div className={"checkbox-wrapper" + (this.state.structuresSelectionnees.length != this.props.data.structures.length ? " selection" : "")}>
																									<input type="checkbox" id="toggle-structures-all" checked={this.state.structuresSelectionnees.length > 0} onChange={() => this.toggleAllStructures()} />
																									<label htmlFor="toggle-structures-all" className="toggle"></label>
																								</div>
																							</th>*/}																	
																							{this.state.structuresSelectionnees.length == 0 &&
																								<>
																									<th>Nom</th>
																									<th>Contact</th>
																									<th>Nbre {getTerme("d'accompagnants", this.state.domaine)}</th>
																									<th>Nbre {getTerme("d'utilisateurs", this.state.domaine)} suivis<br />avec signaux faibles</th>
																									<th>Nbre {getTerme("d'utilisateurs", this.state.domaine)} suivis<br />sans signaux faibles</th>
																									<th>Actions</th>
																								</>
																							}
																							{this.state.structuresSelectionnees.length > 0 &&
																								<th colSpan="5">
																									{this.structuresSelectionneesActives() !== null && <a href="#" data-toggle="modal" data-target="#modal-toggleactivationstructures" className="btn btn-annexe contour"><i className={this.structuresSelectionneesActives() ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i> {this.structuresSelectionneesActives() ? "Désactiver" : "Réactiver"}</a>}
																									<a href="#" data-toggle="modal" data-target="#modal-suppressionstructures" className="btn btn-annexe contour"><i className="dripicons-trash"></i> Supprimer</a>
																								</th>
																							}
																						</tr>
																					</thead>
																					<tbody>
																						{structures}
																						<tr>
																							<td colSpan="8" className="tdaction">
																								<button className="m-0 btn btn-annexe contour action" data-toggle="modal" data-target="#modal-nouvellestructure"><i className="mdi mdi-plus"></i> Créer une nouvelle structure</button>
																							</td>
																						</tr>
																					</tbody>
																				</table>	
																			</div>
																		</div>
																		<div className={"tab-pane p-3"} id={domaine + "-bibliotheques"} role="tabpanel">
																			<h3 className="ml-3 mt-3">Les bibliothèques de questions</h3>
																			<div className="row">
																				<div className="col-6">
																					<div className="form-group">
																						<select className="form-control" value={this.state.bibliothequeCourante ? this.state.bibliothequeCourante.id : null} onChange={(e) => this.setState({bibliothequeCourante: this.getBibliotheque(e.target.value)})}>
																							{this.props.data.bibliotheques.filter(b => b.domaine == this.state.domaine).map((bibliotheque, iBibliotheque) => {
																								return (
																									<option key={"bibliotheque-" + iBibliotheque} value={bibliotheque.id}>{this.cibles[bibliotheque.cible]} - {bibliotheque.nom}</option>																	
																								)
																							})}															
																						</select>
																					</div>
																				</div>															
																				<div className="col-3">
																					{this.state.bibliothequeCourante && 
																						<>
																							<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifbibliotheque"} onClick={() => this.setEditingBibliotheque(this.state.bibliothequeCourante)}><i className="dripicons-document-edit"></i></a></span>
																							<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionbibliotheque"}><i className="dripicons-trash"></i></a></span>
																						</>
																					}
																				</div>																													
																				<div className="col-3">
																					<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouvellebibliotheque"><i className="mdi mdi-plus"></i> Nouvelle bibliothèque</button>				                                                    														
																				</div>															
																			</div>	
																			<hr />																											
																			{this.state.domaine == domaine && this.state.bibliothequeCourante &&
																				<>
																					{/*<div className="form-group row">
																						<div className="col-9">
																							<input type="text" className="form-control" value={this.state.bibliothequeCourante.nom} onChange={(e) => console.log(e)} />
																						</div>
																						<div className="col-3">
																							<button className="btn btn-primary">Enregistrer</button>
																						</div>
																					</div>*/}
																					{/*<Bibliotheque
																						domaine={domaine}
																						bibliotheque={this.state.bibliothequeCourante}
																						enregistre={(bibliotheque) => this.enregistreBibliotheque(bibliotheque)}
																					/>*/}
																				</>
																			}
																			
																		</div>
																		{/*{Object.getOwnPropertyNames(this.props.data.questions).map(property => {
																			return (
																				<TabQuestions
																					key={"tab-questiondefaut-" + property}	
																					cible={property}
																					questions={this.props.data.questions[property]}
																					enregistre={(question) => this.enregistreQuestion(question, property)}
																				/>
																			);
																		})}*/}													
																		<div className={"tab-pane p-3"} id={domaine + "-statistiques"} role="tabpanel">
																			<h3 className="ml-3 mt-3">Statistiques</h3>															
																			<form className="form-statistiques">	
																				<div className="row">
																					<div className="input-group date">
																						<div className="input-group-prepend">
																							<div className="input-group-text">Date de début</div>
																						</div>
																						<DatePicker
																							ref={(input) => { this.ref_statistiquesDebutWidget = input; }} 
																							className="form-control" 
																							locale="fr"
																							selected={this.state.statistiquesDebut}
																							onChange={(date) => this.setState({statistiquesDebut: date})}
																							dateFormat="dd/MM/yyyy"
																							maxDate={this.state.statistiquesFin}
																						/>
																						<div className="input-group-append" onClick={() => this.ref_statistiquesDebutWidget.setFocus(true)}>
																							<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																						</div>
																					</div>
																					<div className="input-group date">
																						<div className="input-group-prepend">
																							<div className="input-group-text">Date de fin (incluse)</div>
																						</div>
																						<DatePicker
																							ref={(input) => { this.ref_statistiquesFinWidget = input; }} 
																							className="form-control" 
																							locale="fr"
																							selected={this.state.statistiquesFin}
																							onChange={(date) => this.setState({statistiquesFin: date})}
																							dateFormat="dd/MM/yyyy"
																							minDate={this.state.statistiquesDebut}
																						/>
																						<div className="input-group-append" onClick={() => this.ref_statistiquesFinWidget.setFocus(true)}>
																							<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																						</div>
																					</div>																	
																				</div>	
																				<br clear="both" />														
																				<div className="row mt-3 ml-1">
																					<button type="button" className="mb-3 btn btn-primary" onClick={() => this.getStatistiques()}>Télécharger le fichier de statistiques</button>
																				</div>															
																			</form>
																		</div>																		
																	</div>																	
																</div>                                         
															</React.Fragment>
														)
													})}	
													<div className={"tab-pane p-3"} id="questions" role="tabpanel">
														<h3 className="ml-3 mt-3">Questions</h3>	
														<ul className="nav nav-tabs nav-tabs-custom nav-tabs-2" role="tablist">
															<li className="nav-item">
																<a className={"nav-link active"} data-toggle="tab" href={"#questions-questions"} role="tab">
																	<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
																	<span className="d-none d-sm-block">Banque des questions</span>
																</a>
															</li>
															<li className="nav-item">
																<a className={"nav-link"} data-toggle="tab" href={"#questions-categories"} role="tab">
																	<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
																	<span className="d-none d-sm-block">Catégories</span>
																</a>
															</li>														
															<li className="nav-item">
																<a className={"nav-link"} data-toggle="tab" href={"#questions-surveillance"} role="tab">
																	<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
																	<span className="d-none d-sm-block">Surveillance des questions</span>
																</a>
															</li>																		
														</ul>
														<div className="tab-content">
															<div className={"tab-pane p-3 active"} id="questions-questions" role="tabpanel">
																<h3 className="ml-3 mt-3">Banque des questions</h3>																	
																<form className="form mb-2" onSubmit={e => {e.preventDefault(); }}>	
																	<div className="form-group row">																
																		<div className="col-6">																
																			<div className="form-group">
																				<input type="text" className="form-control" placeholder="Libellé de la question..." value={this.state.rechercheQuestions.motscles} onChange={(e) => {this.state.rechercheQuestions.motscles = e.target.value; this.setState({rechercheQuestions: this.state.rechercheQuestions})}} onBlur={() => {}/*this.chercheQuestions()*/}/>																				
																			</div>																
																		</div>
																		<div className="col-6">
																			<div className="form-group">																				
																				<select id="filtre-questions-categories" className="form-control select2 select2-multiple" multiple data-placeholder="Catégories..." style={{padding: "0px imporant!"}}>
																					{this.props.data.categoriesQuestions
																					.map((categorie, iCategorie) => {
																						return (
																							<React.Fragment key={"filtre-" + iCategorie}>
																								<option value={categorie.id}>{categorie.nom}</option>																								
																							</React.Fragment>
																						)
																					})}																		
																				</select>																				
																			</div>
																		</div>
																		<div className="col-4">
																			<div className="form-group">
																				<select id="filtre-questions-domaines" className="form-control select2 select2-multiple" multiple data-placeholder="Secteurs d'activité..." style={{padding: "0px imporant!"}}>
																					{Object.entries(this.domaines).map(([kDomaine, domaine]) => {
																						return (
																							<React.Fragment key={"filtre-" + kDomaine}>
																								<option value={kDomaine}>{domaine}</option>																								
																							</React.Fragment>
																						)
																					})}
																				</select>
																			</div>
																		</div>
																		<div className="col-4">
																			<div className="form-group">
																				<select id="filtre-questions-cibles" className="form-control select2 select2-multiple" multiple data-placeholder="Cibles..." style={{padding: "0px imporant!"}}>
																					{Object.entries({
																						beneficiaire: 'Utilisateur',
																						'proche-superieur': 'Supérieur hiérarchique'
																					}).map(([kCible, cible]) => {
																						return (
																							<React.Fragment key={"filtre-" + kCible}>
																								<option value={kCible}>{cible}</option>																							
																							</React.Fragment>
																						)
																					})}
																				</select>
																			</div>
																		</div>
																		<div className="col-4">
																			<div className="form-group">
																				<select id="filtre-questions-communications" className="form-control select2 select2-multiple" multiple data-placeholder="Tutoiement/vouvoiement..." style={{padding: "0px imporant!"}}>
																					<option value="tutoiement">Tutoiement</option>
																					<option value="vouvoiement">Vouvoiement</option>
																				</select>
																			</div>
																		</div>
																	</div>
																	<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-questiondefaut" onClick={(e) => {e.preventDefault(); setEditingQuestion(null)}}><i className="mdi mdi-plus"></i> Nouvelle question</button>				                                                    																
																	<br clear="both" />
																	<div className="row">
																		<table className="table table-vertical" id="ordre">
																			<thead>
																				<tr>
																					<th>
																						{this.state.questions.length > 0 &&
																							<div className={"checkbox-wrapper" + (this.state.questionsSelectionnees.length != this.state.questions.length ? " selection" : "")}>
																								<input type="checkbox" id="toggle-questions-all" checked={this.state.questionsSelectionnees.length > 0} onChange={() => this.toggleAllQuestions()} />
																								<label htmlFor="toggle-questions-all" className="toggle"></label>
																							</div>
																						}
																					</th>
																					{this.state.questionsSelectionnees.length == 0 &&
																						<>
																							<th>Libellé</th>
																							<th>Secteur(s) d'activité</th>
																							<th>Cible(s)</th>
																							<th>Catégorie(s)</th>
																							<th>Tu / Vous</th>
																							<th>Actions</th>																							
																						</>
																					}
																					{this.state.questionsSelectionnees.length > 0 &&
																						<th colSpan="4">
																							<a href="#" data-toggle="modal" data-target="#modal-dupliquequestionsdefaut" className="btn btn-annexe contour m-b-20"><i className="dripicons-copy"></i> Dupliquer</a>
																							<a href="#" data-toggle="modal" data-target="#modal-suppressionquestionsdefaut" className="btn btn-annexe contour m-b-20"><i className="dripicons-trash"></i> Supprimer</a>																							
																						</th>
																					}
																				</tr>
																			</thead>
																			{/*
																			<tbody>
																				{this.state.questions.map((question, iQuestion) => {
																					console.log(question)
																					return (
																						<>
																							<tr key={"row-question-" + question.id} className={this.state.questionsSelectionnees.includes(question.id) ? "table-warning" : ""}>
																								<td>
																									<div className="checkbox-wrapper">
																										<input type="checkbox" id={"toggle-question-" + question.id} onChange={() => this.toggleQuestion(question)} checked={this.state.questionsSelectionnees.includes(question.id)} />
																										<label htmlFor={"toggle-question-" + question.id} className="toggle"></label>
																									</div>
																								</td>
																								<td className="libelle-question">
																									<p className="m-0 table-nom text-muted font-14">{question.libelle}</p>
																									{question.reponses.filter(r => r.questionDeclenchee !== null).length > 0 &&
																										<>
																											<p className="ml-2">
																												{question.reponses.map((reponse, indexReponse) => {
																													if (reponse.questionDeclenchee == null) return null
																													return (
																														<React.Fragment key={"reponse-" + indexReponse}>
																															<small>{question.type === 'gradient' ? (indexReponse + 1) : reponse.libelle} --&gt; {reponse.questionDeclenchee.libelle}</small><br />
																														</React.Fragment>
																													)
																												})}
																											</p>
																										</>
																									}																									
																								</td>
																								<td>
																									<p className="m-0 text-muted font-14">
																										{question.domaines.map((domaine, iDomaine) => {
																											return (
																												<React.Fragment key={"domaine-" + iDomaine}>
																													<span>{this.domaines[domaine]}</span><br />
																												</React.Fragment>
																											)
																										})}
																									</p>
																								</td>
																								<td>
																									<p className="m-0 text-muted font-14">
																										{question.cibles.map((cible, iCible) => {
																											return (
																												<React.Fragment key={"cible-" + cible}>
																													<span>{this.cibles[cible]}</span><br />
																												</React.Fragment>
																											)
																										})}
																									</p>
																								</td>
																								<td>
																									<p className="m-0 text-muted font-14">
																										{question.categories.map((c, iC) => {
																											return (
																												<React.Fragment key={"categorie-" + iC}>
																													{this.props.data.categoriesQuestions.filter(categorie => categorie.id == c).map((categorie, iCategorie) => {
																														return (<><span>{categorie.nom}</span><br /></>)																														
																													})}
																												</React.Fragment>
																											)
																										})}
																									</p>
																								</td>																								
																								<td>
																									<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-questiondefaut" onClick={() => this.setEditingQuestion(question)}><i className="dripicons-document-edit"></i></a></span>
																									<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Dupliquer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-questiondefaut" onClick={() => {let q = JSON.parse(JSON.stringify(question)); this.removeId(q); q.libelle += " - Copie"; this.setEditingQuestion(q)}}><i className="dripicons-copy"></i></a></span>																							
																									<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-suppressionquestiondefaut" onClick={() => this.setEditingQuestion(question)}><i className="dripicons-trash"></i></a></span>
																								</td>	
																								<td>
																									<div className="deplacer"><i className="dripicons-move"></i></div>
																								</td>																							
																							</tr>
																						</>
																					)
																				})}
																				<tr>
																					<td colSpan="7" className="tdaction">
																						<a href="#" data-toggle="modal" data-target="#modal-questiondefaut" onClick={() => {this.setEditingQuestion(null)}} className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle question</a>
																					</td>
																				</tr>
																			</tbody>*/}
																			<SortableQuestions
																				shouldCancelStart={(e) => {
																					return !e.target.classList.contains("dripicons-move");
																				}}
																				onSortEnd={({oldIndex, newIndex, collection, isKeySorting}, e) => {
																					console.log(oldIndex, newIndex, collection, isKeySorting)
																					this.reordonneQuestions(oldIndex, newIndex);
																				}}
																				helperContainer={() => {
																					return document.getElementById("tbody-questions");                                
																				}}
																				items={this.state.questions
																					.filter(question => {
																						console.log(this.state.rechercheQuestions)
																						if (this.state.rechercheQuestions.motscles.trim() != '') {
																							for (let mot of this.state.rechercheQuestions.motscles.trim().split(' ').filter(m => m != '')) {
																								if (question.libelle.toLowerCase().indexOf(mot.toLowerCase()) === -1) {
																									return false
																								}
																							}
																						}
																						if (this.state.rechercheQuestions.domaines.length != 0) {
																							if (this.state.rechercheQuestions.domaines.filter(c => question.domaines.includes(c)).length !== this.state.rechercheQuestions.domaines.length) {
																								return false
																							}
																						}
																						if (this.state.rechercheQuestions.cibles.length != 0) {
																							if (this.state.rechercheQuestions.cibles.filter(c => question.cibles.includes(c)).length !== this.state.rechercheQuestions.cibles.length) {
																								return false
																							}
																						}
																						if (this.state.rechercheQuestions.categories.length != 0) {
																							console.log(this.state.rechercheQuestions.categories)
																							if (this.state.rechercheQuestions.categories.filter(c => question.categories.includes(parseInt(c))).length !== this.state.rechercheQuestions.categories.length) {
																								return false
																							}
																						}
																						if (this.state.rechercheQuestions.communications.length != 0) {
																							if (this.state.rechercheQuestions.communications.includes(question.communication) === false) {
																								return false
																							}
																						}
																						return true
																					})
																					.sort((a, b) => (a.numero < b.numero) ? -1 : 1)}
																				questionsSelectionnees={this.state.questionsSelectionnees}
																				toggleQuestion={(question) => this.toggleQuestion(question)}
																				setEditingQuestion={(question) => this.setEditingQuestion(question)}																				
																				domaines={this.domaines}
																				cibles={this.cibles}
																				categories={this.props.data.categoriesQuestions}
																				filtres={this.state.rechercheQuestions}
																				removeId={(question) => this.removeId(question)}
																			/>
																		</table>																		
																	</div>																	
																</form>																
															</div>
															<div className={"tab-pane p-3"} id="questions-categories" role="tabpanel">
																<h3 className="ml-3 mt-3">Catégories</h3>	
																<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouvellecategorie" onClick={(e) => this.setState({editingCategorie: {id: null, nom: ''}})}><i className="mdi mdi-plus"></i> Nouvelle catégorie</button>				                                                    
																<div className="table-responsive">
																	<table className="table table-vertical">
																		<thead>
																			<tr>
																				{/*<th>
																					<div className={"checkbox-wrapper" + (this.state.structuresSelectionnees.length != this.props.data.structures.length ? " selection" : "")}>
																						<input type="checkbox" id="toggle-structures-all" checked={this.state.structuresSelectionnees.length > 0} onChange={() => this.toggleAllStructures()} />
																						<label htmlFor="toggle-structures-all" className="toggle"></label>
																					</div>
																				</th>*/}
																				<th>Nom</th>
																				<th>Actions</th>
																			</tr>
																		</thead>
																		<tbody>
																			{this.props.data.categoriesQuestions.map((categorie, iCategorie) => {
																				return (
																					<React.Fragment key={"categorie-" + iCategorie}>
																						<tr>
																							<td>
																								{categorie.nom}
																							</td>
																							<td>
																								<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" data-toggle="modal" data-target={"#modal-modifcategorie-" + categorie.id} onClick={(e) => this.setState({editingCategorie: categorie})} className="m-0 btn btn-annexe contour"><i className="dripicons-document-edit"></i></a></span>
                                																<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" data-toggle="modal" data-target={"#modal-suppressioncategorie-" + categorie.id} className="m-0 btn btn-annexe contour"><i className="dripicons-trash"></i></a></span>
																							</td>
																						</tr>
																					</React.Fragment>
																				)
																			})}
																			<tr>
																				<td colSpan="2" className="tdaction">
																					<button className="m-0 btn btn-annexe contour action" data-toggle="modal" data-target="#modal-nouvellecategorie" onClick={(e) => this.setState({editingCategorie: {id: null, nom: ''}})}><i className="mdi mdi-plus"></i> Créer une nouvelle catégorie</button>
																				</td>
																			</tr>
																		</tbody>
																	</table>	
																</div>															
															</div>
															<div className={"tab-pane p-3"} id="questions-surveillance" role="tabpanel">
																<h3 className="ml-3 mt-3">Surveillance</h3>																
																<ol className="activity-feed archives mb-0">
																	{surveillance}
																</ol>
															</div>																																	
														</div>																
													</div>
													<div className={"tab-pane p-3"} id="securite" role="tabpanel">
														<h3 className="ml-3 mt-3">Sécurité</h3>															
														<form className="form mb-2" onSubmit={e => {e.preventDefault(); this.chercheSecuriteLogs()}}>	
															<div className="form-group row">																
																<div className="col-4">																
																	<div className="input-group">
																		<input type="text" className="form-control" value={this.state.rechercheSecuriteLogs} onChange={(e) => this.setState({rechercheSecuriteLogs: e.target.value})} />
																		<div className="input-group-append">
																			<button type="submit" className="btn btn-outline-secondary">Chercher</button>
																		</div>
																	</div>																
																</div>
															</div>
														</form>
														{this.state.securiteLogs === null &&
															<div className="three-balls">
																<div className="ball ball1"></div>
																<div className="ball ball2"></div>
																<div className="ball ball3"></div>
															</div>
														}
														{this.state.securiteLogs !== null &&
															<table className="table table-sm">
																<thead>
																	<tr>
																		<th>Date</th>
																		<th>Identifiant</th>
																		<th>Commentaire</th>
																		<th>IP</th>
																	</tr>
																</thead>
																<tbody>
																	{this.state.securiteLogs.map((log, indexLog) => {
																		return (
																			<tr key={"log-" + indexLog}>
																				<td>{moment(log.date, "YYYY/MM/DD HH:mm:ss").format('DD/MM/YYYY HH:mm:ss')}</td>
																				<td>
																					{log.identifiant}
																					{log.utilisateur != null &&
																						<>
																							<br />
																							{log.utilisateur.prenom} {log.utilisateur.nom}
																							{log.utilisateur.details.statut == "admin" && <> (Admin)</>}
																							{log.utilisateur.details.statut == "conseiller" &&
																								<> ({log.utilisateur.details.structure.nom})</>
																							}
																						</>
																					}
																				</td>
																				<td>
																					{
																						{
																							identifiant: "Identifiant inexistant",
																							motdepasse: "Mot de passe incorrect",
																							adminvpn: "Hors VPN",
																							compteinactif: "Compte inactif"
																						}[log.type]		
																					}
																				</td>
																				<td>
																					{log.ip}
																				</td>
																			</tr>
																		)
																	})}																	
																</tbody>
															</table>
														}
													</div>
													<div className={"tab-pane p-3"} id="rgpd" role="tabpanel">
														<h3 className="ml-3 mt-3">RGPD</h3>	
														<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-rgpd" onClick={() => this.setState({editingRgpd: {id: null, date: new Date(), type: 'acces', commentaire: '', personne: '', utilisateur: '', autre: '', statut: 'non-traite', action: ''}})}><i className="mdi mdi-plus"></i> Nouvel événement RGPD</button>
														{/*<form className="form mb-2" onSubmit={e => {e.preventDefault(); this.chercheSecuriteLogs()}}>	
															<div className="form-group row">																
																<div className="col-4">																
																	<div className="input-group">
																		<input type="text" className="form-control" value={this.state.rechercheSecuriteLogs} onChange={(e) => this.setState({rechercheSecuriteLogs: e.target.value})} />
																		<div className="input-group-append">
																			<button type="submit" className="btn btn-outline-secondary">Chercher</button>
																		</div>
																	</div>																
																</div>
															</div>
														</form>*/}
														{this.state.rgpdLogs === null &&
															<div className="three-balls">
																<div className="ball ball1"></div>
																<div className="ball ball2"></div>
																<div className="ball ball3"></div>
															</div>
														}
														{this.state.rgpdLogs !== null &&
															<table className="table table-sm">
																<thead>
																	<tr>
																		<th>Date</th>
																		<th>Utilisateur</th>
																		<th>Type</th>																		
																		<th>Statut</th>
																		<th>Action mise en place</th>
																	</tr>
																</thead>
																<tbody>
																	{this.state.rgpdLogs.map((log, indexLog) => {
																		console.log(log)
																		let individu = log.individu.prenom + " " + log.individu.nom
																		if (log.individu.details.statut == "admin") {
																			individu += " (Admin)"
																		}
																		if (log.individu.details.statut == "conseiller") {
																			individu += " (accompagnant/référent, " + log.individu.details.structure.nom + ")"
																		}
																		if (log.individu.details.statut == "beneficiaire") {
																			individu += " (utilisateur/salarié, " + log.individu.details.structure.nom + ")"
																		}
																		if (log.individu.details.statut == "proche") {
																			individu += " (proche de " + log.individu.details.beneficiaire.prenom + " " + log.individu.details.beneficiaire.nom + ", " + log.individu.details.beneficiaire.structure.nom + ")"
																		}
																		return (
																			<tr key={"log-" + indexLog}>
																				<td>{moment(log.date, "YYYY/MM/DD HH:mm:ss").format('DD/MM/YYYY HH:mm')}</td>
																				<td>
																					{individu}																				
																				</td>
																				<td>
																					{{
																						acces: 'Accès',
																						recticfication: 'Rectification',
																						limitation: 'Limitation',
																						effacement: 'Effacement',
																						opposition: 'Opposition',
																						portabilite: 'Portabilité'
																					}[log.type]}
																				</td>
																				<td>
																					{log.statut === 'non-traite' && <>Non traité</>}
																					{log.statut === 'en-cours' && <>En cours</>}
																					{log.statut === 'traite' && <>Traité {log.dateTraitement && <> le {moment(log.dateTraitement, "YYYY/MM/DD HH:mm:ss").format('DD/MM/YYYY à HH:mm')}</>}</>}
																				</td>
																				<td>
																					{log.action}
																				</td>		
																				<td>
																					{log.id && 
																						<>
																							<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-rgpd" onClick={() => {this.state.editingRgpd = {id: log.id, date: log.date, commentaire: log.commentaire, utilisateur: ['utilisateur', 'conseiller', 'admin'].includes(log.individu.details.statut) ? log.individu.id : null, personne: ['personne', 'beneficiaire', 'proche'].includes(log.individu.details.statut) ? log.individu.id : null, autre: !['utilisateur', 'admin', 'conseiller', 'personne', 'beneficiaire', 'proche'].includes(log.individu.details.statut) ? log.individu.nom : '', type: log.type, statut: log.statut, action: log.action, dateTraitement: log.dateTraitement}; this.setState({editingRgpd: this.state.editingRgpd}, () => log.individu.details.statut != 'autre' ? document.getElementById('input-recherche-rgpd').value = individu : null)}}><i className="dripicons-document-edit"></i></a></span>
																							<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-suppressionrgpd" onClick={() => this.setState({removingRgpd: log})}><i className="dripicons-trash"></i></a></span>
																						</>
																					}
																				</td>																		
																			</tr>
																		)
																	})}																	
																</tbody>
															</table>
														}
													</div>
												</div>																						
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
				<div id="modal-nouvellestructure" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Nouvelle structure "{this.domaines[this.state.domaine]}"</h5>
								<div className="header-buttons">
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
									<button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formEditingStructureValide()} onClick={() => this.enregistreStructure(this.state.editingStructure)}>Enregistrer</button>
								</div>
							</div>
							<div className="modal-body">
								<FormStructure
									structure={this.state.editingStructure}
									domaine={this.state.domaine}
									modifie={(structure) => this.setEditingStructure(structure)}
								/>
							</div>									
						</div>
					</div>
				</div>
				<div id="modal-suppressionstructures" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des structures</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>L'ensemble des services de suivi et comptes utilisateurs liés aux structures sélectionnées seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeStructures()} data-dismiss="modal">Supprimer les structures</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                <div key="modal-toggleactivationstructures" id="modal-toggleactivationstructures" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.structuresSelectionneesActives() ? "Désactiver" : "Réactiver" } des structures</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.structuresSelectionneesActives() && <p>L'ensemble des services de suivi et comptes utilisateurs liés aux structures sélectionnées seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!this.structuresSelectionneesActives() && <p>L'ensemble des services de suivi et comptes utilisateurs liés aux structures sélectionnées seront réactivés. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationStructures()} data-dismiss="modal">{this.structuresSelectionneesActives() ? "Désactiver" : "Réactiver" } les structures</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div id="modal-nouvellebibliotheque" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Nouvelle bibliothèque</h5>
								<div className="header-buttons">
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
									<button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formEditingBibliothequeValide()} onClick={() => this.enregistreBibliotheque(this.state.editingBibliotheque)}>Enregistrer</button>
								</div>
							</div>
							<div className="modal-body">
								<FormBibliotheque
									bibliotheque={this.state.editingBibliotheque}
									modifie={(bibliotheque) => this.setEditingBibliotheque(bibliotheque)}
								/>
							</div>									
						</div>
					</div>
				</div>
				{this.state.bibliothequeCourante &&
					<>
						<div id="modal-modifbibliotheque" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
							<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title mt-0">{this.state.bibliothequeCourante.nom}</h5>
										<div className="header-buttons">
											<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>											
										</div>
									</div>
									<div className="modal-body">
										<FormBibliotheque
											bibliotheque={this.state.editingBibliotheque}
											modifie={(bibliotheque) => this.setEditingBibliotheque(bibliotheque)}
										/>
									</div>									
								</div>
							</div>
						</div>
						<div key={"modal-suppressionbibliotheque"} id={"modal-suppressionbibliotheque"} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
							<div className="modal-dialog modal-dialog-centered modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title mt-0">Supprimer la bibliothèque</h5>
										<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
									</div>
									<div className="modal-body">
										<p>La bibliothèque <b>{this.state.bibliothequeCourante.nom}</b> sera supprimée. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeBibliotheque()} data-dismiss="modal">Supprimer la bibliothèque</button>
										<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
									</div>
								</div>
							</div>
						</div>
					</>
				}
				<div id="modal-rgpd" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
						{this.state.editingRgpd &&
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">{this.state.editingRgpd.id ? 'Evénement RGPD' : 'Nouvel événement RGPD'}</h5>
									<div className="header-buttons">
										<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
										<button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!(this.state.editingRgpd.commentaire && this.state.editingRgpd.date && (this.state.editingRgpd.personne || this.state.editingRgpd.utilisateur || this.state.editingRgpd.autre))} onClick={() => this.postRgpd()}>Enregistrer</button>
									</div>
								</div>
								<div className="modal-body">
									<div className="row">
                						<div className="col-md-12">
											<form>
												<div className='row'>
													<div className="col-md-6">
														<h5>Demande</h5>
														<div className="form-group app-search">													
															<button><i className="fa fa-search"></i></button>
															<input className="form-control" type="search" placeholder="Rechercher une personne" id="input-recherche-rgpd" autoComplete="off" disabled={this.state.editingRgpd.personne || this.state.editingRgpd.utilisateur} /> 													
															<a href="#" onClick={(e) => {e.preventDefault(); this.state.editingRgpd.personne = ''; this.state.editingRgpd.utilisateur= ''; this.setState({editingRgpd: this.state.editingRgpd}); document.getElementById('input-recherche-rgpd').value = ''}}>Annuler</a>
														</div>											
														{!(this.state.editingRgpd.personne || this.state.editingRgpd.utilisateur) &&
															<>
																<div className="mb-1">-- OU --</div>
																<div className='form-group'>
																	<input type='text' className='form-control' style={{textAlign: 'left'}} placeholder="Information sur l'auteur de la demande" value={this.state.editingRgpd.autre} onChange={(e) => {this.state.editingRgpd.autre = e.target.value; this.setState({editingRgpd: this.state.editingRgpd})}}/>
																</div>
															</>
														}
														<div className="form-group row">
															<label className="col-form-label mr-2 ml-3">Type :</label>
															<div>
																<select className="form-control" value={this.state.editingRgpd.type} onChange={(e) => {this.state.editingRgpd.type = e.target.value; this.setState({editingRgpd: this.state.editingRgpd})}}>
																	<option value='acces'>Accès</option>
																	<option value='recticfication'>Rectification</option>
																	<option value='limitation'>Limitation</option>
																	<option value='effacement'>Effacement</option>
																	<option value='opposition'>Opposition</option>
																	<option value='portabilite'>Portabilité</option>
																</select>
															</div>
														</div>
														<div className="form-group row row-forminteraction">
															<div className="input-group date">
																<div className="input-group-prepend">
																	<div className="input-group-text">Date</div>
																</div>
																<DatePicker
																	ref={(input) => { this.ref_editingRgpdDate = input; }} 
																	className="form-control" 
																	locale="fr"
																	selected={this.state.editingRgpd.date ? new Date(this.state.editingRgpd.date) : ''}
																	onChange={(date) => {this.state.editingRgpd.date = moment(date).format('YYYY/MM/DD HH:mm:00'); this.setState({editingRgpd: this.state.editingRgpd})}}
																	dateFormat="dd/MM/yyyy HH:mm"	
																	showTimeSelect											
																/>
																<div className="input-group-append" onClick={() => this.ref_editingRgpdDate.setFocus(true)}>
																	<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																</div>
															</div>
														</div>
														<div className="form-group">
															<label className="col-form-label">Commentaire <InfobulleRGPD /> :</label>
															<div className="input-group">
																<textarea className="form-control" rows="3" value={this.state.editingRgpd.commentaire} onChange={(e) => {this.state.editingRgpd.commentaire = e.target.value; this.setState({editingRgpd: this.state.editingRgpd})}}></textarea>
															</div>
														</div>	
													</div>
													<div className="col-md-6">
														<h5>Traitement</h5>
														<div className="form-group row" style={{marginTop: 22}}>
															<label className="col-form-label mr-2 ml-3">Statut :</label>
															<select className="form-control" value={this.state.editingRgpd.statut} onChange={(e) => {this.state.editingRgpd.statut = e.target.value; this.setState({editingRgpd: this.state.editingRgpd})}}>
																<option value='non-traite'>Non traite</option>
																<option value='en-cours'>En cours</option>
																<option value='traite'>Traité</option>
															</select>
														</div>
														{console.log(this.state.editingRgpd.statut)}
														{this.state.editingRgpd.statut == 'traite' &&
															<>
																<div className="form-group row row-forminteraction">
																	<div className="input-group date">
																		<div className="input-group-prepend">
																			<div className="input-group-text">Date</div>
																		</div>
																		<DatePicker
																			ref={(input) => { this.ref_editingRgpdDateTraitement = input; }} 
																			className="form-control" 
																			locale="fr"
																			selected={this.state.editingRgpd.dateTraitement ? new Date(this.state.editingRgpd.dateTraitement) : ''}
																			onChange={(date) => {this.state.editingRgpd.dateTraitement = moment(date).format('YYYY/MM/DD HH:mm:00'); this.setState({editingRgpd: this.state.editingRgpd})}}
																			dateFormat="dd/MM/yyyy HH:mm"	
																			showTimeSelect											
																		/>
																		<div className="input-group-append" onClick={() => this.ref_editingRgpdDateTraitement.setFocus(true)}>
																			<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																		</div>
																	</div>
																</div>
																<div className="form-group">
																	<label className="col-form-label">Action mise en oeuvre <InfobulleRGPD /> :</label>
																	<div className="input-group">
																		<textarea className="form-control" rows="3" value={this.state.editingRgpd.action} onChange={(e) => {this.state.editingRgpd.action = e.target.value; this.setState({editingRgpd: this.state.editingRgpd})}}></textarea>
																	</div>
																</div>	
															</>
														}
													</div>
												</div>																							
											</form>									
										</div>
									</div>
								</div>									
							</div>
						}
						
					</div>										
				</div>
				<div key="modal-suppressionrgpd" id="modal-suppressionrgpd" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer un événment RGPD</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>L'événement RGPD sera supprimé. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" disabled={this.state.removingRgpd === null} onClick={() => this.removeRgpd()} data-dismiss="modal">Supprimer l'événement RGPD</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
				<div id="modal-questiondefaut" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">{!this.state.editingQuestion || this.state.editingQuestion.id == null ? "Nouvelle question" : "Question"}</h5>
                                <div className="header-buttons">
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                {this.state.editingQuestionDeclenchee === false && <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.enregistreQuestion()} disabled={!this.formQuestionDefautValide()}>Enregistrer</button>}
                                </div>
                            </div>
                            <div className="modal-body">
								<FormQuestionDefaut
									question={this.state.editingQuestion}
									modifie={(question) => this.setEditingQuestion(question)}                                    
									categories={this.props.data.categoriesQuestions}
									domaines={this.domaines}
									cibles={this.cibles}
									setEditingQuestionDeclenchee={(editingQuestionDeclenchee) => this.setState({editingQuestionDeclenchee})}
								/>
                            </div>
                        </div>
                    </div>
                </div>
				<div id="modal-suppressionquestiondefaut" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Suppression de la question</h5>                                
                            </div>
                            <div className="modal-body">
								{this.state.editingQuestion &&
									<>La question <b>{this.state.editingQuestion.libelle}</b> va être supprimée de la banque de questions. Cette action est irréversible.</>
								}                                
                            </div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestion()} data-dismiss="modal">Supprimer la question</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
                        </div>
                    </div>
                </div>
				<div id="modal-suppressionquestionsdefaut" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des questions</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Les questions sélectionnées seront désactivées et supprimées. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestions()} data-dismiss="modal">Supprimer les questions</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
				<div id="modal-dupliquequestionsdefaut" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Dupliquer des questions</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Les questions sélectionnées vont être dupliquées. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.dupliqueQuestions()} data-dismiss="modal">Dupliquer les questions</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
				{modals}
			</div>
		);
	}
}

const SortableQuestion = SortableElement(({value, items, questionsSelectionnees, toggleQuestion, setEditingQuestion, domaines, cibles, categories, filtres, removeId}) => {
    let question = value;

    return (
        <tr key={"row-question-" + question.id} className={questionsSelectionnees.includes(question.id) ? "table-warning" : ""}>
			<td>
				<div className="checkbox-wrapper">
					<input type="checkbox" id={"toggle-question-" + question.id} onChange={() => toggleQuestion(question)} checked={questionsSelectionnees.includes(question.id)} />
					<label htmlFor={"toggle-question-" + question.id} className="toggle"></label>
				</div>
			</td>
			<td className="libelle-question">
				<p className="m-0 table-nom text-muted font-14">{question.libelle}</p>
				{question.reponses.filter(r => r.questionDeclenchee !== null).length > 0 &&
					<>
						<p className="ml-2">
							{question.reponses.map((reponse, indexReponse) => {
								if (reponse.action != 'question') return null
								if (reponse.questionDeclenchee == null) return null
								return (
									<React.Fragment key={"reponse-" + indexReponse}>
										<small>{question.type === 'gradient' ? (indexReponse + 1) : reponse.libelle} --&gt; {reponse.questionDeclenchee.libelleCalcule}</small><br />
									</React.Fragment>
								)
							})}
						</p>
					</>
				}																									
			</td>
			<td>
				<p className="m-0 text-muted font-14">
					{question.domaines.map((domaine, iDomaine) => {
						return (
							<React.Fragment key={"domaine-" + iDomaine}>
								<span>{domaines[domaine]}</span><br />
							</React.Fragment>
						)
					})}
				</p>
			</td>
			<td>
				<p className="m-0 text-muted font-14">
					{question.cibles.map((cible, iCible) => {
						return (
							<React.Fragment key={"cible-" + cible}>
								<span>{cibles[cible]}</span><br />
							</React.Fragment>
						)
					})}
				</p>
			</td>
			<td>
				<p className="m-0 text-muted font-14">
					{question.categories.map((c, iC) => {
						return (
							<React.Fragment key={"categorie-" + iC}>
								{categories.filter(categorie => categorie.id == c).map((categorie, iCategorie) => {
									return (<><span>{categorie.nom}</span><br /></>)																														
								})}
							</React.Fragment>
						)
					})}
				</p>
			</td>	
			<td>
				<p className="m-0 text-muted font-14">
					{question.communication === 'vouvoiement' && 'Vouvoiement'}
					{question.communication === 'tutoiement' && 'Tutoiement'}
				</p>
			</td>																								
			<td>
				<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-questiondefaut" onClick={() => setEditingQuestion(question)}><i className="dripicons-document-edit"></i></a></span>
				<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Dupliquer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-questiondefaut" onClick={() => {let q = JSON.parse(JSON.stringify(question)); removeId(q); q.libelle += " - Copie"; console.log(q); setEditingQuestion(q)}}><i className="dripicons-copy"></i></a></span>																							
				<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target="#modal-suppressionquestiondefaut" onClick={() => setEditingQuestion(question)}><i className="dripicons-trash"></i></a></span>
			</td>	
			<td>
				{filtres.motscles == '' && filtres.domaines.length + filtres.cibles.length + filtres.categories.length + filtres.communications.length == 0 && <div className="deplacer"><i className="dripicons-move"></i></div>}
			</td>																							
		</tr>
    );
});

const SortableQuestions = SortableContainer(({items, questionsSelectionnees, toggleQuestion, setEditingQuestion, domaines, cibles, categories, filtres, removeId}) => {
    return (
        <tbody id="tbody-questions">
            {items.map((value, index) => (
                <SortableQuestion 
                    key={`item-${value.id}`} 
                    sortIndex={value.numero} 
                    index={value.numero} 
                    value={value} 
                    questionsSelectionnees={questionsSelectionnees} 
                    toggleQuestion={toggleQuestion} 
                    setEditingQuestion={(question) => setEditingQuestion(question)}
                    items={items}
					domaines={domaines}
					cibles={cibles}
					categories={categories}
					filtres={filtres}
					removeId={(question) => removeId(question)}
                />
            ))}
            <tr>
				<td colSpan="7" className="tdaction">
					<a href="#" data-toggle="modal" data-target="#modal-questiondefaut" onClick={() => {setEditingQuestion(null)}} className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle question</a>
				</td>
			</tr>            
        </tbody>
    );
});

export default withRouter(connect(mapStateToProps, null)(Superadmin));