import React, { Component } from 'react';

import CalendarService from '../../services/calendar';
import FormPeriode from '../Forms/FormPeriode';

class TabPeriodes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            beneficiaire: JSON.parse(JSON.stringify(this.props.beneficiaire)),
            editingPeriode: null,
            periodesSelectionnees: []
        }
    }

    componentDidMount() {
        console.log("TabPeriodes did mount");

        this.bindModals();
    }

    componentDidUpdate(prevProps) {
        console.log("TabPeriodes did update");

        if (this.props != prevProps) {
            this.setState({
                beneficiaire: JSON.parse(JSON.stringify(this.props.beneficiaire))
            });
        }

        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-nouvelleperiode").off('hidden.bs.modal');
        window.jQuery("#modal-nouvelleperiode").on('hidden.bs.modal', () => {
            console.log("dismiss");
            this.setState({
                editingPeriode: null
            });
        });
        this.props.beneficiaire.periodes.map((periode) => {
            window.jQuery("#modal-modifperiode-" + periode.id).off('hidden.bs.modal');
            window.jQuery("#modal-modifperiode-" + periode.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingPeriode: null
                });
            });
        });
    }

    setEditingPeriode(periode) {
        this.setState({
            editingPeriode: periode
        });
    }

    togglePeriode(periode) {
        let periodesSelectionnees = this.state.periodesSelectionnees;
        if (periodesSelectionnees.includes(periode.id)) {
            periodesSelectionnees.splice(periodesSelectionnees.indexOf(periode.id), 1);
        }
        else {
            periodesSelectionnees.push(periode.id);
        }
        this.setState({
            periodesSelectionnees: periodesSelectionnees
        });
    }

    toggleAllPeriodes() {
        let periodesSelectionnees = [];
        if (this.state.periodesSelectionnees.length == this.getPeriodesAffichees().length) {
            periodesSelectionnees = [];
        }
        else {
            this.getPeriodesAffichees().map((periode) => {
                periodesSelectionnees.push(periode.id)
            });
        }
        this.setState({
            periodesSelectionnees: periodesSelectionnees
        })
    }

    modifieBeneficiaire(champ, val) {
        let beneficiaire = this.state.beneficiaire;
        if (champ == "periodes") {
            beneficiaire.periodes = val;
            this.enregistreBeneficiaire("periodes");
        }
        this.setState({
            beneficiaire: beneficiaire
        })
    }

    enregistreBeneficiaire(champ) {
        console.log(champ);
        let beneficiaire = null;
        if (champ == "periodes") {
            if (this.state.beneficiaire.periodes != this.props.beneficiaire.periodes) {
				beneficiaire = {
                    id: this.props.beneficiaire.id,
                    classe: this.props.beneficiaire.classe,
                    periodes: this.state.beneficiaire.periodes
                }
            }
        }
        if (beneficiaire) {
            this.props.enregistre(beneficiaire);
		}
    }

    ajouteModele() {
        let periodes = this.state.beneficiaire.periodes;
        let modele = JSON.parse(JSON.stringify(this.state.editingPeriode));
        let periode = JSON.parse(JSON.stringify(this.state.editingPeriode));
        modele.debutLastOccurrence = periode.debut;
        modele.finLastOccurrence = periode.fin;
        modele.occurrences = [periode];
        periodes.push(
            modele
        );
        this.modifieBeneficiaire("periodes", periodes);
    }

    modifiePeriode() {
        let iModele = null;
        let iPeriode = null;
        let periodes = this.state.beneficiaire.periodes;
        
        periodes.map((modele, index) => {
            if (modele.id == this.state.editingPeriode.modele) iModele = index;
        });

        periodes[iModele].occurrences.map((periode, index) => {
            if (periode.id == this.state.editingPeriode.id) iPeriode = index;
        })
        periodes[iModele].occurrences[iPeriode] = this.state.editingPeriode;

        this.modifieBeneficiaire("periodes", periodes);
    }

    modifieModele() {
        let iModele = null;
        let iPeriode = null;
        let periodes = this.state.beneficiaire.periodes;

        console.log("periodes", periodes);
        
        periodes.map((modele, index) => {
            if (modele.id == this.state.editingPeriode.modele) iModele = index;
        });

        console.log("iModele", iModele);

        periodes[iModele].occurrences.map((periode, index) => {
            if (periode.id == this.state.editingPeriode.id) iPeriode = index;
        })


        periodes[iModele].occurrences[iPeriode] = this.state.editingPeriode;

        periodes[iModele].description = periodes[iModele].occurrences[iPeriode].description;
        periodes[iModele].seuil = periodes[iModele].occurrences[iPeriode].seuil;
        periodes[iModele].commentaire = periodes[iModele].occurrences[iPeriode].commentaire;
        periodes[iModele].debut = periodes[iModele].occurrences[iPeriode].debut;
        periodes[iModele].fin = periodes[iModele].occurrences[iPeriode].fin; 
        periodes[iModele].debutLastOccurrence = periodes[iModele].occurrences[iPeriode].debut;
        periodes[iModele].finLastOccurrence = periodes[iModele].occurrences[iPeriode].fin;
        for (let j = 0 ; j < periodes[iModele].occurrences ; j++) {
            if (periodes[iModele].occurrences[j].id > periodes[iModele].occurrences[iPeriode].id) {
                let tabDebut = periodes[iModele].occurrences[j].debut.split('-');
                let tabFin = periodes[iModele].occurrences[j].fin.split('-');
                tabDebut[2] = periodes[iModele].occurrences[iPeriode].debut.split('-')[2];
                tabFin[2] = periodes[iModele].occurrences[iPeriode].fin.split('-')[2];
                if (periodes[iModele].occurrences[iPeriode].repetition.type == "annee" || periodes[iModele].occurrences[iPeriode].repetition.type == "none") {
                    tabDebut[1] = periodes[iModele].occurrences[iPeriode].debut.split('-')[1];
                    tabFin[1] = periodes[iModele].occurrences[iPeriode].fin.split('-')[1];
                }
                if (periodes[iModele].occurrences[iPeriode].repetition.type == "none") {
                    tabDebut[0] = periodes[iModele].occurrences[iPeriode].debut.split('-')[0];
                    tabFin[0] = periodes[iModele].occurrences[iPeriode].fin.split('-')[0];
                }
                periodes[iModele].occurrences[j].debut = tabDebut.join("-");
                periodes[iModele].occurrences[j].fin = tabFin.join("-");
            }
        }
        this.modifieBeneficiaire("periodes", periodes);
    }

    getModele(modeleId) {
        let modele = null;
        this.state.beneficiaire.periodes.map((m) => {
            if (m.id == modeleId) modele = m;
        });
        return modele;
    }

    getPeriodesAffichees() {
        let allPeriodes = [];
        this.props.beneficiaire.periodes.map((modele) => {
            allPeriodes = allPeriodes.concat(modele.occurrences)
        });
        console.log(allPeriodes);
        return allPeriodes;
    }

    supprimeModele(modele) {
        let periodes = this.state.beneficiaire.periodes;
        periodes[periodes.indexOf(modele)].supprime = true;
        this.modifieBeneficiaire("periodes", periodes);
    }

    supprimePeriode(periode) {
        let periodes = this.state.beneficiaire.periodes;
        let iModele = null;
        periodes.map((modele, index) => {
            if (modele.id == periode.modele) iModele = index;
        });
        if (iModele !== null) {
            let iPeriode = null;
            periodes[iModele].occurrences.map((p, index) => {
                if (p.id == periode.id) {
                    iPeriode = index;
                }
            })
            if (iPeriode !== null) {
                periodes[iModele].occurrences[iPeriode].supprime = true;
                console.log(periodes);
                this.modifieBeneficiaire("periodes", periodes);
            }            
        }
    }

    formValide() {
        console.log(this.state.editingPeriode);
        return (
            this.state.editingPeriode
            && this.state.editingPeriode.debut
            && this.state.editingPeriode.fin
            && this.state.editingPeriode.debut < this.state.editingPeriode.fin
            && this.state.editingPeriode.description != ""
            && this.state.editingPeriode.seuil != ""
            && this.state.editingPeriode.seuil > 0
            && (
                this.state.editingPeriode.repetition.type == "none"
                || (
                    this.state.editingPeriode.repetition.pas
                    && (
                        this.state.editingPeriode.repetition.fin.type == "none"
                        || this.state.editingPeriode.repetition.fin.type == "date" && this.state.editingPeriode.repetition.fin.date
                        || this.state.editingPeriode.repetition.fin.type == "occurrences" && this.state.editingPeriode.repetition.fin.occurrences != "" && this.state.editingPeriode.repetition.fin.occurrences > 0
                    )
                )
            )
        );
    }
    
	render() {

        let modals = [];

        let calendarService = new CalendarService();

        let periodesEnCours = [];
        this.props.beneficiaire.periodesEnCours.map((periode) => {
            periodesEnCours.push(periode.id);
        });

        let periodes = [];
        this.getPeriodesAffichees().sort((a, b) => {
            if (a.enCours != b.enCours) return a.enCours ? -1 : 1;
            return (new Date(a.debut) > new Date(b.debut)) ? -1 : 1;
        }).map((periode, index) => {
            let debut = new Date(periode.debut);
            let fin = (periode.finAnticipee ? new Date(periode.finAnticipee) : new Date(periode.fin));
            console.log(fin);
            periodes.push(
				<tr key={"row-periode-" + periode.id} className={(this.state.periodesSelectionnees.includes(periode.id) ? "table-warning" : "") + " " + (fin < new Date() ? "obsolete" : "")}>
                    <td>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id={"toggle-periode-" + periode.id} onChange={() => this.togglePeriode(periode)} checked={this.state.periodesSelectionnees.includes(periode.id)} />
                            <label htmlFor={"toggle-periode-" + periode.id} className="toggle"></label>
                        </div>
                    </td>
                    <td>
                        <p className="m-0 table-nom text-muted font-14">{periode.description}</p>
                    </td>
                    {periodesEnCours.includes(periode.id) && 
                        <td>
                            <p className="m-0 text-muted font-14">Jusqu'au {fin.getDate()} {calendarService.mois()[fin.getMonth()].nomCourt} {fin.getFullYear()}</p>
                        </td>
                    }
                    {!periodesEnCours.includes(periode.id) && 
                        <td>
                            <p className="m-0 text-muted font-14">Du {debut.getDate()} {calendarService.mois()[debut.getMonth()].nomCourt} {debut.getFullYear()} au {fin.getDate()} {calendarService.mois()[fin.getMonth()].nomCourt} {fin.getFullYear()}</p>
                        </td>   
                    }
                    <td>
                        <p className="m-0 text-muted font-14">Seuil d'alerte temporaire : {periode.seuil}</p>
                    </td>
                    <td>
                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifperiode-" + periode.id} onClick={() => this.setEditingPeriode(periode)}><i className="dripicons-document-edit"></i></a></span>
                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionperiode-" + periode.id}><i className="dripicons-trash"></i></a></span>
                    </td>
                </tr>
            );
            
            modals.push(
                <div key={"modal-modifperiode-" + periode.id} id={"modal-modifperiode-" + periode.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Période</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    {this.state.editingPeriode && this.state.editingPeriode.repetition.type != "none" && 
                                        <>
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formValide() || (this.getModele(this.state.editingPeriode.modele) && JSON.stringify(this.state.editingPeriode.repetition) != JSON.stringify(this.getModele(this.state.editingPeriode.modele).repetition))} onClick={() => this.modifiePeriode()}>Enr. cette occurrence</button>
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formValide()} onClick={() => this.modifieModele()}>Enr. cette occurrence et les suivantes</button>
                                        </>
                                    }
                                    {this.state.editingPeriode && this.state.editingPeriode.repetition.type == "none" && 
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formValide()} onClick={() => this.modifieModele()}>Enregistrer</button>
                                    }
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormPeriode
                                    periode={this.state.editingPeriode}
                                    modifie={(periode) => this.setEditingPeriode(periode)}
                                    modele={this.state.editingPeriode ? this.getModele(this.state.editingPeriode.modele) : null}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
            );
            let suppressionModele = false;
            let suppressionPeriode = false;
            let modele = this.getModele(periode.modele);
            console.log("btn suppr" + periode.description + periode.id);
            if (modele) {
                console.log("modele");
                if (modele.repetition.type == "none") {
                    suppressionModele = true;
                    console.log("type none");
                }
                else {
                    console.log("type else");
                    suppressionPeriode = true;
                    if (modele.occurrences.length > 0 && modele.occurrences[0].id == periode.id) {
                        suppressionModele = true;
                    }
                }
            }
            modals.push(
                <div key={"modal-suppressionperiode-" + periode.id} id={"modal-suppressionperiode-" + periode.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer la période sensible</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>La période sensible "{periode.description}" pour <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> sera supprimée. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
                                {suppressionPeriode && <button type="button" className="btn btn-primary suppression" onClick={() => this.supprimePeriode(periode)} data-dismiss="modal">{suppressionModele ? "Suppr. cette occurrence" : "Suppr. la période sensible"}</button>}
                                {suppressionModele && <button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeModele(this.getModele(periode.modele))} data-dismiss="modal">{suppressionPeriode ? "Suppr. cette occurrence et les suivantes" : "Suppr. la période sensible"}</button>}
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
            );
        });

        let aucunePeriode = null;
        if (periodes.length == 0) {
			aucunePeriode =
				<tr key="row-periode-0">
					<td></td>
					<td>
						<p className="m-0 text-muted font-14">Aucune période sensible.</p>
					</td>
					<td></td>
                    <td></td>
                    <td></td>
				</tr>
			;
		}

        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="periodes" role="tabpanel">
                <a href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouvelleperiode"><i className="mdi mdi-plus"></i> Nouvelle période sensible</a>
                <br clear="both" />
                <div className="table-responsive">
                    <table className="table table-vertical">
                        <thead>
                            <tr>
                                <th>
                                    <div className={"checkbox-wrapper" + (this.state.periodesSelectionnees.length != this.props.beneficiaire.periodes.length ? " selection" : "")}>
                                        <input type="checkbox" id="toggle-periodes-all" checked={this.state.periodesSelectionnees.length > 0} onChange={() => this.toggleAllPeriodes()} />
                                        <label htmlFor="toggle-periodes-all" className="toggle"></label>
                                    </div>
                                </th>
                                {this.state.periodesSelectionnees.length == 0 &&
                                    <>
                                        <th>Objet</th>
                                        <th>Durée</th>
                                        <th>Seuil</th>
                                        <th>Actions</th>
                                    </>
                                }
                                {this.state.periodesSelectionnees.length > 0 &&
                                    <th colSpan="4">
                                        {/*<a href="#" data-toggle="modal" data-target="#modal-suppressionperiodes" className="btn btn-annexe contour m-b-20"><i className="dripicons-trash"></i> Supprimer</a>*/}
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {periodes}
                            {aucunePeriode}
                            <tr>
                                <td colSpan="5" className="tdaction">
                                    <a href="#" data-toggle="modal" data-target="#modal-nouvelleperiode" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle période sensible</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>     
                <div id="modal-nouvelleperiode" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Période</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" disabled={!this.formValide()} data-dismiss="modal" onClick={() => this.ajouteModele()}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormPeriode
                                    periode={this.state.editingPeriode}
                                    modifie={(periode) => this.setEditingPeriode(periode)}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
                {modals}                                            					   
            </div>
        )
	}
}
  

export default TabPeriodes;