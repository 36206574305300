import moment from 'moment';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Api from '../../api';

class SidebarProche extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log("SidebarProche did mount");
    }

    enregistreProche(champ, val) {
        let proche = null;
        if (champ == "actif") {
            proche = {
                id: this.props.proche.id,
                classe: this.props.proche.classe,
                actif: val
            }
        }
        if (champ == "supprime") {
            proche = {
                id: this.props.proche.id,
                classe: this.props.proche.classe,
                supprime: val
            }
        }
        if (proche) {
            this.props.enregistre(proche);
		}
    }

    envoieQuestionTest() {
		let api = new Api();
		api.sendPersonneQuestionTest(this.props.proche).then((json) => {
			console.log(json);
		}).catch(err => {
			console.log(err);
		});
    }

    envoieEmailConsentement() {
        let api = new Api();
		api.sendPersonneConsentement(this.props.proche).then((json) => {
			console.log(json);
            if (json.succes) {
                this.props.enregistre({
                    id: this.props.proche.id,
                    classe: this.props.proche.classe,
                    etatConsentement: true // fausse valeur
                })
            }
		}).catch(err => {
			console.log(err);
		});
    }
    
	render() {
 
        let questionsEnPause = null;
		if (this.props.proche.pauseQuestions) {
			questionsEnPause = 
                <>
                    <div className="text-center signaux">
                        <p className="pause" onClick={() => this.props.goToQuestions()}><i className="mdi mdi-pause-circle-outline"></i> Questions automatiques en pause</p>
                        <br clear="both" />
                    </div>
                    <hr />
                </>
            ;
		}

        console.log(this.props.proche)

        return (
            <div className="card card-profil sidebar m-b-20">
                <div className="card-body text-center">
                    <h4 className="mt-0 header-title mb-3 text-center">{this.props.proche.prenom} {this.props.proche.nom}</h4>
                    <br clear="both" />
                    <div className="prochede">Proche de : <NavLink to={"/utilisateurs/" + this.props.beneficiaire.id}><span>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</span></NavLink></div>
                    {/*
                    {this.props.proche.description == "superieur" &&
                        <>
                            <hr />
                            {questionsEnPause}
                            <a href="#" className="btn btn-annexe contour text-center w-100" data-toggle="modal" data-target="#modal-questiontest"><i className="mdi mdi-comment-question-outline"></i> Envoyer une question test</a>
                            <div id="modal-questiontest" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0">Envoyer une question test</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Etes vous sur de vouloir envoyer une question à <b>{this.props.proche.prenom} {this.props.proche.nom}</b> qui ne sera pas comptabilisée dans les résultats. Cela peut vous permettre de lui faire une démonstration. Voulez-vous continuer ?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" onClick={() => this.envoieQuestionTest()} data-dismiss="modal">Envoyer la question test</button>
                                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }*/}
                    <hr />
                    <div className="prochede">Consentement : {this.props.proche.etatConsentement ? this.props.proche.etatConsentement == "accepte" ? "Accepté" : "Refusé" : this.props.proche.dateDemandeConsentement ? ("En attente (depuis le " + moment(this.props.proche.dateDemandeConsentement, "YYYY/MM/DD HH:mm:ss").format('DD/MM/YYYY') + ")") : "Non demandé"}</div>
                    {this.props.proche.etatConsentement == "refuse" &&
                        <>
                            <a href="#" data-toggle="modal" data-target="#modal-procheconsentement" className="btn btn-annexe contour contour text-center w-100">
                                <i className="mdi mdi-send"></i>
                                <>Envoyer un nouvel email pour obtenir le consentement</>
                            </a>
                            <div id="modal-procheconsentement" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0">{this.props.proche.actif ? "Désactiver" : "Réactiver" } la personne</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Un nouvel email de recueil du consentement va être envoyé à <b>{this.props.proche.prenom} {this.props.proche.nom}</b>. Voulez-vous continuer ?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" onClick={() => this.envoieEmailConsentement()} data-dismiss="modal">Envoyer</button>
                                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }       
                    <hr />             
                    <a href="#" data-toggle="modal" data-target="#modal-toggleactivationproche" className={"btn btn-annexe contour text-center w-100" + (this.props.proche.actif ? " attention" : " action")}><i className="mdi mdi-block-helper"></i> {this.props.proche.actif ? "Désactiver" : "Réactiver" } la personne</a>
                    <div key="modal-toggleactivationproche" id="modal-toggleactivationproche" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">{this.props.proche.actif ? "Désactiver" : "Réactiver" } la personne</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    {this.props.proche.actif && <p>L'ensemble des services de suivi concernant <b>{this.props.proche.prenom} {this.props.proche.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
                                    {!this.props.proche.actif && <p>L'ensemble des services de suivi concernant <b>{this.props.proche.prenom} {this.props.proche.nom}</b> seront réactivés. Voulez-vous continuer ?</p>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.enregistreProche("actif", !this.props.proche.actif)} data-dismiss="modal">{this.props.proche.actif ? "Désactiver" : "Réactiver" } la personne</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="#" data-toggle="modal" data-target="#modal-suppressionproche" className={"btn btn-annexe contour text-center w-100 attention suppression"}><i className="dripicons-trash"></i> Supprimer le compte</a>
                    <div key="modal-suppressionproche" id="modal-suppressionproche" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Supprimer le compte de la personne</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>L'ensemble des services de suivi concernant <b>{this.props.proche.prenom} {this.props.proche.nom}</b> seront supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary suppression" onClick={() => this.enregistreProche("supprime", true)} data-dismiss="modal">Supprimer le compte de la personne</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
	}
}
  

export default SidebarProche;