import React, { Component } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import CalendarService from '../../services/calendar';

import FormQuestion from '../Forms/FormQuestion';
import DataService from '../../services/data';
import { questionValide } from '../../services/validation';

const SortableQuestion = SortableElement(({value, items, questionsSelectionnees, toggleQuestion, setEditingQuestion}) => {
    let question = value;

    let calendarService = new CalendarService();

    let joursSemaine = [];
    for (let i = 1 ; i <= 7 ; i++) {
        joursSemaine.push(
            <span key={"btn-jourSemaine-" + (i % 7)} className={"jour" + (question.repetition.type == "jour" || question.repetition.joursSemaine.includes((i % 7)) ? " actif" : "")}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
        );
    }

    let joursMois = [];
    question.repetition.mois.joursAbsolus.sort((a,b) => (a < b) ? -1 : 1).map((i) => {
        joursMois.push(
            <span key={"btn-jourMois-" + i} className={"jour" + (question.repetition.mois.joursAbsolus.includes(i) ? " actif" : "")}>{i}</span>
        );
    });

    let jourAn = "Envoyée tous les " + parseInt(question.repetition.jourAn.jour) + " " + calendarService.mois()[parseInt(question.repetition.jourAn.mois)].nom;

    return (
        <tr key={"row-question-" + question.id} className={questionsSelectionnees.includes(question.id) ? "table-warning" : ""}>
            <td>
                <div className="checkbox-wrapper">
                    <input type="checkbox" id={"toggle-question-" + question.id} onChange={() => toggleQuestion(question)} checked={questionsSelectionnees.includes(question.id)} />
                    <label htmlFor={"toggle-question-" + question.id} className="toggle"></label>
                </div>
            </td>
            <td className="libelle-question">
                <p className="m-0 table-nom text-muted font-14">{question.libelle}</p>
            </td>
            <td>
                <p className="m-0 text-muted font-14">
                    {["jour", "semaine"].includes(question.repetition.type) && <>{joursSemaine}</>}
                    {["semaine-paire", "semaine-impaire"].includes(question.repetition.type) && <>Semaines {question.repetition.type == "semaine-impaire" ? "im" : ""}paires<br />{joursSemaine}</>}
                    {["mois"].includes(question.repetition.type) && <>{joursMois}</>}
                    {["an"].includes(question.repetition.type) && <>{jourAn}</>}
                    {/*{["reglages"].includes(question.repetition.type) && "Déclenchée selon les réglages de la personne"}*/}
                    {question.repetition.type == "none" && "Déclenchée par une autre question"}
                    {question.repetition.type == "aleatoire" && "Déclenchée aléatoirement"}
                </p>
            </td>
            <td>
                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifquestion-" + question.id} onClick={() => setEditingQuestion(question)}><i className="dripicons-document-edit"></i></a></span>
                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionquestion-" + question.id}><i className="dripicons-trash"></i></a></span>
            </td>
            <td>
                {items.length > 1 &&<div className="deplacer"><i className="dripicons-move"></i></div>}
            </td>	
        </tr>
    );
});

const SortableQuestions = SortableContainer(({items, questionsSelectionnees, toggleQuestion, setEditingQuestion, cible}) => {
    return (
        <tbody id="tbody-questions">
            {items.map((value, index) => (
                <SortableQuestion 
                    key={`item-${value.id}`} 
                    sortIndex={value.numero} 
                    index={value.numero} 
                    value={value} 
                    questionsSelectionnees={questionsSelectionnees} 
                    toggleQuestion={toggleQuestion} 
                    setEditingQuestion={setEditingQuestion}
                    items={items}
                />
            ))}
            {items.length == 0 &&
                <tr key="row-question-0">
                    <td></td>
                    <td><p className="m-0 text-muted font-14">Aucune question.</p></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            }
            <tr>
                <td colSpan="5" className="tdaction">
                    <a href="#" data-toggle="modal" data-target={"#modal-nouvellequestiondefaut-" + cible} className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle question</a>
                </td>
            </tr>
        </tbody>
    );
});

class Bibliotheque extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: props.bibliotheque.questions,
            editingQuestion: null,
            editingQuestionDeclenchee: false,
            questionsSelectionnees: [],
            personne: {
                id: null,
                classe: props.bibliotheque.cible == "beneficiaire" ? "Beneficiaire" : "Proche",
                nom: "Defaut",
                prenom: "Defaut",
                prefixeMessage: "",
                suffixeMessage: "",
                modalite: props.bibliotheque.cible == "beneficiaire" ? "sms" : "email",
                questions: props.bibliotheque.questions
            },
            draggable: false
        }
    }

    componentDidMount() {
        console.log("Bibliotheque did mount");

        this.bindModals();
    }

    componentDidUpdate(prevProps) {
        console.log("Bibliotheque did update");

        if (prevProps != this.props) {
            this.setState({
                questions: this.props.bibliotheque.questions,
                personne: {
                    id: null,
                    classe: this.props.bibliotheque.cible == "beneficiaire" ? "Beneficiaire" : "Proche",
                    nom: "Defaut",
                    prenom: "Defaut",
                    prefixeMessage: "",
                    suffixeMessage: "",
                    modalite: this.props.bibliotheque.cible == "beneficiaire" ? "sms" : "email",
                    questions: this.props.bibliotheque.questions
                },
            })
        }

        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-nouvellequestiondefaut-" + this.props.bibliotheque.cible).off('hidden.bs.modal');
        window.jQuery("#modal-nouvellequestiondefaut-" + this.props.bibliotheque.cible).on('hidden.bs.modal', () => {            
            this.setState({
                editingQuestion: null
            });
        });
        this.props.bibliotheque.questions.map((question) => {
            window.jQuery("#modal-modifquestion-" + question.id).off('hidden.bs.modal');
            window.jQuery("#modal-modifquestion-" + question.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingQuestion: null
                });
            });
        });
    }

    toggleQuestion(question) {
        let questionsSelectionnees = this.state.questionsSelectionnees;
        if (questionsSelectionnees.includes(question.id)) {
            questionsSelectionnees.splice(questionsSelectionnees.indexOf(question.id), 1);
        }
        else {
            questionsSelectionnees.push(question.id);
        }
        this.setState({
            questionsSelectionnees: questionsSelectionnees
        });
    }

    toggleAllQuestions() {
        let questionsSelectionnees = [];
        if (this.state.questionsSelectionnees.length == this.props.bibliotheque.questions.length) {
            questionsSelectionnees = [];
        }
        else {
            this.props.bibliotheque.questions.map((question) => {
                questionsSelectionnees.push(question.id)
            });
        }
        this.setState({
            questionsSelectionnees: questionsSelectionnees
        })
    }

    setEditingQuestion(question) {
        this.setState({
            editingQuestion: question
        });
    }

    enregistreQuestion() {        
        let bibliotheque = JSON.parse(JSON.stringify(this.props.bibliotheque))
        if (this.state.editingQuestion.id) {
            bibliotheque.questions.map((question, iQuestion) => {
                if (question.id == this.state.editingQuestion.id) {
                    bibliotheque.questions[iQuestion] = this.state.editingQuestion
                }
            })
        }   
        else {
            bibliotheque.questions.push(this.state.editingQuestion)
        }     
        this.props.enregistre(bibliotheque)         
    }

    supprimeQuestion(q) {
        let bibliotheque = JSON.parse(JSON.stringify(this.props.bibliotheque))
        bibliotheque.questions.map((question, iQuestion) => {
            if (question.id == q.id) {
                bibliotheque.questions[iQuestion].supprime = true
            }
        })
        this.props.enregistre(bibliotheque);
    }

    supprimeQuestions() {
        let bibliotheque = JSON.parse(JSON.stringify(this.props.bibliotheque))
            
        this.state.questionsSelectionnees.map((q) => {
            bibliotheque.questions.map((question, iQuestion) => {
                if (question.id == q) {
                    bibliotheque.questions[iQuestion].supprime = true
                }
            })            
        });
        this.props.enregistre(bibliotheque);            
        this.setState({
            questionsSelectionnees: []
        })
    }

    reordonneQuestions(oldIndex, newIndex) {
        let questions = this.state.questions;
        if (oldIndex != newIndex) {
            let question = null;
            this.state.questions.map((q, index) => {
                if (q.numero == oldIndex) {
                    questions[index].numero = (oldIndex > newIndex) ? (newIndex - 1) : (newIndex + 1);
                    question = {
                        id: q.id,
                        classe: q.classe,
                        numero: questions[index].numero
                    };
                }
            });
            console.log(question);
            if (question) {
                this.setState({
                    questions: questions
                });       
                let bibliotheque = JSON.parse(JSON.stringify(this.props.bibliotheque))
                bibliotheque.questions = questions           
                this.props.enregistre(bibliotheque);
            }
        }    
    }

    formValide() {
        /*return (
            this.state.editingQuestion
            && this.state.editingQuestion.libelle != ""
            && (
                this.state.editingQuestion.type == "liste"
                || (
                    this.state.editingQuestion.type == "gradient"
                    && this.state.editingQuestion.parametres.gradient.min
                    && this.state.editingQuestion.parametres.gradient.max
                    && this.state.editingQuestion.parametres.gradient.min < this.state.editingQuestion.parametres.gradient.max
                )
                || this.state.editingQuestion.type == "information"
            )
            && (
                this.state.editingQuestion.type == "information"
                || (
                    this.state.editingQuestion.reponses.length > 0
                    && this.formReponsesValide()
                )
            )
            && (
                this.state.editingQuestion.repetition.fin.type == "none"
                || this.state.editingQuestion.repetition.fin.type == "date" && this.state.editingQuestion.repetition.fin.date
                || this.state.editingQuestion.repetition.fin.type == "occurrences" && this.state.editingQuestion.repetition.fin.occurrences != "" && this.state.editingQuestion.repetition.fin.occurrences > 0
            )
        );*/

        return questionValide(this.state.editingQuestion)
    }

    /*formValide() {
        return (
            this.state.editingQuestion
            && this.state.editingQuestion.libelle != ""
            && (
                this.state.editingQuestion.type == "liste"
                || (
                    this.state.editingQuestion.type == "gradient"
                    && this.state.editingQuestion.parametres.gradient.min
                    && this.state.editingQuestion.parametres.gradient.max
                    && this.state.editingQuestion.parametres.gradient.min < this.state.editingQuestion.parametres.gradient.max
                )
            )
            && this.state.editingQuestion.reponses.length > 0
            && this.formReponsesValide()
            && (
                this.state.editingQuestion.repetition.fin.type == "none"
                || this.state.editingQuestion.repetition.fin.type == "date" && this.state.editingQuestion.repetition.fin.date
                || this.state.editingQuestion.repetition.fin.type == "occurrences" && this.state.editingQuestion.repetition.fin.occurrences != "" && this.state.editingQuestion.repetition.fin.occurrences > 0
            )
        );
    }*/

    formReponsesValide() {
        let valide = true;
        if (this.state.editingQuestion && this.state.editingQuestion.type == "liste") {
            this.state.editingQuestion.reponses.map((reponse) => {
                if (
                    reponse.libelle == ""
                ) {
                    valide = false;
                }
            })
        }
        return valide;
    }
    
	render() {

        let modals = [];

        let questionsDeclenchees = [];
        this.props.bibliotheque.questions.map((question, index) => {			
            if (question.repetition.type == "none") {
                questionsDeclenchees.push(question);
            }
        });

        this.props.bibliotheque.questions.map((question, index) => {	
            modals.push(
                <div key={"modal-modifquestion-" + question.id} id={"modal-modifquestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Question</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    {this.state.editingQuestionDeclenchee === false && <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.enregistreQuestion()} disabled={!this.formValide()}>Enregistrer</button>}
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormQuestion 
                                    question={this.state.editingQuestion}
                                    personne={this.state.personne}
                                    questionsDeclenchees={questionsDeclenchees}
                                    modifie={(question) => this.setEditingQuestion(question)}
                                    numero={(this.props.bibliotheque.questions.length + 1) * 2}
                                    setEditingQuestionDeclenchee={(editingQuestionDeclenchee) => this.setState({editingQuestionDeclenchee})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
            modals.push(
                <div key={"modal-suppressionquestion-" + question.id} id={"modal-suppressionquestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer la question</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>La question <b>{question.libelle}</b> sera désactivée et supprimée. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestion(question)} data-dismiss="modal">Supprimer la question</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
            );
        });

        let nomCible = "Utilisateurs";
        if (this.props.bibliotheque.cible == "proche-superieur") {
            nomCible = "Employeurs";
        }
        
        return (            
            <>
                <h3 className="ml-3 mt-3">{nomCible} - {this.props.bibliotheque.nom}</h3>
                <a href="#" data-toggle="modal" data-target={"#modal-nouvellequestiondefaut-" + this.props.bibliotheque.cible} className="m-b-20 btn btn-primary float-right"><i className="mdi mdi-plus"></i> Nouvelle question</a>
                <br clear="both" />
                <div className="table-responsive">
                    <table className="table table-vertical" id="ordre">
                        <thead>
                            <tr>
                                <th>
                                    {this.state.questions.length > 0 &&
                                        <div className={"checkbox-wrapper" + (this.state.questionsSelectionnees.length != this.props.bibliotheque.questions.length ? " selection" : "")}>
                                            <input type="checkbox" id="toggle-questions-all" checked={this.state.questionsSelectionnees.length > 0} onChange={() => this.toggleAllQuestions()} />
                                            <label htmlFor="toggle-questions-all" className="toggle"></label>
                                        </div>
                                    }
                                </th>
                                {this.state.questionsSelectionnees.length == 0 &&
                                    <>
                                        <th>Libellé</th>
                                        <th>Déclenchement</th>
                                        <th>Actions</th>
                                        <th></th>
                                    </>
                                }
                                {this.state.questionsSelectionnees.length > 0 &&
                                    <th colSpan="4">
                                        {/*<a href="#" className="btn btn-annexe contour m-b-20"><i className="mdi mdi-calendar-clock"></i> Modifier la récurrence</a>*/}
                                        <a href="#" data-toggle="modal" data-target="#modal-suppressionquestionsdefaut" className="btn btn-annexe contour m-b-20"><i className="dripicons-trash"></i> Supprimer</a>
                                    </th>
                                }
                            </tr>
                        </thead>
                        <SortableQuestions
                            shouldCancelStart={(e) => {
                                return !e.target.classList.contains("dripicons-move");
                            }}
                            onSortEnd={({oldIndex, newIndex, collection, isKeySorting}, e) => {
                                this.reordonneQuestions(oldIndex, newIndex);
                            }}
                            helperContainer={() => {
                                return document.getElementById("tbody-questions");                                
                            }}
                            items={this.state.questions.filter(q => q.autreType == null).sort((a, b) => (a.numero < b.numero) ? -1 : 1)}
                            questionsSelectionnees={this.state.questionsSelectionnees}
                            toggleQuestion={(question) => this.toggleQuestion(question)}
                            setEditingQuestion={(question) => this.setEditingQuestion(question)}
                            cible={this.props.bibliotheque.cible}
                        />
                    </table>
                </div>  
                <div id={"modal-nouvellequestiondefaut-" + this.props.bibliotheque.cible} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Nouvelle question</h5>
                                <div className="header-buttons">
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                {this.state.editingQuestionDeclenchee === false && <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.enregistreQuestion()} disabled={!this.formValide()}>Enregistrer</button>}
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormQuestion 
                                    question={this.state.editingQuestion}
                                    personne={this.state.personne}
                                    questionsDeclenchees={questionsDeclenchees}
                                    modifie={(question) => this.setEditingQuestion(question)}
                                    numero={(this.props.bibliotheque.questions.length + 1) * 2}
                                    setEditingQuestionDeclenchee={(editingQuestionDeclenchee) => this.setState({editingQuestionDeclenchee})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-suppressionquestionsdefaut" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des questions</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Les questions sélectionnées seront désactivées et supprimées. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestions()} data-dismiss="modal">Supprimer les questions</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                {modals}                                                 					   
            </>
        )
	}
}
  

export default Bibliotheque;