import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Footer from '../components/Footer';

import Api from '../api/index';

import DataService from '../services/data';
import SecurityService from '../services/security';
import RouteService from '../services/route';

import FormBeneficiaire from '../components/Forms/FormBeneficiaire';
import FormConseiller from '../components/Forms/FormConseiller';
import { getTerme } from '../services/contextualisation';

const mapStateToProps = (state, props) => {
	let dataService = new DataService();
	let securityService = new SecurityService();

	let structure = dataService.getStructureById(props.route.match.params.id);

	if (!structure) {
		if (securityService.isResponsable()) {
			structure = dataService.getMyStructure();
		}
	}

	return {
	  data: state.data,
	  ihm: state.ihm,
	  structure: structure,
	  history: props.route.history
	}
  }

class Structure extends Component {

	constructor(props) {
		super(props);

		this.state = {
			editingConseiller: null,
			nouveauResponsable: false,
			structure: JSON.parse(JSON.stringify(props.structure)),
			conseillersSelectionnes: [],
			responsablesSelectionnes: [],
			beneficiairesSelectionnes: [],
			mailEnvoyeResponsable: null,
			statistiquesDebut: moment().startOf('year').toDate(),
			statistiquesFin: moment().endOf('year').toDate(),
			filtresBeneficiaires: [],
			filtresConseillers: [],
			filtresGroupeBeneficiaires: [],
			filtresGroupeConseillers: [],
			erreurs: [],
			motifSuppression: 'depart'
		}
	}
	
	componentDidMount() {
		console.log("structure did mount");
		if (!this.props.structure) {
			console.log("oups");
			this.props.history.replace('/');
		}

		this.bindModals();
		this.bindSelect2();

		console.log(this.props.location);
		console.log(this.props.ihm.history);
		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});
	}

	componentDidUpdate(prevProps) {
		console.log("structure did update");

		if (prevProps.structure != this.props.structure) {
			this.setState({
				structure: JSON.parse(JSON.stringify(this.props.structure)),
			});
		}

		this.bindModals();
	}

	bindModals() {
        window.jQuery("#modal-nouveauconseiller").off('hidden.bs.modal');
        window.jQuery("#modal-nouveauconseiller").on('hidden.bs.modal', () => {
            this.setState({
				editingConseiller: null,
				nouveauResponsable: false
            });
		});

		window.jQuery("#modal-filtresconseillers").off('hidden.bs.modal');
        window.jQuery("#modal-filtresconseillers").on('hidden.bs.modal', () => {
            this.setState({
				filtresGroupeConseillers: []
            });
		});

		window.jQuery("#modal-filtresbeneficiaires").off('hidden.bs.modal');
        window.jQuery("#modal-filtresbeneficiaires").on('hidden.bs.modal', () => {
            this.setState({
				filtresGroupeBeneficiaires: []
            });
		});

		window.jQuery(".modal-suppression").off('hidden.bs.modal');
		window.jQuery(".modal-suppression").on('hidden.bs.modal', () => {
            this.setState({
				motifSuppression: 'depart'
            });
		});
	}

	bindSelect2() {
        window.jQuery("#filtre-beneficiaires-filtres").off('change');
        window.jQuery("#filtre-beneficiaires-filtres").on('change', (e) => {
            console.log(window.jQuery("#filtre-beneficiaires-filtres").val());
            this.setState({
                filtresBeneficiaires: window.jQuery("#filtre-beneficiaires-filtres").val(),
				beneficiairesSelectionnes: []
            });            
        });

		window.jQuery("#filtre-conseillers-filtres").off('change');
        window.jQuery("#filtre-conseillers-filtres").on('change', (e) => {
            console.log(window.jQuery("#filtre-conseillers-filtres").val());
            this.setState({
                filtresConseillers: window.jQuery("#filtre-conseillers-filtres").val(),
				conseillersSelectionnes: []
            });            
        });
    }

	changeTab(tab) {
		this.setState({
			tab: tab
		})
		this.props.dispatch({type: 'IHM_STRUCTURE_TAB', payload: tab});
	}

	changeTabBeneficiaire(tab) {
		this.props.dispatch({type: 'IHM_BENEFICIAIRE_TAB', payload: tab});
	}

	toggleConseiller(conseiller) {
        let conseillersSelectionnes = this.state.conseillersSelectionnes;
        if (conseillersSelectionnes.includes(conseiller.id)) {
            conseillersSelectionnes.splice(conseillersSelectionnes.indexOf(conseiller.id), 1);
        }
        else {
            conseillersSelectionnes.push(conseiller.id);
        }
        this.setState({
            conseillersSelectionnes: conseillersSelectionnes
        });
    }

    toggleAllConseillers() {
		let dataService = new DataService()

		let conseillersSelectionnes = [];
		if (this.state.conseillersSelectionnes.length == dataService.getStructureConseillers(this.props.structure).filter((conseiller) => !conseiller.seulementAdmin).length) {
            conseillersSelectionnes = [];
        }
        else {
            dataService.getStructureConseillers(this.props.structure).filter((conseiller) => !conseiller.seulementAdmin).map((conseiller) => {
                conseillersSelectionnes.push(conseiller.id)
            });
		}		

        this.setState({
            conseillersSelectionnes: conseillersSelectionnes
        })
	}

	toggleResponsable(responsable) {
		console.log(responsable);
        let responsablesSelectionnes = this.state.responsablesSelectionnes;
        if (responsablesSelectionnes.includes(responsable.id)) {
            responsablesSelectionnes.splice(responsablesSelectionnes.indexOf(responsable.id), 1);
        }
        else {
            responsablesSelectionnes.push(responsable.id);
		}
		console.log(responsablesSelectionnes);
        this.setState({
            responsablesSelectionnes: responsablesSelectionnes
        });
    }

    toggleAllResponsables() {
		let dataService = new DataService();
        let responsablesSelectionnes = [];
        if (this.state.responsablesSelectionnes.length == dataService.getStructureConseillers(this.props.structure).filter((conseiller) => this.props.structure.responsables.includes(conseiller.id) || conseiller.seulementAdmin).length) {
            responsablesSelectionnes = [];
        }
        else {
            dataService.getStructureConseillers(this.props.structure).filter((conseiller) => this.props.structure.responsables.includes(conseiller.id) || conseiller.seulementAdmin).map((responsable) => {
                responsablesSelectionnes.push(responsable.id)
            });
        }
        this.setState({
            responsablesSelectionnes: responsablesSelectionnes
        })
	}

	contientAllResponsables(liste) {
		let all = true;
		this.props.structure.responsables.map(r => {
			if (!liste.includes(r)) {
				all = false;
			}
		})
		return all;
	}
	
	toggleBeneficiaire(beneficiaire) {
        let beneficiairesSelectionnes = this.state.beneficiairesSelectionnes;
        if (beneficiairesSelectionnes.includes(beneficiaire.id)) {
            beneficiairesSelectionnes.splice(beneficiairesSelectionnes.indexOf(beneficiaire.id), 1);
        }
        else {
            beneficiairesSelectionnes.push(beneficiaire.id);
        }
        this.setState({
            beneficiairesSelectionnes: beneficiairesSelectionnes
        });
    }

    toggleAllBeneficiaires() {
		let beneficiairesSelectionnes = [];
        if (this.state.beneficiairesSelectionnes.length == this.props.structure.beneficiaires.length) {
            beneficiairesSelectionnes = [];
        }
        else {
            this.props.structure.beneficiaires.map((beneficiaire) => {
                beneficiairesSelectionnes.push(beneficiaire)
            });
        }
        this.setState({
            beneficiairesSelectionnes: beneficiairesSelectionnes
        })
    }

	changeStructure(champ, val) {
		let structure = this.state.structure;
		if (champ == "nom") {	
			structure.nom = val;
		}
		if (champ == "accesSuiviActivite") {	
			structure.accesSuiviActivite = !structure.accesSuiviActivite;
			this.blurInformationStructure(champ);
		}
		if (champ == "nbMaxConseillers") {	
			structure.nbMaxConseillers = val;
			this.blurInformationStructure(champ);
		}
		if (champ == "nbMaxBeneficiaires") {	
			structure.nbMaxBeneficiaires = val;
			this.blurInformationStructure(champ);
		}
		if (champ == "nbMaxBeneficiairesSuiviAvance") {	
			structure.nbMaxBeneficiairesSuiviAvance = val;
			this.blurInformationStructure(champ);
		}
		if (champ == "nbMaxBeneficiairesSuiviNormal") {	
			structure.nbMaxBeneficiairesSuiviNormal = val;
			this.blurInformationStructure(champ);
		}
		if (champ == "nbMessagesAvantAvertissement") {	
			structure.nbMessagesAvantAvertissement = val;
			this.blurInformationStructure(champ);
		}
		if (champ == "responsables") {
			structure.responsables = val;
			this.blurInformationStructure(champ);
		}
		if (champ == "type") {	
			structure.type = val;			
			this.blurInformationStructure(champ);
			if (structure.type == "autre") {
				structure.precisionType = ""
			}
		}
		if (champ == "precisionType") {	
			structure.precisionType = val;						
		}
		if (champ == "actif") {	
			structure.actif = val;
			this.blurInformationStructure(champ);
		}
		if (champ == "supprime") {	
			structure.supprime = val;
			this.blurInformationStructure(champ);
		}
		this.setState({
			structure: structure
		})		
	}
	
	blurInformationStructure(champ) {
		let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
		let structure = null;
		if (champ == "nom") {
			if (this.state.structure.nom != this.props.structure.nom) {
                if (this.state.structure.nom != "") {
                    structure = {
                        id: this.props.structure.id,
                        classe: this.props.structure.classe,
                        nom: this.state.structure.nom
					};
                }
                else {
                    erreurs.push(champ);
                }
			}			
		}
		if (champ == "accesSuiviActivite") {
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				accesSuiviActivite: this.state.structure.accesSuiviActivite
			}			
		}
		if (champ == "nbMaxConseillers") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				nbMaxConseillers: this.state.structure.nbMaxConseillers
			}
		}
		if (champ == "nbMaxBeneficiaires") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				nbMaxBeneficiaires: this.state.structure.nbMaxBeneficiaires
			}
		}
		if (champ == "nbMaxBeneficiairesSuiviAvance") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				nbMaxBeneficiairesSuiviAvance: this.state.structure.nbMaxBeneficiairesSuiviAvance
			}
		}
		if (champ == "nbMaxBeneficiairesSuiviNormal") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				nbMaxBeneficiairesSuiviNormal: this.state.structure.nbMaxBeneficiairesSuiviNormal
			}
		}
		if (champ == "nbMessagesAvantAvertissement") {				
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				nbMessagesAvantAvertissement: this.state.structure.nbMessagesAvantAvertissement
			}
		}
		if (champ == "responsables") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				responsables: this.state.structure.responsables
			}
		}
		if (champ == "type") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				type: this.state.structure.type
			}
		}
		if (champ == "precisionType") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				precisionType: this.state.structure.precisionType
			}
		}
		if (champ == "actif") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				actif: this.state.structure.actif
			}
		}
		if (champ == "supprime") {	
			structure = {
				id: this.props.structure.id,
				classe: this.props.structure.classe,
				supprime: this.state.structure.supprime
			}
		}
		if (structure) {
			this.enregistreStructure(structure);
		}
		this.setState({
            erreurs: erreurs
        })
	}

	enregistreStructure(structure) {
		this.props.dispatch({type: 'DATA_POST_STRUCTURE', payload: {structure: structure}});
		if (structure.supprime) this.props.history.goBack();
	}

	conseillersSelectionnesActifs() {
        let dataService = new DataService();
        if (this.state.conseillersSelectionnes.length == 0) return null;
        
        let premierConseiller = dataService.getConseillerById(this.state.conseillersSelectionnes[0]);
        if (!premierConseiller) return null;
        
        for (let i = 1 ; i < this.state.conseillersSelectionnes.length ; i++) {
            let conseiller = dataService.getConseillerById(this.state.conseillersSelectionnes[i]);
            if (premierConseiller.actif != conseiller.actif || this.props.structure.responsables.includes(conseiller.id)) {
                return null;
            }
        }
        return premierConseiller.actif;
	}

	responsablesSelectionnesActifs() {
        let dataService = new DataService();
        if (this.state.responsablesSelectionnes.length == 0) return null;
        
        let premierResponsable = dataService.getConseillerById(this.state.responsablesSelectionnes[0]);
        if (!premierResponsable) return null;
        
        for (let i = 1 ; i < this.state.responsablesSelectionnes.length ; i++) {
            let responsable = dataService.getConseillerById(this.state.responsablesSelectionnes[i]);
            if (premierResponsable.actif != responsable.actif || this.props.structure.responsables.includes(responsable.id)) {
                return null;
            }
        }
        return premierResponsable.actif;
	}

	beneficiairesSelectionnesActifs() {
        let dataService = new DataService();
        if (this.state.beneficiairesSelectionnes.length == 0) return null;
        
        let premierBeneficiaire = dataService.getBeneficiaireById(this.state.beneficiairesSelectionnes[0]);
        if (!premierBeneficiaire) return null;
        
        for (let i = 1 ; i < this.state.beneficiairesSelectionnes.length ; i++) {
            let beneficiaire = dataService.getBeneficiaireById(this.state.beneficiairesSelectionnes[i]);
            if (premierBeneficiaire.actif != beneficiaire.actif) {
                return null;
            }
        }
        return premierBeneficiaire.actif;
	}

	setEditingConseiller(conseiller) {
        this.setState({
            editingConseiller: conseiller
        });
	}
	
	formConseillerValide() {
        return (
			this.state.editingConseiller
			&& this.state.editingConseiller.nom != ""
            && this.state.editingConseiller.prenom != ""
			&& /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.editingConseiller.email)
			&& this.state.editingConseiller.checkUniqueEmail
			&& (this.state.editingConseiller.telephone == "" || /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.editingConseiller.telephone))
			&& (
				this.props.structure.filtres.length == 0
				|| this.state.nouveauResponsable
				|| this.state.editingConseiller.filtres.length > 0
			)
        );
    }

	enregistreBeneficiaire(beneficiaire) {
		console.log(beneficiaire);
		if (beneficiaire.id) {
			this.props.dispatch({type: 'DATA_POST_BENEFICIAIRE', payload: {beneficiaire: beneficiaire}});
		}
		else {
			this.props.dispatch({type: 'DATA_PUT_STRUCTURE_BENEFICIAIRE', payload: {structure: this.props.structure, beneficiaire: beneficiaire}});
		}
	}

	enregistreConseiller(conseiller) {
		if (this.state.nouveauResponsable) {
			conseiller.responsable = true;
		}
		if (conseiller.id) {
			this.props.dispatch({type: 'DATA_POST_CONSEILLER', payload: {conseiller: conseiller}});
		}
		else {
			this.props.dispatch({type: 'DATA_PUT_STRUCTURE_CONSEILLER', payload: {structure: this.props.structure, conseiller: conseiller}});
		}
	}

	changeConseiller(c, champ, val) {
		console.log(c, champ, val);
		let conseiller = null;
		if (champ == "actif") {
			conseiller = {
				id: c.id,
				classe: c.classe,
				actif: val
			}
		}
		if (champ == "supprime") {
			conseiller = {
				id: c.id,
				classe: c.classe,
				supprime: val,
				motifSuppression: this.state.motifSuppression
			}
		}
		if (champ == "filtres") {
			conseiller = {
				id: c.id,
				classe: c.classe,
				filtres: val
			}
		}
		if (conseiller) {
			this.enregistreConseiller(conseiller);
		}
	}

	supprimeConseillers() {
        let dataService = new DataService();
        this.state.conseillersSelectionnes.map(c => {
            let conseiller = dataService.getConseillerById(c);
            if (conseiller) {
                this.changeConseiller(conseiller, "supprime", true);
            }
        });
        this.setState({
            conseillersSelectionnes: []
        })
    }

    toggleActivationConseillers() {
        let dataService = new DataService();
        this.state.conseillersSelectionnes.map(c => {
            let conseiller = dataService.getConseillerById(c);
            if (conseiller) {
                this.changeConseiller(conseiller, "actif", !conseiller.actif);
            }
        });
        this.setState({
            conseillersSelectionnes: []
        })
	}
	
	toggleStructureResponsable(conseiller) {
		let responsables = this.props.structure.responsables;
		if (responsables.includes(conseiller.id)) {
			responsables.splice(responsables.indexOf(conseiller.id), 1);
		}
		else {
			responsables.push(conseiller.id);
		}
		this.changeStructure("responsables", responsables);
	}

	changeBeneficiaire(b, champ, val) {
		let beneficiaire = null;
		if (champ == "actif") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				actif: val
			}
		}
		if (champ == "suiviAvance") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				suiviAvance: val,
				actif: true
			}
		}
		if (champ == "filtres") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				filtres: val
			}
		}
		if (champ == "supprime") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				supprime: val,
				motifSuppression: this.state.motifSuppression
			}
		}
		if (beneficiaire) {
			this.enregistreBeneficiaire(beneficiaire);
		}
	}

	supprimeBeneficiaires() {
        let dataService = new DataService();
        this.state.beneficiairesSelectionnes.map(c => {
            let beneficiaire = dataService.getBeneficiaireById(c);
            if (beneficiaire) {
                this.changeBeneficiaire(beneficiaire, "supprime", true);
            }
        });
        this.setState({
            beneficiairesSelectionnes: []
        })
    }

    toggleActivationBeneficiaires(type) {
        let dataService = new DataService();
        this.state.beneficiairesSelectionnes.map(c => {
            let beneficiaire = dataService.getBeneficiaireById(c);
            if (beneficiaire) {
				if (beneficiaire.actif) {
					this.changeBeneficiaire(beneficiaire, "actif", false);	
				}
				else {
					this.changeBeneficiaire(beneficiaire, "actif", true);
					this.changeBeneficiaire(beneficiaire, "suiviAvance", type == "avance");
				}                
            }
        });
        this.setState({
            beneficiairesSelectionnes: []
        })
    }

	ouvreFormNouveauResponsable() {
		this.setState({
			nouveauResponsable: true
		});
		window.jQuery("#modal-nouveauconseiller").modal("show");
	}

	getStatistiques() {
		let api = new Api();

		api.getStatistiques(this.props.structure.id, this.state.statistiquesDebut, this.state.statistiquesFin).then((stream) => {
            let reader = stream.getReader();
            let result = [];   
            let self = this;
            reader.read().then(function processFile({done, value}) {
                console.log(done);
                if (done) {
                    let blob = new Blob(result, {type: "application/pdf"});
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.setAttribute("target", "_blank");
					// commenter cette ligne pour ouvrir dans nouveau tab, mais impossible d'avoir le bon nom					
                    a.setAttribute("download", ("Statistiques " + self.props.structure.nom + " - " + moment().format("DD-MM-YYYY") + ".xlsx"));  
                    a.click();
                    return;
                }
                result = result.concat(value);
                reader.read().then(processFile);
            });
           
        }).catch(err => {
            console.log(err);
        });
	}

	ajouteFiltre() {
		this.setState({
			structure: {
				...this.state.structure,
				filtres: [
					...this.state.structure.filtres,
					{
						id: null,
						classe: 'Filtre',
						nom: '',
						filtres: [{
							id: null,
							classe: 'Filtre',
							nom: ''
						}]
					}
				]
			}
		})
	}

	modifieFiltre(iFiltre, champ, val) {
		if (champ == "nom") {
			this.state.structure.filtres[iFiltre].nom = val
		}
		this.setState({
			structure: this.state.structure
		})
	}

	supprimeFiltre(iFiltre) {
		this.state.structure.filtres.splice(iFiltre, 1)
		this.setState({
			structure: this.state.structure
		})
	}

	ajouteSousFiltre(iFiltre) {
		this.state.structure.filtres[iFiltre].filtres.push({
			id: null,
			classe: 'Filtre',
			nom: 'Sous-filtre ' + (this.state.structure.filtres[iFiltre].filtres.length + 1)
		})
		this.setState({
			structure: this.state.structure
		})
	}

	modifieSousFiltre(iFiltre, iSousFiltre, champ, val) {
		if (champ == "nom") {
			this.state.structure.filtres[iFiltre].filtres[iSousFiltre].nom = val
		}
		this.setState({
			structure: this.state.structure
		})
	}

	supprimeSousFiltre(iFiltre, iSousFiltre) {
		this.state.structure.filtres[iFiltre].filtres.splice(iSousFiltre, 1)
		this.setState({
			structure: this.state.structure
		})
	}

	enregistreFiltres() {
		let structure = {
			id: this.props.structure.id,
			classe: this.props.structure.classe,
			filtres: this.state.structure.filtres
		}
		this.enregistreStructure(structure)
	}

	toggleFiltreGroupeBeneficiaires(id) {
		if (this.state.filtresGroupeBeneficiaires.includes(id)) {
            this.state.filtresGroupeBeneficiaires.splice(this.state.filtresGroupeBeneficiaires.indexOf(id), 1)            
            this.props.structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.filtresGroupeBeneficiaires.includes(sousFiltre.id)) {
                            this.state.filtresGroupeBeneficiaires.splice(this.state.filtresGroupeBeneficiaires.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.filtresGroupeBeneficiaires.push(id)            
            this.props.structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.filtresGroupeBeneficiaires.includes(filtre.id)) {
                            this.state.filtresGroupeBeneficiaires.push(filtre.id)
                        }
                    }
                })
            })
        }
        this.setState({
            filtresGroupeBeneficiaires: this.state.filtresGroupeBeneficiaires
        })
	}

	toggleFiltreGroupeConseillers(id) {		
		if (this.state.filtresGroupeConseillers.includes(id)) {
            this.state.filtresGroupeConseillers.splice(this.state.filtresGroupeConseillers.indexOf(id), 1)            
            this.props.structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.filtresGroupeConseillers.includes(sousFiltre.id)) {
                            this.state.filtresGroupeConseillers.splice(this.state.filtresGroupeConseillers.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.filtresGroupeConseillers.push(id)            
            this.props.structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.filtresGroupeConseillers.includes(filtre.id)) {
                            this.state.filtresGroupeConseillers.push(filtre.id)
                        }
                    }
                })
            })
        }
        this.setState({
            filtresGroupeConseillers: this.state.filtresGroupeConseillers
        })
	}

	enregistreFiltresGroupeBeneficiaires() {
		let dataService = new DataService();
        this.state.beneficiairesSelectionnes.map(b => {
            let beneficiaire = dataService.getBeneficiaireById(b);
            if (beneficiaire) {
                this.changeBeneficiaire(beneficiaire, "filtres", this.state.filtresGroupeBeneficiaires);
            }
        });        
	}

	enregistreFiltresGroupeConseillers() {
		let dataService = new DataService();
        this.state.conseillersSelectionnes.map(c => {
            let conseiller = dataService.getConseillerById(c);
            if (conseiller) {
                this.changeConseiller(conseiller, "filtres", this.state.filtresGroupeConseillers);
            }
        });        
	}

	filtresValides() {
		for (let filtre of this.state.structure.filtres) {
			if (filtre.nom == '') {
				return false
			}
			for (let sousFiltre of filtre.filtres) {
				if (sousFiltre.nom == '') {
					return false
				}	
			}
		}	
		return true
	}

	render() {

		if (!this.props.structure) return <></>;

		console.log("structure", this.props.structure);

		let dataService = new DataService();
		let securityService = new SecurityService();

		let domaines = {
			accompagnement: 'Accompagnement et handicap',
			entreprise: 'Entreprise et handicap', 
			prevention: 'Prévention et RPS'
		}

		let modals = [];

		let conseillers = [];
		dataService.getStructureConseillers(this.props.structure)
		.filter((conseiller) => !conseiller.seulementAdmin)
		.filter(c => {
			return this.state.filtresConseillers.length == 0 || this.state.filtresConseillers.filter(f => c.filtres.includes(parseInt(f))).length > 0
		})
		.sort((a, b) => {
			if (a.actif != b.actif) return a.actif ? -1 : 1;
			return ((a.nom + a.prenom).localeCompare(b.nom + b.prenom));
		}).map((conseiller, index) => {

			let filtresConseiller = []
			this.props.structure.filtres.map(filtre => {
				let actif = conseiller.filtres.includes(filtre.id)
				let sousFiltres = []
				filtre.filtres.map(sousFiltre => {
					if (conseiller.filtres.includes(sousFiltre.id)) {
						actif = true
						sousFiltres.push(sousFiltre)
					}
				})								
				if (actif) {
					if (sousFiltres.length == 0) {
						filtresConseiller.push(filtre.nom)
					}
					else {
						sousFiltres.map(sousFiltre => {
							filtresConseiller.push(filtre.nom + " - " + sousFiltre.nom)
						})
					}
				}
			})

			let conseillerBeneficiaires = []
			conseillerBeneficiaires = dataService.getStructureBeneficiaires(this.props.structure).filter(beneficiaire => securityService.canFollowBeneficiaire(conseiller, beneficiaire)).map(beneficiaire => beneficiaire.id).concat(
				conseiller.beneficiaires
			)
			conseillerBeneficiaires = conseillerBeneficiaires.filter((b, iB) => conseillerBeneficiaires.indexOf(b) == iB)			
				
			conseillers.push(				
				<tr key={"row-conseiller-" + conseiller.id} className={(this.state.conseillersSelectionnes.includes(conseiller.id) ? "table-warning" : "") + " " + (!conseiller.actif ? "obsolete" : "")}>
					<td>
						<div className="checkbox-wrapper">
							<input type="checkbox" id={"toggle-conseiller-" + conseiller.id} onChange={() => this.toggleConseiller(conseiller)} checked={this.state.conseillersSelectionnes.includes(conseiller.id)} />
                            <label htmlFor={"toggle-conseiller-" + conseiller.id} className="toggle"></label>
						</div>
					</td>
					<td>
						<NavLink to={"/accompagnants/" + conseiller.id}><p className="m-0 table-nom font-14">{conseiller.prenom} {conseiller.nom}</p></NavLink>
						{filtresConseiller.length > 0 &&
							<p>
								{filtresConseiller.map((filtre, iFiltre) => {
									return (
										<React.Fragment key={"filtre-" + iFiltre}>
											{iFiltre != 0 && <br />}
											{filtre}
										</React.Fragment>
									)
								})}						
							</p>
						}
					</td>
					
					<td>
						<p className="m-0 text-muted font-14">{conseiller.email}<br />{conseiller.telephone}</p>
					</td>
					<td className="nbrebeneficiaires">
						<p className="m-0 text-muted font-14"><i className="mdi mdi-account-multiple-outline"></i> 
							{conseillerBeneficiaires.length}						 
						</p>
					</td>
					{securityService.isAdmin() &&
						<td><p className="m-0 text-muted font-14">{conseiller.lastLogin != null ? moment(conseiller.lastLogin).format("DD-MM-YYYY") : "-"}</p></td>
					}																					
					<td>
						{(this.props.structure.responsables.length > 1 || !this.props.structure.responsables.includes(conseiller.id)) && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={this.props.structure.responsables.includes(conseiller.id) ? "Retirer rôle de responsable" : "Ajouter rôle de responsable" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-toggleresponsable-" + conseiller.id}><i className={"mdi " + (this.props.structure.responsables.includes(conseiller.id) ? "mdi-clipboard-outline" : "mdi-clipboard-account")}></i></button></span>}
						{(this.props.structure.responsables.length > 1 || !this.props.structure.responsables.includes(conseiller.id)) && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={conseiller.actif ? "Désactiver" : "Réactiver" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-toggleactivationconseiller-" + conseiller.id} disabled={!conseiller.actif && dataService.getStructureNbConseillersActifs(this.props.structure) >= this.props.structure.nbMaxConseillers}><i className={"mdi " + (conseiller.actif ? "mdi-block-helper" : "mdi-checkbox-marked-circle-outline")}></i></button></span>}
						{(this.props.structure.responsables.length > 1 || !this.props.structure.responsables.includes(conseiller.id)) && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionconseiller-" + conseiller.id}><i className="dripicons-trash"></i></button></span>}
					</td>
				</tr>
			);
			modals.push(
				<div key={"modal-toggleactivationconseiller-" + conseiller.id} id={"modal-toggleactivationconseiller-" + conseiller.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{conseiller.actif ? "Désactiver" : "Réactiver" } le compte {getTerme("accompagnant", this.props.structure.domaine)}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{conseiller.actif && <p>L'accès aux données et tous les services de suivi seront désactivés pour <b>{conseiller.prenom} {conseiller.nom}</b>. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!conseiller.actif && <p>L'accès aux données et tous les services de suivi seront rétablis pour <b>{conseiller.prenom} {conseiller.nom}</b>. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.changeConseiller(conseiller, "actif", !conseiller.actif)} data-dismiss="modal">{conseiller.actif ? "Désactiver" : "Réactiver" } le compte {getTerme("accompagnant", this.props.structure.domaine)}</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
			modals.push(
				<div key={"modal-suppressionconseiller-" + conseiller.id} id={"modal-suppressionconseiller-" + conseiller.id} className="modal fade bs-example-modal-center bs-example-modal-lg modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer le compte {getTerme("accompagnant", this.props.structure.domaine)}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Merci d'indiquer le motif de la suppression du compte :</p>
								<form>
									<div className="form-group">													
										<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
											<option value="depart">Ne fait plus partie des effectifs</option>
											<option value="rgpd">Demande explicite (RGPD)</option>
											<option value="autre">Autre</option>
										</select>
									</div>
								</form>
								<p>L'ensemble des services de suivi et informations liés à <b>{conseiller.prenom} {conseiller.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.changeConseiller(conseiller, "supprime", true)} data-dismiss="modal">Supprimer le compte {getTerme("accompagnant", this.props.structure.domaine)}</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
			modals.push(
				<div key={"modal-toggleresponsable-" + conseiller.id} id={"modal-toggleresponsable-" + conseiller.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.props.structure.responsables.includes(conseiller.id) ? "Retirer" : "Ajouter" } le rôle de responsable</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.props.structure.responsables.includes(conseiller.id) && <p>Les droits d'administration de la structure <b>{this.props.structure.nom}</b> seront retirés à <b>{conseiller.prenom} {conseiller.nom}</b>. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!this.props.structure.responsables.includes(conseiller.id) && <p>Les droits d'administration de la structure <b>{this.props.structure.nom}</b> seront ajoutés <b>{conseiller.prenom} {conseiller.nom}</b>. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.toggleStructureResponsable(conseiller)} data-dismiss="modal">{this.props.structure.responsables.includes(conseiller.id) ? "Retirer" : "Ajouter" } le rôle de responsable</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
		});

		let responsables = [];
		dataService.getStructureConseillers(this.props.structure).sort((a, b) => {
			if (a.actif != b.actif) return a.actif ? -1 : 1;
			if (this.props.structure.responsables.includes(a.id) != this.props.structure.responsables.includes(b.id)) return this.props.structure.responsables.includes(a.id) ? -1 : 1;
			return ((a.nom + a.prenom).localeCompare(b.nom + b.prenom));
		}).filter((conseiller) => this.props.structure.responsables.includes(conseiller.id) || conseiller.seulementAdmin).map((conseiller, index) => {
			responsables.push(
				<tr key={"row-conseiller-" + conseiller.id} className={(this.state.conseillersSelectionnes.includes(conseiller.id) ? "table-warning" : "") + " " + (!conseiller.actif ? "obsolete" : "")}>
					<td>
						<div className="checkbox-wrapper">
							<input type="checkbox" id={"toggle-responsable-" + conseiller.id} onChange={() => this.toggleResponsable(conseiller)} checked={this.state.responsablesSelectionnes.includes(conseiller.id)} />
                            <label htmlFor={"toggle-responsable-" + conseiller.id} className="toggle"></label>
						</div>
					</td>
					<td>
						<NavLink to={"/accompagnants/" + conseiller.id}><p className="m-0 table-nom font-14">{conseiller.prenom} {conseiller.nom}</p></NavLink>
					</td>
					
					<td>
						<p className="m-0 text-muted font-14">{conseiller.email}<br />{conseiller.telephone}</p>
					</td>
					<td>
						<p className="m-0 text-muted font-14">{this.props.structure.responsables.includes(conseiller.id) ? "Oui" : "Non"}</p>
					</td>
					<td>
						<p className="m-0 text-muted font-14">{!conseiller.seulementAdmin ? "Oui" : "Non"}</p>
					</td>
					{securityService.isAdmin() &&
						<td><p className="m-0 text-muted font-14">{conseiller.lastLogin != null ? moment(conseiller.lastLogin).format("DD-MM-YYYY") : "-"}</p></td>
					}
					<td>
						{(!this.props.structure.responsables.includes(conseiller.id) || this.props.structure.responsables.length > 1) && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={this.props.structure.responsables.includes(conseiller.id) ? "Retirer rôle de responsable" : "Ajouter rôle de responsable" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-toggleresponsable-" + conseiller.id}><i className={"mdi " + (this.props.structure.responsables.includes(conseiller.id) ? "mdi-clipboard-outline" : "mdi-clipboard-account")}></i></button></span>}
						{(!this.props.structure.responsables.includes(conseiller.id) || this.props.structure.responsables.length > 1) && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={conseiller.actif ? "Désactiver" : "Réactiver" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-toggleactivationconseiller-" + conseiller.id}><i className={"mdi " + (conseiller.actif ? "mdi-block-helper" : "mdi-checkbox-marked-circle-outline")}></i></button></span>}
						{(!this.props.structure.responsables.includes(conseiller.id) || this.props.structure.responsables.length > 1) && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionconseiller-" + conseiller.id}><i className="dripicons-trash"></i></button></span>}
					</td>
				</tr>
			);
			if (conseiller.seulementAdmin) {
				modals.push(
					<div key={"modal-toggleresponsable-" + conseiller.id} id={"modal-toggleresponsable-" + conseiller.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">{this.props.structure.responsables.includes(conseiller.id) ? "Retirer" : "Ajouter" } le rôle de responsable</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
								<div className="modal-body">
									{this.props.structure.responsables.includes(conseiller.id) && <p>Les droits d'administration de la structure <b>{this.props.structure.nom}</b> seront retirés à <b>{conseiller.prenom} {conseiller.nom}</b>. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
									{!this.props.structure.responsables.includes(conseiller.id) && <p>Les droits d'administration de la structure <b>{this.props.structure.nom}</b> seront ajoutés <b>{conseiller.prenom} {conseiller.nom}</b>. Voulez-vous continuer ?</p>}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary" onClick={() => this.toggleStructureResponsable(conseiller)} data-dismiss="modal">{this.props.structure.responsables.includes(conseiller.id) ? "Retirer" : "Ajouter" } le rôle de responsable</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>
							</div>
						</div>
					</div>	
				);
				modals.push(
					<div key={"modal-toggleactivationconseiller-" + conseiller.id} id={"modal-toggleactivationconseiller-" + conseiller.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">{conseiller.actif ? "Désactiver" : "Réactiver" } le compte utilisateur</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
								<div className="modal-body">
									{conseiller.actif && <p>L'accès aux données et tous les services de suivi seront désactivés pour <b>{conseiller.prenom} {conseiller.nom}</b>. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
									{!conseiller.actif && <p>L'accès aux données et tous les services de suivi seront rétablis pour <b>{conseiller.prenom} {conseiller.nom}</b>. Voulez-vous continuer ?</p>}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary" onClick={() => this.changeConseiller(conseiller, "actif", !conseiller.actif)} data-dismiss="modal">{conseiller.actif ? "Désactiver" : "Réactiver" } le compte utilisateur</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>
							</div>
						</div>
					</div>	
				);
				modals.push(
					<div key={"modal-suppressionconseiller-" + conseiller.id} id={"modal-suppressionconseiller-" + conseiller.id} className="modal fade bs-example-modal-center bs-example-modal-lg modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">Supprimer le compte utilisateur</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
								<div className="modal-body">
									<p>Merci d'indiquer le motif de la suppression du compte :</p>
									<form>
										<div className="form-group">													
											<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
												<option value="depart">Ne fait plus partie des effectifs</option>
												<option value="rgpd">Demande explicite (RGPD)</option>
												<option value="autre">Autre</option>
											</select>
										</div>
									</form>
									<p>L'ensemble des services de suivi et informations liés à <b>{conseiller.prenom} {conseiller.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary suppression" onClick={() => this.changeConseiller(conseiller, "supprime", true)} data-dismiss="modal">Supprimer le compte utilisateur</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>
							</div>
						</div>
					</div>	
				);
			}	
		});
		
		let profils = [{
			nom: 'Profil par défaut',
			filtre: {
				id: 'defaut',
				profils: this.props.structure.profilsDefaut
			}
		}]
		for (let filtre of this.props.structure.filtres) {
			let profil = {
				nom: 'Profil ' + filtre.nom,
				filtre: filtre		
			}
			profils.push(profil)
			for (let sousfiltre of filtre.filtres) {
				let profil = {
					nom: 'Profil ' + filtre.nom + ' - ' + sousfiltre.nom,
					filtre: sousfiltre
				}
				profils.push(profil)
			}
		}	
		let lignesProfils = []
		profils.map((profil, iProfil) => {
			console.log(profil)
			lignesProfils.push(
				<tr key={"row-profil-" + iProfil}>
					<td>
						<NavLink to={"/structures/" + this.props.structure.id + "/profils/" + (profil.filtre ? profil.filtre.id : 'defaut')} onClick={() => console.log(profil)}><p className="m-0 table-nom font-14">{profil.nom}</p></NavLink>						
					</td>	
					<td>{dataService.getStructureBeneficiaires(this.props.structure).filter(beneficiaire => (beneficiaire.filtreReference === profil.filtre.id) || (beneficiaire.filtreReference === null && profil.filtre.id === 'defaut')).length}{/*{profil.filtre.profils.filter(p => p.cible === 'beneficiaire').reduce((acc, profil) => acc + profil.nbBeneficiaires, 0)}*/}</td>				
					<td>{profil.filtre.profils.filter(p => p.cible === "beneficiaire").length > 0 && profil.filtre.profils.filter(p => p.cible === "beneficiaire")[0].dateSynchronisation ? moment(profil.filtre.profils.filter(p => p.cible === "beneficiaire")[0].dateSynchronisation).format('DD/MM/YYYY à HH:mm') : ''}</td>									
					<td>{profil.filtre.profils.filter(p => p.cible === "proche-superieur").length > 0 && profil.filtre.profils.filter(p => p.cible === "proche-superieur")[0].dateSynchronisation ? moment(profil.filtre.profils.filter(p => p.cible === "proche-superieur")[0].dateSynchronisation).format('DD/MM/YYYY à HH:mm') : ''}</td>														
				</tr>
			)
		})	

		let beneficiaires = [];
		dataService.getStructureBeneficiaires(this.props.structure)
		.filter(b => {
			return this.state.filtresBeneficiaires.length == 0 || this.state.filtresBeneficiaires.filter(f => b.filtres.includes(parseInt(f))).length > 0
		})
		.sort((a, b) => {
			if (a.actif != b.actif) return a.actif ? -1 : 1;
			if (a.suiviAvance != b.suiviAvance) return a.suiviAvance ? -1 : 1;
			return ((a.nom + a.prenom).localeCompare(b.nom + b.prenom));
		}).map((beneficiaire, index) => {
			let vignettesConseillers = [];
			/*if (dataService.getConseillerById(beneficiaire.conseillerPrincipal)) {
				let conseiller = dataService.getConseillerById(beneficiaire.conseillerPrincipal);
				vignettesConseillers.push(
					<button key={"vignette-conseiller-" + conseiller.id} type="button" className="acronyme principal" data-toggle="tooltip" data-placement="top" title={(beneficiaire.conseillers.length > 1 ? "Principal: " : "") + conseiller.prenom + " " + conseiller.nom}>
						{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
					</button>
				)
			}
			beneficiaire.conseillers.filter((c) => (c != beneficiaire.conseillerPrincipal)).map((c) => {
				let conseiller = dataService.getConseillerById(c);
				if (conseiller != null) {
					vignettesConseillers.push(
						<button key={"vignette-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom}>
							{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
						</button>
					)
				}
			});*/
			let conseillersFiltres = []
			dataService.getStructureConseillers(this.props.structure).map(conseiller => {
				if (securityService.canFollowBeneficiaire(conseiller, beneficiaire) === false) {
					return
				}
				vignettesConseillers.push(
					<button key={"vignette-conseiller-" + conseiller.id} type="button" className="acronyme principal" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom}>
						{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
					</button>
				)
				conseillersFiltres.push(conseiller.id)
			})        
			beneficiaire.conseillers
			.filter(c => conseillersFiltres.includes(c) === false)
			.map((c) => {
				console.log(c)
				let conseiller = dataService.getConseillerById(c);
				if (conseiller != null) {
					vignettesConseillers.push(
						<button key={"vignette-conseiller-" + conseiller.id} type="button" className="acronyme" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom + (this.props.structure.filtres.length > 0 ? ' (ajout manuel)' : '')}>
							{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
						</button>
					)
				}
			});     

			let filtresBeneficiaire = []
			this.props.structure.filtres.map(filtre => {
				let actif = beneficiaire.filtres.includes(filtre.id)
				let sousFiltres = []
				filtre.filtres.map(sousFiltre => {
					if (beneficiaire.filtres.includes(sousFiltre.id)) {
						actif = true
						sousFiltres.push(sousFiltre)
					}
				})								
				if (actif) {
					if (sousFiltres.length == 0) {
						filtresBeneficiaire.push(filtre.nom)
					}
					else {
						sousFiltres.map(sousFiltre => {
							filtresBeneficiaire.push(filtre.nom + " - " + sousFiltre.nom)
						})
					}
				}
			})			

			beneficiaires.push(
				<tr key={"row-beneficiaire-" + beneficiaire.id} className={(this.state.beneficiairesSelectionnes.includes(beneficiaire.id) ? "table-warning" : "") + " " + (!beneficiaire.actif ? "obsolete" : "")}>
					<td>
						<div className="checkbox-wrapper">
							<input type="checkbox" id={"toggle-beneficiaire-" + beneficiaire.id} onChange={() => this.toggleBeneficiaire(beneficiaire)} checked={this.state.beneficiairesSelectionnes.includes(beneficiaire.id)} />
                            <label htmlFor={"toggle-beneficiaire-" + beneficiaire.id} className="toggle"></label>
						</div>
					</td>
					<td>
						<NavLink to={"/utilisateurs/" + beneficiaire.id} onClick={() => this.changeTabBeneficiaire("informations")}><p className="m-0 table-nom font-14">{beneficiaire.suiviAvance && <i className="mdi mdi-wifi"></i>} {beneficiaire.prenom} {beneficiaire.nom}</p></NavLink><br />
						{filtresBeneficiaire.length > 0 &&
							<p>
								{filtresBeneficiaire.map((filtre, iFiltre) => {
									return (
										<React.Fragment key={"filtre-" + iFiltre}>
											{iFiltre != 0 && <br />}
											{filtre}
										</React.Fragment>
									)
								})}						
							</p>
						}						
					</td>
					<td>
						<p className="m-0 text-muted font-14">
							<span className="table-signaux">{beneficiaire.score}<span className="table-signaux total">/{beneficiaire.seuilCalcule}</span></span>																				
						</p>
					</td>
					<td>
						{vignettesConseillers}
					</td>
					<td>
						{beneficiaire.suiviAvance && !beneficiaire.pauseQuestions && !beneficiaire.enConges && beneficiaire.etatConsentement == 'accepte' ? "OUI" : ("NON" + ((beneficiaire.etatConsentement == null && beneficiaire.dateDemandeConsentement) ? " (consentement en attente)" : (beneficiaire.enConges ? " (congés)" : "")))}
					</td>
					<td>
						{beneficiaire.etatConsentement == null &&
							<>{beneficiaire.dateDemandeConsentement ? "En attente" : "Non demandé"}</>
						}
						{beneficiaire.etatConsentement != null &&
							<>{beneficiaire.etatConsentement == 'accepte' ? "Accepté" : (beneficiaire.etatConsentement == 'etre-rappele' ? 'Demande à être rappelé': "Refusé")}</>
						}						
					</td>
					<td>
						{/*{beneficiaire.actif && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={beneficiaire.suiviAvance ? "Passer en suivi sans signaux faibles" : "Passer en suivi avec signaux faibles" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-togglesuivibeneficiaire-" + beneficiaire.id} disabled={beneficiaire.suiviAvance ? dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviNormal : dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviAvance}><i className={"mdi " + (beneficiaire.suiviAvance ? "mdi-wifi-off" : "mdi-wifi")}></i></button></span>}*/}
						<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={beneficiaire.actif ? "Désactiver" : "Réactiver" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-toggleactivationbeneficiaire-" + beneficiaire.id} disabled={!beneficiaire.actif ? dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviAvance && dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviNormal : false}><i className={"mdi " + (beneficiaire.actif ? "mdi-block-helper" : "mdi-checkbox-marked-circle-outline")}></i></button></span>
						<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionbeneficiaire-" + beneficiaire.id}><i className="dripicons-trash"></i></button></span>
					</td>
				</tr>
			);
			modals.push(
				<div key={"modal-togglesuivibeneficiaire-" + beneficiaire.id} id={"modal-togglesuivibeneficiaire-" + beneficiaire.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Changer le type de suivi la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{beneficiaire.suiviAvance && <p>Le suivi avec signaux faibles de <b>{beneficiaire.prenom} {beneficiaire.nom}</b> sera désactivé. Il pourra être rétabli ultérieurement. Voulez-vous continuer ?</p>}
								{!beneficiaire.suiviAvance && <p>Le suivi avec signaux faibles de <b>{beneficiaire.prenom} {beneficiaire.nom}</b> sera activé. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "suiviAvance", !beneficiaire.suiviAvance)} data-dismiss="modal">Passer en suivi {beneficiaire.suiviAvance ? "sans" : "avec"} signaux faibles</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
			modals.push(
				<div key={"modal-toggleactivationbeneficiaire-" + beneficiaire.id} id={"modal-toggleactivationbeneficiaire-" + beneficiaire.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{beneficiaire.actif ? "Désactiver" : "Réactiver" } la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{beneficiaire.actif && <p>L'ensemble des services de suivi concernant <b>{beneficiaire.prenom} {beneficiaire.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!beneficiaire.actif && <p>Le suivi concernant <b>{beneficiaire.prenom} {beneficiaire.nom}</b> sera réactivé. Vous pouvez choisir ci-dessous le type de suivi (avec signaux faibles, sans signaux faibles) souhaité.</p>}
							</div>
							<div className="modal-footer">
								{beneficiaire.actif &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "actif", false)} data-dismiss="modal">Désactiver le suivi de la personne</button>
									</>
								}
								{!beneficiaire.actif &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "suiviAvance", true)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviAvance}>Réactiver la personne avec signaux faibles</button>
										<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "suiviAvance", false)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviNormal}>Réactiver la personne sans signaux faibles</button>
									</>
								}								
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);
			modals.push(
				<div key={"modal-suppressionbeneficiaire-" + beneficiaire.id} id={"modal-suppressionbeneficiaire-" + beneficiaire.id} className="modal fade bs-example-modal-center bs-example-modal-lg modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer le compte de la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Merci d'indiquer le motif de la suppression du compte :</p>
								<form>
									<div className="form-group">													
										<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
											<option value="depart">Ne fait plus partie des effectifs</option>
											<option value="rgpd">Demande explicite (RGPD)</option>
											<option value="autre">Autre</option>
										</select>
									</div>
								</form>
								<p>L'ensemble des services de suivi et informations liés à <b>{beneficiaire.prenom} {beneficiaire.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.changeBeneficiaire(beneficiaire, "supprime", true)} data-dismiss="modal">Supprimer le compte de la personne</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
			);			
		});

		let beneficiairesSupprimes = [];
		if (securityService.isAdmin()) {
			dataService.getStructureBeneficiairesSupprimes(this.props.structure).sort((a, b) => {
				return ((a.nom + a.prenom).localeCompare(b.nom + b.prenom));
			}).map((beneficiaire, index) => {
				beneficiairesSupprimes.push(
					<tr key={"row-beneficiaire-" + beneficiaire.id} className="obsolete">
						<td>
						</td>
						<td>
							<NavLink to={"/utilisateurs/" + beneficiaire.id} onClick={() => this.changeTabBeneficiaire("informations")}><p className="m-0 table-nom font-14">{beneficiaire.prenom} {beneficiaire.nom}</p></NavLink>
						</td>
						<td>
							<p className="m-0 text-muted font-14 compte-supprime">Compte supprimé</p>
						</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				);			
			});
		}
		
		let iOptionsMaxBeneficiairesSuiviAvance = [1, 2, 3, 4];
		for (let i = 5 ; i <= 100 ; i+=5) {
			iOptionsMaxBeneficiairesSuiviAvance.push(i)
		}
		if (!iOptionsMaxBeneficiairesSuiviAvance.includes(dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure))) {
			iOptionsMaxBeneficiairesSuiviAvance.push(dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure))
		}
		if (!iOptionsMaxBeneficiairesSuiviAvance.includes(this.props.structure.nbMaxBeneficiairesSuiviAvance)) {
			iOptionsMaxBeneficiairesSuiviAvance.push(this.props.structure.nbMaxBeneficiairesSuiviAvance)
		}
		let optionsNbMaxBeneficiairesSuiviAvance = [];
		iOptionsMaxBeneficiairesSuiviAvance.filter(i => i >= dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure)).sort((a, b) => a < b ? -1 : 1).map(i => {
			optionsNbMaxBeneficiairesSuiviAvance.push(
				<option key={"option-maxbeneficiairessuiviavance-" + i} value={i}>{i}</option>
			)
		})

		let iOptionsMaxBeneficiairesSuiviNormal = [1, 2, 3, 4];
		for (let i = 5 ; i <= 100 ; i+=5) {
			iOptionsMaxBeneficiairesSuiviNormal.push(i)
		}
		if (!iOptionsMaxBeneficiairesSuiviNormal.includes(dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure))) {
			iOptionsMaxBeneficiairesSuiviNormal.push(dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure))
		}
		if (!iOptionsMaxBeneficiairesSuiviNormal.includes(this.props.structure.nbMaxBeneficiairesSuiviNormal)) {
			iOptionsMaxBeneficiairesSuiviNormal.push(this.props.structure.nbMaxBeneficiairesSuiviNormal)
		}
		let optionsNbMaxBeneficiairesSuiviNormal = [];
		iOptionsMaxBeneficiairesSuiviNormal.filter(i => i >= dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure)).sort((a, b) => a < b ? -1 : 1).map(i => {
			optionsNbMaxBeneficiairesSuiviNormal.push(
				<option key={"option-maxbeneficiairessuivinormal-" + i} value={i}>{i}</option>
			)
		})


		/*let optionsNbMaxBeneficiaires = [];
		optionsNbMaxBeneficiaires.push(
			<option key={"option-maxbeneficiaires-" + dataService.getStructureNbBeneficiairesActifs(this.props.structure)} value={dataService.getStructureNbBeneficiairesActifs(this.props.structure)}>{dataService.getStructureNbBeneficiairesActifs(this.props.structure)}</option>
		);
		for (let i = (dataService.getStructureNbBeneficiairesActifs(this.props.structure) + (5 - (dataService.getStructureNbBeneficiairesActifs(this.props.structure) % 5))) ; i <= 100 ; i+=5) {
			optionsNbMaxBeneficiaires.push(
				<option key={"option-maxbeneficiaires-" + i} value={i}>{i}</option>
			);
		}*/

		let optionsNbMaxConseillers = [];
		optionsNbMaxConseillers.push(
			<option key={"option-maxconseillers-" + dataService.getStructureNbConseillersActifs(this.props.structure)} value={dataService.getStructureNbConseillersActifs(this.props.structure)}>{dataService.getStructureNbConseillersActifs(this.props.structure)}</option>
		);
		for (let i = (dataService.getStructureNbConseillersActifs(this.props.structure) + (5 - (dataService.getStructureNbConseillersActifs(this.props.structure) % 5))) ; i <= 50 ; i+=5) {
			optionsNbMaxConseillers.push(
				<option key={"option-maxconseillers-" + i} value={i}>{i}</option>
			);
		}

		let optionsNbMessagesAvantAvertissement = [];
		for (let i = 0 ; i <= 50 ; i+=5) {
			optionsNbMessagesAvantAvertissement.push(
				<option key={"option-maxmessages-" + i} value={i}>{i}</option>
			);
		}	

		let conseillersResponsables = [];
		this.props.structure.conseillers.map((c) => {
			let conseiller = dataService.getConseillerById(c);
			if (conseiller && conseiller.actif && !conseiller.supprime) {
				conseillersResponsables.push(
					<option key={"option-responsable-" + conseiller.id} value={conseiller.id}>{conseiller.prenom} {conseiller.nom}</option>
				);
			}
		});

		let routeService = new RouteService();
		let historyLastLocation = routeService.getPreviousLocation();
		let lastLocation = 
			<NavLink to={historyLastLocation ? historyLastLocation : "/"}><i className="mdi mdi-arrow-left"></i>
				{routeService.getLocationLibelle(historyLastLocation)}
			</NavLink>
		;

		console.log("avance", dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure))
		console.log("normal", dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure))
		console.log(this.props.structure)

		let types = []
        if (this.props.structure.domaine == 'accompagnement') {
            types.push(
                <option key="deac" value="deac">DEAC</option>,
                <option key="capemploi" value="capemploi">Cap Emploi</option>,
                <option key="esat" value="esat">ESAT</option>,
                <option key="formation"value="formation">Organisme de formation</option>,                
            )
        }
        if (this.props.structure.domaine == 'entreprise') {
            types.push(
                <option key="ea" value="ea">EA</option>,
                <option key="horsea" value="horsea">Hors EA</option>,
                <option key="public" value="public">Secteur public</option>,                
            )
        }
        if (this.props.structure.domaine == 'rps') {
            
        }
        types.push(<option key="autre" value="autre">Autre</option>)

		const filtresSupprimes = this.props.structure.filtres.map((f) => [f.id].concat(f.filtres.map(sf => sf.id))).reduce((allIds, filtresIds, i) => allIds.concat(filtresIds), []).filter((originFiltreId) => {
			return this.state.structure.filtres.map((f) => [f.id].concat(f.filtres.map(sf => sf.id))).reduce((allIds, filtresIds, i) => allIds.concat(filtresIds), []).includes(originFiltreId) === false
		})
		console.log(filtresSupprimes)

		return (
			<div className="content-page h-100" id="page-admin-structure">
                <div className="content h-100">
                    <div className="container-fluid h-100">
						{securityService.isAdmin() && 
							<div className="row">
								<div className="col-lg-12 lienretour">
									{lastLocation}
								</div>
							</div>
						}
						<div className="row h-100">
							<div className="col-md-4 h-100">
								<div className="card card-profil sidebar m-b-20">
									<div className="card-body text-center">
										{securityService.isAdmin() &&
											<>
												<h4>Structure {domaines[this.props.structure.domaine]}</h4>
												<form>														
													<div className="form-row">
														<div className="form-group col-md-12">
															<div className={"input-group input-obligatoire" + (this.state.erreurs.includes('nom') ? " input-error" : "")}>
																<div className="input-group-prepend">
																	<div className="input-group-text">Nom *</div>
																</div>
																<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.nom} onChange={(e) => this.changeStructure("nom", e.target.value)} onBlur={() => this.blurInformationStructure("nom")} />
															</div>
														</div>
													</div>													
												</form>												
												<div className="signaux">
													<div className="text-left">
														<div className="form-group row">													
															<label className="col-form-label mr-2">Type : </label>
															<div className="">
																<select className="form-control" value={this.state.structure.type} onChange={(e) => this.changeStructure("type", e.target.value)}>
																	{types}
																</select>
															</div>													
														</div>
														{this.state.structure.type == "autre" &&
															<div className="form-group row">													
																<div className={"input-group"}>
																	<div className="input-group-prepend">
																		<div className="input-group-text">Précision</div>
																	</div>
																	<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.structure.precisionType} onChange={(e) => this.changeStructure("precisionType", e.target.value)} onBlur={() => this.blurInformationStructure("precisionType")} />
																</div>
															</div>
														}
														<div className="form-group row mt-5">
															<input type="checkbox" id="switch8" switch="warning" checked={this.state.structure.accesSuiviActivite} onChange={(e) => this.changeStructure("accesSuiviActivite", e.target.value)} />
															<label htmlFor="switch8"></label>
															Accès au suivi d'activité
														</div> 												   
														{/*<div className="form-group row">
															<label className="col-form-label mr-2">Limite utilisateurs : {dataService.getStructureNbBeneficiairesActifs(this.props.structure)} / </label>
															<div className="">
																<select className="form-control" value={this.state.structure.nbMaxBeneficiaires} onChange={(e) => this.changeStructure("nbMaxBeneficiaires", e.target.value)}>
																	{optionsNbMaxBeneficiaires}
																</select>
															</div>
														</div>*/}
														<div className="form-group row">
															<label className="col-form-label mr-2">Limite {getTerme("utilisateurs", this.props.structure.domaine)} avec signaux faibles : {dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure)} / </label>
															<div className="">
																<select className="form-control" value={this.state.structure.nbMaxBeneficiairesSuiviAvance} onChange={(e) => this.changeStructure("nbMaxBeneficiairesSuiviAvance", e.target.value)}>
																	{optionsNbMaxBeneficiairesSuiviAvance}
																</select>
															</div>
														</div>
														{/*<div className="form-group row">
															<label className="col-form-label mr-2">Limite {getTerme("utilisateurs", this.props.structure.domaine)} sans signaux faibles : {dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure)} / </label>
															<div className="">
																<select className="form-control" value={this.state.structure.nbMaxBeneficiairesSuiviNormal} onChange={(e) => this.changeStructure("nbMaxBeneficiairesSuiviNormal", e.target.value)}>
																	{optionsNbMaxBeneficiairesSuiviNormal}
																</select>
															</div>
														</div>*/}
														<div className="form-group row">
															<label className="col-form-label mr-2">Limite {getTerme("accompagnants", this.props.structure.domaine)} : {dataService.getStructureNbConseillersActifs(this.props.structure)} / </label>
															<div className="">
																<select className="form-control" value={this.state.structure.nbMaxConseillers} onChange={(e) => this.changeStructure("nbMaxConseillers", e.target.value)}>
																	{optionsNbMaxConseillers}
																</select>
															</div>
														</div>	   	
														<div className="form-group row">
															<div className="col-md-9 p-0 m-0">
																<label className="col-form-label mr-2">Nbre de SMS non désirés avant avertissement :</label>
															</div>
															<div className="col-md-3 p-0 m-0">
																<div className="mt-2">
																	<select className="form-control" value={this.state.structure.nbMessagesAvantAvertissement} onChange={(e) => this.changeStructure("nbMessagesAvantAvertissement", e.target.value)}>
																		{optionsNbMessagesAvantAvertissement}
																	</select>
																</div>
															</div>
														</div>                                                                                        	
													</div>
												</div>
												<hr />
												<h4>Responsable{this.props.structure.responsables.length > 1 && <>s</>}</h4>
												<div className="text-left">
													{this.props.structure.responsables.map(r => {
														let responsable = dataService.getConseillerById(r);
														if (!responsable) return null;
														return (
															<p key={"row-responsable-" + responsable.id}>{responsable.prenom} {responsable.nom}</p>
														)
													})}											                                                                                        	
												</div>
												<hr />
												<a href="#" data-toggle="modal" data-target="#modal-toggleactivationstructure" className={"btn btn-annexe contour text-center w-100" + (this.state.structure.actif ? " attention" : " action")}><i className={this.state.structure.actif ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i> {this.state.structure.actif ? "Désactiver la structure" : "Réactiver la structure"}</a>
												<div key="modal-toggleactivationstructure" id="modal-toggleactivationstructure" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
													<div className="modal-dialog modal-dialog-centered modal-lg">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title mt-0">{this.state.structure.actif ? "Désactiver" : "Réactiver" } la structure</h5>
																<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
															</div>
															<div className="modal-body">
																{this.state.structure.actif && <p>L'ensemble des services de suivi et comptes utilisateurs liés à <b>{this.state.structure.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
																{!this.state.structure.actif && <p>L'ensemble des services de suivi et comptes utilisateurs liés à <b>{this.state.structure.nom}</b> seront réactivés. Voulez-vous continuer ?</p>}
															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-primary" onClick={() => this.changeStructure("actif", !this.state.structure.actif)} data-dismiss="modal">{this.state.structure.actif ? "Désactiver" : "Réactiver" } la structure</button>
																<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
															</div>
														</div>
													</div>
												</div>
												<a href="#" data-toggle="modal" data-target="#modal-suppressionstructure" className={"btn btn-annexe contour text-center w-100 attention suppression"}><i className="dripicons-trash"></i> Supprimer la structure</a>
												<div key="modal-suppressionstructure" id="modal-suppressionstructure" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
													<div className="modal-dialog modal-dialog-centered modal-lg">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title mt-0">Supprimer la structure</h5>
																<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
															</div>
															<div className="modal-body">
																<p>L'ensemble des services de suivi et comptes utilisateurs liés à <b>{this.state.structure.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-primary suppression" onClick={() => this.changeStructure("supprime", true)} data-dismiss="modal">Supprimer la structure</button>
																<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
															</div>
														</div>
													</div>
												</div>
											</>
										}
										{!securityService.isAdmin() &&
											<>
												<h4>Responsable{this.props.structure.responsables.length > 1 && <>s</>}</h4>
												<div className="text-left">
													{this.props.structure.responsables.map(r => {
														let responsable = dataService.getConseillerById(r);
														if (!responsable) return null;
														return (
															<p key={"row-responsable-" + responsable.id}>{responsable.prenom} {responsable.nom}</p>
														)
													})}							                                                                                        	
												</div>
												<hr />
												<h4>Paramètres</h4>
												<div className="signaux">
													<div className="text-left">
														<div className="form-group row mt-5">
															<label className="col-form-label mr-2">Accès au suivi d'activité :  <b>{this.props.structure.accesSuiviActivite ? "Oui" : "Non"}</b></label>
														</div> 												   
														{/*<div className="form-group row">
															<label className="col-form-label mr-2">Comptes utilisateurs actifs :  <b>{dataService.getStructureNbBeneficiairesActifs(this.props.structure)} / {this.props.structure.nbMaxBeneficiaires}</b></label>
														</div>*/}
														<div className="form-group row">
															<label className="col-form-label mr-2">Comptes {getTerme("utilisateurs", this.props.structure.domaine)} actifs avec signaux faibles :  <b>{dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure)} / {this.props.structure.nbMaxBeneficiairesSuiviAvance}</b></label>
														</div>
														<div className="form-group row">
															<label className="col-form-label mr-2">Comptes {getTerme("utilisateurs", this.props.structure.domaine)} actifs sans signaux faibles :  <b>{dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure)} / {this.props.structure.nbMaxBeneficiairesSuiviNormal}</b></label>
														</div>
														<div className="form-group row">
															<label className="col-form-label mr-2">Comptes {getTerme("accompagnants", this.props.structure.domaine)} actifs :  <b>{dataService.getStructureNbConseillersActifs(this.props.structure)} / {this.props.structure.nbMaxConseillers}</b></label>
														</div>	   	
														<div className="form-group row">
															<div className="col-md-9 p-0 m-0">
																<label className="col-form-label mr-2">Nbre de SMS non désirés avant avertissement : <b>{this.props.structure.nbMessagesAvantAvertissement}</b></label>
															</div>
														</div>                                                                                        	
													</div>
												</div>
											</>
										}
									</div>
								</div>
							</div>
							<div className="col-md-8">
								<div className="row">
									<div className="col-md-12">		
										<h3>{this.props.structure.nom}</h3>
										<br clear="both" />
										<div className="card contenu m-b-20">
											<div className="card-body">
												<ul className="nav nav-tabs" role="tablist">
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.structureTab == "conseillers" ? "active" : "")} data-toggle="tab" href="#conseillers" role="tab" onClick={() => this.changeTab("conseillers")}>
															<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
															<span className="d-none d-sm-block">{getTerme("Accompagnants", this.props.structure.domaine)}</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.structureTab == "responsables" ? "active" : "")} data-toggle="tab" href="#responsables" role="tab" onClick={() => this.changeTab("responsables")}>
															<span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
															<span className="d-none d-sm-block">Responsables</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.structureTab == "beneficiaires" ? "active" : "")} data-toggle="tab" href="#beneficiaires" role="tab" onClick={() => this.changeTab("beneficiaires")}>
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">{getTerme("Utilisateurs", this.props.structure.domaine)}</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.structureTab == "statistiques" ? "active" : "")} data-toggle="tab" href="#statistiques" role="tab" onClick={() => this.changeTab("statistiques")}>
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">Statistiques</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.structureTab == "filtres" ? "active" : "")} data-toggle="tab" href="#filtres" role="tab" onClick={() => this.changeTab("filtres")}>
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">Filtres</span>
														</a>
													</li>
													<li className="nav-item">
														<a className={"nav-link " + (this.props.ihm.structureTab == "profils" ? "active" : "")} data-toggle="tab" href="#profils" role="tab" onClick={() => this.changeTab("profils")}>
															<span className="d-block d-sm-none"><i className="far fa-user"></i></span>
															<span className="d-none d-sm-block">Profils</span>
														</a>
													</li>
												</ul>
												<div className="tab-content">
													<div className={"tab-pane p-3 " + (this.props.ihm.structureTab == "conseillers" ? "active" : "")} id="conseillers" role="tabpanel">										
														<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouveauconseiller" disabled={dataService.getStructureNbConseillersActifs(this.props.structure) >= this.props.structure.nbMaxConseillers}><i className="mdi mdi-plus"></i> {getTerme("Nouvel accompagnant", this.props.structure.domaine)}</button>
														<br clear="both" />
														{this.props.structure.filtres.length > 0 &&
															<div className="mb-3 select2-filtre">
																<select id="filtre-conseillers-filtres" className="form-control select2 select2-multiple" multiple data-placeholder="Filtrer...">
																	{this.props.structure.filtres
																	.map((filtre, iFiltre) => {
																		return (
																			<React.Fragment key={"filtre-" + iFiltre}>
																				<option value={filtre.id}>{filtre.nom}</option>
																				{filtre.filtres.map((sousFiltre, iSousFiltre) => {
																					return (
																						<React.Fragment key={"sousfiltre-" + iSousFiltre}>
																							<option value={sousFiltre.id}>{filtre.nom} - {sousFiltre.nom}</option>
																						</React.Fragment>
																					)
																				})}
																			</React.Fragment>
																		)
																	})}																		
																</select>
															</div>
														}
														<div className="table-responsive">
															<table className="table table-vertical">
																<thead>
																	<tr>
																		<th>
																			<div className={"checkbox-wrapper" + (this.state.conseillersSelectionnes.length != dataService.getStructureConseillers(this.props.structure).filter((conseiller) => !conseiller.seulementAdmin).length ? " selection" : "")}>
																				<input type="checkbox" id="toggle-conseillers-all" checked={this.state.conseillersSelectionnes.length > 0} onChange={() => this.toggleAllConseillers()} />
                                        										<label htmlFor="toggle-conseillers-all" className="toggle"></label>
																			</div>
																		</th>
																		{this.state.conseillersSelectionnes.length == 0 &&
																			<>
																				<th>Nom</th>
																				<th>Coordonnées</th>
																				<th>Nbre {getTerme("d'utilisateurs", this.props.structure.domaine)} suivis</th>
																				{securityService.isAdmin() &&
																					<th>Dern. connexion</th>
																				}
																				<th>Actions</th>
																			</>
																		}
																		{this.state.conseillersSelectionnes.length > 0 &&
																			<th colSpan="4">
																				<button type="button" data-toggle="modal" data-target="#modal-filtresconseillers" className="btn btn-annexe contour">Filtres</button>
																				{!this.contientAllResponsables(this.state.conseillersSelectionnes) && this.conseillersSelectionnesActifs() !== null && <button type="button" data-toggle="modal" data-target="#modal-toggleactivationconseillers" disabled={this.state.conseillersSelectionnes.length + dataService.getStructureNbConseillersActifs(this.props.structure) >= this.props.structure.nbMaxConseillers} className="btn btn-annexe contour"><i className={this.conseillersSelectionnesActifs() ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i> {this.conseillersSelectionnesActifs() ? "Désactiver" : "Réactiver"}</button>}
                                        										{!this.contientAllResponsables(this.state.conseillersSelectionnes) && <button type="button" data-toggle="modal" data-target="#modal-suppressionconseillers" className="btn btn-annexe contour"><i className="dripicons-trash"></i> Supprimer</button>}
																			</th>
																		}
																	</tr>
																</thead>
																<tbody>
																	{conseillers}
																	<tr>
																		<td colSpan="6" className="tdaction">
																			<button className="m-0 btn btn-annexe contour action" data-toggle="modal" data-target="#modal-nouveauconseiller" disabled={dataService.getStructureNbConseillersActifs(this.props.structure) >= this.props.structure.nbMaxConseillers}><i className="mdi mdi-plus"></i> Créer un {getTerme("nouvel accompagnant", this.props.structure.domaine)}</button>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>                                                  
													</div>
													<div className={"tab-pane p-3 " + (this.props.ihm.structureTab == "responsables" ? "active" : "")} id="responsables" role="tabpanel">										
														<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouveauconseiller" onClick={() => this.setState({nouveauResponsable: true})}><i className="mdi mdi-plus"></i> Nouveau responsable</button>						
														                                                   												
														<br clear="both" />
														<div className="table-responsive">
															<table className="table table-vertical">
																<thead>
																	<tr>
																		<th>
																			<div className={"checkbox-wrapper" + (this.state.responsablesSelectionnes.length != dataService.getStructureConseillers(this.props.structure).filter((conseiller) => this.props.structure.responsables.includes(conseiller.id) || conseiller.seulementAdmin).length ? " selection" : "")}>
																				<input type="checkbox" id="toggle-responsables-all" checked={this.state.responsablesSelectionnes.length > 0} onChange={() => this.toggleAllResponsables()} />
                                        										<label htmlFor="toggle-responsables-all" className="toggle"></label>
																			</div>
																		</th>
																		{this.state.responsablesSelectionnes.length == 0 &&
																			<>
																				<th>Nom</th>
																				<th>Coordonnées</th>
																				<th>Est responsable</th>
																				<th>Est {getTerme("accompagnant", this.props.structure.domaine)}</th>
																				{securityService.isAdmin() &&
																					<th>Dern. connexion</th>
																				}
																				<th>Actions</th>
																			</>
																		}
																		{this.state.responsablesSelectionnes.length > 0 &&
																			<th colSpan="5">
																				{!this.contientAllResponsables(this.state.responsablesSelectionnes) && this.responsablesSelectionnesActifs() !== null && <a href="#" data-toggle="modal" data-target="#modal-toggleactivationconseillers" className="btn btn-annexe contour"><i className={this.responsablesSelectionnesActifs() ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i> {this.responsablesSelectionnesActifs() ? "Désactiver" : "Réactiver"}</a>}
                                        										{!this.contientAllResponsables(this.state.responsablesSelectionnes) && <a href="#" data-toggle="modal" data-target="#modal-suppressionconseillers" className="btn btn-annexe contour"><i className="dripicons-trash"></i> Supprimer</a>}
																			</th>
																		}
																	</tr>
																</thead>
																<tbody>
																	{responsables}
																	<tr>
																		<td colSpan="7" className="tdaction">
																			<button className="m-0 btn btn-annexe contour action" data-toggle="modal" data-target="#modal-nouveauconseiller" onClick={() => this.setState({nouveauResponsable: true})}><i className="mdi mdi-plus"></i> Créer un nouveau responsable</button>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>                                                  
													</div>
													<div className={"tab-pane p-3 " + (this.props.ihm.structureTab == "beneficiaires" ? "active" : "")} id="beneficiaires" role="tabpanel">						
														<div className="row">
															<div className="col-md-12">							
																<button href="#" className="mb-3 btn btn-primary float-right" data-toggle="modal" data-target="#modal-nouvellepersonne" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviAvance && dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviNormal}><i className="mdi mdi-plus"></i> {getTerme("Nouvel utilisateur", this.props.structure.domaine)}</button>
																<br clear="both" />
																{this.props.structure.filtres.length > 0 &&
																	<div className="mb-3 select2-filtre">
																		<select id="filtre-beneficiaires-filtres" className="form-control select2 select2-multiple" multiple data-placeholder="Filtrer...">
																			{this.props.structure.filtres
																			.map((filtre, iFiltre) => {
																				return (
																					<React.Fragment key={"filtre-" + iFiltre}>
																						<option value={filtre.id}>{filtre.nom}</option>
																						{filtre.filtres.map((sousFiltre, iSousFiltre) => {
																							return (
																								<React.Fragment key={"sousfiltre-" + iSousFiltre}>
																									<option value={sousFiltre.id}>{filtre.nom} - {sousFiltre.nom}</option>
																								</React.Fragment>
																							)
																						})}
																					</React.Fragment>
																				)
																			})}																		
																		</select>
																	</div>
																}
																<div className="table-responsive">
																	<table className="table table-vertical">
																		<thead>
																			<tr>
																				<th>
																					<div className={"checkbox-wrapper" + (this.state.beneficiairesSelectionnes.length != this.props.structure.beneficiaires.length ? " selection" : "")}>
																						<input type="checkbox" id="toggle-beneficiaires-all" checked={this.state.beneficiairesSelectionnes.length > 0} onChange={() => this.toggleAllBeneficiaires()} />
																						<label htmlFor="toggle-beneficiaires-all" className="toggle"></label>
																					</div>
																				</th>
																				{this.state.beneficiairesSelectionnes.length == 0 &&
																					<>
																						<th>Nom</th>
																						<th>Signaux faibles</th>
																						<th>{getTerme("Accompagnants", this.props.structure.domaine)}</th>																						
																						<th>Questions actives</th>
																						<th>Consentement</th>
																						<th>Actions</th>
																					</>
																				}
																				{this.state.beneficiairesSelectionnes.length > 0 &&
																					<th colSpan="4">
																						<button type="button" data-toggle="modal" data-target="#modal-filtresbeneficiaires" className="btn btn-annexe contour">Filtres</button>
																						{this.beneficiairesSelectionnesActifs() !== null && <button type="button" data-toggle="modal" data-target="#modal-structuretoggleactivationbeneficiaires" className="btn btn-annexe contour" disabled={!this.beneficiairesSelectionnesActifs() ? (this.state.beneficiairesSelectionnes.length + dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) > this.props.structure.nbMaxBeneficiairesSuiviAvance && this.state.beneficiairesSelectionnes.length + dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) > this.props.structure.nbMaxBeneficiairesSuiviNormal) : false}><i className={this.beneficiairesSelectionnesActifs() ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i> {this.beneficiairesSelectionnesActifs() ? "Désactiver" : "Réactiver"}</button>}
																						<button type="button" data-toggle="modal" data-target="#modal-structuresuppressionbeneficiaires" className="btn btn-annexe contour"><i className="dripicons-trash"></i> Supprimer</button>
																					</th>
																				}
																			</tr>
																		</thead>
																		<tbody>
																			{beneficiaires}
																			{beneficiairesSupprimes}
																			<tr>
																				<td colSpan="7" className="tdaction">
																					<button className="m-0 btn btn-annexe contour action" data-toggle="modal" data-target="#modal-nouvellepersonne" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviAvance && dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) >= this.props.structure.nbMaxBeneficiairesSuiviNormal}><i className="mdi mdi-plus"></i> Créer un {getTerme("nouvel utilisateur", this.props.structure.domaine)}</button>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>                                                       
													</div>
													<div className={"tab-pane p-3 " + (this.props.ihm.structureTab == "statistiques" ? "active" : "")} id="statistiques" role="tabpanel">						
														<div className="row">
															<div className="col-md-12">
																<form className="form-statistiques">	
																	<div className="row">
																		<div className="input-group date">
																			<div className="input-group-prepend">
																				<div className="input-group-text">Date de début</div>
																			</div>
																			<DatePicker
																				ref={(input) => { this.ref_statistiquesDebutWidget = input; }} 
																				className="form-control" 
																				locale="fr"
																				selected={this.state.statistiquesDebut}
																				onChange={(date) => this.setState({statistiquesDebut: date})}
																				dateFormat="dd/MM/yyyy"
																				maxDate={this.state.statistiquesFin}
																			/>
																			<div className="input-group-append" onClick={() => this.ref_statistiquesDebutWidget.setFocus(true)}>
																				<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																			</div>
																		</div>
																		<div className="input-group date">
																			<div className="input-group-prepend">
																				<div className="input-group-text">Date de fin (incluse)</div>
																			</div>
																			<DatePicker
																				ref={(input) => { this.ref_statistiquesFinWidget = input; }} 
																				className="form-control" 
																				locale="fr"
																				selected={this.state.statistiquesFin}
																				onChange={(date) => this.setState({statistiquesFin: date})}
																				dateFormat="dd/MM/yyyy"
																				minDate={this.state.statistiquesDebut}
																			/>
																			<div className="input-group-append" onClick={() => this.ref_statistiquesFinWidget.setFocus(true)}>
																				<span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
																			</div>
																		</div>																	
																	</div>	
																	<br clear="both" />														
																	<div className="row mt-3 ml-1">
																		<button type="button" className="mb-3 btn btn-primary" onClick={() => this.getStatistiques()}>Télécharger le fichier de statistiques</button>
																	</div>															
																</form>
															</div>
														</div>
													</div>
													<div className={"tab-pane p-3 " + (this.props.ihm.structureTab == "filtres" ? "active" : "")} id="filtres" role="tabpanel">						
														<div className="row">
															<div className="col-md-12">
																<button onClick={(e) => this.ajouteFiltre()} className="mb-3 btn btn-primary float-right"><i className="mdi mdi-plus"></i> Nouveau filtre</button>		
																<br clear="both" />																
																<form>																																																																																
																	{this.state.structure.filtres.length == 0 &&
																		<><p>Aucun filtre.</p></>
																	}
																	{this.state.structure.filtres.map((filtre, iFiltre) => {
																		return (
																			<React.Fragment key={"filtre-" + iFiltre}>		
																				{iFiltre != 0 && 
																					<hr />
																				}																	
																				<div className="form-row">
																					<div className="form-group col-md-9">
																						<div className={"input-group input-obligatoire" + (this.state.erreurs.includes('filtre-' + iFiltre + '-nom') ? " input-error" : "")}>
																							<div className="input-group-prepend">
																								<div className="input-group-text">Filtre {iFiltre + 1} *</div>
																							</div>
																							<input type="text" className="form-control" value={filtre.nom} onChange={(e) => this.modifieFiltre(iFiltre, "nom", e.target.value)} />
																							<div className="input-group-append">
																								<button className="btn btn-outline-secondary" onClick={(e) => {e.preventDefault(); this.supprimeFiltre(iFiltre)}}><i className="dripicons-trash" /></button>																					
																							</div>
																						</div>																						
																					</div>																						
																				</div>
																				{filtre.filtres.map((sousFiltre, iSousFiltre) => {
																					return (
																						<React.Fragment key={"sousfiltre-" + iSousFiltre}>
																							<div className="form-row">
																								<div className="form-group offset-md-1 col-sm-8 col-md-7">
																									<div className={"input-group input-obligatoire" + (this.state.erreurs.includes('filtre-' + iFiltre + '-nom') ? " input-error" : "")}>
																										<div className="input-group-prepend">
																											<div className="input-group-text">Sous-filtre {iSousFiltre + 1} *</div>
																										</div>
																										<input type="text" className="form-control" value={sousFiltre.nom} onChange={(e) => this.modifieSousFiltre(iFiltre, iSousFiltre, "nom", e.target.value)} />
																										<div className="input-group-append">
																											<button className="btn btn-outline-secondary" onClick={(e) => {e.preventDefault(); this.supprimeSousFiltre(iFiltre, iSousFiltre)}}><i className="dripicons-trash" /></button>																					
																										</div>
																									</div>																									
																								</div>																																																
																							</div>																							
																						</React.Fragment>
																					)
																				})}																					
																				<div className="offset-md-1">
																					<button className="btn btn-secondary" onClick={(e) => {e.preventDefault(); this.ajouteSousFiltre(iFiltre)}}><i className="mdi mdi-plus"></i> Nouveau sous-filtre</button>																																							
																				</div>																																								
																			</React.Fragment>
																		)
																	})}														
																</form>	
																<br clear="both" />
																<button className="btn btn-primary" disabled={JSON.stringify(this.props.structure.filtres) == JSON.stringify(this.state.structure.filtres) || !this.filtresValides()} data-toggle="modal" data-target="#modal-filtres">Enregistrer</button>
															</div>
														</div>
													</div>
													<div className={"tab-pane p-3 " + (this.props.ihm.structureTab == "profils" ? "active" : "")} id="profils" role="tabpanel">						
														<div className="row">
															<div className="col-md-12">							
																<div className="table-responsive">
																	<table className="table table-vertical">
																		<thead>
																			<tr>
																				<th>Nom</th>																				
																				<th>Nombre {getTerme("d'utilisateurs", this.props.structure.domaine)}</th>																				
																				<th>Date de synchronisation {getTerme('utilisateur', this.props.structure.domaine)}</th>
																				<th>Date de synchronisation {getTerme('supérieur hiérarchique', this.props.structure.domaine)}</th>																				
																			</tr>
																		</thead>
																		<tbody>
																			{lignesProfils}																			
																		</tbody>
																	</table>
																</div>
															</div>
														</div>                                                       
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>							
						</div>
					</div>
					<Footer />
				</div>
				{modals}
				<div id="modal-nouveauconseiller" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.state.nouveauResponsable ? "Nouveau responsable" : getTerme("Nouvel accompagnant", this.props.structure.domaine)}</h5>
								<div className="header-buttons">
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
									<button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formConseillerValide()} onClick={() => this.enregistreConseiller(this.state.editingConseiller)}>Enregistrer</button>
								</div>
							</div>
							<div className="modal-body">
								<FormConseiller
									conseiller={this.state.editingConseiller}
									modifie={(conseiller) => this.setEditingConseiller(conseiller)}
									responsable={this.state.nouveauResponsable}
									structure={this.props.structure}
								/>
							</div>									
						</div>
					</div>
				</div>
				<div id="modal-nouvellepersonne" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg modal-form">
						<FormBeneficiaire
							enregistre={(beneficiaire) => this.enregistreBeneficiaire(beneficiaire)}
							structure={this.props.structure}
						/>
					</div>
				</div>  
				<div id="modal-suppressionconseillers" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des comptes de conseillers</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Merci d'indiquer le motif de la suppression des comptes :</p>
								<form>
									<div className="form-group">													
										<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
											<option value="depart">Ne font plus partie des effectifs</option>
											<option value="rgpd">Demande explicite (RGPD)</option>
											<option value="autre">Autre</option>
										</select>
									</div>
								</form>
								<p>L'ensemble des services de suivi et informations liés aux conseillers sélectionnés seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeConseillers()} data-dismiss="modal">Supprimer les comptes des conseillers</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                <div key="modal-toggleactivationconseillers" id="modal-toggleactivationconseillers" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.conseillersSelectionnesActifs() ? "Désactiver" : "Réactiver" } des comptes de conseillers</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.conseillersSelectionnesActifs && <p>L'accès aux données et tous les services de suivi seront désactivés pour les conseillers sélectionnés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!this.conseillersSelectionnesActifs && <p>L'accès aux données et tous les services de suivi seront rétablis pour les conseillers sélectionnés. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationConseillers()} data-dismiss="modal">{this.conseillersSelectionnesActifs() ? "Désactiver" : "Réactiver" } les comptes conseillers</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div key="modal-filtresconseillers" id="modal-filtresconseillers" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Gérer les filtres</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								Veuillez choisir les filtres auxquels doivent être rattachées les personnes sélectionnées :<br /><br />
								{this.props.structure.filtres.map((filtre, iFiltre) => {
									return (
										<React.Fragment key={"filtre-" + iFiltre}>
											<div className="checkbox-wrapper mr-2">
												<input type="checkbox" id={"toggle-filtregroupeconseillers-" + filtre.id} checked={this.state.filtresGroupeConseillers.includes(filtre.id)} onChange={(e) => this.toggleFiltreGroupeConseillers(filtre.id)} />
												<label htmlFor={"toggle-filtregroupeconseillers-" + filtre.id} className="toggle"></label>
											</div>
											<label htmlFor={"toggle-filtregroupeconseillers-" + filtre.id}> {filtre.nom}</label>
											<br />
											{filtre.filtres.map((sousFiltre, iSousFiltre) => {
												return (
													<React.Fragment key={"sousfiltre-" + iSousFiltre}>
														<div className="ml-3">
															<div className="checkbox-wrapper mr-2">
																<input type="checkbox" id={"toggle-filtregroupeconseillers-" + sousFiltre.id} checked={this.state.filtresGroupeConseillers.includes(sousFiltre.id)} onChange={(e) => this.toggleFiltreGroupeConseillers(sousFiltre.id)} />
																<label htmlFor={"toggle-filtregroupeconseillers-" + sousFiltre.id} className="toggle"></label>
															</div>
															<label htmlFor={"toggle-filtregroupeconseillers-" + sousFiltre.id}> {sousFiltre.nom}</label>
														</div>                                                
													</React.Fragment>
												)
											})}
										</React.Fragment>
									)
								})}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.enregistreFiltresGroupeConseillers()} data-dismiss="modal">Enregistrer</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div id="modal-structuresuppressionbeneficiaires" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des comptes de personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Merci d'indiquer le motif de la suppression des comptes :</p>
								<form>
									<div className="form-group">													
										<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
											<option value="depart">Ne font plus partie des effectifs</option>
											<option value="rgpd">Demande explicite (RGPD)</option>
											<option value="autre">Autre</option>
										</select>
									</div>
								</form>
								<p>L'ensemble des services de suivi et informations liés aux personnes sélectionnées seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeBeneficiaires()} data-dismiss="modal">Supprimer les comptes des personnes</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                <div key="modal-structuretoggleactivationbeneficiaires" id="modal-structuretoggleactivationbeneficiaires" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.beneficiairesSelectionnesActifs() ? "Désactiver" : "Réactiver" } des comptes de personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.beneficiairesSelectionnesActifs() && <p>L'ensemble des services de suivi concernant concernant les personnes sélectionnées seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!this.beneficiairesSelectionnesActifs() && <p>Les suivis concernant les personnes sélectionnées seront réactivés. Vous pouvez choisir ci-dessous le type de suivi (avec signaux faibles, sans signaux faibles) souhaité.</p>}
							</div>
							<div className="modal-footer">
								{this.beneficiairesSelectionnesActifs() &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationBeneficiaires(null)} data-dismiss="modal">Désactiver le suivi des personnes</button>
									</>
								}
								{!this.beneficiairesSelectionnesActifs() &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationBeneficiaires("avance")} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(this.props.structure) + this.state.beneficiairesSelectionnes.length > this.props.structure.nbMaxBeneficiairesSuiviAvance}>Réactiver les personnes avec signaux faibles</button>
										<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationBeneficiaires("normal")} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviNormal(this.props.structure) + this.state.beneficiairesSelectionnes.length > this.props.structure.nbMaxBeneficiairesSuiviNormal}>Réactiver les personnes sans signaux faibles</button>
									</>
								}								
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div key="modal-filtresbeneficiaires" id="modal-filtresbeneficiaires" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Gérer les filtres</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								Veuillez choisir les filtres auxquels doivent être rattachées les personnes sélectionnées :<br /><br />
								{this.props.structure.filtres.map((filtre, iFiltre) => {
									return (
										<React.Fragment key={"filtre-" + iFiltre}>
											<div className="checkbox-wrapper mr-2">
												<input type="checkbox" id={"toggle-filtregroupebeneficiaires-" + filtre.id} checked={this.state.filtresGroupeBeneficiaires.includes(filtre.id)} onChange={(e) => this.toggleFiltreGroupeBeneficiaires(filtre.id)} />
												<label htmlFor={"toggle-filtregroupebeneficiaires-" + filtre.id} className="toggle"></label>
											</div>
											<label htmlFor={"toggle-filtregroupebeneficiaires-" + filtre.id}> {filtre.nom}</label>
											<br />
											{filtre.filtres.map((sousFiltre, iSousFiltre) => {
												return (
													<React.Fragment key={"sousfiltre-" + iSousFiltre}>
														<div className="ml-3">
															<div className="checkbox-wrapper mr-2">
																<input type="checkbox" id={"toggle-filtregroupebeneficiaires-" + sousFiltre.id} checked={this.state.filtresGroupeBeneficiaires.includes(sousFiltre.id)} onChange={(e) => this.toggleFiltreGroupeBeneficiaires(sousFiltre.id)} />
																<label htmlFor={"toggle-filtregroupebeneficiaires-" + sousFiltre.id} className="toggle"></label>
															</div>
															<label htmlFor={"toggle-filtregroupebeneficiaires-" + sousFiltre.id}> {sousFiltre.nom}</label>
														</div>                                                
													</React.Fragment>
												)
											})}
										</React.Fragment>
									)
								})}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.enregistreFiltresGroupeBeneficiaires()} data-dismiss="modal">Enregistrer</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div> 
				<div key="modal-filtres" id="modal-filtres" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Enregistrement des filtres</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Vous êtes sur le point d'enregistrer les filtres.</p>
								{filtresSupprimes.length > 0 &&
									<>
										<p>
											Attention ! <b>{filtresSupprimes.length} filtre{filtresSupprimes.length > 1 ? 's ont' : ' a'} été supprimé{filtresSupprimes.length > 1 ? 's' : ''}</b>.<br />
											Cela peut avoir une incidence sur la gestion des {getTerme('utilisateurs', this.props.structure.domaine)} par les {getTerme('accompagnants', this.props.structure.domaine)} et sur les questions qui leur sont envoyées. 
										</p>
									</>
								} 								
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.enregistreFiltres()} data-dismiss="modal">Enregistrer les filtres</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(Structure));