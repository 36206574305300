import React, { Component } from 'react';

import CalendarService from '../../services/calendar';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfobulleRGPD from '../InfobulleRGPD';

class FormPeriodeConges extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.erreurs = [];        
    }

    initFromProps() {

        let calendarService = new CalendarService();

        let fin = new Date();
        fin.setMonth(fin.getMonth() + 1);

        let periode = this.props.periode ? JSON.parse(JSON.stringify(this.props.periode)) : {     
            id: null,
            classe: "PeriodeConges",
            commentaire: "",
            debutWidget: new Date(),
            debut: calendarService.formatteDate(new Date()),
            finWidget: fin,
            fin: calendarService.formatteDate(fin),
        };

        periode.debutWidget = new Date(periode.debut);
        periode.finWidget = new Date(periode.fin);        

        return {
            periode: periode
        };
    }

    componentDidMount() {
		console.log("formperiode did mount");

    }

    componentDidUpdate(prevProps) {
        if (prevProps.periode != this.props.periode) {
            this.setState(this.initFromProps());
        }
    }
    
    modifiePeriode(champ, val) {
        let periode = this.state.periode;
        console.log(periode);
        console.log(champ);
        console.log(val);
        if (champ == "commentaire") {
            periode.commentaire = val;
        }
        if (champ == "debut") {
            let calendarService = new CalendarService();
            periode.debutWidget = val;
            periode.debut = calendarService.formatteDate(periode.debutWidget);
            if (periode.debut >= periode.fin) {                                
                periode.finWidget = new Date(periode.debutWidget.getTime());
                periode.finWidget.setDate(periode.finWidget.getDate() + 1);                        
                periode.fin = calendarService.formatteDate(periode.finWidget)                
            }            
        }
        if (champ == "fin") {
            let calendarService = new CalendarService();
            periode.finWidget = val;
            periode.fin = calendarService.formatteDate(periode.finWidget);
        }
        console.log(periode);
        this.setState({
            periode: periode,
            erreurs: this.valideChamp(champ)
        });
        console.log(this.state.periode);
        if (this.props.modifie) {
            this.props.modifie(this.state.periode);
        }
    }

    valideChamp(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
        return erreurs;
    }


	render() {

        return ( 
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="input-group date">
                            <label>Du</label>
                            <DatePicker
                                ref={(input) => { this.ref_dateDebutWidget = input; }} 
                                className="form-control" 
                                locale="fr"
                                selected={this.state.periode.debutWidget}
                                onChange={(date) => this.modifiePeriode("debut", date)}
                                dateFormat="dd/MM/yyyy"
                                //maxDate={this.state.periode.finWidget}
                            />    
                            <div className="input-group-append" onClick={() => this.ref_dateDebutWidget.setFocus(true)}>
                                <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                            </div>
                        </div>
                        <div className="input-group date">
                            <label>Au</label>
                            <DatePicker
                                ref={(input) => { this.ref_dateFinWidget = input; }} 
                                className="form-control" 
                                locale="fr"
                                selected={this.state.periode.finWidget}
                                onChange={(date) => this.modifiePeriode("fin", date)}
                                dateFormat="dd/MM/yyyy"
                                minDate={this.state.periode.debutWidget}
                            />   
                            <div className="input-group-append" onClick={() => this.ref_dateFinWidget.setFocus(true)}>
                                <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                            </div>
                        </div>                                                                              
                        <br clear="both" />
                        <hr />        
                        <div className="form-group">
                            <label className="col-form-label">Commentaire <InfobulleRGPD /> :</label>
                            <div className="input-group">
                                <textarea id="textarea" className="form-control" maxLength="225" rows="3" value={this.state.periode.commentaire} onChange={(e) => this.modifiePeriode("commentaire", e.target.value)}></textarea>
                            </div>
                        </div>
                    </form>
                </div>                                
            </div>
        );
	}
}

export default FormPeriodeConges;