import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import 'bootstrap-filestyle/src/bootstrap-filestyle.min.js';

import Api from '../../api';

import CalendarService from '../../services/calendar';

import FormEmploi from '../Forms/FormEmploi';
import FormProche from '../Forms/FormProche';
import InfobulleRGPD from '../InfobulleRGPD';
import DataService from '../../services/data';

const streamToBlob = require('stream-to-blob');

class TabInformations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            personne: JSON.parse(JSON.stringify(this.props.personne)),
            editingEmploi: null,
            editingProche: null,
            erreurs: [],
            changeFormulaireConsentement: false
        }
    }

    componentDidMount() {
        console.log("TabInformations did mount");

        this.bindModals();

        $("#formulaire-consentement").filestyle({
            buttonText: "Ajouter un ficher",
            buttonName: "btn-secondary",
            iconName: "fas fa-paperclip"
        });

        
    }

    componentDidUpdate(prevProps) {
        console.log("TabInformations did update");

        if (JSON.stringify(prevProps.personne) != JSON.stringify(this.props.personne)) {
            this.setState({
                personne: JSON.parse(JSON.stringify(this.props.personne))
            });
        }

        this.bindModals();   
        
        $("#formulaire-consentement").filestyle({
            buttonText: "Ajouter un ficher",
            buttonName: "btn-secondary",
            iconName: "fas fa-paperclip"
        });
    }

    bindModals() {
        window.jQuery("#modal-nouvelemploi").off('hidden.bs.modal');
        window.jQuery("#modal-nouvelemploi").on('hidden.bs.modal', () => {
            this.setState({
                editingEmploi: null
            });
        });
        window.jQuery("#modal-informations-nouveauproche").off('hidden.bs.modal');
        window.jQuery("#modal-informations-nouveauproche").on('hidden.bs.modal', () => {
            this.setState({
                editingProche: null
            });
        });
        if (this.props.personne.classe == "Beneficiaire") {
            this.props.personne.emplois.map((emploi) => {
                window.jQuery("#modal-modifemploi-" + emploi.id).off('hidden.bs.modal');
                window.jQuery("#modal-modifemploi-" + emploi.id).on('hidden.bs.modal', () => {
                    this.setState({
                        editingEmploi: null
                    });
                });
            });
        }
    }

    changeInformationPersonne(champ, valeur) {
		let personne = this.state.personne;
		if (champ == "nom") {
			personne.nom = valeur;
		}
		if (champ == "prenom") {
			personne.prenom = valeur;
        }
        /*
        if (champ == "genre") {
            personne.genre = valeur;
            this.blurInformationPersonne("genre");
		}
        */
		if (champ == "email") {
			personne.email = valeur;
		}
		if (champ == "telephone") {
			personne.telephone = valeur;
        }
        if (champ == "adresse-adresse1") {
			personne.adresse.adresse1 = valeur || null;
        }
        if (champ == "adresse-codePostal") {
			personne.adresse.codePostal = valeur || null;
        }
        if (champ == "adresse-ville") {
			personne.adresse.ville = valeur || null;
		}
		if (champ == "modalite") {
			personne.modalite = valeur;
			this.blurInformationPersonne("modalite");
        }
        if (champ == "commentaire") {
			personne.commentaire = valeur || null;
			//this.blurInformationPersonne("commentaire");
        }
        if (champ == "situationFamiliale") {
			personne.situationFamiliale = valeur || null;
        }
        if (champ == "emplois") {
            personne.emplois = valeur;
            this.blurInformationPersonne("emplois");
        }
        if (champ == "proches") {
            personne.proches = valeur;
            this.blurInformationPersonne("proches");
        }
        if (champ == "formulaireConsentement") {
            personne.formulaireConsentement = valeur;
            this.blurInformationPersonne("formulaireConsentement");
        }
		this.setState({
			personne: personne
		});
	}

	blurInformationPersonne(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
		let personne = null;
		if (champ == "nom") {
			console.log(this.state.personne.nom, this.props.personne.nom)
			if (this.state.personne.nom != this.props.personne.nom) {
                if (this.state.personne.nom != "") {
                    personne = {
                        id: this.props.personne.id,
                        classe: this.props.personne.classe,
                        nom: this.state.personne.nom
                    };
                }
                else {
                    erreurs.push(champ);
                }
			}
		}
		if (champ == "prenom") {
			if (this.state.personne.prenom != this.props.personne.prenom) {
                if (this.state.personne.prenom != "") {
                    personne = {
                        id: this.props.personne.id,
                        classe: this.props.personne.classe,
                        prenom: this.state.personne.prenom
                    };
                }
				else {
                    erreurs.push(champ);
                }
			}
        }
        /*
        if (champ == "genre") {
			if (this.state.personne.genre != this.props.personne.genre) {
                personne = {
                    id: this.props.personne.id,
                    classe: this.props.personne.classe,
                    genre: this.state.personne.genre
                };
            }            
		}
        */
		if (champ == "email") {
			if (this.state.personne.email != this.props.personne.email) {
                if (this.props.personne.classe == "Beneficiaire") {
                    if (this.state.personne.email == "") {
                        if (this.state.personne.telephone == "" || !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.personne.telephone)) {
                            erreurs.push("email");
                        }
                    }
                    else if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.personne.email)) {
                        erreurs.push("email");
                    }
                    else {
                        personne = {
                            id: this.props.personne.id,
                            classe: this.props.personne.classe,
                            email: this.state.personne.email
                        };
                        if (this.state.personne.telephone == "" || /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.personne.telephone)) {
                            personne.telephone = this.state.personne.telephone;
                        }
                        if (this.ajusteModalite()) {
                            personne.modalite = this.ajusteModalite();
                        }
                        if (erreurs.includes("email")) {
                            erreurs.splice(erreurs.indexOf("email"), 1);
                        }
                    }
                }
                if (this.props.personne.classe == "Proche") {
                    if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.personne.email)) {
                        erreurs.push("email");
                    }
                    else {
                        personne = {
                            id: this.props.personne.id,
                            classe: this.props.personne.classe,
                            email: this.state.personne.email
                        };
                        if (this.ajusteModalite()) {
                            personne.modalite = this.ajusteModalite();
                        }
                    }
                }                
			}
        }
		if (champ == "telephone") {
			if (this.state.personne.telephone != this.props.personne.telephone) {
                if (this.props.personne.classe == "Beneficiaire") {
                    if (this.state.personne.telephone == "") {
                        if (this.state.personne.email == "" || !/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.personne.email)) {
                            erreurs.push("telephone");
                        }
                    }
                    else if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.personne.telephone)) {
                        erreurs.push("telephone");
                    }
                    else {
                        personne = {
                            id: this.props.personne.id,
                            classe: this.props.personne.classe,
                            telephone: this.state.personne.telephone
                        };
                        if (this.state.personne.email == "" || /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.personne.email)) {
                            personne.email = this.state.personne.email;
                            if (erreurs.includes("email")) {
                                erreurs.splice(erreurs.indexOf("email"), 1);
                            }
                        }
                        if (this.ajusteModalite()) {
                            personne.modalite = this.ajusteModalite();
                        }
                        if (erreurs.includes("email")) {
                            erreurs.splice(erreurs.indexOf("email"), 1);
                        }
                    }
                }
                if (this.props.personne.classe == "Proche") {
                    if (this.state.personne.telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.personne.telephone)) {
                        erreurs.push("telephone");
                    }
                    else {
                        personne = {
                            id: this.props.personne.id,
                            classe: this.props.personne.classe,
                            telephone: this.state.personne.telephone
                        };
                        if (this.ajusteModalite()) {
                            personne.modalite = this.ajusteModalite();
                        }
                    }
                }
			}
        }
		if (champ == "modalite") {
			if (this.state.personne.modalite != this.props.personne.modalite) {
				personne = {
					id: this.props.personne.id,
					classe: this.props.personne.classe,
					modalite: this.state.personne.modalite
				};
			}
        }
        if (champ == "adresse") {
			if (this.state.personne.adresse != this.props.personne.adresse) {
				personne = {
					id: this.props.personne.id,
					classe: this.props.personne.classe,
					adresse: this.state.personne.adresse
				};
			}
		}
        if (champ == "commentaire") {
			if (this.state.personne.commentaire != this.props.personne.commentaire) {
				personne = {
					id: this.props.personne.id,
					classe: this.props.personne.classe,
					commentaire: this.state.personne.commentaire
				};
			}
        }
        if (champ == "situationFamiliale") {
			if (this.state.personne.situationFamiliale != this.props.personne.situationFamiliale) {
				personne = {
					id: this.props.personne.id,
					classe: this.props.personne.classe,
					situationFamiliale: this.state.personne.situationFamiliale
				};
			}
        }
        if (champ == "emplois") {
			if (this.state.personne.emplois != this.props.personne.emplois) {
				personne = {
					id: this.props.personne.id,
					classe: this.props.personne.classe,
					emplois: this.state.personne.emplois
				};
			}
        }
        if (champ == "proches") {
			if (this.state.personne.proches != this.props.personne.proches) {
				personne = {
					id: this.props.personne.id,
					classe: this.props.personne.classe,
					proches: this.state.personne.proches
				};
			}
        }
        if (champ == "formulaireConsentement") {
            personne = {
                id: this.props.personne.id,
                classe: this.props.personne.classe,
                formulaireConsentement: this.state.personne.formulaireConsentement
            };
		}
        if (champ == "filtres") {
            personne = {
                id: this.props.personne.id,
                classe: this.props.personne.classe,
                filtres: this.state.personne.filtres
            }
        }
		if (personne) {
            this.props.enregistre(personne);
        }
        this.setState({
            erreurs: erreurs
        })
	}

	ajusteModalite() {
		let modalites = [];
		if (this.state.personne.classe == "Beneficiaire" && this.state.personne.telephone) {
			modalites.push("sms");
		}
		if (this.state.personne.email) {
			modalites.push("email");
        }
        /*if (this.state.personne.facebookMessengerId) {
			modalites.push("facebook");
		}*/
		if (modalites.length > 0 && !modalites.includes(this.state.personne.modalite)) {
			return modalites[0];
		}
		return null;
    }
    
    setEditingEmploi(emploi) {
        this.setState({
            editingEmploi: emploi
        });
    }

    ajouteEmploi() {
        let emplois = this.state.personne.emplois;
        emplois.push(
            this.prepareEmploi()
        );
        this.changeInformationPersonne("emplois", emplois);
    }

    modifieEmploi(index) {
        let emplois = this.state.personne.emplois;
        emplois[index] = this.prepareEmploi();
        this.changeInformationPersonne("emplois", emplois);
    }

    formEmploiValide() {

        if (!this.state.editingEmploi) return false;

        let proches = true;
        this.state.editingEmploi.proches.map((proche) => {
            console.log(JSON.parse(JSON.stringify(proche)))
            console.log("tel", /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(proche.telephone))
            console.log("email", /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(proche.email))
            if (
                (proche.nom != "" || proche.prenom != "" || proche.telephone != "" || proche.email != "")
                && (proche.nom == "" || proche.prenom == "" || (proche.telephone != null && proche.telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(proche.telephone)) || !/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(proche.email))
            ) {
                proches = false;
            }
        });

        let sites = true;
        this.state.editingEmploi.sites.map((site) => {
            if (site.codePostal != "" && !/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(site.codePostal)) {
                sites = false;
            }
        });

        console.log("proches", proches)
        console.log("sites", sites)
        console.log("entreprise", this.state.editingEmploi.entreprise)
        console.log("poste", this.state.editingEmploi.poste)

        return (
            this.state.editingEmploi.entreprise != ""
            && this.state.editingEmploi.poste != ""
            && proches
            && sites
        );
    }

    prepareEmploi() {
        if (!this.state.editingEmploi) return;

        let calendarService = new CalendarService();

        let emploi = JSON.parse(JSON.stringify(this.state.editingEmploi));

        console.log(new Date());
        console.log(emploi.debutWidget);

        emploi.proches = this.prepareProches(emploi.proches);
        emploi.sites = this.prepareSites(emploi.sites);
        //emploi.debut = calendarService.formatteDate(emploi.debutWidget);
        //emploi.fin = calendarService.formatteDate(emploi.finWidget);

        return emploi;
    }

    prepareProches(proches) {
        let newProches = proches.filter((proche) => {
            return (proche.nom != "")
        });
        return newProches;
    }

    prepareSites(sites) {
        let newSites = sites.filter((site) => {
            return (site.adresse1 != "" || site.codePostal != "" || site.ville != "")
        });
        return newSites;
    }

    supprimeEmploi(index) {
        let emplois = this.state.personne.emplois;
        emplois[index].supprime = true;
        this.changeInformationPersonne("emplois", emplois);
    }

    setEditingProche(proche) {
        this.setState({
            editingProche: proche
        });
    }

    ajouteProche() {
        console.log("ajouteproche");
        let proches = this.state.personne.proches;
        proches.push(this.state.editingProche);
        console.log(proches);
        this.changeInformationPersonne("proches", proches);
    }

    formProcheValide() {
        return (
			this.state.editingProche
			&& this.state.editingProche.nom != ""
            && this.state.editingProche.prenom != ""
            && /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.editingProche.email)
            && (this.state.editingProche.telephone == "" || /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.editingProche.telephone))
        );
    }

    enregistreProche(p, champ, val) {
        let proche = null;
        if (champ == "actif") {
            proche = {
                id: p.id,
                classe: p.classe,
                actif: val
            }
        }
        if (champ == "supprime") {
            proche = {
                id: p.id,
                classe: p.classe,
                supprime: val
            }
        }
        if (proche) {
            this.props.enregistreProche(proche);
        }
    }

    getDescriptionProche(d) {
        if (d == "parent") return "Parent";
        if (d == "conjoint") return "Conjoint";
        if (d == "ami") return "Ami";
        if (d == "famille") return "Autre membre de la famille";
        if (d == "collegue") return "Collègue";
        if (d == "superieur") return "Supérieur";
        if (d == "rh") return "RH";
        if (d == "emploi") return "Autre personne lié à l'emploi";
        return "Autre";
    }

    uploadFichier(e) {

        if (e.target.files && e.target.files[0]) {
            let api = new Api();

            api.putFichier(e.target.files[0], () => {
                console.log("uploading");
            }, (response) => {
                if (response.fichier) {
                    console.log("finished");
                    console.log(response.fichier);
                    this.changeInformationPersonne("formulaireConsentement", response.fichier);
                    this.setState({
                        changeFormulaireConsentement: false
                    });
                }
            });        
        }
    }

    ouvreFormulaireConsentement() {
        let api = new Api();

        api.getFichier(this.props.personne.formulaireConsentement).then((stream) => {
            let reader = stream.getReader();
            let result = [];   
            let self = this;
            reader.read().then(function processFile({done, value}) {
                console.log(done);
                if (done) {
                    let blob = new Blob(result, {type: "application/pdf"});
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.setAttribute("target", "_blank");
                    // commenter cette ligne pour ouvrir dans nouveau tab, mais impossible d'avoir le bon nom
                    a.setAttribute("download", self.props.personne.prenom + " " + self.props.personne.nom + " - Formulaire de consentement.pdf");  
                    a.click();
                    return;
                }
                result = result.concat(value);
                reader.read().then(processFile);
            });
           
        }).catch(err => {
            console.log(err);
        });
        
    }

    ouvreFormulaireConsentementVierge(falc = false) {
        let api = new Api();

        api.getFichier(falc ? 2 : 1).then((stream) => {
            let reader = stream.getReader();
            let result = [];   
            let self = this;
            reader.read().then(function processFile({done, value}) {
                console.log(done);
                if (done) {
                    let blob = new Blob(result, {type: "application/pdf"});
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.setAttribute("target", "_blank");
                    // commenter cette ligne pour ouvrir dans nouveau tab, mais impossible d'avoir le bon nom
                    a.setAttribute("download", "Wesireport - Charte d'utilisation.pdf");  
                    a.click();
                    return;
                }
                result = result.concat(value);
                reader.read().then(processFile);
            });
           
        }).catch(err => {
            console.log(err);
        }); 
    }

    // remplacé par fonction en dessous pour n'avoir qu'un seul niveau de filtre
    /*toggleFiltre(id) {
        let dataService = new DataService()
        let structure = dataService.getStructureById(this.props.personne.structure)
        if (this.state.personne.filtres.includes(id)) {
            this.state.personne.filtres.splice(this.state.personne.filtres.indexOf(id), 1)            
            structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.personne.filtres.includes(sousFiltre.id)) {
                            this.state.personne.filtres.splice(this.state.personne.filtres.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.personne.filtres.push(id)            
            structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.personne.filtres.includes(filtre.id)) {
                            this.state.personne.filtres.push(filtre.id)
                        }
                    }
                })
            })
        }
        this.setState({
            personne: this.state.personne
        })
        this.blurInformationPersonne("filtres");
    }*/

    // un seul filtre par niveau
    toggleFiltre(id) {
        let dataService = new DataService()
        let structure = dataService.getStructureById(this.props.personne.structure)
        if (this.state.personne.filtres.includes(id)) {
            this.state.personne.filtres.splice(this.state.personne.filtres.indexOf(id), 1)            
            structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.personne.filtres.includes(sousFiltre.id)) {
                            this.state.personne.filtres.splice(this.state.personne.filtres.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.personne.filtres = [id]
            structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.personne.filtres.includes(filtre.id)) {
                            this.state.personne.filtres.push(filtre.id)                            
                        }
                    }
                })
            })
        }
        this.setState({
            personne: this.state.personne
        })
        //this.blurInformationPersonne("filtres");
        $('#modal-togglefiltre').modal('show')
    }

    getNomsFiltres(filtres) {
        let dataService = new DataService()
        let structure = dataService.getStructureById(this.props.personne.structure)
        let filtresBeneficiaire = []
        structure.filtres.map(filtre => {
            let actif = filtres.includes(filtre.id)
            let sousFiltres = []
            filtre.filtres.map(sousFiltre => {
                if (filtres.includes(sousFiltre.id)) {
                    actif = true
                    sousFiltres.push(sousFiltre)
                }
            })								
            if (actif) {
                if (sousFiltres.length == 0) {
                    filtresBeneficiaire.push(filtre.nom)
                }
                else {
                    sousFiltres.map(sousFiltre => {
                        filtresBeneficiaire.push(filtre.nom + " - " + sousFiltre.nom)
                    })
                }
            }
        })
        return filtresBeneficiaire
    }
    
	render() {

        let calendarService = new CalendarService();
        let dataService = new DataService();

        let structure = this.props.personne.classe == "Beneficiaire" ? dataService.getStructureById(this.props.personne.structure) : null
        
        let modals = [];

        let modalites = [];
		if (this.props.personne.classe == "Beneficiaire" && this.props.personne.telephone) {
			modalites.push(
				<option key="option-modalite-sms" value="sms">SMS : {this.props.personne.telephone}</option>
			);
		}
		if (this.props.personne.email) {
			modalites.push(
				<option key="option-modalite-email" value="email">Email : {this.props.personne.email}</option>
			);
        }
        /*if (this.props.personne.facebookMessengerId) {
			modalites.push(
				<option key="option-modalite-facebook" value="facebook">Facebook : {this.props.personne.facebookMessengerId}</option>
			);
        }*/

        let emplois = [];
        let emploisTous = [];
        let proches = [];
        if (this.props.personne.classe == "Beneficiaire") {
            
            this.props.personne.emplois.map((emploi, index) => {
                if (emploi.enCours) {
                    emploisTous.push(
                        <React.Fragment key={"row-emploi-" + emploi.id}>
                            <tr>
                                <td>
                                    <p className="m-0 table-nom font-14">{emploi.poste}</p>
                                </td>
                                <td>
                                    <p className="m-0 text-muted font-14">{emploi.entreprise}</p>
                                </td>
                                <td>
                                    <p className="m-0 text-muted font-14">depuis : {calendarService.depuis(calendarService.intervalle(new Date(emploi.debut), new Date()))}</p>
                                </td>
                            </tr>
                        </React.Fragment>
                    );
                }
                emplois.push(
                    <tr key={"row-emploi-" + emploi.id}>
                        <td>
                            <p className="m-0 table-nom font-14">{emploi.poste}</p>
                        </td>
                        <td>
                            <p className="m-0 text-muted font-14">{emploi.entreprise}</p>
                        </td>
                        <td>
                            <p className="m-0 text-muted font-14">depuis : {calendarService.depuis(calendarService.intervalle(new Date(emploi.debut), new Date()))}</p>
                        </td>
                        <td>
                            <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifemploi-" + emploi.id} onClick={() => this.setEditingEmploi(emploi)}><i className="dripicons-document-edit"></i></a></span>
                            <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionemploi-" + emploi.id}><i className="dripicons-trash"></i></a></span>
                        </td>
                    </tr>
                );
                modals.push(
                    <div key={"modal-modifemploi-" + emploi.id} id={"modal-modifemploi-" + emploi.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Emploi</h5>
                                    <div className="header-buttons">
                                        <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formEmploiValide()} onClick={() => this.modifieEmploi(index)}>Enregistrer</button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <FormEmploi 
                                        emploi={this.state.editingEmploi}
                                        modifie={(emploi) => this.setEditingEmploi(emploi)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                modals.push(
                    <div key={"modal-suppressionemploi-" + emploi.id} id={"modal-suppressionemploi-" + emploi.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Supprimer un emploi</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>En supprimant cet emploi de <b>{this.props.personne.prenom} {this.props.personne.nom}</b>, les personnes associées seront également supprimées. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.supprimeEmploi(index, true)} data-dismiss="modal">Supprimer l'emploi</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>	
                );
            });
            
            this.props.personne.proches.filter((proche) => proche.tag == "famille").map((proche) => {
                proches.push(
                    <tr key={"row-proche-" + proche.id} className={(proche.actif ? "" : "obsolete")}>
                        <td>
                            <NavLink to={"/utilisateurs/" + this.props.personne.id + "/proches/" + proche.id}><p className="m-0 table-nom font-14">{proche.prenom} {proche.nom}</p></NavLink>
                        </td>
                        <td>
                            <p className="m-0 text-muted font-14">{this.getDescriptionProche(proche.description)}</p>
                        </td>
                        <td>
                            <p className="m-0 text-muted font-14"><i className="mdi mdi-checkbox-blank-circle text-success"></i> {proche.actif ? "Actif" : "Inactif"}</p>
                        </td>
                        <td>
                            <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={proche.actif ? "Désactiver" : "Réactiver"}><a href="#" data-toggle="modal" data-target={"#modal-toggleactivationproche-" + proche.id} className="m-0 btn btn-annexe contour"><i className="mdi mdi-block-helper"></i></a></span>
                            <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" data-toggle="modal" data-target={"#modal-suppressionproche-" + proche.id} className="m-0 btn btn-annexe contour"><i className="dripicons-trash"></i></a></span>
                        </td>
                    </tr>
                );
                modals.push(
                    <div key={"modal-toggleactivationproche-" + proche.id} id={"modal-toggleactivationproche-" + proche.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">{proche.actif ? "Désactiver" : "Réactiver" } la personne</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    {proche.actif && <p>Les questions envoyées à <b>{proche.prenom} {proche.nom}</b> concernant <b>{this.props.personne.prenom} {this.props.personne.nom}</b> seront désactivés. Elles pourront être rétablies ultérieurement. Voulez-vous continuer ?</p>}
                                    {!proche.actif && <p>Les questions envoyées à <b>{proche.prenom} {proche.nom}</b> concernant <b>{this.props.personne.prenom} {this.props.personne.nom}</b> seront réactivées. Voulez-vous continuer ?</p>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.enregistreProche(proche, "actif", !proche.actif)} data-dismiss="modal">{proche.actif ? "Désactiver" : "Réactiver" } la personne</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                modals.push(
                    <div key={"modal-suppressionproche-" + proche.id} id={"modal-suppressionproche-" + proche.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Supprimer le compte de la personne</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <p>L'ensemble des services de suivi et informations liés à <b>{proche.prenom} {proche.nom}</b> concernant <b>{this.props.personne.prenom} {this.props.personne.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => this.enregistreProche(proche, "supprime", true)} data-dismiss="modal">Supprimer le compte de la personne</button>
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>	
                );
            });
        }
        
        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="informations" role="tabpanel">
                <h3>Informations personnelles</h3> 							
                <form>
                    <div className="form-row">
                        {/*
                        <div className="form-group col-md-2">
                            <select className="form-control select-genre" value={this.state.personne.genre} onChange={(e) => this.changeInformationPersonne("genre", e.target.value)}>
                                <option value="h">M.</option>
                                <option value="f">Mme</option>
                            </select>                    
                        </div>
                        */}
                        <div className="form-group col-md-6">
                            <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('nom') ? " input-error" : "")}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Nom *</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.personne.nom} onChange={(e) => this.changeInformationPersonne("nom", e.target.value)} onBlur={() => this.blurInformationPersonne("nom")} />
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('prenom') ? " input-error" : "")}>	
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Prénom *</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.personne.prenom} onChange={(e) => this.changeInformationPersonne("prenom", e.target.value)} onBlur={() => this.blurInformationPersonne("prenom")} />
                            </div>
                        </div>
                    </div>
                    {/*{this.state.personne.classe == "Beneficiaire" &&
                        <>
                            <h6>Adresse de vie :</h6>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">N° et nom de rue</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.personne.adresse.adresse1 || ""} onChange={(e) => this.changeInformationPersonne("adresse-adresse1", e.target.value)} onBlur={() => this.blurInformationPersonne("adresse")} />
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Code postal</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.personne.adresse.codePostal || ""} onChange={(e) => this.changeInformationPersonne("adresse-codePostal", e.target.value)} onBlur={() => this.blurInformationPersonne("adresse")} />
                                    </div>
                                </div>
                                <div className="form-group col-md-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Commune</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.personne.adresse.ville || ""} onChange={(e) => this.changeInformationPersonne("adresse-ville", e.target.value)} onBlur={() => this.blurInformationPersonne("adresse")} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }*/}
                    {this.props.personne.classe == "Beneficiaire" &&
                        <>
                            <h6>Moyens de contact :</h6>
                            <p>Saisir au minimum un moyen de contact</p>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('email') ? " input-error" : "")}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Mail **</div>
                                        </div>		
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.personne.email || ""} onChange={(e) => this.changeInformationPersonne("email", e.target.value)}  onBlur={() => this.blurInformationPersonne("email")} />
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('telephone') ? " input-error" : "")}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Téléphone **</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.personne.telephone || ""} onChange={(e) => this.changeInformationPersonne("telephone", e.target.value)}  onBlur={() => this.blurInformationPersonne("telephone")} />
                                    </div>
                                </div>
                            </div>								  		
                            <div className="form-group row">
                                <label className="col-form-label ml-3">Envoyer les questions automatiques sur :</label>
                                <div className="">	
                                    <select className="form-control" value={this.state.personne.modalite} onChange={(e) => this.changeInformationPersonne("modalite", e.target.value)}>
                                        {modalites}
                                    </select>
                                </div>
                            </div>
                            {/*<a href="#" className="mt-0 mb-3 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Ajouter un autre moyen de contact</a>*/}
                        </>
                    }
                    {this.props.personne.classe == "Proche" &&
                        <>
                            <h6>Moyens de contact :</h6>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <div className={"input-group input-obligatoire" + (this.state.erreurs.includes('email') ? " input-error" : "")}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Mail *</div>
                                        </div>		
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.personne.email || ""} onChange={(e) => this.changeInformationPersonne("email", e.target.value)}  onBlur={() => this.blurInformationPersonne("email")} />
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className={"input-group" + (this.state.erreurs.includes('telephone') ? " input-error" : "")}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Téléphone</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="" value={this.state.personne.telephone || ""} onChange={(e) => this.changeInformationPersonne("telephone", e.target.value)}  onBlur={() => this.blurInformationPersonne("telephone")} />
                                    </div>
                                </div>
                            </div>								  		
                            <div className="form-group row">
                                <label className="col-form-label ml-3">Envoyer les questions automatiques sur :</label>
                                <div className="">	
                                    <select className="form-control" value={this.state.personne.modalite} onChange={(e) => this.changeInformationPersonne("modalite", e.target.value)}>
                                        {modalites}
                                    </select>
                                </div>
                            </div>
                            {/*<a href="#" className="mt-0 mb-3 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Ajouter un autre moyen de contact</a>*/}
                        </>
                    }
                </form>
                <br clear="both" />
                {this.props.personne.classe == "Beneficiaire" && ((!this.props.conseiller && structure.filtres.length > 0) || (this.props.conseiller && this.props.conseiller.filtres.length > 0) || (this.props.conseiller && structure.responsables.includes(this.props.conseiller.id))) &&
                    <>
                        <h3>Filtres</h3> 
                        {structure.filtres
                        .filter(filtre => {
                            if (!this.props.conseiller) return true
                            if (this.props.conseiller.responsabilites.includes(structure.id)) return true
                            return this.props.conseiller.filtres.includes(filtre.id)
                        })
                        .map((filtre, iFiltre) => {
                            return (
                                <React.Fragment key={"filtre-" + iFiltre}>
                                    <div className="checkbox-wrapper mr-2">
                                        <input type="checkbox" id={"toggle-filtre-" + filtre.id} checked={this.state.personne.filtres.includes(filtre.id)} onChange={(e) => this.toggleFiltre(filtre.id)} />
                                        <label htmlFor={"toggle-filtre-" + filtre.id} className="toggle"></label>
                                    </div>
                                    <label htmlFor={"toggle-filtre-" + filtre.id}> {filtre.nom}</label>
                                    <br />
                                    {filtre.filtres
                                    .filter(filtre => {
                                        if (!this.props.conseiller) return true
                                        if (this.props.conseiller.responsabilites.includes(structure.id)) return true
                                        if (filtre.filtres.filter(f => this.props.conseiller.includes(f.id)).length == 0) return true
                                        return this.props.conseiller.filtres.includes(filtre.id)
                                    })
                                    .map((sousFiltre, iSousFiltre) => {
                                        return (
                                            <React.Fragment key={"sousfiltre-" + iSousFiltre}>
                                                <div className="ml-3">
                                                    <div className="checkbox-wrapper mr-2">
                                                        <input type="checkbox" id={"toggle-filtre-" + sousFiltre.id} checked={this.state.personne.filtres.includes(sousFiltre.id)} onChange={(e) => this.toggleFiltre(sousFiltre.id)} />
                                                        <label htmlFor={"toggle-filtre-" + sousFiltre.id} className="toggle"></label>
                                                    </div>
                                                    <label htmlFor={"toggle-filtre-" + sousFiltre.id}> {sousFiltre.nom}</label>
                                                </div>                                                
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                        <br clear="both" />
                    </>
                }
                {this.props.personne.classe == "Beneficiaire" && structure.domaine != "entreprise" &&						 
                    <>
                        <h3>Emploi{emplois.length > 1 ? "s" : ""} actuel{emplois.length > 1 ? "s" : ""}</h3> 
                        <div className="table-responsive">
                            <table className="table table-vertical">
                                <tbody>
                                    {emplois}
                                    <tr>
                                        <td colSpan="6" className="tdaction">
                                            <a href="#" data-toggle="modal" data-target="#modal-nouvelemploi" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Ajouter un nouvel emploi</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div id="modal-nouvelemploi" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0">Emploi</h5>
                                            <div className="header-buttons">
                                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                                                <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formEmploiValide()} onClick={() => this.ajouteEmploi()}>Enregistrer</button>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <FormEmploi 
                                                emploi={this.state.editingEmploi}
                                                modifie={(emploi) => this.setEditingEmploi(emploi)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {emplois.length != emploisTous.length && <a href="#" className="m-0 btn btn-eye" data-toggle="modal" data-target="#modal-touslesemplois"><i className="dripicons-preview"></i> Voir tous les emplois</a>}
                            <div id="modal-touslesemplois" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0">Tous les emplois</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="table-responsive">
                                                <table className="table table-vertical">
                                                    <tbody>
                                                        {emploisTous}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="header-buttons">
                                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Fermer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>						
                        </div>                                                          							  
                        <br clear="both" />
                    </>
                }
                {this.props.personne.classe == "Beneficiaire" &&						 
                    <>
                        {/*<h3>Situation familiale</h3>  					
                        <form>
                            <div className="form-row">
                                <div className="form-group w-100">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Description libre &nbsp;<InfobulleRGPD /></div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.personne.situationFamiliale || ""} onChange={(e) => this.changeInformationPersonne("situationFamiliale", e.target.value)} onBlur={(e) => this.blurInformationPersonne("situationFamiliale")} />
                                    </div>
                                </div>
                            </div>							  
                        </form>*/}
                        {/*
                        <div className="table-responsive">
                            <table className="table table-vertical">
                                <tbody>
                                    {proches}
                                    <tr>
                                        <td colSpan="6" className="tdaction">
                                            <a href="#" data-toggle="modal" data-target="#modal-informations-nouveauproche" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Ajouter une nouvelle personne</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>								
                            <button onClick={() => this.props.changeTab('annuaire')} className="m-0 btn btn-eye"><i className="dripicons-preview"></i> Voir toutes les personnes</button>
                        </div>
                        */}
                        {/*<br clear="both" />*/}
                    </>
                }
                {this.props.personne.classe == "Beneficiaire" &&						 
                    <>
                        <h3>Commentaire <InfobulleRGPD /></h3> 
                        <form>
                            <div className="form-group">
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.personne.commentaire || ""} onChange={(e) => this.changeInformationPersonne("commentaire", e.target.value)} onBlur={(e) => this.blurInformationPersonne("commentaire")}></textarea>
                            </div>  
                        </form>
                    </>
                }
                {this.props.personne.classe == "Beneficiaire" &&
                    <>
                        <br />
                        {/*<h3>Conditions Générales d’Utilisation, Politique de confidentialité, Mentions légales<br /><a className="btn btn-default" target="_blank" href={process.env.APP_MEDIA_URL + (structure && structure.domaine === 'entreprise' ? "Wesireport Entreprise - Convention utilisateur.pdf" : "Wesireport - Convention utilisateur.pdf")}>Télécharger un modèle vierge</a> | <a className="btn btn-default" target="_blank" href={process.env.APP_MEDIA_URL + "Wesireport - Convention utilisateur - FALC.pdf"}>Télécharger un modèle FALC vierge</a></h3>*/}
                        <h3>Conditions Générales d’Utilisation, Politique de confidentialité, Mentions légales<br /><a className="btn btn-default" target="_blank" href={process.env.APP_MEDIA_URL + "Wesireport - Convention utilisateur.pdf"}>Télécharger un modèle vierge</a> | <a className="btn btn-default" target="_blank" href={process.env.APP_MEDIA_URL + "Wesireport - Convention utilisateur - FALC.pdf"}>Télécharger un modèle FALC vierge</a></h3>
                        {/*
                        {this.props.personne.formulaireConsentement && !this.state.changeFormulaireConsentement &&
                            <>
                                <button className="btn btn-secondary" onClick={() => this.ouvreFormulaireConsentement()}><i className="mdi mdi-download"></i> Télécharger le formulaire de consentement</button>
                                <button className="btn btn-default" onClick={() => this.setState({changeFormulaireConsentement: true})}>Uploader un nouveau fichier</button>
                            </>
                        }                        
                        {(!this.props.personne.formulaireConsentement || this.state.changeFormulaireConsentement) && 
                            <>
                                <form>
                                    <div className="col-md-8 p-0">
                                        <div className="form-group">
                                            <input type="file" accept=".pdf,application/pdf" id="formulaire-consentement" onChange={(e) => this.uploadFichier(e)} />                                            
                                        </div>
                                    </div>
                                    {this.state.changeFormulaireConsentement &&
                                        <div className="col-md-4 p-0">                                            
                                            <button className="btn btn-default" onClick={() => this.setState({changeFormulaireConsentement: false})}>Annuler</button>                                          
                                        </div>
                                    }     
                                </form> 
                            </>
                        }
                        */}
                    </>
                }
                {modals}
                {this.props.personne.classe == "Beneficiaire" &&
                    <>
                        <div id="modal-informations-nouveauproche" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">Nouvelle personne</h5>
                                        <div className="header-buttons">
                                            <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formProcheValide()} onClick={() => this.ajouteProche()}>Enregistrer</button>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <FormProche
                                            beneficiaire={this.props.personne}
                                            proche={this.state.editingProche}
                                            modifie={(proche) => this.setEditingProche(proche)}
                                            famille={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="modal-togglefiltre" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">Changement du filtre</h5>                                        
                                    </div>
                                    <div className="modal-body">
                                        <p>
                                            Vous êtes sur le point de changer le filtre de <b>{this.props.personne.prenom} {this.props.personne.nom}</b> : {this.props.personne.filtres.length > 0 ? this.getNomsFiltres(this.props.personne.filtres.join(', ')) : 'Pas de filtre'} --&gt; {this.state.personne.filtres.length > 0 ? this.getNomsFiltres(this.state.personne.filtres.join(', ')) : 'Pas de filtre'}.
                                            <br />
                                            Ce changement peut entrainer la <b>réinitialisation des questions</b> qui lui sont posées.
                                        </p>
                                    </div>
                                    <div className="modal-footer">                                        
                                        <button type="button" className="btn btn-annexe contour" data-dismiss="modal" onClick={() => {
                                            this.state.personne.filtres = this.props.personne.filtres
                                            this.setState({
                                                personne: this.state.personne
                                            })
                                        }}>Annuler</button>
                                        <button type="button" className="btn btn-primary" onClick={() => this.blurInformationPersonne('filtres')} data-dismiss="modal">Continuer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
	}
}
  

export default TabInformations;