import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import DataService from '../services/data';

import logo from '../assets/images/logo.png';

const mapStateToProps = (state, props) => {	
	return {
	  utilisateur: state.data.me,
	}
  }

class MentionsInformationsConseiller extends Component {


	componentDidMount() {
		console.log("mentionsinformationsconseiller did mount");

		document.getElementById('page-login').classList.add('cgu');
	}

	render() {

		if (((new URLSearchParams(window.location.search)).has('la-poste') || this.props.utilisateur && this.props.utilisateur.structure && this.props.utilisateur.structure.id == 12)) {
			return (
				<div className="card">
					<div className="card-body">
						<h3 className="text-center m-0">
							<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
						</h3>
						<div className="p-3">
							<h3 className="text-muted font-18 m-b-5 text-center">Mentions d'informations - Accompagnant</h3>
							<p className="mt-3 p-3 text-justify">
								Les informations recueillies sur ce formulaire sont traitées par Wesireport contact@wesireport.com pour <b>permettre la création de votre compte accompagnant</b>. La base légale du traitement est l’intérêt légitime de La Poste dans le cadre de la mise à disposition et du fonctionnement de l’outil Wesireport.<br /><br />
								Les données collectées ne seront accessibles qu’à la personne désignée comme « supérieur » et aux personnes qui effectuent l’administration du site.							
							</p>
							<p className="mt-3 p-3 text-justify">
								Les données sont conservées pendant <b>la durée du contrat entre Wesireport et La Poste puis jusqu’à la fin de l’année civile en cas de résiliation du contrat</b>.<br /><br />
								Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données.<br /><br />								
								Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter <b>Wesireport contact@wesireport.com</b>.<br /><br />
								Si vous souhaitez avoir plus d’informations sur le traitement de vos données personnelles par la Poste vous pouvez écrire à : Madame la Déléguée à la Protection des Données du Groupe La Poste, CP C703 - 9 rue du Colonel Pierre Avia, 75015 PARIS.<br /><br />
								Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.
							</p>						
						</div>
					</div>
				</div>
			)
		}

		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						<h3 className="text-muted font-18 m-b-5 text-center">Mentions d'informations - Accompagnant</h3>
						<p className="mt-3 p-3 text-justify">
							Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Wesireport contact@wesireport.com pour <b>permettre la création de votre compte accompagnant</b>. La base légale du traitement est l’intérêt légitime de votre structure dans le cadre de la mise à disposition et du fonctionnement de l’outil Wesireport.<br /><br />
							Les données collectées ne seront communiquées à personne et ne seront accessibles qu’à la personne désignée comme « responsable » et aux personnes qui effectuent l’administration du site.							
						</p>
						<p className="mt-3 p-3 text-justify">
							Les données sont conservées pendant <b>la durée du contrat entre wesireport et votre structure puis jusqu’à la fin de l’année civile en cas de résiliation du contrat</b>.<br /><br />
							Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données.<br /><br />
							Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter <b>Wesireport contact@wesireport.com</b>.<br /><br />
							Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.
						</p>						
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(MentionsInformationsConseiller));