import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DatePicker from "react-datepicker";
import moment from 'moment'

import CalendarService from '../../services/calendar';
import DataService from '../../services/data';

import FormQuestion from '../Forms/FormQuestion';
import FormRepetitionQuestions from '../Forms/FormRepetitionQuestions';

import Api from '../../api'
import { getPreparationSynchronisationProfil, getQuestionsDefaut, postSynchronisationProfil } from '../../api/axios';
import { getTerme } from '../../services/contextualisation';
import SecurityService from '../../services/security';
import { questionValide } from '../../services/validation';


const mapStateToProps = (state, props) => {
    return {
        data: state.data
    }
}

const SortableQuestion = SortableElement(({ value, items, questionsSelectionnees, personne, toggleQuestion, setEditingQuestion }) => {
    let question = value;

    let calendarService = new CalendarService();

    let joursSemaine = [];
    for (let i = 1; i <= 7; i++) {
        joursSemaine.push(
            <span key={"btn-jourSemaine-" + (i % 7)} className={"jour" + (question.repetition.type == "jour" || question.repetition.joursSemaine.includes((i % 7)) ? " actif" : "")}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
        );
    }

    let joursReglages = [];
    for (let i = 1; i <= 7; i++) {
        joursReglages.push(
            <span key={"btn-jourReglages-" + (i % 7)} className={"jour" + (question.repetition.type == "reglages" && personne.declenchementQuestionsAutomatiques.jours.includes((i % 7)) ? " actif" : "")}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
        );
    }

    let joursMois = [];
    question.repetition.mois.joursAbsolus.sort((a, b) => (a < b) ? -1 : 1).map((i) => {
        joursMois.push(
            <span key={"btn-jourMois-" + i} className={"jour" + (question.repetition.mois.joursAbsolus.includes(i) ? " actif" : "")}>{i}</span>
        );
    });

    let jourAn = "Envoyée tous les " + parseInt(question.repetition.jourAn.jour) + " " + calendarService.mois()[parseInt(question.repetition.jourAn.mois) - 1].nom;

    let fin = new Date(question.repetition.fin.date);

    return (
        <tr key={"row-question-" + question.id} className={(questionsSelectionnees.includes(question.id) ? "table-warning" : "") + " " + (question.termine ? "obsolete" : "")}>
            <td>
                <div className="checkbox-wrapper">
                    <input type="checkbox" id={"toggle-question-" + question.id} onChange={() => toggleQuestion(question)} checked={questionsSelectionnees.includes(question.id)} />
                    <label htmlFor={"toggle-question-" + question.id} className="toggle"></label>
                </div>
            </td>
            <td className="libelle-question">
                <p className="m-0 table-nom text-muted font-14">{question.libelleCalcule}</p>
                {question.reponses.filter(reponse => reponse.questionDeclenchee != null).length > 0 &&
                    <p className="ml-2">
                        {question.reponses.map((reponse, indexReponse) => {
                            if (reponse.action != 'question') return null
                            if (reponse.questionDeclenchee == null) return null
                            return (
                                <React.Fragment key={"reponse-" + indexReponse}>
                                    <small>{question.type === 'gradient' ? (indexReponse + 1) : reponse.libelle} --&gt; {reponse.questionDeclenchee.libelleCalcule}</small><br />
                                </React.Fragment>
                            )
                        })}
                    </p>
                }
            </td>
            <td>
                <p className="m-0 text-muted font-14">
                    {!question.termine && ["jour", "semaine"].includes(question.repetition.type) && <>{joursSemaine}</>}
                    {!question.termine && ["semaine-paire", "semaine-impaire"].includes(question.repetition.type) && <>Semaines {question.repetition.type == "semaine-impaire" ? "im" : ""}paires<br />{joursSemaine}</>}
                    {!question.termine && ["mois"].includes(question.repetition.type) && 
                        <>
                            {question.repetition.mois.type === 'absolu' &&
                                <span className="mr-2">{joursMois}</span>    
                            }
                            {question.repetition.mois.type === 'relatif' &&
                                <>{{
                                    first: 'Premier',
                                    second: 'Deuxième',
                                    third: 'Troisième',
                                    fourth: 'Quatrième',
                                    last: 'Dernier'
                                }[question.repetition.mois.joursRelatifs.relatif]} {moment().days(question.repetition.mois.joursRelatifs.jour).format('dddd')}</>
                            }
                            <> tous les{question.repetition.mois.frequence > 1 ? <> {question.repetition.mois.frequence}</> : <></>} mois</>
                        </>
                    }
                    {!question.termine && ["an"].includes(question.repetition.type) && <>{jourAn}</>}
                    {!question.termine && ["reglages"].includes(question.repetition.type) && <>{joursReglages}</>}
                    {question.termine && question.repetition.type != "none" && question.repetition.fin.type == "date" && <>N'est plus envoyée depuis le {fin.getDate()} {calendarService.mois()[fin.getMonth()].nomCourt} {fin.getFullYear()}</>}
                    {question.termine && question.repetition.type != "none" && question.repetition.fin.type == "occurrences" && <>N'est plus envoyée ({question.repetition.fin.occurrences} occurrences)</>}
                    {question.repetition.type == "none" && <>Non envoyée</>}
                    {question.repetition.type == "aleatoire" && "Déclenchée aléatoirement"}
                </p>
            </td>
            <td>
                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Modifier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-modifquestion-" + question.id} onClick={() => setEditingQuestion(question)}><i className="dripicons-document-edit"></i></a></span>
                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Copier"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-copiequestion-" + question.id}><i className="mdi mdi-content-duplicate"></i></a></span>
                <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionquestion-" + question.id}><i className="dripicons-trash"></i></a></span>
            </td>
            <td>
                {items.length > 1 && <div className="deplacer"><i className="dripicons-move"></i></div>}
            </td>
        </tr>
    );
});

const SortableQuestions = SortableContainer(({ items, questionsSelectionnees, profil, toggleQuestion, setEditingQuestion }) => {
    return (
        <tbody id="tbody-questions">
            {items.map((value, index) => (
                <SortableQuestion
                    key={`item-${value.id}`}
                    sortIndex={value.numero}
                    index={value.numero}
                    value={value}
                    questionsSelectionnees={questionsSelectionnees}
                    toggleQuestion={toggleQuestion}
                    setEditingQuestion={setEditingQuestion}
                    items={items}
                    profil={profil}
                />
            ))}
            {items.length == 0 &&
                <tr key="row-question-0">
                    <td></td>
                    <td><p className="m-0 text-muted font-14">Aucune question.</p></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            }
            <tr>
                <td colSpan="5" className="tdaction">
                    <a href="#" data-toggle="modal" data-target="#modal-nouvellequestion" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Créer une nouvelle question / information</a>
                </td>
            </tr>
        </tbody>
    );
});

class TabQuestionsProfil extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profil: JSON.parse(JSON.stringify(this.props.profil)),
            autresProfils: [],
            editingQuestion: null,
            editingQuestionDeclenchee: false,
            questionsSelectionnees: [],
            autresProfils: [],
            editingRepetition: null,
            nouvelleBibliotheque: null,
            questionsDefaut: [],
            questionsDefautSelectionnees: [],
            filtreQuestionsDefaut: {
                motscles: '',
                communication: ''
            },
            preparationSynchronisationEnCours: false,
            synchronisationEnCours: false,
            synchronisation: [],
            strategieSynchronisation: 'global',
            strategieGlobaleSynchronisation: {
                questionsAjoutees: 'laisser',
                questionsModifiees: 'retablir',
                questionsAbsentes: 'ajouter'
            },
            strategieCasparcasPersonne: null,
            etatSynchronisation: 'init'
        }
    }

    componentDidMount() {
        console.log("TabQuestionsProfil did mount");

        this.bindModals();

        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip("dispose");
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").tooltip({
            trigger: 'hover'
        });
        window.jQuery(".tab-pane#questions [data-toggle='tooltip']").click(function () {
            window.jQuery(this).tooltip("hide");
        });

        console.log(this.props.personne)

        this.getQuestionsDefaut()
    }

    componentDidUpdate(prevProps) {
        console.log("TabQuestions did update");

        if (JSON.stringify(this.props.profil) != JSON.stringify(prevProps.profil)) {
            this.setState({
                profil: JSON.parse(JSON.stringify(this.props.profil)),
            });
            this.getQuestionsDefaut()
        }

        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-nouvellequestion").off('hidden.bs.modal');
        window.jQuery("#modal-nouvellequestion").on('hidden.bs.modal', () => {
            this.setState({
                editingQuestion: null
            });
        });
        this.props.profil.bibliotheque.questions.map((question) => {
            window.jQuery("#modal-modifquestion-" + question.id).off('hidden.bs.modal');
            window.jQuery("#modal-modifquestion-" + question.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingQuestion: null
                });
            });
        })
        window.jQuery("#modal-banquequestions").off('hidden.bs.modal');
        window.jQuery("#modal-banquequestions").on('hidden.bs.modal', () => {
            this.setState({
                questionsDefautSelectionnees: [],
                filtreQuestionsDefaut: {
                    motscles: '',
                    communication: ''
                }
            });
        });
        window.jQuery("#modal-synchronisation").off('hidden.bs.modal');
        window.jQuery("#modal-synchronisation").on('hidden.bs.modal', () => {
            this.setState({
                synchronisation: [],
                strategieGlobaleSynchronisation: {
                    questionsAjoutees: 'laisser',
                    questionsModifiees: 'retablir',
                    questionsAbsentes: 'ajouter'
                },
                etatSynchronisation: 'init'
            });
        });
    }

    getQuestionsDefaut() {
        getQuestionsDefaut({
            cibles: [this.props.profil.cible],
            domaines: [this.props.structure.domaine],
            motscles: '',
            categories: [],
            communications: [],
            limit: 200
        })
            .then(res => {
                console.log(res)
                this.setState({
                    questionsDefaut: res.data.questions
                })
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    filtreQuestionDefaut(question) {  
        if (this.state.filtreQuestionsDefaut.motscles.trim() !== '') {
            let mots = this.state.filtreQuestionsDefaut.motscles.replace(/[\.,?!\'\-"]/g, '').split(' ').filter(m => m != '')
            for (let mot of mots) {
                console.log('mot', mot, question.libelle.toLowerCase().indexOf(mot.toLowerCase()))
                if (question.libelle.toLowerCase().indexOf(mot.toLowerCase()) == -1) {
                    return false
                }
            }
        }
        if (this.state.filtreQuestionsDefaut.communication !== '') {
            if (question.communication !== this.state.filtreQuestionsDefaut.communication) {
                return false;
            }
        }
        
        return true
    }

    toggleQuestionDefautSelectionnee(question) {
        if (this.state.questionsDefautSelectionnees.includes(question.id)) {
            this.state.questionsDefautSelectionnees.splice(this.state.questionsDefautSelectionnees.indexOf(question.id), 1)
        }
        else {
            this.state.questionsDefautSelectionnees.push(question.id)
        }
        this.setState({
            questionsDefautSelectionnees: this.state.questionsDefautSelectionnees
        })
    }

    toggleQuestion(question) {
        let questionsSelectionnees = this.state.questionsSelectionnees;
        if (questionsSelectionnees.includes(question.id)) {
            questionsSelectionnees.splice(questionsSelectionnees.indexOf(question.id), 1);
        }
        else {
            questionsSelectionnees.push(question.id);
        }
        this.setState({
            questionsSelectionnees: questionsSelectionnees
        });
    }

    toggleAllQuestions() {

        let questionsSelectionnees = [];
        if (this.state.questionsSelectionnees.length == this.state.profil.bibliotheque.questions.length) {
            questionsSelectionnees = [];
        }
        else {
            this.state.profil.bibliotheque.questions.map((question) => {
                questionsSelectionnees.push(question.id)
            });
        }
        this.setState({
            questionsSelectionnees: questionsSelectionnees
        })
    }

    setEditingQuestion(question) {
        console.log(question);
        this.setState({
            editingQuestion: question
        });
    }

    setEditingRepetition(repetition) {
        this.setState({
            editingRepetition: repetition
        });
    }

    resetReglages() {
        let personne = this.state.personne;
        personne.prefixeMessage = this.props.personne.prefixeMessage;
        personne.suffixeMessage = this.props.personne.suffixeMessage;
        personne.modeMessage = this.props.personne.modeMessage;
        personne.nbNoReponseAlerte = this.props.personne.nbNoReponseAlerte;
        personne.declenchementQuestionsAutomatiques = this.props.personne.declenchementQuestionsAutomatiques;
        personne.declenchementQuestionsAleatoires = this.props.personne.declenchementQuestionsAleatoires;
        this.setState({
            personne: personne
        })
    }

    modifieProfil(champ, val) {
        let profil = this.state.profil
        if (champ == "bibliotheque") {
            profil.bibliotheque = val
            this.enregistreProfil("bibliotheque");
        }
        this.setState({
            profil: this.state.profil
        })
    }

    enregistreProfil(champ) {
        console.log(champ);
        let profil = null;
        if (champ == "bibliotheque") {
            if (this.state.profil.bibliotheque != this.props.profil.bibliotheque) {
                profil = {
                    id: this.props.profil.id,
                    classe: this.props.profil.classe,
                    bibliotheque: this.state.profil.bibliotheque
                }
            }
        }
        if (profil) {
            this.props.enregistre(profil);
        }
    }

    ajouteQuestion() {
        if (this.state.editingQuestion && this.state.editingQuestion.type == 'information') {
            this.state.editingQuestion.reponses = []
        }
        this.state.profil.bibliotheque.questions.push(
            this.state.editingQuestion
        )
        this.modifieProfil("bibliotheque", this.state.profil.bibliotheque);
    }

    ajouteQuestionsDefaut() {
        this.props.ajouteQuestionsDefaut(this.state.questionsDefautSelectionnees, this.props.profil)
    }

    modifieQuestion(index) {
        if (this.state.editingQuestion && this.state.editingQuestion.type == 'information') {
            this.state.editingQuestion.reponses.map((reponse) => {
                reponse.supprime = true
            })
        }
        this.state.profil.bibliotheque.questions[index] = this.state.editingQuestion
        this.modifieProfil("bibliotheque", this.state.profil.bibliotheque);
    }

    supprimeQuestion(index) {
        this.state.profil.bibliotheque.questions[index].supprime = true
        this.modifieProfil("bibliotheque", this.state.profil.bibliotheque);
    }

    supprimeQuestions() {
        this.state.questionsSelectionnees.map((q) => {
            this.state.profil.bibliotheque.questions.map((question, iQuestion) => {
                if (question.id == q) {
                    this.state.profil.bibliotheque.questions[iQuestion].supprime = true;
                }
            })
        })
        this.modifieProfil("bibliotheque", this.state.profil.bibliotheque);

        this.setState({
            questionsSelectionnees: []
        })
    }

    reordonneQuestions(oldIndex, newIndex) {
        if (oldIndex != newIndex) {
            this.state.profil.bibliotheque.questions.map((q, iQuestion) => {
                if (q.numero == oldIndex) {
                    this.state.profil.bibliotheque.questions[iQuestion].numero = (oldIndex > newIndex) ? (newIndex - 1) : (newIndex + 1);
                }
            });
            this.modifieProfil("bibliotheque", this.state.profil.bibliotheque);
        }
    }

    copieQuestion(question) {
        this.props.copieQuestions([question.id], this.state.autresProfils);
        this.setState({
            autresProfils: []
        })
    }

    copieQuestions() {
        this.props.copieQuestions(this.state.questionsSelectionnees, this.state.autresProfils);
        this.setState({
            autresProfils: [],
            questionsSelectionnees: []
        })
    }

    toggleAutresProfils(profil) {
        let autresProfils = this.state.autresProfils;
        if (autresProfils.includes(profil)) {
            autresProfils.splice(autresProfils.indexOf(profil), 1);
        }
        else {
            autresProfils.push(profil);
        }
        this.setState({
            autresProfils: autresProfils
        })
    }

    formValide() {
        /*return (
            this.state.editingQuestion
            && this.state.editingQuestion.libelle != ""
            && (
                this.state.editingQuestion.type == "liste"
                || (
                    this.state.editingQuestion.type == "gradient"
                    && this.state.editingQuestion.parametres.gradient.min
                    && this.state.editingQuestion.parametres.gradient.max
                    && this.state.editingQuestion.parametres.gradient.min < this.state.editingQuestion.parametres.gradient.max
                )
                || this.state.editingQuestion.type == "information"
            )
            && (
                this.state.editingQuestion.type == "information"
                || (
                    this.state.editingQuestion.reponses.length > 0
                    && this.formReponsesValide()
                )
            )
            && (
                this.state.editingQuestion.repetition.fin.type == "none"
                || this.state.editingQuestion.repetition.fin.type == "date" && this.state.editingQuestion.repetition.fin.date
                || this.state.editingQuestion.repetition.fin.type == "occurrences" && this.state.editingQuestion.repetition.fin.occurrences != "" && this.state.editingQuestion.repetition.fin.occurrences > 0
            )
        );*/
        return questionValide(this.state.editingQuestion)
    }

    formReponsesValide() {
        let valide = true;
        if (this.state.editingQuestion && this.state.editingQuestion.type == "liste") {
            this.state.editingQuestion.reponses.map((reponse) => {
                if (
                    reponse.libelle == ""
                ) {
                    valide = false;
                }
            })
        }
        return valide;
    }

    toggleJourDeclenchementQuestionsAutomatiques(jour) {
        let declenchementQuestionsAutomatiques = this.state.personne.declenchementQuestionsAutomatiques;
        if (declenchementQuestionsAutomatiques.jours.includes(jour)) {
            declenchementQuestionsAutomatiques.jours.splice(declenchementQuestionsAutomatiques.jours.indexOf(jour), 1);
        }
        else {
            declenchementQuestionsAutomatiques.jours.push(jour);
        }
        this.modifiePersonne("declenchementQuestionsAutomatiques-jour", declenchementQuestionsAutomatiques);
    }

    toggleJourDeclenchementQuestionsAleatoires(jour) {
        let declenchementQuestionsAleatoires = this.state.personne.declenchementQuestionsAleatoires;
        if (declenchementQuestionsAleatoires.jours.includes(jour)) {
            declenchementQuestionsAleatoires.jours.splice(declenchementQuestionsAleatoires.jours.indexOf(jour), 1);
        }
        else {
            declenchementQuestionsAleatoires.jours.push(jour);
        }
        this.modifiePersonne("declenchementQuestionsAleatoires-jour", declenchementQuestionsAleatoires);
    }

    prepareSynchronisation() {

        this.setState({
            preparationSynchronisationEnCours: true
        })

        getPreparationSynchronisationProfil(this.props.profil)
            .then(res => {
                console.log(res)
                let synchronisation = res.data
                synchronisation.map(personne => {
                    personne.synchronisation = false
                    personne.questionsAjoutees.map(question => {
                        question.action = "laisser"
                    })
                    personne.questionsModifiees.map(question => {
                        question.action = "retablir"
                    })
                    personne.questionsAbsentes.map(question => {
                        question.action = "ajouter"
                    })
                })
                this.setState({
                    preparationSynchronisationEnCours: false,
                    strategieCasparcasPersonne: synchronisation.filter(p => p.questionsAjoutees.length + p.questionsModifiees.length +  p.questionsAbsentes.length > 0).length > 0 ? synchronisation.indexOf(synchronisation.filter(p => p.questionsAjoutees.length + p.questionsModifiees.length +  p.questionsAbsentes.length > 0)[0]) : null,
                    synchronisation
                })
                $("#modal-synchronisation").modal('show')
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    preparationSynchronisationEnCours: false
                })
            })
    }

    synchronise() {
        this.setState({
            etatSynchronisation: 'encours'
        })
        if (this.state.strategieSynchronisation === 'global') {
            this.state.synchronisation.map(personne => {
                personne.synchronisation = true
            })
        }
        postSynchronisationProfil(this.props.profil, this.state.synchronisation)
            .then(res => {
                console.log(res)
                this.setState({
                    etatSynchronisation: 'termine',
                    synchronisation: [],
                })
            })
            .catch(err => {
                console.log(err.response)
                this.setState({
                    etatSynchronisation: 'init'
                })
            })
    }

    render() {

        let calendarService = new CalendarService();
        let dataService = new DataService();
        let securityService = new SecurityService();

        let modals = [];

        let questionsDeclenchees = [];
        this.props.profil.bibliotheque.questions.map((question, index) => {
            if (question.repetition.type == "none") {
                questionsDeclenchees.push(question);
            }
        });

        let autresProfils = [];        

        let cibles = {
            beneficiaire: 'Utilisateur',
            'proche-superieur': 'Supérieur hiérarchique'
        }

        this.props.structure.filtres
            .filter(f => {
                if (!securityService.isAdmin()) {
                    if (!securityService.isResponsableStructure(this.props.structure)) {
                        if (!this.props.conseiller.filtres.contains(f)) {
                            return false
                        }
                    }
                }
                return true
            })
            .map((f) => {
                f.profils.map(p => {
                    autresProfils.push(
                        <React.Fragment key={"checkbox-copie-question-" + p.id}>
                            <div className="checkbox-wrapper mr-2">
                                <input type="checkbox" id={"checkbox-copie-question-" + p.id} checked={this.state.autresProfils.includes(p.id)} onChange={() => this.toggleAutresProfils(p.id)} />
                                <label htmlFor={"checkbox-copie-question-" + p.id} className="checkbox-remember-me"></label>
                            </div>
                            <label htmlFor={"checkbox-copie-question-" + p.id}>{f.nom} | {getTerme(cibles[p.cible], this.props.structure.domaine)}</label>
                            <br />
                        </React.Fragment>
                    );
                })
                f.filtres
                    .filter(sf => {
                        if (!securityService.isAdmin()) {
                            if (!securityService.isResponsableStructure(this.props.structure)) {
                                if (!this.props.conseiller.filtres.contains(sf)) {
                                    return false
                                }
                            }
                        }
                        return true
                    })
                    .map(sf => {
                        sf.profils.map(p => {
                            autresProfils.push(
                                <React.Fragment key={"checkbox-copie-question-" + p.id}>
                                    <div className="checkbox-wrapper mr-2">
                                        <input type="checkbox" id={"checkbox-copie-question-" + p.id} checked={this.state.autresProfils.includes(p.id)} onChange={() => this.toggleAutresProfils(p.id)} />
                                        <label htmlFor={"checkbox-copie-question-" + p.id} className="checkbox-remember-me"></label>
                                    </div>
                                    <label htmlFor={"checkbox-copie-question-" + p.id}>{f.nom} - {sf.nom} | {getTerme(cibles[p.cible], this.props.structure.domaine)}</label>
                                    <br />
                                </React.Fragment>
                            );
                        })
                    })
            });

        this.props.profil.bibliotheque.questions.map((question, index) => {
            modals.push(
                <div key={"modal-modifquestion-" + question.id} id={"modal-modifquestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Question</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    {this.state.editingQuestionDeclenchee === false && <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.modifieQuestion(index)} disabled={!this.formValide()}>Enregistrer</button>}
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormQuestion
                                    question={this.state.editingQuestion}
                                    personne={{
                                        prefixeMessage: '',
                                        suffixeMessage: '',
                                        classe: this.props.profil.cible === 'beneficiaire' ? 'Beneficiaire' : 'Proche'
                                    }}
                                    questionsDeclenchees={questionsDeclenchees}
                                    modifie={(question) => this.setEditingQuestion(question)}
                                    numero={(this.state.profil.bibliotheque.questions.length + 1) * 2}
                                    setEditingQuestionDeclenchee={(editingQuestionDeclenchee) => this.setState({editingQuestionDeclenchee})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
            modals.push(
                <div key={"modal-suppressionquestion-" + question.id} id={"modal-suppressionquestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Supprimer une question</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>La question <b>{question.libelleCalcule}</b> sera désactivée et supprimée. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestion(index)} data-dismiss="modal">Supprimer la question</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            modals.push(
                <div key={"modal-copiequestion-" + question.id} id={"modal-copiequestion-" + question.id} className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Copier une question</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>Copier la question <b>{question.libelle}</b> vers :</p>
                                {autresProfils}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" disabled={this.state.autresProfils.length == 0} data-dismiss="modal" onClick={() => this.copieQuestion(question)}>Copier la question</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        let optionsNbNoReponseAlerte = [];
        for (let i = 1; i <= 20; i++) {
            optionsNbNoReponseAlerte.push(
                <option key={"option-nbNoReponseAlerte-" + i} value={i}>{i}</option>
            );
        }

        let optionsHeuresDeclenchement = [];
        for (let i = 7; i <= 21; i++) {
            let heure = ("0" + i).substr(-2);
            optionsHeuresDeclenchement.push(
                <option key={"option-heuresDeclenchement-" + i} value={heure}>{heure}</option>
            )
        }

        let optionsMinutesDeclenchement = [];
        for (let i = 0; i < 60; i += 15) {
            let minute = ("0" + i).substr(-2);
            optionsMinutesDeclenchement.push(
                <option key={"option-heuresDeclenchement-" + i} value={minute}>{minute}</option>
            )
        }

        console.log(this.props.profil)

        let beneficiairesSynchronisations = dataService.getStructureBeneficiaires(this.props.structure).filter(b => b.filtres.includes(this.props.filtre.id))

        return (
            <>
                <br clear="both" />
                <h3>
                    {dataService.getFiltreParent(this.props.filtre) && <>{dataService.getFiltreParent(this.props.filtre).nom + " - "}</>}{this.props.filtre.nom} | {getTerme({
                        beneficiaire: 'Utilisateur',
                        'proche-superieur': 'Supérieur'
                    }[this.props.profil.cible], this.props.structure.domaine)}
                </h3>
                <br clear="both" />
                {/*<a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-changebibliotheque"><i className={"mdi " + (this.getBibliotheque(this.state.personne.bibliotheque) ? "mdi-autorenew" : "mdi-plus")}></i> {this.getBibliotheque(this.state.personne.bibliotheque) ? "Changer de bibliothèque" : "Ajouter une bibliothèque"}</a>*/}
                <a href="#" className="btn btn-annexe contour m-b-20 float-right" data-toggle="modal" data-target="#modal-banquequestions"><i className="mdi mdi-playlist-plus"></i> Banque de questions</a>
                <br clear="both" />
                <div className="table-responsive">
                    <table className="table table-vertical" id="ordre">
                        <thead>
                            <tr>
                                <th>
                                    {this.state.profil.bibliotheque.questions.length > 0 &&
                                        <div className={"checkbox-wrapper" + (this.state.questionsSelectionnees.length != this.state.profil.bibliotheque.questions.length ? " selection" : "")}>
                                            <input type="checkbox" id="toggle-questions-all" checked={this.state.questionsSelectionnees.length > 0} onChange={() => this.toggleAllQuestions()} />
                                            <label htmlFor="toggle-questions-all" className="toggle"></label>
                                        </div>
                                    }
                                </th>
                                {this.state.questionsSelectionnees.length == 0 &&
                                    <>
                                        <th>Libellé</th>
                                        <th>Déclenchement</th>
                                        <th>Actions</th>
                                        <th></th>
                                    </>
                                }
                                {this.state.questionsSelectionnees.length > 0 &&
                                    <th colSpan="4">
                                        <a href="#" data-toggle="modal" data-target="#modal-copiequestions" className="btn btn-annexe contour m-b-20"><i className="mdi mdi-content-duplicate"></i> Copier vers un autre profil</a>
                                        <a href="#" data-toggle="modal" data-target="#modal-suppressionquestions" className="btn btn-annexe contour m-b-20"><i className="dripicons-trash"></i> Supprimer</a>
                                    </th>
                                }
                            </tr>
                        </thead>
                        <SortableQuestions
                            shouldCancelStart={(e) => {
                                return !e.target.classList.contains("dripicons-move");
                            }}
                            onSortEnd={({ oldIndex, newIndex, collection, isKeySorting }, e) => {
                                this.reordonneQuestions(oldIndex, newIndex);
                            }}
                            helperContainer={() => {
                                return document.getElementById("tbody-questions");
                            }}
                            updateBeforeSortStart={() => {
                                console.log("updatebeforesart");
                            }}
                            items={this.state.profil.bibliotheque.questions.filter(q => q.autreType == null).sort((a, b) => (a.numero < b.numero) ? -1 : 1)}
                            questionsSelectionnees={this.state.questionsSelectionnees}
                            toggleQuestion={(question) => this.toggleQuestion(question)}
                            setEditingQuestion={(question) => this.setEditingQuestion(question)}
                            profil={this.props.profil}
                        />
                    </table>
                    {beneficiairesSynchronisations.length > 0 &&
                        <div className="alert alert-warning" role="alert">
                            <i className="mdi mdi-alert-outline"></i> Les modifications et questions ajoutées à la bibliothèque ne s'appliqueront aux {getTerme(this.props.profil.cible === 'beneficiaire' ? 'utilisateurs' : 'supérieurs hiérarchiques', this.props.structure.domaine)} qu'après synchronisation.
                        </div>
                    }
                    <button className='btn btn-primary btn-block' onClick={() => this.prepareSynchronisation()} disabled={this.state.preparationSynchronisationEnCours}>
                        {this.state.preparationSynchronisationEnCours ?
                            <>
                                Veuillez patienter...
                            </>
                            :
                            <>
                                <>Synchroniser avec les {getTerme(this.props.profil.cible === 'beneficiaire' ? 'utilisateurs' : 'supérieurs hiérarchiques', this.props.structure.domaine)} éligibles</>
                                {/*{beneficiairesSynchronisations.length == 0 &&
                                    <>Aucun {getTerme(this.props.profil.cible === 'beneficiaire' ? 'utilisateur' : 'supérieur hiérarchique', this.props.structure.domaine)} à synchroniser</>
                                }
                                {beneficiairesSynchronisations.length > 0 &&
                                    <>Synchroniser avec les {getTerme(this.props.profil.cible === 'beneficiaire' ? 'utilisateurs' : 'supérieurs hiérarchiques', this.props.structure.domaine)} éligibles</>
                                }*/}
                            </>
                        }
                    </button>
                </div>
                <div id="modal-nouvellequestion" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Nouvelle {this.state.editingQuestion && this.state.editingQuestion.type == 'information' ? 'information' : 'question'}</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    {this.state.editingQuestionDeclenchee === false && <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.ajouteQuestion()} disabled={!this.formValide()}>Enregistrer</button>}
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormQuestion
                                    question={this.state.editingQuestion}
                                    personne={{
                                        prefixeMessage: '',
                                        suffixeMessage: '',
                                        classe: this.props.profil.cible === 'beneficiaire' ? 'Beneficiaire' : 'Proche'
                                    }}
                                    questionsDeclenchees={questionsDeclenchees}
                                    modifie={(question) => this.setEditingQuestion(question)}
                                    numero={this.state.profil.bibliotheque.questions.length + 1 * 2}
                                    setEditingQuestionDeclenchee={(editingQuestionDeclenchee) => this.setState({editingQuestionDeclenchee})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-copiequestions" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Copier des questions</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>Copier les questions sélectionnées vers :</p>
                                {autresProfils}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" disabled={this.state.autresProfils.length == 0} data-dismiss="modal" onClick={() => this.copieQuestions()}>Copier les questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-suppressionquestions" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Supprimer des questions</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <p>Les questions sélectionnées seront désactivées et supprimées. Les précédentes réponses restent <b>enregistrées et prises en compte</b>. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeQuestions()} data-dismiss="modal">Supprimer les questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div id="modal-repetitionquestions" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Modifier la récurrence</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal">Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormRepetitionQuestions
                                    repetition={this.state.editingRepetition}
                                    modifie={(repetition) => this.setEditingRepetition(repetition)}
                                />
                            </div>
                        </div>
                    </div>
                                </div>*/}
                <div id="modal-banquequestions" className="modal fade bs-example-modal-center bs-example-modal-xl" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content" style={{ minHeight: '80vh' }}>
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Banque de questions</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-8'>
                                        <form>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <input type="text" className="form-control" placeholder="Filtrer les questions..." value={this.state.filtreQuestionsDefaut.motscles} onChange={(e) => this.setState({ filtreQuestionsDefaut: {...this.state.filtreQuestionsDefaut, motscles: e.target.value }})} />
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <select id="filtre-questionsdefaut-communication" className="form-control ml-0" style={{padding: "0px imporant!", width: '100%'}} value={this.state.filtreQuestionsDefaut.communication} onChange={(e) => {this.state.filtreQuestionsDefaut.communication = e.target.value; this.setState({filtreQuestionDefaut: this.state.filtreQuestionDefaut})}}>
                                                            <option value=''>Tutoiement ou vouvoiement</option>
                                                            <option value='tutoiement'>Tutoiement</option>
                                                            <option value='vouvoiement'>Vouvoiement</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div id="accordion">
                                            {this.props.data.categoriesQuestions.filter(categorie => this.state.questionsDefaut.filter(question => question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question)).length > 0).length == 0 &&
                                                <>
                                                    Aucune question.
                                                </>
                                            }
                                            {this.props.data.categoriesQuestions
                                                .filter(categorie => this.state.questionsDefaut.filter(question => question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question)).length > 0)
                                                .map((categorie, iCategorie) => {
                                                    return (
                                                        <React.Fragment key={"categorie-" + iCategorie}>
                                                            <div className="card shadow-none border mb-1">
                                                                <div className="card-header p-3" id={"heading-categorie" + categorie.id}>
                                                                    <h5 className="m-0 font-14" style={{ marginBottom: 0 }}>
                                                                        <a href={"#collapse-categorie" + categorie.id} className="text-dark" data-toggle="collapse" aria-expanded="false" aria-controls={"collapse-categorie" + categorie.id}>
                                                                            {categorie.nom} <small>({this.state.questionsDefaut.filter(question => question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question)).length} question{this.state.questionsDefaut.filter(question => question.categories.includes(categorie.id) && this.filtreQuestionDefaut(question)).length > 1 ? 's' : ''})</small>
                                                                        </a>
                                                                    </h5>
                                                                </div>
                                                                <div id={"collapse-categorie" + categorie.id} className="collapse" aria-labelledby={"heading-categorie" + categorie.id} data-parent="#accordion">
                                                                    <div className="card-body">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-vertical" id="ordre">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th>Libellé</th>
                                                                                        {/*<th>Type</th>*/}
                                                                                        <th>Réponses</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.questionsDefaut
                                                                                        .filter(question => question.categories.includes(categorie.id))
                                                                                        .filter(question => this.filtreQuestionDefaut(question))
                                                                                        .map((question, iQuestion) => {
                                                                                            return (
                                                                                                <React.Fragment key={"question-" + iQuestion}>
                                                                                                    <tr className={(this.state.questionsDefautSelectionnees.includes(question.id) ? "table-warning" : "")}>
                                                                                                        <td>
                                                                                                            <div className="checkbox-wrapper">
                                                                                                                <input type="checkbox" id={"toggle-questiondefaut-" + question.id} onChange={() => this.toggleQuestionDefautSelectionnee(question)} checked={this.state.questionsDefautSelectionnees.includes(question.id)} />
                                                                                                                <label htmlFor={"toggle-questiondefaut-" + question.id} className="toggle"></label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>{question.libelle}</td>
                                                                                                        {/*<td>{{liste: 'Liste', information: 'Information', gradient: 'Gradient'}[question.type]} {question.type === 'gradient' &&
                                                                                                    <>de {question.parametres.gradient.min} à {question.parametres.gradient.max}</>
                                                                                                }</td>*/}
                                                                                                        <td>
                                                                                                            {question.type != 'information' &&
                                                                                                                <>
                                                                                                                    {question.reponses.sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, iReponse) => {
                                                                                                                        return (
                                                                                                                            <React.Fragment key={"reponse-" + iReponse}>
                                                                                                                                {question.type === 'liste' ? <>{reponse.libelle}</> : <>{reponse.cle.code}</>}: {reponse.score === 0 ? <>
                                                                                                                                    Ne fait pas avancer le compteur
                                                                                                                                </> : <>
                                                                                                                                    Fait {reponse.score > 0 ? 'avancer' : 'reculer'} de {reponse.score} {Math.abs(reponse.score) > 1 ? 'signaux faibles' : 'signal faible'}
                                                                                                                                </>}
                                                                                                                                {reponse.action === 'question' && reponse.questionDeclenchee &&
                                                                                                                                    <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">{reponse.questionDeclenchee.libelleCalcule}</span></>
                                                                                                                                }
                                                                                                                                {reponse.action === 'question-etre-rappele' &&
                                                                                                                                    <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">Souhaitez-vous être rappelé(e) ?</span></>
                                                                                                                                }
                                                                                                                                <br />
                                                                                                                            </React.Fragment>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </>
                                                                                                            }

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="modal-sidebar">
                                            <h5 className="modal-title mt-0">Sélection</h5>
                                            <div id="accordion-questionsdefaut">
                                                {this.state.questionsDefautSelectionnees.map((q, iQ) => {
                                                    let question = this.state.questionsDefaut.filter(question => question.id === q)
                                                    if (question.length === 0) return null
                                                    question = question[0]
                                                    return (
                                                        <React.Fragment key={"question-" + q}>
                                                            <div className="card shadow-none border mb-1">
                                                                <div className="card-header p-3" id={"heading-questiondefaut" + question.id}>
                                                                    <div className="checkbox-wrapper">
                                                                        <input type="checkbox" id={"toggle-questiondefaut-" + question.id} onChange={() => this.toggleQuestionDefautSelectionnee(question)} checked={this.state.questionsDefautSelectionnees.includes(question.id)} />
                                                                        <label htmlFor={"toggle-questiondefaut-" + question.id} className="toggle"> </label>
                                                                    </div> <a href={"#collapse-questiondefaut" + question.id} data-toggle="collapse" aria-expanded="false" aria-controls={"collapse-questiondefaut" + question.id}>{question.libelle}</a>
                                                                </div>
                                                                <div id={"collapse-questiondefaut" + question.id} className="collapse" aria-labelledby={"heading-questiondefaut" + question.id} data-parent="#accordion-questionsdefaut">
                                                                    <div className="card-body">
                                                                        {/*<p className='p-0'>
                                                                            {{liste: 'Liste', information: 'Information', gradient: 'Gradient'}[question.type]} {question.type === 'gradient' &&
                                                                                <>de {question.parametres.gradient.min} à {question.parametres.gradient.max}</>
                                                                            }
                                                                        </p>*/}
                                                                        <p className='p-0'>
                                                                            {question.type != 'information' &&
                                                                                <>
                                                                                    {question.reponses.sort((a, b) => a.numero < b.numero ? -1 : 1).map((reponse, iReponse) => {
                                                                                        console.log(reponse)
                                                                                        return (
                                                                                            <React.Fragment key={"reponse-" + iReponse}>
                                                                                                {question.type === 'liste' ? <>{reponse.libelle}</> : <>{reponse.cle.code}</>}: {reponse.score === 0 ? <>
                                                                                                    Ne fait pas avancer le compteur
                                                                                                </> : <>
                                                                                                    Fait {reponse.score > 0 ? 'avancer' : 'reculer'} de {reponse.score} {Math.abs(reponse.score) > 1 ? 'signaux faibles' : 'signal faible'}
                                                                                                </>}
                                                                                                {reponse.action === 'question' && reponse.questionDeclenchee &&
                                                                                                    <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">{reponse.questionDeclenchee.libelleCalcule}</span></>
                                                                                                }
                                                                                                {reponse.action === 'question-etre-rappele' &&
                                                                                                    <><br /><span className="pl-3">+ Question déclenchée :</span><br /><span className="pl-3">Souhaitez-vous être rappelé(e) ?</span></>
                                                                                                }
                                                                                                <br />
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.ajouteQuestionsDefaut()} data-dismiss="modal" disabled={this.state.questionsDefautSelectionnees.length === 0}>Ajouter les questions</button>
                                <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-synchronisation" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Synchronisation</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {this.state.etatSynchronisation !== 'termine' ?
                                    <>
                                        {this.state.synchronisation.filter(personne => personne.questionsAbsentes.length + personne.questionsModifiees.length + personne.questionsAjoutees.length > 0).length > 0 ?
                                            <>
                                                <p>
                                                    <b>Certains {getTerme('utilisateurs', this.props.structure.domaine)} ont des particularités</b>. Voir le détail ci-dessous.<br />
                                                    Vous pouvez établir une stratégie globale (commune à tous les {getTerme('utilisateurs', this.props.structure.domaine)}) ou au cas par cas.
                                                </p>
                                                <div id="accordion-synchronisation">
                                                    <div className="card">
                                                        <div className="card-header" id="synchronisation-globale-card-header">
                                                            <h5 className="mb-0">
                                                                <div className="radio d-inline ml-2 mr-4">
                                                                    <label>
                                                                        <input type="radio" checked={this.state.strategieSynchronisation === 'global'} onChange={() => this.setState({ strategieSynchronisation: 'global'})} /> Stratégie globale
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                {/*<button className="btn btn-link" onClick={() => this.setState({strategieSynchronisation: 'global'})}>
                                                                    <div>
                                                                        <div className="checkbox-wrapper">
                                                                            <input id="toggle-synchronisation-globale" type="checkbox" checked={this.state.strategieSynchronisation === 'global'} />
                                                                            <label htmlFor={"toggle-synchronisation-globale"} className="toggle"></label>
                                                                        </div>
                                                                        <label className="pl-1"> Stratégie globale</label>
                                                                    </div>                                                                    
                                                                </button>*/}
                                                            </h5>
                                                        </div>
                                                        <div id="synchronisation-globale-card">
                                                            {this.state.strategieSynchronisation === 'global' && 
                                                                <div className="card-body">
                                                                    <div className='mb-2'><b>Questions personnalisées :</b> questions issues du profil ayant été personnalisées sur le paramétrage des {getTerme('utilisateurs', this.props.structure.domaine)}</div>
                                                                    <div className="radio d-inline ml-2 mr-4">
                                                                        <label>
                                                                            <input type="radio" checked={this.state.strategieGlobaleSynchronisation.questionsModifiees === 'retablir'} onChange={() => { this.state.strategieGlobaleSynchronisation.questionsModifiees = 'retablir'; this.setState({ strategieGlobaleSynchronisation: this.state.strategieGlobaleSynchronisation }) }} /> Ne pas conserver les personnalisations
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio d-inline mr-4">
                                                                        <label>
                                                                            <input type="radio" checked={this.state.strategieGlobaleSynchronisation.questionsModifiees === 'nepasretablir'} onChange={() => { this.state.strategieGlobaleSynchronisation.questionsModifiees = 'nepasretablir'; this.setState({ strategieGlobaleSynchronisation: this.state.strategieGlobaleSynchronisation }) }} /> Conserver les personnalisations
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                    <div className='mb-2'><b>Questions manquantes :</b> questions issues du profil volontairement supprimées sur le paramétrage des {getTerme('utilisateurs', this.props.structure.domaine)}</div>
                                                                    <div className="radio d-inline ml-2 mr-4">
                                                                        <label>
                                                                            <input type="radio" checked={this.state.strategieGlobaleSynchronisation.questionsAbsentes === 'ajouter'} onChange={() => { this.state.strategieGlobaleSynchronisation.questionsAbsentes = 'ajouter'; this.setState({ strategieGlobaleSynchronisation: this.state.strategieGlobaleSynchronisation }) }} /> Ajouter
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio d-inline mr-4">
                                                                        <label>
                                                                            <input type="radio" checked={this.state.strategieGlobaleSynchronisation.questionsAbsentes === 'nepasajouter'} onChange={() => { this.state.strategieGlobaleSynchronisation.questionsAbsentes = 'nepasajouter'; this.setState({ strategieGlobaleSynchronisation: this.state.strategieGlobaleSynchronisation }) }} /> Ne pas ajouter
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                    <div className='mb-2'><b>Questions supplémentaires :</b> questions ajoutées spécifiquement sur le paramétrage des {getTerme('utilisateurs', this.props.structure.domaine)}</div>
                                                                    <div className="radio d-inline ml-2 mr-4">
                                                                        <label>
                                                                            <input type="radio" checked={this.state.strategieGlobaleSynchronisation.questionsAjoutees === 'laisser'} onChange={() => { this.state.strategieGlobaleSynchronisation.questionsAjoutees = 'laisser'; this.setState({ strategieGlobaleSynchronisation: this.state.strategieGlobaleSynchronisation }) }} /> Laisser
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio d-inline mr-4">
                                                                        <label>
                                                                            <input type="radio" checked={this.state.strategieGlobaleSynchronisation.questionsAjoutees === 'supprimer'} onChange={() => { this.state.strategieGlobaleSynchronisation.questionsAjoutees = 'supprimer'; this.setState({ strategieGlobaleSynchronisation: this.state.strategieGlobaleSynchronisation }) }} /> Supprimer
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }                                                            
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-header" id="synchronisation-casparcas-card-header">
                                                            <h5 className="mb-0">
                                                                <div className="radio d-inline ml-2 mr-4">
                                                                    <label>
                                                                        <input type="radio" checked={this.state.strategieSynchronisation === 'casparcas'} onChange={() => this.setState({ strategieSynchronisation: 'casparcas'})} /> Stratégie au cas par cas
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                {/*<button className="btn btn-link" onClick={() => this.setState({strategieSynchronisation: 'global'})}>
                                                                    <div>
                                                                        <div className="checkbox-wrapper">
                                                                            <input id="toggle-synchronisation-casparcas" type="checkbox" checked={this.state.strategieSynchronisation === 'casparcas'} />
                                                                            <label htmlFor={"toggle-synchronisation-casparcas"} className="toggle"></label>
                                                                        </div>
                                                                        <label className="pl-1"> Stratégie au cas par cas</label>
                                                                    </div>                                                                    
                                                                </button>*/}
                                                            </h5>
                                                        </div>
                                                        <div id="synchronisation-casparcas-card" >
                                                            {this.state.strategieSynchronisation === 'casparcas' && 
                                                                <div className="card-body">
                                                                    <div className='row'>
                                                                        <div className='col-5'>
                                                                            {this.state.synchronisation.filter(p => p.questionsAjoutees.length + p.questionsModifiees.length +  p.questionsAbsentes.length > 0).length === 0 ?
                                                                                <>Aucun cas à traiter.</>
                                                                            :
                                                                                <>
                                                                                    <div className='pb-1'>
                                                                                        <div className={"checkbox-wrapper" + (this.state.synchronisation.filter(personne => personne.synchronisation === true).length !== this.state.synchronisation.length ? " selection" : "")}>
                                                                                            <input type="checkbox" id={"toggle-personnes"} checked={this.state.synchronisation.filter(personne => personne.synchronisation === true).length > 0} onChange={() => {
                                                                                                if (this.state.synchronisation.filter(personne => personne.synchronisation === true).length === 0) {
                                                                                                    this.state.synchronisation.map(personne => {
                                                                                                        personne.synchronisation = true
                                                                                                    })  
                                                                                                    this.setState({
                                                                                                        synchronisation: this.state.synchronisation
                                                                                                    })
                                                                                                    return
                                                                                                }
                                                                                                if (this.state.synchronisation.filter(personne => personne.synchronisation === true).length !== this.state.synchronisation.length) {
                                                                                                    this.state.synchronisation.map(personne => {
                                                                                                        personne.synchronisation = true
                                                                                                    })  
                                                                                                    this.setState({
                                                                                                        synchronisation: this.state.synchronisation
                                                                                                    })
                                                                                                    return
                                                                                                }
                                                                                                this.state.synchronisation.map(personne => {
                                                                                                    personne.synchronisation = false
                                                                                                })  
                                                                                                this.setState({
                                                                                                    synchronisation: this.state.synchronisation
                                                                                                })
                                                                                            }} />
                                                                                            <label htmlFor={"toggle-personnes"} className="toggle"></label>
                                                                                        </div>
                                                                                        <label htmlFor={"toggle-personnes"} className="pl-1"><b>Tout sélectionner</b></label>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {this.state.synchronisation.map((personne, indexPersonne) => {
                                                                                if (personne.questionsAjoutees.length + personne.questionsModifiees.length +  personne.questionsAbsentes.length === 0) {
                                                                                    return null
                                                                                }
                                                                                return (
                                                                                    <React.Fragment key={'personne-' + indexPersonne}>
                                                                                        <div className={"blocpersonne mb-2" + (this.state.strategieCasparcasPersonne == indexPersonne ? ' blocpersonne-actif' : '')}>
                                                                                            <div className={this.state.strategieCasparcasPersonne == indexPersonne ? 'text-warning' : ''}>
                                                                                                <div className="checkbox-wrapper">
                                                                                                    <input type="checkbox" id={"toggle-personne-" + personne.id} onChange={() => { this.state.synchronisation[indexPersonne].synchronisation = !this.state.synchronisation[indexPersonne].synchronisation; this.setState({ synchronisation: this.state.synchronisation }) }} checked={personne.synchronisation} />
                                                                                                    <label htmlFor={"toggle-personne-" + personne.id} className="toggle"></label>
                                                                                                </div>
                                                                                                <label className="pl-1" onClick={() => this.setState({strategieCasparcasPersonne: indexPersonne})}><b>{personne.prenom} {personne.nom}</b></label>
                                                                                                {/*<b>{personne.prenom} {personne.nom}</b> */}                                                                                                  
                                                                                            </div>
                                                                                            {personne.classe === 'Proche' && 
                                                                                                <>({personne.beneficiaire.prenom} {personne.beneficiaire.nom})<br /></>
                                                                                            }
                                                                                            {personne.questionsAjoutees.length + personne.questionsModifiees.length + personne.questionsAbsentes.length} question{personne.questionsAjoutees.length + personne.questionsModifiees.length + personne.questionsAbsentes.length > 1 ? 's' : ''} à traiter
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            {this.state.strategieCasparcasPersonne !== null &&
                                                                                <>
                                                                                    <div className='mb-2 text-warning'><b>{this.state.synchronisation[this.state.strategieCasparcasPersonne].prenom} {this.state.synchronisation[this.state.strategieCasparcasPersonne].nom}</b></div>
                                                                                    {this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsModifiees.length > 0 &&
                                                                                        <>
                                                                                            <b>Questions personnalisées :</b>
                                                                                            <ul>
                                                                                                {this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsModifiees.map((questionModifiee, indexQuestionModifiee) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={"questionModifiee-" + indexQuestionModifiee}>
                                                                                                            <li>
                                                                                                                <div className='mb-2'>{questionModifiee.libelle}</div>
                                                                                                                <div className="radio d-inline mr-4">
                                                                                                                    <label>
                                                                                                                        <input type="radio" checked={questionModifiee.action === 'retablir'} onChange={() => { this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsModifiees[indexQuestionModifiee].action = 'retablir'; this.setState({ synchronisation: this.state.synchronisation }) }} /> Ne pas conserver les personnalisations
                                                                                                                        <span className="checkmark"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="radio d-inline mr-4">
                                                                                                                    <label>
                                                                                                                        <input type="radio" checked={questionModifiee.action === 'nepasretablir'} onChange={() => { this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsModifiees[indexQuestionModifiee].action = 'nepasretablir'; this.setState({ synchronisation: this.state.synchronisation }) }} /> Conserver les personnalisations
                                                                                                                        <span className="checkmark"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </>
                                                                                    }
                                                                                    {this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAbsentes.length > 0 &&
                                                                                        <>
                                                                                            <b>Questions manquantes :</b>
                                                                                            <ul>
                                                                                                {this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAbsentes.map((questionAbsente, indexQuestionAbsente) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={"questionAbsence-" + indexQuestionAbsente}>
                                                                                                            <li>
                                                                                                                <div className='mb-2'>{questionAbsente.libelle}</div>
                                                                                                                <div className="radio d-inline mr-4">
                                                                                                                    <label>
                                                                                                                        <input type="radio" checked={questionAbsente.action === 'ajouter'} onChange={() => { this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAbsentes[indexQuestionAbsente].action = 'ajouter'; this.setState({ synchronisation: this.state.synchronisation }) }} /> Ajouter
                                                                                                                        <span className="checkmark"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="radio d-inline mr-4">
                                                                                                                    <label>
                                                                                                                        <input type="radio" checked={questionAbsente.action === 'nepasajouter'} onChange={() => { this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAbsentes[indexQuestionAbsente].action = 'nepasajouter'; this.setState({ synchronisation: this.state.synchronisation }) }} /> Ne pas ajouter
                                                                                                                        <span className="checkmark"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </>
                                                                                    }
                                                                                    {this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAjoutees.length > 0 &&
                                                                                        <>
                                                                                            <b>Questions supplémentaires :</b>
                                                                                            <ul>
                                                                                                {this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAjoutees.map((questionAjoutee, indexQuestionAjoutee) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={"questionAjoutee-" + indexQuestionAjoutee}>
                                                                                                            <li>
                                                                                                                <div className='mb-2'>{questionAjoutee.libelle}</div>
                                                                                                                <div className="radio d-inline mr-4">
                                                                                                                    <label>
                                                                                                                        <input type="radio" checked={questionAjoutee.action === 'laisser'} onChange={() => { this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAjoutees[indexQuestionAjoutee].action = 'laisser'; this.setState({ synchronisation: this.state.synchronisation }) }} /> Laisser
                                                                                                                        <span className="checkmark"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="radio d-inline mr-4">
                                                                                                                    <label>
                                                                                                                        <input type="radio" checked={questionAjoutee.action === 'supprimer'} onChange={() => { this.state.synchronisation[this.state.strategieCasparcasPersonne].questionsAjoutees[indexQuestionAjoutee].action = 'supprimer'; this.setState({ synchronisation: this.state.synchronisation }) }} /> Supprimer
                                                                                                                        <span className="checkmark"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>                                                                                                        
                                            </>
                                            :
                                            <>
                                                <p>Tout est prêt. Vous pouvez confirmer la synchronisation.</p>                                                
                                            </>
                                        }
                                    </>
                                :
                                    <>
                                        <p>
                                            <b>La synchronisation a bien été enregistrée</b>. Les effets seront visibiles dans quelques instants... Il sera nécessaire de rafraîchir la page de votre navigateur.
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="modal-footer">                                
                                {this.state.etatSynchronisation !== 'termine' &&
                                    <button className="btn btn-primary" disabled={this.state.etatSynchronisation !== 'init' || (this.state.strategieSynchronisation === 'casparcas' && this.state.synchronisation.filter(personne => personne.synchronisation === true).length === 0)} onClick={() => {
                                        if (this.state.strategieSynchronisation === 'global') {
                                            this.state.synchronisation.map(personne => {
                                                personne.questionsAjoutees.map(question => {
                                                    question.action = this.state.strategieGlobaleSynchronisation.questionsAjoutees
                                                })
                                                personne.questionsModifiees.map(question => {
                                                    question.action = this.state.strategieGlobaleSynchronisation.questionsModifiees
                                                })
                                                personne.questionsAbsentes.map(question => {
                                                    question.action = this.state.strategieGlobaleSynchronisation.questionsAbsentes
                                                })
                                            })
                                        }                                        
                                        this.synchronise()
                                    }}>
                                        {this.state.etatSynchronisation === 'encours' &&
                                            <>Veuillez patienter...</>
                                        }
                                        {this.state.etatSynchronisation !== 'encours' &&
                                            <>Synchroniser</>
                                        }                                    
                                    </button>
                                }                                
                                <button className="btn btn-annexe contour" data-dismiss="modal">{this.state.etatSynchronisation === 'termine' ? 'Fermer' : 'Annuler'}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {modals}
            </>
        )
	}
}

export default connect(mapStateToProps, null)(TabQuestionsProfil);