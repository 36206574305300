import React, { Component } from 'react';

import CalendarService from '../../services/calendar';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class FormRepetitionQuestions extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.repetitionTypeSelect = "jour";
    }

    initFromProps() {
        let repetition = this.props.repetition ? JSON.parse(JSON.stringify(this.props.repetition)) : {     
            type: "none",
            joursSemaine: [1],
            joursMois: [1],
            jourAn: {
                jour: "01",
                mois: "01"
            },
            heure: "12",
            minute: "00",
            fin: {
                type: "none",
                date: null,
                dateWidget: new Date(),
                occurrences: 10
            }
        };

        repetition.fin.dateWidget = (repetition.fin.date) ? new Date(repetition.fin.date) : null;

        let repetitionTypeSelect = "jour";
        if (["jour", "semaine", "semaine-paire", "semaine-impaire", "mois", "an"].includes(repetition.type)) {
            repetitionTypeSelect = repetition.type;
        }

        return {
            repetition: repetition,
            repetitionTypeSelect: repetitionTypeSelect
        };
    }

    componentDidMount() {
		console.log("formrepetitionquestions did mount");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.repetition != this.props.repetition) {
            this.setState(this.initFromProps());
        }
    }
    
    modifieRepetition(champ, val) {
        let repetition = this.state.repetition;
        let repetitionTypeSelect = this.state.repetitionTypeSelect;
        if (champ == "repetition-type") {
            repetition.type = val;
            if (["jour", "semaine", "semaine-paire", "semaine-impaire", "mois"].includes(val)) {
                repetitionTypeSelect = val;
            }
        }
        if (champ == "repetition-joursSemaine") {
            repetition.joursSemaine = val;
        }
        if (champ == "repetition-joursMois") {
            repetition.joursMois = val;
        }
        if (champ == "repetition-jourAn-jour") {
            repetition.jourAn.jour = val;
        }
        if (champ == "repetition-jourAn-mois") {
            repetition.jourAn.mois = val;
        }
        if (champ == "repetition-heure") {
            repetition.heure = val;
        }
        if (champ == "repetition-minute") {
            repetition.minute = val;
        }
        if (champ == "repetition-fin-type") {
            repetition.fin.type = val;
        }
        if (champ == "repetition-fin-date") {
            repetition.fin.type = "date";
            repetition.fin.dateWidget = val;
            let calendarService = new CalendarService();
            repetition.fin.date = calendarService.formatteDate(repetition.fin.dateWidget);

        }
        if (champ == "repetition-fin-occurrences") {
            repetition.fin.type = "occurrences";
            repetition.fin.occurrences = val;
        }
        this.setState({
            repetition: repetition,
            repetitionTypeSelect: repetitionTypeSelect
        });
        if (this.props.modifie) {
            this.props.modifie(this.state.repetition);
        }
    }

    toggleJourSemaine(jour) {
        if (["semaine", "semaine-paire", "semaine-impaire"].includes(this.state.repetition.type)) {
            let joursSemaine = this.state.repetition.joursSemaine;
            if (joursSemaine.includes(jour)) {
                joursSemaine.splice(joursSemaine.indexOf(jour), 1);
            }
            else {
                joursSemaine.push(jour);
            }
            this.modifieRepetition("repetition-joursSemaine", joursSemaine);
        }    
    }

    toggleJourMois(jour) {
        let joursMois = this.state.repetition.joursMois;
        if (joursMois.includes(jour)) {
            joursMois.splice(joursMois.indexOf(jour), 1);
        }
        else {
            joursMois.push(jour);
        }
        this.modifieRepetition("repetition-joursMois", joursMois);
    }

	render() {

        let calendarService = new CalendarService();

        let joursSemaine = [];
        for (let i = 1 ; i <= 7 ; i++) {
            joursSemaine.push(
                <span key={"btn-jourSemaine-" + (i % 7)} className={"jour" + (this.state.repetition.type == "jour" || this.state.repetition.joursSemaine.includes((i % 7)) ? " actif" : "")} onClick={() => this.toggleJourSemaine((i % 7))}>{calendarService.jours()[(i % 7)].charAt(0).toUpperCase()}</span>
            );
        }

        let joursMois = [];
        for (let i = 1 ; i <= 31 ; i++) {
            joursMois.push(
                <span key={"btn-jourMois-" + i} className={"jour" + (this.state.repetition.joursMois.includes(i) ? " actif" : "")} onClick={() => this.toggleJourMois(i)}>{i}</span>
            );
        }

        let joursAn = [];
        for (let i = 1 ; i <= 31 ; i++) {
            let val = ("0" + i).substr(-2);
            joursAn.push(
                <option key={"option-jourAn-" + i} value={val}>{i}</option>
            );
        }

        let moisAn = [];
        for (let i = 0 ; i < 12 ; i++) {
            let mois = calendarService.mois()[i];
            moisAn.push(
                <option key={"option-moisAn-" + i} value={mois.chiffres}>{mois.nom}</option>
            );
        }

        let heures = [];
        for (let i = 7 ; i < 22 ; i++) {
            heures.push(
                <option key={"option-heure-" + i} value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>
            );
        }

        let minutes = [];
        for (let i = 0 ; i < 60 ; i+=5) {
            minutes.push(
                <option key={"option-minute-" + i} value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>
            );
        }

        return ( 
            <div className="row">
                <div className="col-md-8">
                    <form>
                       
                    </form>
                </div>
                <div className="col-md-4">
                    <div className="modal-sidebar">
                        <h5 className="modal-title mt-0">Récurrence</h5>
                        <h6 className="">Répéter</h6>
                        <div className="form-group">
                            <div className="radio">
                                <label>
                                    <input type="radio" checked={this.state.repetition.type == "none"} onChange={(e) => this.modifieRepetition("repetition-type", "none")} /> Jamais
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="radio">
                                <label>
                                    <input type="radio" checked={this.state.repetition.type == "aleatoire"} onChange={(e) => this.modifieRepetition("repetition-type", "aleatoire")} /> Envoyée aléatoirement
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="radio">
                                <label>
                                    <input type="radio" checked={["jour", "semaine", "semaine-paire", "semaine-impaire", "mois", "an"].includes(this.state.repetition.type)} onChange={() => this.modifieRepetition("repetition-type", this.state.repetitionTypeSelect)} /> Tou(te)s les :
                                    <span className="checkmark"></span>
                                </label>
                                <select className="form-control" value={this.state.repetitionTypeSelect} onChange={(e) => this.modifieRepetition("repetition-type", e.target.value)}>
                                    <option key="option-repetition-jour" value="jour">jours</option>
                                    <option key="option-repetition-semaine" value="semaine">semaines</option>
                                    <option key="option-repetition-semaine-paire" value="semaine-paire">semaines</option>
                                    <option key="option-repetition-semaine-impaire" value="semaine-impaire">semaines</option>
                                    <option key="option-repetition-mois" value="mois">mois</option>
                                    <option key="option-repetition-an" value="an">ans</option>
                                </select>
                            </div>
                        </div>   
                        {["jour", "semaine", "semaine-paire", "semaine-impaire", "mois", "an"].includes(this.state.repetition.type) && 
                            <>
                                <h6 className="jours">Répéter le</h6>
                                {["jour", "semaine", "semaine-paire", "semaine-impaire"].includes(this.state.repetition.type) &&
                                    <>
                                        <p className="modal-jours m-0 text-muted font-14">
                                            {joursSemaine}
                                        </p>
                                        <br clear="both" />
                                    </>
                                }
                                {this.state.repetition.type == "mois" &&
                                    <>
                                        <p className="modal-jours m-0 text-muted font-14">
                                            {joursMois}
                                        </p>
                                        <br clear="both" />
                                    </>
                                }
                                {this.state.repetition.type == "an" &&
                                    <>
                                        <p className="modal-jours m-0 text-muted font-14">
                                            <select className="form-control" value={this.state.repetition.jourAn.jour} onChange={(e) => this.modifieRepetition("repetition-jourAn-jour", e.target.value)}>
                                                {joursAn}
                                            </select>
                                            <select className="form-control" value={this.state.repetition.jourAn.mois} onChange={(e) => this.modifieRepetition("repetition-jourAn-mois", e.target.value)}>
                                                {moisAn}
                                            </select>
                                        </p>
                                        <br clear="both" />
                                    </>
                                }
                                <h6 className="">Répéter à : 
                                    <select className="form-control" value={this.state.repetition.heure} onChange={(e) => this.modifieRepetition("repetition-heure", e.target.value)}>
                                        {heures}
                                    </select>
                                    h
                                    <select className="form-control" value={this.state.repetition.minute} onChange={(e) => this.modifieRepetition("repetition-minute", e.target.value)}>
                                        {minutes}
                                    </select>
                                </h6>
                                <h6 className="">Se termine</h6>
                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.repetition.fin.type == "none"} onChange={() => this.modifieRepetition("repetition-fin-type", "none")} /> Jamais
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.repetition.fin.type == "date"} onChange={() => this.modifieRepetition("repetition-fin-type", "date")} /> <span className="le">Le :</span>
                                            <span className="checkmark"></span>
                                            <div className="input-group date">
                                                <DatePicker
                                                    className="form-control" 
                                                    locale="fr"
                                                    selected={this.state.repetition.fin.dateWidget}
                                                    onChange={(date) => this.modifieRepetition("repetition-fin-date", date)}
                                                    dateFormat="dd/MM/yyyy"
                                                />    
                                                <div className="input-group-append">
                                                    <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" checked={this.state.repetition.fin.type == "occurrences"} onChange={() => this.modifieRepetition("repetition-fin-type", "occurrences")} /> Après
                                            <input type="text" className="form-control simple" id="inlineFormInputGroupUsername" value={this.state.repetition.fin.occurrences} onChange={(e) => this.modifieRepetition("repetition-fin-occurrences", e.target.value)} /> occurrences
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <br clear="both" /> 
                            </>
                        }                                                      
                    </div>
                </div>
            </div>
        );
	}
}

export default FormRepetitionQuestions;