import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import FormProche from '../Forms/FormProche';

import DataService from '../../services/data';
import { getTerme } from '../../services/contextualisation';

class TabAnnuaire extends Component {

    constructor(props) {
        super(props);

        this.state = {
            beneficiaire: JSON.parse(JSON.stringify(this.props.beneficiaire)),
            editingProche: null,
            tags: ["famille", "emploi"],
            prochesSelectionnes: []
        }
    }

    componentDidMount() {
        console.log("TabProches did mount");

        this.bindModals();
    }

    componentDidUpdate() {
        console.log("TabProches did update");

        this.bindModals();
    }

    bindModals() {
        window.jQuery("#modal-nouveauproche").off('hidden.bs.modal');
        window.jQuery("#modal-nouveauproche").on('hidden.bs.modal', () => {
            console.log("dismiss");
            this.setState({
                editingProche: null
            });
        });
        this.props.beneficiaire.proches.map((proche) => {
            window.jQuery("#modal-modifproche-" + proche.id).off('hidden.bs.modal');
            window.jQuery("#modal-modifproche-" + proche.id).on('hidden.bs.modal', () => {
                this.setState({
                    editingProche: null
                });
            });
        });
    }

    setEditingProche(proche) {
        this.setState({
            editingProche: proche
        });
    }

    formProcheValide() {
        return (
			this.state.editingProche
			&& this.state.editingProche.nom != ""
            && this.state.editingProche.prenom != ""
            && /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.editingProche.email)
            && (this.state.editingProche.telephone == "" || /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.editingProche.telephone))
        );
    }

    toggleTag(tag) {
        let tags = this.state.tags;
        if (tags.includes(tag)) {
            tags.splice(tags.indexOf(tag), 1);
        }
        else {
            tags.push(tag);
        }
        this.setState({
            tags: tags
        });
        this.updateProchesSelectionnes();
    }

    toggleProche(proche) {
        let prochesSelectionnes = this.state.prochesSelectionnes;
        if (prochesSelectionnes.includes(proche.id)) {
            prochesSelectionnes.splice(prochesSelectionnes.indexOf(proche.id), 1);
        }
        else {
            prochesSelectionnes.push(proche.id);
        }
        this.setState({
            prochesSelectionnes: prochesSelectionnes
        });
    }

    toggleAllProches() {
        let prochesSelectionnes = [];
        if (this.state.prochesSelectionnes.length == this.props.beneficiaire.proches.filter((proche) => this.state.tags.includes(proche.tag)).length) {
            prochesSelectionnes = [];
        }
        else {
            this.props.beneficiaire.proches.filter((proche) => this.state.tags.includes(proche.tag)).map((proche) => {
                prochesSelectionnes.push(proche.id)
            });
        }
        this.setState({
            prochesSelectionnes: prochesSelectionnes
        })
    }

    updateProchesSelectionnes() {
        let prochesSelectionnes = this.state.prochesSelectionnes;
        prochesSelectionnes.map((p, index) => {
            let trouve = false;
            this.props.beneficiaire.proches.filter((proche) => this.state.tags.includes(proche.tag)).map(proche => {
                if (proche.id == p) trouve = true;
            });
            if (!trouve) {
                prochesSelectionnes.splice(index, 1);
            }
        });
        this.setState({
            prochesSelectionnes: prochesSelectionnes
        })
    }

    changeProche(p, champ, val) {
		let proche = null;
		if (champ == "actif") {
			proche = {
				id: p.id,
				classe: p.classe,
				actif: val
			}
		}
		if (champ == "supprime") {
			proche = {
				id: p.id,
				classe: p.classe,
				supprime: val
			}
		}
		if (proche) {
			this.props.enregistreProche(proche);
		}
    }

    ajouteProche(proche) {
        let beneficiaire = { 
            id: this.props.beneficiaire.id,
            classe: this.props.beneficiaire.classe
        }
        console.log(proche)
        if (proche.emploi) {
            let emplois = this.props.beneficiaire.emplois;
            emplois.map((emploi, index) => {
                console.log({...emploi})
                if (emploi.id == proche.emploi) {
                    emplois[index].proches.push(proche);
                }
                console.log({...emplois[index]})
            });
            beneficiaire.emplois = emplois;
        }
        else {
            let proches = this.props.beneficiaire.proches;
            proches.push(proche);
            beneficiaire.proches = proches;            
        }
        this.props.enregistre(beneficiaire);
    }

    supprimeProches() {
        let dataService = new DataService();
        this.state.prochesSelectionnes.map(p => {
            let proche = dataService.getProcheByBeneficiaireAndId(this.props.beneficiaire, p);
            if (proche) {
                this.changeProche(proche, "supprime", true);
            }
        });
        this.setState({
            prochesSelectionnes: []
        })
    }

    toggleActivationProches() {
        let dataService = new DataService();
        this.state.prochesSelectionnes.map(p => {
            console.log(p);
            let proche = dataService.getProcheByBeneficiaireAndId(this.props.beneficiaire, p);
            console.log(proche);
            if (proche) {
                console.log("change");
                this.changeProche(proche, "actif", !proche.actif);
            }
        });
        this.setState({
            prochesSelectionnes: []
        })
    }

    prochesSelectionnesActifs() {
        let dataService = new DataService();
        if (this.state.prochesSelectionnes.length == 0) return null;
        
        let premierProche = dataService.getProcheByBeneficiaireAndId(this.props.beneficiaire, this.state.prochesSelectionnes[0]);
        if (!premierProche) return null;
        
        for (let i = 1 ; i < this.state.prochesSelectionnes.length ; i++) {
            let proche = dataService.getProcheByBeneficiaireAndId(this.props.beneficiaire, this.state.prochesSelectionnes[i]);
            if (premierProche.actif != proche.actif) {
                return null;
            }
        }
        return premierProche.actif;
    }

    getDescription(d) {
        let dataService = new DataService()
        let structure = dataService.getStructureById(this.props.beneficiaire.structure)


        if (d == "parent") return "Parent";
        if (d == "conjoint") return "Conjoint";
        if (d == "ami") return "Ami";
        if (d == "famille") return "Autre membre de la famille";
        if (d == "collegue") return "Collègue";
        if (d == "superieur") return getTerme("Supérieur", structure.domaine);
        if (d == "rh") return "RH";
        if (d == "emploi") return "Autre personne lié à l'emploi";
        return "Autre";
    }

	render() {

        let modals = [];

        let tags = [
			//<a key="btn-tag-famille" href="#" onClick={() => this.toggleTag("famille")} className={"btn btn-filtre m-t-30 bg-secondary " + (this.state.tags.includes('famille') ? "actif" : "")}>Famille {this.state.tags.includes('famille') && <i className="mdi mdi-close"></i>}</a>,
			<a key="btn-tag-emploi" href="#" onClick={() => this.toggleTag("emploi")} className={"btn btn-filtre m-t-30 " + (this.state.tags.includes('emploi') ? "actif" : "")}>Emploi {this.state.tags.includes('emploi') && <i className="mdi mdi-close"></i>}</a>,
        ]
        
        let proches = [];
        this.props.beneficiaire.proches.filter((proche) => this.state.tags.includes(proche.tag)).sort((a, b) => {
			return (a.actif != b.actif) ? (a.actif ? -1 : 1) : 0;
		}).map((proche) => {
            console.log(proche)
			proches.push(                
                <tr key={"row-proche-" + proche.id} className={(this.state.prochesSelectionnes.includes(proche.id) ? "table-warning" : "") + (proche.actif ? "" : " obsolete")}>
                    <td>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id={"toggle-proche-" + proche.id} onChange={() => this.toggleProche(proche)} checked={this.state.prochesSelectionnes.includes(proche.id)} />
                            <label htmlFor={"toggle-proche-" + proche.id} className="toggle"></label>
                        </div>
                    </td>
                    <td>
                        <NavLink to={"/utilisateurs/" + this.props.beneficiaire.id + "/proches/" + proche.id}><p className="m-0 table-nom font-14">{proche.prenom} {proche.nom}</p></NavLink>
                    </td>
                    <td>
                        <p className="m-0 text-muted font-14">{this.getDescription(proche.description)}</p>
                    </td>
                    <td><span className={((proche.tag == "famille") ? "bg-secondary" : "bg-primary") + " badge badge-pill"}>{(proche.tag == "famille") ? "Famille" : "Emploi"}</span></td>
                    {/*<td>
                        <p className="m-0 text-muted font-14"><i className="mdi mdi-checkbox-blank-circle text-success"></i> {proche.actif ? "Actif" : "Inactif"}</p>
                    </td>*/}
                    <td>
                        {proche.description == "superieur" && proche.actif && 
                            <p className="m-0 text-muted font-14"><i className={proche.initQuestions && !proche.pauseQuestions ? "mdi mdi-play-circle-outline":"mdi mdi-pause-circle-outline"}></i> {proche.initQuestions && !proche.pauseQuestions ? "Actives" : "En pause"}</p>
                        }
                        {(proche.description != "superieur" || !proche.actif) && 
                            <p className="m-0 text-muted font-14">Non concerné</p>
                        }
                    </td>
                    <td>
                        {proche.description == "superieur" &&
                            <>
                                <p className="m-0 text-muted font-14">{proche.etatConsentement ? proche.etatConsentement == "accepte" ? "Accepté" : "Refusé" : proche.dateDemandeConsentement ? "En attente" : "Non demandé"}</p>
                            </>
                        }
                        {proche.description != "superieur" &&
                            <>
                                <p className="m-0 text-muted font-14">Non concerné</p>
                            </>
                        }                        
                    </td>
                    <td>
                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={proche.actif ? "Désactiver" : "Réactiver"}><a href="#" data-toggle="modal" data-target={"#modal-toggleactivationannuaireproche-" + proche.id} className="m-0 btn btn-annexe contour"><i className={proche.actif ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i></a></span>
                        <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><a href="#" data-toggle="modal" data-target={"#modal-suppressionannuaireproche-" + proche.id} className="m-0 btn btn-annexe contour"><i className="dripicons-trash"></i></a></span>
                    </td>
                </tr>
            );
            modals.push(
                <div key={"modal-toggleactivationannuaireproche-" + proche.id} id={"modal-toggleactivationannuaireproche-" + proche.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{proche.actif ? "Désactiver" : "Réactiver" } la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{proche.actif && <p>Les questions envoyées à <b>{proche.prenom} {proche.nom}</b> concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront désactivés. Elles pourront être rétablies ultérieurement. Voulez-vous continuer ?</p>}
								{!proche.actif && <p>Les questions envoyées à <b>{proche.prenom} {proche.nom}</b> concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront réactivées. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.changeProche(proche, "actif", !proche.actif)} data-dismiss="modal">{proche.actif ? "Désactiver" : "Réactiver" } la personne</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
            );
            modals.push(
                <div key={"modal-suppressionannuaireproche-" + proche.id} id={"modal-suppressionannuaireproche-" + proche.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer le compte de la personne</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>L'ensemble des services de suivi et informations liés à <b>{proche.prenom} {proche.nom}</b> concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.changeProche(proche, "supprime", true)} data-dismiss="modal">Supprimer le compte de la personne</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>	
            );
        });
        
        let aucunProche = null;
        if (proches.length == 0) {
			aucunProche =
				<tr key="row-periode-0">
					<td></td>
					<td>
						<p className="m-0 text-muted font-14">Aucune personne.</p>
					</td>
					<td></td>
                    <td></td>
                    <td></td>
                    <td></td>
				</tr>
			;
		}

        return (
            <div className={"tab-pane p-3" + (this.props.actif ? " active" : "")} id="annuaire" role="tabpanel">
                {this.props.beneficiaire.proches.length > 0 && tags}
                <a href="" data-toggle="modal" data-target="#modal-nouveauproche" className="mb-3 btn btn-primary float-right" onClick={() => this.setEditingProche(null)}><i className="mdi mdi-plus"></i> Nouvelle personne</a>
                <br clear="both" />
                <div className="table-responsive">
                    <table className="table table-vertical">
                        <thead>
                            <tr>
                                <th>
                                    {proches.length > 0 &&
                                        <div className={"checkbox-wrapper" + (this.state.prochesSelectionnes.length != proches.length ? " selection" : "")}>
                                            <input type="checkbox" id="toggle-proches-all" checked={this.state.prochesSelectionnes.length > 0} onChange={() => this.toggleAllProches()} />
                                            <label htmlFor="toggle-proches-all" className="toggle"></label>
                                        </div>
                                    }
                                </th>
                                {this.state.prochesSelectionnes.length == 0 &&
                                    <>
                                        <th>Nom</th>
                                        <th>Relation</th>
                                        <th>Cercle</th>
                                        <th>Questions auto.</th>
                                        <th>Consentement</th>
                                        <th>Actions</th>
                                    </>
                                }
                                {this.state.prochesSelectionnes.length > 0 &&
                                    <th colSpan="5">
                                        {/*<a href="#" className="btn btn-annexe contour"><i className="mdi mdi-content-duplicate"></i> Dupliquer</a>*/}
                                        {this.prochesSelectionnesActifs() !== null && <a href="#" data-toggle="modal" data-target="#modal-toggleactivationproches" className="btn btn-annexe contour"><i className={this.prochesSelectionnesActifs() ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i> {this.prochesSelectionnesActifs() ? "Désactiver" : "Réactiver"}</a>}
                                        <a href="#" data-toggle="modal" data-target="#modal-suppressionproches" className="btn btn-annexe contour"><i className="dripicons-trash"></i> Supprimer</a>
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {proches}
                            {aucunProche}
                            <tr>
                                <td colSpan="7" className="tdaction">
                                    <a href="" data-toggle="modal" data-target="#modal-nouveauproche" className="m-0 btn btn-annexe contour action"><i className="mdi mdi-plus"></i> Ajouter une nouvelle personne</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="modal-nouveauproche" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Nouvelle personne</h5>
                                <div className="header-buttons">
                                    <button type="button" className="btn btn-annexe contour" data-dismiss="modal">Quitter sans enregistrer</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.formProcheValide()} onClick={() => this.ajouteProche(this.state.editingProche)}>Enregistrer</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormProche
                                    beneficiaire={this.props.beneficiaire}
                                    proche={this.state.editingProche}
                                    modifie={(proche) => this.setEditingProche(proche)}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
                <div id="modal-suppressionproches" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des comptes de personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>L'ensemble des services de suivi et informations liés aux personnes sélectionnées concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeProches()} data-dismiss="modal">Supprimer les comptes des personnes</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                <div key="modal-toggleactivationproches" id="modal-toggleactivationproches" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.prochesSelectionnesActifs() ? "Désactiver" : "Réactiver" } des personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.prochesSelectionnesActifs() && <p>Les questions envoyées aux personnes sélectionnées concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront désactivés. Elles pourront être rétablies ultérieurement. Voulez-vous continuer ?</p>}
								{!this.prochesSelectionnesActifs() && <p>Les questions envoyées aux personnes sélectionnées concernant <b>{this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</b> seront réactivées. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationProches()} data-dismiss="modal">{this.prochesSelectionnesActifs() ? "Désactiver" : "Réactiver" } les personnes</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
                {modals}
            </div>
        )
	}
}
  

export default TabAnnuaire;