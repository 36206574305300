import React, { Component } from 'react';

import Api from '../../api';
import DataService from '../../services/data';

class FormConseiller extends Component {

    constructor(props) {
        super(props);

        this.state = this.initFromProps();
        this.state.erreurs = [];        
    }

    initFromProps() {

        let dataService = new DataService()

        let conseiller = this.props.conseiller ? JSON.parse(JSON.stringify(this.props.conseiller)) : {     
            id: null,
            classe: "Conseiller",
            nom: "",
            prenom: "",
            email: "",
            telephone: "",
            actif: true,
            seulementAdmin: dataService.getStructureNbConseillersActifs(this.props.structure) >= this.props.structure.nbMaxConseillers,
            filtres: [],
            checkUniqueEmail: null
        };

        return {
            conseiller: conseiller
        };
    }

    componentDidMount() {
        console.log("formconseiller did mount");
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.conseiller != this.props.conseiller) {
            this.setState(this.initFromProps());
            if (!this.props.conseiller) {
                this.setState({erreurs: []});
            }
        }
    }

    modifieConseiller(champ, val) {
        let conseiller = this.state.conseiller;
        if (champ == "nom") {
            conseiller.nom = val;
        }
        if (champ == "prenom") {
            conseiller.prenom = val;
        }
        if (champ == "telephone") {
            conseiller.telephone = val;
        }
        if (champ == "email") {
            conseiller.email = val;
            conseiller.checkUniqueEmail = null;
            if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(val)) {
                this.checkUniqueEmail();
            }            
        }
        if (champ == "seulementAdmin") {
            conseiller.seulementAdmin = !conseiller.seulementAdmin;
        }
        if (champ == "actif") {
            conseiller.actif = val;
        }
        this.setState({
           conseiller: conseiller
        });
        if (this.props.modifie) {
            this.props.modifie(conseiller);
        }
    }

    valideChamp(champ) {
        let erreurs = this.state.erreurs;
        if (erreurs.includes(champ)) {
            erreurs.splice(erreurs.indexOf(champ), 1);
        }
        if (champ == "nom") {
            if (this.state.conseiller.nom == "") {
                erreurs.push(champ);
            }
        }
        if (champ == "prenom") {
            if (this.state.conseiller.nom == "") {
                erreurs.push(champ);
            }
        }
        if (champ == "email") {
            if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.conseiller.email)) {
                erreurs.push(champ);
            }
            else {
                this.checkUniqueEmail();
            }            
        }
        if (champ == "telephone") {
            if (this.state.conseiller.telephone != "" && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.conseiller.telephone)) {
                erreurs.push(champ);
            }
        }
        this.setState({
            erreurs: erreurs
        });
    }

    checkUniqueEmail() {
        let api = new Api();

        let conseiller = this.state.conseiller;

        api.checkUniqueEmail(conseiller.email, conseiller.classe, conseiller.id).then(json => {
            let erreurs = this.state.erreurs;
            if (json.unique && erreurs.includes("email")) {
                erreurs.splice(erreurs.indexOf("email"), 1);
            }
            if (!json.unique && !erreurs.includes("email")) {
                erreurs.push("email");
            }
            conseiller.checkUniqueEmail = json.unique;
            this.setState({
                conseiller: conseiller,
                erreurs: erreurs
            });
            this.props.modifie(conseiller);
        }).catch(err => {
            console.log(err);
        })
    }

    toggleFiltre(id) {
        if (this.state.conseiller.filtres.includes(id)) {
            this.state.conseiller.filtres.splice(this.state.conseiller.filtres.indexOf(id), 1)            
            this.props.structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.conseiller.filtres.includes(sousFiltre.id)) {
                            this.state.conseiller.filtres.splice(this.state.conseiller.filtres.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.conseiller.filtres.push(id)            
            this.props.structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.conseiller.filtres.includes(filtre.id)) {
                            this.state.conseiller.filtres.push(filtre.id)
                        }
                    }
                })
            })
        }
        this.setState({
            conseiller: this.state.conseiller
        })
        if (this.props.modifie) {
            this.props.modifie(this.state.conseiller);
        }
    }

    render() {

        let dataService = new DataService()

        return (
            <div className="row">
                <div className="col-md-12">
                    <form>													
                        <h3>Coordonnées</h3> 
                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("nom") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Nom *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.nom} onChange={(e) => this.modifieConseiller("nom", e.target.value)} onBlur={() => this.valideChamp("nom")} />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                            <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("prenom") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Prénom *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.prenom} onChange={(e) => this.modifieConseiller("prenom", e.target.value)} onBlur={() => this.valideChamp("prenom")} />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                            <div className={"input-group input-obligatoire" + (this.state.erreurs.includes("email") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Adresse email *</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.email} onChange={(e) => this.modifieConseiller("email", e.target.value)} onBlur={() => this.valideChamp("email")} />                                    
                                </div>
                                {this.state.conseiller.checkUniqueEmail === false && <ul className="parsley-errors-list filled" id="parsley-id-15"><li className="parsley-required">Cette adresse email est déjà utilisée.</li></ul>}
                            </div>
                            <div className="form-group col-md-6">
                            <div className={"input-group" + (this.state.erreurs.includes("telephone") ? " input-error": "")}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Téléphone</div>
                                    </div>
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.telephone} onChange={(e) => this.modifieConseiller("telephone", e.target.value)} onBlur={() => this.valideChamp("telephone")} />
                                </div>
                            </div>
                        </div>
                        {this.props.responsable &&
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <input type="checkbox" id={"toggle-nouveauresponsable-seulementAdmin-new"} switch="warning" checked={!this.state.conseiller.seulementAdmin} onChange={(e) => this.modifieConseiller("seulementAdmin", !e.target.value)} disabled={dataService.getStructureNbConseillersActifs(this.props.structure) >= this.props.structure.nbMaxConseillers} />
                                    <label htmlFor={"toggle-nouveauresponsable-seulementAdmin-new"}></label>
                                    Est aussi accompagnant
                                </div>
                            </div>
                        }
                        <br clear="both" />
                        {!this.props.responsable && this.props.structure.filtres.length > 0 &&
                            <>
                                <h3>Filtres</h3> 
                                {this.props.structure.filtres.map((filtre, iFiltre) => {
                                    return (
                                        <React.Fragment key={"filtre-" + iFiltre}>
                                            <div className="checkbox-wrapper mr-2">
                                                <input type="checkbox" id={"toggle-filtre-" + filtre.id} checked={this.state.conseiller.filtres.includes(filtre.id)} onChange={(e) => this.toggleFiltre(filtre.id)} />
                                                <label htmlFor={"toggle-filtre-" + filtre.id} className="toggle"></label>
                                            </div>
                                            <label htmlFor={"toggle-filtre-" + filtre.id} className={this.state.conseiller.filtres.includes(filtre.id) ? "bold" : ""}> {filtre.nom}</label>
                                            <br />
                                            {filtre.filtres.map((sousFiltre, iSousFiltre) => {
                                                return (
                                                    <React.Fragment key={"sousfiltre-" + iSousFiltre}>
                                                        <div className="ml-3">
                                                            <div className={"checkbox-wrapper mr-2 " + (this.state.conseiller.filtres.includes(filtre.id) && filtre.filtres.filter(f => this.state.conseiller.filtres.includes(f.id)).length == 0 ? "bright" : "")}>
                                                                <input type="checkbox" id={"toggle-filtre-" + sousFiltre.id} checked={this.state.conseiller.filtres.includes(sousFiltre.id)} onChange={(e) => this.toggleFiltre(sousFiltre.id)} />
                                                                <label htmlFor={"toggle-filtre-" + sousFiltre.id} className="toggle"></label>
                                                            </div>
                                                            <label htmlFor={"toggle-filtre-" + sousFiltre.id} className={this.state.conseiller.filtres.includes(sousFiltre.id) || (this.state.conseiller.filtres.includes(filtre.id) && filtre.filtres.filter(f => this.state.conseiller.filtres.includes(f.id)).length == 0) ? "bold" : ""}> {sousFiltre.nom}</label>
                                                        </div>                                                
                                                    </React.Fragment>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </>
                        }
                    </form>
                </div>
            </div>
        );
	}
}

export default FormConseiller;
