import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import CalendarService from '../../services/calendar';
import DataService from '../../services/data';
import SecurityService from '../../services/security';

import JaugeBeneficiaire from '../JaugeBeneficiaire';

class CardBeneficiaire extends Component {

    constructor(props) {
        super(props);

        console.log("card ", props);
    }

    componentDidMount() {
        console.log("CardBeneficiaire did mount");
        console.log(this.props.beneficiaire);

        window.jQuery("#dropdown-notifications-card-beneficiaire-" + this.props.beneficiaire.id).on('hidden.bs.dropdown', () => {
            let notifications = [];
            this.props.notifications.filter((notification) => notification.dateLecture == null).map((notification) => {
                notification.lu = true;
                notifications.push({
                    id: notification.id,
                    classe: notification.classe,
                    lu: true
                });
            });
            if (notifications.length > 0) {
                this.props.enregistreNotifications(notifications);
            }
        });
    }
    
	render() {

        let calendarService = new CalendarService();
        let dataService = new DataService();
        let securityService = new SecurityService();

        let structure = dataService.getStructureById(this.props.beneficiaire.structure)

        /*let questionsEnPause = null;
        if (this.props.beneficiaire.pauseQuestions) {
            questionsEnPause = 
                <p className="pause">
                    <i className="mdi mdi-pause-circle-outline"></i> Questions automatiques en pause {this.props.beneficiaire.enConges && <>(congés)</>}
                </p>
            ;
        }*/
        let questionsEnPause = null
        if (this.props.beneficiaire.suiviAvance && !this.props.beneficiaire.pauseQuestions && !this.props.beneficiaire.enConges && this.props.beneficiaire.etatConsentement == 'accepte') {        
            
        }
        else {
            questionsEnPause = 
                <p className="pause">
                    <i className="mdi mdi-pause-circle-outline"></i> {(this.props.beneficiaire.etatConsentement == null && this.props.beneficiaire.dateDemandeConsentement) ? "Consentement en attente" : (this.props.beneficiaire.etatConsentement === 'refuse' ? 'Consentement refusé' : (this.props.beneficiaire.etatConsentement === 'etre-rappele' ? 'Demande à être rappelé' : (this.props.beneficiaire.enConges ? "En congés" : "En pause")))}                    
                </p>
            ;
        }

        let notifications = [];
        this.props.notifications.filter((notification) => notification.dateLecture == null).map((notification) => {
            notifications.push(
                <a href="#" key={"row-notificationnonlue-" + notification.id} className="dropdown-item notify-item active">
                    <p className="notify-details">{notification.titre}<span className="text-muted">{notification.libelle}</span></p>
                    <span className="notif-date">{calendarService.ilya(calendarService.intervalle(new Date(notification.date), new Date()))}</span>
                </a>
            );
        });

        let filtresBeneficiaire = []
        structure.filtres.map(filtre => {
            let actif = this.props.beneficiaire.filtres.includes(filtre.id)
            let sousFiltres = []
            filtre.filtres.map(sousFiltre => {
                if (this.props.beneficiaire.filtres.includes(sousFiltre.id)) {
                    actif = true
                    sousFiltres.push(sousFiltre)
                }
            })								
            if (actif) {
                if (sousFiltres.length == 0) {
                    filtresBeneficiaire.push(filtre.nom)
                }
                else {
                    sousFiltres.map(sousFiltre => {
                        filtresBeneficiaire.push(filtre.nom + " - " + sousFiltre.nom)
                    })
                }
            }
        })

        return (
            <div className="col-lg-6 col-xl-4 widget-home" onClick={() => this.props.ouvreBeneficiaire()}>
                <div className="card widget-user m-b-20">
                    <div className="card-body">
                        {/*<div className="card-checkbox">
                            <div className="checkbox-wrapper">
                                <input type="checkbox" id={"toggle-card-" + this.props.beneficiaire.id} onChange={(e) => this.props.toggle()} />
                                <label htmlFor={"toggle-card-" + this.props.beneficiaire.id} id={"label-card-toggle-" + this.props.beneficiaire.id} className="toggle"></label>
                            </div>
                        </div>*/}
                        <NavLink to={"/utilisateurs/" + this.props.beneficiaire.id}><h4 className="mt-0 header-title mb-3 text-center">{this.props.beneficiaire.suiviAvance && <i className="mdi mdi-wifi"></i>} {this.props.beneficiaire.prenom} {this.props.beneficiaire.nom}</h4></NavLink>
                        {filtresBeneficiaire.length > 0 &&
                            <p className="text-center">
                                {filtresBeneficiaire.map((filtre, iFiltre) => {
                                    return (                                        <React.Fragment key={"filtre-" + iFiltre}>
                                            {iFiltre != 0 && <br />}
                                            {filtre}
                                        </React.Fragment>
                                    )
                                })}						
                            </p>
                        }	
                        <div className="card-menu" onClick={(e) => e.stopPropagation() }>
                            <li className="dropdown notification-list">
                                <a className="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="mdi mdi-dots-vertical"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                    {dataService.getStructureById(this.props.beneficiaire.structure).domaine !== 'entreprise' &&
                                        <>
                                            <button className="dropdown-item" disabled={!(this.props.conseiller && this.props.beneficiaire.conseillerPrincipal != this.props.conseiller.id)} onClick={() => this.props.ouvreModalStoppeSuivi()}>Ne plus suivre</button>
                                            <button className="dropdown-item" disabled={!this.props.peutPartager} onClick={() => this.props.ouvreModalPartage()} href="#">Partager avec un collègue</button>
                                        </>
                                    }                                    
                                    <button className="dropdown-item" onClick={() => this.props.ouvreModalToggleActivation()} disabled={dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviAvance && dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviNormal}>{this.props.beneficiaire.actif ? "Désactiver" : "Réactiver"}</button>
                                    {/*{this.props.beneficiaire.actif && <button className="dropdown-item" onClick={() => this.props.ouvreModalToggleSuivi()} disabled={this.props.beneficiaire.suiviAvance ? dataService.getStructureNbBeneficiairesSuiviNormal(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviNormal : dataService.getStructureNbBeneficiairesSuiviAvance(dataService.getStructureById(this.props.beneficiaire.structure)) >= dataService.getStructureById(this.props.beneficiaire.structure).nbMaxBeneficiairesSuiviAvance}>{this.props.beneficiaire.suiviAvance ? "Passer en suivi sans signaux faibles" : "Passer en suivi avec signaux faibles" }</button>}*/}
                                    {(securityService.isAdmin() || securityService.isResponsableStructure(dataService.getStructureById(this.props.beneficiaire.structure))) && <button className="dropdown-item" onClick={() => this.props.ouvreModalSuppression()} href="#">Supprimer</button>}
                                </div>
                            </li>
                        </div>
                        <div className="widget-user-desc text-center">
                            <div id="sparkline">
                                <JaugeBeneficiaire
                                    beneficiaire={this.props.beneficiaire}
                                    min="0"
                                    max={this.props.beneficiaire.seuilCalcule}
                                    value={this.props.beneficiaire.score}
                                />
                            </div>
                        </div>
                        <div className="text-center signaux">
                            <h6 className="mb-1">{this.props.beneficiaire.score}<span>/{this.props.beneficiaire.seuilCalcule}</span></h6>
                            <p className="text-muted mb-0">Signaux faibles comptabilisés</p>
                            {questionsEnPause}
                            
                        </div>
                        {notifications.length > 0 && 
                            <div className="dropdown card-notifs" id={"dropdown-notifications-card-beneficiaire-" + this.props.beneficiaire.id}>
                                <a className="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="mdi mdi-bell-outline"></i>
                                    <span className="badge badge-pill badge-danger noti-icon-badge">{notifications.length}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                    <div className="slimscroll notification-item-list">
                                        {notifications}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
	}
}
  

export default CardBeneficiaire;