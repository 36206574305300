import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../api';

import logo from '../assets/images/logo.png';

import { NavLink } from 'react-router-dom';

import storageKey from '../services/session';


const mapStateToProps = (state, props) => {
	console.log(props);
	return {
	  data: state.data,
	  ihm: state.ihm,
	  alerteComplexId: props.route.match.params.alerteComplexId,
	  qualification: props.route.match.params.qualification
	}
  }

class HookQualificationAlerte extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hook: null
		}
	}

	componentDidMount() {
		console.log("hookalerte did mount");
		this.hookQualificationAlerte();
	}

	hookQualificationAlerte() {
		let api = new Api();
		api.hookQualificationAlerte(this.props.alerteComplexId, this.props.qualification).then((json) => {
			console.log(json);
			this.setState({
				hook: json
			})
		}).catch(err => {
			console.log(err);
		});
	}

	render() {

		let erreur = "Oups, une erreur est survenue.";
		if (this.state.hook && this.state.hook.erreur) {
			if (this.state.hook.erreur.code == "lien-invalide") {
				erreur = "Oups, ce lien n'est pas valide.";
			}
			if (this.state.hook.erreur.code == "alerte-qualifiee") {
				erreur = "Oups, un retour a déjà été enregistré pour cette alerte.";
			}
		}

		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						{this.state.hook && this.state.hook.succes &&
							<h3 className="text-muted font-18 m-b-5 text-center">
								Merci, votre réponse a bien été prise en compte.<br />
								Vous pouvez fermer cette page.
							</h3>
						}
						{this.state.hook && this.state.hook.erreur &&
							<h3 className="text-muted font-18 m-b-5 text-center">{erreur}</h3>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(HookQualificationAlerte);