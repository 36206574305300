import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import JustGage from 'justgage/justgage';

class JaugeBeneficiaire extends Component {

    componentDidMount() {

        console.log(this.props);

        this.node = ReactDOM.findDOMNode( this );
  
        this.jauge = new JustGage({
            id: "jauge-" + this.props.beneficiaire.id,
            value: parseInt( this.props.value ),
            min: parseInt( this.props.min ),
            max: parseInt( this.props.max ),
            symbol: '/' + this.props.max,
            pointer: true,
            pointerOptions: {
                toplength: -15,
                bottomlength: 10,
                bottomwidth: 12,
                color: '#8e8e93',
                stroke: '#ffffff',
                stroke_width: 3,
                stroke_linecap: 'round'
            },
            gaugeWidthScale: 1.2,
            counter: true,
            hideValue: true
        });
    }
    
    componentWillReceiveProps( nextProps ) {
        if ( nextProps.value !== this.props.value ) {
            this.jauge.refresh( nextProps.value );      
        }
        if ( nextProps.max !== this.props.max ) {
            this.jauge.refresh( this.props.value, nextProps.max );      
        }
    }

    componentWillUnmount() {
      
    }

    render() {
        return (
            <div id={"jauge-" + this.props.beneficiaire.id} />
        );
    }
}

export default JaugeBeneficiaire;