export const getTerme = (terme, domaine) => {
    if (domaine == "entreprise") {
        if (terme == "Accompagnants") {
            return "Référents"
        }
        if (terme == "accompagnants") {
            return "référents"
        }
        if (terme == "Accompagnant") {
            return "Référent"
        }
        if (terme == "accompagnant") {
            return "référent"
        }
        if (terme == "d'accompagnants") {
            return "de référents"
        }
        if (terme == "nouvel accompagnant") {
            return "nouveau référent"
        }
        if (terme == "Nouvel accompagnant") {
            return "Nouveau référent"
        }
        if (terme == "à l'accompagnant") {
            return "au référent"
        } 
        if (terme == "Utilisateurs") {
            return "Salariés"
        }
        if (terme == "Utilisateur") {
            return "Salarié"
        }
        if (terme == "utilisateurs") {
            return "salariés"
        }
        if (terme == "utilisateur") {
            return "salarié"
        }        
        if (terme == "l'utilisateur") {
            return "le salarié"
        }
        if (terme == "d'utilisateurs") {
            return "de salariés"
        }
        if (terme == "nouvel utilisateur") {
            return "nouveau salarié"
        }
        if (terme == "Nouvel utilisateur") {
            return "Nouveau salarié"
        }   
        if (terme == "Supérieur") {
            return "Manager"
        }
        if (terme == "Supérieur hiérarchique") {
            return "Manager"
        }
        if (terme == "supérieurs hiérarchiques") {
            return "managers"
        }
        if (terme == "supérieur hiérarchique") {
            return "manager"
        }        
    }
    return terme
}