import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../assets/images/logo.png';

class Cookies extends Component {


	componentDidMount() {
		console.log("cgu did mount");

		document.getElementById('page-login').classList.add('cgu');
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						<h3 className="text-muted font-18 m-b-5 text-center">Cookies</h3>
						<p className="px-3 text-justify">
							Nous utilisons des cookies dans le cadre de la mise à disposition de notre site internet et de notre application.
						</p>
						<h5 className="p-3">Qu'est-ce qu'un cookie (en informatique) ?</h5>
						<p className="px-3 text-justify">
							Un cookie est un petit fichier stocké par un serveur dans le terminal (ordinateur, téléphone, etc.) d’un utilisateur et associé à un domaine web (c’est à dire dans la majorité des cas à l’ensemble des pages d’un même site web). Ce fichier est automatiquement renvoyé lors de contacts ultérieurs avec le même domaine. Cliquez <a href="https://www.cnil.fr/fr/definition/cookie" target="_blank">ici</a> pour en savoir plus.
						</p>
						<h5 className="p-3">Cookies que nous considérons nécessaires au site pour fonctionner</h5>
						<p className="px-3 text-justify">
							Ces cookies permettent au site de fonctionner de manière optimale. Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre navigateur, cependant votre expérience utilisateur risque d’être dégradée. Ces cookies ne nécessitent pas votre consentement pour être déposés.<br /><br />
							<table border={1}> 
								<thead>
									<tr>
										<th className='p-3'>Nom du cookie</th>
										<th className='p-3'>Finalité</th>
										<th className='p-3'>Durée de conservation</th>
									</tr>
									<tr>
										<td className='p-3'>wesireport_api_token</td>
										<td className='p-3'>Connexion</td>
										<td className='p-3'>Session</td>
									</tr>
								</thead>
							</table>
						</p>						
					</div>
				</div>
			</div>
		);
	}
}

export default Cookies;