import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../assets/images/logo.png';

class PolitiqueConfidentialiteLaposte extends Component {


	componentDidMount() {
		console.log("cgu did mount");

		document.getElementById('page-login').classList.add('cgu');
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					<h3 className="text-center m-0">
						<NavLink to="/" className="logo logo-admin"><img src={logo} height="55" alt="logo" /></NavLink>
					</h3>
					<div className="p-3">
						<h3 className="text-muted font-18 m-b-5 text-center">Politique de confidentialité et de protection des données personnelles</h3>
						<p className="p-3 text-center">
							En vigueur au 12/01/2024
						</p>
						{/*<p className="mt-3 p-3 text-justify">
							Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site app.wesireport.com et de définir les conditions d’accès et d’utilisation.<br /><br />
							Toute validation de profil implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’Accompagnant . Lors de l'inscription sur la plateforme via la validation du mail de création de mot de passe, chaque Accompagnant accepte expressément les présentes CGU en cochant la case précédant le texte suivant : <b>« J'accepte les Conditions Générales d'Utilisation de la plateforme WESIREPORT »</b>.<br /><br />
							En cas de non-acceptation des CGU stipulées dans le présent contrat, l’Accompagnant se doit de renoncer à l'accès aux services proposés par l’application.<br /><br />
							https://app.wesireport.com/ se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
						</p>*/}
						{/*<h5 className="p-3">Article 1 : Les mentions légales</h5>
						<p className="mt-3 p-3 text-justify">
							L'édition du site https://app.wesireport.com/ est assurée par : <br /><br />
							la Société par Action Simplifiée Wesireport au capital de 10000 euros, dont le siège social est situé au 82 Route de Jean Roux 33700 Salles.<br /><br />
							Immatriculation au RCS 841 368 822 RCS Bordeaux.<br /><br />
							Adresse e-mail : contact@wesireport.com.<br /><br />
							Les Directeurs de la publication sont  : Christophe Guérin et Christophe Lécuyer<br /><br />
							L'hébergeur du site https://app.wesireport.com/ est la société SAS OVH, dont le siège social est situé au 2 rue Kellermann BP 80157 59053 Roubaix Cedex 1.
						</p>*/}
						<a href="#1"><h5 className="px-3 py-1">1. Déclaration générale</h5></a>
						<a href="#2"><h5 className="px-3 py-1">2. Qui sommes-nous ?</h5></a>
						<a href="#3"><h5 className="px-3 py-1">3. Quelles sont les données à caractère personnel que nous recueillons et comment sont-elles recueillies ?</h5></a>
						<a href="#3.1"><h6 className="px-3 py-1">3.1 Données que vous fournissez directement</h6></a>
						<a href="#3.2"><h6 className="px-3 py-1">3.2 Données recueillies indirectement</h6></a>
						<a href="#4"><h5 className="px-3 py-1">4. Pourquoi recueillons-nous vos données à caractère personnel et comment les utilisons-nous ?</h5></a>
						<a href="#5"><h5 className="px-3 py-1">5. Combien de temps conservons-nous vos données à caractère personnel ?</h5></a>
						<a href="#6"><h5 className="px-3 py-1">6. Comment divulguons-nous et transférons-nous vos données à caractère personnel ?</h5></a>
						<a href="#7"><h5 className="px-3 py-1">7. Comment protégeons-nous vos données à caractère personnel ?</h5></a>
						<a href="#8"><h5 className="px-3 py-1">8. Quels sont vos droits sur vos données à caractère personnel ?</h5></a>
						<a href="#9"><h5 className="px-3 py-1">9. Comment nous contacter ?</h5></a>
						<a href="#10"><h5 className="px-3 py-1">10. Modifications de notre Politique de confidentialité</h5></a>

						<hr />

						<h5 id="1" className="p-3">1. Déclaration générale</h5>
						<p className="px-3 text-justify">
							Nous prenons votre vie privée très au sérieux et nous nous engageons à la protéger. Par ailleurs, vous devez :<br />
							- Savoir les traitements que nous opérons sur vos données à caractère personnel que nous recueillons et utilisons,<br />
							- Comprendre vos droits en lien avec celles-ci.<br /><br />
							La présente politique de confidentialité (la « Politique de confidentialité ») explique nos politiques et pratiques quant à la manière dont nous recueillons, utilisons et divulguons les données à caractère personnel que nous recueillons.<br /><br />
							Nous vous recommandons de lire attentivement la présente Politique de confidentialité car elle contient des informations importantes sur vos données à caractère personnel. <br /><br />
							N’hésitez pas à nous contacter si vous avez des questions ou des remarques sur notre Politique de confidentialité (voir la rubrique « Comment nous contacter » ci-dessous). 
						</p>
						<h5 id="2" className="p-3">2. Qui sommes-nous ?</h5>
						<p className="px-3 text-justify">
							Les termes « WESIREPORT», « nous », « notre » et « nos » désignent la société WESIREPORT en tant que responsable du traitement de vos données à caractère personnel.<br /><br />
							Vous trouverez nos coordonnées dans la rubrique « Comment nous contacter »  ci-dessous.
						</p>
						<h5 id="3" className="p-3">3. Quelles sont les données à caractère personnel que nous recueillons et comment sont elles recueillies ? </h5>
						<p className="px-3 text-justify">
							Les données à caractère personnel sont des informations relatives à une personne physique identifiée ou identifiable.<br /><br />
							Elles incluent par exemple le nom, le prénom ou l’adresse email.<br /><br />
							Nous pouvons recueillir des données à caractère personnel directement auprès de vous ou indirectement.<br /><br />
							<h6 id="3.1">3.1	Données que vous nous fournissez directement</h6>
							Vous pouvez nous fournir des données : <br />
							- Lorsque vous participez au programme dr prévention et maintien dans l'emploi,<br />
							- Lorsque vous communiquez avec WESIREPORT.<br /><br />
							Selon ce que vous nous fournissez, lesdites informations peuvent inclure : <br />
							- Votre identité (y compris votre prénom, nom de famille),<br />
							- Vos coordonnées (votre e-mail, numéro de téléphone professionnel),<br />
							- D’autres informations que vous pouvez fournir en remplissant des formulaires ou en nous contactant (y compris vos commentaires ou d’autres communications avec nous).<br /><br />
							Nous vous informerons lorsque vos informations sont nécessaires pour traiter votre demande, pour répondre à vos demandes ou pour vous permettre de participer à l’un de nos programmes. Si vous ne donnez pas ces informations, cela peut retarder ou rendre impossible le traitement de votre demande.<br /><br />
							Nous nous efforçons de veiller à ce que les données à caractère personnel que nous détenons soient exactes à tout moment. Nous vous encourageons donc à mettre à jour vos données au cas où des changements se produiraient. Nous pouvons également vous demander de mettre à jour vos données de temps à autre.<br /><br />
							<h6 id="3.2" >3.2 Données recueillies indirectement </h6>
							Pour la création de votre compte, nous pouvons recueillir des données de manière indirecte auprès de votre employeur.<br /><br />
							Nous pouvons recueillir des données lorsque vous utilisez notre site, il s'agit de la date de votre dernière connexion à Wesireport.
						</p>
						<h5 id="4" className="p-3">4. Pourquoi recueillons-nous vos données à caractère personnel et comment les utilisons-nous ? </h5>
						<p className="px-3 text-justify">
							Nous recueillons vos données pour vous permettre d'utiliser Wesireport (finalité de traitement).<br /><br />
							Nous recueillons et utilisons vos données à caractère personnel sur la base légale de traitement qui est l’intérêt légitime de votre structure (Responsable de traitement) dans le cadre de la mise à disposition et du fonctionnement de Wesireport. Nous vous en informons au moment de l’inscription et nous vous demandons votre accord.<br /><br />
							Selon le contexte, nous pouvons utiliser vos données à caractère personnel pour :<br />
							- Créer votre compte et vous envoyer un lien d'activation et de création du mot de passe,<br />
							- Vous envoyer une lettre d’information (avec votre consentement préalable) ou des invitations à l’un de nos événements,<br />
							- Répondre à vos questions, demandes et suggestions,<br />
							- Surveiller et améliorer nos Plateformes numériques,<br />
							- Effectuer des analyses statistiques,<br />
							- Fournir des informations aux organismes de réglementation lorsque la loi l’exige.
						</p>
						<h5 id="5" className="p-3">5. Combien de temps conservons-nous vos données à caractère personnel ? </h5>
						<p className="px-3 text-justify">
							Vos données à caractère personnel sont traitées pendant la période nécessaire pour les finalités pour lesquelles elles ont été recueillies, pour se conformer aux obligations légales et réglementaires et pour la durée de toute période nécessaire à l’établissement, l’exercice ou la défense de droits légaux.<br /><br />
							Afin de déterminer les durées de conservation les plus appropriées pour vos données à caractère personnel, nous avons spécifiquement pris en compte la quantité, la nature et la sensibilité de vos données à caractère personnel, les raisons pour lesquelles nous avons recueilli vos données à caractère personnel, ainsi que les exigences légales applicables.<br /><br />
							Concrètement, les données sont conservées pendant la durée du contrat entre Wesireport et votre structure. Néanmoins dès que votre compte est supprimé de Wesireport, vos données personnelles sont anonymisées instantanément.
						</p>
						<h5 id="6" className="p-3">6. Comment divulguons-nous et transférons-nous vos données à caractère personnel ?</h5>
						<p className="px-3 text-justify">
							Nous ne pouvons divulguer vos données à caractère personnel qu’aux parties désignées dans le cadre des finalités de l’application WESIREPORT : les employés de votre entreprise ayant besoin de savoir par leur fonction ainsi qu’aux administrateurs de la plateforme.<br /><br />
							Aucune donnée n’est traitée en dehors de l’Union Européenne.
						</p>
						<h5 id="7" className="p-3">7. Comment protégeons-nous vos données à caractère personnel ?</h5>
						<p className="px-3 text-justify">
							Toutes vos données à caractère personnel sont strictement confidentielles et ne seront accessibles qu’en cas de nécessité, uniquement par le personnel dûment habilité.<br /><br />
							WESIREPORT a mis en place des mesures de sécurité pour protéger vos données à caractère personnel contre tout accès et utilisation non autorisés. Nous suivons des procédures de sécurité appropriées dans la conservation et la divulgation de vos données à caractère personnel afin d’empêcher l’accès non autorisé par des tiers et d’éviter la perte accidentelle de vos données. Nous limitons l’accès à vos données à caractère personnel aux personnes qui ont réellement besoin d’y accéder pour des raisons professionnelles. Les personnes qui accèdent à vos données sont soumises à un devoir de confidentialité.<br /><br />
							Nous avons également mis en place des procédures pour traiter toute atteinte présumée à la sécurité des données. Nous vous aviserons, ainsi que toute autorité de contrôle compétente, en cas d’atteinte présumée à la sécurité des données lorsque la loi nous impose de le faire.<br /><br />
							Nous exigeons également des personnes à qui nous transmettons vos données à caractère personnel qu’elles respectent ce qui précède. Malheureusement, la transmission d’informations via Internet n’est pas totalement sécurisée. Nous ne pouvons donc pas garantir la sécurité de vos données à caractère personnel que vous nous avez transmises. Une telle transmission se fait à vos propres risques et vous reconnaissez et acceptez que nous déclinons toute responsabilité en cas d’utilisation, de distribution, de dommage ou de destruction non autorisés de vos données, sauf dans la mesure où la loi nous oblige à endosser une telle responsabilité. Une fois que nous aurons reçu vos données à caractère personnel, nous appliquerons les mesures de sécurité susmentionnées.
						</p>
						<h5 id="8" className="p-3">8. Quels sont vos droits sur vos données à caractère personnel ? </h5>
						<p className="px-3 text-justify">
							Conformément au droit applicable à la protection des données, vous pouvez à tout moment demander l’accès, la rectification, l’effacement et la portabilité de vos données à caractère personnel, ou en restreindre le traitement ou vous y opposer. Un résumé de ces droits est énoncé ci-dessous :<br />
							<b>Votre droit d’accès</b> : le droit de recevoir une copie de vos données à caractère personnel. <br />
							<b>Votre droit de rectification</b> : le droit de nous demander de rectifier toute erreur dans vos données ou de les compléter. <br />
							<b>Votre droit à l’oubli</b> : le droit de nous demander de supprimer vos données à caractère personnel, dans certaines situations. <br />
							<b>Votre droit à la limitation du traitement</b> : le droit de nous demander de limiter le traitement de vos données à caractère personnel, dans certaines circonstances, par exemple si vous contestez l’exactitude des données. <br />
							<b>Votre droit à la portabilité des données</b> : le droit de recevoir les données à caractère personnel que vous nous avez fournies, dans un format structuré, couramment utilisé et lisible par machine et/ou de transmettre ces données à un tiers, dans certaines situations. <br />
							<b>Votre droit d’opposition au traitement</b> : le droit de s’opposer à tout moment pendant le traitement de vos données à caractère personnel.<br /><br />
							Vous avez également le droit de déposer une plainte auprès de votre autorité locale de protection des données en cas de violation présumée des règles de protection des données qui vous sont applicables.<br /><br />
							Pour exercer l’un de ces droits, veuillez nous contacter en utilisant les coordonnées ci-dessous (voir « Comment nous contacter »).<br /><br />
							Veuillez noter que si vous exercez l’un des droits mentionnés ci-dessus, il vous sera demandé de nous faire savoir quel droit vous souhaitez exercer et de nous fournir certaines informations. La copie de votre carte d’identité, passeport ou autre pièce d’identité légalement reconnue peut vous être demandé en cas de doute légitime sur l’identité du demandeur à des fins d’identification pour vous protéger contre les demandes frauduleuses émanant de tiers.
						</p>
						<h5 id="9" className="p-3">9. Comment nous contacter ? </h5>
						<p className="px-3 text-justify">
							Si vous souhaitez avoir plus d’informations sur le traitement de vos données personnelles par la Poste vous pouvez écrire à : Madame la Déléguée à la Protection des Données du Groupe La Poste, CP C703 - 9 rue du Colonel Pierre Avia, 75015 PARIS.<br /><br />
							Pour exercer vos droits, pour poser des questions d’ordre général ou pour porter réclamation, veuillez communiquer avec nos équipes : <br />
							- Par mail : rgpd@wesireport.com<br />
							- En utilisant le formulaire de contact sur le site : www.wesireport.com<br /><br />
							Si vous avez des questions ou des préoccupations concernant notre Politique de confidentialité ou le traitement des données, vous pouvez contacter le délégué à la protection des données du groupe à l’adresse suivante : rgpd@wesireport.com							
						</p>
						<h5 id="10" className="p-3">10. Modifications de notre Politique de confidentialité </h5>
						<p className="px-3 text-justify">
							Notre politique de confidentialité reflète nos pratiques actuelles et peut être modifiée et mise à jour de temps à autre. Lorsque nous publions des changements à notre Politique de confidentialité, nous modifions la « Date d’effet » précisée en haut de ce document pour indiquer quand de tels changements sont entrés en vigueur. <br /><br />
							Si nous modifions notre Politique de confidentialité d’une manière substantielle, nous vous en informerons par le biais d’un avis de modification au début de celle-ci et sur la page d’accueil du site Web « www.wesireport.com ».
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default PolitiqueConfidentialiteLaposte;