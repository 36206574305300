import store from '../store';

import DataService from './data';

export default class SecurityService {

    constructor() {
        this.dataService = new DataService();
    }

    canViewConseiller(conseiller) {

        let me = store.getState().data.me;

        if (!me) return false;

        if (me.roles.includes('ROLE_ADMIN')) return true;

        if (this.dataService.getStructureById(conseiller.structure) && this.dataService.getStructureById(conseiller.structure).responsables.includes(me.id)) {
            return true;
        }

        return false;
    }

    canViewBeneficiaire(beneficiaire) {

        let me = store.getState().data.me;

        if (!me) return false;

        if (me.roles.includes('ROLE_ADMIN')) return true;

        if (this.isResponsable()) return true

        if (this.dataService.getMyStructure() && this.dataService.getMyStructure().beneficiaires.includes(beneficiaire.id)) {
            return true;
        }

        if (this.canFollowBeneficiaire(me, beneficiaire)) {
            return true
        }

        if (beneficiaire.conseillers.includes(me.id)) return true

        return false;
    }

    isAdmin() {
        let me = store.getState().data.me;

        if (!me) return false;

        return me.roles.includes('ROLE_ADMIN');        
    }

    isResponsable() {
        let me = store.getState().data.me;

        if (!me) return false;

        return (me.responsabilites.length > 0);
    }

    isResponsableStructure(structure) {
        let me = store.getState().data.me;

        if (!me) return false;

        return (structure && me.responsabilites.includes(structure.id));
    }

    isConseiller() {
        let me = store.getState().data.me;

        if (!me) return false;

        return (this.dataService.getConseillerById(me.id) != null && !this.dataService.getConseillerById(me.id).seulementAdmin);
    }

    canFollowBeneficiaire(conseiller, beneficiaire) {        
        let structure = this.dataService.getStructureById(beneficiaire.structure)

        if (conseiller.seulementAdmin) {
            return false
        }

        if (structure.responsables.includes(conseiller.id)) {
            return false
        }

        if (structure.filtres.length == 0) {
            //return true
        }

        if (structure.filtres.length != 0) {
            if (beneficiaire.filtres.length == 0 && conseiller.filtres.length == 0) {
                return true
            }        
        }

        /*for (let filtre of structure.filtres) {
            if (!beneficiaire.filtres.includes(filtre.id)) {
                continue
            }
            if (!conseiller.filtres.includes(filtre.id)) {
                continue
            }            
            if (filtre.filtres.length == 0) return true

            if (filtre.filtres.filter(f => beneficiaire.filtres.includes(f.id)).length == 0) return true

            if (filtre.filtres.filter(f => conseiller.filtres.includes(f.id)).length == 0) return true

            if (
                filtre.filtres
                .filter(f => beneficiaire.filtres.includes(f.id))
                .filter(f => conseiller.filtres.includes(f.id))
                .length > 0
            ) {
                return true
            }
        }*/

        return conseiller.filtresReference.includes(beneficiaire.filtreReference)
        
        return false
    }

    canViewFiltre(structure, filtre) {

        let me = store.getState().data.me;

        if (this.isAdmin()) {
            return true
        }
        
        if (structure.responsables.includes(me.id)) {
            return true
        }

        if (structure.filtres.length == 0) {
            return true
        }

        let conseiller = this.dataService.getConseillerById(me.id)

        if (conseiller === null) {
            return false
        }

        return conseiller.filtres.includes(filtre.id)
    }
}