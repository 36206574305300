import store from '../store';

export default class DataService {
    getStructureById(id) {
        for (let s of store.getState().data.structures) {
            if (s.id == id && !s.supprime) return s;
        }
        return null;
    }

    getBeneficiaireById(id) {
        for (let b of store.getState().data.beneficiaires) {
            if (b.id == id && (!b.supprime || (store.getState().data.me && store.getState().data.me.roles.includes('ROLE_ADMIN'))) && this.getStructureById(b.structure)) return b;
        }
        return null;
    }

    getConseillerById(id) {
        for (let c of store.getState().data.conseillers) {
            if (c.id == id && !c.supprime && this.getStructureById(c.structure)) return c;
        }
        return null;
    }

    getQuestionById(id) {
        let question = null;
        Object.getOwnPropertyNames(store.getState().data.questions).map((property) => {
            for (let q of store.getState().data.questions[property]) {
                if (q.id == id && !q.supprime) question = q;
            }            
        });
        return question;
    }

    getBibliotheques() {
        return store.getState().data.bibliotheques
    }
    
    getBibliothequeById(id) {
        let bibliotheque = null;
        for (let b of store.getState().data.bibliotheques) {
            if (b.id == id && !b.supprime) bibliotheque = b;
        }            
        return bibliotheque;
    }

    getNotificationById(id) {
        for (let n of store.getState().data.notifications) {
            if (n.id == id && !n.supprime) return n;
        }
        return null;
    }

    getProcheByBeneficiaireAndId(beneficiaire, id) {
        if (beneficiaire && !beneficiaire.supprime) {
            for (let p of beneficiaire.proches) {
                if (p.id == id && !p.supprime) return p;
            }
        }
        return null;
    }

    getMyStructure() {
        if (!store.getState().data.me) return null;
        
        let meconseiller = this.getConseillerById(store.getState().data.me.id);
        if (!meconseiller) return null;
        
        return this.getStructureById(meconseiller.structure);
    }    

    getStructureBeneficiaires(structure) {
        if (!structure || !structure.beneficiaires) return [];
        let beneficiaires = [];
        structure.beneficiaires.map((b, index) => {
            let beneficiaire = this.getBeneficiaireById(b);
            if (beneficiaire) beneficiaires.push(beneficiaire);
        });
        return beneficiaires;
    }

    getStructureBeneficiairesSupprimes(structure) {
        if (!structure || !structure.beneficiairesSupprimes) return [];
        let beneficiaires = [];
        structure.beneficiairesSupprimes.map((b, index) => {
            let beneficiaire = this.getBeneficiaireById(b);
            if (beneficiaire) beneficiaires.push(beneficiaire);
        });
        return beneficiaires;
    }

    getStructureConseillers(structure) {
        if (!structure || !structure.conseillers) return [];
        let conseillers = [];
        structure.conseillers.map((c, index) => {
            let conseiller = this.getConseillerById(c);
            if (conseiller) conseillers.push(conseiller);
        });
        return conseillers;
    }

    getStructureNbConseillersActifs(structure) {
        let nb = 0;
        this.getStructureConseillers(structure).map((c) => {
            if (!c.supprime && c.actif && !c.seulementAdmin) {
                nb++;
            }
        });
        return nb;
    }

    getConseillerBeneficiaires(conseiller) {
        if (!conseiller || !conseiller.beneficiaires) return [];
        let beneficiaires = [];
        conseiller.beneficiaires.map((b, index) => {
            let beneficiaire = this.getBeneficiaireById(b);
            if (beneficiaire) beneficiaires.push(beneficiaire);
        });
        return beneficiaires;
    }

    getStructureNbBeneficiairesActifs(structure) {
        let nb = 0;
        this.getStructureBeneficiaires(structure).map((b) => {
            if (!b.supprime && b.actif) {
                nb++;
            }
        });
        return nb;
    }

    getStructureNbBeneficiairesSuiviAvance(structure) {
        let nb = 0;
        this.getStructureBeneficiaires(structure).map((b) => {
            if (!b.supprime && b.actif && b.suiviAvance) {
                nb++;
            }
        });
        return nb;
    }

    getStructureNbBeneficiairesSuiviNormal(structure) {
        let nb = 0;
        this.getStructureBeneficiaires(structure).map((b) => {
            if (!b.supprime && b.actif && !b.suiviAvance) {
                nb++;
            }
        });
        return nb;
    }

    getFiltreById(id) {
        for (let s of store.getState().data.structures) {
            if (!s.supprime) {
                for (let f of s.filtres) {
                    if (f.id == id) {
                        return f
                    }
                    for (let sf of f.filtres) {
                        if (sf.id == id) {
                            return sf
                        }
                    }
                }
            };
        }
        return null;
    }

    getFiltreParent(filtre) {
        for (let s of store.getState().data.structures) {
            if (!s.supprime) {
                for (let f of s.filtres) {
                    for (let sf of f.filtres) {
                        if (sf.id == filtre.id) {
                            return f
                        }
                    }
                }
            };
        }
        return null;
    }

    getFiltreStructure(filtre) {
        for (let s of store.getState().data.structures) {
            if (!s.supprime) {
                for (let f of s.filtres) {
                    if (f.id == filtre.id) {
                        return s
                    }
                    for (let sf of f.filtres) {
                        if (sf.id == filtre.id) {
                            return s
                        }
                    }
                }
            };
        }
        return null;
    }

    getModalites() {
        console.log("get modalites", store.getState().data);
        return store.getState().data.modalites;
    }

    getTimestamp() {
        return store.getState().data.timestamp;
    }
}