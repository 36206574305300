import React, { Component } from 'react';

class InfobulleRGPD extends Component {

    componentDidMount() {
        $('.infobullergpd').tooltip()
    }

    componentDidUpdate() {
        $('.infobullergpd').tooltip()
    }

    render() {
        return (
            <span className="span-tooltip infobullergpd" data-toggle="tooltip" data-placement="top" title="Les données insérées dans les zones de commentaires libres doivent être adéquates, pertinentes et non excessives. En conséquence, les commentaires doivent rester objectifs, ne pas être injurieux, ni porter atteinte à la dignité des personnes"><i className="mdi mdi-comment-alert-outline"/></span>
        )
    }
}

export default InfobulleRGPD;
