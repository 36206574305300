import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom'

import Footer from '../components/Footer';

import DataService from '../services/data';
import SecurityService from '../services/security';
import RouteService from '../services/route';

import Api from '../api';
import { getTerme } from '../services/contextualisation';

const mapStateToProps = (state, props) => {
	console.log(props);
	let dataService = new DataService();
	return {
	  data: state.data,
	  ihm: state.ihm,
	  conseiller: dataService.getConseillerById(props.route.match.params.id),
	  history: props.route.history
	}
  }

class Conseiller extends Component {

	constructor(props) {
		super(props);

		this.state = {
			conseiller: JSON.parse(JSON.stringify(props.conseiller)),
			beneficiairesSelectionnes: [],
			mailEnvoye: null,
			ajoutBeneficiaire: null,
			erreurs: [],
			checkUniqueEmail: null,	
			motifSuppression: 'depart'		
		};

		console.log('conseiller', this.props.conseiller)
	}

	componentDidMount() {
		console.log("conseiller did mount");
		if (!this.props.conseiller) {
			console.log("oups");
			this.props.history.replace('/');
			return;
		}
		let securityService = new SecurityService();
		if (!securityService.canViewConseiller(this.props.conseiller)) {
			console.log("not allowed");
			this.props.history.replace('/');
			return;
		}

		this.bindModals()

		console.log(this.props.location);
		this.props.dispatch({type: 'IHM_HISTORY_PUSH', payload: this.props.location});

		this.bindModals()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.conseiller != this.props.conseiller) {
			this.setState({
				conseiller: JSON.parse(JSON.stringify(this.props.conseiller)),
			});
		}
	}

	bindModals() {
		window.jQuery("#modal-conseillerfiltres").off('hidden.bs.modal');
        window.jQuery("#modal-conseillerfiltres").on('hidden.bs.modal', () => {
            this.setState({
				conseiller: {
					...this.state.conseiller,
					filtres: JSON.parse(JSON.stringify(this.props.conseiller.filtres))
				}
            });
		});
	}

	componentDidUpdate(prevProps) {
		this.bindModals()
	}

	bindModals() {
        window.jQuery(".modal-suppression").off('hidden.bs.modal');
        window.jQuery(".modal-suppression").on('hidden.bs.modal', () => {
            this.setState({
                motifSuppression: 'depart'
            });
        });
    }

	changeConseiller(champ, val) {
		let conseiller = this.state.conseiller;
		let checkUniqueEmail = this.state.checkUniqueEmail;
		if (champ == "nom") {	
			conseiller.nom = val;
		}
		if (champ == "prenom") {	
			conseiller.prenom = val;
		}
		if (champ == "email") {	
			conseiller.email = val;
			checkUniqueEmail = null;
            if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(val)) {
                this.checkUniqueEmail();
            }
		}
		if (champ == "telephone") {	
			conseiller.telephone = val;
		}
		if (champ == "seulementAdmin") {	
			conseiller.seulementAdmin = val;
		}
		if (champ == "actif") {	
			conseiller.actif = val;
		}
		if (champ == "supprime") {	
			conseiller.supprime = val;
		}
		this.setState({
			conseiller: conseiller,
			checkUniqueEmail: checkUniqueEmail
		})
		console.log(champ);
		if (["seulementAdmin", "actif", "supprime"].includes(champ)) {
			this.enregistreConseiller(champ);
		}
	}

	enregistreConseiller(champ) {
		let conseiller = null;
		let erreurs = this.state.erreurs;
		if (this.state.erreurs.includes(champ)) {
			this.state.erreurs.splice(this.state.erreurs.indexOf(champ));
		}
		if (champ == "nom" && this.state.conseiller.nom != this.props.conseiller.nom) {
			if (this.state.conseiller.nom != "") {
				conseiller = {
					id: this.props.conseiller.id,
					classe: this.props.conseiller.classe,
					nom: this.state.conseiller.nom
				}
			}
			else {
				erreurs.push(champ);
			}
		}
		if (champ == "prenom" && this.state.conseiller.prenom != this.props.conseiller.prenom) {
			if (this.state.conseiller.prenom != "") {
				conseiller = {
					id: this.props.conseiller.id,
					classe: this.props.conseiller.classe,
					prenom: this.state.conseiller.prenom
				}
			}
			else {
				erreurs.push(champ);
			}
		}
		if (champ == "email" && this.state.conseiller.email != this.props.conseiller.email) {		
			if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.conseiller.email) && this.state.checkUniqueEmail) {
				conseiller = {
					id: this.props.conseiller.id,
					classe: this.props.conseiller.classe,
					email: this.state.conseiller.email
				}
			}
			else {
				erreurs.push(champ);
			}
		}
		if (champ == "telephone" && this.state.conseiller.telephone != this.props.conseiller.telephone) {
			if (this.state.conseiller.telephone == "" || /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(this.state.conseiller.telephone)) {
				conseiller = {
					id: this.props.conseiller.id,
					classe: this.props.conseiller.classe,
					telephone: this.state.conseiller.telephone
				}
			}
			else {
				erreurs.push(champ);
			}
		}
		if (champ == "seulementAdmin" && this.state.conseiller.seulementAdmin != this.props.conseiller.seulementAdmin) {	
			conseiller = {
				id: this.props.conseiller.id,
				classe: this.props.conseiller.classe,
				seulementAdmin: this.state.conseiller.seulementAdmin
			}
		}
		if (champ == "filtres") {
			conseiller = {
				id: this.props.conseiller.id,
				classe: this.props.conseiller.classe,
				filtres: this.state.conseiller.filtres
			}
		}
		if (champ == "actif" && this.state.conseiller.actif != this.props.conseiller.actif) {	
			conseiller = {
				id: this.props.conseiller.id,
				classe: this.props.conseiller.classe,
				actif: this.state.conseiller.actif
			}
		}
		if (champ == "supprime" && this.state.conseiller.supprime != this.props.conseiller.supprime) {	
			conseiller = {
				id: this.props.conseiller.id,
				classe: this.props.conseiller.classe,
				supprime: this.state.conseiller.supprime,
				motifSuprression: this.state.motifSuppression
			}
		}
		if (conseiller) {
			this.props.dispatch({type: 'DATA_POST_CONSEILLER', payload: {conseiller: conseiller, callbackErreur: () => {
				if (champ == "email") {
					let conseiller = this.state.conseiller;
					conseiller.email = this.props.conseiller.email;
					this.setState({
						conseiller: conseiller
					})
				}
			}}});
			if (conseiller.supprime) this.props.history.goBack();
		}
		this.setState({
			erreurs: erreurs
		})
	}

	toggleBeneficiaire(beneficiaire) {
        let beneficiairesSelectionnes = this.state.beneficiairesSelectionnes;
        if (beneficiairesSelectionnes.includes(beneficiaire.id)) {
            beneficiairesSelectionnes.splice(beneficiairesSelectionnes.indexOf(beneficiaire.id), 1);
        }
        else {
            beneficiairesSelectionnes.push(beneficiaire.id);
        }
        this.setState({
            beneficiairesSelectionnes: beneficiairesSelectionnes
        });
    }

    toggleAllBeneficiaires() {
		let beneficiairesSelectionnes = [];
        if (this.state.beneficiairesSelectionnes.length == this.props.structure.beneficiaires.length) {
            beneficiairesSelectionnes = [];
        }
        else {
            this.props.structure.beneficiaires.map((beneficiaire) => {
                beneficiairesSelectionnes.push(beneficiaire)
            });
        }
        this.setState({
            beneficiairesSelectionnes: beneficiairesSelectionnes
        })
    }
	
	demandeToken() {
		let api = new Api();

		api.sendResetPasswordToken(this.props.conseiller.email).then((json) => {
			console.log(json);
			if (json.succes) {
				this.setState({
					mailEnvoye: true
				});
			}
			else {
				this.setState({
					mailEnvoye: false
				});
			}	
		}).catch((err) => {
			console.log(err);
			this.setState({
				mailEnvoye: false
			});
		});
	}

	getBeneficiaire() {
		let dataService = new DataService();
		return dataService.getBeneficiaireById(this.state.ajoutBeneficiaire);
	}

	ajouteBeneficiaire() {
		let beneficiaire = this.getBeneficiaire();
		if (beneficiaire) {
			let conseillers = beneficiaire.conseillers;
			conseillers.push(this.props.conseiller.id);
			this.changeBeneficiaire(beneficiaire, "conseillers", conseillers);
		}
	}

	retireBeneficiaire(beneficiaire) {
		if (beneficiaire) {
			let conseillers = beneficiaire.conseillers;
			if (conseillers.includes(this.props.conseiller.id)) {
				conseillers.splice(conseillers.indexOf(this.props.conseiller.id), 1);
			}
			this.changeBeneficiaire(beneficiaire, "conseillers", conseillers);
		}
	}

	retireBeneficiaires() {
		let dataService = new DataService();
		this.state.beneficiairesSelectionnes.map((b) => {
			let beneficiaire = dataService.getBeneficiaireById(b);
			this.retireBeneficiaire(beneficiaire);
		});
		this.setState({
			beneficiairesSelectionnes: []
		})
	}

	changeBeneficiaire(b, champ, val) {
		let beneficiaire = null;
		if (champ == "actif") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				actif: val
			};
		}
		if (champ == "suiviAvance") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				actif: true,
				suiviAvance: val
			};
		}
		if (champ == "supprime") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				supprime: val,
				motifSuppression: this.state.motifSuppression
			};
		}
		if (champ == "conseillers") {
			beneficiaire = {
				id: b.id,
				classe: b.classe,
				conseillers: val
			};
		}
		if (beneficiaire) {
			this.props.dispatch({type: 'DATA_POST_BENEFICIAIRE', payload: {beneficiaire: beneficiaire}});
		}
	}

	beneficiairesSelectionnesActifs() {
        let dataService = new DataService();
        if (this.state.beneficiairesSelectionnes.length == 0) return null;
        
        let premierBeneficiaire = dataService.getBeneficiaireById(this.state.beneficiairesSelectionnes[0]);
        if (!premierBeneficiaire) return null;
        
        for (let i = 1 ; i < this.state.beneficiairesSelectionnes.length ; i++) {
            let beneficiaire = dataService.getBeneficiaireById(this.state.beneficiairesSelectionnes[i]);
            if (premierBeneficiaire.actif != beneficiaire.actif) {
                return null;
            }
        }
        return premierBeneficiaire.actif;
	}

	supprimeBeneficiaires() {
        let dataService = new DataService();
        this.state.beneficiairesSelectionnes.map(c => {
            let beneficiaire = dataService.getBeneficiaireById(c);
            if (beneficiaire) {
                this.changeBeneficiaire(beneficiaire, "supprime", true);
            }
        });
        this.setState({
            beneficiairesSelectionnes: []
        })
    }

    toggleActivationBeneficiaires(type) {
        let dataService = new DataService();
        this.state.beneficiairesSelectionnes.map(c => {
            let beneficiaire = dataService.getBeneficiaireById(c);
            if (beneficiaire) {
				if (beneficiaire.actif) {
					this.changeBeneficiaire(beneficiaire, "actif", false);	
				}
				else {
					this.changeBeneficiaire(beneficiaire, "actif", true);
					this.changeBeneficiaire(beneficiaire, "suiviAvance", type == "avance");
				}                
            }
        });
        this.setState({
            beneficiairesSelectionnes: []
        })
    }
	
	checkUniqueEmail() {
        let api = new Api();

		let conseiller = this.state.conseiller;
		
		api.checkUniqueEmail(conseiller.email, conseiller.classe, conseiller.id).then(json => {
            let erreurs = this.state.erreurs;
            if (json.unique && erreurs.includes("email")) {
                erreurs.splice(erreurs.indexOf("email"), 1);
            }
            if (!json.unique && !erreurs.includes("email")) {
                erreurs.push("email");
            }                        
            this.setState({
				checkUniqueEmail: json.unique,
				erreurs: erreurs,				
            });            
        }).catch(err => {
			console.log(err);			
        })
    }

	toggleFiltre(id) {
        let dataService = new DataService()
        let structure = dataService.getStructureById(this.props.conseiller.structure)
        if (this.state.conseiller.filtres.includes(id)) {
            this.state.conseiller.filtres.splice(this.state.conseiller.filtres.indexOf(id), 1)            
            structure.filtres.map(filtre => {
                if (filtre.id == id) {
                    filtre.filtres.map(sousFiltre => {
                        if (this.state.conseiller.filtres.includes(sousFiltre.id)) {
                            this.state.conseiller.filtres.splice(this.state.conseiller.filtres.indexOf(sousFiltre.id), 1)
                        }
                    })
                }                
            })
        }
        else {
            this.state.conseiller.filtres.push(id)            
            structure.filtres.map(filtre => {
                filtre.filtres.map(sousFiltre => {
                    if (sousFiltre.id == id) {
                        if (!this.state.conseiller.filtres.includes(filtre.id)) {
                            this.state.conseiller.filtres.push(filtre.id)
                        }
                    }
                })
            })
        }
        this.setState({
            conseiller: this.state.conseiller
        })
        //this.enregistreConseiller("filtres");
    }
	
	render() {

		if (!this.props.conseiller) return <></>;

		let dataService = new DataService();
		let securityService = new SecurityService();

		let structure = dataService.getStructureById(this.props.conseiller.structure);

		let modals = [];

		let beneficiaires = []
		let conseillerBeneficiaires = [];
		conseillerBeneficiaires = dataService.getStructureBeneficiaires(structure).filter(beneficiaire => securityService.canFollowBeneficiaire(this.props.conseiller, beneficiaire)).map(beneficiaire => beneficiaire.id).concat(
			this.props.conseiller.beneficiaires
		)
		conseillerBeneficiaires = conseillerBeneficiaires.filter((b, iB) => conseillerBeneficiaires.indexOf(b) == iB)

		conseillerBeneficiaires.sort((a, b) => {
			a = dataService.getBeneficiaireById(a);
			b = dataService.getBeneficiaireById(b);
			if (a && b) {
				if (a.actif != b.actif) return a.actif ? -1 : 1;
				if (a.suiviAvance != b.suiviAvance) return a.suiviAvance ? -1 : 1;
				return ((a.nom + a.prenom).localeCompare(b.nom + b.prenom));
			}	
			return -1;
		}).map((b, index) => {
			let beneficiaire = dataService.getBeneficiaireById(b);
			if (beneficiaire) {

				let conseillers = [];
				/*if (dataService.getConseillerById(beneficiaire.conseillerPrincipal)) {
					let conseiller = dataService.getConseillerById(beneficiaire.conseillerPrincipal);
					vignettesConseillers.push(
						<button key={"vignette-conseiller-" + conseiller.id} type="button" className="acronyme principal" data-toggle="tooltip" data-placement="top" title={(beneficiaire.conseillers.length > 1 ? "Principal: " : "") + conseiller.prenom + " " + conseiller.nom}>
							{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
						</button>
					)
				}
				beneficiaire.conseillers.filter((c) => (c != beneficiaire.conseillerPrincipal)).map((c) => {
					let conseiller = dataService.getConseillerById(c);
					if (conseiller != null) {
						vignettesConseillers.push(
							<button key={"vignette-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom}>
								{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
							</button>
						)
					}
				});*/
				let conseillersFiltres = []
				dataService.getStructureConseillers(structure).map(conseiller => {
					if (securityService.canFollowBeneficiaire(conseiller, beneficiaire) === false) {
						return
					}
					conseillers.push(
						<button key={"vignette-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme principal" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom}>
							{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
						</button>
					)
					conseillersFiltres.push(conseiller.id)
				})        
				beneficiaire.conseillers
				.filter(c => conseillersFiltres.includes(c) === false)
				.map((c) => {
					console.log(c)
					let conseiller = dataService.getConseillerById(c);
					if (conseiller != null) {
						conseillers.push(
							<button key={"vignette-conseiller-" + conseiller.id} onClick={() => this.props.ouvreConseiller(conseiller)} type="button" className="acronyme" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom + (structure.filtres.length > 0 ? ' (ajout manuel)' : '')}>
								{conseiller.prenom.charAt(0)}{conseiller.nom.charAt(0)}
							</button>
						)
					}
				});  
				/*let conseillerPrincipal = dataService.getConseillerById(beneficiaire.conseillerPrincipal);
				if (conseillerPrincipal) {
					conseillers.push(
						<button key={"vignette-conseiller-" + conseillerPrincipal.id} type="button" className="acronyme principal" data-toggle="tooltip" data-placement="top" title={(beneficiaire.conseillers.length > 1 ? "Principal: " : "") + conseillerPrincipal.prenom + " " + conseillerPrincipal.nom}>{conseillerPrincipal.prenom.charAt(0).toUpperCase()}{conseillerPrincipal.nom.charAt(0).toUpperCase()}</button>
					)
				}
				beneficiaire.conseillers.filter((c) => beneficiaire.conseillerPrincipal != c).map((c) => {
					let conseiller = dataService.getConseillerById(c);
					conseillers.push(
						<button key={"vignette-conseiller-" + conseiller.id} type="button" className="acronyme" data-toggle="tooltip" data-placement="top" title={conseiller.prenom + " " + conseiller.nom}>{conseiller.prenom.charAt(0).toUpperCase()}{conseiller.nom.charAt(0).toUpperCase()}</button>
					)
				});*/

				let filtresBeneficiaire = []
				if (structure) {
					structure.filtres.map(filtre => {
						let actif = beneficiaire.filtres.includes(filtre.id)
						let sousFiltres = []
						filtre.filtres.map(sousFiltre => {
							if (beneficiaire.filtres.includes(sousFiltre.id)) {
								actif = true
								sousFiltres.push(sousFiltre)
							}
						})								
						if (actif) {
							if (sousFiltres.length == 0) {
								filtresBeneficiaire.push(filtre.nom)
							}
							else {
								sousFiltres.map(sousFiltre => {
									filtresBeneficiaire.push(filtre.nom + " - " + sousFiltre.nom)
								})
							}
						}
					})
				}

				beneficiaires.push(
					<tr key={"row-beneficiaire-" + beneficiaire.id} className={(this.state.beneficiairesSelectionnes.includes(beneficiaire.id) ? "table-warning" : "") + " " + (!beneficiaire.actif ? "obsolete" : "")}>
						<td>
							<div className="checkbox-wrapper">
								<input type="checkbox" id={"toggle-beneficiaire-" + beneficiaire.id} onChange={() => this.toggleBeneficiaire(beneficiaire)} checked={this.state.beneficiairesSelectionnes.includes(beneficiaire.id)} />
								<label htmlFor={"toggle-beneficiaire-" + beneficiaire.id} className="toggle"></label>
							</div>
						</td>
						<td>
							<NavLink to={"/utilisateurs/" + beneficiaire.id}><p className="m-0 table-nom font-14">{beneficiaire.suiviAvance && <i className="mdi mdi-wifi"></i>} {beneficiaire.prenom} {beneficiaire.nom}</p></NavLink>
							{filtresBeneficiaire.length > 0 &&
							<p>
								{filtresBeneficiaire.map((filtre, iFiltre) => {
									return (
										<React.Fragment key={"filtre-" + iFiltre}>
											{iFiltre != 0 && <br />}
											{filtre}
										</React.Fragment>
									)
								})}						
							</p>
						}	
						</td>
						<td>
							<p className="m-0 text-muted font-14">Signaux faibles : <span className="table-signaux">{beneficiaire.score}<span className="table-signaux total">/{beneficiaire.seuilCalcule}</span></span>
							</p> 
						</td>
						<td>
							{conseillers}
						</td>
						<td>
							{securityService.canFollowBeneficiaire(this.props.conseiller, beneficiaire) === false &&
								<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Retirer le suivi"><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-stoppesuivibeneficiaire-" + beneficiaire.id}><i className="mdi mdi-bell-off"></i></button></span>
							}							
							{/*{beneficiaire.actif && <span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={beneficiaire.suiviAvance ? "Passer en suivi sans signaux faibles" : "Passer en suivi avec signaux faibles" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-togglesuivibeneficiaire-" + beneficiaire.id} disabled={beneficiaire.suiviAvance ? dataService.getStructureNbBeneficiairesSuiviNormal(structure) >= structure.nbMaxBeneficiairesSuiviNormal : dataService.getStructureNbBeneficiairesSuiviAvance(structure) >= structure.nbMaxBeneficiairesSuiviAvance}><i className={"mdi " + (beneficiaire.suiviAvance ? "mdi-wifi-off" : "mdi-wifi")}></i></button></span>}*/}
							<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title={beneficiaire.actif ? "Désactiver" : "Réactiver" }><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-toggleactivationbeneficiaire-" + beneficiaire.id}><i className={"mdi " + (beneficiaire.actif ? "mdi-block-helper" : "mdi-checkbox-marked-circle-outline")}></i></button></span>
							<span className="span-tooltip" data-toggle="tooltip" data-placement="top" title="Supprimer"><button className="m-0 btn btn-annexe contour" data-toggle="modal" data-target={"#modal-suppressionbeneficiaire-" + beneficiaire.id}><i className="dripicons-trash"></i></button></span>
						</td>
					</tr>
				);

				modals.push(
					<div key={"modal-stoppesuivibeneficiaire-" + beneficiaire.id} id={"modal-stoppesuivibeneficiaire-" + beneficiaire.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">Retirer le suivi</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
								<div className="modal-body">
									<p><b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b> n'assurera plus le suivi de <b>{beneficiaire.prenom} {beneficiaire.nom}</b>. Voulez-vous continuer ?</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary" onClick={() => this.retireBeneficiaire(beneficiaire)} data-dismiss="modal">Retirer le suivi</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>
							</div>
						</div>
					</div>	
				);
				modals.push(
					<div key={"modal-toggleactivationbeneficiaire-" + beneficiaire.id} id={"modal-toggleactivationbeneficiaire-" + beneficiaire.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">{beneficiaire.actif ? "Désactiver" : "Réactiver" } l'utilisateur</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
								{/*<div className="modal-body">
									{beneficiaire.actif && <p>L'ensemble des services de suivi concernant <b>{beneficiaire.prenom} {beneficiaire.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
									{!beneficiaire.actif && <p>L'ensemble des services de suivi concernant <b>{beneficiaire.prenom} {beneficiaire.nom}</b> seront réactivés. Voulez-vous continuer ?</p>}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "actif", !beneficiaire.actif)} data-dismiss="modal">{beneficiaire.actif ? "Désactiver" : "Réactiver" } l'utilisateur</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>*/}
								<div className="modal-body">
									{beneficiaire.actif && <p>L'ensemble des services de suivi concernant <b>{beneficiaire.prenom} {beneficiaire.nom}</b> seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
									{!beneficiaire.actif && <p>Le suivi concernant <b>{beneficiaire.prenom} {beneficiaire.nom}</b> sera réactivé. Vous pouvez choisir ci-dessous le type de suivi (avec signaux faibles, sans signaux faibles) souhaité.</p>}
								</div>
								<div className="modal-footer">
									{beneficiaire.actif &&
										<>
											<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "actif", false)} data-dismiss="modal">Désactiver le suivi de la personne</button>
										</>
									}
									{!beneficiaire.actif &&
										<>
											<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "suiviAvance", true)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(structure) >= structure.nbMaxBeneficiairesSuiviAvance}>Réactiver la personne avec signaux faibles</button>
											<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "suiviAvance", false)} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviNormal(structure) >= structure.nbMaxBeneficiairesSuiviNormal}>Réactiver la personne sans signaux faibles</button>
										</>
									}								
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>
							</div>
						</div>
					</div>	
				);
				modals.push(
					<div key={"modal-togglesuivibeneficiaire-" + beneficiaire.id} id={"modal-togglesuivibeneficiaire-" + beneficiaire.id} className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">Changer le type de suivi la personne</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
								<div className="modal-body">
									{beneficiaire.suiviAvance && <p>Le suivi avec signaux faibles de <b>{beneficiaire.prenom} {beneficiaire.nom}</b> sera désactivé. Il pourra être rétabli ultérieurement. Voulez-vous continuer ?</p>}
									{!beneficiaire.suiviAvance && <p>Le suivi avec signaux faibles de <b>{beneficiaire.prenom} {beneficiaire.nom}</b> sera activé. Voulez-vous continuer ?</p>}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary" onClick={() => this.changeBeneficiaire(beneficiaire, "suiviAvance", !beneficiaire.suiviAvance)} data-dismiss="modal">Passer en suivi {beneficiaire.suiviAvance ? "sans" : "avec"} signaux faibles</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>
							</div>
						</div>
					</div>	
				);
				modals.push(
					<div key={"modal-suppressionbeneficiaire-" + beneficiaire.id} id={"modal-suppressionbeneficiaire-" + beneficiaire.id} className="modal fade bs-example-modal-center bs-example-modal-lg modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title mt-0">Supprimer le compte de l'utilisateur</h5>
									<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
								<div className="modal-body">
									<p>Merci d'indiquer le motif de la suppression du compte :</p>
									<form>
										<div className="form-group">													
											<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
												<option value="depart">Ne fait plus partie des effectifs</option>
												<option value="rgpd">Demande explicite (RGPD)</option>
												<option value="autre">Autre</option>
											</select>
										</div>
									</form>
									<p>L'ensemble des services de suivi et informations liés à <b>{beneficiaire.prenom} {beneficiaire.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary suppression" onClick={() => this.changeBeneficiaire(beneficiaire, "supprime", true)} data-dismiss="modal">Supprimer le compte de l'utilisateur</button>
									<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
								</div>
							</div>
						</div>
					</div>	
				);
			}
		});

		let autresBeneficiaires = [];		
		if (structure) {
			dataService.getStructureBeneficiaires(structure).filter((b) => !this.props.conseiller.beneficiaires.includes(b.id)).map((b) => {
				if (securityService.canFollowBeneficiaire(this.props.conseiller, b)) {
					autresBeneficiaires.push(
						<option key={"option-conseillerajoutbeneficiaire-" + b.id} value={b.id}>{b.prenom} {b.nom}</option>
					);
				}				
			});
		}

		let routeService = new RouteService();
		let historyLastLocation = routeService.getPreviousLocation();
		let lastLocation = 
			<NavLink to={historyLastLocation ? historyLastLocation : "/"}><i className="mdi mdi-arrow-left"></i>
				{routeService.getLocationLibelle(historyLastLocation)}
			</NavLink>
		;

		console.log(!(this.props.conseiller.seulementAdmin && this.props.conseiller.beneficiaires.length > 0) || (this.props.conseiller.seulementAdmin && dataService.getStructureNbConseillersActifs(structure) >= structure.nbMaxConseillers));
		console.log(!(this.props.conseiller.seulementAdmin && this.props.conseiller.beneficiaires.length > 0));
		console.log(this.props.conseiller.beneficiaires.length > 0);


		let disabledSeulementAdmin = 
			(
				this.props.conseiller.seulementAdmin && 
				dataService.getStructureNbConseillersActifs(structure) >= structure.nbMaxConseillers
			)
			||
			(
				!this.props.conseiller.seulementAdmin &&
				this.props.conseiller.beneficiaires.length > 0
			)
		;

		console.log(disabledSeulementAdmin);

		return (
			<div className="content-page h-100" id="page-admin-conseiller">
                <div className="content h-100">
                    <div className="container-fluid h-100">
						<div className="row">
							<div className="col-lg-12 lienretour">
								{lastLocation}
							</div>
						</div>
						<div className="row h-100">
							<div className="col-md-4 h-100">
								<div className="card card-profil sidebar m-b-20">
									<div className="card-body">
										<h3>Coordonnées</h3>
										<form>														
											<div className="form-row">
												<div className="form-group col-md-12">
													<div className={"input-group input-obligatoire" + (this.state.erreurs.includes("nom") ? " input-error" : "")}>
														<div className="input-group-prepend">
															<div className="input-group-text">Nom *</div>
														</div>
														<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.nom} onChange={(e) => this.changeConseiller("nom", e.target.value)} onBlur={() => this.enregistreConseiller("nom")} />
													</div>
												</div>
												<div className="form-group col-md-12">
													<div className={"input-group input-obligatoire" + (this.state.erreurs.includes("prenom") ? " input-error" : "")}>
														<div className="input-group-prepend">
															<div className="input-group-text">Prénom *</div>
														</div>
														<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.prenom} onChange={(e) => this.changeConseiller("prenom", e.target.value)} onBlur={(e) => this.enregistreConseiller("prenom")} />
													</div>
												</div>
											</div>			
											<div className="form-row">			
												<div className="form-group col-md-12">
												<div className={"input-group input-obligatoire" + (this.state.erreurs.includes("email") ? " input-error" : "")}>
														<div className="input-group-prepend">
															<div className="input-group-text">Email *</div>
														</div>
														<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.email} onChange={(e) => this.changeConseiller("email", e.target.value)} onBlur={(e) => this.enregistreConseiller("email")} />
													</div>
													{this.state.checkUniqueEmail === false && <ul className="parsley-errors-list filled" id="parsley-id-15"><li className="parsley-required">Cette adresse email est déjà utilisée.</li></ul>}
												</div>
												<div className="form-group col-md-12">
												<div className={"input-group" + (this.state.erreurs.includes("telephone") ? " input-error" : "")}>
														<div className="input-group-prepend">
															<div className="input-group-text">Téléphone</div>
														</div>
														<input type="text" className="form-control" id="inlineFormInputGroupUsername" value={this.state.conseiller.telephone} onChange={(e) => this.changeConseiller("telephone", e.target.value)} onBlur={(e) => this.enregistreConseiller("telephone")} />
													</div>
												</div>													
											</div>
											<div className="form-row text-left">
												<div className="form-group col-md-12">
													<input type="checkbox" id={"toggle-conseiller-seulementAdmin-" + this.state.conseiller.id} switch="warning" checked={!this.state.conseiller.seulementAdmin} onChange={() => this.changeConseiller("seulementAdmin", !this.state.conseiller.seulementAdmin)} disabled={disabledSeulementAdmin} />
													<label htmlFor={"toggle-conseiller-seulementAdmin-" + this.state.conseiller.id}></label>
													Est un {getTerme("accompagnant", structure.domaine)}
												</div>
											</div>
											<hr />
											{structure.filtres.length > 0 && structure.responsables.includes(this.props.conseiller.id) === false &&
												<>
													<h3>Filtres</h3>
													<p>
														{structure.filtres
														.filter(f => this.props.conseiller.filtres.includes(f.id) || structure.responsables.includes(this.props.conseiller.id))
														.map((filtre, iFiltre) => {
															return (
																<React.Fragment key={"filtre-" + iFiltre}>
																	- {filtre.nom}<br />
																	{filtre.filtres
																	.filter(f => this.props.conseiller.filtres.includes(f.id) || (this.props.conseiller.filtres.includes(filtre.id) && filtre.filtres.filter(f => this.props.conseiller.filtres.includes(f.id)).length == 0) || structure.responsables.includes(this.props.conseiller.id))
																	.map((sousFiltre, iSousFiltre) => {
																		return (
																			<React.Fragment key={"filtre-" + iSousFiltre}>
																				<span className="pl-3">-- {sousFiltre.nom}<br /></span>	
																			</React.Fragment>
																		)
																	})}
																</React.Fragment>
															)
														})}
													</p>
													{!structure.responsables.includes(this.props.conseiller.id) && !this.props.conseiller.seulementAdmin &&
														<a href="#" data-toggle="modal" data-target="#modal-conseillerfiltres" className="btn btn-annexe contour contour text-center w-100">
															<i className="mdi mdi-filter-outline"></i>
															Gérer les filtres
														</a>
													}
													
													<hr />
												</>
											}
											{this.state.mailEnvoye !== null && this.state.mailEnvoye &&
												<div className="alert alert-success bg-success text-white" role="alert">
													Le mail a bien été envoyé.
												</div>
											}
											{this.state.mailEnvoye !== null && !this.state.mailEnvoye &&
												<div className="alert alert-warning bg-warning text-white" role="alert">
													Une erreur est survenue.
												</div>
											}
											<a href="#" data-toggle="modal" data-target="#modal-resetpasswordconseiller" className="btn btn-annexe contour contour text-center w-100">
												<i className="mdi mdi-send"></i>
												{!this.state.conseiller.motdepasse && <>Envoyer le mail de création du mot de passe</>}
												{this.state.conseiller.motdepasse && <>Envoyer un mail de réinitialisation du mot de passe</>}
											</a>
											
											{(!structure.responsables.includes(this.props.conseiller.id) && this.props.conseiller.id != this.props.data.me.id) &&
												<>
													<hr />
													<a href="#" data-toggle="modal" data-target="#modal-toggleactivationconseiller" className={"btn btn-annexe contour text-center w-100" + (this.props.conseiller.actif ? " attention" : " action")}><i className="mdi mdi-block-helper"></i> {this.props.conseiller.actif ? "Désactiver" : "Réactiver" } le compte {getTerme("accompagnant", structure.domaine)}</a>
													<a href="#" data-toggle="modal" data-target="#modal-suppressionconseiller" className={"btn btn-annexe contour text-center w-100 attention suppression"}><i className="dripicons-trash"></i> Supprimer le compte</a>
												</>
											}
										</form>
									</div>
								</div>
							</div>
							<div className="col-md-8">
								<div className="row">
									<div className="col-md-12">
										<h3>{this.props.conseiller.prenom} {this.props.conseiller.nom}</h3>
										{structure.responsables.includes(this.props.conseiller.id) &&
											<div className="card contenu m-b-20">
												<div className="card-body">
													<p>Cette personne est actuellement <b>définie comme responsable</b> de la structure <b>{structure.nom}</b>.</p>
													{this.props.conseiller.seulementAdmin && <p>Elle n'est pas en charge du suivi de personnes.</p>}
												</div>
											</div>
										}
										{this.props.conseiller.seulementAdmin && !structure.responsables.includes(this.props.conseiller.id) &&
											<div className="card contenu m-b-20">
												<div className="card-body">
													<p>Cette personne n'est actuellement <b>pas définie comme responsable</b> de la structure <b>{structure.nom}</b>.</p>
													<p>Elle n'est pas en charge du suivi de personnes.</p>
												</div>
											</div>
										}
										{!this.props.conseiller.seulementAdmin &&
											<div className="card contenu m-b-20">
												<div className="card-body">
													<h3 className="ml-3 mt-3">{getTerme("Utilisateurs", structure.domaine)} suivis</h3>
													<div className="table-responsive">
														<table className="table table-vertical">
															<thead>
																<tr>
																	<th>
																		<div className={"checkbox-wrapper" + (this.state.beneficiairesSelectionnes.length != this.props.conseiller.beneficiaires.length ? " selection" : "")}>
																			<input type="checkbox" id="toggle-beneficiaires-all" checked={this.state.beneficiairesSelectionnes.length > 0} onChange={() => this.toggleAllBeneficiaires()} />
																			<label htmlFor="toggle-beneficiaires-all" className="toggle"></label>
																		</div>
																	</th>
																	{this.state.beneficiairesSelectionnes.length == 0 &&
																		<>
																			<th>Nom</th>
																			<th>Signaux</th>
																			<th>{getTerme("Accompagnants", structure.domaine)}</th>
																			<th>Actions</th>
																		</>
																	}
																	{this.state.beneficiairesSelectionnes.length > 0 &&
																		<th colSpan="4">
																			<a href="#" data-toggle="modal" data-target="#modal-stoppesuivibeneficiaires" className="btn btn-annexe contour"><i className="mdi mdi-bell-off"></i> Retirer le suivi</a>
																			{this.beneficiairesSelectionnesActifs() !== null && <a href="#" data-toggle="modal" data-target="#modal-conseillertoggleactivationbeneficiaires" className="btn btn-annexe contour"><i className={this.beneficiairesSelectionnesActifs() ? "mdi mdi-block-helper" : "mdi mdi-checkbox-marked-circle-outline"}></i> {this.beneficiairesSelectionnesActifs() ? "Désactiver" : "Réactiver"}</a>}
																			<a href="#" data-toggle="modal" data-target="#modal-conseillersuppressionbeneficiaires" className="btn btn-annexe contour"><i className="dripicons-trash"></i> Supprimer</a>
																		</th>
																	}
																</tr>
															</thead>
															<tbody>
																{beneficiaires}
																<tr>
																	<td colSpan="5" className="tdaction">
																		<button className="m-0 btn btn-annexe contour action" data-toggle="modal" data-target="#modal-conseillerajoutbeneficiaire"><i className="mdi mdi-plus"></i> Ajouter un {getTerme("utilisateur", structure.domaine)}</button>
																	</td>
																</tr>
															</tbody>
														</table>	
													</div>                                                
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
				{modals}
				<div key="modal-conseillerajoutbeneficiaire" id="modal-conseillerajoutbeneficiaire" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Ajouter un suivi {getTerme("à l'accompagnant", structure.domaine)}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p className="pl-0">Choisissez ci-desssous {getTerme("l'utilisateur", structure.domaine)} qui sera maintenant suivi par <b>{this.props.conseiller.prenom} {this.props.conseiller.prenom}</b>.</p>
								<select className="form-control" value={this.state.ajoutBeneficiaire || ""} onChange={(e) => this.setState({ajoutBeneficiaire: e.target.value || null})}>
									<option value="">Choisir un {getTerme("utilisateur", structure.domaine)}</option>
									{autresBeneficiaires}
								</select>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.ajouteBeneficiaire()} disabled={this.getBeneficiaire() == null} data-dismiss="modal">Ajouter le suivi {getTerme("à l'accompagnant", structure.domaine)}</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div id="modal-resetpasswordconseiller" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">
									{!this.state.conseiller.motdepasse && <>Envoyer le mail de création du mot de passe</>}
									{this.state.conseiller.motdepasse && <>Envoyer un mail de réinitialisation du mot de passe</>}
								</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{!this.state.conseiller.motdepasse && <p>Un email va être envoyé à <b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b> afin de procéder à la création de son mot de passe. Voulez-vous continuer ?</p>}
								{this.state.conseiller.motdepasse && <p>Un email va être envoyé à <b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b> afin de réinitialiser son mot de passe. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.demandeToken()} data-dismiss="modal">
								{!this.state.conseiller.motdepasse && <>Envoyer le mail de création du mot de passe</>}
								{this.state.conseiller.motdepasse && <>Envoyer un mail de réinitialisation du mot de passe</>}
								</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div key="modal-toggleactivationconseiller" id="modal-toggleactivationconseiller" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.props.conseiller.actif ? "Désactiver" : "Réactiver" } le compte {getTerme("accompagnant", structure.domaine)}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.props.conseiller.actif && <p>L'accès aux données et tous les services de suivi seront désactivés pour <b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b>. Voulez-vous continuer ?</p>}
								{!this.props.conseiller.actif && <p>L'accès aux données et tous les services de suivi seront rétablis pour <b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b>. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.changeConseiller("actif", !this.props.conseiller.actif)} data-dismiss="modal">{this.props.conseiller.actif ? "Désactiver" : "Réactiver" } le compte {getTerme("accompagnant", structure.domaine)}</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div key="modal-suppressionconseiller" id="modal-suppressionconseiller" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer le compte {getTerme("accompagnant", structure.domaine)}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Merci d'indiquer le motif de la suppression du compte :</p>
								<form>
									<div className="form-group">													
										<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
											<option value="depart">Ne fait plus partie des effectifs</option>
											<option value="rgpd">Demande explicite (RGPD)</option>
											<option value="autre">Autre</option>
										</select>
									</div>
								</form>
								<p>L'ensemble des services de suivi et informations liés à <b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b> seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.changeConseiller("supprime", true)} data-dismiss="modal">Supprimer le compte {getTerme("accompagnant", structure.domaine)}</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
				<div id="modal-conseillersuppressionbeneficiaires" className="modal fade bs-example-modal-center bs-example-modal-lg modalquestion modal-suppression" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Supprimer des comptes de personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>Merci d'indiquer le motif de la suppression des comptes :</p>
								<form>
									<div className="form-group">													
										<select className="form-control" value={this.state.motifSuppression} onChange={(e) => this.setState({motifSuppression: e.target.value})}>
											<option value="depart">Ne font plus partie des effectifs</option>
											<option value="rgpd">Demande explicite (RGPD)</option>
											<option value="autre">Autre</option>
										</select>
									</div>
								</form>
								<p>L'ensemble des services de suivi et informations liés aux personnes sélectionnées seront désactivés et supprimés. Cette action est <b>irréversible</b>. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary suppression" onClick={() => this.supprimeBeneficiaires()} data-dismiss="modal">Supprimer les comptes des personnes</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
                    </div>
                </div>
                {/*<div key="modal-conseillertoggleactivationbeneficiaires" id="modal-conseillertoggleactivationbeneficiaires" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.beneficiairesSelectionnesActifs() ? "Désactiver" : "Réactiver" } des comptes de personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.beneficiairesSelectionnesActifs() && <p>L'ensemble des services de suivi concernant les personnes sélectionnées seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!this.beneficiairesSelectionnesActifs() && <p>L'ensemble des services de suivi concernant les personnes sélectionnées seront réactivés. Voulez-vous continuer ?</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationBeneficiaires()} data-dismiss="modal">{this.beneficiairesSelectionnesActifs() ? "Désactiver" : "Réactiver" } les comptes des personnes</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>*/}
				<div key="modal-conseillertoggleactivationbeneficiaires" id="modal-conseillertoggleactivationbeneficiaires" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">{this.beneficiairesSelectionnesActifs() ? "Désactiver" : "Réactiver" } des comptes de personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								{this.beneficiairesSelectionnesActifs() && <p>L'ensemble des services de suivi concernant concernant les personnes sélectionnées seront désactivés. Ils pourront être rétablis ultérieurement. Voulez-vous continuer ?</p>}
								{!this.beneficiairesSelectionnesActifs() && <p>Les suivis concernant les personnes sélectionnées seront réactivés. Vous pouvez choisir ci-dessous le type de suivi (avec signaux faibles, sans signaux faibles) souhaité.</p>}
							</div>
							<div className="modal-footer">
								{this.beneficiairesSelectionnesActifs() &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationBeneficiaires(null)} data-dismiss="modal">Désactiver le suivi des personnes</button>
									</>
								}
								{!this.beneficiairesSelectionnesActifs() &&
									<>
										<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationBeneficiaires("avance")} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviAvance(structure) + this.state.beneficiairesSelectionnes.length > structure.nbMaxBeneficiairesSuiviAvance}>Réactiver les personnes avec signaux faibles</button>
										<button type="button" className="btn btn-primary" onClick={() => this.toggleActivationBeneficiaires("normal")} data-dismiss="modal" disabled={dataService.getStructureNbBeneficiairesSuiviNormal(structure) + this.state.beneficiairesSelectionnes.length > structure.nbMaxBeneficiairesSuiviNormal}>Réactiver les personnes sans signaux faibles</button>
									</>
								}								
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div> 
				<div key="modal-stoppesuivibeneficiaires" id="modal-stoppesuivibeneficiaires" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Retirer le suivi de personnes</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p><b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b> n'assurera plus le suivi des personnes sélectionnées. Voulez-vous continuer ?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.retireBeneficiaires()} data-dismiss="modal">Retirer le suivi des personnes</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div> 	
				<div key="modal-conseillerfiltres" id="modal-conseillerfiltres" className="modal fade bs-example-modal-center bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">Gérer les filtres</h5>
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
							</div>
							<div className="modal-body">
								<p>
									<b>Attention</b>, en modifiant les filtres affectés à <b>{this.props.conseiller.prenom} {this.props.conseiller.nom}</b>, certains suivis pourraient être désactivés.
								</p>
								<div className="ml-4">
									{structure.filtres.map((filtre, iFiltre) => {
										return (
											<React.Fragment key={"filtre-" + iFiltre}>
												<div className="checkbox-wrapper mr-2">
													<input type="checkbox" id={"toggle-filtre-" + filtre.id} checked={this.state.conseiller.filtres.includes(filtre.id)} onChange={(e) => this.toggleFiltre(filtre.id)} />
													<label htmlFor={"toggle-filtre-" + filtre.id} className="toggle"></label>
												</div>
												<label htmlFor={"toggle-filtre-" + filtre.id} className={this.state.conseiller.filtres.includes(filtre.id) ? "bold" : ""}> {filtre.nom}</label>
												<br />
												{filtre.filtres.map((sousFiltre, iSousFiltre) => {
													return (
														<React.Fragment key={"sousfiltre-" + iSousFiltre}>
															<div className="ml-3">
																<div className={"checkbox-wrapper mr-2 " + (this.state.conseiller.filtres.includes(filtre.id) && filtre.filtres.filter(f => this.state.conseiller.filtres.includes(f.id)).length == 0 ? "bright" : "")}>
																	<input type="checkbox" id={"toggle-filtre-" + sousFiltre.id} checked={this.state.conseiller.filtres.includes(sousFiltre.id)} onChange={(e) => this.toggleFiltre(sousFiltre.id)} />
																	<label htmlFor={"toggle-filtre-" + sousFiltre.id} className="toggle"></label>
																</div>
																<label htmlFor={"toggle-filtre-" + sousFiltre.id} className={this.state.conseiller.filtres.includes(sousFiltre.id) || (this.state.conseiller.filtres.includes(filtre.id) && filtre.filtres.filter(f => this.state.conseiller.filtres.includes(f.id)).length == 0) ? "bold" : ""}> {sousFiltre.nom}</label>
															</div>                                                
														</React.Fragment>
													)
												})}
											</React.Fragment>
										)
									})}	
								</div>											
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={() => this.enregistreConseiller('filtres')} data-dismiss="modal">Enregistrer les filtres</button>
								<button type="button" className="btn btn-annexe contour" data-dismiss="modal">Annuler</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(mapStateToProps, null)(Conseiller));